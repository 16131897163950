import { Group } from '@mantine/core';
import { useAssetGridContext } from '../../AssetGridContext';
import PixiButton from '@pixi/elements/Button';
import PixiIcon from '@pixi/elements/Icon';
import { selectFile } from '@pixi/AppController';
import { getMultiStore } from '@pixi/store';

export default function AssetGridUploadBrandAsssets({
  onUpload,
}: {
  onUpload?: (files: Pickit.FileInterface[]) => void;
}) {
  const { currentFolder, currentFolderId, context, disable, currentLibrary } =
    useAssetGridContext();

  // In the future, we will check for something like currentLibrary.type
  if (disable?.includes('upload') || !currentFolderId) {
    return <></>;
  }

  return (
    <>
      <Group wrap="nowrap">
        {currentFolderId && (
          <PixiButton
            leftSection={<PixiIcon name="plus" />}
            size="md"
            variant="light"
            onClick={async () => {
              const files = await selectFile({
                popupProps: {
                  title: `Add assets to folder`,
                },
                libraryId: currentLibrary?.nameId,
                multiple: true,
                selectButtonLabel: (files) =>
                  `Add ${files.length} selected assets to folder`,
                assetGridProps: {
                  disable: ['upload'],
                  forceFilters: {
                    folders: [
                      {
                        value: [currentFolderId],
                        modifier: '$nin',
                      },
                    ],
                  },
                },
              });
              const newFiles = await context.saveDocumentsWhere(
                {
                  _id: {
                    $in: files.map((f) => f._id),
                  },
                },
                {
                  $addToSet: {
                    ...(currentFolderId
                      ? {
                          folders: currentFolderId,
                        }
                      : {}),
                  },
                },
              );
              onUpload?.(newFiles?.documents);
            }}
          >
            Add existing
          </PixiButton>
        )}
        {currentLibrary?.nameId === 'brandassets' && (
          <PixiButton
            leftSection={<PixiIcon name="plus" />}
            size="md"
            variant="light"
            onClick={async () => {
              const files = await selectFile({
                popupProps: {
                  title: 'Add media to brand assets',
                },
                libraryId: 'media',
                multiple: true,
                selectButtonLabel: (files) =>
                  `Add ${files.length} selected assets to brand`,
                assetGridProps: {
                  disable: ['upload'],
                  forceFilters: {
                    libraries: [
                      {
                        value: ['brandassets'],
                        modifier: '$nin',
                      },
                    ],
                  },
                },
              });
              const newFiles = await context.saveDocumentsWhere(
                {
                  _id: {
                    $in: files.map((f) => f._id),
                  },
                },
                {
                  $addToSet: {
                    ...(currentFolder?._id
                      ? {
                          folders: currentFolder._id,
                        }
                      : {}),
                    libraries: 'brandassets',
                  },
                },
              );
              onUpload?.(newFiles?.documents);
            }}
          >
            Add from media
          </PixiButton>
        )}
      </Group>
    </>
  );
}
