import { FileThumbnailV2 } from 'components/FileThumbnail';
import { Flex, Icon, Tooltip } from 'elements_v2';
import { PinkToolbar } from 'elements_v2/PinkToolbar';
import { useMediaContext } from 'hooks';
import useStorageUrl from 'hooks/files/useStorageUrl';
import useUserStatus from 'hooks/useUserStatus';
import React, { useRef } from 'react';
import './style.scss';

export default React.forwardRef(
  (
    {
      thumbnailUrl,
      board,
      thumbnailType,
      style,
      onClick,
      contextMenu,
      contextMenuProps,
      showLabel,
      showUnpublishedWarning,
      hidePublishedTooltip,
      selected,
      toolbar,
    },
    ref
  ) => {
    const className = ['BoardCard'];
    const contentRef = useRef();
    const userStatus = useUserStatus();
    const Media = useMediaContext();
    const { generateUrl } = useStorageUrl(Media);

    if (onClick) {
      className.push('BoardCard--clickable');
    }

    if (selected) {
      className.push('BoardCard--selected');
    }

    if (board.type) {
      className.push(`BoardCard--${board.type}`);
    }

    if (thumbnailType === 'textOutside') {
      className.push('BoardCard--textOutside');

      if (!board?.thumbnail?.id) {
        className.push('BoardCard--noThumb');
      }
    }

    return (
      <div className={className.join(' ')} ref={ref} style={style}>
        <div className="BoardCard__wrapper" ref={contentRef}>
          {toolbar && (
            <div className="BoardCard__content__toolbar">{toolbar}</div>
          )}
          <div
            className="BoardCard__content"
            onClick={onClick}
            style={{
              background:
                thumbnailType !== 'textOutside' &&
                board.preferences?.cardBackgroundColor,
              color:
                thumbnailType !== 'textOutside' &&
                board.preferences?.cardTextColor,
            }}
          >
            <div
              className="BoardCard__content__background"
              style={
                !(thumbnailUrl || board?.thumbnail?.url)
                  ? {
                      background: board.preferences?.cardBackgroundColor,
                      color: board.preferences?.cardTextColor,
                    }
                  : {}
              }
            >
              {(thumbnailUrl || board?.thumbnail?.url) && (
                <>
                  {thumbnailUrl ? (
                    <img
                      alt={board.name}
                      src={
                        thumbnailUrl ||
                        generateUrl(
                          board?.thumbnail?.previews?.[0]?.url ||
                            board?.thumbnail?.url
                        )
                      }
                      style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                      }}
                    />
                  ) : (
                    <FileThumbnailV2
                      file={board?.thumbnail}
                      ignoreFileSize
                      context={Media}
                      imageStyle={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                      }}
                    />
                  )}
                </>
              )}
            </div>
            {!board.preferences?.cardHideTitle && (
              <div className="BoardCard__content__title">
                <div className="BoardCard__content__title__text">
                  {board.name}
                </div>
                {thumbnailType === 'textOutside' && (
                  <Icon name="ChevronRight" />
                )}
              </div>
            )}
            {board.type !== 'textOutside' && (
              <div className="BoardCard__content__subtitle">
                {showLabel && <h2>{board.author}</h2>}
                {userStatus.product.isBusinessAdmin &&
                  !board.published &&
                  !hidePublishedTooltip &&
                  showUnpublishedWarning && (
                    <Tooltip delay={0.3} message="Not published">
                      <Icon name="ExclamationCircleFill" />
                    </Tooltip>
                  )}
              </div>
            )}
          </div>
          <PinkToolbar
            parentRef={contentRef}
            style={{ zIndex: 999 }}
            {...(contextMenuProps || {})}
          >
            {(contextMenu || [])?.map((item) => {
              let icon = (
                <Icon
                  button
                  bubble
                  key={item.icon}
                  name={item.icon}
                  onClick={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    item.onClick();
                  }}
                />
              );
              if (item.wrapper) {
                icon = item.wrapper(icon);
              }
              if (item.tooltip) {
                return (
                  <Tooltip message={item.tooltip} key={item.icon}>
                    {icon}
                  </Tooltip>
                );
              }
              return icon;
            })}
          </PinkToolbar>
        </div>
      </div>
    );
  }
);
