import { Group, Stack, Text, TextInput, Title } from '@mantine/core';
import System from '@pixi/System';
import {
  ForwardedRef,
  MouseEvent,
  ReactNode,
  forwardRef,
  useState,
} from 'react';
import PixiButton, { PixiButtonProps } from '../Button';
import PixiDropdown, { PixiDropdownProps } from '../Dropdown';

export interface PixiConfirmProps
  extends Omit<PixiDropdownProps, 'children' | 'target'> {
  children: React.ReactElement;
  title: string;
  description: ReactNode;
  confirmProps?: PixiButtonProps;
  confirmLabel?: string;
  cancelProps?: PixiButtonProps;
  cancelLabel?: string;
  onConfirm?: (close: (closeConfirm: boolean) => void) => void;
  confirmByTyping?: string;
}

const _PixiConfirm = (
  {
    children,
    title,
    description,
    onConfirm,
    confirmLabel,
    confirmProps,
    cancelProps,
    cancelLabel,
    onMouseLeave,
    confirmByTyping,
    ...rest
  }: PixiConfirmProps,
  ref: ForwardedRef<HTMLDivElement>,
) => {
  const [confirmType, setConfirmType] = useState('');
  return (
    <PixiDropdown
      {...rest}
      onMouseLeave={onMouseLeave}
      ref={ref}
      width={340}
      target={children}
      radius="md"
      offset={0}
      boxProps={{
        p: 'lg',
      }}
    >
      {({ setIsOpen }) => (
        <Stack>
          <Stack gap="xs">
            <Title order={3}>{title}</Title>
            {typeof description === 'string' ? (
              <Text>{description}</Text>
            ) : (
              description
            )}
          </Stack>
          {confirmByTyping ? (
            <TextInput
              label={`Confirm by typing '${confirmByTyping}'`}
              value={confirmType}
              onChange={(event) => {
                setConfirmType(event.currentTarget?.value);
              }}
            />
          ) : (
            <></>
          )}
          <Group w="100%" wrap="nowrap" justify="space-between">
            <PixiButton
              variant="subtle"
              color="gray"
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
                setIsOpen(false);
              }}
              style={{ flexShrink: 0 }}
              {...cancelProps}
            >
              {cancelLabel || 'Cancel'}
            </PixiButton>
            <PixiButton
              {...(confirmProps || {})}
              fullWidth
              disabled={
                confirmProps?.disabled ||
                (confirmByTyping &&
                  confirmType?.toLowerCase() !==
                    confirmByTyping?.toLowerCase()) ||
                undefined
              }
              onClick={async () => {
                await onConfirm?.(() => setIsOpen(false));
              }}
            >
              {confirmLabel || 'Confirm'}
            </PixiButton>
          </Group>
        </Stack>
      )}
    </PixiDropdown>
  );
};

const PixiConfirm = forwardRef(_PixiConfirm);

export default PixiConfirm;
