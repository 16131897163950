import { Box, BoxProps, MantineColor, useMantineTheme } from '@mantine/core';
import { useColorScheme } from '@mantine/hooks';

interface TransparentPatternProps extends BoxProps {
  color?: MantineColor;
}

function generatePattern(color: string) {
  const svg = `<svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10">
    <rect width="100%" height="100%" fill="transparent"/>
    <rect x="0" y="0" width="5" height="5" fill="${color}"/>
    <rect x="5" y="5" width="5" height="5" fill="${color}"/>
  </svg>`;

  const encodedSvg = encodeURIComponent(svg);

  return `url("data:image/svg+xml,${encodedSvg}")`;
}

export function TransparentPattern(props: TransparentPatternProps) {
  const theme = useMantineTheme();
  const color = !props.color
    ? 'red'
    : theme.colors[props.color.split('.')[0] as MantineColor][
        parseInt(props.color.split('.')[1])
      ];
  return (
    <Box
      w="100%"
      h="100%"
      pos="absolute"
      style={{
        backgroundSize: 12,
        backgroundImage: generatePattern(color),
      }}
      opacity={1}
      top={0}
      left={0}
      {...props}
    />
  );
}
