import React from 'react';
import './style.scss';

export default function Badge(props) {
  const className = ['Pixi', 'Pixi__Badge'];

  if (props.new) {
    className.push('Pixi__Badge--new');
  }
  if (props.outline) {
    className.push('Pixi__Badge--outline');
  }
  if (props.white) {
    className.push('Pixi__Badge--white');
  }
  if (props.size) {
    className.push(`Pixi__Badge--${props.size}`);
  }

  return (
    <div className={className.join(' ')} style={props.style}>
      {props.children}
    </div>
  );
}
