import React, { useEffect, useReducer, useRef, useState } from 'react';

export default function useInspectionTool(context) {
  const [latestMessage, setLatestMessage] = useReducer(
    (state, action) => ({ ...action }),
    {}
  );
  const [extractedFiles, setExtractedFiles] = useReducer(
    (state, action) => [...action],
    []
  );
  const [socket, setSocket] = useState(null);
  const [isStarted, setIsStarted] = useState(false);
  const [isRunning, setIsRunning] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [globalStatus, setGlobalStatus] = useReducer(
    (state, action) => ({ ...action }),
    {}
  );
  const codes = {
    CONNECTED: 'Connected to our Inspection Tool service',
    FETCHING_FILES: 'Fetching files into Inspection Tool',
    FETCHING_SELECTED_FILES: 'Fetching selected files into Inspection Tool',
    PROCESSING_FILES: 'Extracting media files',
    COLLECTING_FILES: 'Collecting extracted files',
    COLLECTING_SELECTED_FILES: 'Collecting selected files',
    STORING_SELECTED_UPDATES: 'Updating information on selected files',
    STORED_UPDATES: 'Updates applied',
    CREATING_PREVIEWS: 'Creating preview for extracted files',
    STORING_PREVIEWS: 'Storing prepared data',
    PREVIEWS_STORED: 'Prepared data stored',
    SEARCHING_SAME: 'Searching for extracted images in the library',
    SEARCHING_SIMILAR: 'Searching for similar images in the library',
    SELECTED_FILES_NOT_FOUND: 'Selected files not found',
    DONE: 'Fetching changes',
  };

  useEffect(() => {
    return () => {
      socket?.close();
    };
  }, []);

  function translateCode(code) {
    if (
      latestMessage?.progress &&
      latestMessage?.progress?.processed !== undefined &&
      latestMessage?.progress?.total !== undefined
    ) {
      return `${codes[code]} (${latestMessage?.progress?.processed} out of ${latestMessage?.progress?.total})`;
    }
    return codes[code] || code;
  }

  async function runInspectionSocket(
    file,
    postfix = '',
    selectedFiles = [],
    collectFiles = false
  ) {
    setIsRunning(true);
    setIsStarted(true);
    const newSocket = context.request.inspectionToolSocket(
      file,
      postfix,
      selectedFiles,
      collectFiles,
      {
        onProcess: (message) => {
          if (message?.extracted !== undefined) {
            setExtractedFiles(message.extracted);
          }
          setLatestMessage(message);
        },
        onClose: () => {
          socket?.close();
          setIsRunning(false);
          setIsStarted(false);
          setLatestMessage(undefined);
          setSocket(undefined);
        },
        onError: (e) => {
          console.error(e);
        },
      }
    );
    setSocket(newSocket);
  }

  async function getExtractedFiles(fileId) {
    setExtractedFiles([]);
    setIsRunning(true);
    const extracted = await context.request.inspectionToolExtracted(fileId);
    setExtractedFiles(extracted?.extracted ?? []);
    setIsProcessing(extracted?.isProcessing ?? false);
    setIsRunning(false);
  }

  async function getGlobalStatus() {
    setGlobalStatus({});
    const status = await context.request.inspectionToolStatus();
    setGlobalStatus(status);
  }

  return {
    getGlobalStatus,
    getExtractedFiles,
    globalStatus,
    runInspectionSocket,
    latestMessage,
    extractedFiles,
    translateCode,
    stop: () => {
      socket?.close();
      setIsRunning(false);
    },
    isRunning,
    isStarted,
    isProcessing,
  };
}
