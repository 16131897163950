import './style.scss';
import Tutorial from './Tutorial';
import Walkthrough from './Walkthrough';
import Simple from './Simple';

const Tooltip = Simple;

Tooltip.Tutorial = Tutorial;
Tooltip.Walkthrough = Walkthrough;
Tooltip.Simple = Simple;

export default Tooltip;
