import { bytesToSize } from 'utils';
import PixiButton from '@pixi/elements/Button';
import { Paper, Progress, Stack } from '@mantine/core';
import Field from '@pixi/elements/Field';
import { getStore, useDataStoreData } from '@pixi/store';
import Message from '@pixi/elements/Message';
import { truncate } from '@pixi/helpers/utils';

export function AssetGridUploadProgress() {
  const fileProgress = useDataStoreData('FILES_UPLOADING');
  const errors = useDataStoreData('SYSTEM_ERRORS');

  const loadedBytes = fileProgress?.reduce(
    (total, file) => total + (file.status?.bytesUploaded || 0),
    0,
  );

  const totalSize = fileProgress?.reduce((total, file) => total + file.size, 0);

  if (!loadedBytes && !totalSize && !errors.length) {
    return <></>;
  }
  return (
    <Paper p="lg">
      {!!loadedBytes && !!totalSize && (
        <Stack w="100%" mt="md">
          <>
            <Field
              label="Uploading files"
              description={
                loadedBytes !== totalSize
                  ? `Uploaded ${bytesToSize(loadedBytes)}/${bytesToSize(totalSize)}`
                  : 'Finalising upload...'
              }
            >
              <Progress
                value={(loadedBytes / totalSize) * 100}
                animated
                size="lg"
                color="dark"
                bg="transparent"
              />
            </Field>
          </>
        </Stack>
      )}
      {!!errors.length && (
        <Stack w="100%" gap="xs" mt="xs">
          {errors
            ?.filter(
              (_error, index, self) =>
                self.findIndex((_e) => _e.id === _error.id) === index,
            )
            .map((error) => (
              <Message
                key={error.id}
                onClose={() => {
                  getStore('SYSTEM_ERRORS').removeByKey(error.id);
                }}
                title={truncate(error.title?.toString() as string, 70, '...')}
                message={error.message}
                icon={error.icon}
                toolbar={
                  errors?.length > 1 && (
                    <PixiButton
                      onClick={() => {
                        getStore('SYSTEM_ERRORS').reset();
                      }}
                      variant="light"
                      color="dark"
                      size="xs"
                    >
                      Close all
                    </PixiButton>
                  )
                }
                actions={
                  <>
                    <PixiButton
                      onClick={() => {
                        getStore('SYSTEM_ERRORS').removeByKey(error.id);
                      }}
                    >
                      Got it
                    </PixiButton>
                    {error.actions?.length &&
                      error.actions.map(({ label, id, ...props }) => {
                        return (
                          <PixiButton key={id} {...props}>
                            {label}
                          </PixiButton>
                        );
                      })}
                  </>
                }
              />
            ))}
        </Stack>
      )}
    </Paper>
  );
}
