import { Box, Group, Image, ThemeIcon } from '@mantine/core';
import PixiButton from '@pixi/elements/Button';
import PixiPopup, { PixiPopupProps } from '@pixi/elements/Popup';
import {
  AppConfigInterface,
  createAppToast,
  openAssetPreview,
} from '@pixi/AppController';
import { useContextFromCollection } from 'hooks/useContextFromCollection';
import * as React from 'react';
import FilesWithHook from '../../views/Files/parts/FilesWithHook';
import { AssetGrid } from './AssetGrid/AssetGrid';
import { AssetGridProps } from './AssetGrid/Helpers';
import PixiIcon from '@pixi/elements/Icon';
import useUserStatus from 'hooks/useUserStatus';
import { useAppContext } from 'contexts/Providers/AppProvider';
import PixiConfirm from '@pixi/elements/Confirm';
import { getPreviewPrefs } from './AssetActions/useAssetActions';

const CollectionPreview = ({
  collection,
  onClose,
  zIndex,
  popupProps,
  assetGridProps,
  openAssetPreviewPrefs: _openAssetPreviewPrefs,
  readOnly = true,
}: {
  collection?: Pickit.CollectionInterface;
  zIndex?: number;
  onClose?: () => void;
  popupProps?: Partial<PixiPopupProps>;
  assetGridProps?: Partial<AssetGridProps>;
  openAssetPreviewPrefs?: (
    file: Pickit.FileInterface,
  ) => Partial<AppConfigInterface['filePreview']['prefs']>;
  readOnly?: boolean;
}) => {
  const context = useContextFromCollection(collection);
  const userStatus = useUserStatus();
  const App = useAppContext();
  const [selectedFile, setSelectedFile] = React.useReducer(
    (
      state: null | Pickit.FileInterface,
      action: null | Pickit.FileInterface,
    ) => (!action ? null : action),
    null,
  );

  React.useEffect(() => {
    if (selectedFile?._id) {
      openAssetPreview(selectedFile);
    }
  }, [selectedFile?._id]);

  if (!collection?._id) {
    return <></>;
  }

  return (
    <>
      <PixiPopup
        size={1400}
        fullHeight
        onClose={() => {
          onClose?.();
        }}
        zIndex={zIndex}
        bodyProps={{
          w: 1400,
          maw: '100%',
          h: '100%',
        }}
        title={
          <Group w="100%" wrap="nowrap">
            <Group miw="none" w="100%" wrap="nowrap" gap="xs">
              <ThemeIcon
                variant="light"
                color="dark"
                bg="dark.3"
                style={{ overflow: 'hidden' }}
              >
                <Image
                  w="100%"
                  h="100%"
                  fit="cover"
                  src={context.getCollectionThumbnailUrl(collection)}
                />
              </ThemeIcon>
              {collection?.name}
            </Group>
            {!readOnly && (
              <Group w="100%" wrap="nowrap" justify="flex-end" pr="xl">
                <PixiButton
                  style={{ marginLeft: 10, whiteSpace: 'nowrap' }}
                  leftSection={<PixiIcon name="eye" />}
                  rightSection={
                    <PixiIcon name="arrow-up-right-from-square" size="xs" />
                  }
                  component="a"
                  color="dark"
                  variant="light"
                  target="_BLANK"
                  href={`${window.location.origin}#/${collection.libraryType}/collection/${collection?._id}`}
                >
                  View in web app
                </PixiButton>
                {userStatus?.product?.isBusinessAdmin && (
                  <>
                    <PixiButton
                      onClick={() => App.toggleEdit('collection', collection)}
                      color="dark"
                      variant="light"
                      leftSection={<PixiIcon name="gear" />}
                    >
                      Settings
                    </PixiButton>
                    <PixiConfirm
                      title="Are you sure you want to delete this collection?"
                      description="This can't be reverted"
                      position="bottom-end"
                      key="delete_collection"
                      confirmLabel="Delete collection"
                      confirmProps={{
                        leftSection: <PixiIcon name="trash-can" />,
                        color: 'red',
                      }}
                      onConfirm={async () => {
                        await context.deleteCollection(collection);
                        createAppToast({
                          id: collection?._id,
                          message: 'Collection was deleted successfully',
                          icon: 'trash-can',
                        });
                      }}
                    >
                      <PixiButton
                        onClick={() => context.setEditingCollection(collection)}
                        color="dark"
                        variant="light"
                        leftSection={<PixiIcon name="trash-can" />}
                      >
                        Delete collection
                      </PixiButton>
                    </PixiConfirm>
                  </>
                )}
              </Group>
            )}
          </Group>
        }
        opened={!!collection?._id}
        {...popupProps}
      >
        <Group w="100%" h="100%" bg="gray.1" pl="xs">
          <AssetGrid
            id={`PreviewCollection-${collection._id}`}
            context={context}
            disable={readOnly ? ['rightPanel'] : undefined}
            onFileClick={
              readOnly
                ? (event, file, toolbar, allFiles) => {
                    openAssetPreview(file, {
                      ...getPreviewPrefs({
                        file,
                        allFiles,
                        extraPrefs: _openAssetPreviewPrefs?.(file),
                      }),
                    });
                  }
                : undefined
            }
            collectionId={collection?._id}
            defaultView={{
              displayAs: collection?.layout?.typev2 || 'grid',
            }}
            readOnly={readOnly}
            isInPopup
            forceFilters={{
              collections: [{ value: collection._id }],
            }}
            fileToolbarCustom={
              !readOnly
                ? [
                    {
                      key: 'remove_from_collection',
                      label: 'Remove from collection',
                      icon: 'xmark',
                      wrapper: (trigger, file) => (
                        <PixiConfirm
                          title="Remove from collection"
                          description="This can't be reverted"
                          confirmLabel="Remove"
                          zIndex={150}
                          onConfirm={async () => {
                            await context.savePartial({
                              _id: file._id,
                              $pull: {
                                collections: collection?._id,
                              },
                            });
                          }}
                        >
                          {trigger}
                        </PixiConfirm>
                      ),
                    },
                  ]
                : undefined
            }
            {...assetGridProps}
          />
        </Group>
        {/* <FilesWithHook
          context={context}
          collectionId={collection?._id}
          view="collection"
          includeDownloadBag
          loadMoreEvent="collection_popup_bottom_scroll"
          filters={context.filters.filter(
            (filter: any) => filter.view?.key !== 'collections',
          )}
          onFileRedirect={(file: Pickit.FileInterface) => {
            setSelectedFile(file);
          }}
          popupMode
          goBack={() => {
            context.setGlobalSettings({
              key: 'files_filters',
              data: {
                filters: {},
              },
            });
            onClose?.();
          }}
          setPreviewFile={setSelectedFile}
        /> */}
      </PixiPopup>
    </>
  );
};

export default CollectionPreview;
