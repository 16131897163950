import { useDocumentsContext } from 'hooks/useDocumentsContext';
import { useMediaContext } from 'hooks/useMediaContext';
import { googleMimetoExt } from 'utils';
import asyncPool from 'utils/async-pool';

export default function useHubspotImportAPI(props) {
  const { HubspotImportAuth } = props;
  const Media = useMediaContext();
  const Documents = useDocumentsContext();

  function getFoldersFromItems(items) {
    return items.filter((item) => getValue('isFolder', item));
  }

  async function getAllDrives() {
    /**
     * Getting all available drivers
     */

    return await HubspotImportAuth.getConnections();
  }

  async function getAllFolderItems(
    itemId,
    driveId,
    signal,
    includeFolders = true,
  ) {
    /**
     * Getting all folder items
     */
    const items = await getFolderItems(driveId, itemId, signal);
    let results = items;
    try {
      if (!includeFolders) {
        results = items.filter((item) => !getValue('isFolder', item));
      }
    } catch (e) {}
    return results;
  }

  function getFilesFromItems(items) {
    /**
     * Get all files
     */
    return items
      .filter((item) => !getValue('isFolder', item))
      .map((item) => item);
  }

  async function getAllFolderItemsNested(itemId, driveId, signal, onProgress) {
    /**
     * Explore drive
     * @type {*}
     */
    const items = await getAllFolderItems(itemId, driveId);
    const results = [];
    try {
      // Limit by 3 for very long paths with a lot of folders
      await asyncPool(3, items, async (item) => {
        if (getValue('isFolder', item)) {
          const folderItems = await getAllFolderItemsNested(
            getValue('id', item),
            driveId,
            signal,
          );
          folderItems.forEach((file) => results.push(file));
        }
        results.push(item);
      });
    } catch (e) {
      console.error(e);
    }

    return results;
  }

  async function getRecentFiles(driveId) {
    /**
     * Getting files that were changed less than a week ago
     * @type {*}
     */
    if (driveId === undefined) {
      return [];
    }
    const response = await HubspotImportAuth.request(driveId, 0, '', true);
    return response?.data ?? [];
  }

  async function getDrive(driveId) {
    /**
     * Getting drive details
     * @type {*}
     */
    return await HubspotImportAuth.getConnectionDetails(driveId);
  }

  async function getDriveItems(itemId, driveId) {
    /**
     * Getting all file and folders for the drive
     * @type {*}
     */

    return this.getFolderItems(driveId, 0);
  }

  async function getDriveItem(itemId, driveId) {
    /**
     * Getting exact item from drive
     * @type {*}
     */
    const response = await HubspotImportAuth.request(
      driveId,
      0,
      '',
      false,
      itemId,
    );
    return response?.data?.length ? response?.data[0] : {};
  }

  async function getFolder(itemId, driveId) {
    /**
     * Getting exact folder details
     */
    return getDriveItem(itemId, driveId);
  }

  async function getFolderItems(driveId, folderId, signal) {
    /**
     * Getting items list based on folder path
     * @type {*}
     */
    const response = await HubspotImportAuth.request(
      driveId,
      folderId,
      undefined,
      undefined,
      undefined,
      signal,
    );
    return response?.data ?? [];
  }

  async function search(driveId, query) {
    /**
     * Search files in NFS
     * @type {*}
     */

    const response = await HubspotImportAuth.request(driveId, 0, query);
    return response?.data || [];
  }

  async function getImportedReferences(items) {
    /**
     * Checking which items were imported
     * @type {{documents: *[], media: *[]}}
     */
    const libraries = {
      media: [],
      documents: [],
    };
    items.forEach((item) => {
      if (getValue('extension', item) !== undefined && !item.isFolder) {
        const extension =
          getValue('extension', item) || googleMimetoExt(item.mimeType);

        if (Media.isFileSupported(null, extension?.toLowerCase())) {
          libraries.media.push(item.id);
        }
        if (Documents.isFileSupported(null, extension?.toLowerCase())) {
          libraries.documents.push(item.id);
        }
      }
    });
    const mediaFiles = await Media.importCheckReferences(
      'hubspot_import',
      libraries.media,
    );
    const documentFiles = await Documents.importCheckReferences(
      'hubspot_import',
      libraries.documents,
    );
    return [...mediaFiles, ...documentFiles];
  }

  const translations = {
    FETCHING_DRIVE: `Fetching drive from ${HubspotImportAuth.connectionType}`,
    FETCHING_ALL_FILES: `Fetching all files from ${HubspotImportAuth.connectionType}`,
    CHECKING_ALREADY_IMPORTED: 'Checking for any file changes',
    REPLACING_UPDATED_FILES: 'Replacing changed files',
    CONNECTED: 'Starting upload',
    UPLOADING: 'Uploading file',
    PROCESSING: 'Processing file',
    AI: 'Processing file (AI)',
    JOB_CHECKED: 'All files imported',
    IMPORTING_FILES: 'Uploading files',
    ADDING_FILES_TO_COLLECTION: 'Adding already uploaded files to collection',
    ERROR_COLLECTION_DELETED: 'Sync failed. Collection has been deleted.',
  };

  function translateCode(code) {
    if (translations[code]) {
      return translations[code];
    }
    return code;
  }

  function getSiteValue(key, item) {
    return getValue(key, item);
  }

  function getDriveValue(key, item) {
    return getValue(key, item);
  }

  function getPathFromId(id) {
    return decodeURIComponent(atob(id));
  }

  function createIdFromPath(path) {
    return btoa(encodeURIComponent(path));
  }

  function getSite() {
    return {};
  }

  function createItemPath(item, currentPath = [], items) {
    if (item?.parentFolderId) {
      const parent = items.find((i) => i.id === item?.parentFolderId);
      if (parent) {
        item.idPath = [parent.id, ...currentPath];
        if (
          parent?.parentFolderId &&
          items.find((i) => i.id === parent?.parentFolderId)
        ) {
          return createItemPath(parent, item.idPath, items);
        }
      } else {
        item.idPath = currentPath;
      }
    }
    return item.idPath.join('/');
  }

  function getValue(key, item, preferences) {
    switch (key) {
      case 'id':
        return item.id;

      case 'parentId':
        return item?.parentFolderId;

      case 'driveId':
        return item?.tenant_id;

      case 'parentName':
        const splitPath = item?.path?.split('/');
        return {
          name: splitPath[splitPath.length - 2],
        };

      case 'idPath':
        return createItemPath(item, [], preferences?.items);

      case 'isFolder':
        return item.isFolder;

      case 'isFolderEmpty':
        return false;

      case 'folderParentReferenceId':
        return item?.parentFolderId;

      case 'name':
        return item.name;

      case 'lastModified':
        return item.updatedAt;

      case 'size':
        return item?.size;

      case 'hash':
        return item?.hash;

      case 'extension':
        const splitName = item.name.split('.');
        return item.isFolder ? undefined : splitName[splitName.length - 1];

      case 'downloadUrl':
        return item.url;

      case 'openUrl':
        return undefined;

      case 'createdBy':
        return undefined;

      case 'pathString':
        return item.path;

      default:
        throw new Error(
          `failed getting api item value due to invalid or missing key: ${key}`,
        );
    }
  }

  async function processFileForImport(file, job) {
    const fileData = await getDriveItem(
      getValue('id', file),
      getValue('driveId', file),
    );

    return {
      accessUrl: getValue('downloadUrl', fileData),
      file: {
        name: file.name,
        import: {
          from: props.API_ID,
          reference: getValue('id', fileData),
          referenceLocation: job?.itemId, // reference to the location in the api service
          source: job?._id,
          checksum: file?.hash,
        },
        uploadedAt: getValue('lastModified', fileData),
        collections: job?.libraryCollection
          ? [job?.libraryCollection]
          : undefined,
        .../* job?.ownerOverrideRequireApproval ?? */ (job?.requireApproval
          ? {
              approval: {
                status: 'none',
                status_change_reason: 'imported',
              },
            }
          : {}),
      },

      params: {
        ...getImportParams(file, job),
      },
    };
  }

  const getImportParams = (file, job) => {
    return {
      external_file: job?.preferences?.externalFiles,
      thumbnail_from: getValue('downloadUrl', file),
      thumbnail_from_ext: getValue('extension', file),
    };
  };

  return {
    createIdFromPath,
    getAllFolderItems,
    getAllFolderItemsNested,
    getAllDrives,
    getFilesFromItems,
    getFolderItems,
    getFolder,
    getFoldersFromItems,
    getDrive,
    getDriveItem,
    getDriveItems,
    getDriveValue,
    getImportParams,
    getImportedReferences,
    getRecentFiles,
    getSiteValue,
    getSite,
    getValue,
    getPathFromId,
    processFileForImport,
    search,
    translateCode,

    isConnected: HubspotImportAuth.isConnected,
    isConnecting: HubspotImportAuth.isConnecting,
    isReceiving: HubspotImportAuth.isReceiving,
    connectionType: HubspotImportAuth.connectionType,
    connect: HubspotImportAuth.connect,
    disconnect: HubspotImportAuth.disconnect,
    connectedDrivers: HubspotImportAuth.connectedDrivers,
    getConnectionDetails: HubspotImportAuth.getConnectionDetails,

    importedFiles: [
      ...(Media.data.imported_refs_hubspot_import || []),
      ...(Documents.data.imported_refs_hubspot_import || []),
    ],
  };
}
