import React, { useState } from 'react';
import { Button, Icon } from 'elements_v2';
import './style.scss';
import Loader from 'elements_v2/Loader';

function Switch(props) {
  const [isLoading, setIsLoading] = useState(false);
  let iconOn = 'ToggleOn';
  let iconOff = 'ToggleOff';

  if (props.checkbox) {
    iconOn = 'CheckSquareFill';
    iconOff = 'Square';
  }

  const className = ['Pixi', 'Pixi__Switch'];

  if (props.size && props.size === 'small') {
    className.push('Pixi__Switch--small');
  }
  if (props.size && props.size === 'large') {
    className.push('Pixi__Switch--large');
  }
  if (props.white) {
    className.push('Pixi__Switch--white');
  }

  return (
    <Button
      as="div"
      inline={props.inline}
      transparent
      className={className.join(' ')}
      noLoader
      style={props.style}
      disabled={props.disabled || isLoading}
      onClick={async (event) => {
        setIsLoading(true);
        if (!props.disabled) {
          await props.onChange(!props.active, event);
        }
        setIsLoading(false);
      }}
    >
      {React.isValidElement(props.labelLeft)
        ? React.cloneElement(props.labelLeft, {
            className: 'Pixi__Switch__label Pixi__Switch__label--left',
          })
        : props.labelLeft}
      {isLoading ? (
        <Loader inline style={{ height: 32, padding: 0, marginLeft: -5 }} />
      ) : (
        <>
          <Icon
            bubble
            name={iconOn}
            style={{
              ...(props.iconStyle ?? {}),
              display: !props.active ? 'none' : '',
            }}
          />
          <Icon
            bubble
            name={iconOff}
            style={{
              ...(props.iconStyle ?? {}),
              display: props.active ? 'none' : '',
            }}
          />
        </>
      )}
      {React.isValidElement(props.label)
        ? React.cloneElement(props.label, {
            className: 'Pixi__Switch__label Pixi__Switch__label--right',
          })
        : props.label}
    </Button>
  );
}

export default Switch;
