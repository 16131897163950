import { useDocumentsContext } from 'hooks/useDocumentsContext';
import { useMediaContext } from 'hooks/useMediaContext';
import { sleep } from 'utils';
import asyncPool from 'utils/async-pool';

export default function useDropboxAPI(props) {
  const { DropboxAuth } = props;
  const Media = useMediaContext();
  const Documents = useDocumentsContext();

  function getFoldersFromItems(items) {
    return items.filter((i) => i['.tag'] === 'folder');
  }
  function getFilesFromItems(items) {
    return items.filter((i) => i['.tag'] === 'file');
  }

  async function getAllFolderItemsV2(itemId, driveId, isNested, onProgress, fileCount) {
    const rootItem = await getDriveItem(itemId, 'folder'); 
    const items = await getDriveItemsV2(
      isNested,
      itemId,
      undefined,
      onProgress,
      {
        total: fileCount,
      }
    );
    const parsedItems = items.map((item) => {
      if (item?.id === itemId) {
        return item;
      }
      let path = item.path_lower.split('/');
      path.pop();
      path = path.join('/');
      if (
        item.path_lower ===
        `${rootItem.path_lower}/${item.path_lower?.split('/').pop()}`
      ) {
        return {
          ...item,
          folderId: rootItem?.id,
        };
      }
      const folder = items.find(
        (i) => i['.tag'] === 'folder' && i.path_lower === path,
      );
      return {
        ...item,
        folderId: folder?.id,
      };
    });

    return parsedItems;
  }

  async function getAllFolderItems(itemId, driveId, isNested, callback) {
    const items = await getDriveItems(itemId, true, undefined, isNested);
    const results = [];
    if (!isNested) return items;
    try {
      await sleep(500);
      await asyncPool(10, items, async (item) => {
        if (item['.tag'] === 'folder') {
          callback?.({
            code: 'FETCHING_ALL_FILES',
            data: item,
            isLoading: true,
          });
          const folderItems = await getAllFolderItems(
            item.id,
            driveId,
            true,
            callback,
          );
          await sleep(500);
          results.push(...folderItems);
        }
        results.push({ ...item, folderId: itemId });
      });
    } catch (e) {
      console.error(e);
    }
    return results;
  }
  async function getAllFolderItemsNestedV2(itemId, driveId, callback, fileCount) {
    return getAllFolderItemsV2(itemId, driveId, true, callback, fileCount);
  }
  async function getAllFolderItemsNested(itemId, driveId, callback) {
    return getAllFolderItems(itemId, driveId, true, callback);
  }

  async function getDriveItemsV2(
    isNested,
    path,
    fromCursor,
    onProgress,
    fileCount = { total: 0, current: 0 },
  ) {
    onProgress({
      total: fileCount.total
    });
    const data = await DropboxAuth.request(
      `/2/files/list_folder${fromCursor ? '/continue' : ''}`,
      {
        method: 'POST',
        body: fromCursor
          ? {
              cursor: fromCursor,
            }
          : {
              path: path || '',
              limit: 2000,
              include_media_info: true,
              ...(isNested ? { recursive: true } : {}),
            },
      },
    );
    if (data?.cursor && data?.has_more) {
      const items = await getDriveItemsV2(
        isNested,
        path,
        data.cursor,
        onProgress,
        {
          total: fileCount.total + data.entries.length,
        },
      );
      data.entries = [...data.entries, ...items];
    }
    if (fromCursor) {
      return data.entries;
    }
    if (data?.entries?.length) {
      data.entries = data.entries.sort((a, b) => a.name.localeCompare(b.name));
    }
    return data.entries.filter((entry) => !entry?.sharing_info?.no_access);
  }

  async function getDriveItems(
    path,
    getEverything = true,
    fromCursor,
    isNested,
  ) {
    const data = await DropboxAuth.request(
      `/2/files/list_folder${fromCursor ? '/continue' : ''}`,
      {
        method: 'POST',
        body: fromCursor
          ? {
              cursor: fromCursor,
            }
          : {
              path: path || '',
              limit: 2000,
              include_media_info: true,
            },
      },
    );
    if (getEverything && data?.cursor && data?.has_more) {
      const items = await getDriveItems(path, true, data.cursor);
      data.entries = [...data.entries, ...items];
    }
    if (fromCursor) {
      return data.entries;
    }
    if (data?.entries?.length) {
      data.entries = data.entries.sort((a, b) => a.name.localeCompare(b.name));
    }
    return data.entries.filter((entry) => !entry?.sharing_info?.no_access);
  }
  async function shareItem(id, create) {
    if (!create) {
      const data = await DropboxAuth.request(`/2/sharing/list_shared_links`, {
        method: 'POST',
        body: {
          path: id,
          direct_only: true,
        },
      });
      if (!data?.links?.[0]) {
        return shareItem(id, true);
      }
      return data?.links?.[0];
    }
    const data = await DropboxAuth.request(
      `/2/sharing/create_shared_link_with_settings`,
      {
        method: 'POST',
        body: {
          path: id,
        },
      },
    );
    return data;
  }

  async function getDriveItem(itemId, driveId) {
    if (driveId === 'folder') {
      const data = await DropboxAuth.request(
        `/2/files/get_metadata`,
        {
          method: 'POST',
          body: {
            path: itemId,
          },
        },
        '',
      );
      return data;
    }

    const data = await DropboxAuth.request(
      `/2/files/get_temporary_link`,
      {
        method: 'POST',
        body: {
          path: itemId,
        },
      },
      '',
    );
    return data;
  }
  async function search(query, options) {
    const data = await DropboxAuth.request(
      options?.cursor ? `/2/files/search/continue_v2` : `/2/files/search_v2`,
      {
        method: 'POST',
        body: options?.cursor
          ? options
          : {
              query,
              options: options || {},
            },
      },
      '',
    );
    if (data?.matches?.length) {
      data.matches = data.matches.map((match) => {
        return {
          found_on: match.match_type['.tag'],
          ...match.metadata.metadata,
        };
      });
    }
    return data;
  }
  async function getFolder(itemId, driveId) {
    return getDriveItem(itemId, driveId ?? 'folder');
  }

  const translations = {
    FETCHING_DRIVE: 'Fetching folder from Dropbox',
    FETCHING_ALL_FILES: 'Fetching all files from Dropbox',
    CHECKING_ALREADY_IMPORTED: 'Checking for any file changes',
    REPLACING_UPDATED_FILES: 'Replacing changed files',
    CONNECTED: 'Starting upload',
    UPLOADING: 'Uploading file',
    PROCESSING: 'Processing file',
    AI: 'Processing file (AI)',
    JOB_CHECKED: 'All files imported',
    IMPORTING_FILES: 'Uploading files',
    ADDING_FILES_TO_COLLECTION: 'Adding already uploaded files to collection',
    ERROR_COLLECTION_DELETED: 'Sync failed. Collection has been deleted.',
    SOMETHING_WENT_WRONG: 'Sync failed due to an unknown reason.',
  };

  function translateCode(code, status) {
    if (translations[code]) {
      return (
        translations[code] +
        (code === 'IMPORTING_FILES' && status?.total
          ? ` (${status?.current}/${status?.total})`
          : '')
      );
    }
    return code;
  }

  async function processFileForImport(file, job) {
    const fileData = await getDriveItem(file.id ?? file?.metadata?.id);

    let sharedFile = {};
    if (
      job?.preferences?.externalFiles &&
      job?.preferences?.externalSeamlessDownload
    ) {
      sharedFile = await shareItem(file.id);
    }
    return {
      accessUrl: fileData.link,
      file: {
        name: fileData?.metadata?.name,
        ...(job?.preferences?.externalFiles
          ? {
              file: {
                url: job?.preferences?.externalSeamlessDownload
                  ? sharedFile?.url?.replace('dl=0', 'dl=1')
                  : `https://www.dropbox.com/home/${file.path_lower}`,
                open_url: job?.preferences?.externalSeamlessDownload
                  ? `https://www.dropbox.com/home/${file.path_lower}`
                  : undefined,
                ext: fileData?.metadata?.name?.split('.')?.pop()?.toLowerCase(),
                external_file: job?.preferences?.externalFiles,
                name: fileData?.metadata?.name,
              },
            }
          : {}),
        import: {
          from: props.API_ID,
          reference: fileData.metadata.id,
          referenceLocation: fileData.metadata.path_lower,
          source: job?._id,
          checksum: fileData.metadata.content_hash,
        },
        uploadedAt: fileData.metadata.client_modified,
        collections: job?.libraryCollection
          ? [job?.libraryCollection]
          : undefined,
        .../* job?.ownerOverrideRequireApproval ?? */ (job?.requireApproval
          ? {
              approval: {
                status: 'none',
                status_change_reason: 'imported',
              },
            }
          : {}),
      },

      params: {
        ...getImportParams(fileData, job),
      },
    };
  }
  const getImportParams = (file, job) => {
    return {
      external_file: job?.preferences?.externalFiles,
      thumbnail_from: file.link,
      thumbnail_from_ext:
        file?.name?.split('.')?.pop() ??
        file?.metadata?.name?.split('.')?.pop(),
    };
  };

  function createItemPath(item, currentPath = [], items) {
    if (item?.folderId) {
      const parent = items.find((i) => i.id === item?.folderId);
      if (parent) {
        item.path = [parent?.id, ...currentPath];
        if (parent?.folderId) {
          return createItemPath(parent, item.path, items);
        }
      } else {
        item.path = currentPath;
      }
    } else {
      item.path = [];
    }
    return item.path.join('/');
  }

  function getValue(key, item, preferences = {}) {
    switch (key) {
      case 'id':
        return item.id || item.path_lower;
      case 'idPath':
        return createItemPath(item, [], preferences?.items);
      case 'driveId':
        return undefined;
      case 'isFolder':
        return item['.tag'] === 'folder';
      case 'isFolderEmpty':
        return false;
      case 'folderParentReferenceId':
        return item.folderId; // This is saved from nested folder

      case 'name':
        return item.name;
      case 'lastModified':
        return item?.client_modified;
      case 'createdBy':
        return undefined;
      case 'size':
        return item?.size;
      case 'hash':
        return item?.content_hash;
      case 'extension':
        return item.name?.split?.('.')?.pop()?.toLowerCase();
      case 'downloadUrl':
        return item.link;
      case 'openUrl':
        return undefined;
      case 'pathString':
        return item.path_display;
      default:
        throw new Error(
          item,
          'failed getting api item value due to invalid or missing key',
        );
    }
  }

  return {
    getDriveItems,
    getAllFolderItemsNested,
    getAllFolderItemsV2,
    getAllFolderItemsNestedV2,
    getDriveItem,
    search,
    getAllFolderItems,
    getFoldersFromItems,
    getFilesFromItems,
    getFolder,
    translateCode,
    processFileForImport,
    getValue,
    getImportParams,

    isConnected: DropboxAuth.isConnected,

    importedFiles: [
      ...(Media.data.imported_refs_dropbox || []),
      ...(Documents.data.imported_refs_dropbox || []),
    ],
  };
}
