import React from 'react';
import './BackButton.scss';
import PropTypes from 'prop-types';

const propTypes = {
  onClick: PropTypes.func,
  color: PropTypes.string,
  noHover: PropTypes.bool,
};

const defaultProps = {
  onClick: () => console.info('Click'),
  color: '#515151',
  noHover: false,
};

export const BackButton = ({
  color,
  noHover,
  onClick,
  style,
  cross,
  className,
  disabled,
}) => (
  <div
    className={`BackButton ${className} ${
      noHover || disabled ? ' BackButton--noHover' : ''
    }`}
    onClick={disabled ? () => {} : onClick}
    style={{
      ...style,
      ...(disabled ? { cursor: 'default', opacity: 0.5 } : {}),
    }}
  >
    {cross ? (
      <svg
        className="BackButton__img"
        height="20"
        viewBox="0 0 20 20"
        width="20"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g
          fill="none"
          stroke={color}
          strokeLinecap="round"
          transform="translate(1 1)"
        >
          <path d="m.5.5 17.1172428 17.1172428" />
          <path
            d="m.5.5 17.1172428 17.1172428"
            transform="matrix(-1 0 0 1 18 0)"
          />
        </g>
      </svg>
    ) : (
      <svg
        className="BackButton__img"
        xmlns="http://www.w3.org/2000/svg"
        width="8"
        height="12"
        viewBox="0 0 8 12"
      >
        <path
          fill="none"
          fillRule="nonzero"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M7 12L1 6l6-6"
        />
      </svg>
    )}
  </div>
);

BackButton.propTypes = propTypes;
BackButton.defaultProps = defaultProps;
