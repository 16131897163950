import { Button, Flex, Icon } from 'elements_v2';
import Input from 'elements_v2/Input/index_ts';
import { useMediaContext } from 'hooks';
import React, { useState, useEffect } from 'react';

const timeToSeconds = (timeString) => {
  const [hours, minutes, seconds] = timeString.split(':');
  return +hours * 60 * 60 + +minutes * 60 + +seconds.replace(',', '.');
};

const timeToTimestamp = (timeString) => {
  const [hours, minutes, rawSeconds] = timeString.split(':');
  // Handle seconds which might contain commas (like "03,500" for "03.500")
  const seconds = Math.floor(parseFloat(rawSeconds.replace(',', '.')));

  // Get the current date
  const timestamp = new Date();

  // Reset the date to the start of the day
  timestamp.setHours(0, 0, 0, 0);

  // Adjust hours, minutes, and seconds based on the timeString
  timestamp.setHours(Number(hours));
  timestamp.setMinutes(Number(minutes));
  timestamp.setSeconds(seconds);

  // Return the adjusted timestamp
  return timestamp;
};
const formatTimestamp = (timestamp) => {
  const hours = String(timestamp.getHours()).padStart(2, '0');
  const minutes = String(timestamp.getMinutes()).padStart(2, '0');
  const seconds = String(timestamp.getSeconds()).padStart(2, '0');

  return `${hours}:${minutes}:${seconds}`;
};

const CaptionButton = ({
  caption,
  onCaptionClick,
  onTextChange,
  currentTime,
  isActive,
}) => {
  return (
    <Flex style={{ width: '100%' }} alignItems="center" gap={10}>
      <div>{formatTimestamp(timeToTimestamp(caption.startTime))}</div>
      <Input.Field
        textarea
        value={caption.text}
        onChange={(val) => onTextChange(val, caption.id)}
        style={{ width: '100%', flex: 1, resize: 'none' }}
        inputStyle={{
          resize: 'none',
        }}
      />
      <Button
        inline
        primary={isActive}
        onClick={() => onCaptionClick(caption.startTime)}
      >
        <Icon name="play-fill" style={{ marginRight: 0 }} />
      </Button>
    </Flex>
  );
};

const SrtEditor = ({ url, currentTime, fileId, onSave, videoRef }) => {
  const Media = useMediaContext();
  const [captions, setCaptions] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [currentCaption, setCurrentCaption] = useState(null);

  async function loadUrl(url) {
    setIsFetching(true);
    try {
      const response = await fetch(url);
      const data = await response.text();
      const parsedCaptions = parseSrt(data);
      setCaptions(parsedCaptions);
    } catch (error) {
      console.error('Failed to fetch SRT:', error);
    } finally {
      setIsFetching(false);
    }
  }
  const onCaptionClick = (startTime) => {
    const seconds = timeToSeconds(startTime);
    videoRef.current.currentTime = seconds;
    videoRef.current.play();
  };

  useEffect(() => {
    loadUrl(url);
  }, [url]);

  useEffect(() => {
    const findCaptionForTime = () => {
      for (const caption of captions) {
        const startTime = timeToSeconds(caption.startTime);
        const endTime = timeToSeconds(caption.endTime);
        if (currentTime >= startTime && currentTime <= endTime) {
          setCurrentCaption(caption);
          return;
        }
      }
      setCurrentCaption(null);
    };

    findCaptionForTime();
  }, [captions, currentTime]);

  const parseSrt = (data) => {
    const regex =
      /(\d+)\s*([\d:,]+)\s*-->\s*([\d:,]+)\s*([\s\S]*?)(?=\n{2,}|$)/g;
    let match;
    const results = [];

    while ((match = regex.exec(data))) {
      results.push({
        id: match[1],
        startTime: match[2],
        endTime: match[3],
        text: match[4].trim(),
      });
    }

    return results;
  };

  const timeToSeconds = (timeString) => {
    const [hours, minutes, seconds] = timeString.split(':');
    return +hours * 60 * 60 + +minutes * 60 + +seconds.replace(',', '.');
  };

  const captionsToSrt = (captions) => {
    return captions
      .map((caption) => {
        return `${caption.id}
${caption.startTime} --> ${caption.endTime}
${caption.text}`;
      })
      .join('\n\n');
  };
  useEffect(() => {
    if (isDirty) {
      const newSrtData = captionsToSrt(captions);
      onSave(newSrtData);
    }
  }, [isDirty, captions]);
  if (isFetching) {
    return <div>Loading...</div>;
  }
  const activeCaption = captions.find((caption) => {
    const startTimeInSeconds = timeToSeconds(caption.startTime);
    const endTimeInSeconds = timeToSeconds(caption.endTime);

    const isCurrentTimeInRange =
      currentTime > startTimeInSeconds && currentTime < endTimeInSeconds;
    return isCurrentTimeInRange;
  });

  return (
    <>
      <Flex
        column
        gap={5}
        style={{
          width: '100%',
          maxHeight: '270px',
          overflow: 'auto',
          marginTop: 15,
        }}
      >
        {captions.map((caption) => (
          <CaptionButton
            key={caption.id}
            caption={caption}
            onCaptionClick={onCaptionClick}
            currentTime={currentTime}
            isActive={activeCaption === caption}
            onTextChange={(value, id) => {
              setIsDirty(true);
              const updatedCaptions = captions.map((caption) =>
                caption.id === id ? { ...caption, text: value } : caption
              );
              setCaptions(updatedCaptions);
            }}
          />
        ))}
      </Flex>
    </>
  );
};

export default SrtEditor;
