import { useEffect, useRef, useState } from 'react';

export default function useFullViewportHeight() {
  const ref = useRef<HTMLDivElement>(null); // Reference to the component's root element

  const [dynamicHeight, setDynamicHeight] = useState<string | number>('100vh');

  const rightPanel = ref.current;
  const scrollableParent = rightPanel?.closest?.('.pixi-ScrollArea-viewport');

  function align() {
    if (!scrollableParent) {
      return;
    }
    if (!rightPanel) {
      return;
    }
    const viewportHeight = window.innerHeight;
    const scrollableParentBounding = scrollableParent.getBoundingClientRect();
    const topPos = rightPanel.getBoundingClientRect().top;
    const newHeight = viewportHeight - topPos; // adjust height to cover from top of the panel to the bottom of the viewport
    setDynamicHeight(
      newHeight > scrollableParentBounding.height
        ? scrollableParentBounding.height
        : newHeight,
    );
  }

  useEffect(() => {
    // Initial height adjustment when the component mounts
    align();

    scrollableParent?.addEventListener('scroll', align);
    window.addEventListener('resize', align); // Handle window resizing

    return () => {
      scrollableParent?.removeEventListener('scroll', align);
      window.removeEventListener('resize', align);
    };
  }, [rightPanel, scrollableParent]);

  return {
    height: dynamicHeight,
    ref,
  };
}
