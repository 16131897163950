import * as yup from 'yup';
import React from 'react';
import Icon from './icon.svg?react';

export default [
  {
    moduleId: 'TITLE_TEXT',
    menuGroupIds: ['TEXT'],
    moduleInterface: {
      title: 'TITLE',
      text: 'TEXT',
      link: 'LINK',
    },
    status: {
      isEmpty: (data) => {
        return !data?.title;
      },
    },
    moduleMetadataInterface: {
      noPadding: yup.boolean(),
      extraPadding: yup.boolean(),
      textAlign: yup.mixed().oneOf(['left', 'right', 'center']),
      titleSize: yup.mixed().oneOf(['small', 'medium', 'large']),
      textSize: yup.mixed().oneOf(['small', 'medium', 'large']),
      backgroundColor: yup.string(),
      textColor: yup.string(),
    },
    menuContent: { key: 'TITLE_TEXT', icon: <Icon />, name: 'Title and text' },
  },
  {
    moduleId: 'TEXT',
    menuGroupIds: ['TEXT'],
    moduleInterface: {
      text: 'TEXT',
      link: 'LINK',
    },
    status: {
      isEmpty: (data) => {
        return !data?.text;
      },
    },
    moduleMetadataInterface: {
      noPadding: yup.boolean(),
      extraPadding: yup.boolean(),
      textAlign: yup.mixed().oneOf(['left', 'right', 'center']),
      titleSize: yup.mixed().oneOf(['small', 'medium', 'large']),
      textSize: yup.mixed().oneOf(['small', 'medium', 'large']),
      linkSize: yup.mixed().oneOf(['small', 'medium', 'large']),
      backgroundColor: yup.string(),
      textColor: yup.string(),
    },
    menuContent: { key: 'TEXT', icon: <Icon />, name: 'Text' },
  },
];
