import {
  ActionIcon,
  Badge,
  Group,
  Highlight,
  Pill,
  PillsInput,
  PillsInputProps,
  TextInput,
  ThemeIcon,
} from '@mantine/core';
import PixiIcon from '@pixi/elements/Icon';
import { useAssetGridContext } from '../AssetGridContext';
import { useEffect, useMemo, useState } from 'react';
import PixiDropdown from '@pixi/elements/Dropdown';
import { useUserContext } from 'hooks';
import PixiButton from '@pixi/elements/Button';
import useDelayedState from 'hooks/useDelayedState';

export default function AssetGridSearch({ ...rest }: Partial<PillsInputProps>) {
  const { setFilter, activeFilters, metadata, currentLibrary } =
    useAssetGridContext();
  const User = useUserContext();
  const [searchValue, setSearchValue] = useState<string>('');
  const [delayedSearchValue, setDelayedSearchValue] = useDelayedState('', 200);

  const keywordsFilter = metadata?.metaFilters?.tags;
  const keywordsFilterExists = !!keywordsFilter?.length;

  const isTagsEnabled =
    currentLibrary?.preferences?.enableTagsSearch && keywordsFilterExists;

  useEffect(() => {
    setDelayedSearchValue(searchValue);
  }, [searchValue]);

  const availableAutocompleteTags = useMemo(
    () =>
      keywordsFilter
        ?.map((r) => r.value)
        ?.filter((tag: string) => {
          const keywordFilter = keywordsFilter?.find(
            (r) => (r.value as string)?.toLowerCase() === tag?.toLowerCase(),
          );
          return (
            tag
              .toLowerCase()
              .includes(
                delayedSearchValue.toLowerCase().trim().replace(/\s+/g, ' '),
              ) &&
            keywordFilter?.count &&
            !activeFilters?.tags?.find((t) => t?.value === tag)
          );
        })
        ?.slice(0, !delayedSearchValue ? 20 : undefined) || [],
    [delayedSearchValue],
  );

  const autoComplete = useMemo(() => {
    return (
      isTagsEnabled && (
        <>
          {availableAutocompleteTags
            .sort((a: string, b: string) => {
              const aKeywordFilter =
                keywordsFilter?.find(
                  (r) =>
                    (r.value as string)?.toLowerCase() === a?.toLowerCase(),
                )?.count || 0;
              const bKeywordFilter =
                keywordsFilter?.find(
                  (r) =>
                    (r.value as string)?.toLowerCase() === b?.toLowerCase(),
                )?.count || 0;
              return bKeywordFilter - aKeywordFilter;
            })
            .slice(0, 25)
            .map((tag: string) => {
              const keywordFilter = keywordsFilter?.find(
                (r) =>
                  (r.value as string)?.toLowerCase() === tag?.toLowerCase(),
              );
              return (
                <PixiDropdown.Item
                  key={tag}
                  onClick={() => {
                    setFilter(
                      'tags',
                      [...(activeFilters.tags || []), { value: tag }],
                      {
                        replace: true,
                      },
                    );
                    setSearchValue('');
                    setDelayedSearchValue('');
                  }}
                  leftSection={
                    keywordFilter?.count ? (
                      <Badge size="xs" variant="filled" color="gray">
                        {keywordFilter?.count}
                      </Badge>
                    ) : undefined
                  }
                >
                  <Highlight highlight={delayedSearchValue}>
                    {tag?.toLowerCase()}
                  </Highlight>
                </PixiDropdown.Item>
              );
            })}
        </>
      )
    );
  }, [activeFilters, metadata?.metaFilters, delayedSearchValue]);

  return (
    <PixiDropdown
      width={360}
      height={340}
      disabled={
        !isTagsEnabled ||
        !delayedSearchValue ||
        !availableAutocompleteTags?.length
      }
      closeOnItemClick
      offset={0}
      target={
        <TextInput
          maw={220}
          placeholder="Search"
          onChange={(event) => {
            setSearchValue(event.currentTarget.value);
          }}
          variant="glass"
          size="sm"
          styles={{
            root: {
              width: '100%',
              display: 'flex',
              alignSelf: 'stretch',
            },
            wrapper: {
              width: '100%',
              display: 'flex',
              alignSelf: 'stretch',
            },
            input: {
              height: 'auto',
            },
          }}
          h="auto"
          leftSection={<PixiIcon name="magnifying-glass" />}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              if (!searchValue) {
                return;
              }
              setFilter(
                '$query',
                [...(activeFilters.$query || []), { value: searchValue }],
                {
                  replace: true,
                },
              );
              setSearchValue('');
            }
          }}
          value={searchValue}
          {...rest}
        />
      }
    >
      {({ isOpen }) => isOpen && autoComplete}
    </PixiDropdown>
  );
}
