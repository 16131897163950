import sessionStorageWrapper from './sessionStorageWrapper';

export const inOffice = () => {
  return (
    window.Office &&
    window.Office.context &&
    !!Object.keys(window.Office.context).length
  );
};
export const isIE11 = !!window.MSInputMethodContext && !!document.documentMode;
export const isChrome =
  /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
export const inTeams = () => {
  return (
    window.navigator?.userAgent?.indexOf('Teams/') > -1 ||
    sessionStorageWrapper.getItem('platform') === 'teams'
  );
};
export const isEdge = window.navigator.userAgent.indexOf('Edge/') > -1;
export const inOfficeOnline = () => {
  return window.top?.window !== window && inOffice();
};
export const inGoogleDocs = () => {
  return !!window.googleaddon;
};
export const inAdobeAddon = () => {
  return !!window.adobeaddon;
};
export const isAddin = () => {
  return inGoogleDocs() || inOffice() || inAdobeAddon() || isWordpress;
};

// The app is embed in an iframe that isn't updating the url
export const isDirectEmbed = window.location?.href?.includes('?direct_embed');

// The app is embed and is using smart url
export const isEmbed = window.location?.href?.includes('?embed');

export const isWordpress = window.location?.href?.includes('?wordpress');

export const inWebApp = () => {
  return !isAddin() && !inTeams() && !inOffice() && !inOfficeOnline();
};

export const isNative = () => {
  return !!window.pickitNative?.quitApp;
};

export const isWindows = () => {
  return window?.navigator?.userAgentData?.platform === 'Windows';
};
export const isMac = () => {
  return window?.navigator?.userAgentData?.platform === 'macOS';
};

const photoshopFormats = [
  'aac',
  'm2a',
  'm4a',
  'mp2',
  'mp3',
  'mp4',
  '264',
  '3gp',
  '3gpp',
  'avc',
  'avi',
  'f4v',
  'flv',
  'mov',
  'mpe',
  'mpeg',
  'mpe',
  'mpg',
  'mts',
  'mxf',
  'r3d',
  'ts',
  'vob',
  'webp',
  'bmp',
  'gif',
  'dicom',
  'heif',
  'heic',
  'iff ',
  'jpeg',
  'jpeg2000',
  'psb',
  'openexr',
  'pcx',
  'dcs',
  'eps',
  'pdf',
  'psd',
  'raw',
  'pict',
  'pxr',
  'png',
  'tga',
  'vda',
  'icb',
  'vst',
  'tiff',
  'tif',
  'ai',
  'arw',
  'cr2',
  'dng',
  'mxf',
  'nef',
  'nrw',
  'orf',
  'pef',
  'raf',
  'rw2',
  'wav',
  'braw',
];
export const supportedAdobeFormats: Record<
  'PHSP' | 'PHXS' | 'IDSN' | 'ILST' | 'PPRO' | 'AEFT',
  string[]
> = {
  PHSP: photoshopFormats,
  PHXS: photoshopFormats,
  IDSN: [
    'tiff',
    'tif',
    'gif',
    'jpg',
    'jpeg',
    'bmp',
    'eps',
    'dcs',
    'idms',
    'wmf',
    'emf',
    'pcx',
    'png',
    'sct',
    'ai',
    'psd',
    'pdf',
    'indd',
    'indt',
    'txt',
    'doc',
    'docx',
    'xls',
    'xlsx',
    'rtf',
    'mp4',
    'avi',
    'mov',
    'mp3',
    'heic',
    'heif',
    'nef',
    'dng',
    'mp3',
    'nef',
  ],
  ILST: [
    'ai',
    'ait',
    'pdf',
    'dwg',
    'dxf',
    'avif',
    'bmp',
    'rle',
    'dib',
    'cgm',
    'cdr',
    'eps',
    'epsf',
    'ps',
    'emf',
    'gif',
    'heic',
    'heif',
    'jpg',
    'jpe',
    'jpeg',
    'jpf',
    'jpx',
    'jp2',
    'j2k',
    'j2c',
    'jpc',
    'rtf',
    'doc',
    'docx',
    'pcx',
    'psd',
    'pdd',
    'pxr',
    'png',
    'pns',
    'svg',
    'svgz',
    'tga',
    'vda',
    'icb',
    'vst',
    'txt',
    'tif',
    'tiff',
    'webp',
    'wmf',
    'dng',
    'nef',
  ],
  PPRO: [
    'ai',
    'eps',
    'bmp',
    'dib',
    'rle',
    'dpx',
    'eps',
    'gif',
    'ico',
    'jpeg',
    'jpe',
    'jpg',
    'jfif',
    'png',
    'psd',
    'ptl',
    'prtl',
    'tga',
    'icb',
    'vda',
    'vst',
    'tiff',
    'tif',
    'dfxp',
    'mcc',
    'scc',
    'srt',
    'stl',
    'xml',
    'aaf',
    'aep',
    'aepx',
    'chproj',
    'csv',
    'pbl',
    'txt',
    'tab',
    'edl',
    'prel',
    'prproj',
    'xml',
    'mp4',
    'mov',
    'mp3',
    'avi',
    'heic',
    'heif',
    'mxf',
    'wav',
    'braw',
  ],
  AEFT: [
    'aac',
    'm4a',
    'aif',
    'aiff',
    'mp3',
    'mpeg',
    'mpg',
    'mpa',
    'mpe',
    'wav',
    'ai',
    'eps',
    'ps',
    'pdf',
    'psd',
    'bmp',
    'rle',
    'dib',
    'tif',
    'tiff',
    'crw',
    'nef',
    'raf',
    'orf',
    'mrw',
    'dcr',
    'mos',
    'raw',
    'pef',
    'srf',
    'dng',
    'x3f',
    'cr2',
    'erf',
    'cin',
    'dpx',
    'gif',
    'rla',
    'rpf',
    'img',
    'ei',
    'eps',
    'iff',
    'tdi',
    'jpeg',
    'jpg',
    'jpe',
    'heif',
    'heic',
    'ma',
    'exr',
    'pcx',
    'png',
    'hdr',
    'rgbe',
    'xyze',
    'sgi',
    'bw',
    'rgb',
    'pic',
    'tga',
    'vda',
    'icb',
    'vst',
    'pnc',
    'crm',
    'r3d',
    'mxf',
    'hevc',
    'mov',
    '3gpp',
    '3gp',
    '3g2',
    'amc',
    'swf',
    'flv',
    'f4v',
    'prores',
    'avchd',
    'm2ts',
    'dv',
    'm4v',
    'mpg',
    'mpe',
    'mpa',
    'mpv',
    'mod',
    'mpg',
    'm2p',
    'm2v',
    'm2p',
    'm2a',
    'm2t',
    'mp4',
    'm4v',
    'omf',
    'avi',
    'wmv',
    'wma',
    'mp4',
    'arw',
    'dng',
    'mp3',
    'mxf',
    'nrw',
    'rw2',
    'wav',
    'braw',
  ],
};
