import React, { useEffect, useState } from 'react';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  UniqueIdentifier,
} from '@dnd-kit/core';
import {
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  rectSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

interface SortableItemData<T> {
  data: T;
  id: string;
}

interface SortableProps<T> {
  initialData: T[];
  onRearrange: (newData: SortableItemData<T>[]) => void;
  render: ({
    dataRow,
    props,
    isDragging,
  }: {
    dataRow: SortableItemData<T>;
    props: any;
    isDragging: boolean;
  }) => JSX.Element;
}

const SortableItem: React.FC<{
  id: string;
  data: SortableItemData<any>;
  render: (args: {
    dataRow: SortableItemData<any>;
    props: any;
    isDragging: boolean;
  }) => JSX.Element;
}> = ({ id, render, data }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return render({
    dataRow: {
      data,
      id,
    },
    isDragging,
    props: {
      ref: setNodeRef,
      style,
      ...attributes,
      ...listeners,
    },
  });
};

const Sortable: React.FC<SortableProps<any>> = ({
  initialData,
  onRearrange,
  render,
}) => {
  const [data, setData] = useState<SortableItemData<any>[]>(() =>
    initialData.map((item, index) => ({
      data: item,
      id: `item-${index}`,
    })),
  );

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 5,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  useEffect(() => {
    setData(
      initialData.map((item, index) => ({
        data: item,
        id: `item-${index}`,
      })),
    );
  }, [initialData]);

  function handleDragEnd(event: {
    active: { id: UniqueIdentifier };
    over: { id: UniqueIdentifier } | null;
  }) {
    const { active, over } = event;
    if (over && active.id !== over.id) {
      const oldIndex = data.findIndex((item) => item.id === active.id);
      const newIndex = data.findIndex((item) => item.id === over.id);
      const newData = [...data];
      newData.splice(newIndex, 0, newData.splice(oldIndex, 1)[0]);
      onRearrange(newData.map((f) => f.data));
      setData(newData);
    }
  }

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
    >
      <SortableContext
        items={data.map((item) => item.id)}
        strategy={rectSortingStrategy}
      >
        {data.map((item) => (
          <SortableItem
            key={item.id}
            data={item.data}
            id={item.id}
            render={render}
          />
        ))}
      </SortableContext>
    </DndContext>
  );
};

export default Sortable;
