import * as yup from 'yup';

export default {
  COLORS: yup.array().of(
    yup.object().shape({
      colorId: yup.string().required(),
      showVariants: yup.boolean(),
    })
  ),
  CONTENT: yup.object().shape({
    isHidden: yup.boolean(),
    data: yup.array().of(
      yup.object().shape({
        id: yup.string(),
        text: yup.string(),
        textFile: yup.object().shape({
          library: yup.string(),
          value: yup.string(),
        }),
        assets: yup.array().of(
          yup.object().shape({
            type: yup
              .mixed()
              .oneOf(['collection', 'file', 'custom', 'board', 'iframe']),
            library: yup.string(),
            data: yup.object(),
            action: yup.mixed().oneOf(['download', 'link']),
            value: yup.string(),
            label: yup.string(),
            preferences: yup.object().shape({
              fullWidth: yup.boolean(),
            }),
          })
        ),
        downloads: yup.array().of(
          yup.object().shape({
            type: yup.mixed().oneOf(['collection', 'file', 'custom', 'board']),
            library: yup
              .mixed()
              .oneOf(['media', 'documents', 'brandassets', '']),
            value: yup.string(),
            label: yup.string(),
          })
        ),
        links: yup.array().of(
          yup.object().shape({
            type: yup
              .mixed()
              .oneOf(['collection', 'file', 'custom', 'email', 'board']),
            library: yup
              .mixed()
              .oneOf(['media', 'documents', 'brandassets', '']),
            value: yup.string(),
            label: yup.string(),
          })
        ),
      })
    ),
  }),
  FILE: yup.object().nullable().shape({
    library: yup.string(),
    fileId: yup.string(),
    variantId: yup.string().optional(),
  }),
  IMAGE_TITLE_TEXT_BLOCKS: yup.array().of(
    yup.object().shape({
      blockId: yup.string().required(),
      file: yup
        .object()
        .shape({
          library: yup.string(),
          fileId: yup.string(),
          variantId: yup.string(),
        })
        .nullable(),
      title: yup.string().optional(),
      text: yup.string().optional(),
    })
  ),
  FILES: yup.array().of(
    yup.object().shape({
      library: yup.string().required(),
      fileId: yup.string().required(),
      variantId: yup.string().optional(),
    })
  ),
  COLLECTION: yup.object().shape({
    library: yup.string().required(),
    collectionId: yup.string().required(),
    preferences: yup.object().shape({
      thumbnailSize: yup.mixed().oneOf(['medium', 'huge']),
    }),
  }),
  BOARDS: yup.array().of(
    yup.object().shape({
      boardId: yup.string().required(),
    })
  ),
  COLLECTIONS: yup.array().of(
    yup.object().shape({
      library: yup.string().required(),
      collectionId: yup.string().required(),
    })
  ),
  EMBED_VIDEO: yup.object().shape({
    provider: yup.string().oneOf(['youtube', 'vimeo', 'custom']),
    url: yup.string().required(),
  }),
  LINK: yup.object().shape({
    label: yup.string(),
    url: yup.string(),
  }),
  TITLE: yup.string(),
  TEXT: yup.string(),
};
