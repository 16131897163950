import React, { useEffect, useRef } from 'react';
import Gallery from 'react-photo-gallery';
import './style.scss';
import { BottomScrollListener } from 'react-bottom-scroll-listener';
import { Loader } from 'elements_v2';
import useIntersectionObserver from 'hooks/useIntersection';
import { File } from './File';
import Image from './Image';

function ContributionsGridV2(props) {
  const [images, setImages] = React.useReducer((state, action) => {
    return [...action];
  }, []);
  const [targetRowHeight, setTargetRowHeight] = React.useState(
    props.targetRowHeight || 150,
  );

  const blockRef = useRef();
  const isInView = useIntersectionObserver(blockRef, {
    rootMargin: '100px',
  });

  useEffect(() => {
    if (isInView && props.loadMoreSection) {
      props.onBottom();
    }
  }, [isInView, images, props.loadMoreSection]);

  React.useEffect(() => {
    if (props.gridSize && !props.targetRowHeight) {
      if (props.gridSize === 'small') {
        setTargetRowHeight(80);
      }
      if (props.gridSize === 'medium') {
        setTargetRowHeight(200);
      }
      if (props.gridSize === 'large') {
        setTargetRowHeight(350);
      }
    } else if (props.targetRowHeight) {
      setTargetRowHeight(props.targetRowHeight);
    }
  }, [props.gridSize, props.targetRowHeight]);

  const fileGridClassName = ['FileGrid'];
  if (props.gridSize === 'small') {
    fileGridClassName.push('FileGrid--small');
  }
  if (props.gridSize === 'medium') {
    fileGridClassName.push('FileGrid--medium');
  }
  if (props.gridSize === 'large') {
    fileGridClassName.push('FileGrid--large');
  }

  const imageRenderer = React.useCallback(
    (imageProps) => {
      const { index, left, top, key, photo } = imageProps;
      const image = (ref, prefs) => (
        <Image
          key={key}
          ref={ref}
          margin="2px"
          processing={photo.data?.processing || photo.processing}
          processingMessage={photo.processingMessage || 'Processing file...'}
          isSelected={photo.isSelected}
          injectThumbnail={prefs?.injectThumbnail}
          isLoading={prefs?.isLoading}
          loadingMessage={prefs?.loadingMessage}
          customLoader={prefs?.customLoader}
          tryAgain={photo.data?.tryAgain}
          downloadWrapper={props.downloadWrapper}
          index={index}
          photo={{ ...photo, targetRowHeight }}
          left={left}
          top={top}
          gridSize={props.gridSize}
          draggable={props.draggable}
          fileType={props.fileType}
          onClick={(event) => photo?.onClick(event, photo.data)}
          context={prefs?.context || props.context}
          sticky={props.sticky}
          fileToolbar={props.fileToolbar}
          detailed={props.detailed}
          disabledVariants={props.disabledVariants}
        />
      );

      if (props.onRender) {
        return props.onRender(image, imageProps);
      }
      return image;
    },
    [props.images, props.detailed],
  );

  React.useEffect(() => {
    if (props.images) {
      setImages(props.images);
    }
  }, [props.images]);

  const LoadingFiles = (
    <Loader active style={{ margin: '10px auto' }}>
      Loading files...
    </Loader>
  );

  const onRender = props.onRender || ((render) => render);
  if (props.detailed) {
    return (
      <>
        <div
          className={fileGridClassName.join(' ')}
          style={{
            ...(props.style || {}),
          }}
        >
          {props.images.map((file) =>
            onRender(
              (ref, prefs) => (
                <File
                  file={file}
                  key={file?.id}
                  ref={ref}
                  isLoading={prefs?.isLoading}
                  loadingMessage={prefs?.loadingMessage}
                  customLoader={prefs?.customLoader}
                  injectThumbnail={prefs?.injectThumbnail}
                  processing={file?.data?.processing}
                  processingMessage={
                    file?.data?.processingMessage || 'Processing file...'
                  }
                  forceFileTypeLabel={props.forceFileTypeLabel}
                  fileType={props.fileType}
                  onClick={(event) => file.onClick(event, file?.data)}
                  draggable={props.draggable}
                  gridSize={props.gridSize}
                  context={prefs?.context || props.context}
                  fileToolbar={props.fileToolbar}
                  detailed={props.detailed}
                  disabledVariants={props.disabledVariants}
                  thumbnailOverlay={
                    props.thumbnailOverlay
                      ? props.thumbnailOverlay({ photo: file })
                      : null
                  }
                />
              ),
              {
                photo: file,
              },
            ),
          )}
          {props.isLoading ? (
            props.images?.length ? (
              LoadingFiles
            ) : (
              <div style={{ width: '100%' }} />
            )
          ) : (
            ''
          )}
        </div>
        {props.loadMoreSection && (
          <div
            ref={blockRef}
            style={{ width: '100%', height: 5, background: 'transparent' }}
          />
        )}
      </>
    );
  }

  return (
    <>
      <div
        className={fileGridClassName.join(' ')}
        style={{
          ...(props.style || {}),
        }}
      >
        {props.images.map((file) =>
          onRender(
            (ref, prefs) => (
              <File
                file={file}
                key={file?.id}
                ref={ref}
                isLoading={prefs?.isLoading}
                loadingMessage={prefs?.loadingMessage}
                customLoader={prefs?.customLoader}
                injectThumbnail={prefs?.injectThumbnail}
                processing={file?.data?.processing}
                processingMessage={
                  file?.data?.processingMessage || 'Processing file...'
                }
                forceFileTypeLabel={props.forceFileTypeLabel}
                fileType={props.fileType}
                onClick={(event) => file.onClick(event, file?.data)}
                draggable={props.draggable}
                gridSize={props.gridSize}
                context={prefs?.context || props.context}
                fileToolbar={props.fileToolbar}
                detailed={props.detailed}
                disabledVariants={props.disabledVariants}
                thumbnailOverlay={
                  props.thumbnailOverlay
                    ? props.thumbnailOverlay({ photo: file })
                    : null
                }
              />
            ),
            {
              photo: file,
            },
          ),
        )}
        {props.isLoading ? (
          props.images?.length ? (
            LoadingFiles
          ) : (
            <div style={{ width: '100%' }} />
          )
        ) : (
          ''
        )}
        {props.loadMoreSection && (
          <div
            ref={blockRef}
            style={{ width: '100%', height: 5, background: 'transparent' }}
          />
        )}
      </div>
    </>
  );
}

export default ContributionsGridV2;
