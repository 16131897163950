import { Box, Card, Group } from '@mantine/core';
import { useAssetActions } from '@pixi/components/AssetActions/useAssetActions';
import PixiButton from '@pixi/elements/Button';
import PixiDropdown from '@pixi/elements/Dropdown';
import PixiIcon from '@pixi/elements/Icon';
import PixiText from '@pixi/elements/Text';
import { useTextContext } from 'hooks';
import useParsedFile from 'hooks/files/useParsedFile';
import useStorageUrl from 'hooks/files/useStorageUrl';
import useContextFromFile from 'hooks/useContextFromFile';
import { downloadUrl } from 'utils';
import { inOffice } from 'utils/platform';

export const AssetPreviewSlides = ({
  file,
}: {
  file: Pickit.FileInterface;
}) => {
  const context = useContextFromFile(file);
  const Text: any = useTextContext();
  const { generateUrl } = useStorageUrl(context);
  const metadata: Pickit.FileInterface = useParsedFile({ context, file });
  const { availability, api } = useAssetActions({
    file: metadata,
  });

  return (
    <>
      <Group w="100%" h="100%" wrap="nowrap" gap={0} align="stretch">
        <Box w="100%" h="100%" pos="relative">
          <Group w="100%" justify="center">
            {metadata?.file?.slides?.map((slide, key) => (
              <Card w="100%" maw={350} key={slide.id} shadow="xs" withBorder>
                <Card.Section>
                  <img
                    alt={slide.name}
                    src={generateUrl(slide.previews?.[0]?.url)}
                  />
                </Card.Section>
                <Group justify="space-between" mt="md" mb="xs">
                  <PixiText fw={500}>Slide {key + 1}</PixiText>
                  <PixiIcon name="note-sticky" style={{ opacity: 0.3 }} />
                </Group>
                <PixiButton
                  fullWidth
                  onClick={async () => {
                    availability.insertSlide
                      ? await api.insertSlide(slide)
                      : downloadUrl(generateUrl(slide.url), slide.name);
                  }}
                  leftSection={
                    <PixiIcon
                      name={
                        availability.insertSlide
                          ? 'arrow-left'
                          : 'cloud-arrow-down'
                      }
                    />
                  }
                  dropdown={
                    inOffice() && (
                      <PixiDropdown.Item
                        onClick={async () => {
                          await api.insertSlide(slide, {
                            formatting: 'UseDestinationTheme',
                          });
                        }}
                      >
                        {Text.get(
                          'documents/insert_powerpoint_slide_with_theme',
                        )}
                      </PixiDropdown.Item>
                    )
                  }
                >
                  {availability.insertSlide ? 'Insert' : 'Download'}
                </PixiButton>
              </Card>
            ))}
          </Group>
        </Box>
      </Group>
    </>
  );
};
