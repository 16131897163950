import {
  Box,
  Combobox,
  Input,
  InputBase,
  ScrollArea,
  TextInput,
  useCombobox,
} from '@mantine/core';
import System from '@pixi/System';
import PixiDropdown, { PixiDropdownProps } from '@pixi/elements/Dropdown';
import PixiIcon from '@pixi/elements/Icon';
import { useBoards } from 'contexts/Providers/BoardsProvider';
import { useUserContext } from 'hooks';
import React, { ReactElement, useEffect, useState } from 'react';

export default function SelectBoard({
  target,
  onBoardClick,
  onSearch,
  value,
  type,
  onlyDropdown,
  asInput,
  ...rest
}: {
  target?: React.ReactElement;
  value?: string;
  type?: 'sub-guide';
  onSearch?: (query: string) => void | Promise<void>;
  onlyDropdown?: boolean;
  onBoardClick: (folder: Pickit.BoardInterface) => void | Promise<void>;
  asInput?: boolean;
} & Partial<PixiDropdownProps>) {
  const Boards = useBoards();
  const [query, setQuery] = useState('');
  const [isOpen, setIsOpen] = useState(!!onlyDropdown);
  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
  });
  const [search, setSearch] = useState('');

  useEffect(() => {
    if (isOpen) {
      Boards.api.getBoards();
    }
  }, [isOpen]);

  const name = type === 'sub-guide' ? 'Sub-guide' : 'Board';
  const namePlural = type === 'sub-guide' ? 'Sub-guides' : 'Boards';

  const boards = Boards.data.filter((b: Pickit.BoardInterface) => {
    if (type === 'sub-guide') {
      return b.type === 'brand' || b.type === 'entrypoint';
    }
    return b.type === 'standard';
  }) as Pickit.BoardInterface[];

  const items = (
    <>
      <PixiDropdown.Label>Add to folder</PixiDropdown.Label>
      <Box p="xs">
        <TextInput
          placeholder={`Search ${namePlural.toLowerCase()}`}
          value={query}
          onChange={(event) => {
            setQuery(event.currentTarget.value);
          }}
        />
      </Box>
      {boards.map((f) => (
        <PixiDropdown.Item
          icon="layer-group"
          key={f._id}
          onClick={async () => {
            await onBoardClick(f);
          }}
          disabled={value?.includes(f._id)}
          color={value?.includes(f._id) ? 'gray' : undefined}
          rightSection={
            value?.includes(f._id) ? <PixiIcon name="check" /> : undefined
          }
        >
          {f.name}
        </PixiDropdown.Item>
      ))}
    </>
  );
  if (onlyDropdown) {
    return items;
  }
  if (asInput) {
    return (
      <PixiDropdown
        withinPortal
        alwaysCloseOnClickOutside
        height={300}
        target={
          <InputBase
            label="Select board"
            component="button"
            type="button"
            pointer
            rightSection={<Combobox.Chevron />}
            rightSectionPointerEvents="none"
            onClick={() => combobox.toggleDropdown()}
          >
            {Boards?.data?.find((b: Pickit.BoardInterface) => b._id === value)
              ?.name || (
              <Input.Placeholder>Select {name.toLowerCase()}</Input.Placeholder>
            )}
          </InputBase>
        }
      >
        {({ setIsOpen }) => {
          return (
            <>
              <PixiDropdown.Label>
                Select {namePlural.toLowerCase()}
              </PixiDropdown.Label>
              <Box p="xs">
                <TextInput
                  placeholder={`Search ${namePlural.toLowerCase()}`}
                  value={search}
                  size="xs"
                  onChange={(event) => {
                    setSearch(event.currentTarget.value);
                  }}
                />
              </Box>
              <PixiDropdown.Divider />
              {boards
                .filter((b) => {
                  const query = search?.toLowerCase();
                  if (!query) {
                    return true;
                  }
                  return b.name?.toLowerCase()?.includes(query);
                })
                .map((b) => (
                  <PixiDropdown.Item
                    value={b?._id}
                    key={b._id}
                    onClick={() => {
                      onBoardClick(b);
                      setIsOpen(false);
                    }}
                  >
                    {b.name}
                  </PixiDropdown.Item>
                ))}
            </>
          );
        }}
      </PixiDropdown>
    );
  }
  return (
    <PixiDropdown
      width={300}
      height={350}
      onOpen={() => {
        setIsOpen(true);
      }}
      onClose={() => {
        setIsOpen(false);
      }}
      isFrozen={!!query}
      target={target as ReactElement}
      {...rest}
    >
      {items}
    </PixiDropdown>
  );
}
