import { Icon } from 'elements_v2';
import React from 'react';
import './style.scss';

function Header(props) {
  return (
    <div className="Pixi Pixi__Card__header">
      <div className="Pixi__Card__header__title">{props.children}</div>
      {props.onClose ? (
        <div className="Pixi__Card__header__close">
          <Icon bubble button name="X" />
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}
function Image(props) {
  const className = ['Pixi Pixi__Card__image'];

  if (props.centered) {
    className.push('Pixi__Card__image--centered');
  }
  return (
    <div className={className.join(' ')} style={props.style}>
      {props.children}
    </div>
  );
}
function Text(props) {
  return <div className="Pixi Pixi__Card__text">{props.children}</div>;
}
function Actions(props) {
  return <div className="Pixi Pixi__Card__actions">{props.children}</div>;
}

const Card = React.forwardRef((props, ref) => {
  return (
    <div
      className="Pixi Pixi__Card"
      ref={ref}
      style={props.style}
      onClick={props.onClick}
    >
      {props.toolbar && (
        <div className="Pixi Pixi__Card__toolbar">{props.toolbar}</div>
      )}
      <div className="Pixi__Card__content" style={props.contentStyle}>
        {props.children}
      </div>
    </div>
  );
});

Card.Header = Header;
Card.Image = Image;
Card.Text = Text;
Card.Actions = Actions;

export default Card;
