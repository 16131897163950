import React from 'react';
import './style.scss';
import { Icon, Popover } from 'elements_v2';
import Tooltip from 'rc-tooltip';

function Title(props) {
  return <h1 className="Pixi__Tooltip-Walkthrough__title">{props.children}</h1>;
}

function Description(props) {
  return (
    <p className="Pixi__Tooltip-Walkthrough__description">{props.children}</p>
  );
}

function Information(props) {
  return (
    <div className="Pixi__Tooltip-Walkthrough__information">
      {props.children}
    </div>
  );
}

function List(props) {
  return (
    <div className="Pixi__Tooltip-Walkthrough__list">
      <h3>{props.title}</h3>
      <ul className="Pixi__Tooltip-Walkthrough__list__items">
        {Object.values(props.steps).map((step) => (
          <li
            key={step.title}
            className={`Pixi__Tooltip-Walkthrough__list__items__item ${
              step.active &&
              'Pixi__Tooltip-Walkthrough__list__items__item--active'
            } ${
              step.completed &&
              'Pixi__Tooltip-Walkthrough__list__items__item--completed'
            }`}
          >
            <Icon name={step.completed ? 'CheckCircleFill' : 'Circle'} />
            {step.title}
          </li>
        ))}
      </ul>
    </div>
  );
}

function Actions(props) {
  return (
    <div className="Pixi__Tooltip-Walkthrough__actions">{props.children}</div>
  );
}

Walkthrough.Title = Title;
Walkthrough.Description = Description;
Walkthrough.Actions = Actions;
Walkthrough.List = List;
Walkthrough.Information = Information;

export default function Walkthrough(props) {
  const [isOpen, setIsOpen] = React.useState(false);
  const className = ['Pixi__Tooltip--Arrow Pixi__Tooltip-Walkthrough'];

  React.useEffect(() => {
    if (props.isOpen) {
      const timeout = setTimeout(() => {
        setIsOpen(true);
      }, props.delay ?? 2000);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [props.isOpen]);

  return (
    <>
      <div
        className={`Pixi__Tooltip-Walkthrough__wrapper ${
          isOpen ? 'Pixi__Tooltip-Walkthrough__wrapper--open' : ''
        }`}
      >
        {isOpen && props.withDimmer ? (
          <div className="Pixi__Tooltip-Walkthrough__dimmer" />
        ) : (
          ''
        )}
        <Tooltip
          // overlayStyle={props.triggerStyle}
          overlayClassName="Pixi__Tooltip-Walkthrough__box"
          // placement={props.position || 'bottom'}
          // trigger={['hover']}
          visible={isOpen}
          arrowContent={<div id="arrow" />}
          overlay={
            <div>
              <div className="Pixi Pixi__Tooltip-Walkthrough__content">
                {typeof props.children === 'function'
                  ? props.children(isOpen, setIsOpen)
                  : props.children}
              </div>
            </div>
          }
        >
          <div className="Pixi__Tooltip-Walkthrough__trigger">
            {props.trigger}
          </div>
        </Tooltip>
        {/* </Tooltip>
        <Popover
          isOpen={isOpen}
          position={props.position || 'bottom-start'}
          boxClassName={className.join(' ')}
          triggerStyle={props.triggerStyle}
          triggerClassName={`${props.className} ` + `Pixi__Tooltip-Walkthrough__trigger`}
          forwardRef
          locked
          triggerRender={props.triggerRender}
          trigger={() => props.trigger}>
          <>
            <div id="arrow" />
            <div className="Pixi Pixi__Tooltip-Walkthrough__content">
              {typeof props.children === 'function' ? props.children(isOpen, setIsOpen) : props.children}
            </div>
          </>
        </Popover> */}
      </div>
    </>
  );
}
