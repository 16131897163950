import React, { useState } from 'react';
import { useUserContext } from 'hooks';
import { DataContext } from 'contexts';

/**
 * This context is to get and keep data during a whole session.
 */
export function DataProvider(props) {
  const User = useUserContext();
  const saveData = (state, action) => {
    if (action.isLoading) {
      return {
        ...state,
        isLoading: action.isLoading,
      };
    }
    if (action.isFinished !== undefined) {
      state.isFinished = action.isFinished;
    }
    if (action.isLoading !== undefined) {
      state.isLoading = action.isLoading;
    }
    if (action.page_nr !== undefined) {
      state.page_nr = action.page_nr;
    }
    return {
      ...state,
      data: !action.merge ? action.data : [...state.data, ...action.data],
    };
  };
  const defaultData = {
    page_nr: 0,
    isLoading: false,
    data: [],
  };

  /**
   * User Collections
   */
  const [userCollections, setUserCollections] = React.useReducer(
    saveData,
    defaultData,
  );
  const getUserCollections = async (clearCache) => {
    if (
      userCollections.isLoading ||
      (!clearCache && userCollections.isFinished)
    ) {
      return false;
    }
    setUserCollections({
      isLoading: true,
    });
    const data = await User.request.user.getUserCollections(
      User.djangoProfile.id,
      clearCache ? 1 : userCollections.page_nr + 1,
    );
    setUserCollections({
      clearCache,
      page_nr: clearCache ? 1 : userCollections.page_nr + 1,
      merge: !clearCache,
      data: data.collections,
      isLoading: false,
      isFinished: clearCache ? false : data.collections < 50,
    });
  };

  /**
   * User Favorites
   */
  const [userFavorites, setUserFavorites] = React.useReducer(
    saveData,
    defaultData,
  );
  const addUserFavorite = async (image) => {
    setUserFavorites({
      data: [image],
      merge: true,
    });
  };
  const removeUserFavorite = async (slug) => {
    setUserFavorites({
      data: userFavorites.data.filter((image) => image.slug !== slug),
      merge: false,
    });
  };
  const getUserFavorites = async (clearCache) => {
    if (userFavorites.isLoading || (!clearCache && userFavorites.isFinished)) {
      return false;
    }
    setUserFavorites({
      isLoading: true,
    });
    const data = await User.request.user.getAllFavorites();
    setUserFavorites({
      data,
      merge: false,
      page_nr: null,
      isLoading: false,
      isFinished: true,
    });
  };

  /**
   * Allows to set the current Collection to prevent prop drilling
   */
  const [currentCollection, setCurrentCollection] = useState(undefined);

  return (
    <DataContext.Provider
      value={{
        getUserCollections,
        userCollections,

        getUserFavorites,
        userFavorites,
        addUserFavorite,
        removeUserFavorite,

        currentCollection,
        setCurrentCollection,
      }}
    >
      {props.children}
    </DataContext.Provider>
  );
}
