import { Icon } from 'elements_v2';
import React from 'react';
import tinycolor from 'tinycolor2';
import './style.scss';

export default function Message(props) {
  let { style } = props;
  const className = ['Pixi', 'Pixi__Message'];

  if (props.error) {
    className.push('Pixi__Message--error');
  }

  if (props.info) {
    className.push('Pixi__Message--info');
  }

  if (props.size) {
    className.push(`Pixi__Message--${props.size}`);
  }

  if (props.tutorial) {
    className.push('Pixi__Message--tutorial');
  }

  if (props.inverted) {
    className.push('Pixi__Message--inverted');
  }
  if (props.transparent) {
    className.push('Pixi__Message--transparent');
  }

  if (props.highlight) {
    if (!style) {
      style = {};
    }
    style.background = tinycolor(props.highlight).lighten(40);
  }

  return (
    <div className={className.join(' ')} style={style}>
      {props.icon && (
        <div className="Pixi__Message__Icon">
          {typeof props.icon === 'string' ? (
            <Icon name={props.icon} />
          ) : (
            props.icon
          )}
        </div>
      )}
      <div className="Pixi__Message__text">
        {props.message || props.children}
      </div>
      {props.onClose ? (
        <div className="Pixi__Message__Close">
          <Icon name="X" bubble button onClick={props.onClose} />
        </div>
      ) : (
        ''
      )}
    </div>
  );
}
