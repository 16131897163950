import { getConfigStore } from '@pixi/store';
import { AppContext } from 'contexts/App';
import { PopUp } from 'elements';
import { Popup } from 'elements_v2';
import { useUserContext } from 'hooks';
import useDownloadBag from 'hooks/files/useDownloadBag';
import useCloudStorage from 'hooks/useCloudStorage';
import { useRouter } from 'hooks/useRouter';
import useUserStatus from 'hooks/useUserStatus';
import React, {
  useContext,
  useReducer,
  useRef,
  useState,
  useEffect,
} from 'react';
import tinycolor from 'tinycolor2';
import { uniqueArray } from 'utils';
import localStorageWrapper from 'utils/localStorageWrapper';
import { inOffice, isAddin, isNative } from 'utils/platform';

const persistenceDoNotNavigateFrom_BASE = ['set/space'];
const persistenceDoNotNavigateTo_BASE = ['set/space', '#/brand'];

export const useAppContext = () => useContext(AppContext);

export function AppProvider(props) {
  const User = useUserContext();
  const Router = useRouter();
  const checkingPersistence = useRef(false);
  const [isDevToolsOpen, setIsDevToolsOpen] = useState(false);
  const [isStandaloneView, setIsStandaloneView] = React.useState(false);
  const [isManageMenuMinified, setIsManageMenuMinified] = React.useState(false);
  const [isIgnoreLIV, setIsIgnoreLIV] = React.useState(false);
  const [isMainNavHidden, setIsMainNavHidden] = React.useState(false);
  const [isHeaderHidden, setIsHeaderHidden] = React.useState(false);
  const [headerBackground, setHeaderBackground] = React.useState(false);
  const [isDarkMode, setIsDarkMode] = React.useState(
    localStorageWrapper.getItem('userDarkMode') === '1',
  );
  const [forcedTheme, setForcedTheme] = React.useState(false);
  const [isContainerExpanded, setIsContainerExpanded] = React.useState(false);
  const [isNotificationCenterOpen, setIsNotificationCenterOpen] =
    useState(false);
  const [isManage, setIsManage] = React.useState(false);
  const [isManageCollectionsHidden, setIsManageCollectionsHidden] =
    React.useState(User.cloudStorage.preferences?.curationHidden);

  const [editCollection, setEditCollection] = React.useState(false);
  const [editFile, setEditFile] = React.useState(false);
  const [editBoard, setEditBoard] = React.useState(false);
  const [editFileVariant, setEditFileVariant] = useState(false);
  const [createFileVariant, setCreateFileVariant] = useState(false);
  const [persitenceNavigatedTo, setPersitenceNavigatedTo] = useState(undefined);
  const [shouldPersistenceNavigate, setShouldPersistenceNavigate] =
    useState(false);
  const [previewFile, setPreviewFile] = React.useState(false);
  const [users, setUsers] = useReducer(
    (state, action) => uniqueArray([...state, ...action], 'id'),
    [],
  );
  const [userIds, setUserIds] = useReducer(
    (state, action) => [...state, ...action],
    [],
  );
  const gettingUsers = useRef(false);

  useEffect(() => {
    getConfigStore('APP_VIEW').setValue(
      'colorScheme',
      isDarkMode && !isManage ? 'dark' : 'light',
    );
  }, [isDarkMode, isManage]);

  async function getUsersByArrayId(ids) {
    try {
      gettingUsers.current = true;
      const notStateUsers = ids
        .filter((id) => id && !userIds.includes(parseInt(id)))
        .map((i) => parseInt(i));
      if (notStateUsers?.length) {
        setUserIds(notStateUsers);
        let newUsers = [];
        try {
          newUsers = await User.request.proxy.getUsersV2({
            search: `${ids.filter((i) => !!i).join(',')},`,
            page_limit: 999,
            include_api_users: 1,
          });
        } catch (e) {
          console.error(e);
        }
        let newUsersExternal = [];
        try {
          newUsersExternal = await User.request.proxy.getUsersV2({
            search: `${ids.filter((i) => !!i).join(',')},`,
            page_limit: 999,
            role: 'external',
          });
        } catch (e) {
          console.error(e);
        }
        setUsers([...(newUsers.data ?? []), ...(newUsersExternal.data ?? [])]);
      }
    } catch (e) {
      console.error(e);
    }
    gettingUsers.current = false;
  }
  const downloadBag = useDownloadBag();

  const isDarkModeSetByUser = !!localStorageWrapper.getItem('userDarkMode');

  function forceDarkMode(isDarkMode) {
    setIsDarkMode(isDarkMode);
    setForcedTheme(isDarkMode ? 'dark' : false);
    localStorageWrapper.setItem('userDarkMode', isDarkMode ? '1' : '0');
  }

  function detectDarkMode(e) {
    const newColorScheme = e.matches ? 'dark' : 'light';
    document.body.setAttribute('system-color-scheme', newColorScheme);
    setIsDarkMode(newColorScheme === 'dark');
  }

  function checkOfficeDarkMode() {
    if (!inOffice()) {
      return false;
    }
    const bodyBackgroundColor =
      window.Office?.context?.officeTheme?.bodyBackgroundColor;
    if (bodyBackgroundColor) {
      setIsDarkMode(tinycolor(bodyBackgroundColor).isDark());
      return true;
    }
    return false;
  }

  function setAutoDarkMode() {
    if (isDarkModeSetByUser) {
      return false;
    }
    if (checkOfficeDarkMode()) {
      return false;
    }
    if (
      window?.matchMedia &&
      window?.matchMedia('(prefers-color-scheme: dark)')?.matches
    ) {
      document.body.setAttribute('system-color-scheme', 'dark');
      setIsDarkMode(true);
    } else {
      document.body.setAttribute('system-color-scheme', 'light');
      setIsDarkMode(false);
    }
    if (window.matchMedia?.('(prefers-color-scheme: dark)')?.addEventListener) {
      window
        .matchMedia('(prefers-color-scheme: dark)')
        ?.addEventListener?.('change', detectDarkMode);

      return () => {
        window
          .matchMedia('(prefers-color-scheme: dark)')
          ?.removeEventListener?.('change', detectDarkMode);
      };
    }
  }

  React.useEffect(() => {
    window
      .matchMedia('(prefers-color-scheme: dark)')
      ?.addEventListener?.('change', detectDarkMode);

    return () => {
      window
        .matchMedia('(prefers-color-scheme: dark)')
        ?.removeEventListener?.('change', detectDarkMode);
    };
  }, []);

  React.useEffect(() => {
    if (isNative()) {
      document.body.classList.add('Pickit--isNative');
      document.querySelector('html').classList.add('Pickit--isNative');
    }
    document
      .querySelector('body')
      .setAttribute('data-os', window?.navigator?.userAgentData?.platform);
  }, []);

  React.useEffect(() => {
    setAutoDarkMode();
  }, [window.Office?.context?.officeTheme?.bodyBackgroundColor]);

  React.useEffect(() => {
    checkDarkMode();
  }, [isDarkMode, forcedTheme]);

  function checkDarkMode() {
    if (forcedTheme) {
      if (
        document.body.classList.contains('Pickit--isDarkMode') &&
        forcedTheme === 'light'
      ) {
        document.body.classList.remove('Pickit--isDarkMode');
        setIsDarkMode(false);
      }
      if (
        !document.body.classList.contains('Pickit--isDarkMode') &&
        forcedTheme === 'dark'
      ) {
        document.body.classList.add('Pickit--isDarkMode');
        setIsDarkMode(true);
      }
      return false;
    }
    if (
      (isDarkMode || forcedTheme === 'dark') &&
      !document.body.classList.contains('Pickit--isDarkMode')
    ) {
      document.body.classList.add('Pickit--isDarkMode');
    }
    if (
      (!isDarkMode || forcedTheme === 'light') &&
      document.body.classList.contains('Pickit--isDarkMode')
    ) {
      document.body.classList.remove('Pickit--isDarkMode');
    }
    if (!forcedTheme) {
      setAutoDarkMode();
    }
  }

  const values = {
    isStandaloneView,
    setIsStandaloneView,

    isManageMenuMinified,
    setIsManageMenuMinified,

    isIgnoreLIV,
    setIsIgnoreLIV,

    isHeaderHidden,
    setIsHeaderHidden,

    headerBackground,
    setHeaderBackground,

    isDarkMode,
    setIsDarkMode,

    isContainerExpanded,
    setIsContainerExpanded,

    checkDarkMode,
    forceDarkMode,

    forcedTheme,
    setForcedTheme,

    setIsMainNavHidden,
    isMainNavHidden,

    setIsDevToolsOpen,
    isDevToolsOpen,

    isNotificationCenterOpen,
    setIsNotificationCenterOpen,

    isManage,
    setIsManage,

    isDarkModeSetByUser,

    getUsersByArrayId,
    users,

    setAutoDarkMode,

    toggleAssetPreview: (file) => {
      setPreviewFile(file);
    },
    previewFile,
    toggleEdit: (type, data) => {
      if (type === 'file') {
        setEditFile(data);
      }
      if (type === 'collection') {
        setEditCollection(data);
      }
      if (type === 'board') {
        setEditBoard(data);
      }
    },
    editFile,
    editBoard,
    editCollection,

    toggleCreateFileVariant: (file) => {
      if (!file) {
        setCreateFileVariant(null);
      }
      setCreateFileVariant({ file });
    },
    createFileVariant,
    editFileVariant,
    toggleEditFileVariant: (file, fileVariant) => {
      setEditFileVariant({
        file,
        fileVariant,
      });
    },

    downloadBag,

    setIsManageCollectionsHidden: (val) => {
      setIsManageCollectionsHidden(val);
      User.setCloudStorage('preferences.curationHidden', val);
    },
    isManageCollectionsHidden,
  };
  return (
    <AppContext.Provider value={values}>{props.children}</AppContext.Provider>
  );
}
