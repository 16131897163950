import { Button, Flex, Icon, Input, Popup } from 'elements_v2';
import React, { useEffect, useReducer, useState } from 'react';
import Color from 'color';
import Form from 'elements_v2/Form';
import List from 'elements_v2/List';
import { randomString, reorder } from 'utils';
import ntc from './NameThatColor';

export const ColorVariantsHeader = (
  <>
    <h1>Create color variants</h1>
    <p>Easy way to create different shades or tints of your color.</p>
  </>
);

export default function ColorForm(props) {
  const [name, setName] = useState(props.metadata?.name || '');
  const [isCustomName, setIsCustomName] = useState(
    !!props.metadata?._id || props.isEditing,
  );
  const [value, setValue] = useState(props.metadata?.data?.value?.color || '');
  const [pantone, setPantone] = useState(
    props.metadata?.data?.value?.pantone || '',
  );
  const [CMYK, setCMYK] = useState(props.metadata?.data?.value?.cmyk || '');
  const [variants, setVariants] = useReducer(
    (state, action) => [...action],
    props.metadata?.data?.value?.variants || [],
  );
  const [isSaving, setIsSaving] = useState(false);
  const [editShade, setEditShade] = useState(false);

  useEffect(() => {
    if (props.metadata?.data?.value?.color) {
      setValue(props.metadata?.data?.value?.color);
    }
  }, [props.metadata?.data?.value?.color]);

  useEffect(() => {
    if (!variants.includes('main')) {
      setVariants([...variants, 'main']);
    }
  }, [variants]);

  useEffect(() => {
    if (props.onlyVariants && props.onChange) {
      props.onChange(variants);
    }
  }, [props.onlyVariants, variants]);

  useEffect(() => {
    if (props.metadata?.name) {
      if (props.metadata?._id || props.isEditing) {
        setIsCustomName(true);
      }
      setName(props.metadata?.name);
      setValue(props.metadata?.data?.value?.color);
      setCMYK(props.metadata?.data?.value?.cmyk);
      setPantone(props.metadata?.data?.value?.pantone);
      // setVariants(props.metadata?.data?.value?.variants);
    }
  }, [props.metadata]);

  useEffect(() => {
    if (value && !isCustomName) {
      setName(ntc.name(value)?.[1]);
    }
  }, [value]);

  const addColorForm = (
    <Flex>
      <div style={{ width: '100%', marginRight: 15 }}>
        <Input.Field
          inline
          autoFocus
          fullWidth
          openIfEmpty
          isForceOpen
          type="color"
          label="Select color"
          hideBrandAssets
          value={value}
          key={value}
          onChange={(value) => {
            if (value?.length !== 6 && value?.length !== 7) {
              return false;
            }
            setValue(value);
            if (props.onlyForm && props.onChange) {
              props.onChange({
                value: {
                  color: value,
                  pantone,
                  cmyk: CMYK,
                  variants,
                },
                type: 'color',
              });
            }
          }}
        />
      </div>
      <div style={{ width: '100%' }}>
        {value && (
          <Input.Field
            inline
            label="Name"
            value={name}
            onChange={(val) => {
              setIsCustomName(true);
              setName(val);
            }}
          />
        )}
        <Input.Field
          inline
          label="Pantone"
          value={pantone}
          maxlength={30}
          onChange={(pantone) => {
            setIsCustomName(true);
            setPantone(pantone);
            if (props.onlyForm && props.onChange) {
              props.onChange({
                value: {
                  color: value,
                  pantone,
                  cmyk: CMYK,
                  variants,
                },
                type: 'color',
              });
            }
          }}
        />
        <Input.Field
          inline
          label="CMYK"
          value={CMYK}
          maxlength={30}
          onChange={(cmyk) => {
            setIsCustomName(true);
            setCMYK(cmyk);
            if (props.onlyForm && props.onChange) {
              props.onChange({
                value: {
                  color: value,
                  pantone,
                  cmyk,
                  variants,
                },
                type: 'color',
              });
            }
          }}
        />
      </div>
    </Flex>
  );

  const shades = (
    <>
      <ColorForm
        title="Add color shade"
        disableShades
        context={props.context}
        metadata={{
          data: {
            value: editShade.id
              ? editShade
              : {
                  color: value,
                },
          },
        }}
        libraryId="brandassets"
        zIndex={99999}
        isEditing={!!editShade.id}
        isOpen={editShade}
        customSave
        onSave={(name, color) => {
          setEditShade(false);
          if (!color.value.id) {
            color.value.id = randomString(8);
          }
          delete color.value.variants;
          if (editShade.id) {
            setVariants(
              variants.map((variant) =>
                variant.id === color.value.id
                  ? {
                      name,
                      ...color.value,
                    }
                  : variant,
              ),
            );
          } else {
            setVariants([
              ...variants,
              {
                name,
                ...color.value,
              },
            ]);
          }
        }}
        onClose={() => setEditShade(false)}
      />
      <Button
        as="div"
        pixi
        inline
        onClick={() => setEditShade(true)}
        style={{ marginBottom: 20 }}
      >
        <Icon name="Palette2" />
        Add color variant
      </Button>
      <List cards>
        {variants.map((item, index) => (
          <List.Item
            key={item === 'main' ? value : item.color}
            subtitle={item === 'main' ? 'Main color' : item.name}
            title={
              <Flex>
                <div
                  style={{
                    width: '100%',
                    height: 25,
                    borderRadius: 5,
                    background: item === 'main' ? value : item.color,
                    marginRight: 10,
                  }}
                />
                {index !== 0 && (
                  <Icon
                    style={{ flexShrink: 0 }}
                    onClick={() =>
                      setVariants(reorder(variants, index, index - 1))
                    }
                    bubble
                    button
                    name="ChevronUp"
                  />
                )}
                {index + 1 !== variants.length && (
                  <Icon
                    style={{ flexShrink: 0 }}
                    onClick={() =>
                      setVariants(reorder(variants, index, index + 1))
                    }
                    bubble
                    button
                    name="ChevronDown"
                  />
                )}
                {item !== 'main' && (
                  <>
                    <Icon
                      style={{ flexShrink: 0 }}
                      onClick={() =>
                        setVariants(variants.filter((v, key) => key !== index))
                      }
                      bubble
                      button
                      name="Trash"
                    />
                    <Icon
                      style={{ flexShrink: 0 }}
                      onClick={() => setEditShade(item)}
                      bubble
                      button
                      name="Pencil"
                    />
                  </>
                )}
              </Flex>
            }
          />
        ))}
      </List>
    </>
  );

  const colorForm = (
    <Form.Tabs>
      <Form.Tab label="Color">{addColorForm}</Form.Tab>
      <Form.Tab label="Color Variants" header={ColorVariantsHeader}>
        {shades}
      </Form.Tab>
    </Form.Tabs>
  );

  if (props.onlyVariants) {
    return <>{shades}</>;
  }
  if (props.onlyForm) {
    return <>{addColorForm}</>;
  }

  return (
    <Popup
      isOpen={props.isOpen}
      width={750}
      style={{ minHeight: 500 }}
      title={
        props.title || props.metadata?._id || props.isEditing
          ? 'Edit color'
          : 'Add color'
      }
      contentStyle={!props.disableShades ? { padding: 0 } : {}}
      actions={
        <>
          <Button
            inline
            style={{ marginRight: 'auto' }}
            onClick={props.onClose}
          >
            Cancel
          </Button>
          <Button
            inline
            primary
            loading={isSaving}
            disabled={!name || !value}
            onClick={async () => {
              if (!value) {
                return false;
              }
              setIsSaving(true);
              const data = {
                value: {
                  color: value,
                  pantone,
                  cmyk: CMYK,
                  variants,
                },
                type: 'color',
              };
              if (props.customSave) {
                setIsSaving(false);
                return props.onSave(name, {
                  value: {
                    ...(props.metadata?.data?.value || {}),
                    ...data.value,
                  },
                  type: 'color',
                });
              }
              const color = await props.context.saveDocument({
                data,
                name: name || value,
                categories: ['color'],
                _id: props.metadata?._id,
                folders: props.toFolder,
                libraries: [props.libraryId],
                file: {
                  ext: 'color',
                },
              });
              if (props.onSave) {
                props.onSave(color);
              }
              setName('');
              setCMYK('');
              setValue('');
              setPantone('');
              setVariants([]);
              setIsCustomName(false);
              setIsSaving(false);
              props.onClose();
            }}
          >
            <Icon name="Palette2" />{' '}
            {props.metadata?._id || props.isEditing
              ? 'Save changes'
              : 'Add color'}
          </Button>
        </>
      }
      onClose={props.onClose}
    >
      {props.disableShades ? addColorForm : colorForm}
    </Popup>
  );
}
