import React from 'react';
// import "./style.scss";

export default function useSimpleDataStore(defaultStore) {
  const memoizedReducer = React.useCallback((state, actions) => {
    if (!Array.isArray(actions)) {
      actions = [actions];
    }
    for (let i = 0; i < actions.length; i++) {
      const action = actions[i];
      if (action.type === 'replace') {
        return [...action.data];
      }
      if (typeof state === 'undefined') {
        state = [];
      }
      if (action.type === 'parse') {
        state = [...action.func(state)];
      }
      if (action.type === 'merge') {
        if (!action.key) {
          const newArray = [...state, ...action.data];
          state = newArray.filter((item, pos) => {
            return newArray.indexOf(item) === pos;
          });
        } else {
          for (let i = 0; i < action.data.length; i++) {
            const item = action.data[i];
            const existingItem = state.find(
              (row) => row[action.key] === item[action.key]
            );
            const existingItemKey = state.findIndex(
              (row) => row[action.key] === item[action.key]
            );
            if (!existingItem) {
              state = [...state, item];
            } else {
              state[existingItemKey] = {
                ...existingItem,
                ...action.data.find(
                  (t2) => t2[action.key] === existingItem[action.key]
                ),
              };
            }
          }
          state = [...state];
        }
      }
      if (action.type === 'update-or-push') {
        const dataIndex = state.findIndex(
          (doc) => doc?.[action.key] === action.data?.[action.key]
        );
        if (dataIndex < 0) {
          action.type = 'push';
        } else {
          action.type = 'update';
        }
      }
      if (action.type === 'push') {
        if (Array.isArray(action.data)) {
          state = [...state, ...action.data];
        } else {
          state = [...state, action.data];
        }
      }
      if (action.type === 'prepend') {
        if (Array.isArray(action.data)) {
          state = [...action.data, ...state];
        } else {
          state = [action.data, ...state];
        }
      }
      if (action.type === 'delete') {
        if (Array.isArray(action.data)) {
          state = state.filter(
            (file) =>
              !action.data.find((f) => f[action.key] === file[action.key])
          );
        } else {
          state = state.filter(
            (file) => file[action.key] !== action.data[action.key]
          );
        }
      }
      if (action.type === 'update') {
        const data = state.find(
          (doc) => doc[action.key] === action.data[action.key]
        );
        const dataIndex = state.findIndex(
          (doc) => doc[action.key] === action.data[action.key]
        );
        state[dataIndex] = {
          ...(data || {}),
          ...action.data,
        };
        state = [...state];
      }
    }
    return [...state];
  }, []);
  const [data, setData] = React.useReducer(memoizedReducer, defaultStore || []);

  function reset() {
    setData({
      type: 'replace',
      data: defaultStore,
    });
  }

  return {
    setData,
    data,
    reset,
  };
}
