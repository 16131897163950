import { ToastService } from 'elements/Toast';
import { useUserContext } from 'hooks/useUserContext';
import useUserStatus from 'hooks/useUserStatus';
import { useState, useEffect } from 'react';
import { analytics, insert } from 'services';
import { downloadUrl, openInNewTab } from 'utils';
import { getSelectedSlideID } from 'utils/office';
import {
  inAdobeAddon,
  inGoogleDocs,
  inOffice,
  isNative,
  isWordpress,
  supportedAdobeFormats,
} from 'utils/platform';
import { useMediaContext, useDocumentsContext } from 'hooks';
import { isBefore } from 'date-fns';
import localStorageWrapper from 'utils/localStorageWrapper';
import useStorageUrl from './useStorageUrl';

export default function useInsert(props) {
  const [isLoadingImage, setIsLoadingImage] = useState([]);
  const [finishedLoadingImage, setFinishedLoadingImage] = useState([]);
  const User = useUserContext();
  const userStatus = useUserStatus();
  const { generateUrl, getInfo } = useStorageUrl();
  const contexts = {
    media: useMediaContext(),
    documents: useDocumentsContext(),
    brandassets: useMediaContext(),
  };
  useEffect(() => {
    setIsLoadingImage(
      isLoadingImage.filter((i) => !finishedLoadingImage.includes(i)),
    );
    setFinishedLoadingImage([]);
  }, [finishedLoadingImage.length]);

  function getFile(file, preferredSize) {
    const context = contexts[file.type];
    if (!context) {
      return false;
    }
    const licenseExpired =
      file?.license?.expirationDate &&
      isBefore(new Date(file?.license?.expirationDate), new Date());
    if (licenseExpired) {
      ToastService.createToast({
        message: 'The license for this file has expired.',
        error: true,
        delay: 6000,
      });
      return false;
    }
    if (context.type === 'documents') {
      return file.file;
    }
    if (
      file.file.contentType?.includes('video') ||
      file.file.contentType?.includes('audio')
    ) {
      return file.file;
    }
    const { adobeProduct } = window;

    if (
      inAdobeAddon() &&
      supportedAdobeFormats[adobeProduct]?.includes(
        file.file.ext.toLowerCase(),
      ) &&
      (preferredSize === 'default' || preferredSize === 'source')
    ) {
      return file.file;
    }

    preferredSize =
      preferredSize ||
      localStorageWrapper.getItem('pickitBOAIInsertSize') ||
      'medium';
    let image;
    if (
      preferredSize &&
      preferredSize !== 'original' &&
      file.file.previews?.length
    ) {
      image = file.file.previews?.find((thumb) => thumb.name === preferredSize);

      if (!image) {
        image = file.file.previews[0];
      }
    } else if (!file.file?.external_file) {
      image = file.file;
    }

    if (
      (preferredSize === 'original' || file.file.contentType === 'image/gif') &&
      !file.file.external_file
    ) {
      if (
        (file.file.image_details.width > 5900 ||
          file.file.image_details.height > 5900) &&
        inGoogleDocs()
      ) {
        ToastService.createToast({
          message:
            'The image is too large to insert. Please choose another size.',
          error: true,
          delay: 6000,
        });
        return;
      }
      return file.file;
    }
    let skipSvg = false;
    if (inAdobeAddon()) {
      const { adobeProduct } = window;
      skipSvg =
        inAdobeAddon() && (adobeProduct === 'PPRO' || adobeProduct === 'AEFT');
    }
    if (
      file.file.ext === 'svg' &&
      !file.file.external_file &&
      !inGoogleDocs() &&
      !skipSvg
    ) {
      return file.file;
    }
    if (
      !file.file.contentType?.includes('image') &&
      !file.file.external_file &&
      !image
    ) {
      return file.file;
    }
    return image;
  }

  async function insertSlide(file, container, prefs = {}) {
    const fileContext = contexts.documents;
    return new Promise(async (resolve, reject) => {
      try {
        await window.PowerPoint.run(async function (context) {
          let selectedSlideID = '';
          try {
            selectedSlideID = await getSelectedSlideID();
          } catch (e) {}

          const base64 = await fileContext.fileUrlToBase64(
            generateUrl(file.url),
          );
          context.presentation.insertSlidesFromBase64(base64, {
            targetSlideId: selectedSlideID ? `${selectedSlideID}#` : undefined,
            ...prefs,
          });

          await context.sync();
          analytics.trackEvent('File Downloaded', {
            File: file._id,
            'File Download Type': 'Insert',
          });
          resolve(true);
        });
      } catch (e) {
        console.error(e);
        reject(e);
      }
    });
  }

  async function downloadImage(file) {
    const context = contexts[file.type];
    if (!context) {
      return false;
    }
    const image = getFile(file);
    if (!image) {
      return false;
    }
    // Hotfix for LIV not triggering for company images due to the insert.js interface not being used. Should probably be moved
    if (
      userStatus.product.isLimited &&
      !User.hasInsertsLeft() &&
      !User.canInsert(image.slug || image._id)
    ) {
      User.setLIVError(new Error('no more inserts'));
      return;
    }
    if (file?.file?.external_file && isNative()) {
      openInNewTab(file.file.url);
    } else {
      downloadUrl(
        file?.file?.external_file ? file.file.url : generateUrl(image.url),
      );
    }
    analytics.trackEvent('File Downloaded', {
      File: file._id,
      'File Download Type': 'Download',
    });
    User.increaseLimitedInsertions(image.slug || image._id || image.id);
  }

  async function openFile(file) {
    if (file.file.open_url) {
      openInNewTab(file.file.open_url);
      return;
    }
    openInNewTab(file.file.url);
  }

  async function postImgUrltoWordpress(file, preferredSize, variantId) {
    setIsLoadingImage([...isLoadingImage, file._id]);
    const image = variantId
      ? file.file.variants.find((variant) => variant.id === variantId)
      : getFile(file, preferredSize);
    const data = {
      url: image.url,
      name: file.name,
      checksum: file.file?.checksum,
      id: image?.id,
    };
    window.parent?.postMessage(JSON.stringify(data), '*');
  }

  async function insertFile(file) {
    const { container, context } = getInfo(file.url);
    const base64 = await context.fileUrlToBase64(
      file.url,
      undefined,
      inAdobeAddon(),
    );
    await insert.fromMedia(base64, file, context.type);
  }

  async function insertImage(file, preferredSize, variantId) {
    const context = contexts[file.type];

    if (!context) {
      return false;
    }
    if (isWordpress) {
      postImgUrltoWordpress(file, preferredSize, variantId);
      return;
    }
    if (
      file?.file?.ext === 'pptxslide' &&
      inOffice() &&
      window.Office?.context?.requirements?.isSetSupported(
        'PowerPointApi',
        '1.2',
      )
    ) {
      setIsLoadingImage([...isLoadingImage, file._id]);
      await insertSlide(file?.file);
      setIsLoadingImage([...isLoadingImage.filter((i) => i._id !== file._id)]);
      return false;
    }
    if (file.file.external_file && !file.file?.previews?.length) {
      const image = getFile(file);
      openInNewTab(image.url);
      return;
    }
    const { adobeProduct } = window;
    if (
      !file.file?.contentType?.includes('image') &&
      !file.file?.previews?.length &&
      preferredSize !== 'variant' &&
      !supportedAdobeFormats[adobeProduct]?.includes(
        file.file.ext.toLowerCase(),
      )
    ) {
      return downloadImage(file);
    }

    if (!inOffice() && !inGoogleDocs() && !inAdobeAddon()) {
      return downloadImage(file);
    }
    try {
      setIsLoadingImage([...isLoadingImage, file._id]);
      const image = getFile(file, preferredSize);
      let base64;
      const url =
        preferredSize === 'variant'
          ? file.file.variants.find((variant) => variant.id === variantId).url
          : image.url;
      if (
        image?.ext === 'svg' &&
        inOffice() &&
        window.Office?.context?.requirements?.isSetSupported(
          'ImageCoercion',
          '1.2',
        )
      ) {
        base64 = await context.fileUrlToText(image);
      } else if (url.includes('-thumbnails')) {
        if (inAdobeAddon()) {
          const jpeg = ['jpg', 'jpeg'];
          // console.log(file.ext);
          const name = file.name.split('.');
          const nameExt = name.pop();
          file = {
            ...file,
            overrideExt: jpeg.includes(file?.file?.ext) ? file.ext : 'png',
            name: `${name.join('.')}-${
              preferredSize === 'variant' ? variantId : preferredSize
            }.${nameExt}`,
          };
        }
        base64 = await context.fileUrlToBase64(url, undefined, inAdobeAddon());
      } else {
        if (inAdobeAddon() && preferredSize === 'variant') {
          const name = file.name.split('.');
          const nameExt = name.pop();
          file = {
            ...file,
            name: `${name.join('.')}-${variantId}.${nameExt}`,
          };
        }
        base64 = await context.fileUrlToBase64(url, undefined, inAdobeAddon());
      }
      analytics.trackEvent('File Downloaded', {
        File: file._id,
        'File Download Type': 'Insert',
      });

      await insert.fromMedia(base64, file, context.type);
      setFinishedLoadingImage([...finishedLoadingImage, file._id]);
    } catch (e) {
      setFinishedLoadingImage([...finishedLoadingImage, file._id]);
      if (e?.error?.code === 5001 || e?.error?.code === 2001) {
        ToastService.createToast({
          message:
            "Can't insert image to current selection. Please move the selection within the document and try again.",
          error: true,
          delay: 6000,
        });
      }
    }
  }

  return {
    insertImage,
    downloadImage,
    getFile,
    openFile,
    isLoadingImage,
    insertSlide,
    insertFile,
  };
}
