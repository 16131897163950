import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import './style.scss';
import { Flex, Icon } from 'elements_v2';
import { useBottomScrollListener } from 'react-bottom-scroll-listener';

type Props = {
  isOpen?: boolean;
  onBottomScroll?: Function;
  onPopupClose?: Function;
  onOpen?: Function;
  onClose?: Function;
  actions?: Function | React.ReactNode;
  description?: Function | React.ReactNode;
  trigger?: Function | React.ReactElement;
  children?: Function | React.ReactNode;
  className?: string;
  contentClassName?: string;
  actionsClassName?: string;
  style?: React.CSSProperties;
  contentStyle?: React.CSSProperties;
  actionsStyle?: React.CSSProperties;
  wrapperStyle?: React.CSSProperties;
  headerStyle?: React.CSSProperties;
  width?: string | number;
  zIndex?: number;
  noPadding?: boolean;
  noPopup?: boolean;
  compact?: boolean;
  withCloseButton?: boolean;
  minifyHeaderOnScroll?: boolean;
  disableOutsideClick?: boolean;
  centerActions?: boolean;
  closeButton?: React.ReactNode;
  topBar?: React.ReactNode;
  header?: React.ReactNode;
  title?: React.ReactNode;
  titleAction?: React.ReactNode;
};

function Popup(props: Props) {
  const [isOpen, setIsOpen] = React.useState(props.isOpen);
  const [isScrolled, setIsScrolled] = useState(false);
  const ref = React.useRef<HTMLDivElement>(null);
  const contentRef = useBottomScrollListener<HTMLDivElement>(() => {
    props.onBottomScroll?.();
  });

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflowY = 'hidden';
      return () => {
        if (!document.body.querySelectorAll('.Pixi__Popup--open')?.length) {
          document.body.style.overflowY = 'auto';
        }
      };
    }
  }, [isOpen]);

  React.useEffect(() => {
    if (!isOpen) {
      props.onPopupClose?.();
    }
  }, [isOpen]);

  React.useEffect(() => {
    if (isOpen) {
      props.onOpen?.();
    }
  }, [isOpen]);

  React.useEffect(() => {
    setIsOpen(props.isOpen);
  }, [props.isOpen]);

  const className = ['Pixi', 'Pixi__Popup'];

  if (isOpen) {
    className.push('Pixi__Popup--open');
  }
  if (props.className) {
    className.push(props.className);
  }
  if (props.noPadding) {
    className.push('Pixi__Popup--noPadding');
  }
  if (props.noPopup) {
    className.push('Pixi__Popup--noPopup');
  }
  if (props.compact) {
    className.push('Pixi__Popup--compact');
  }
  if (props.minifyHeaderOnScroll && isScrolled) {
    className.push('Pixi__Popup--minifyHeader');
  }

  const content = (
    <div
      ref={ref}
      className={className.join(' ')}
      style={{
        zIndex: props.zIndex,
      }}
    >
      <div className="Pixi Pixi__Popup__content">
        <div
          className="Pixi Pixi__Popup__content__dimmer"
          onClick={() =>
            props.disableOutsideClick
              ? () => {}
              : props.onClose
              ? props.onClose()
              : setIsOpen(false)
          }
        />
        <div
          className="Pixi__Popup__content__wrapper"
          style={{
            ...props.wrapperStyle,
            width:
              props.width ||
              props.wrapperStyle?.width ||
              (props.style?.width ? '100%' : ''),
          }}
        >
          {props.closeButton ? (
            <div className="Pixi Pixi__Popup__content__close">
              {props.closeButton}
            </div>
          ) : (
            ''
          )}
          <div
            className={`Pixi Pixi__Popup__content__box ${
              props.contentClassName || ''
            }`}
            style={props.style}
          >
            {props.topBar}
            {props.header
              ? props.header
              : !!props.title && (
                  <div
                    className="Pixi__Popup__content__box__header"
                    style={props.headerStyle}
                  >
                    <div className="Pixi Pixi__Popup__content__box__title">
                      <Flex style={{ width: '100%' }}>
                        <h1>{props.title}</h1>
                        {(props.onClose || props.withCloseButton) && (
                          <Icon
                            bubble
                            button
                            name="x-lg"
                            onClick={
                              props.onClose
                                ? (e) => props.onClose?.(e)
                                : () => setIsOpen(false)
                            }
                          />
                        )}
                      </Flex>
                      {typeof props.description === 'function' ? (
                        <p>{props.description(isOpen, setIsOpen)}</p>
                      ) : (
                        !!props.description && <p>{props.description}</p>
                      )}
                    </div>
                    {props.titleAction}
                  </div>
                )}
            <div
              className="Pixi Pixi__Popup__content__box__contentWrapper"
              ref={contentRef}
            >
              <div
                className="Pixi Pixi__Popup__content__box__content"
                style={props.contentStyle}
              >
                {typeof props.children === 'function'
                  ? props.children(isOpen, setIsOpen)
                  : props.children}
              </div>
            </div>
            {props.actions && (
              <div
                style={props.actionsStyle}
                className={`Pixi Pixi__Popup__content__box__actions ${
                  props.actionsClassName || ''
                } ${
                  props.centerActions
                    ? 'Pixi__Popup__content__box__actions--centered'
                    : ''
                }`}
              >
                {typeof props.actions === 'function'
                  ? props.actions(isOpen, setIsOpen)
                  : props.actions}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );

  if (props.noPopup) {
    return content;
  }

  return (
    <>
      {props.trigger && typeof props.trigger === 'function'
        ? props.trigger(isOpen, setIsOpen)
        : props.trigger
        ? props.onClose
          ? props.trigger
          : React.cloneElement(props.trigger, {
              onClick: () => setIsOpen(true),
            })
        : ''}
      {isOpen && ReactDOM.createPortal(content, document.body)}
    </>
  );
}

export default Popup;
