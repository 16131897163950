import * as React from 'react';
import { useDocumentsContext } from './useDocumentsContext';
import { useMediaContext } from './useMediaContext';

export const useGetContextFromFile = () => {
  const contexts = {
    media: useMediaContext(),
    documents: useDocumentsContext(),
    brand: useMediaContext(),
  };

  return (file) => {
    if (!file?.type) {
      return null;
    }
    if (!contexts[file?.type]) {
      return null;
    }

    return contexts[file?.type];
  };
};
const useContextFromFile = (file) => {
  const contexts = {
    media: useMediaContext(),
    documents: useDocumentsContext(),
    brand: useMediaContext(),
  };

  // Actual file is being sent, we can figure it out with the URL
  if (file?.file?.id && file?.file?.url) {
    if (file?.file?.url?.includes('pickitmedia')) {
      return contexts.media;
    }
    if (file?.file?.url?.includes('pickitdocumentslive')) {
      return contexts.documents;
    }
    if (file?.file?.previews?.[0]?.url?.includes('pickitmedia')) {
      return contexts.media;
    }
    if (file?.file?.previews?.[0]?.url?.includes('pickitdocumentslive')) {
      return contexts.documents;
    }
  }

  if (file?.libraries?.includes('brandassets')) {
    return contexts.media;
  }

  if (file?.libraries?.includes('media')) {
    return contexts.media;
  }

  if (!file?.type) {
    return null;
  }
  if (!contexts[file?.type]) {
    return null;
  }

  return contexts[file?.type];
};

export default useContextFromFile;
