import React, { ReactNode, useEffect, useReducer, useState } from 'react';
import { useUserContext } from 'hooks';
import useUserStatus from 'hooks/useUserStatus';
import { truncate } from 'utils';
import useLibraryFilters from 'hooks/files/useLibraryFilters';
import {
  ActionIcon,
  Divider,
  Group,
  Stack,
  Text,
  TextInput,
} from '@mantine/core';
import PixiIcon, { PixiIconName } from '@pixi/elements/Icon';
import PixiDropdown from '@pixi/elements/Dropdown';
import PixiButton from '@pixi/elements/Button';
import PixiTooltip from '@pixi/elements/Tooltip';
import useFiles from 'hooks/files/useFiles';
import {
  AssetGridContextInterface,
  AssetGridDisplayAs,
  AssetGridGroupBy,
} from '../../Helpers';
import { useAssetGridContext } from '../../AssetGridContext';
import PixiCollapse from '@pixi/elements/Collapse';
import AssetGridSearch from '../AssetGridSearch';
import AssetGridFilters from '../LeftPanels/AssetGridFilters';
import { openAssetPreview } from '@pixi/AppController';
import { getPreviewPrefs } from '@pixi/components/AssetActions/useAssetActions';
import useAssetGridSortBy from '../../hooks/useAssetGridSortBy';
import { getMultiStore, getStore } from '@pixi/store';

export function AssetGridToolbar({
  disable,
  forceViewport,
}: {
  disable?: AssetGridContextInterface['disable'];
  forceViewport?: AssetGridContextInterface['viewport'];
}) {
  const User = useUserContext();
  const userStatus = useUserStatus();
  const AssetGrid = useAssetGridContext();
  const AssetGridSortBy = useAssetGridSortBy();
  const [propertySearch, setPropertySearch] = useReducer(
    (state: Record<string, string>, action: Record<string, string>) => {
      return { ...action };
    },
    {},
  );
  useEffect(() => {
    if (Object.keys(propertySearch)?.length) {
      const values = Object.keys(propertySearch).reduce(
        (result: Record<string, string>, key) => {
          if (propertySearch[key]) {
            result[key] = propertySearch[key];
            if (key === 'name') {
              result['file.name'] = propertySearch[key];
            }
          }
          return result;
        },
        {},
      );
      const timeout = setTimeout(() => {
        AssetGrid.setParam('query_properties', JSON.stringify(values));
      }, 600);

      return () => {
        clearTimeout(timeout);
      };
    }
    if (AssetGrid.activeFilters?.query_properties) {
      AssetGrid.setParam('query_properties', null);
    }
  }, [propertySearch]);

  const properties = [
    { field: 'tags', placeholder: 'Tags' },
    { field: 'description', placeholder: 'Description' },
    { field: 'author', placeholder: 'Author' },
    { field: 'name', placeholder: 'Name' },
  ];

  if (
    userStatus.activeFeatures?.ocr_and_fulltext_search &&
    AssetGrid.context?.type
  ) {
    properties.push({
      field: 'ocr',
      placeholder:
        AssetGrid.context?.type === 'media'
          ? 'OCR Context'
          : 'Document Context',
    });
  }
  if (User?.data?.selectedCommunity?.settings?.attributes?.searchable?.length) {
    User?.data?.selectedCommunity?.settings?.attributes?.searchable.forEach(
      (row: any) => {
        const customLabel =
          User?.data?.selectedCommunity?.settings?.attributes?.labels?.find(
            (label: any) => label.field === row,
          )?.label;
        properties.push({
          field: `attributes.${row}`,
          placeholder: customLabel || row,
        });
      },
    );
  }

  const sortOptions = AssetGridSortBy.map((option) => (
    <PixiDropdown.Item
      key={option.field + option.direction}
      onClick={() => {
        AssetGrid.setView('sortBy', {
          field: option.field,
          direction: option.direction,
        });
      }}
      color={
        AssetGrid.view.sortBy?.field === option.field &&
        AssetGrid.view.sortBy.direction === option.direction
          ? 'primary'
          : undefined
      }
    >
      {option.icon && (
        <PixiIcon name={option.icon} style={{ marginRight: 6 }} />
      )}
      {option.name}
    </PixiDropdown.Item>
  ));

  const viewOptions = (
    <>
      <PixiDropdown.Label>Display as</PixiDropdown.Label>
      {AssetGridDisplayAs?.map((displayAs) => (
        <PixiDropdown.Item
          key={displayAs.value}
          onClick={() => {
            AssetGrid.setView('displayAs', displayAs.value);
          }}
          color={
            AssetGrid.view.displayAs === displayAs.value ? 'primary' : undefined
          }
          icon={displayAs.icon}
        >
          {displayAs.name}
        </PixiDropdown.Item>
      ))}
      <PixiDropdown.Label>Group by</PixiDropdown.Label>
      {AssetGridGroupBy?.map((groupBy) => (
        <PixiDropdown.Item
          key={groupBy.value}
          onClick={() => {
            AssetGrid.setView('groupBy', groupBy.value);
          }}
          color={
            AssetGrid.view.groupBy === groupBy.value ? 'primary' : undefined
          }
          hovered={AssetGrid.view.groupBy === groupBy.value}
          rightSection={
            AssetGrid.view.groupBy === groupBy.value && (
              <>
                <ActionIcon
                  size="md"
                  variant="filled"
                  onClick={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    AssetGrid.setView('groupBy', null);
                  }}
                >
                  <PixiIcon name="xmark" />
                </ActionIcon>
              </>
            )
          }
          icon={groupBy.icon}
        >
          {groupBy.name}
        </PixiDropdown.Item>
      ))}
      {AssetGrid.view.displayAs === 'grid' && (
        <>
          <PixiDropdown.Label>Display size</PixiDropdown.Label>
          {['large', 'medium', 'small'].map((size) => {
            return (
              <PixiDropdown.Item
                key={size}
                onClick={() => {
                  AssetGrid.setView(
                    'displaySize',
                    size as 'small' | 'medium' | 'large',
                  );
                  User.setCloudStorage(
                    `preferences.${AssetGrid.context.type}.displaySize`,
                    size,
                  );
                }}
                color={
                  AssetGrid.view.displaySize === size ? 'primary' : undefined
                }
              >
                {size === 'large'
                  ? 'Large'
                  : size === 'medium'
                    ? 'Medium'
                    : 'Small'}
              </PixiDropdown.Item>
            );
          })}
        </>
      )}
    </>
  );

  const viewport = forceViewport || AssetGrid.viewport;

  return (
    <Group
      align={
        viewport === 'xxs' ||
        viewport === 'xs' ||
        viewport === 'sm' ||
        viewport === 'md'
          ? 'stretch'
          : 'flex-start'
      }
    >
      <Group align="stretch" gap="5">
        {viewport !== 'xxs' &&
          viewport !== 'xs' &&
          viewport !== 'sm' &&
          viewport !== 'md' && (
            <Group gap="5" mih={36} align="stretch">
              <AssetGridSearch maw={270} size="sm" variant="glass" />
            </Group>
          )}
        <Group gap="5" align="stretch">
          {AssetGrid.disable?.includes('leftPanel') &&
          !disable?.includes('filter') ? (
            <PixiDropdown
              width={300}
              height={550}
              target={
                <PixiButton variant="glass" size="sm" px="sm" h="auto">
                  <PixiIcon name="filter" />
                </PixiButton>
              }
            >
              <AssetGridFilters disable={['search']} asDropdown />
            </PixiDropdown>
          ) : (
            <></>
          )}
          {viewport === 'xxs' ||
          viewport === 'xs' ||
          viewport === 'sm' ||
          viewport === 'md' ? (
            <>
              <PixiDropdown
                width={240}
                height={400}
                position="bottom-end"
                target={
                  <PixiButton
                    color="dark"
                    variant="glass"
                    size="sm"
                    px="sm"
                    h="auto"
                  >
                    <PixiIcon name="ellipsis" />
                  </PixiButton>
                }
              >
                {viewport === 'xxs' ||
                  viewport === 'xs' ||
                  viewport === 'sm' ||
                  (viewport === 'md' && (
                    <Group w="100%" p="xs">
                      <AssetGridSearch w="100%" size="sm" variant="glass" />
                    </Group>
                  ))}
                <Divider mb="xs" />
                <PixiCollapse
                  target={(isOpen) => (
                    <PixiDropdown.Item icon="arrow-down-a-z" withChevron>
                      Sort by
                    </PixiDropdown.Item>
                  )}
                >
                  {sortOptions}
                  <PixiDropdown.Divider />
                </PixiCollapse>
                <PixiCollapse
                  target={(isOpen) => (
                    <PixiDropdown.Item icon="eye" withChevron>
                      View
                    </PixiDropdown.Item>
                  )}
                >
                  {viewOptions}
                  <PixiDropdown.Divider />
                </PixiCollapse>
              </PixiDropdown>
            </>
          ) : (
            <>
              <PixiTooltip label="Sort by">
                <PixiDropdown
                  width={250}
                  target={
                    <PixiButton variant="glass" size="md" px="sm">
                      <PixiIcon name="arrow-up-wide-short" />
                    </PixiButton>
                  }
                >
                  {sortOptions}
                </PixiDropdown>
              </PixiTooltip>

              <PixiTooltip label="View options">
                <PixiDropdown
                  width={250}
                  target={
                    <PixiButton
                      variant="glass"
                      size="md"
                      px="sm"
                      styles={{ label: { overflow: 'visible' } }}
                    >
                      <PixiIcon name="eye" />
                    </PixiButton>
                  }
                >
                  {viewOptions}
                </PixiDropdown>
              </PixiTooltip>
              {!AssetGrid.disable?.includes('fullscreen') && (
                <PixiTooltip label="Fullscreen browse">
                  <PixiButton
                    variant="glass"
                    size="md"
                    px="sm"
                    styles={{ label: { overflow: 'visible' } }}
                    onClick={() => {
                      const allFiles = getMultiStore(
                        'ASSETGRID_ASSETS',
                        AssetGrid.id,
                      ).state;
                      const allFilesData = getStore('FILES').getByKeys(
                        allFiles.map((s) => s._id),
                      );
                      const firstSelectedFile = allFiles?.findIndex(
                        (p) => p._id === AssetGrid?.selectedFiles?.[0]?._id,
                      );
                      openAssetPreview(
                        allFilesData?.[
                          AssetGrid?.selectedFiles?.[0]?._id &&
                          firstSelectedFile !== -1
                            ? firstSelectedFile
                            : 0
                        ],
                        getPreviewPrefs({
                          file: allFilesData?.[
                            AssetGrid?.selectedFiles?.[0]?._id &&
                            firstSelectedFile !== -1
                              ? firstSelectedFile
                              : 0
                          ],
                          allFiles: allFilesData,
                          extraPrefs: {
                            previewFileProps: {
                              editPanelProps: {
                                focus: AssetGrid?.activeShortcutIds?.find((f) =>
                                  f?.includes('approvals'),
                                )
                                  ? 'approvals'
                                  : undefined,
                              },
                            },
                          },
                        }),
                      );
                    }}
                  >
                    <PixiIcon name="expand" />
                  </PixiButton>
                </PixiTooltip>
              )}
            </>
          )}
        </Group>
      </Group>
    </Group>
  );
}
