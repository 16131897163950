import React from 'react';
import {
  FlowData,
  InterfaceFlowState,
  InterfaceFlowStep,
} from 'services/api/Flows';

export const FlowsContext = React.createContext<FlowsContextType>(
  {} as FlowsContextType,
);

type SplitUserTasks = {
  media: InterfaceFlowState[];
  documents: InterfaceFlowState[];
};
type SplitUserTasksCount = {
  media: InterfaceFlowState[];
  documents: InterfaceFlowState[];
};
type FlowsContextType = {
  getFlows: (reset?: boolean) => Promise<void>;
  createFlow: (data: FlowData) => Promise<boolean>;
  updateFlow: (data: FlowData) => Promise<boolean>;
  deleteFlow: (id: string) => Promise<void>;
  doAction: (
    flowId: string,
    fileIds: string[],
    action: string,
    stepId: string,
    message: string,
    doUpdateStatemap: boolean,
  ) => Promise<{ data: FlowData; result: 'moved' | 'approved' }>;
  doActionMultiple: (
    states: InterfaceFlowState[],
    action: string,
    message: string,
    doUpdateStatemap: boolean,
  ) => Promise<{
    data: FlowData;
    result: string;
  }>;
  doActionFiles: (
    fileIds: string[],
    action: string,
    message: string,
    doUpdateStatemap: boolean,
  ) => Promise<FlowData[]>;
  getFlowFromStepId: (stepId: string) => FlowData | undefined;
  getStateContributor: (
    stepId: string,
  ) => Promise<InterfaceFlowState | undefined>;
  hasAccessToStep: (step: InterfaceFlowStep | undefined) => boolean;
  getUserTasks: () => Promise<InterfaceFlowState[]>;
  getSplitUserTasks: () => Promise<SplitUserTasks>;
  getSplitUserTasksCount: () => Promise<SplitUserTasksCount>;
  getUserTasksCount: () => Promise<number>;
  flows: FlowData[];
  isLoading: Boolean;
  stateMap: any;
  userTasks: InterfaceFlowState[];
};
