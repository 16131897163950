import { Menu, Popover, Portal } from '@mantine/core';
import {
  useState,
  useCallback,
  useEffect,
  forwardRef,
  ReactNode,
  RefObject,
  MouseEvent,
} from 'react';
import { sleep } from 'utils';
import PixiDropdown, { PixiDropdownProps } from '../Dropdown';

export interface PixiContextMenuProps
  extends Omit<PixiDropdownProps, 'children' | 'target'> {
  isOpen?: boolean;
  element: RefObject<
    HTMLElement | HTMLDivElement | HTMLButtonElement | HTMLAnchorElement
  >;
  onTrigger?: (isOpen: boolean) => void;
  children: PixiDropdownProps['children'];
  withinPortal?: boolean;
}

const PixiContextMenu = ({
  element,
  onTrigger,
  children,
  isOpen: _isOpen = false,
  onOpen,
  onClose,
  ...rest
}: PixiContextMenuProps) => {
  const [x, setXPos] = useState(0);
  const [y, setYPos] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(_isOpen);
  }, [_isOpen]);

  const handleContextMenu = async (e: any) => {
    e.preventDefault();
    e.stopPropagation();

    setIsOpen(false);
    const rect = element.current?.getBoundingClientRect();
    if (!rect) {
      return;
    }
    const x = e.clientX - rect.left; // x position within the element.
    const y = e.clientY - rect.top; // y position within the element.
    setXPos(x);
    setYPos(y);
    setIsOpen(true);
    onOpen?.();
  };

  useEffect(() => {
    onTrigger?.(isOpen);
  }, [isOpen]);

  useEffect(() => {
    if (element?.current) {
      element.current?.addEventListener?.('contextmenu', handleContextMenu);
      return () => {
        element.current?.removeEventListener?.(
          'contextmenu',
          handleContextMenu,
        );
      };
    }
  }, [element]);

  return (
    <>
      <PixiDropdown
        target={
          <div
            style={{
              width: 1,
              height: 1,
              position: 'absolute',
              top: y,
              left: x,
              zIndex: 99999999,
            }}
          />
        }
        closeOnItemClick
        width={250}
        {...rest}
        forceOpen={isOpen}
        onOpen={() => {
          onOpen?.();
          setIsOpen(true);
        }}
        onClose={(reason) => {
          setIsOpen(false);
          onClose?.(reason);
        }}
      >
        {children}
      </PixiDropdown>
    </>
  );
};

export default PixiContextMenu;
