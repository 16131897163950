import { Collapse } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { cloneElement } from 'react';

export default function PixiCollapse({
  target,
  children,
  reversed,
  disabled,
}: {
  target: React.ReactElement | ((isOpen: boolean) => React.ReactElement);
  children: React.ReactNode;
  reversed?: boolean;
  disabled?: boolean;
}) {
  const [opened, { toggle }] = useDisclosure(false);
  const trigger = cloneElement(
    typeof target === 'function' ? target(opened) : target,
    {
      onClick: toggle,
    },
  );

  if (disabled) {
    return children;
  }
  return (
    <>
      {!reversed ? trigger : <></>}
      <Collapse in={opened}>{children}</Collapse>
      {reversed ? trigger : <></>}
    </>
  );
}
