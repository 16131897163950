import { useAppContext } from 'contexts/Providers/AppProvider';
import { useMediaContext, useDocumentsContext, useUserContext } from 'hooks';
import { Button, Dropdown, Flex, Icon, Input, Popup } from 'elements_v2';

import React, { cloneElement, useEffect, useReducer, useState } from 'react';
import { downloadUrl } from 'utils';
import { FilesResult } from 'views/_Manage/Boards/components/BoardSearchResults';
import PixiDropdown from '@pixi/elements/Dropdown';

export default function useDownloadBag() {
  const User = useUserContext();
  const [files, setFiles] = useReducer((state, action) => [...action], []);

  const addToBag = (file) => setFiles([...files, file]);
  const removeFromBag = (file) =>
    setFiles(files.filter((f) => f._id !== file._id));
  const isInBag = (file) => files.find((f) => f._id === file._id);

  // function cacheFiles() {
  //   localStorageWrapper.setItem(
  //     'downloadBagFiles',
  //     JSON.stringify(
  //       files.map((file) => ({
  //         id: file._id,
  //         library: file.type,
  //       }))
  //     )
  //   );
  // }

  // function parseCache() {
  //   const cachedItems = JSON.parse(
  //     localStorageWrapper.getItem('downloadBagFiles') || '{}'
  //   );
  //   const cachedFiles = [];
  //   cachedItems.forEach((file) => {
  //     const dataFile = libraries[file.library].data.files.find(
  //       (f) => f._id === file.id
  //     );
  //     if (dataFile) {
  //       cachedFiles.push(dataFile);
  //     }
  //   });
  //   setFiles(cachedFiles);
  // }

  // useEffect(() => {
  //   cacheFiles(files);
  // }, [files]);

  async function downloadFiles(files, communitySlug, context) {
    await context.downloadFilesAsZip(files);
  }

  return {
    files,
    addToBag,
    removeFromBag,
    isInBag,
    downloadFiles,
    clearBag: () => setFiles([]),
    // parseCache,
    toggleFile: (file) =>
      !isInBag(file) ? addToBag(file) : removeFromBag(file),
  };
}

export function DownloadBagActions({
  communitySlug,
  context,
  onClear,
  downloadWrapper,
}) {
  const User = useUserContext();
  const { downloadBag } = useAppContext();
  const Media = useMediaContext();
  const Documents = useDocumentsContext();
  const [isBagOpen, setIsBagOpen] = useState(false);
  const [activeSection, setActiveSection] = useState('media');
  const [downloading, setDownloading] = useState('');

  async function downloadFiles(files, type) {
    setDownloading(type);
    await downloadBag.downloadFiles(
      files,
      communitySlug,
      type === 'media' ? Media : Documents,
    );
    setDownloading('');
  }

  const mediaFiles = downloadBag.files
    .filter((file) => file.type === 'media')
    .map((file) => Media.data.files.find((f) => f._id === file._id) || file);
  const documentFiles = downloadBag.files
    .filter((file) => file.type === 'documents')
    .map(
      (file) => Documents.data.files.find((f) => f._id === file._id) || file,
    );

  function downloadWrapperElement(trigger, action) {
    if (downloadWrapper) {
      return downloadWrapper(trigger, action);
    }
    return cloneElement(trigger, { onClick: action });
  }

  return (
    <div style={{ padding: 10 }}>
      <Popup
        title="Your bag selection"
        zIndex={9999999}
        description={
          <>
            <Button
              inline
              size="large"
              primary={activeSection === 'media'}
              onClick={() => {
                setActiveSection('media');
              }}
            >
              {mediaFiles?.length} media files
            </Button>
            <Button
              inline
              size="large"
              primary={activeSection === 'documents'}
              onClick={() => {
                setActiveSection('documents');
              }}
            >
              {documentFiles?.length} documents
            </Button>
          </>
        }
        actions={
          <>
            <Flex width="100%">
              <Button
                inline
                pixi
                style={{ marginRight: 'auto' }}
                onClick={() => {
                  setActiveSection('');
                  setIsBagOpen(false);
                  downloadBag.clearBag();
                }}
              >
                Clear bag
              </Button>
              <div>
                {activeSection === 'media' &&
                  !!mediaFiles?.length &&
                  downloadWrapperElement(
                    <Button
                      inline
                      pixi
                      primary
                      loading={downloading === 'media'}
                    >
                      <Icon name="CloudDownload" />
                      Download all media files
                    </Button>,
                    () => downloadFiles(mediaFiles, 'media'),
                  )}
                {activeSection === 'documents' &&
                  !!documentFiles?.length &&
                  downloadWrapperElement(
                    <Button
                      inline
                      pixi
                      primary
                      loading={downloading === 'documents'}
                    >
                      <Icon name="CloudDownload" />
                      Download all documents
                    </Button>,
                    () => downloadFiles(documentFiles, 'documents'),
                  )}
              </div>
            </Flex>
          </>
        }
        isOpen={isBagOpen}
        onClose={() => setIsBagOpen(false)}
      >
        <FilesResult
          style={{ marginLeft: 0, marginTop: 0 }}
          files={activeSection === 'documents' ? documentFiles : mediaFiles}
          selectedFiles={[]}
          includeDownloadBag
          downloadWrapper={downloadWrapper}
        />
      </Popup>
      <PixiDropdown.Label>View your selection</PixiDropdown.Label>
      <PixiDropdown.Item>Hej</PixiDropdown.Item>
      <PixiDropdown.Item>Hejs</PixiDropdown.Item>
      <Input.Field
        label="View your selection"
        custom={
          <Button
            dropdown={
              <div style={{ color: '#000' }}>
                <Dropdown.Option
                  onClick={() => {
                    downloadBag.clearBag();
                    onClear?.();
                  }}
                >
                  <Icon name="TrashFill" />
                  Clear bag
                </Dropdown.Option>
              </div>
            }
            primary
            onClick={() => setIsBagOpen(true)}
          >
            <Icon name="Bag" />
            Open bag
          </Button>
        }
      />
      {!!mediaFiles.length && (
        <Input.Field
          label={`${mediaFiles?.length} media files`}
          style={{ marginTop: 15 }}
          custom={downloadWrapperElement(
            <Button outline loading={downloading === 'media'}>
              <Icon name="CloudDownload" />
              Download all
            </Button>,
            () => downloadFiles(mediaFiles, 'media'),
          )}
        />
      )}
      {!!documentFiles.length && (
        <Input.Field
          label={`${documentFiles?.length} documents`}
          style={{ marginTop: 15 }}
          custom={downloadWrapperElement(
            <Button outline loading={downloading === 'documents'}>
              <Icon name="CloudDownload" />
              Download all
            </Button>,
            () => downloadFiles(documentFiles, 'documents'),
          )}
        />
      )}
    </div>
  );
}
