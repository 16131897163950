import { analytics } from 'services';
import localStorageWrapper from 'utils/localStorageWrapper';
import {
  inAdobeAddon,
  inGoogleDocs,
  inOffice,
  isAddin,
  isWordpress,
} from 'utils/platform';
export default class insert {
  static setUserContext(context) {
    insert.userContext = context;
  }

  static setUserStatus(data) {
    insert.userStatus = data;
  }

  static getApi() {
    if (insert.forcedApi) {
      return insert.forcedApi;
    }
    if (insert.userContext.spaces?.selected) {
      return insert.userContext.request.community;
    }
    return insert.userContext.request.user;
  }

  static sendAnalytics(asyncResult, contribution) {
    insert.userContext.setCloudStorage('onboarding.imageInserted', true);
    if (
      asyncResult &&
      asyncResult?.status === window?.Office?.AsyncResultStatus?.Failed
    ) {
      return analytics.trackEvent('Contribution Download Failed', {
        'Contribution Slug': contribution.slug,
        'Contribution Name': contribution.filename || contribution.name,
        'Contribution User Django Id': contribution.user.id,
        'Contribution User Display Name': contribution.user.display_name,
        'Contribution Search Query': analytics.getActiveParam(
          'Contribution Search Query'
        ),
        'Contribution Collection Slug': analytics.getActiveParam(
          'Contribution Collection Slug'
        ),
        'Contribution Category Slug': analytics.getActiveParam(
          'Contribution Category Slug'
        ),
        'Contribution Position': analytics.getActiveParam(
          'Contribution Position'
        ),
        'Contribution Downloaded Size': 'default',
        'Contribution Downloaded Method': 'window.Office api',
        'Contribution Download Failed Error': asyncResult.error.name,
        'Contribution Download Failed Error Message': asyncResult.error.message,
        'Contribution Download Failed Error Code': asyncResult.error.code,
      });
    }
    if (asyncResult && asyncResult?.status === 'Google Success') {
      return analytics.trackEvent('Contribution Downloaded', {
        'Contribution Slug': contribution.slug,
        'Contribution Name': contribution.filename || contribution.name,
        'Contribution Source Name':
          contribution.photos?.filename || contribution?.filename,
        'Contribution Content Type': contribution.image_type,
        'Contribution User Django Id': contribution.user.id,
        'Contribution User Display Name': contribution.user.display_name,
        'Contribution Search Query': analytics.getActiveParam(
          'Contribution Search Query'
        ),
        'Contribution Search Image Type': analytics.getActiveParam(
          'Contribution Search Image Type'
        ),
        'Contribution Collection Slug': analytics.getActiveParam(
          'Contribution Collection Slug'
        ),
        'Contribution Category Slug': analytics.getActiveParam(
          'Contribution Category Slug'
        ),
        'Contribution Position': analytics.getActiveParam(
          'Contribution Position'
        ),

        'Contribution Downloaded Size': 'default',
        'Contribution Downloaded Method': 'Google api',
      });
    }

    analytics.trackEvent('Contribution Downloaded', {
      'Contribution Slug': contribution.slug,
      'Contribution Name': contribution.filename || contribution.name,
      'Contribution Source Name':
        contribution.photos?.filename || contribution?.filename,
      'Contribution Content Type': contribution.image_type,
      'Contribution User Django Id': contribution.user.id,
      'Contribution User Display Name': contribution.user.display_name,
      'Contribution Search Query': analytics.getActiveParam(
        'Contribution Search Query'
      ),
      'Contribution Search Image Type': analytics.getActiveParam(
        'Contribution Search Image Type'
      ),
      'Contribution Collection Slug': analytics.getActiveParam(
        'Contribution Collection Slug'
      ),
      'Contribution Category Slug': analytics.getActiveParam(
        'Contribution Category Slug'
      ),
      'Contribution Position': analytics.getActiveParam(
        'Contribution Position'
      ),

      'Contribution Downloaded Size': 'default',
      'Contribution Downloaded Method': 'window.Office api',
    });
  }

  static handleLIVInsert(contribution, failed) {
    if (insert.skipLimitIncrease) {
      insert.skipLimitIncrease = false;
    } else if (!failed && insert.userStatus.product.isLimited) {
      insert.userContext.increaseLimitedInsertions(
        contribution.slug || contribution._id || contribution.id
      );
    }
  }

  //* * */
  static skipLIV() {
    insert.skipLimitIncrease = true;
  }

  /**
   * Get next size in order if preferred doesn't exist.
   * @param {*} preferredSize The prefered size <String>.
   * @param {*} links The contribution download links <Object>.
   */
  static getFallbackSize(links) {
    switch (true) {
      case links.large.has_access:
        return 'large';
      case links.medium.has_access:
        return 'medium';
      case links.small.has_access:
        return 'small';
      default:
        return 'origin';
    }
  }

  static getFallbackSizeV2(links) {
    switch (true) {
      case links.large.url:
        return 'large';
      case links.medium.url:
        return 'medium';
      case links.small.url:
        return 'small';
      default:
        return 'origin';
    }
  }

  static async getBase64V2(size) {
    const data = await fetch(
      size.url.includes('?')
        ? `${size.url}&format=base64`
        : `${size.url}?format=base64`
    );
    return await data.text();
  }

  static async fromUserV2(contribution) {
    if (
      insert.userStatus.product.isLimited &&
      !insert.userContext.hasInsertsLeft() &&
      !insert.skipLimitedInsert &&
      !insert.userContext.canInsert(contribution.slug) &&
      !this.disableLIV
    ) {
      throw new Error('no more inserts');
    }
    const preferred = localStorageWrapper.getItem('pickitBOAIInsertSize');
    let size =
      contribution.download_links?.large ||
      contribution.download_links?.medium ||
      contribution.download_links?.small;
    const links = contribution?.download_links;
    if (preferred && preferred !== 'default') {
      size =
        links[links[preferred] ? preferred : this.getFallbackSizeV2(links)];
    }
    if (!isAddin()) {
      window.open(size.url);
      insert.handleLIVInsert(contribution);
      return insert.sendAnalytics(null, contribution);
    }
    const base64String = await this.getBase64V2(size);
    if (inGoogleDocs()) {
      return insert.toGoogleAddon(`${base64String}base64`, contribution);
    }
    if (inAdobeAddon()) {
      const file = { ...contribution };
      const parts = file.filename.split('.');
      const ext = parts.pop();
      file.filename =
        parts.join('.') +
        '-' +
        (links[preferred] ? preferred : this.getFallbackSizeV2(links)) +
        '.' +
        ext;
      return insert.toAdobeAddon(base64String, file, undefined, 'base64');
    }
    return insert.fromBase64(base64String, contribution);
  }

  /**
   * Insert image from user preference.
   * @param {*} contribution The contribution, used for logging.
   */
  static async fromUser(contribution) {
    if (
      insert.userStatus.product.isLimited &&
      !insert.userContext.hasInsertsLeft() &&
      !insert.skipLimitedInsert &&
      !insert.userContext.canInsert(contribution.slug) &&
      !this.disableLIV
    ) {
      throw new Error('no more inserts');
    }
    const preferred = localStorageWrapper.getItem('pickitBOAIInsertSize');
    if (preferred && preferred !== 'default') {
      const links = await insert
        .getApi()
        .getContributionDownloadLinks(contribution.slug);
      const size =
        links[preferred] && links[preferred].has_access
          ? preferred
          : this.getFallbackSize(links);
      if (links[size] && links[size].has_access) {
        return insert.fromUrl(links[size].url, contribution);
      }
    }
    const base64String = await insert
      .getApi()
      .getContributionBase64(contribution.slug);
    return insert.fromBase64(base64String, contribution);
  }

  static async fromSizeV2(size, contribution, links) {
    if (
      insert.userStatus.product.isLimited &&
      !insert.userContext.hasInsertsLeft() &&
      !insert.skipLimitedInsert &&
      !insert.userContext.canInsert(contribution.slug) &&
      !this.disableLIV
    )
      return;

    if (size === 'default') {
      size = 'medium';
    }
    if (size === 'source' && !links[size] && !links.initial) {
      size = 'medium';
    } else if (size === 'source' && !links[size] && links.initial) {
      size = 'initial';
    }
    if (size === 'origin' && !links[size]) {
      size = 'medium';
    }
    if (!links[size]) {
      size = 'small';
    }
    const base64String = await this.getBase64V2(links[size]);
    if (inGoogleDocs()) {
      return insert.toGoogleAddon(`${base64String}base64`, contribution);
    }
    if (inAdobeAddon()) {
      const file = { ...contribution };
      const parts = file.filename.split('.');
      const ext = parts.pop();
      file.filename = parts.join('.') + '-' + size + '.' + ext;
      return insert.toAdobeAddon(base64String, file, undefined, 'base64');
    }
    return insert.fromBase64(base64String, contribution);
  }

  static async fromSize(size, contribution) {
    if (
      insert.userStatus.product.isLimited &&
      !insert.userContext.hasInsertsLeft() &&
      !insert.skipLimitedInsert &&
      !insert.userContext.canInsert(contribution.slug) &&
      !this.disableLIV
    )
      return;
    if (size && size !== 'default') {
      const links = await insert
        .getApi()
        .getContributionDownloadLinks(contribution.slug);

      const newSize =
        links[size] && links[size].has_access
          ? size
          : this.getFallbackSize(links);
      if (links[newSize] && links[newSize].has_access) {
        return insert.fromUrl(links[newSize].url, contribution);
      }
    }

    const base64String = await insert
      .getApi()
      .getContributionBase64(contribution.slug);
    return insert.fromBase64(base64String, contribution);
  }

  static async fromMedia(base64, file) {
    return new Promise(async (resolve, reject) => {
      if (
        insert.userStatus.product.isLimited &&
        !insert.userContext.hasInsertsLeft() &&
        !insert.skipLimitedInsert &&
        !insert.userContext.canInsert(file._id) &&
        !this.disableLIV
      ) {
        return reject({
          error: 'insertLimitReached',
          errorTitle: 'Oops!',
          errorMessage: 'no more inserts',
        });
      }
      if (isWordpress) {
        const data = {
          url: base64,
          name: file.name,
        };
        window.parent?.postMessage(JSON.stringify(data), '*');
      }
      if (inGoogleDocs()) {
        await insert
          .toGoogleAddon(`${base64}base64`, file, true)
          .then(() => {
            resolve(true);
          })
          .catch((resp) => {
            reject(resp);
          });
        return;
      }
      if (inAdobeAddon()) {
        await insert
          .toAdobeAddon(base64, file, true)
          .then(() => {
            resolve(true);
          })
          .catch((resp) => {
            reject(resp);
          });
      }

      const options = {
        coercionType:
          file?.file?.ext === 'svg' &&
            window.Office.context.requirements.isSetSupported(
              'ImageCoercion',
              '1.2'
            )
            ? window.Office.CoercionType.XmlSvg
            : window.Office.CoercionType.Image,
      };
      if (
        file?.file?.ext === 'svg' &&
        window.Office.context.requirements.isSetSupported(
          'ImageCoercion',
          '1.2'
        )
      ) {
        options.imageWidth = 300;
      }
      window.Office.context.document.setSelectedDataAsync(
        base64,
        options,
        (asyncResult) => {
          /** Check for error on window.Office insertion API */
          if (asyncResult.status === window.Office.AsyncResultStatus.Failed) {
            reject(asyncResult);
            return false;
          }
          resolve(true);
        }
      );
    });
  }

  /**
   * Insert an image from base65 string.
   * @param {string} base64String The image in base64 format. If you don't have this, you can request insert.fromUrl or insert.fromUser.
   * @param {object} contribution The contribution, sent for logging.
   */
  static fromBase64 = (base64String, contribution) => {
    return new Promise(async (resolve, reject) => {
      if (
        insert.userStatus.product.isLimited &&
        !insert.userContext.hasInsertsLeft() &&
        !insert.skipLimitedInsert &&
        !insert.userContext.canInsert(contribution.slug) &&
        !this.disableLIV
      ) {
        return reject({
          error: 'insertLimitReached',
          errorTitle: 'Oops!',
          errorMessage: 'no more inserts',
        });
      }
      const { cloudStorage, setCloudStorage } = insert.userContext;
      const { insertHistory } = cloudStorage;
      const options = {
        coercionType: window.Office.CoercionType.Image,
      };

      const afterInsert = (asyncResult) => {
        /** Check for error on window.Office insertion API */
        if (asyncResult.status === window.Office.AsyncResultStatus.Failed) {
          if (contribution) {
            insert.sendAnalytics(asyncResult, contribution);
            insert.handleLIVInsert(contribution, true);
          }
          reject(asyncResult);
          return false;
        }
        if (contribution) {
          insert.sendAnalytics(asyncResult, contribution);
          insert.handleLIVInsert(contribution);
          const previouslyInserted = insertHistory.findIndex(
            (historyContribution) =>
              historyContribution.slug === contribution.slug
          );
          if (previouslyInserted > -1) {
            insertHistory.splice(previouslyInserted, 1);
          }
          insertHistory.splice(0, 0, {
            slug: contribution.slug,
            thumb: contribution.thumb,
            width: contribution.width,
            height: contribution.height,
          });
        }

        setCloudStorage('insertHistory', insertHistory);
        return resolve();
      };

      if (contribution) {
        const previouslyInserted = insertHistory.findIndex(
          (historyContribution) =>
            historyContribution.slug === contribution.slug
        );
        if (previouslyInserted > -1) {
          insertHistory.splice(previouslyInserted, 1);
        }
        insertHistory.splice(0, 0, {
          slug: contribution.slug,
          thumb: contribution.thumb,
          width: contribution.width,
          height: contribution.height,
        });
      }
      /*
        This was a Fix for a Customer that fixed itself somehow.
        However. It might be an issue for the future as this seems to work
        with the explorer controller but not the edge controller
        So keep this for a rainy day. This fix also breaks inserting
        a picture in a media placeholder box in PowerPoint, thus
        making the natural fix better for the moment.

        Also preserved values are used because inserting with toolbar 
        changes width and height when the picture is made to a thumbnail
      	
      if (contribution.preservedWidth < 300 || contribution.preservedHeight < 300) {
        options.imageWidth = contribution.width;
        //options.imageHeight = contribution.height;
      }

      if (!inOffice() || !window.Office.context.requirements.isSetSupported('ImageCoercion', 1.0)) {
        this.downloadImage(base64String, contribution && contribution.photos && contribution.photos.filename ? contribution.photos.filename : "untitled");
        return resolve();
      } */

      if (
        !inOffice() ||
        !window.Office.context.requirements.isSetSupported('ImageCoercion', 1.0)
      ) {
        // this.fromSize('medium', contribution);

        return resolve();
      }
      window.Office.context.document.setSelectedDataAsync(
        base64String,
        options,
        afterInsert
      );
    }); // End of promise function
  };

  static async fromImgUrl(url) {
    return new Promise((res) => {
      const img = document.createElement('img');
      img.src = url;
      img.onload = () => {
        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0);
        const dataURL = canvas.toDataURL();
        res(dataURL.replace(/^data:image\/(png|jpg);base64,/, ''));
      };
    });
  }

  /**
   * Insert image from url based on the DownloadLinks object.
   * @param {*} url The URl. Note: base64/ will be appended.
   * @param {*} contribution The contribution, used for logging.
   */
  static async fromUrl(url, contribution, ignoreLIV) {
    if (
      !ignoreLIV &&
      insert.userStatus.product.isLimited &&
      !insert.userContext.hasInsertsLeft() &&
      !insert.skipLimitedInsert &&
      !insert.userContext.canInsert(contribution.slug) &&
      !this.disableLIV
    )
      throw new Error('no more inserts');

    if (
      (!inOffice() ||
        !window.Office.context.requirements.isSetSupported(
          'ImageCoercion',
          1.0
        )) &&
      !inGoogleDocs()
    ) {
      window.location.href = url;
      insert.sendAnalytics({ status: null }, contribution);
      insert.handleLIVInsert(contribution);
      return false;
    }
    let base64string;
    if (contribution) {
      url = url.replace('?with_token=', 'base64/?with_token=');
      base64string = await insert
        .getApi()
        .getContributionBase64FromUrl(`${url}base64/`);
    } else {
      base64string = await insert.fromImgUrl(url);
      base64string = base64string.split(',').pop();
    }
    if (inGoogleDocs()) {
      return insert.toGoogleAddon(`${base64string}base64`, contribution);
    }
    if (inAdobeAddon()) {
      return insert.toAdobeAddon(base64string, contribution);
    }
    return insert.fromBase64(base64string, contribution);
  }

  /**
   * Insert image from url to the google API
   * @param {*} url The URL.
   * @param {*} file The file, used for logging.
   */
  static async toGoogleAddon(url, file, skipAnalytics) {
    const Inserts = this.googleInserts;

    return new Promise((resolve, reject) => {
      const Id = new Date().toISOString();
      window.parent.postMessage(
        JSON.stringify({
          Id,
          url,
          type:
            file?.file?.ext ||
            file?.src?.toLowerCase().split('.').pop() ||
            'jpeg',
        }),
        '*'
      );
      Inserts[Id] = setTimeout(() => {
        reject({
          name: 'Google Insert Timeout',
          message: 'Insert took too long',
        });
        delete Inserts[Id];
      }, 20000);

      const eventMethod = window.addEventListener
        ? 'addEventListener'
        : 'attachEvent';
      const eventer = window[eventMethod];
      const messageEvent =
        eventMethod === 'attachEvent' ? 'onmessage' : 'message';

      function callback(e) {
        if (!e.origin.includes('script.googleusercontent.com')) return;
        const message = e.data || e.message;

        if (typeof message === 'string') {
          const response = JSON.parse(message);
          if (Inserts[response.Id]) {
            clearTimeout(Inserts[response.Id]);
            delete Inserts[response.Id];
            console.info('Google Insert Success');
            window.removeEventListener(messageEvent, callback);
            if (!skipAnalytics)
              insert.sendAnalytics({ status: 'Google Success' }, file);
            insert.handleLIVInsert(file);

            return resolve(message);

            // TODO: handle analytics
          }
        }
      }
      eventer(messageEvent, callback);
    });
  }

  static googleInserts = {};

  /**
   * Insert image from url to the adobe API
   * @param {*} url The URL.
   * @param {*} file The file, used for logging.
   */

  static async toAdobeAddon(url, file, skipAnalytics, encoding) {
    try {
      const Inserts = this.adobeInserts;
      return new Promise((resolve, reject) => {
        try {
          const Id = new Date().toISOString();
          let name = (
            file?.name ||
            file?.file?.filename ||
            file?.filename
          )?.split('.');
          const ext = name.pop();
          name = name.join('.');

          window.parent.postMessage(
            {
              Id,
              url,
              type:
                file?.overrideExt ||
                file?.file?.ext ||
                file?.src?.toLowerCase()?.split('.')?.pop() ||
                ext ||
                'jpeg',
              name,
              forceEncoding: encoding,
            },
            '*'
          );
          Inserts[Id] = setTimeout(() => {
            reject({
              name: 'Adobe Insert Timeout',
              message: 'Insert took too long',
            });
            delete Inserts[Id];
          }, 20000);

          const eventMethod = window.addEventListener
            ? 'addEventListener'
            : 'attachEvent';
          const eventer = window[eventMethod];
          const messageEvent =
            eventMethod === 'attachEvent' ? 'onmessage' : 'message';

          function callback(e) {
            // if (!e.origin.includes('script.googleusercontent.com')) return;
            const message = e.data || e.message;

            const response = message;
            if (Inserts[response.Id]) {
              clearTimeout(Inserts[response.Id]);
              delete Inserts[response.Id];
              console.info('Adobe Insert Success');
              window.removeEventListener(messageEvent, callback);
              if (!skipAnalytics)
                insert.sendAnalytics({ status: 'Adobe Success' }, file);
              insert.handleLIVInsert(file);

              return resolve(message);

              // TODO: handle analytics
            }
          }
          eventer(messageEvent, callback);
        } catch (e) {
          alert(e.message);
        }
      });
    } catch (e) {
      alert(e.message);
    }
  }

  static adobeInserts = {};
}
