import { Button, Dropdown, Icon, Intro, Message, Switch } from 'elements_v2';
import * as React from 'react';

interface ClipboardProps {
  next: () => void;
}

const Clipboard: React.FC<ClipboardProps> = (props: ClipboardProps) => {
  return (
    <div>
      <Intro noPadding>
        <Intro.Illustration
          style={{ width: 80, marginBottom: 15, textAlign: 'center' }}
        >
          <Icon name="clipboard-check" style={{ fontSize: 56 }} />
        </Intro.Illustration>
        <Intro.Message>
          <h1>Clipboard</h1>
          <p>
            Double-clicking a file will add it
            <br />
            to your clipboard.
          </p>
        </Intro.Message>
        <Button primary inline pixi size="large" onClick={props.next}>
          Got it
        </Button>
      </Intro>
      <Message icon="info-circle" size="small" style={{ marginTop: 50 }}>
        <p style={{ opacity: 0.65, fontSize: 12 }}>
          You can paste the copied file in a program that supports it or
          directly to your explorer.
        </p>
      </Message>
    </div>
  );
};

export default Clipboard;
