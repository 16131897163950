import React from 'react';
// import "./style.scss";
import { format, formatDistance, parse } from 'date-fns';
import { TextContext } from 'contexts/Text';
import { enGB, sv, de, fr, es, ja, it } from 'date-fns/locale';

const locales = { enGB, sv, de, fr, es, jp: ja, it };

export function useDateFns(props) {
  const Text = React.useContext(TextContext);
  const localeString =
    Text.language.id !== 'en'
      ? {
          locale: locales[Text.language.id],
        }
      : { locale: null };

  return {
    format(date, formatStr = 'PP') {
      try {
        return format(date, formatStr, localeString);
      } catch (e) {
        return null;
      }
    },
    parse(dateString, formatStr = 'PP', baseDate, locale) {
      return parse(dateString, formatStr, baseDate, locale);
    },
    formatDistance(date, fromDate) {
      return formatDistance(date, fromDate, localeString);
    },
  };
}

export default useDateFns;
