import localStorageWrapper from 'utils/localStorageWrapper';

export default class Storage {
  static _availableMethods = ['localStorage', 'sessionStorage'];

  static method = 'localStorage';

  static watchers = {};

  static setItem(key, value = '') {
    try {
      const oldValue = Storage.getItem(key);
      let setValue = {
        type: typeof value,
        data: value,
      };
      if (typeof value === 'object') {
        setValue.data = JSON.stringify(value);
      }

      setValue = JSON.stringify(setValue);

      if (Storage.method === 'localStorage') {
        localStorageWrapper.setItem(key, setValue);
      }
      if (Storage.method === 'sessionStorage') {
        sessionStorage.setItem(key, setValue);
      }
      if (Storage.watchers[key]) {
        Storage.callWatchers(key, oldValue, value);
      }
    } catch (e) {
      return null;
    }
  }

  static removeItem(key) {
    try {
      const oldValue = Storage.getItem(key);
      if (Storage.method === 'localStorage') {
        localStorageWrapper.removeItem(key);
      }
      if (Storage.method === 'sessionStorage') {
        sessionStorage.removeItem(key);
      }
      if (Storage.watchers[key]) {
        Storage.callWatchers(key, oldValue, null);
      }
    } catch (e) {
      return null;
    }
  }

  static getItem(key) {
    try {
      let storageValue = {};
      if (Storage.method === 'localStorage') {
        storageValue = localStorageWrapper.getItem(key);
      }
      if (Storage.method === 'sessionStorage') {
        storageValue = sessionStorage.getItem(key);
      }
      if (!storageValue) {
        return null;
      }
      storageValue = JSON.parse(storageValue);
      if (storageValue.type === 'object') {
        storageValue.data = JSON.parse(storageValue.data);
      }
      return (storageValue && storageValue.data) || null;
    } catch (e) {
      return null;
    }
  }

  /**
   * Watch an item for changes.
   * @param {*} key Item to watch
   * @param {(oldValue, newValue)} callback Will be fired when item changes or removed.
   */
  static watchItem(key, callback) {
    if (!Storage.watchers[key]) {
      Storage.watchers[key] = [];
    }
    Storage.watchers[key].push(callback);
    if (Storage.getItem(key)) {
      callback(null, Storage.getItem(key));
    }
  }

  static callWatchers(key, oldValue, newValue) {
    if (Storage.watchers[key]) {
      Storage.watchers[key].forEach((cb) => {
        cb(oldValue, newValue);
      });
    }
  }
}
