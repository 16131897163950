import {
  Box,
  Card,
  Group,
  Image,
  Paper,
  ScrollArea,
  Stack,
} from '@mantine/core';
import { useColorScheme } from '@pixi/AppController';
import { useAssetActions } from '@pixi/components/AssetActions/useAssetActions';
import PixiButton from '@pixi/elements/Button';
import PixiText from '@pixi/elements/Text';
import useStorageUrl from 'hooks/files/useStorageUrl';
import { downloadUrl } from 'utils';

export default function AssetPreviewVariants({
  file,
}: {
  file: Pickit.FileInterface;
}) {
  const { api, getAction } = useAssetActions({
    file,
    confirmProps: {
      zIndex: 99999999,
    },
    dropdownProps: {
      zIndex: 99999999,
    },
  });
  const { generateUrl } = useStorageUrl();
  const colorScheme = useColorScheme();
  return (
    <ScrollArea h="100%">
      <Group w="100%">
        {file.file?.variants?.map((v) => {
          return (
            <Paper
              w="100%"
              maw={570}
              bg={colorScheme === 'dark' ? 'dark.6' : 'gray.0'}
              key={v.id}
              p="lg"
            >
              <Group wrap="nowrap">
                <Box
                  w="100%"
                  h={200}
                  bg={colorScheme === 'dark' ? 'dark.7' : 'gray.1'}
                  p="xs"
                >
                  <Image
                    src={v.url}
                    alt={v.name}
                    w="100%"
                    h="100%"
                    fit="contain"
                  />
                </Box>
                <Stack w="100%" maw={200}>
                  <PixiText>{v.alias}</PixiText>
                  <PixiButton
                    onClick={async () => {
                      getAction('insert')
                        ? api.insertFile(v)
                        : await downloadUrl(
                            generateUrl(v.url),
                            (v.alias || v.name) + '.png',
                          );
                    }}
                  >
                    {getAction('insert') ? 'Insert' : 'Download'}
                  </PixiButton>
                </Stack>
              </Group>
            </Paper>
          );
        })}
      </Group>
    </ScrollArea>
  );
}
