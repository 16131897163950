import React, { useContext, useEffect, useReducer, useRef } from 'react';
import { useUserContext } from 'hooks/useUserContext';
import useSimpleDataStore from 'hooks/warehouse/useSimpleStore';
import FolderForm from 'views/_Manage/Drive/helpers/FolderForm';
import useDispatch from 'hooks/useDispatch';
import useLibraries from 'hooks/useLibraries';
import { useGetContextFromCollectionId } from 'hooks/useContextFromCollection';
import asyncPool from 'utils/async-pool';
import { sleep } from 'utils';
import { PickitDriveContext } from 'contexts/Drive';

export const usePickitDrive = () => useContext(PickitDriveContext);
export const PickitDriveProvider = (props) => {
  const Libraries = useLibraries();
  const User = useUserContext();
  const editItemPrefs = useRef({});
  const [editItem, setEditItem] = useReducer((state, action) => {
    if (!action?._id) {
      editItemPrefs.current = {};
    }
    return { ...action };
  }, {});
  const { data, setData } = useSimpleDataStore(
    User.data?.selectedCommunityAssets || [],
  );

  const getContextFromCollectionId = useGetContextFromCollectionId();

  const { addEventListener, removeEventListener, dispatch } = useDispatch();

  useEffect(() => {
    setData({
      type: 'merge',
      data: User.data?.selectedCommunityAssets || [],
    });
  }, [User.data?.selectedCommunityAssets]);

  async function getAllItemsByImportReferences(from, references, checksums) {
    const response = await User.request.files.assets.getItemsByImportReference(
      from,
      references,
      checksums,
    );
    return response;
  }
  async function getAllItemsByImportJobId(jobId) {
    const response =
      await User.request.files.assets.getAllItemsByImportJobId(jobId);
    return response.data;
  }
  async function getAllItemsInFolder(folderId) {
    const response =
      await User.request.files.assets.getFolderItemsFlat(folderId);
    return response.items;
  }

  async function createFolderStructureFromFiles(_files, preferences = {}) {
    let files = JSON.parse(JSON.stringify(_files)); // Mutate files
    function getFoldersFromString(path) {
      if (preferences.skipToFolder) {
        path = path.replace(preferences.skipToFolder, '');
      }
      const folders = path.split('/');
      return folders.filter((t) => !!t);
    }

    const folderMaps = {};
    files = files.map((f) => {
      const id =
        typeof preferences?.keys?.identifier === 'function'
          ? preferences?.keys?.identifier(f)
          : f[preferences?.keys?.identifier];
      let path =
        typeof preferences?.keys?.path === 'function'
          ? preferences?.keys?.path(f)
          : f[preferences?.keys?.path];
      path = getFoldersFromString(path);
      path.push(id);
      f.path = `/${path.join('/')}`.replace('//', '/');
      f.id = id;
      return f;
    });
    files.forEach((file) => {
      const folders = file.path.split('/').filter((t) => !!t);
      folders.forEach((folder, level) => {
        const folderObject = {
          folder,
          refObject: files.find((f) => f.id === folder),
          parentLevel: folders[level - 1],
        };
        if (!folderMaps[level]) {
          folderMaps[level] = [folderObject];
        } else if (
          !folderMaps[level].find(
            (f) =>
              f.folder === folder && f.parentLevel === folderObject.parentLevel,
          )
        ) {
          folderMaps[level].push(folderObject);
        }
      });
    });
    const allFolders = [];
    const createdFolderMap = {};
    for (let i = 0; i < Object.keys(folderMaps).length; i++) {
      const folders = folderMaps[i];
      await asyncPool(4, folders, async (folder) => {
        const id =
          typeof preferences?.keys?.identifier === 'function'
            ? preferences?.keys?.identifier(folder.refObject)
            : folder.refObject[preferences?.keys?.identifier];
        const name =
          typeof preferences?.keys?.name === 'function'
            ? preferences?.keys?.name(folder.refObject)
            : folder.refObject[preferences?.keys?.name];
        const alreadyUploaded = preferences?.skipAssets?.find(
          (a) => a.import?.reference === id,
        );
        let newFolder;
        if (alreadyUploaded) {
          newFolder = alreadyUploaded;
        } else {
          newFolder = await saveItem(
            {
              name,
              type: 'folder',
              ...(id
                ? {
                    import: {
                      from: preferences?.source,
                      reference: id,
                      jobId: preferences?.sourceId,
                    },
                  }
                : {}),
              ...(folder.parentLevel
                ? {
                    inFolder: allFolders?.find(
                      (f) => f.import?.reference === folder.parentLevel,
                    )?._id,
                  }
                : !i && preferences?.rootFolder
                  ? {
                      inFolder: preferences?.rootFolder,
                    }
                  : {}),
            },
            allFolders?.find((f) => f.import?.reference === folder.parentLevel)
              ?._id,
          );
        }
        if (!createdFolderMap[i]) {
          createdFolderMap[i] = {};
        }
        createdFolderMap[i][id] = newFolder;
        allFolders.push(newFolder);
        await sleep(1000);
      });
    }
    return {
      folderMap: createdFolderMap,
      folders: allFolders,
      files: files.reduce((final, file) => {
        const id =
          typeof preferences?.keys?.identifier === 'function'
            ? preferences?.keys?.identifier(file)
            : file[preferences?.keys?.identifier];
        const parentFolder = allFolders.find(
          (folder) => folder?.import?.reference === id,
        );
        return {
          ...final,
          [id]: parentFolder?._id,
        };
      }, {}),
    };
  }

  async function getItem(itemId) {
    const newItem = await User.request.files.assets.getFolderItem(itemId);
    if (newItem?.data?._id) {
      setData({
        type: 'merge',
        data: newItem?.data,
        key: '_id',
      });
      return newItem?.data;
    }
    return null;
  }

  async function deleteItems(items, prefs) {
    const data = await User.request.files.assets.deleteMultipleItems(
      items.map((i) => i._id),
      prefs,
    );
    dispatch('onItemDelete', items);
    setData({
      type: 'delete',
      data: items,
      key: '_id',
    });
    if (prefs.isDeleteFromLibraries) {
      const mediaCollections = items.filter(
        (i) => i.referenceLocation === 'mediacollections',
      );
      const documentCollections = items.filter(
        (i) => i.referenceLocation === 'mediacollections',
      );

      if (mediaCollections?.length) {
        Libraries.media.setData(
          mediaCollections.map((item) => ({
            store: 'collections',
            type: 'delete',
            data: { _id: item?.referenceData?._id },
            key: '_id',
          })),
        );
      }
      if (documentCollections?.length) {
        Libraries.media.setData(
          documentCollections.map((item) => ({
            store: 'collections',
            type: 'delete',
            data: { _id: item?.referenceData?._id },
            key: '_id',
          })),
        );
      }
    }
    return data;
  }

  async function loadFolder(folderId, prefs = {}) {
    const data = await User.request.files.assets.load(folderId, prefs);
    if (prefs?.no_state) {
      return data;
    }
    setData([
      {
        type: 'merge',
        data: [data.folder],
        key: '_id',
      },
    ]);
    return data;
  }

  async function getFolderItems(folderId, prefs, readOnly) {
    const response = await User.request.files.assets.getFolderItems(folderId, {
      ...prefs,
      ...(readOnly ? { published: 1 } : {}),
    });
    return response;
  }

  async function saveItem(updates) {
    const data = await User.request.files.assets.saveFolderItem(updates);
    if (data.deletedAssets?.length) {
      dispatch('onItemDelete', data.deletedAssets);
      setData({
        type: 'delete',
        data: data.deletedAssets,
        key: '_id',
      });
    }
    setData({
      type: 'update',
      data: data.item,
      key: '_id',
    });
    dispatch('onItemSave', data.item);
    if (data?.modifiedCollections?.documents?.length) {
      Libraries.documents.setData(
        data?.modifiedCollections?.documents?.map((c) => ({
          store: 'collections',
          type: 'update',
          data: c,
          key: '_id',
        })),
      );
    }
    if (data?.modifiedCollections?.media?.length) {
      Libraries.media.setData(
        data?.modifiedCollections?.media?.map((c) => ({
          store: 'collections',
          type: 'update',
          data: c,
          key: '_id',
        })),
      );
    }
    if (updates?.publish?.libraries?.media?.importFromCollection) {
      const context = getContextFromCollectionId(
        updates?.publish?.libraries?.media?.importFromCollection,
      );
      const files = await context.getFiles({
        limit: 9000,
        filter: {
          collections: updates?.publish?.libraries?.media?.importFromCollection,
        },
      });
      await saveItems(
        files.documents.map((f) => ({
          name: f.name,
          referenceData: f._id,
          referenceLocation: f.type,
          inFolder: data.item._id,
          import: {
            from: 'collection',
          },
          type: 'file',
        })),
      );
    }
    return data.item;
  }
  async function saveItems(items, set = {}) {
    if (!items?.length) {
      return false;
    }
    const data = await User.request.files.assets.saveFolderItemMultiple(
      items,
      set,
    );
    if (data.items?.length) {
      setData({
        type: 'merge',
        data: data.items,
        key: '_id',
      });
      dispatch('onItemSave', data.items);
    }
  }

  async function getItemsByIds(ids) {
    const items = await User.request.files.assets.getItemsByIds(ids);
    setData({
      type: 'merge',
      data: items,
      key: '_id',
    });
    return items;
  }

  const values = {
    data,
    setData,
    saveItem,
    saveItems,
    getFolderItems,
    getItemFromState: (folderId) => data.find((f) => f._id === folderId),
    getItem,
    loadFolder,
    deleteItems,
    editItem: (item, prefs) => {
      editItemPrefs.current = prefs;
      setEditItem(item);
    },
    addEventListener,
    removeEventListener,
    createFolderStructureFromFiles,
    getAllItemsInFolder,
    getAllItemsByImportReferences,
    getAllItemsByImportJobId,
    getItemsByIds,
    getAssetsByLibrary: (library) => {
      return data.filter((d) => !!d?.publish?.libraries?.[library]);
    },
  };
  return (
    <PickitDriveContext.Provider value={values}>
      {editItem?._id && (
        <FolderForm
          onClose={() => setEditItem({})}
          item={editItem}
          key={editItem._id}
          preferences={editItemPrefs?.current}
          onSave={async (metadata) => {
            await saveItem(metadata);
            setEditItem({});
          }}
        />
      )}
      {props.children}
    </PickitDriveContext.Provider>
  );
};
