import {
  Badge,
  Group,
  Image,
  Loader,
  TextProps,
  ThemeIcon,
} from '@mantine/core';
import PixiButton from '@pixi/elements/Button';
import PixiButtonLink from '@pixi/elements/Button/Link';
import PixiIcon from '@pixi/elements/Icon';
import PixiTooltip from '@pixi/elements/Tooltip';
import { useUserContext } from 'hooks';
import { useEffect, useState } from 'react';
import { copyTextToClipboard, openInNewTab } from 'utils';
import { useAssetEditPanel } from '../context';
import { Detail, SectionCollapse } from '../elements';
import {
  openCollectionPreview,
  openFileSettingsPopup,
} from '@pixi/AppController';
import PixiDropdown from '@pixi/elements/Dropdown';
import PixiConfirm from '@pixi/elements/Confirm';
import SelectCollection from '@pixi/components/SelectCollection';
import PixiText from '@pixi/elements/Text';
import { truncate } from '@pixi/helpers/utils';

type AppearsIn = {
  collections: Pickit.CollectionInterface[];
  boards: Pickit.BoardInterface[];
  brandGuides: Pickit.BoardInterface[];
  mainGuide: Pickit.BoardInterface[];
  overview: Pickit.BoardInterface[];
  entrypoints: Pickit.BoardInterface[];
};

function Usage() {
  const { file, context } = useAssetEditPanel();
  const [appearsIn, setAppearsIn] = useState<AppearsIn>({} as AppearsIn);
  const [isAppearsInLoading, setIsAppearsInLoading] = useState(false);

  async function getFileSharing() {
    setIsAppearsInLoading(true);
    const data = await context.request.getFileSharing(file._id);
    setAppearsIn(data);
    setIsAppearsInLoading(false);
  }

  useEffect(() => {
    getFileSharing();
  }, [file._id]);

  const noResults = Object.keys(appearsIn).every(
    (key) => !appearsIn[key as keyof AppearsIn]?.length,
  );
  return (
    <>
      {isAppearsInLoading && <Loader />}
      {noResults && !isAppearsInLoading && (
        <PixiText fs="italic" c="dimmed" size="xs">
          Not used anywhere
        </PixiText>
      )}
      {Object.keys(appearsIn).map((key) => {
        const cleanName =
          key === 'mainGuide'
            ? 'Main guide'
            : key === 'boardOverview'
              ? 'Board Overview'
              : key === 'entrypoints'
                ? 'Entrypoint'
                : key === 'brandGuides'
                  ? 'Brand guide'
                  : key === 'boards'
                    ? 'Board'
                    : key === 'collections'
                      ? 'Collection'
                      : key === 'folders'
                        ? 'Drive Folder'
                        : key;
        if (appearsIn[key as keyof AppearsIn]?.length > 0) {
          return appearsIn[key as keyof AppearsIn].map((c) => (
            <Detail
              key={c._id}
              left={cleanName}
              right={c.name}
              column
              actions={[
                {
                  icon: 'eye',
                  label: 'View & edit',
                  asButton: true,
                  onClick: () => {
                    if (key === 'collections') {
                      openCollectionPreview(c as Pickit.CollectionInterface, {
                        readOnly: false,
                      });
                    }
                    if (key === 'folders') {
                      openInNewTab(
                        `${window.location.origin}/#/manage/drive?path=root${(c as any).path.length ? '-' : ''}${(c as any).path.join('-')}-${c._id}`,
                      );
                    }
                    if (key === 'boards') {
                      openInNewTab(
                        `${window.location.origin}/#/manage/boards/builder/${c._id}`,
                      );
                    }
                    if (key === 'brandGuides') {
                      openInNewTab(
                        `${window.location.origin}/#/manage/library/brand/sub-guides/builder/${c._id}`,
                      );
                    }
                    if (key === 'mainGuide') {
                      openInNewTab(
                        `${window.location.origin}/#/manage/library/brand/brand-guide`,
                      );
                    }
                    if (key === 'overview') {
                      openInNewTab(
                        `${window.location.origin}/#/manage/boards/overview`,
                      );
                    }
                    if (key === 'entrypoints') {
                      openInNewTab(
                        `${window.location.origin}/#/manage/library/brand/brand-guide/${c._id}`,
                      );
                    }
                  },
                },
              ]}
            />
          ));
        }
      })}
    </>
  );
}

export default function AssetEditPanelSharing() {
  const { file, context, disabledSections } = useAssetEditPanel();

  const permissions: Record<
    Pickit.FileInterface['share_to'],
    {
      label: string;
      tooltip?: string;
      rightProps?: TextProps;
    }
  > = {
    none: {
      label: 'Admins only',
      rightProps: {
        c: 'red',
        fw: 600,
      },
    },
    'not-set': {
      label: 'Inherit',
      tooltip:
        'Inherit permissions from parent asset such as collection or board',
    },
    specific: {
      label: 'Specific',
    },
    all: {
      label: 'All',
    },
  };
  const url = `https://embed.pickit.com/files/${file.communitySlug}/${file._id}`;
  const embedUrl = `${`${url}.${file?.file?.previewVideo?.id ? 'mp4' : file?.file?.ext}`}`;
  return (
    <>
      {!disabledSections?.includes('sharing') && (
        <SectionCollapse label="Access rights" defaultOpen>
          <Detail
            left="Permission"
            right={
              <Group gap="xs" align="center" justify="flex-end">
                {permissions[file.share_to]?.label}
                {permissions[file.share_to]?.tooltip && (
                  <PixiTooltip label={permissions[file.share_to]?.tooltip}>
                    <PixiButton size="xs" px="xs" color="dark" variant="light">
                      <PixiIcon name="circle-info" size="xs" />
                    </PixiButton>
                  </PixiTooltip>
                )}
              </Group>
            }
            rightProps={permissions[file.share_to]?.rightProps}
          />
          {file?.share_to !== 'not-set' || file.share_to_anonymous ? (
            <Detail
              left="Anonymous access"
              right={
                file.share_to_anonymous
                  ? 'Enabled'
                  : file.share_to === 'not-set'
                    ? 'Inherit'
                    : 'Disabled'
              }
            />
          ) : (
            <></>
          )}
          <PixiButton
            variant="light"
            size="xs"
            onClick={() => {
              openFileSettingsPopup(file, {
                defaultTab: 'sharing',
              });
            }}
          >
            Edit
          </PixiButton>
        </SectionCollapse>
      )}
      {!disabledSections?.includes('collections') && (
        <SectionCollapse label="Collections">
          {context.stores.data.collections
            .filter((c: Pickit.CollectionInterface) => {
              return file.collections?.includes(c._id);
            })
            .map((col: Pickit.CollectionInterface) => (
              <Detail
                key={col._id}
                left={truncate(col.name, 25, '...')}
                leftTooltip={col.name}
                right={
                  <PixiDropdown
                    width={260}
                    position="bottom-end"
                    target={
                      <PixiButton
                        size="xs"
                        px="xs"
                        variant="light"
                        color="dark"
                        w="auto"
                      >
                        <PixiIcon name="ellipsis" />
                      </PixiButton>
                    }
                    zIndex={10}
                  >
                    <PixiDropdown.Item
                      onClick={() => {
                        openCollectionPreview(
                          col as Pickit.CollectionInterface,
                          {
                            readOnly: false,
                          },
                        );
                      }}
                      icon="eye"
                    >
                      View & edit
                    </PixiDropdown.Item>
                    <PixiDropdown.Divider />
                    <PixiConfirm
                      title="Remove from collection"
                      description="Selected files will be removed from the collection."
                      confirmLabel="Remove"
                      confirmProps={{
                        color: 'red',
                      }}
                      onConfirm={async () => {
                        await context.savePartial({
                          _id: file._id,
                          $pull: {
                            collections: col._id,
                          },
                        });
                      }}
                    >
                      <PixiDropdown.Item icon="xmark" color="red">
                        Remove from collection
                      </PixiDropdown.Item>
                    </PixiConfirm>
                  </PixiDropdown>
                }
              />
            ))}
          <SelectCollection
            target={
              <PixiButton
                size="xs"
                variant="light"
                leftSection={<PixiIcon name="rectangle-history-circle-plus" />}
              >
                Add to collection
              </PixiButton>
            }
            customFilter={(c) => !file.collections?.includes(c._id)}
            type={context.type}
            onCollectionClick={async (collection) => {
              await context.savePartial({
                _id: file._id,
                $addToSet: {
                  collections: collection._id,
                },
              });
            }}
          />
        </SectionCollapse>
      )}
      {!disabledSections?.includes('embed') && (
        <SectionCollapse label="Embed">
          <Detail
            left="Embed enabled"
            right={file.file?.allowEmbedCode ? 'Yes' : 'No'}
          />
          {file.file?.allowEmbedCode && (
            <>
              <Detail
                left="Embed link"
                right={
                  <PixiButton
                    size="compact-xs"
                    fz={11}
                    variant="light"
                    color="dark"
                    onClick={() => {
                      copyTextToClipboard(embedUrl);
                    }}
                  >
                    Copy url
                  </PixiButton>
                }
              />
              <Detail
                left="Embed code"
                right={
                  <PixiButton
                    size="compact-xs"
                    fz={11}
                    variant="light"
                    color="dark"
                    onClick={() => {
                      copyTextToClipboard(
                        file.file?.previewVideo?.url
                          ? `<video><source src="${embedUrl}.${
                              file?.file?.previewVideo?.id
                                ? 'mp4'
                                : file?.file?.ext
                            }" type="video/mp4" /></video>`
                          : `<img src="${embedUrl}.${
                              file?.file?.previewVideo?.id
                                ? 'mp4'
                                : file?.file?.ext
                            }" />`,
                      );
                    }}
                  >
                    Copy code
                  </PixiButton>
                }
              />
            </>
          )}
          <PixiButton
            variant="light"
            size="xs"
            onClick={() => {
              openFileSettingsPopup(file, {
                defaultTab: 'embed',
              });
            }}
          >
            Edit
          </PixiButton>
        </SectionCollapse>
      )}
      {!disabledSections?.includes('usage') && (
        <SectionCollapse label="Appears in">
          <Usage />
        </SectionCollapse>
      )}
    </>
  );
}
