import { useUserContext } from 'hooks';
import useContextFromFile from 'hooks/useContextFromFile';
import useParsedFile from 'hooks/files/useParsedFile';
import { useEffect, useState } from 'react';
import { Box, Group, TextInput } from '@mantine/core';
import PixiTooltip from '@pixi/elements/Tooltip';
import PixiForm from '@pixi/elements/Form';
import PixiButton from '@pixi/elements/Button';
import PixiIcon from '@pixi/elements/Icon';
import { AssetThumbnail } from '@pixi/components/AssetThumbnail';
import { AssetPreviewFacialRecognitionPreferences } from '../types';
import PixiDropdown from '@pixi/elements/Dropdown';
import AssetPreviewFacialRecognitionSelect from './AssetPreviewFacialRecognitionSelect';

export const AssetPreviewFacialRecognition = ({
  file,
  preferences,
}: {
  file: Pickit.FileInterface;
  preferences?: AssetPreviewFacialRecognitionPreferences;
}) => {
  const User = useUserContext();
  const context = useContextFromFile(file);
  const metadata: Pickit.FileInterface = useParsedFile({ context, file });

  const [faceGroups, setFaceGroups] = useState<
    {
      _id: string;
      name: string;
      faces: { faceId: string; assetId: string }[];
    }[]
  >([]);

  async function getFaceGroups() {
    const data = await context.request.getFacesByIds(
      metadata?.vision?.faceGroups,
    );
    setFaceGroups(data.faces);
  }

  useEffect(() => {
    getFaceGroups();
  }, []);

  let imageSize = metadata.file?.previews?.[0] as {
    width: number;
    height: number;
  };
  if (
    imageSize?.height &&
    imageSize?.width &&
    metadata.file?.previews?.[1] &&
    (imageSize?.height > 2000 || imageSize?.width > 2000)
  ) {
    imageSize = {
      width: metadata.file?.previews[1].width,
      height: metadata.file?.previews[1].height,
    };
  }
  if (
    imageSize?.height &&
    imageSize?.width &&
    metadata.file?.previews?.[2] &&
    (imageSize?.height > 2000 || imageSize?.width > 2000)
  ) {
    imageSize = {
      width: metadata.file?.previews[2].width,
      height: metadata.file?.previews[2].height,
    };
  }
  if (
    imageSize?.height &&
    imageSize?.width &&
    metadata.file?.previews?.[3] &&
    (imageSize?.height > 2000 || imageSize?.width > 2000)
  ) {
    imageSize = {
      width: metadata.file?.previews[3].width,
      height: metadata.file?.previews[3].height,
    };
  }

  return (
    <>
      <Group w="100%" h="100%" mih={1} wrap="nowrap" gap={0} align="stretch">
        <Group
          align="center"
          mih={1}
          justify="center"
          w="100%"
          h="100%"
          pos="relative"
        >
          <Box maw="100%" h="100%" pos="relative" mih={1} miw={1}>
            <svg
              style={{
                width: '100%',
                height: '100%',
                position: 'absolute',
                zIndex: 11,
              }}
              viewBox={`0 0 ${imageSize?.width} ${imageSize?.height}`}
            >
              <defs>
                <mask id="facesMask">
                  <rect width="100%" height="100%" fill="white" />
                  {metadata?.vision?.faces?.map((face, index) => {
                    const { width, height, top, left } = face.faceRectangle;
                    return (
                      <rect
                        key={face._id}
                        x={left}
                        y={top}
                        width={width}
                        height={height}
                        fill="black"
                      />
                    ); // Black for faces, to keep them clear
                  })}
                </mask>
              </defs>
              <rect
                width="100%"
                height="100%"
                fill="rgba(0,0,0,0.4)"
                mask="url(#facesMask)"
              />
            </svg>
            <Box pos="absolute" w="100%" h="100%" style={{ zIndex: 11 }}>
              {metadata?.vision?.faces?.map((face) => {
                const width = imageSize?.width || 0;
                const height = imageSize?.height || 0;
                const widthPercentage =
                  (face.faceRectangle.width / width) * 100;
                const heightPercentage =
                  (face.faceRectangle.height / height) * 100;
                const topPercentage = (face.faceRectangle.top / height) * 100;
                const leftPercentage = (face.faceRectangle.left / width) * 100;

                // Assuming minWidth and minHeight are defined in pixels directly
                const minWidth = 100; // Example minimum width in pixels
                const minHeight = 100; // Example minimum height in pixels

                // Calculate the actual width and height of the face box in pixels
                const actualWidth = (widthPercentage / 100) * width;
                const actualHeight = (heightPercentage / 100) * height;

                // Determine if adjustments are needed based on minWidth and minHeight
                const needWidthAdjustment = actualWidth < minWidth;
                const needHeightAdjustment = actualHeight < minHeight;

                // Calculate the adjusted width and height in pixels, ensuring they meet the minimum
                const adjustedWidth = needWidthAdjustment
                  ? minWidth
                  : actualWidth;
                const adjustedHeight = needHeightAdjustment
                  ? minHeight
                  : actualHeight;

                // Convert the adjusted width and height back to percentages of the image size
                const adjustedWidthPercentage = (adjustedWidth / width) * 100;
                const adjustedHeightPercentage =
                  (adjustedHeight / height) * 100;

                // Calculate the difference in size to adjust the position
                const widthDifference = adjustedWidth - actualWidth;
                const heightDifference = adjustedHeight - actualHeight;

                // Adjust the left and top percentages to center the box, converting adjustments back to percentages
                const adjustedLeftPercentage =
                  leftPercentage - (widthDifference / 2 / width) * 100;
                const adjustedTopPercentage =
                  topPercentage - (heightDifference / 2 / height) * 100;

                const group = faceGroups?.find((group) =>
                  group.faces.find((f) => f.faceId === face.persistedFaceId),
                );

                if (!group?._id) {
                  return <></>;
                }

                return (
                  <PixiTooltip
                    key={face?._id}
                    position="bottom"
                    color="white"
                    opened={
                      preferences?.highlightFace === group?._id
                        ? true
                        : undefined
                    }
                    withinPortal={false}
                    c="dark"
                    zIndex={3}
                    label={group?.name || `Unknown person`}
                  >
                    {(isOpen, setIsOpen, setIsForceOpen) => (
                      <Box
                        key={face?._id}
                        style={{
                          width: `${adjustedWidthPercentage}%`,
                          height: `${adjustedHeightPercentage}%`,
                          position: 'absolute',
                          border: '2px solid #FFF',
                          borderRadius: 5,
                          left: `${adjustedLeftPercentage}%`,
                          top: `${adjustedTopPercentage}%`,
                          zIndex: isOpen ? 999 : 0,
                        }}
                      >
                        {isOpen && (
                          <Box>
                            <PixiForm<{
                              name: string;
                            }>
                              type="dropdown"
                              onOpen={() => {
                                setIsForceOpen(true);
                              }}
                              onClose={() => {
                                setIsForceOpen(false);
                              }}
                              target={
                                <PixiButton
                                  size="compact-xs"
                                  variant="white"
                                  c="dark"
                                >
                                  <PixiIcon
                                    size="xs"
                                    name="pencil"
                                    variant="filled"
                                  />
                                </PixiButton>
                              }
                              withinPortal={false}
                              zIndex={0}
                              title={group?.name || `Unknown person`}
                              onSubmit={async ({ name }) => {
                                const data = await context.request.saveFace({
                                  _id: group?._id as string,
                                  name,
                                });
                                User.refreshProfile();
                                setFaceGroups((groups) =>
                                  groups.map((g) =>
                                    g._id === group._id ? data : g,
                                  ),
                                );
                                setIsForceOpen(false);
                              }}
                              form={{
                                name: {
                                  key: 'name',
                                  value: group?.name || `Unknown person`,
                                  render: ({ value, setValue }) => (
                                    <TextInput
                                      type="text"
                                      value={value}
                                      onChange={(e) => setValue(e.target.value)}
                                    />
                                  ),
                                },
                              }}
                            />
                            <AssetPreviewFacialRecognitionSelect
                              target={
                                <PixiButton
                                  size="compact-xs"
                                  variant="white"
                                  c="dark"
                                >
                                  <PixiIcon
                                    size="xs"
                                    name="arrows-cross"
                                    variant="filled"
                                  />
                                </PixiButton>
                              }
                              group={group}
                              onSubmit={getFaceGroups}
                            />
                          </Box>
                        )}
                      </Box>
                    )}
                  </PixiTooltip>
                );
              })}
            </Box>
            <AssetThumbnail
              size="large"
              file={file}
              w="auto"
              style={() => ({
                width: '100%',
                height: '100%',
                objectFit: 'contain',
                objectPosition: 'center',
                position: 'relative',
                zIndex: 2,
              })}
            />
          </Box>
        </Group>
      </Group>
    </>
  );
};
