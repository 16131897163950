import ApiService from './_ApiService';

class User extends ApiService {
  djangoProfile = '';

  async getAllFollowingCollections(page_nr) {
    if (!page_nr) {
      page_nr = 1;
    }
    let collections = [];
    const res = await this.getUserFollowingCollections(page_nr);
    if (res.data) {
      collections = res.data;
    }
    if (collections.length === 50) {
      const collectionsNextPage = await this.refreshFollowingCollections(
        page_nr + 1
      );
      if (collectionsNextPage) {
        collections = [...collections, ...collectionsNextPage];
      }
    }
    return collections;
  }

  async getAllFavorites(page_nr) {
    if (!page_nr) {
      page_nr = 1;
    }
    let photos = [];
    const favorites = await this.getFavorites(this.djangoProfile.id, page_nr);
    if (favorites.collection_photos) {
      photos = favorites.collection_photos;
    }
    if (photos.length === 50) {
      const favoritesNextPage = await this.refreshFavorites(page_nr + 1);
      if (favoritesNextPage) {
        photos = [...photos, ...favoritesNextPage];
      }
    }
    return photos;
  }

  async login(username = '', password = '', persistLogin = false) {
    const request = await this.request('/token/create/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: { username, password, keep_me_logged_in: persistLogin },
    }).then(this.handleJSONResponse);
    return request;
  }

  async checkToken(token = '') {
    const request = await this.request(`/token/check/?token=${token}`).then(
      this.handleJSONResponse
    );
    return request;
  }

  getContributionDownloadLinks(slug, collectionSlug) {
    let collection_param = '';
    if (collectionSlug) {
      collection_param = `&collection_slug=${collectionSlug}`;
    }
    return this.request(
      `/contributions/${slug}/download_links/?with_token=1&show_initial=1${collection_param}`
    ).then(this.handleJSONResponse);
  }

  async getSpaces() {
    const request = await this.request(`/user/spaces/`).then(
      this.handleJSONResponse
    );
    return request;
  }

  async getInfo(userId) {
    const request = await this.request(`/users/${userId}/`).then(
      this.handleJSONResponse
    );
    return request;
  }

  async getCountryByIp() {
    const request = await this.request('/get_ip_country/').then(
      this.handleJSONResponse
    );
    return request;
  }

  getContributionBase64(slug) {
    return this.request(`/contributions/${slug}/download/base64/`).then(
      this.handleJSONResponse
    );
  }

  getContributionBase64FromUrl(url, width) {
    return this.request(url + (width ? `?width=${width}` : ''), null, null, {
      skipBaseUrl: true,
    }).then(this.handleTextResponse);
  }

  async getPersonalInformation() {
    return this.request('/gdpr/request/').then(this.handleJSONResponse);
  }

  async requestPersonalInformation(data) {
    return this.request('/gdpr/request/', {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    }).then(this.handleJSONResponse);
  }

  async search(params) {
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'pickit-community': this.community,
      },
      skipBaseUrl: true,
      requestUrl: window.pickit.config.SERVICE_URL,
    };
    const req = await this.request(
      `/documents/search?${new URLSearchParams(params).toString()}`,
      options
    );
    const data = await req.json();
    return data;
  }

  async updateProfile(data) {
    return this.request('/settings/profile/', {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    }).then(this.handleJSONResponse);
  }

  async updateEmail(data) {
    return this.request('/users/update_email', {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    }).then(this.handleJSONResponse);
  }

  async updatePassword(old_password, new_password, new_password_2) {
    return this.request('/settings/password/', {
      method: 'PUT',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: {
        old_password,
        new_password1: new_password,
        new_password2: new_password_2,
      },
    });
  }

  async getFavorites(userId, page_nr) {
    return this.request(
      `/user/${userId}/collection/favorite/?page_limit=50&page_nr=${page_nr}`,
      {
        method: 'GET',
      }
    ).then(this.handleJSONResponse);
  }

  async favoriteContribution(status, slug) {
    return this.request(`/contributions/${slug}/favourites/`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    }).then(this.handleJSONResponse);
  }

  async getUserSavedCollections(userId, page_nr) {
    return this.request(
      `/user/${userId}/collections/?page_limit=50&page_nr=${page_nr}`,
      {
        method: 'GET',
      }
    ).then(this.handleJSONResponse);
  }

  async getUserCollections(userId, page_nr) {
    return this.request(
      `/user/${userId}/collections/?page_limit=50&page_nr=${page_nr}`,
      {
        method: 'GET',
      }
    ).then(this.handleJSONResponse);
  }

  async getCollectionFollowers(collectionSlug) {
    return this.request(`/collections/${collectionSlug}/followers/`).then(
      this.handleJSONResponse
    );
  }

  async createCollection(title) {
    return this.request('/collection/create/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      withoutApi: true,
      body: {
        title,
        slug: 'new-collection',
      },
    }).then(this.handleJSONResponse);
  }

  getSearchContributions(params) {
    if (params.filters) {
      params.filters = this.configureFilter(params.filters);
    }
    return this.request(`/search/contributions/${this.stringify(params)}`).then(
      this.handleJSONResponse
    );
  }

  getCollectionContributions(params, slug) {
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'pickit-api-key': window.pickit.keys.PICKIT_API,
      },
      requestUrl: `https://proxy.${window.pickit.config.BASE_URL}/api/services`,
      skipBaseUrl: true,
    };
    if (params.filters) {
      params.filters = this.configureFilter(params.filters);
    }
    return this.request(
      `/${slug}/image-rights${this.stringify(params)}`,
      options
    ).then(this.handleJSONResponse);
  }

  async removeFromCollection(userId, collectionSlug, contributionSlug) {
    return this.request(
      `/user/${userId}/collection/${collectionSlug}/contribution/${contributionSlug}/delete/`,
      {
        method: 'GET',
      }
    ).then(this.handleJSONResponse);
  }

  async addToCollection(userId, collectionSlug, contributionSlug) {
    return this.request(
      `/users/${userId}/collection/${collectionSlug}/add/photo`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        withoutApi: true,
        body: {
          contribution_slug: contributionSlug,
        },
      }
    ).then(this.handleJSONResponse);
  }

  async modifyCollection(userId, collectionSlug, data) {
    return this.request(
      `/users/${userId}/collection/${collectionSlug}/modify`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        withoutApi: true,
        body: JSON.stringify(data),
      }
    ).then(this.handleJSONResponse);
  }

  async deleteCollection(userId, collectionSlug) {
    return this.request(
      `/users/${userId}/collection/${collectionSlug}/delete/`,
      {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        withoutApi: true,
      }
    ).then(this.handleJSONResponse);
  }

  async promoteCollection(collectionSlug) {
    return this.request(`/collection/${collectionSlug}/promote/`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
    });
  }

  getUserCollection(userId, slug, params) {
    return this.request(
      `/user/${userId}/collection/${slug}/${this.stringify(
        this.addContentFilter(params)
      )}`
    ).then(this.handleJSONResponse);
  }

  getUserFollowingCollections(page_nr) {
    return this.request(
      `/user/collections/following/?page_limit=50&page_nr=${page_nr}`
    ).then(this.handleJSONResponse);
  }

  followCollection(collectionSlug) {
    return this.request(`/collections/${collectionSlug}/follow/`, {
      method: 'POST',
    }).then(this.handleJSONResponse);
  }

  getCategoryContributions(params, categoryId) {
    params = {
      ...params,
      filters: JSON.stringify([
        {
          name: 'categories',
          value: categoryId,
        },
      ]),
    };
    return this.request(
      `/search/contributions/${this.stringify(
        Object.assign(params, {
          explicity_level: this.contentFilter,
          obj_output: true,
        })
      )}`
    ).then(this.handleJSONResponse);
  }

  checkQueryResults(words) {
    return this.request('/statistic/counter/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        queries: words,
      }),
    }).then(this.handleJSONResponse);
  }

  getUserProfile(userId) {
    return this.request(`/users/${userId}/`).then(this.handleJSONResponse);
  }

  getUserProfileWithoutId() {
    return this.request(`/profile/`).then(this.handleJSONResponse);
  }
}

export default User;
