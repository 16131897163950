import { useState, useEffect } from 'react';
import { useUserContext } from 'hooks';
import { getStore, useKeySelector } from '@pixi/store';
import System from '@pixi/System';
import { UserRequest } from 'contexts/Providers/UserProvider';

let pendingRequest = null;
let idAccumulator = [];
let batchTimeout = null;

export const getUsersBatched = (value, fromCommunity) => {
  // Convert value to an array if it's not already one
  const values = Array.isArray(value) ? value : [value];

  // Add new values to the accumulator
  idAccumulator = [...new Set([...idAccumulator, ...values])];

  // Clear existing timeout if it exists
  if (batchTimeout && !pendingRequest) {
    clearTimeout(batchTimeout);
  }

  // Create a new promise if one doesn't already exist
  if (!pendingRequest) {
    pendingRequest = new Promise((resolve, reject) => {
      batchTimeout = setTimeout(async () => {
        try {
          const searchValue = idAccumulator.join(',');
          const users = await UserRequest.proxy.getUsersV2(
            {
              search: searchValue,
              include_api_users: 1,
            },
            fromCommunity,
          );
          getStore('LIST_USERS').addOrUpdate(users.data);
          resolve(users.data);
        } catch (e) {
          reject(e);
        } finally {
          // Reset the accumulator and pendingRequest for the next batch
          idAccumulator = [];
          pendingRequest = null;
        }
      }, 200); // Use a shorter delay if needed to batch more aggressively
    });
  }

  return pendingRequest;
};

export function DjangoIdToImage(props) {
  const User = useUserContext();

  const listUser = useKeySelector('LIST_USERS', props.value);

  useEffect(() => {
    if (props.value && !listUser?.id) {
      getUsersBatched(User, props.value, props.fromCommunity);
    }
  }, [props.value, User, props.fromCommunity, listUser]);

  return listUser?.avatar_url;
}
