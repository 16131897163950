import React from 'react';
import './style.scss';
import { Button, Icon, Tooltip } from 'elements_v2';

function Section(props) {
  const className = ['Pixi Pixi__EditPanel__content__section'];
  if (props.focused) {
    className.push('Pixi Pixi__EditPanel__content__section--focused');
  }
  return (
    <div className={className.join(' ')}>
      {props.title && (
        <div className="Pixi Pixi__EditPanel__content__section__title">
          <span>{props.title}</span>
          <div className="Pixi__EditPanel__content__section__title__toolbar">
            {props.toolbar}
            {props.onEdit && !props.hideEditButton && (
              <Icon
                name={props.icon || 'PencilFill'}
                onClick={props.onEdit}
                bubble="large"
                style={{ fontSize: 14 }}
              />
            )}
          </div>
          {props.onCancel && <span onClick={props.onCancel}>Cancel</span>}
        </div>
      )}
      {props.children}
    </div>
  );
}

function Row(props) {
  return (
    <div className="Pixi Pixi__EditPanel__content__row">{props.children}</div>
  );
}

function Table(props) {
  const className = ['Pixi Pixi__EditPanel__content__table'];

  if (props.double) {
    className.push('Pixi__EditPanel__content__table--double');
  }
  return (
    <div className={className.join(' ')}>
      {Object.keys(props.content).map((key) => (
        <div
          key={key}
          className="Pixi Pixi__EditPanel__content__table__row"
          title={props.content[key]?.title}
        >
          <span>
            {props.leftInject} {key}
          </span>
          <span>
            {props.content[key]?.value
              ? props.content[key]?.value
              : props.content[key]}
          </span>
        </div>
      ))}
    </div>
  );
}

function Header(props) {
  return (
    <header className="Pixi Pixi__EditPanel__header">{props.children}</header>
  );
}

function Title(props) {
  return (
    <div className="Pixi Pixi__EditPanel__header__title">
      <h1>{props.children}</h1>
      {props.onClose && (
        <Tooltip
          message={props.onCloseTooltip || 'Deselect'}
          size="small"
          delay={800}
        >
          <Icon
            style={{ fontSize: 18 }}
            name={props.closeIcon || 'X'}
            button
            bubble
            onClick={() => props.onClose()}
            className="Pixi__EditPanel__header__title__close"
          />
        </Tooltip>
      )}
    </div>
  );
}

function Field(props) {
  const [value, setValue] = React.useState(props.value);
  const [isEditing, setIsEditing] = React.useState(false);
  const [isSaving, setIsSaving] = React.useState(false);

  React.useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  React.useEffect(() => {
    setIsEditing(props.isEditing);
  }, [props.isEditing]);

  React.useEffect(() => {
    if (props.onEdit) {
      props.onEdit(isEditing);
    }
  }, [isEditing, props.onEdit]);

  async function saveData() {
    setIsSaving(true);
    if (props.files) {
      await props.onSave(value, true);
    } else {
      await props.onSave(value, false);
    }
    setIsSaving(false);
    setIsEditing(false);
  }

  return (
    <Section
      title={props.title}
      focused={isEditing}
      hideEditButton={props.hideEditButton}
      toolbar={props.toolbar}
      onEdit={props.onEditClick || (() => setIsEditing(!isEditing))}
    >
      {isEditing
        ? props.control(value, setValue, setIsEditing)
        : props.display(value, setValue, setIsEditing)}
      {isEditing && (
        <Row>
          <Button
            inline
            fluid
            size="small"
            onClick={() => {
              if (props.onCancel) {
                props.onCancel();
              }
              setIsEditing(!isEditing);
            }}
          >
            Cancel
          </Button>
          <Button
            inline
            primary
            fluid
            size="small"
            loading={isSaving}
            onClick={() => saveData()}
          >
            Save
          </Button>
        </Row>
      )}
    </Section>
  );
}

EditPanel.Section = Section;
EditPanel.Header = Header;
EditPanel.Table = Table;
EditPanel.Row = Row;
EditPanel.Field = Field;
EditPanel.Title = Title;

export default function EditPanel(props) {
  return (
    <div className="Pixi Pixi__EditPanel">
      {props.placeholderActive && props.placeholder ? (
        <div className="Pixi Pixi__EditPanel__placeholder">
          {props.placeholder}
        </div>
      ) : (
        <div className="Pixi Pixi__EditPanel__content">
          <div className="Pixi Pixi__EditPanel__content__wrapper">
            {props.children}
          </div>
        </div>
      )}
    </div>
  );
}
