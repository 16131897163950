import { Combobox, Switch, TextInput } from '@mantine/core';
import { selectFile } from '@pixi/AppController';
import { AssetIcon } from '@pixi/components/AssetThumbnail';
import SelectBoard from '@pixi/components/SelectBoard';
import SelectCollection from '@pixi/components/SelectCollection';
import SelectFile from '@pixi/components/SelectFile';
import { PixiDropdownProps } from '@pixi/elements/Dropdown';
import { PixiDropdownRenderProps } from '@pixi/elements/Dropdown/useDropdownContext';
import PixiForm from '@pixi/elements/Form';

export default function LinkForm({
  target,
  variant,
  onSubmit,
  library,
  variantType,
  dropdownRenderProps,
  selectFileProps,
  value,
  ...rest
}: {
  variant?:
    | 'custom'
    | 'email'
    | 'file'
    | 'media_collection'
    | 'document_collection'
    | 'board';
  variantType?: 'sub-guide';
  target: React.ReactElement;
  library?: 'media' | 'documents' | 'brandassets' | string;
  dropdownRenderProps?: PixiDropdownRenderProps;
  selectFileProps?: Partial<Parameters<typeof selectFile>[0]>;
  onSubmit?: (data: { url?: string; label?: string }) => void;
  value?: {
    url?: string;
    label?: string;
    board?: Pickit.BoardInterface;
    collection?: Pickit.CollectionInterface;
    file?: Pickit.FileInterface;
    preferences?: {
      openInNewWindow?: boolean;
    };
  };
} & Partial<PixiDropdownProps>) {
  return (
    <PixiForm<{
      url?: string;
      label?: string;
      board?: Pickit.BoardInterface;
      collection?: Pickit.CollectionInterface;
      file?: Pickit.FileInterface;
      preferences?: {
        openInNewWindow?: boolean;
      };
    }>
      type="dropdown"
      zIndex={12}
      closeOnClassNameClick="link_menu"
      title={
        variant === 'email'
          ? 'Add email link'
          : variant === 'board'
            ? variantType === 'sub-guide'
              ? 'Add sub-guide link'
              : 'Add board link'
            : variant === 'file'
              ? 'Download'
              : 'Add link'
      }
      submitLabel={value?.label ? 'Save changes' : 'Add link'}
      {...rest}
      target={target}
      onSubmit={(data) => {
        dropdownRenderProps?.setIsOpen(false);
        onSubmit?.(data);
      }}
      form={{
        ...(variant === 'board'
          ? {
              board: {
                key: 'board',
                value: value?.board,
                render: ({ value, setValue, data, setData }) => (
                  <SelectBoard
                    asInput
                    type={variantType}
                    value={value?._id}
                    onBoardClick={(board) => {
                      setValue(board);
                      setData({
                        label: board.name,
                      });
                    }}
                  />
                ),
              },
            }
          : variant === 'media_collection' || variant === 'document_collection'
            ? {
                collection: {
                  key: 'collection',
                  value: value?.collection,
                  render: ({ value, data, setData }) => (
                    <SelectCollection
                      asInput
                      type={
                        variant === 'media_collection' ? 'media' : 'documents'
                      }
                      value={value?._id}
                      onCollectionClick={(col) => {
                        setData({
                          collection: col,
                          label: col.name,
                        });
                      }}
                    />
                  ),
                },
              }
            : variant === 'file'
              ? {
                  file: {
                    key: 'file',
                    value: value?.file,
                    render: ({ value, setData, dropdown, data }) => {
                      const openFileSelector = async () => {
                        dropdownRenderProps?.setIsFreezeOpen(true);
                        dropdown.setIsFreezeOpen(true);
                        const files = await selectFile({
                          libraryId: library,
                          popupProps: {
                            size: 1200,
                            zIndex: 999,
                          },
                          view: {
                            title: 'Select assets',
                          },
                          ...selectFileProps,
                          assetGridProps: {
                            disable: [
                              'rightPanel',
                              'status',
                              'fullscreen',
                              'upload',
                              'trash',
                            ],
                            ...(selectFileProps?.assetGridProps || {}),
                          },
                        });
                        if (files?.[0]?._id) {
                          // Only set label if label has been manually edited or if no label exists
                          setData({
                            file: files[0],
                            label: !data?.label
                              ? files[0].name
                              : data?.label === data?.file?.name
                                ? files[0].name
                                : data?.label,
                          });
                        }
                        dropdown.setIsOpen(true);
                        dropdownRenderProps?.setIsOpen(true);
                        setTimeout(() => {
                          dropdown.setIsFreezeOpen(false);
                          dropdownRenderProps?.setIsFreezeOpen(false);
                        }, 250);
                      };
                      return (
                        <TextInput
                          label="File"
                          placeholder="Select file"
                          value={value?.name || ''}
                          onChange={(event) => {
                            event?.preventDefault();
                            event?.stopPropagation();
                            return false;
                          }}
                          style={{
                            cursor: 'pointer',
                          }}
                          leftSection={
                            value?.file && <AssetIcon file={value} />
                          }
                          onClick={async () => {
                            openFileSelector();
                          }}
                        />
                      );
                    },
                  },
                }
              : {
                  url: {
                    key: 'url',
                    value: value?.url,
                    render: ({ value, setValue }) => (
                      <TextInput
                        label={variant === 'email' ? 'Email' : 'URL'}
                        value={value}
                        onChange={(event) => {
                          setValue(event.currentTarget.value);
                        }}
                        autoFocus
                        onFocus={(event) => event.currentTarget?.select()}
                      />
                    ),
                  },
                }),
        label: {
          key: 'label',
          value: value?.label,
          render: ({ value, setValue, data }) => (
            <TextInput
              label="Label"
              value={value}
              onChange={(event) => {
                setValue(event.currentTarget.value);
              }}
            />
          ),
        },
        ...(!variant || variant === 'board' || variant === 'custom'
          ? {
              preferences: {
                key: 'preferences',
                value: value?.preferences,
                render: ({ value, setValue }) => (
                  <Switch
                    label="Open in new window"
                    checked={value?.openInNewWindow}
                    onChange={(event) => {
                      setValue({
                        ...value,
                        openInNewWindow: event.currentTarget.checked,
                      });
                    }}
                  />
                ),
              },
            }
          : {}),
      }}
    >
      Test
    </PixiForm>
  );
}
