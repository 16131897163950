import React from 'react';
import './style.scss';
import { useTextContext } from 'hooks';
import { Button, Popover } from 'elements_v2';

function InfoCard(props) {
  return <div className="InfoPopupCard">{props.children}</div>;
}
function InfoCardContent(props) {
  return <div className="InfoPopupCard__content">{props.children}</div>;
}
function InfoCardTitle(props) {
  return <h1 className="InfoPopupCard__title">{props.children}</h1>;
}
function InfoCardText(props) {
  return <div className="InfoPopupCard__text">{props.children}</div>;
}
function InfoCardActions(props) {
  return <div className="InfoPopupCard__actions">{props.children}</div>;
}
InfoCard.Title = InfoCardTitle;
InfoCard.Text = InfoCardText;
InfoCard.Content = InfoCardContent;
InfoCard.Actions = InfoCardActions;

function Info(props) {
  const Text = useTextContext();

  return (
    <Popover
      triggerClassName="InfoPopup"
      trigger={props.children}
      style={{ zIndex: 9999999999 }}
      position={props.position || 'bottom-start'}
      on="click"
      useV2={props.useV2}
    >
      {(isOpen, setIsOpen) => (
        <InfoCard>
          <InfoCard.Content>
            <InfoCard.Title>{props.title}</InfoCard.Title>
            <InfoCard.Text>{props.text}</InfoCard.Text>
            <InfoCard.Actions>
              <Button
                inline
                background="white"
                onClick={(event) => {
                  event.preventDefault();
                  if (props.onButtonClick) {
                    props.onButtonClick();
                  }
                  setIsOpen(false);
                }}
              >
                {props.buttonText || Text.get('ui/got_it')}
              </Button>
            </InfoCard.Actions>
          </InfoCard.Content>
        </InfoCard>
      )}
    </Popover>
  );
}

export default Info;
