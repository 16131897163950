import * as yup from 'yup';
import React from 'react';
import boardModuleFields from 'utils/boardModuleFields';
import { ContentSectionMetadata } from '../../helpers/Blocks/Text';

import Text from '../_icons/TEXT.svg?react';
import TextColumns from '../_icons/TEXT_TEXT.svg?react';
import Media from '../_icons/MEDIA.svg?react';
import MediaText from '../_icons/MEDIA_TEXT.svg?react';
import MediaColumns from '../_icons/MEDIA_COLUMN.svg?react';
import MediaGrid from '../_icons/MEDIA_GRID.svg?react';
import FileText from '../_icons/FILE_TEXT.svg?react';
import FileColumns from '../_icons/FILE_COLUMN.svg?react';
import FileGrid from '../_icons/FILE_GRID.svg?react';
import CollectionText from '../_icons/COLLECTION_TEXT.svg?react';
import CollectionColumns from '../_icons/COLLECTION_COLUMN.svg?react';
import CollectionGrid from '../_icons/COLLECTION_GRID.svg?react';
import BoardText from '../_icons/BOARD_TEXT.svg?react';
import BoardColumns from '../_icons/BOARD_COLUMN.svg?react';
import BoardGrid from '../_icons/BOARD_GRID.svg?react';
import ColorText from '../_icons/COLOR_TEXT.svg?react';
import ColorColumns from '../_icons/COLOR_COLUMN.svg?react';
import ColorGrid from '../_icons/COLOR_GRID.svg?react';
import Iframe from '../_icons/IFRAME.svg?react';

const modules = [
  ...[
    {
      focus: '',
      icon: <TextColumns />,
      name: 'Text columns',
      key: 'CONTENT_GRID',
    },
    {
      focus: 'asset',
      icon: <MediaColumns />,
      name: 'Media columns',
      key: 'CONTENT_COLUMNS_MEDIA',
      preferences: {
        assetFocus: 'media',
        assetLabel: 'Add media',
      },
    },
    {
      focus: 'asset',
      name: 'Media grid',
      icon: <MediaGrid />,
      key: 'CONTENT_GRID_MEDIA',
      preferences: {
        assetFocus: 'media',
        assetLabel: 'Add media',
        disable: ['text', 'download', 'link'],
      },
    },
    {
      focus: 'asset',
      name: 'Files columns',
      icon: <FileColumns />,
      key: 'CONTENT_COLUMNS_FILES',
      preferences: {
        assetLabel: 'Add file',
        assetFocus: 'file',
      },
    },
    {
      focus: 'asset',
      name: 'Files grid',
      icon: <FileGrid />,
      key: 'CONTENT_GRID_FILES',
      preferences: {
        assetLabel: 'Add file',
        assetFocus: 'file',
        disable: ['text', 'download', 'link'],
      },
    },
    {
      focus: 'asset',
      name: 'Board/guide columns',
      icon: <BoardColumns />,
      key: 'CONTENT_COLUMNS_BOARDS',
      preferences: {
        assetLabel: 'Add board/guide',
        assetFocus: 'board',
      },
    },
    {
      focus: 'asset',
      name: 'Board/guide grid',
      icon: <BoardGrid />,
      key: 'CONTENT_GRID_BOARDS',
      preferences: {
        assetLabel: 'Add board/guide',
        assetFocus: 'board',
        disable: ['text', 'download', 'link'],
      },
    },
    {
      focus: 'asset',
      name: 'Color grid',
      icon: <ColorGrid />,
      key: 'CONTENT_GRID_COLORS',
      preferences: {
        assetLabel: 'Add color',
        assetFocus: 'color',
        disable: ['text', 'download', 'link'],
      },
    },
    {
      focus: 'asset',
      name: 'Color columns',
      icon: <ColorColumns />,
      key: 'CONTENT_COLUMNS_COLORS',
      preferences: {
        assetLabel: 'Add color',
        assetFocus: 'color',
      },
    },
    {
      focus: 'asset',
      name: 'Collection columns',
      icon: <CollectionColumns />,
      key: 'CONTENT_COLUMNS_COLLECTIONS',
      preferences: {
        assetLabel: 'Add collection',
        assetFocus: 'collection',
      },
    },
    {
      focus: 'asset',
      name: 'Collection grid',
      icon: <CollectionGrid />,
      key: 'CONTENT_GRID_COLLECTIONS',
      preferences: {
        assetLabel: 'Add collections',
        assetFocus: 'collection',
        disable: ['text', 'download', 'link'],
      },
    },
  ].map((row) => ({
    moduleId: row.key,
    menuGroupIds: ['TEXT'],
    moduleInterface: {
      contentSection: 'CONTENT',
    },
    preferences: {
      assetType: row.focus,
      menuContentKey: row.key,
      ...(row.preferences || {}),
    },
    status: {
      isEmpty: (data) => {
        return false;
      },
    },
    moduleMetadataInterface: {
      backgroundColor: yup.string().nullable(),
      textColor: yup.string().nullable(),
      spaceBelow: yup.number().nullable(),
      ...ContentSectionMetadata,
    },
    menuContent: { key: row.key, icon: row.icon, name: row.name },
  })),
  ...[
    {
      focus: 'media',
      name: 'Media & text',
      icon: <MediaText />,
      key: 'CONTENT_WITH_IMAGE',
    },
    {
      focus: 'board',
      name: 'Board/guide + text',
      icon: <BoardText />,
      key: 'CONTENT_WITH_BOARD',
    },
    {
      focus: 'color',
      name: 'Color + text',
      icon: <ColorText />,
      key: 'CONTENT_WITH_COLOR',
    },
    {
      focus: 'collection',
      name: 'Collection + text',
      icon: <CollectionText />,
      key: 'CONTENT_WITH_COLLECTION',
    },
    {
      focus: 'asset',
      name: 'Media',
      icon: <Media />,
      key: 'CONTENT_MEDIA',
      preferences: {
        assetLabel: 'Add media',
        disable: ['text', 'download', 'link'],
        assetFocus: 'media',
      },
    },
    {
      focus: 'asset',
      name: 'Embed',
      icon: <Iframe />,
      key: 'CONTENT_IFRAME',
      preferences: {
        assetLabel: 'Add iframe',
        disable: ['text', 'download', 'link'],
        assetFocus: 'iframe',
      },
    },
    {
      focus: 'file',
      name: 'File + text',
      icon: <FileText />,
      key: 'CONTENT_WITH_FILE',
    },
    {
      name: 'Content with content',
      icon: <TextColumns />,
      key: 'CONTENT_WITH_CONTENT',
    },
  ].map((m) => ({
    moduleId: m.key,
    moduleInterface:
      m.key === 'CONTENT_WITH_CONTENT'
        ? {
            contentLeftSection: 'CONTENT',
            contentRightSection: 'CONTENT',
          }
        : m.key === 'CONTENT_MEDIA'
        ? {
            contentSection: 'CONTENT',
          }
        : {
            contentTextSection: 'CONTENT',
            contentAssetSection: 'CONTENT',
          },
    status: {
      isEmpty: (data) => {
        return false;
      },
    },
    preferences: {
      assetType: m.focus,
      menuContentKey: m.key,
      ...(m.preferences || {}),
    },
    moduleMetadataInterface: {
      backgroundColor: yup.string().nullable(),
      buttonBackground: yup.string().nullable(),
      buttonForeground: yup.string().nullable(),
      contentPosition: yup.mixed().oneOf(['left', 'right']),
      buttonAlignment: yup.string(),
      ...(m.key === 'CONTENT_MEDIA'
        ? {
            responsiveImage: boardModuleFields.FILE,
          }
        : {}),
      spaceBelow: yup.number().nullable(),
      ...ContentSectionMetadata,
    },
    menuContent: {
      key: m.key,
      icon: m.icon,
      name: m.name,
    },
  })),
  {
    moduleId: 'CONTENT_ONE',
    moduleInterface: {
      contentTextSection: 'CONTENT',
    },
    status: {
      isEmpty: (data) => {
        return false;
      },
    },
    moduleMetadataInterface: {
      backgroundColor: yup.string().nullable(),
      textColor: yup.string().nullable(),
      fullscreen: yup.boolean(),
      spaceBelow: yup.number().nullable(),
      ...ContentSectionMetadata,
    },
    menuContent: {
      key: 'CONTENT_ONE',
      icon: <Text />,
      name: 'Rich text',
    },
    preferences: {
      disable: ['asset'],
    },
  },
];

export default modules;
