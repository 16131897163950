import { useState } from 'react';

export default function useIntegrationConfig(API_ID) {
  const [capabilities, setCapabilities] = useState({
    jobs: false,
    nested: false,
    external: false,
    seamless: false,
    search: false,
    recent: false,
  });
  const [copy, setCopyInternal] = useState({});
  const enableCapabilities = (keys) => {
    const newCapabilities = { ...capabilities };
    keys.forEach((key) => (newCapabilities[key] = true));
    setCapabilities(newCapabilities);
  };
  const disableCapabilities = (keys) => {
    const newCapabilities = { ...capabilities };
    keys.forEach((key) => (newCapabilities[key] = false));
    setCapabilities(newCapabilities);
  };

  const setCopy = (newCopyObject) => {
    setCopyInternal(newCopyObject);
  };
  const getCopyValue = (key, replaceArray) => {
    return copy[key];
  };
  return {
    API_ID,
    capabilities,
    enableCapabilities,
    disableCapabilities,
    getCopyValue,
    setCopy,
  };
}
