import * as yup from 'yup';
import React from 'react';
import Icon from './icon.svg?react';

export default {
  moduleId: 'ONE_COLLECTION',
  menuGroupIds: ['COLLECTIONS', 'TEXT'],
  moduleInterface: {
    collection: 'COLLECTION',
    title: 'TITLE',
    text: 'TEXT',
  },
  status: {
    isEmpty: (data) => {
      return !data?.collection?.collectionId;
    },
  },
  moduleMetadataInterface: {
    thumbnailPosition: yup.mixed().oneOf(['left', 'right']),
    backgroundColor: yup.string(),
    textColor: yup.string(),
    titleSize: yup.mixed().oneOf(['small', 'medium', 'large']),
    textSize: yup.mixed().oneOf(['small', 'medium', 'large']),
    noBorderRadius: yup.boolean(),
  },
  menuContent: {
    key: 'ONE_COLLECTION',
    icon: <Icon />,
    name: 'Collection with text',
  },
};
