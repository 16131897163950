export function fileToFolderItem(file) {
  return {
    name: file.name,
    referenceLocation: file.type,
    referenceData: file._id,
    type: 'file',
  };
}

export function folderToFolderItem(folder) {
  return {
    name: folder.name,
    referenceLocation: 'assets',
    referenceData: folder._id,
    type: 'folder',
  };
}

export function boardToFolderItem(board) {
  return {
    name: board.name,
    referenceLocation: 'boards',
    referenceData: board._id,
    type: 'board',
  };
}

export function collectionToFolderItem(collection, library) {
  return {
    name: collection.name,
    referenceLocation:
      library === 'media' ? 'mediacollections' : 'documentcollections',
    referenceData: collection._id,
    type: 'collection',
  };
}
