import { useEffect } from 'react';
import useIntegrationConfig from '../useIntegrationConfig';
import useJobs from '../useJobs';
import useBoxAPI from './useBoxAPI';

export default function useBox(props) {
  const config = useIntegrationConfig('box');
  const { API_ID, enableCapabilities, setCopy } = config;
  useEffect(() => {
    enableCapabilities([
      'jobs',
      'nested',
      // 'external',
      // 'seamless',
      'search',
      'approval',
    ]);
    setCopy({
      external: (
        <>
          Import the file's metadata, but keep the file stored externally.
          <br />
          <strong>Note:</strong> The user will be redirected to the source in
          Box and will need access to the file there to download it.
        </>
      ),
      seamless: (
        <>
          External files can be downloaded without the Box UI.
          <br />
          <strong>Note:</strong>
          <p style={{ color: '#f08c00' }}>
            Box requires a paid plan of Box to create direct acess to your files
            which is required for semaless access to work.
          </p>{' '}
          This feature will create a open download URL for your imported assets.
          This URL can be accessed without authentication at Box.
          <br />
        </>
      ),
    });
  }, []);

  const BoxAPI = useBoxAPI({ API_ID, BoxAuth: props.BoxAuth });
  const Jobs = useJobs({ API_ID, API: BoxAPI });
  return {
    ...BoxAPI,
    ...Jobs,
    ...config,
  };
}
