import { Box, BoxProps, Group, Paper, Stack, Text } from '@mantine/core';
import * as React from 'react';

interface FieldProps extends BoxProps {
  label?: string;
  children: React.ReactNode;
  description?: string;
  descriptionToRight?: boolean;
  card?: boolean;
  icon?: React.ReactNode;
  paper?: boolean;
  horizontal?: boolean;
}

const Field: React.FC<FieldProps> = (props: FieldProps) => {
  const {
    label,
    children,
    description,
    descriptionToRight,
    icon,
    card,
    paper,
    horizontal,
    ...rest
  } = props;
  const content = (
    <>
      <Group gap={8}>
        {icon}
        {label && (
          <Text size="sm" fw={500}>
            {label}
          </Text>
        )}
        {descriptionToRight && description ? (
          <>
            <Text size="sm" c="dimmed" ml="auto" fw={500}>
              {description}
            </Text>
          </>
        ) : (
          <></>
        )}
      </Group>
      {card ? (
        <Box
          p="sm"
          style={{
            background: 'var(--mantine-color-gray-0)',
            borderRadius: 'var(--mantine-radius-md)',
          }}
        >
          {children}
        </Box>
      ) : (
        children
      )}
      {description && !descriptionToRight ? (
        <Text c="dimmed" size="xs" fw={500}>
          {description}
        </Text>
      ) : (
        ''
      )}
    </>
  );
  if (paper) {
    return (
      <Paper withBorder p="lg" {...rest}>
        {content}
      </Paper>
    );
  }
  return (
    <Box {...rest}>
      {horizontal ? (
        <Group gap={5}>{content}</Group>
      ) : (
        <Stack gap={5}>{content}</Stack>
      )}
    </Box>
  );
};

export default Field;
