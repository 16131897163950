import { Group, Paper, Stack } from '@mantine/core';
import { useAssetGridContext } from '../AssetGridContext';
import PixiText from '@pixi/elements/Text';
import PixiButton from '@pixi/elements/Button';
import { getMultiStore } from '@pixi/store';
import { useState } from 'react';
import PixiIcon from '@pixi/elements/Icon';

export default function AssetGridGroup({
  children,
  title,
  files,
}: {
  children: React.ReactNode;
  title: React.ReactNode;
  files: Pickit.FileInterface[];
}) {
  const [isHidden, setIsHidden] = useState(false);
  const { selectedFiles, multiSelect, id } = useAssetGridContext();
  return (
    <Paper p="lg">
      <Stack gap="sm">
        <Group justify="space-between">
          <PixiButton
            variant="transparent"
            color="gray"
            pl="0"
            size="xs"
            onClick={() => {
              setIsHidden(!isHidden);
            }}
            rightSection={
              isHidden && <PixiIcon name="chevron-right" size="sm" />
            }
          >
            {title}
          </PixiButton>
          {multiSelect !== false && (
            <Group>
              {selectedFiles?.filter(
                (selectedFile) =>
                  !!files.find((row) => row._id === selectedFile._id),
              ).length === files.length ? (
                <PixiButton
                  variant="light"
                  color="gray"
                  size="xs"
                  onClick={() => {
                    getMultiStore('FILES_SELECTED', id).remove(
                      files?.map((row) => ({
                        _id: row._id,
                      })),
                    );
                  }}
                >
                  Deselect {files?.length} file
                  {files?.length > 1 ? 's' : ''}
                </PixiButton>
              ) : (
                <PixiButton
                  variant="subtle"
                  color="gray"
                  size="xs"
                  onClick={() => {
                    getMultiStore('FILES_SELECTED', id).addOrUpdate(
                      files?.map((row) => ({
                        _id: row._id,
                      })),
                    );
                  }}
                >
                  Select {files?.length} file
                  {files?.length > 1 ? 's' : ''}
                </PixiButton>
              )}
            </Group>
          )}
        </Group>
        {!isHidden && children}
      </Stack>
    </Paper>
  );
}
