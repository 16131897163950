import React from 'react';
import './style.scss';

const Radio = ({ on, label, onClick, styles }) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center', ...styles }}>
      <div
        className={
          on
            ? 'ManagePay__Radio--container--active'
            : 'ManagePay__Radio--container'
        }
        onClick={onClick}
      >
        <input type="radio" />
        {on && <span class="checkmark"></span>}
      </div>
      <p style={{ marginLeft: '15px', marginTop: '1px' }}>{label}</p>
    </div>
  );
};

export default Radio;
