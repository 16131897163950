import { isBefore } from 'date-fns';
import { useMediaContext, useDocumentsContext } from 'hooks';

import { useEffect, useReducer } from 'react';

export default function useStorageUrl(context, type) {
  const [tokens, setTokens] = useReducer((state, action) => {
    return { ...action };
  }, {});
  const contexts = {
    media: useMediaContext(),
    documents: useDocumentsContext(),
    brand: useMediaContext(),
  };

  function getContextByUrl(url) {
    if (url.includes('-brand')) {
      return contexts.brand;
    }
    if (url.includes('pickitmedia')) {
      return contexts.media;
    }
    if (url.includes('pickitdocslive')) {
      return contexts.documents;
    }
    if (url.includes('-brand')) {
      return contexts.brand;
    }
    if (url.includes('pickitmediadev')) {
      return contexts.media;
    }
    if (url.includes('pickitdocsdev')) {
      return contexts.documents;
    }
  }

  useEffect(() => {
    if (context?.sasToken?.token) {
      setTokens(context.sasToken.token);
    }
  }, [context?.sasToken]);

  // useEffect(() => {
  //   context?.getToken(context?.sasToken);
  // }, []);

  return {
    getInfo: (url) => {
      if (url) {
        const currentContext = getContextByUrl(url);
        const parts = url.split('/');
        const storage = parts?.[3]?.split('-');
        const community = storage[0];
        const type = storage[1];
        const token = (context || currentContext)?.storageTokens?.[community]?.[
          type
        ];
        return {
          community,
          container: type,
          token,
          context: currentContext,
        };
      }
    },
    generateUrl: (url) => {
      if (url) {
        let parts = url.split('/');
        if (url.includes('localhost')) {
          parts = url.replace('/' + parts[3], '').split('/');
        }
        const currentContext = getContextByUrl(url);

        if (!parts?.[3]) {
          return url;
        }
        const storage = parts?.[3]?.split('-');
        const community = storage[0];
        const type = storage[1];
        const token = (context || currentContext)?.storageTokens?.[community]?.[
          type
        ];
        if (!token) {
          return url;
        }
        const params = new URLSearchParams(token);
        try {
          if (isBefore(new Date(params.get('se')), new Date())) {
            (context || currentContext)?.getTokens();
          }
        } catch (e) {}
        return `${url.split('?')[0]}?${token}`;
      }
    },
  };
}
