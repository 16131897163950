import { ProgressInfo, UpdateInfo } from 'electron-updater';
import { useGetContextFromFile } from 'hooks/useContextFromFile';
import * as React from 'react';
import { isNative } from 'utils/platform';

export interface UseNativeUpdate {
  downloadProgress: Partial<ProgressInfo>;
  updateAvailable: Partial<UpdateInfo>;
  updateNotAvailable: Partial<UpdateInfo>;
  isActive?: boolean;
  isUpdateReady?: boolean;
  syncingFileChanges: any[];
  downloadingFile: DownloadingFile[];
  directoryMap: DirectoryFile[];
}

interface DownloadingFile {
  fileId: string;
  progress: {
    percent: number; // Overall percent (between 0 to 1)
    speed: number; // The download speed in bytes/sec
    size: {
      total: number; // The total payload size in bytes
      transferred: number; // The transferred payload size in bytes
    };
    time: {
      elapsed: number; // The total elapsed seconds since the start (3 decimals)
      remaining: number; // The remaining seconds to finish (3 decimals)
    };
  };
}

const useNativeUpdate = (): UseNativeUpdate => {
  const getContext = useGetContextFromFile();
  const [downloadingFile, setDownloadingFile] = React.useReducer(
    (
      state: DownloadingFile[],
      action: DownloadingFile | { clearId: string },
    ) => {
      if ((action as { clearId: string })?.clearId) {
        return state.filter(
          (f) => f.fileId !== (action as { clearId: string })?.clearId,
        );
      }
      const data = action as DownloadingFile;
      if (!data?.fileId) {
        return state;
      }
      const alreadyInState = state.findIndex((r) => r?.fileId === data?.fileId);
      if (alreadyInState > -1) {
        state[alreadyInState] = data;
        if (data.progress.percent === 1) {
          setTimeout(() => {
            setDownloadingFile({ clearId: data.fileId });
          }, 2500);
        }
      } else {
        return [...state, data];
      }
      return [...state];
    },
    [] as DownloadingFile[],
  );
  const [syncingFileChanges, setSyncingFileChanges] = React.useReducer(
    (state: any[], action: any) => {
      const currentStateFile = state.find((data) =>
        data.file?._id === action?.file?._id ? action : data,
      );
      if (action.status === 'remove') {
        return state.filter((data) => data.file?._id !== action?.file?._id);
      }
      if (!currentStateFile) {
        return [...state, action];
      }
      return state.map((data) =>
        data.file?._id === action?.file?._id ? action : data,
      );
    },
    [],
  );

  const [isUpdateReady, setIsUpdateReady] = React.useState(false);
  const [downloadProgress, setDownloadProgress] = React.useReducer(
    (state: Partial<ProgressInfo>, action: Partial<ProgressInfo>) => ({
      ...action,
    }),
    {},
  );
  const [directoryMap, setDirectoryMap] = React.useReducer(
    (state: DirectoryFile[], action: DirectoryFile[]) => {
      return [...action];
    },
    [],
  );
  const [updateNotAvailable, setUpdatNotAvailable] = React.useReducer(
    (state: Partial<UpdateInfo>, action: Partial<UpdateInfo>) => ({
      ...action,
    }),
    {},
  );
  const [updateAvailable, setUpdateAvailable] = React.useReducer(
    (state: Partial<UpdateInfo>, action: Partial<UpdateInfo>) => ({
      ...action,
    }),
    {},
  );

  React.useEffect(() => {
    if (isNative()) {
      window.pickitNative?.checkForUpdates?.();
      window.pickitNative?.nativeEventListener(({ name, data }) => {
        if (
          name === 'pickit-directory-files' &&
          data &&
          (data as DirectoryFile[])?.length !== directoryMap?.length
        ) {
          setDirectoryMap(data as DirectoryFile[]);
        }
        if (name === 'downloading-file' && data) {
          setDownloadingFile(data as DownloadingFile);
        }
        if (name === 'window-info') {
          const windowInfo = data as { transparent?: boolean };
          if (windowInfo.transparent) {
            document.body.setAttribute(
              'window-transparent',
              windowInfo.transparent.toString(),
            );
          }
        }
        if (name === 'update-download-progress' && data) {
          setDownloadProgress(data as ProgressInfo);
        }
        if (name === 'update-not-available') {
          setUpdatNotAvailable(data as UpdateInfo);
          setTimeout(() => {
            setUpdatNotAvailable({});
          }, 8000);
        }
        if (name === 'update-available' && data) {
          setUpdateAvailable(data as UpdateInfo);
        }
        if (name === 'update-downloaded' && data) {
          setIsUpdateReady(true);
        }
        if (name === 'open-and-edit-file' && data) {
          setSyncingFileChanges(data as any);

          if ((data as any).status === 'finished') {
            const { file } = data as any;
            const context = getContext(file);
            context.finishNewVersion(file);
            setTimeout(() => {
              setSyncingFileChanges({
                ...(data as any),
                status: 'remove',
              });
            }, 8000);
          }
        }
      });
    }
  }, []);

  return {
    isActive: !!downloadProgress?.transferred && !!updateAvailable?.releaseDate,
    downloadProgress,
    updateAvailable,
    updateNotAvailable,
    isUpdateReady,
    downloadingFile,
    syncingFileChanges,
    directoryMap,
  };
};

export default useNativeUpdate;
