
export function fileSourceToText(source: string): string {
  if (!source) {
    return 'Uploaded';
  }
  if (source === 'network_file_system') {
    return 'NFS';
  }
  if (source === 'inspection-tool') {
    return 'Inspection Tool';
  }
  if (source === 'dropbox') {
    return 'Dropbox';
  }
  if (source === 'microsoft') {
    return 'Microsoft';
  }
  if (source === 'google-drive') {
    return 'Google Drive';
  }
  if (source === 'adobeccl') {
    return 'Adobe CC';
  }
  if (source === 'box') {
    return 'Box';
  }
  if (source === 'pickit-market') {
    return 'Pickit Stock';
  }
  if (source === 'sharepoint') {
    return 'Sharepoint';
  }
  if (source === 'onedrive') {
    return 'OneDrive';
  }
  if (source === 's3') {
    return 'S3';
  }
  if (source === 'azure') {
    return 'Azure';
  }
  if (source === 'hubspot_import') {
    return 'Hubspot';
  }
  if (source === 'gcp') {
    return 'GCP';
  }
  if (source === 'aws') {
    return 'AWS';
  }
  if (source === 'other') {
    return 'Other';
  }
  if (source === 'getty-images') {
    return 'Getty Images';
  }
  if (source === 'powerpoint_file_slides') {
    return 'PowerPoint file';
  }
  if(source === "pickit-import"){
    return "Migrated to Pickit";
  }
  return source;
}
