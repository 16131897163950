import { Badge, Group } from '@mantine/core';
import { useAssetGridContext } from '../../AssetGridContext';
import { filterValueToString } from '../../Helpers';
import PixiButton from '@pixi/elements/Button';
import PixiIcon from '@pixi/elements/Icon';
import PixiTooltip from '@pixi/elements/Tooltip';
import { FilterDataRender } from '../LeftPanels/AssetGridFilters';
import { ReactNode } from 'react';
import useAssetGridSortBy from '../../hooks/useAssetGridSortBy';
import { useColorScheme } from '@pixi/AppController';

export default function AssetGridStatusBar({
  isFiltering,
  color,
}: {
  isFiltering: boolean;
  color: string;
}) {
  const {
    activeFilters,
    frozenFilters,
    setFilter,
    activeShortcutsData,
    activeShortcutsFilter,
    forceFilterShortcuts,
    toggleActiveShortcut,
    setActiveShortcuts,
    disable,
    status,
    view,
    setFilters,
    metadata,
  } = useAssetGridContext();
  const colorScheme = useColorScheme();
  const AssetGridSortBy = useAssetGridSortBy();
  const statusBar = (
    <>
      {!disable?.includes('status') && (
        <>
          <Group gap="xs">
            <Group w="100%" align="center" mb="sm">
              {typeof metadata?.stats?.totalAssets === 'number' && (
                <>
                  <Badge
                    variant="transparent"
                    pl="0"
                    color={isFiltering ? color : 'dark'}
                    radius="0"
                  >
                    {metadata?.stats?.totalAssets} results
                  </Badge>
                  {view.sortBy && (
                    <Badge
                      variant="transparent"
                      color={isFiltering ? color : 'dark'}
                    >
                      Sorted by{' '}
                      <strong>
                        {
                          AssetGridSortBy.find(
                            (s) =>
                              s.field === view.sortBy?.field &&
                              s.direction === view.sortBy.direction,
                          )?.name
                        }
                      </strong>
                    </Badge>
                  )}
                </>
              )}
              <Group ml="auto" gap="xs">
                {isFiltering && (
                  <PixiButton
                    size="xs"
                    color={color}
                    variant="light"
                    ml="auto"
                    onClick={() => {
                      setActiveShortcuts([]);
                      setFilters({});
                    }}
                    leftSection={<PixiIcon name="xmark" size="sm" />}
                  >
                    Clear all filters
                  </PixiButton>
                )}
              </Group>
            </Group>
            {Object.keys(activeFilters)
              .filter((field) => {
                return !Object.keys(activeShortcutsFilter || {}).includes(
                  field,
                );
              })
              .map((field, i) => {
                let filterInfo = Object.values(frozenFilters).find(
                  (f) => f.row.field === field,
                );
                if (field === '$query') {
                  filterInfo = {
                    data: [],
                    row: {
                      name: '$query',
                      key: '$query',
                      view: {
                        name: 'Search',
                        description: 'Search',
                        icon: 'search',
                        field: '$query',
                      },
                    },
                  };
                }
                if (!filterInfo?.row) {
                  return;
                }
                const values: {
                  value: string | null;
                  label: ReactNode;
                  fromArray?: boolean;
                  actualValue?: any;
                  index: number;
                  key?: string;
                }[] = [];

                if (!activeFilters[field]?.length) {
                  return;
                }

                activeFilters[field]?.forEach((v, i) => {
                  if (Array.isArray(v.value)) {
                    values.push(
                      ...v.value.map((v) => ({
                        value: filterValueToString(v),
                        label: !v ? null : filterValueToString(v),
                        fromArray: true,
                        actualValue: v.value,
                        index: i,
                        key: filterValueToString(v),
                      })),
                    );
                  } else {
                    values.push({
                      value: !v.value ? null : filterValueToString(v.value),
                      label: !v.value ? null : filterValueToString(v.value),
                      actualValue: v.value,
                      fromArray: false,
                      index: i,
                    });
                  }
                });
                return (
                  <Group gap="4" key={field} style={{ overflow: 'visible' }}>
                    <Badge
                      variant="transparent"
                      color={colorScheme === 'dark' ? 'white' : 'dark'}
                      pl="0"
                      pr="5"
                    >
                      {filterInfo?.row.view?.name}
                    </Badge>
                    <>
                      {values?.map((v) => {
                        const dataRow = filterInfo?.data?.find(
                          (d) => d.value === v.actualValue,
                        );
                        return (
                          <PixiTooltip key={v.value} label="Click to remove">
                            <Badge
                              key={v.key}
                              rightSection={<PixiIcon name="xmark" size="sm" />}
                              tt="inherit"
                              size="lg"
                              onClick={() => {
                                if (v.fromArray && v.value) {
                                  setFilter(field, [
                                    {
                                      value: v.value,
                                      modifier: '$and',
                                    },
                                  ]);
                                  return;
                                }
                                if (values?.length > 1) {
                                  setFilter(
                                    field,
                                    activeFilters[field]?.filter(
                                      (d) => d.value !== v.actualValue,
                                    ),
                                    { replace: true },
                                  );
                                  return;
                                }
                                setFilter(field, null, { replace: true });
                              }}
                              color={color}
                              variant="filled"
                              style={{ cursor: 'pointer' }}
                            >
                              {dataRow ? (
                                <FilterDataRender
                                  filter={filterInfo?.row}
                                  data={dataRow}
                                  onlyText
                                />
                              ) : !v?.value ? (
                                filterInfo?.row?.view?.emptyValue?.label
                              ) : (
                                <>{v.value}</>
                              )}
                            </Badge>
                          </PixiTooltip>
                        );
                      })}
                    </>
                  </Group>
                );
              })}
            {activeShortcutsData?.length ? (
              <>
                <Group gap="0">
                  <Badge
                    variant="transparent"
                    pl="0"
                    color="dark"
                    radius="0"
                    pr="5"
                  >
                    Filter Shortcut
                  </Badge>
                  {activeShortcutsData?.map((activeShortcut) => {
                    if (forceFilterShortcuts?.includes(activeShortcut?.id)) {
                      return <></>;
                    }
                    return (
                      <PixiTooltip
                        key={activeShortcut.id}
                        label="Click to remove"
                      >
                        <Badge
                          color={color}
                          variant="light"
                          size="md"
                          style={{ cursor: 'pointer' }}
                          onClick={() => {
                            toggleActiveShortcut({ id: activeShortcut.id });
                            activeShortcut.revert();
                          }}
                        >
                          {activeShortcut.label}
                        </Badge>
                      </PixiTooltip>
                    );
                  })}
                </Group>
              </>
            ) : (
              <></>
            )}
          </Group>
        </>
      )}
    </>
  );
  return statusBar;
}
