import React, { useEffect, useReducer, useRef, useState } from 'react';
import { Button, Flex, Icon, Loader } from 'elements_v2';
import Badge from 'elements_v2/Badge';
import Card from 'elements_v2/Card';
import Progress from 'elements_v2/Progress';
import { useMediaContext } from 'hooks';
import useInspectionTool from 'hooks/files/useInspectionTool';
import useStorageUrl from 'hooks/files/useStorageUrl';
import Gallery from 'react-photo-gallery';
import { useAppContext } from 'contexts/Providers/AppProvider';

export function InspectionToolCard(props) {
  const { photo, margin, isFilesAlreadyExistsChecked, mediaFile } = props;
  const App = useAppContext();
  const ref = useRef();
  const { generateUrl } = useStorageUrl();

  return (
    <Card
      style={{
        width: photo.width,
        height: photo.height,
        minWidth: 'auto',
        margin: `0 ${margin}px ${margin}px 0`,
      }}
      contentStyle={{
        display: 'flex',
        alignItems: 'center',
        minHeight: '100%',
        borderRadius: 0,
      }}
      onClick={() => App.toggleAssetPreview(mediaFile)}
    >
      {isFilesAlreadyExistsChecked && (
        <>
          {mediaFile ? (
            <Badge
              size="large"
              style={{ position: 'absolute', top: 5, right: 5 }}
            >
              File exists
            </Badge>
          ) : (
            <Icon
              name={photo.selected ? 'CheckSquareFill' : 'Square'}
              bubble
              button
              onClick={() => props.onClick(photo.data, ref)}
              style={{ position: 'absolute', left: 5, top: 5 }}
            />
          )}
        </>
      )}
      <Card.Image disabled={photo.data?.exists} style={{ padding: 0 }}>
        <img
          src={generateUrl(photo.src)}
          width={photo.width}
          height={photo.height}
          key={photo?.data?.id}
          alt={photo.src}
          style={{ objectFit: 'contain', objectPosition: 'center' }}
          ref={ref}
        />
      </Card.Image>
    </Card>
  );
}

export function InspectionToolProgress(props) {
  const { inspectionTool, isProcessing } = props;

  if (inspectionTool.isProcessing || isProcessing) {
    return (
      <Flex column>
        <Loader>
          Your file is being processed, this will run in the background.
        </Loader>
      </Flex>
    );
  }
  return (
    <Flex column>
      <Loader>
        {inspectionTool?.latestMessage?.statusMessage
          ? inspectionTool?.latestMessage?.statusMessage
          : inspectionTool?.latestMessage?.code
            ? inspectionTool.translateCode(inspectionTool?.latestMessage?.code)
            : 'Preparing Inspection Tool...'}
      </Loader>
      <div style={{ textAlign: 'center' }}>
        {inspectionTool?.latestMessage?.name
          ? `Processing ${inspectionTool?.latestMessage?.name}`
          : ''}
      </div>
      {['COLLECTING_FILES', 'CREATING_PREVIEWS'].includes(
        inspectionTool?.latestMessage?.code,
      ) &&
        inspectionTool?.latestMessage?.globalProgress?.total === 1 && (
          <Progress
            currentLabelPercentage
            maxLabel={inspectionTool.translateCode(
              inspectionTool?.latestMessage?.code,
            )}
            current={inspectionTool?.latestMessage?.progress?.processed ?? 0}
            max={inspectionTool?.latestMessage?.progress?.total ?? 1}
          />
        )}
      {inspectionTool?.latestMessage?.globalProgress?.total > 1 && (
        <Progress
          currentLabelPercentage
          maxLabel="Total amount of files for inspection"
          current={
            inspectionTool?.latestMessage?.globalProgress?.processed ?? 0
          }
          max={inspectionTool?.latestMessage?.globalProgress?.total ?? 1}
        />
      )}
    </Flex>
  );
}

export default function InspectionTool(props) {
  const Media = useMediaContext();
  const inspectionTool = useInspectionTool(Media);
  const [isFilesAlreadyExistsChecked, setIsFilesAlreadyExistsChecked] =
    useState(false);

  const [filesAlreadyExists, setFilesAlreadyExists] = useReducer(
    (state, action) => [...action],
    [],
  );

  // Extracted media from pptx
  const [extracted, setExtracted] = useReducer(
    (state, action) => [...action],
    [],
  );
  const [selectedCards, setSelectedCards] = useReducer(
    (state, action) => [...action],
    [],
  );

  async function findMediaThatExists(extractedFiles) {
    setIsFilesAlreadyExistsChecked(false);
    const files = await Media.alreadyUploaded(
      extractedFiles.map((file) => file.checksum),
    );
    setFilesAlreadyExists(files);
    setIsFilesAlreadyExistsChecked(true);
  }

  useEffect(() => {
    if (
      props.isEditing &&
      !props.file?.processing?.extracted?.isProcessing &&
      props.file?.processing?.extracted?.status?.code === 'DONE'
    ) {
      inspectionTool.getExtractedFiles(props.file._id);
    }
  }, [props.isEditing, props.file?.processing?.extracted?.isProcessing]);

  useEffect(() => {
    if (inspectionTool?.extractedFiles?.length) {
      findMediaThatExists(inspectionTool.extractedFiles);
      setExtracted(
        inspectionTool.extractedFiles
          .filter((image) => !!image?.previews?.length)
          .filter((image) => {
            const preview = image?.previews[0];
            return preview?.width && preview?.height && preview?.url;
          }),
      );
      setSelectedCards([]);
    }
  }, [inspectionTool?.extractedFiles]);

  const imageRenderer = React.useCallback(
    (imageProps) => {
      return (
        <InspectionToolCard
          {...imageProps}
          mediaFile={filesAlreadyExists.find(
            (f) => f.file.checksum === imageProps.photo.data.checksum,
          )}
          isFilesAlreadyExistsChecked={isFilesAlreadyExistsChecked}
        />
      );
    },
    [extracted, selectedCards, filesAlreadyExists, isFilesAlreadyExistsChecked],
  );

  const statusCode =
    inspectionTool?.latestMessage?.status?.code ||
    props.file?.processing?.extracted?.status?.code;

  if (
    (inspectionTool.isRunning ||
      inspectionTool.isProcessing ||
      (props.file?.processing?.extracted?.isProcessing &&
        parseInt(
          `${(new Date() - new Date(props.file?.processing?.extracted?.status?.updatedAt)) / 1000 / 60}`,
        ) < 16)) &&
    statusCode !== 'COLLECTING_SELECTED_FILES'
  ) {
    return (
      <InspectionToolProgress
        inspectionTool={inspectionTool}
        isProcessing={props.file?.processing?.extracted?.isProcessing}
      />
    );
  }

  return (
    <>
      <div style={{ display: 'flex', paddingTop: 10, marginBottom: 10 }}>
        <Button
          pixi
          as="div"
          inline
          onClick={async () => {
            await inspectionTool.runInspectionSocket(props.file);
          }}
        >
          <Icon name="LightningChargeFill" />
          {extracted?.length
            ? 'Inspect files again'
            : 'Inspect embedded media files'}
        </Button>
        {!!extracted?.length && (
          <>
            <Button
              pixi
              as="div"
              inline
              onClick={async () => {
                extracted
                  .filter((extractedFile) => !extractedFile.exists)
                  .map((extractedFile) => {
                    if (!selectedCards.includes(extractedFile.id)) {
                      selectedCards.push(extractedFile.id);
                    }
                    setSelectedCards(selectedCards);
                  });
              }}
            >
              <Icon name="CardChecklist" />
              Select all files
            </Button>
            <Button
              pixi
              as="div"
              inline
              disabled={
                (extracted?.length && !selectedCards.length) ||
                statusCode === 'COLLECTING_SELECTED_FILES'
              }
              onClick={async () => {
                await inspectionTool.runInspectionSocket(
                  props.file,
                  '/import',
                  selectedCards,
                );
              }}
            >
              {statusCode === 'COLLECTING_SELECTED_FILES' ? (
                <Loader small />
              ) : (
                <Icon name="CloudUpload" />
              )}
              Import selected files to media library
            </Button>
          </>
        )}
      </div>
      {!!extracted?.length && (
        <Gallery
          photos={extracted.map((image) => {
            const preview = image.previews[0];
            const { width } = preview;
            const { height } = preview;
            const preparedImage = {
              width,
              height,
              src: preview.url,
              selected: selectedCards.includes(image.id),
              data: image,
            };
            if (preparedImage.height / preparedImage.width < 0.2) {
              preparedImage.height = preparedImage.width / 2;
            }
            return preparedImage;
          })}
          renderImage={imageRenderer}
          columns={extracted?.length === 1 ? 2 : null}
          direction={extracted?.length === 1 ? 'column' : 'row'}
          targetRowHeight={200}
          margin={4}
          onClick={(value, _ref2) => {
            if (selectedCards.includes(value.id)) {
              setSelectedCards(
                selectedCards.filter(
                  (selected_values) => selected_values !== value.id,
                ),
              );
            } else {
              selectedCards.push(value.id);
              setSelectedCards(selectedCards);
            }
          }}
        />
      )}
      {!extracted?.length &&
        props.file?.processing?.extracted?.isProcessing === false &&
        props.file?.processing?.extracted?.status?.code === 'DONE' && (
          <span>No media files were found inspecting this file</span>
        )}
    </>
  );
}
