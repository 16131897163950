import { useUserContext } from 'hooks/useUserContext';
import { useEffect, useState } from 'react';
import { ToastService } from '../../../elements';

export default function useHubspotImportAuth() {
  const User = useUserContext();
  const [isConnected, setIsConnected] = useState(false);
  const [isConnecting, setIsConnecting] = useState(false);
  const [isReceiving, setIsReceiving] = useState(false);
  const [connectedDrivers, setConnectedDrivers] = useState([]);
  const [connectionType, setConnectionType] = useState(undefined);

  async function connect() {
    /**
     * getting all active connections
     */
    setIsConnecting(true);
    const connections = await User.request.proxy.getConnections(['hubspot']);
    setConnectedDrivers(connections?.data || []);
    setIsConnected(!!connections?.data?.length);
    setIsConnecting(false);
  }

  function getConnectionDetails(tenant_id) {
    /**
     * getting connection details
     * @type {*[]}
     */
    const connectedDrive = connectedDrivers.filter(
      (drive) => drive.tenant_id === tenant_id,
    );

    if (connectedDrive.length) {
      setConnectionType(
        connectedDrive[0].connection_details?.connection_type ??
          connectedDrive[0]?.connection_type,
      );
      return connectedDrive[0];
    }
  }

  async function getConnections() {
    return connectedDrivers;
  }

  async function disconnect(tenant_id) {
    /**
     * disconnect specific connection
     */
    setIsConnecting(true);
    await User.request.proxy.deleteConnection(tenant_id);
    await connect();
    setIsConnecting(false);
  }

  function getDownloadURL(url) {
    /**
     * fixme: Getting download URL for the Hubspot file
     */

    return `?fixme`;
  }

  useEffect(() => {
    if (User.request.community.communitySlug) {
      (async () => {
        await connect();
      })();
    }
  }, [User.request.community.communitySlug]);

  async function request(
    tenant_id,
    folderId = 0,
    query = '',
    isRecentFiles = false,
    assetId = 0,
  ) {
    /**
     * Send request for getting files and folders
     */

    if (!isConnected) {
      return false;
    }

    if (assetId === 0) {
      // on importing do not flickering with submenu
      setIsReceiving(true);
    }
    await getConnectionDetails(tenant_id);
    try {
      const response = await User.request.proxy.exploreHubspotConnection({
        tenant_id,
        assetId,
        folderId,
        query,
        isRecentFiles,
      });
      if (assetId === 0) {
        // on importing do not flickering with submenu
        setIsReceiving(false);
      }
      return response;
    } catch (e) {
      if (e.status !== 401) {
        ToastService.createToast({
          message: 'Connection is not valid. Please, check the configuration.',
          error: true,
          delay: 6000,
        });
      }
      if (assetId === 0) {
        // on importing do not flickering with submenu
        setIsReceiving(false);
      }
      return { status: 'error', data: [] };
    }
  }

  return {
    isConnecting,
    isConnected,
    isReceiving,
    connectedDrivers,
    connectionType,
    getDownloadURL,
    getConnectionDetails,
    getConnections,
    request,
    connect,
    disconnect,
  };
}
