import { PixiIconName } from '@pixi/elements/Icon';
import { AssetGridSortByTypes } from '../Helpers';
import { useAssetGridContext } from '../AssetGridContext';

export default function useAssetGridSortBy() {
  const AssetGrid = useAssetGridContext();

  const AssetGridSortBy: {
    name: string;
    field: AssetGridSortByTypes;
    direction: 'ascending' | 'descending';
    icon: PixiIconName;
  }[] = [
    {
      name: 'Name A-Z',
      field: 'name',
      direction: 'ascending',
      icon: 'arrow-down-a-z',
    },
    {
      name: 'Name Z-A',
      field: 'name',
      direction: 'descending',
      icon: 'arrow-down-z-a',
    },
    {
      name: 'Newest first',
      field: 'createdAt',
      direction: 'descending',
      icon: 'arrow-down-9-1',
    },
    {
      name: 'Oldest first',
      field: 'createdAt',
      direction: 'ascending',
      icon: 'arrow-down-1-9',
    },
    {
      name: 'Last updated',
      field: 'updatedAt',
      direction: 'descending',
      icon: 'arrow-down-1-9',
    },
  ];

  if (AssetGrid.activeShortcutIds?.find((s) => s.includes('trash'))) {
    AssetGridSortBy.push({
      name: 'Last deleted',
      field: 'trash.addedAt',
      direction: 'descending',
      icon: 'arrow-down-1-9',
    });
  }

  return AssetGridSortBy;
}
