import React, { createContext, useEffect, useState } from 'react';
import countries from 'data/countries';
import {
  useUserContext,
  useHackedDeepState,
  useCompare,
  useTextContext,
} from 'hooks';
import { analytics } from 'services';
import { usePrevious } from 'hooks/usePrevious';
import { CustomerContext } from 'contexts/Customer';

export const VAT_STATUS = {
  FINISHED: 'FINISHED',
  LOADING: 'LOADING',
  FAILED: 'FAILED',
  NOT_STARTED: 'NOT_STARTED',
  COUNTRY_FAILED: 'COUNTRY_FAILED',
};

export const CustomerProvider = ({ children }) => {
  const userContext = useUserContext();
  const Text = useTextContext();
  const { djangoProfile, refreshProfile } = userContext;

  const initBillingDetails = {
    firstname: '',
    lastname: '',
    email: '',
    companyName: '',
    country: '',
    vatId: '',
    address: '',
    city: '',
    zipcode: '',
    coupon: '',
    card: false,
    isCompany: false,
  };

  const initErrors = {
    vatId: '',
    country: '',
  };

  const initVat = {
    isValid: false,
    status: VAT_STATUS.NOT_STARTED,
  };

  const initCustomer = {
    countryConfirmed: false,
    username: '',
    community: userContext.spaces?.selected?.slug ?? '',
    userId: '',
    isEU: false,
    seats: 1,
    tax: 0.25,
    id: '',
    metadata: {
      stripe: {
        id: '',
        region: '',
      },
    },
  };

  const initStripeState = {
    plans: {},
    tieredPlans: {},
    taxRegions: [],
    selectedPlan: '',
  };

  const initSettings = {
    STATUS: 'pending',
    ACCOUNT_TYPE: 'pending',
    ACTIVE: false,
    SUBSCRIBED: false,
    ENDED: false,
    IS_CUSTOMER: false,
    NEXT_EVENT_EPOCH: null,
    NEXT_EVENT_DAYS: null,
    LICENSES: 0,
    PRO_AND_BUSINESS: false,
  };

  const [billingDetails, setBillingDetails] =
    useHackedDeepState(initBillingDetails);
  const [settings, setSettings] = useHackedDeepState(initSettings);
  const [errors, setErrors] = useHackedDeepState(initErrors);
  const [vat, setVat] = useHackedDeepState(initVat);
  const [customer, setCustomer] = useHackedDeepState(initCustomer);
  const [stripeData, setStripe] = useHackedDeepState(initStripeState);
  const [coupon, setCoupon] = useHackedDeepState({});
  const [subscription, setSubscription] = useHackedDeepState({});
  const [isSubscriptionReady, setIsSubscriptionReady] = React.useState(false);
  const [isSubscriptionLoading, setIsSubscriptionLoading] =
    React.useState(false);
  const [sources, setSources] = useHackedDeepState({ data: [] });
  const [productType, setProductType] = useState('');
  const [subscriptions, setSubscriptions] = useState([]);
  const [subscriptionSelected, selectSubscription] = useState(false);

  const { selectedPlan, plans } = stripeData;
  const { card } = billingDetails;

  const prevIsCompany = usePrevious(billingDetails.isCompany);
  const prevCountry = usePrevious(billingDetails.country);

  useEffect(() => {
    if (customer.countryConfirmed && billingDetails.isCompany) {
      const prevRegion = getRegion(prevCountry);
      const region = getRegion(billingDetails.country);
      if (region !== prevRegion) {
        setCustomer({ metadata: { stripe: { region } } });
        window.location.reload();
      }
    }
  }, [
    billingDetails.country,
    billingDetails.isCompany,
    customer.countryConfirmed,
    prevCountry,
    setCustomer,
  ]);

  useEffect(() => {
    (async () => {
      if (customer.countryConfirmed) {
        await initStripe();
      }
    })();
  }, [customer.countryConfirmed, customer.metadata.stripe.region]);

  useEffect(() => {
    if (Object.keys(subscription).length) {
      const endDate = new Date(subscription.endedDate);
      const renewDate = new Date(subscription.renewsAt);
      const now = new Date();
      const ONE_DAY = 1000 * 60 * 60 * 24;

      setSettings({
        STATUS: subscription.status,
        IS_CUSTOMER: true,
        PRO_AND_BUSINESS: subscriptions?.length === 2,
        ENDED: subscription.endedDate
          ? endDate.getTime() < now.getTime()
          : false,
        ACCOUNT_TYPE: subscription.productType === 'team' ? 'business' : 'pro',
        SUBSCRIBED: subscription.status !== 'canceled',
        ACTIVE:
          subscription.status === 'active' ||
          subscription.status === 'past_due' ||
          subscription.status === 'pending_cancelation' ||
          subscription.status === 'trialing' ||
          subscription.status === 'pending', // TODO; Add check so subscription is not older than 24h.
        NEXT_EVENT_EPOCH:
          (subscription.endedDate && endDate.getTime()) ||
          (subscription.renewsAt && renewDate.getTime()),
        NEXT_EVENT_DAYS:
          (subscription.endedDate &&
            Math.ceil((endDate.getTime() - now.getTime()) / ONE_DAY)) ||
          (subscription.renewsAt &&
            Math.ceil((renewDate.getTime() - now.getTime()) / ONE_DAY)),
        LICENSES: subscription.numberOfLicenses,
      });
    }
  }, [
    setSettings,
    subscription.type,
    subscription.status,
    subscriptions?.length,
    userContext.spaces,
  ]);

  useEffect(() => {
    (async () => {
      if (userContext.authenticated && userContext.initialized) {
        setIsSubscriptionLoading(true);
        let response;
        try {
          if (
            djangoProfile?.id &&
            !userContext?.data?.selectedCommunity?.djangoCommunity
              ?.is_demo_disabled
          ) {
            response = await getCustomer(djangoProfile.id, 'django');
            const { data: customerData } = response;
            setBillingDetails(customerData.billingDetails);

            // Hack to pass state validation if state is null, can be removed once state is requied
            if (!billingDetails.state) {
              setBillingDetails({ state: '' });
            }

            setCustomer({
              username: djangoProfile.email,
              community: userContext.spaces?.selected?.slug,
              countryConfirmed: false,
              tax: customerData.tax?.percentage ?? 0.25,
              userId: customerData.userId,
              id: customerData._id,
              metadata: {
                stripe: customerData.metadata.stripe,
              },
            });
            const subscriptionsData = await getSubscription(customerData._id);

            const {
              data: { subscriptions, selected },
            } = subscriptionsData;

            setSubscriptions(subscriptions);

            if (selected) {
              const [subscription] = subscriptions.filter(
                (sub) => sub._id === selected,
              );
              if (subscriptions.length === 1) {
                selectSubscription(true);
              }
              setSubscription(subscription);
            } else {
              setSubscription(subscriptionsData.data);
            }

            const { data } = await getSources(
              customerData.metadata.stripe.id,
              customerData.metadata.stripe.region,
            );

            if (data) {
              setSources(data);
            }
          }
        } catch (e) {
          setIsSubscriptionLoading(false);
          setIsSubscriptionReady(true);
          // 404 fallback
        }
        if (!customer.metadata.stripe.region) {
          await setCustomerLocation();
        }
        setBillingDetails({
          firstname:
            !billingDetails.firstname &&
            djangoProfile?.first_name &&
            djangoProfile?.first_name !== 'A.'
              ? djangoProfile.first_name
              : billingDetails.firstname || '',
          lastname:
            !billingDetails.lastname &&
            djangoProfile?.last_name &&
            djangoProfile?.last_name !== 'User'
              ? djangoProfile.last_name
              : billingDetails.lastname || '',
          email:
            (!billingDetails.email && djangoProfile?.username) ||
            billingDetails.email ||
            '',
          companyName:
            (!billingDetails.companyName && djangoProfile?.ibc_company_name) ||
            '',
        });
        setCustomer({
          userId: djangoProfile?.id,
          community: userContext.spaces?.selected?.slug ?? '',
        });
        setIsSubscriptionLoading(false);
        setIsSubscriptionReady(true);
      } else {
        setIsSubscriptionLoading(false);
      }
    })();
  }, [userContext.initialized, userContext.authenticated, djangoProfile?.id]);

  const hasCountryChanged = useCompare(billingDetails.country);

  useEffect(() => {
    if (hasCountryChanged && billingDetails.vatId) {
      validateVAT();
    }
  }, [billingDetails.vatId, hasCountryChanged]);

  useEffect(() => {
    (async () => {
      if (djangoProfile) {
        if (billingDetails.state) {
          await refreshTaxRates();
          const tax = await getStateTax();
          setCustomer({ tax });
        } else {
          const { country: djangoCountry } = djangoProfile;
          const { country: billingCountry } = billingDetails;
          const isEU = checkIfEU(billingCountry, djangoCountry);
          setCustomer({ isEU: !!isEU });
          const tax = getTax(isEU, billingDetails.isCompany, vat.isValid);
          setCustomer({ tax });
        }
      }
    })();
  }, [billingDetails, billingDetails.isCompany, djangoProfile, vat.isValid]);

  const getRegion = (country) =>
    country === 'Canada' || country === 'Mexico' || country === 'United States'
      ? 'US'
      : 'EU';

  const setCustomerLocation = async (selectedCountry, selectedState) => {
    if (selectedCountry) {
      const region = getRegion(selectedCountry);
      setCustomer({ metadata: { stripe: { region } }, countryConfirmed: true });
      if (selectedCountry === 'United States') {
        setBillingDetails({ country: selectedCountry, state: selectedState });
      } else {
        setBillingDetails({ country: selectedCountry });
      }
      return;
    }

    let countryCode = 'SE';
    try {
      const { country_code } = userContext.country;
      countryCode = country_code;
    } catch {
      // 404 fallback
    }

    // If country code is EU, fallback to SE.
    if (!countryCode || countryCode === 'EU') {
      countryCode = 'SE';
    }

    const [{ Name: country }] = countries.countries.filter(
      (country) => country.Code === countryCode.toUpperCase(),
    );
    setBillingDetails({ country: country || 'Sweden' });

    const region = getRegion(country);
    setCustomer({ metadata: { stripe: { region } } });
  };

  const getTax = (isEU, isCompany, VATIsValid) => {
    // Tax logic
    let tax = 0.25;
    const taxLogic = {
      swedishConsumer: isEU && billingDetails.country === 'Sweden',
      EUConsumer: isEU && (!isCompany || !VATIsValid),
      EUCompany:
        isEU && isCompany && VATIsValid && billingDetails.country !== 'Sweden',
      nonEUConsumer:
        !isEU &&
        (!isCompany || !VATIsValid) &&
        billingDetails.country !== 'Canada' &&
        billingDetails.country !== 'Mexico',
      nonEUCompany:
        !isEU &&
        isCompany &&
        billingDetails.country !== 'Canada' &&
        billingDetails.country !== 'Mexico',
      mxOrCa:
        billingDetails.country === 'Canada' ||
        billingDetails.country === 'Mexico',
    };

    // Tax mapper
    const taxMapper = {
      swedishConsumer: 0.25,
      EUConsumer: isEU && isEU.percentage / 100,
      EUCompany: 0.0,
      nonEUConsumer: 0.25,
      nonEUCompany: 0.0,
      default: 0.25,
      mxOrCa: 0,
    };

    const taxEntries = Object.entries(taxLogic);
    taxEntries.forEach(([taxKey, isMatch]) => {
      if (isMatch) {
        tax = taxMapper[taxKey];
      }
    });
    return tax;
  };

  const checkIfEU = (billingCountry, djangoCountry) => {
    let isEU;
    if (billingCountry) {
      [isEU] = stripeData.taxRegions.filter(
        (region) =>
          region &&
          region.jurisdiction === billingCountry &&
          region.display_name === 'VAT',
      );
    } else {
      [isEU] = stripeData.taxRegions.filter(
        (region) =>
          region.code === djangoCountry && region.display_name === 'VAT',
      );
    }
    return isEU;
  };

  const getStateTax = () => {
    // Tax logic
    const [stateTax] = stripeData.taxRegions.filter(
      (region) =>
        region &&
        region.jurisdiction === billingDetails.state &&
        region.display_name === 'State Tax',
    );
    if (stateTax) {
      return stateTax.percentage / 100;
    }
    return 0.25;
  };

  const toggleIsCompany = async () => {
    if (prevIsCompany) {
      setBillingDetails({
        firstname: '',
        lastname: '',
        companyName: '',
        vatId: '',
        address: '',
        city: '',
        zipcode: '',
        isCompany: !billingDetails.isCompany,
      });
      await updateCustomer();
    } else {
      setBillingDetails({ isCompany: !billingDetails.isCompany });
    }
  };

  const createCustomer = (country = billingDetails.country) => {
    const data = {
      ...customer,
      billingDetails: {
        ...billingDetails,
        country,
        email: billingDetails.email || djangoProfile.email,
      },
      countryConfirmed: true,
      userId: djangoProfile.id,
      username: djangoProfile.email,
    };
    return userContext.request.customer.createCustomer(
      data,
      customer.metadata.stripe.region,
    );
  };

  const getPlans = async (plans) => {
    return userContext.request.customer.getPlans(
      plans,
      customer.metadata.stripe.region,
    );
  };

  const getCustomer = async (id, identifier) => {
    return userContext.request.customer.getCustomer(id, identifier);
  };

  const getInvoices = async () => {
    return userContext.request.customer.getInvoices(
      customer.metadata.stripe.id,
      customer.metadata.stripe.region,
    );
  };

  const cancelSubscription = async () => {
    try {
      userContext.request.customer.cancelSubscription(
        customer.id,
        subscription.metadata.stripe.id,
        customer.metadata.stripe.region,
      );
      setSubscription({ status: 'pending_cancelation' });
    } catch {
      // non 2XX fallback
    }
  };
  const getSubscription = async (id) => {
    return userContext.request.customer.getSubscription(id);
  };

  const refreshSubscription = async () => {
    /*
    const promises = [getSubscription(customerData._id), getSources(customerData.metadata.stripe.id, customerData.metadata.stripe.region)];
            const [subscriptionsData, { data }] = await Promise.all(promises);

            const {
              data: { subscriptions, selected },
            } = subscriptionsData;

            setSubscriptions(subscriptions);

            if (selected) {
              const [subscription] = subscriptions.filter(sub => sub._id === selected);
              if (subscriptions.length === 1) {
                selectSubscription(true);
              }
              setSubscription(subscription);
            } else {
              setSubscription(subscriptionsData.data);
            }
            if (data) {
              setSources(data);
            }
          }
    */
    const subscriptionsData =
      await userContext.request.customer.getSubscription(customer.id);
    const {
      data: { subscriptions, selected },
    } = subscriptionsData;
    setSubscriptions(subscriptions);

    const [subscription] = subscriptions.filter(
      (sub) => sub?._id === subscription?._id,
    );
    setSubscription(subscription);

    if (!subscription && selected) {
      const [subscription] = subscriptions.filter(
        (sub) => sub._id === selected,
      );
      if (subscriptions.length === 1) {
        selectSubscription(true);
      }
      setSubscription(subscription);
    }
  };

  const getSources = async (id, region) => {
    return userContext.request.customer.getSources(id, region);
  };

  const setDefaultSource = async (id) => {
    try {
      await userContext.request.customer.setDefaultSource(
        id,
        customer.metadata.stripe.id,
        customer.metadata.stripe.region,
      );
    } catch {
      // fallback
    }
    try {
      const { data } = await getSources(
        customer.metadata.stripe.id,
        customer.metadata.stripe.region,
      );
      setSources(data);
    } catch {
      // fallback
    }
  };

  const createSource = async (source) => {
    try {
      await userContext.request.customer.createSource(
        source,
        customer.metadata.stripe.id,
        customer.metadata.stripe.region,
      );
    } catch {
      // fallback
    }
    try {
      const { data } = await getSources(
        customer.metadata.stripe.id,
        customer.metadata.stripe.region,
      );
      setSources(data);
    } catch {
      // fallback
    }
  };

  const removeSource = async (id) => {
    try {
      await userContext.request.customer.removeSource(
        customer.metadata.stripe.id,
        id,
        customer.metadata.stripe.region,
      );
    } catch {
      // fallback
    }
    try {
      const { data } = await getSources(
        customer.metadata.stripe.id,
        customer.metadata.stripe.region,
      );
      setSources(data);
    } catch {
      // fallback
    }
  };

  const handleVatIdChange = (value, selectedCountry) => {
    const useCountry = selectedCountry || billingDetails.country;
    let vatId = value;
    const firstCountryChar = value.substring(0, 1);
    if (firstCountryChar && !isNaN(firstCountryChar)) {
      const [{ Code }] = countries.countries.filter(
        (country) => country.Name === useCountry,
      );
      vatId = Code + value;
    }
    setVat({ status: VAT_STATUS.NOT_STARTED });
    return vatId;
  };

  const getTaxRates = async () => {
    return userContext.request.customer.getTaxRates(
      customer.metadata.stripe.region,
    );
  };

  const refreshTaxRates = async () => {
    const { data: taxRegions } = await userContext.request.customer.getTaxRates(
      customer.metadata.stripe.region,
    );
    setStripe({ taxRegions });
  };

  const getInfoFromVatId = async (vatId) => {
    return userContext.request.customer.getInfoFromVatId(vatId);
  };

  const getCoupon = async () => {
    return userContext.request.customer.getCoupon(
      billingDetails.coupon,
      customer.metadata.stripe.region,
    );
  };

  const updateCustomer = (vatId = billingDetails.vatId) => {
    const selectedCustomer = {
      ...customer,
      tax: {
        type: 'eu_vat',
        percentage: customer.tax,
        id: vatId,
      },
      billingDetails,
      username: djangoProfile.username,
    };

    return userContext.request.customer.updateCustomer(
      customer.id,
      selectedCustomer,
      customer.metadata.stripe.region,
    );
  };

  const updateSubscription = async (data) => {
    await userContext.request.customer.updateSubscription(
      data,
      customer.id,
      subscription._id,
      subscription.metadata.stripe.region,
    );
    await refreshSubscription();
  };

  const getCountryCodeFromVatId = (selectedVat) => {
    const vatId = selectedVat || billingDetails.vatId;
    if (vatIdHasCountryCode(vatId)) {
      const firstCountryChar = vatId.substring(0, 1);
      const secondCountryChar = vatId.substring(1, 2);
      return `${firstCountryChar}${secondCountryChar}`.toLowerCase();
    }
  };

  const activatePro = () => {
    const [pro] = subscriptions.filter(
      (sub) => sub.productType === 'individual',
    );
    if (pro) {
      setSubscription(pro);
      selectSubscription(true);
    }
  };

  const activateBusiness = () => {
    const [business] = subscriptions.filter(
      (sub) => sub.productType === 'team',
    );
    if (business) {
      setSubscription(business);
      selectSubscription(true);
    }
  };

  const vatIdHasCountryCode = (selectedVat) => {
    const vatId = selectedVat || billingDetails.vatId;
    const firstCountryChar = vatId.substring(0, 1);
    const secondCountryChar = vatId.substring(1, 2);
    if (!isNaN(firstCountryChar) && !isNaN(secondCountryChar)) {
      return false;
    }
    return true;
  };

  const isCountryCodeMatch = (country, selectedVat) => {
    const vatId = selectedVat || billingDetails.vatId;
    const { country: billingCountry } = billingDetails;
    const selectedCountry = country || billingCountry;
    const [
      {
        metadata: { ISO_CODE: countryCode },
      },
    ] = stripeData.taxRegions.filter(
      (region) =>
        region &&
        region.jurisdiction === selectedCountry &&
        region.display_name === 'VAT',
    );
    return vatId.substring(0, 2) === countryCode;
  };

  const isSwedishMomsReg = (selectedVat) => {
    const vatId = selectedVat || billingDetails.vatId;
    return getCountryCodeFromVatId(vatId) === 'se' && vatId.length === 14;
  };

  const setVatIdFail = (error) => {
    if (error) {
      setErrors({ vatId: error });
    }
    setVat({ status: VAT_STATUS.FAILED, isValid: false });
  };

  const setVatIdSuccess = () => {
    setVat({ status: VAT_STATUS.FINISHED, isValid: true });
    setErrors({ vatId: '' });
  };

  const validateVAT = async (country, selectedVat) => {
    const vatId = selectedVat || billingDetails.vatId;
    setVat({ status: VAT_STATUS.LOADING });
    setErrors({ vatId: '', country: '' });
    try {
      if (!vatIdHasCountryCode(vatId)) {
        setVatIdFail('Your VAT id is missing country code.');
        return;
      }

      if (!isCountryCodeMatch(country, vatId)) {
        setErrors({ country: Text.get('view/payment/country_does_not_match') });
        setVat({ status: VAT_STATUS.COUNTRY_FAILED, isValid: false });
        return;
      }

      const { status, data } = await getInfoFromVatId(vatId);
      const { valid, company_name } = data;

      if (status === 'error') {
        setVatIdFail('VAT lookup failed.');
      }
      if (valid) {
        setVatIdSuccess();
        if (selectedVat) {
          return { companyName: company_name };
        }
        setBillingDetails({ companyName: company_name });
        return;
      }

      /*
      If a Sweish VAT id fails, pass it anyway.
      Because some Swedish companies have VAT ids (moms id) that is
      not meant to be used for export.
      */
      if (!valid && isSwedishMomsReg(vatId)) {
        setVatIdSuccess();
        return;
      }

      // Fallback, fail all other scenarios.
      setVatIdFail('VAT lookup failed.');
    } catch (e) {
      console.error(e);
      setVatIdFail('VAT lookup failed.');
    }
  };

  const hasRequiredInformation = () => {
    const detailsComplete =
      billingDetails.firstname &&
      billingDetails.lastname &&
      billingDetails.email &&
      billingDetails.country;
    let isFilled = false;
    if (!billingDetails.isCompany && card) isFilled = true;
    if (billingDetails.isCompany && !customer.isEU && detailsComplete && card)
      isFilled = true;
    if (
      billingDetails.isCompany &&
      customer.isEU &&
      detailsComplete &&
      card &&
      ((billingDetails.vatId && vat.isValid) || !billingDetails.vatId)
    )
      isFilled = true;
    return isFilled;
  };

  const calculateCost = (tiers, setPlan, seats) => {
    if (tiers) {
      // Shorthands
      let seatsCost = 0;
      let seatsCount = Number(seats || customer.seats);
      let tier = 0;
      const selectedPlan = setPlan || stripeData.selectedPlan;

      if (selectedPlan) {
        while (seatsCount > 0) {
          const currentTier = stripeData.tieredPlans[selectedPlan]?.tiers[tier];
          const { up_to, amount, unit_amount } = currentTier || {};

          if (seatsCount > up_to && up_to !== null) {
            seatsCount -= up_to;
            seatsCost += up_to * (amount || unit_amount);
          } else {
            seatsCost += seatsCount * (amount || unit_amount);
            seatsCount = 0;
          }

          tier++;
        }
      }

      seatsCost /= 100;

      const costInUSD = selectedPlan ? seatsCost : 0;
      let discount =
        coupon && coupon.valid && coupon.percent_off
          ? (costInUSD * (coupon.percent_off / 100)).toFixed(2)
          : 0;
      discount =
        coupon && coupon.valid && coupon.amount_off
          ? coupon.amount_off / 100
          : discount;
      const exclTax = Number(costInUSD).toFixed(2);
      const taxPercentage = `${customer.tax * 100}%`;
      const taxAmount = `$${
        (customer.tax * (costInUSD - discount)).toFixed(2) || ''
      }`;
      const total = `$${((costInUSD - discount) * (customer.tax + 1)).toFixed(
        2,
      )}`;
      return { total, taxAmount, taxPercentage, exclTax, discount, costInUSD };
    }
    let costInUSD = plans[selectedPlan] ? plans[selectedPlan].amount / 100 : 0;
    costInUSD =
      coupon && coupon.valid && coupon.percent_off
        ? (costInUSD * ((100 - coupon.percent_off) / 100)).toFixed(2)
        : costInUSD;
    costInUSD =
      coupon && coupon.valid && coupon.amount_off
        ? costInUSD - coupon.amount_off / 100
        : costInUSD;
    const exclTax = costInUSD;
    const taxPercentage = `${customer.tax * 100}%`;
    const taxAmount = `$${(customer.tax * costInUSD).toFixed(2) || ''}`;
    const total = `$${(costInUSD * (customer.tax + 1)).toFixed(2)}`;
    const saving =
      plans[selectedPlan] && selectedPlan.interval === 'year'
        ? `$${(119.88 * (customer.tax + 1)).toFixed(2)}`
        : '';
    return { saving, total, taxAmount, taxPercentage, exclTax };
  };

  const sendPayment = async (event, stripe, cardElement, method, community) => {
    event.preventDefault();
    if (method && method === 'SEPA') {
      const { source } = await stripe.createSource({
        type: 'sepa_debit',
        currency: 'eur',
        amount: 10, // TODO: Fix EUR
        owner: {
          email: billingDetails.email,
          name: `${billingDetails.firstname} ${billingDetails.lastname}`,
        },
        mandate: {
          notification_method: 'email',
        },
      });

      let country = billingDetails.country || djangoProfile.country;
      if (!country) {
        country = userContext.country.country_code;
      }
      if (!country) {
        country = 'SE';
      }

      const data = {
        data: {
          source,
          username: djangoProfile.username,
          billingDetails: {
            ...billingDetails,
            country,
            email: billingDetails.email || djangoProfile.username,
          },
          purchase: {
            coupon,
            items: [
              {
                plan: selectedPlan.id,
                quantity: customer.seats,
              },
            ],
          },
          community,
        },
      };

      if (billingDetails.vatId) {
        data.data.tax_type = 'eu_vat';
        data.data.tax_id = billingDetails.vatId;
      }

      /* const { data: { clientSecret, intentStatus }, error: e } = await userContext.request.user.activateSubscription(data); */
    } else {
      try {
        const {
          error,
          token: { id: source },
        } = await stripe.createToken();
        if (error) throw new Error(error.message);
        await updateCustomer();
        const data = {
          source,
          community: customer.community,
          purchase: {
            coupon: billingDetails.coupon,
            items: [
              {
                productType,
                plan: selectedPlan,
                quantity: customer.seats,
              },
            ],
          },
        };

        const {
          data: { clientSecret, intentStatus },
          error: e,
        } = await userContext.request.customer.createSubscription(
          customer.id,
          data,
          customer.metadata.stripe.region,
        );
        await handlePaymentIntent(
          e,
          intentStatus,
          stripe,
          clientSecret,
          cardElement,
        );
        await refreshProfile();
        await refreshSubscription();
      } catch (e) {
        await analytics.trackEvent('Purchase Failed (Pickit Backend)', {
          'Error Details': (e && e.message) || JSON.stringify(e),
        });
        console.error(e);
        const error = await e.json();
        const message =
          error.message === 'ValidationError' ? error.errors[0] : error.message;
        throw new Error(message);
      }
    }
  };

  const handlePaymentIntent = async (
    e,
    intentStatus,
    stripe,
    clientSecret,
    cardElement,
  ) => {
    if (e) {
      await analytics.trackEvent('Purchase Failed Stripe', {
        'Error Details': e,
      });
      throw new Error(e.message);
    }

    // Payment Intent logic
    if (intentStatus === 'succeeded') {
      await analytics.trackEvent('Purchase Successful', {
        'Purchase Localization': 'Before extra authentication',
      });
    } else if (
      intentStatus === 'requires_action' ||
      intentStatus === 'requires_source_action'
    ) {
      const { paymentIntent, error } = await stripe.handleCardPayment(
        clientSecret,
        cardElement,
        {
          payment_method_data: {
            billing_details: {
              email: billingDetails.email,
              name: `${billingDetails.firstname} ${billingDetails.lastname}`,
              address: {
                city: billingDetails.city,
                line1: billingDetails.address,
                postal_code: billingDetails.zipcode,
              },
            },
          },
          save_payment_method: true,
          receipt_email: billingDetails.email,
        },
      );
      if (paymentIntent && paymentIntent.status === 'succeeded') {
        await analytics.trackEvent('Purchase Successful', {
          'Purchase Localization': 'Extra authentication',
        });
      }
      if (error) {
        await analytics.trackEvent('Purchase Failed', {
          'Purchase Localization': 'Extra authentication',
          'Error Details': error,
        });
        throw new Error(error);
      }
    } else if (
      intentStatus === 'requires_payment_method' ||
      intentStatus === 'requires_source'
    ) {
      await analytics.trackEvent('Purchase Failed', {
        'Error Details': 'Payment method is missing',
      });
      throw new Error('Payment method is missing');
    } /* else {
      await analytics.trackEvent('Purchase Failed', {
        'Error Details': 'Unknown error',
      });
      throw new Error('Unkown error');
    }*/

    await analytics.trackEvent('User Subscribed', {
      'User Product': selectedPlan.nickname,
      'Product id': selectedPlan.id,
    });
  };

  const applyCoupon = async () => {
    try {
      const { data: coupon } = await getCoupon();
      if (coupon.raw && coupon.raw.code === 'resource_missing') {
        setCoupon({ valid: false, object: 'coupon' });
      } else {
        setCoupon(coupon);
      }
    } catch (e) {
      setCoupon({ valid: false, object: 'coupon' });
      throw e;
    }
  };

  const initStripe = async () => {
    if (customer.metadata.stripe.region) {
      const productIds =
        customer.metadata.stripe.region === 'US'
          ? window.pickit.keys.STRIPE_PLANS_US
          : window.pickit.keys.STRIPE_PLANS_EU;
      const tierdProductIds =
        customer.metadata.stripe.region === 'US'
          ? window.pickit.keys.STRIPE_TIERED_PLANS_US
          : window.pickit.keys.STRIPE_TIERED_PLANS_EU;

      const promises = [
        getPlans(productIds, customer.metadata.stripe.region),
        getTaxRates(customer.metadata.stripe.region),
        getPlans(tierdProductIds, customer.metadata.stripe.region),
      ];
      const [plansResponse, { data: taxRegions }, tieredPlansResponse] =
        await Promise.all(promises);

      const plansArr = [].concat(...plansResponse).map((res) => res.data);
      const plans = {};
      plansArr.forEach((plan) => {
        plans[plan.id] = plan;
      });

      const tieredPlansArr = []
        .concat(...tieredPlansResponse)
        .map((res) => res.data);
      const tieredPlans = {};
      tieredPlansArr.forEach((plan) => {
        tieredPlans[plan.id] = plan;
      });

      setStripe({
        plans,
        tieredPlans,
        taxRegions,
      });
    }
  };

  return (
    <CustomerContext.Provider
      value={{
        getPlans,
        getTaxRates,
        billingDetails,
        calculateCost,
        setBillingDetails,
        createCustomer,
        getInvoices,
        updateCustomer,
        setCustomerLocation,
        applyCoupon,
        sendPayment,
        setStripe,
        setSubscription,
        coupon,
        getSubscription,
        activatePro,
        selectSubscription,
        subscriptionSelected,
        getCustomer,
        setCustomer,
        removeSource,
        setDefaultSource,
        setProductType,
        customer,
        stripeData,
        validateVAT,
        updateSubscription,
        subscriptions,
        sources,
        checkIfEU,
        cancelSubscription,
        createSource,
        refreshSubscription,
        settings,
        handleVatIdChange,
        activateBusiness,
        subscription,
        isSubscriptionReady,
        hasRequiredInformation,
        setVat,
        initStripe,
        toggleIsCompany,
        vat,
        errors,
        isSubscriptionLoading,
      }}
    >
      {children}
    </CustomerContext.Provider>
  );
};
