import * as yup from 'yup';
import React from 'react';
import MediaTitleOverlay from '../_icons/MEDIA_TITLE_OVERLAY.svg?react';

export default [
  {
    moduleId: 'CONTENT_HEADER',
    moduleInterface: {
      media_file: 'FILE',
      contentSection: 'CONTENT',
    },
    status: {
      isEmpty: (data) => {
        return !data?.media_file?.fileId;
      },
    },
    moduleMetadataInterface: {
      titlePositionX: yup.mixed().oneOf(['left', 'center', 'right']),
      titlePositionY: yup.mixed().oneOf(['top', 'center', 'bottom']),
      withPadding: yup.boolean(),
      backgroundColor: yup.string(),
      textColor: yup.string(),
      textBackgroundGradient: yup.boolean(),
      titleSize: yup.mixed().oneOf(['small', 'medium', 'large']),
      textSize: yup.mixed().oneOf(['small', 'medium', 'large']),
      linkSize: yup.mixed().oneOf(['small', 'medium', 'large']),
      fileVariantId: yup.string(),
      imageDownloadable: yup.boolean(),
      autoScaleMedia: yup.boolean(),
      fullscreen: yup.boolean(),
      contentLayout: yup.mixed().oneOf(['fullWidth', 'centered', 'fullscreen']),
    },
    menuContent: {
      key: 'CONTENT_HEADER',
      icon: <MediaTitleOverlay />,
      name: 'Title on media',
    },
  },
];
