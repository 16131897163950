import {
  Box,
  Combobox,
  FocusTrap,
  Group,
  Image,
  Input,
  InputBase,
  ScrollArea,
  TextInput,
  ThemeIcon,
  useCombobox,
} from '@mantine/core';
import PixiDropdown, { PixiDropdownProps } from '@pixi/elements/Dropdown';
import PixiIcon from '@pixi/elements/Icon';
import PixiText from '@pixi/elements/Text';
import useLibraries from 'hooks/useLibraries';
import React, { ReactElement, useState } from 'react';

export default function SelectCollection({
  target,
  onCollectionClick,
  onSearch,
  value,
  onlyDropdown,
  asInput,
  customFilter,
  type,
  ...rest
}: {
  target?: React.ReactElement;
  value?: string;
  onSearch?: (query: string) => void | Promise<void>;
  onlyDropdown?: boolean;
  onCollectionClick: (
    folder: Pickit.CollectionInterface,
  ) => void | Promise<void>;
  asInput?: boolean;
  customFilter?: (collection: Pickit.CollectionInterface) => boolean;
  type: 'media' | 'documents';
} & Partial<PixiDropdownProps>) {
  const Libraries = useLibraries();
  const context = Libraries[type] as any;
  const [query, setQuery] = useState('');
  const [isOpen, setIsOpen] = useState(!!onlyDropdown);
  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
  });
  const [searchQuery, setSearchQuery] = useState('');

  const collections = (
    context.data.collections
      .filter((c: Pickit.CollectionInterface) => {
        if (customFilter) {
          return customFilter(c);
        }
        return true;
      })
      .filter((b: Pickit.CollectionInterface) => {
        return !query
          ? true
          : b.name.toLowerCase().includes(query.toLowerCase());
      }) as Pickit.CollectionInterface[]
  ).sort((a, b) => {
    return a.name.localeCompare(b.name);
  });

  const items = (
    <>
      <PixiDropdown.Label>Select collection</PixiDropdown.Label>
      <Box p="xs">
        <TextInput
          placeholder="Search collections"
          value={query}
          onChange={(event) => {
            setQuery(event.currentTarget.value);
          }}
        />
      </Box>
      {collections.map((f) => (
        <PixiDropdown.Item
          key={f._id}
          onClick={async () => {
            await onCollectionClick(f);
          }}
          disabled={value?.includes(f._id)}
          color={value?.includes(f._id) ? 'gray' : undefined}
          rightSection={
            value?.includes(f._id) ? <PixiIcon name="check" /> : undefined
          }
          leftSection={
            <ThemeIcon
              variant="light"
              color="gray"
              style={{ overflow: 'hidden' }}
            >
              <Image
                h="100%"
                w="100%"
                src={context.getCollectionThumbnailUrl(f)}
                fit="cover"
              />
            </ThemeIcon>
          }
        >
          {f.name}
        </PixiDropdown.Item>
      ))}
    </>
  );
  if (onlyDropdown) {
    return items;
  }
  const selectedCollection = context?.data?.collections?.find(
    (b: Pickit.CollectionInterface) => b._id === value,
  );
  if (asInput) {
    return (
      <Combobox
        store={combobox}
        onOptionSubmit={(val) => {
          onCollectionClick(
            collections.find(
              (b) => b._id === val,
            ) as Pickit.CollectionInterface,
          );
          combobox.closeDropdown();
        }}
        onClose={() => {
          combobox.closeDropdown();
        }}
      >
        <Combobox.Target>
          <InputBase
            label="Select collection"
            component="button"
            type="button"
            pointer
            rightSection={<Combobox.Chevron />}
            rightSectionPointerEvents="none"
            onClick={() => combobox.toggleDropdown()}
          >
            {selectedCollection?._id ? (
              <Group wrap="nowrap" gap="xs">
                <ThemeIcon
                  variant="light"
                  color="gray"
                  style={{ overflow: 'hidden' }}
                >
                  <Image
                    h="100%"
                    w="100%"
                    src={context.getCollectionThumbnailUrl(selectedCollection)}
                    fit="cover"
                  />
                </ThemeIcon>
                <PixiText
                  size="sm"
                  style={{
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                  }}
                >
                  {selectedCollection.name}
                </PixiText>
              </Group>
            ) : (
              <Input.Placeholder>Select collection</Input.Placeholder>
            )}
          </InputBase>
        </Combobox.Target>

        <FocusTrap active={combobox.dropdownOpened}>
          <Combobox.Dropdown>
            <TextInput
              placeholder="Search"
              value={searchQuery}
              onChange={(event) => setSearchQuery(event?.currentTarget.value)}
              autoFocus
            />
            <Combobox.Options>
              <ScrollArea.Autosize type="scroll" mah={200}>
                {collections
                  .filter((b) => {
                    if (searchQuery) {
                      return b.name
                        .toLowerCase()
                        .includes(searchQuery.toLowerCase());
                    }
                    return true;
                  })
                  .map((b) => (
                    <Combobox.Option value={b?._id} key={b._id} tabIndex={1}>
                      <Group wrap="nowrap" gap="xs">
                        <ThemeIcon
                          variant="light"
                          color="gray"
                          style={{ overflow: 'hidden' }}
                        >
                          <Image
                            h="100%"
                            w="100%"
                            src={context.getCollectionThumbnailUrl(b)}
                            fit="cover"
                          />
                        </ThemeIcon>
                        <PixiText
                          size="sm"
                          style={{
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                          }}
                        >
                          {b.name}
                        </PixiText>
                      </Group>
                    </Combobox.Option>
                  ))}
              </ScrollArea.Autosize>
            </Combobox.Options>
          </Combobox.Dropdown>
        </FocusTrap>
      </Combobox>
    );
  }
  return (
    <PixiDropdown
      width={300}
      height={350}
      onOpen={() => {
        setIsOpen(true);
      }}
      onClose={() => {
        setIsOpen(false);
      }}
      closeOnClickOutside
      opened={isOpen}
      target={target as ReactElement}
      {...rest}
    >
      {items}
    </PixiDropdown>
  );
}
