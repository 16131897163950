import React, { cloneElement, useEffect, useRef, useState } from 'react';
import './style.scss';
import { Button, Flex, Popover, Popup } from 'elements_v2';
import { createPortal } from 'react-dom';

function Confirm(props) {
  const [isLoading, setIsLoading] = React.useState(false);
  const [_isOpen, setIsOpen] = useState(props.isOpen || false);
  const className = ['Pixi__Confirm'];
  const confirmRef = useRef();
  if (props.noShrink) {
    className.push('Pixi__Confirm--noShrink');
  }
  if (props.small) {
    className.push('Pixi__Confirm--small');
  }

  const isOpen = props.forceClosed === true ? false : _isOpen;

  useEffect(() => {
    if (props.isOpen) {
      setIsOpen(true);
    }
  }, [props.isOpen]);

  useEffect(() => {
    if (isOpen && props.onOpen) {
      props.onOpen();
    }
  }, [isOpen]);

  async function onSubmit(event) {
    if (isLoading) {
      return false;
    }
    event.preventDefault();
    event.stopPropagation();
    setIsLoading(true);
    await props.onConfirm();
    setIsLoading(false);
    setIsOpen(false);
  }
  if (props.useV2) {
    return (
      <>
        {(isOpen || props.isForceOpen) &&
          createPortal(
            <div
              style={{
                width: '100vw',
                height: '100vh',
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                background: 'rgba(0, 0, 0, 0.2)',
                zIndex: (props.zIndex || 999) - 1,
              }}
              onClick={() => props.onClose?.()}
            />,
            document.body
          )}
        {props.forceClosed || props.disabled ? (
          props.children
        ) : (
          <Popover
            useV2
            overlay={!props.noOverlay}
            isOpen={props.isForceOpen || isOpen}
            onClose={() => {
              props.onClose?.();
              setIsOpen(false);
            }}
            width={props.width || 300}
            zIndex={props.zIndex || 999 + 1}
            title={props.title}
            trigger={cloneElement(props.children, {
              onClick: (event) => {
                event.preventDefault();
                event.stopPropagation();
                setIsOpen(true);
              },
            })}
            noTriggerWrapper
            formWrap
            onOpen={() => {
              props.onOpen?.();
              setIsOpen(true);
            }}
            onSubmit={onSubmit}
            actions={
              <Flex style={{ width: '100%' }} justifyContent="space-between">
                <Button
                  onClick={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    setIsOpen(false);
                    if (props.onCancel) {
                      props.onCancel();
                    }
                  }}
                  type="button"
                  inline
                  style={{ width: '100%', maxWidth: 100, whiteSpace: 'nowrap' }}
                >
                  {props.cancelLabel || 'Cancel'}
                </Button>
                <Button
                  primary
                  loading={isLoading || props.confirmLoading}
                  disabled={
                    isLoading || props.confirmLoading || props.confirmDisabled
                  }
                  onClick={onSubmit}
                  ref={confirmRef}
                  style={{ flexShrink: 0, minWidth: 150 }}
                  inline
                  {...(props.confirmProps || {})}
                >
                  {props.confirm || 'Yes'}
                </Button>
              </Flex>
            }
          >
            {isOpen && (
              <>
                {typeof props.description === 'function'
                  ? props.description(isOpen, setIsOpen)
                  : props.description}
              </>
            )}
          </Popover>
        )}
      </>
    );
  }

  return (
    <>
      <Popup
        size="small"
        trigger={props.children}
        className="Pixi__Confirm"
        actions={(isOpen, setIsOpen) => (
          <Flex style={{ width: '100%' }}>
            <Button
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
                setIsOpen(false);
                if (props.onClose) {
                  props.onClose();
                }
              }}
            >
              {props.cancelLabel || 'Cancel'}
            </Button>
            <Button
              primary
              loading={isLoading || props.confirmLoading}
              disabled={props.confirmDisabled}
              onClick={async (event) => {
                event.preventDefault();
                event.stopPropagation();
                setIsLoading(true);
                await props.onConfirm();
                setIsLoading(false);
                setIsOpen(false);
              }}
            >
              {props.confirm || 'Yes'}
            </Button>
          </Flex>
        )}
        onClose={props.onClose}
      >
        <div className="Pixi Pixi__Confirm__content">
          <div className="Pixi Pixi__Confirm__content__text">
            <h1>{props.title}</h1>
            <p>{props.text}</p>
            <div>{props.content}</div>
          </div>
        </div>
      </Popup>
    </>
  );
}

export default Confirm;
