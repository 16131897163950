import * as yup from 'yup';
import React from 'react';
import Icon from './icon.svg?react';

export default {
  moduleId: 'COLLECTIONS',
  menuGroupIds: ['COLLECTIONS'],
  moduleInterface: {
    collections: 'COLLECTIONS',
  },
  status: {
    isEmpty: (data) => {
      return !data?.collections?.length;
    },
  },
  moduleMetadataInterface: {
    thumbnailSizes: yup.mixed().oneOf(['small', 'medium', 'huge']),
    backgroundColor: yup.string(),
    isGallery: yup.boolean(),
  },
  menuContent: { key: 'COLLECTIONS', icon: <Icon />, name: 'Collections' },
};
