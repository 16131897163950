import React, { useRef } from 'react';

export default function UploadButton(props) {
  const { children, style, ...rest } = props;
  const uploadRef = useRef();
  return (
    <>
      <input
        type="file"
        value={[]}
        ref={uploadRef}
        {...rest}
        style={{ ...style, display: 'none' }}
      />
      {React.cloneElement(children, {
        onClick: () => {
          if (uploadRef?.current) {
            uploadRef.current.click();
          }
        },
      })}
    </>
  );
}
