import useCloudStorage from 'hooks/useCloudStorage';
import { useUserContext } from 'hooks/useUserContext';
import { useEffect, useReducer, useState } from 'react';
import Dialog from 'utils/Dialog';
import localStorageWrapper from 'utils/localStorageWrapper';

export default function useDropboxAuth() {
  const User = useUserContext();
  const [token, setToken] = useReducer((state, action) => ({ ...action }), {});
  const [isRefreshingToken, setIsRefreshingToken] = useState(false);
  const [isConnected, setIsConnected] = useState(false);
  const [isConnecting, setIsConnecting] = useState(false);
  const cloudStorage = useCloudStorage();
  const [account, setAccount] = useState(null);

  useEffect(() => {
    const token = localStorageWrapper.getItem('userDropboxToken');
    if (token) {
      setToken(JSON.parse(token));
    }
  }, []);

  useEffect(() => {
    if (Object.keys(token)?.length) {
      localStorageWrapper.setItem('userDropboxToken', JSON.stringify(token));
      getAccount();
    }
  }, [token]);

  useEffect(() => {
    if (token.access_token && account) {
      setIsConnected(true);
      setIsConnecting(false);
    }
  }, [token, account]);

  async function getAccount() {
    try {
      const data = await request(
        '/2/users/get_current_account',
        {
          method: 'POST',
        },
        '',
        { ignoreConnected: true },
      );
      setAccount(data);
      return data;
    } catch (e) {}
  }

  function connectToken(token, scope, redirectUri) {
    getToken(token, scope, redirectUri);
  }

  async function request(path, options = {}, scope, prefs = {}) {
    if (!token) {
      return false;
    }
    if (!isConnected && !prefs.ignoreConnected) {
      return false;
    }
    const headers = {
      Authorization: `Bearer ${token?.access_token}`,
      ...(options?.headers || {}),
    };
    if (typeof options?.body === 'object') {
      options.body = JSON.stringify(options.body);
      headers['Content-Type'] = 'application/json';
    }
    const hasTeamSpaces =
      account?.root_info?.['.tag'] === 'team' ||
      account?.root_info?.root_namespace_id !==
        account?.root_info?.home_namespace_id;
    if (hasTeamSpaces) {
      headers['Dropbox-API-Path-Root'] =
        `{".tag": "root", "root": "${account.root_info.root_namespace_id}"}`;
    }
    try {
      const req = await fetch(`https://api.dropboxapi.com${path}`, {
        ...options,
        headers,
      });
      if (req.status === 401) {
        const token = await refreshToken(scope);
        if (!token) {
          return disconnect(true);
        }
        return request(
          path,
          {
            ...options,
            headers: {
              ...headers,
              Authorization: `Bearer ${token?.access_token}`,
            },
          },
          scope,
        );
      }
      if (!req.ok) {
        throw req;
      }
      try {
        const data = await req.json();
        return data;
      } catch (e) {
        throw req;
      }
    } catch (e) {
      if (e.status === 401 || e.status === 403) {
        return disconnect(true);
      }
    }
  }

  async function refreshToken(scope) {
    if (isRefreshingToken) {
      return false;
    }
    if (!isConnected) {
      return false;
    }
    setIsRefreshingToken(true);
    try {
      const data = await User.request.files.dropbox.refreshToken(
        token?.refresh_token,
        [scope],
        `${window.location.origin}/genericoauth.html`,
      );
      if (!data?.access_token) {
        disconnect(true);
        return false;
      }
      setToken(data);
      setIsRefreshingToken(false);
      return data;
    } catch (e) {
      disconnect(true);
      return false;
    }
  }

  async function getToken(code, redirectUri) {
    const data = await User.request.files.dropbox.getToken(
      code,
      redirectUri || `${window.location.origin}/genericoauth.html`,
    );
    if (!data?.access_token) {
      return disconnect(true);
    }
    setToken(data);
    setIsConnecting(false);
  }

  function connect() {
    const dialog = new Dialog(
      `https://www.dropbox.com/oauth2/authorize?client_id=${window.pickit.keys.DROPBOX_PUBLIC_KEY}&token_access_type=offline&response_type=code&redirect_uri=${window.location.origin}/genericoauth.html`,
      1000,
      700,
    );
    let isConnectImmediate = false;
    return new Promise((resolve, reject) => {
      dialog.open(async (authToken) => {
        if (
          authToken &&
          typeof authToken === 'string' &&
          !isConnecting &&
          !isConnectImmediate
        ) {
          setIsConnecting(true);
          getToken(authToken);
          isConnectImmediate = true;
        } else {
          reject(false);
          return false;
        }
      });
    });
  }

  function disconnect(automatic) {
    setIsConnected(false);
    localStorageWrapper.removeItem('userDropboxToken');
    if (!automatic) {
      cloudStorage.set('notifications.dropboxDisconnectWarning', true);
    } else {
      cloudStorage.set('notifications.dropboxDisconnectWarning', false);
    }
  }

  return {
    token,
    isConnected,
    isScopeConnected: (scope) => token?.scope?.includes(scope),
    isConnecting,
    connect,
    request,
    disconnect,
    connectToken,
    setAccount,
  };
}
