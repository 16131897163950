import { Tooltip, TooltipProps } from '@mantine/core';
import {
  cloneElement,
  ForwardedRef,
  forwardRef,
  ReactElement,
  useEffect,
  useState,
} from 'react';

export interface PixiTooltipProps extends Omit<TooltipProps, 'children'> {
  isForceOpen?: boolean;
  children:
    | ((
        isOpen: boolean,
        setIsOpen: (isOpen: boolean) => void,
        setIsForceOpen: (isOpen: boolean) => void,
      ) => ReactElement)
    | ReactElement;
}

const PixiTooltip = forwardRef(
  (props: PixiTooltipProps, ref: ForwardedRef<HTMLDivElement>) => {
    const { children, ...rest } = props;
    const [isOpen, setIsOpen] = useState(rest.opened ?? false);
    const [isForceOpen, setIsForceOpen] = useState(props.isForceOpen || false);
    useEffect(() => {
      setIsForceOpen(props.isForceOpen || false);
    }, [props.isForceOpen]);
    return (
      <Tooltip {...rest} opened={isForceOpen || isOpen} ref={ref}>
        {typeof children === 'function'
          ? cloneElement(
              children(isForceOpen || isOpen, setIsOpen, setIsForceOpen),
              {
                onMouseEnter: () => setIsOpen(true),
                onMouseLeave: () => setIsOpen(false),
              },
            )
          : cloneElement(children, {
              onMouseEnter: () => setIsOpen(true),
              onMouseLeave: () => setIsOpen(false),
            })}
      </Tooltip>
    );
  },
);

export default PixiTooltip;
