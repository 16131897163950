import { useEffect } from 'react';
import useIntegrationConfig from '../useIntegrationConfig';
import useJobs from '../useJobs';
import useSharepointAPI from './useSharepointAPI';

export default function useSharepoint(props) {
  const config = useIntegrationConfig('microsoft');
  const { API_ID, enableCapabilities, setCopy } = config;
  useEffect(() => {
    enableCapabilities([
      'jobs',
      'nested',
      // 'external',
      // 'seamless',
      'recent',
      'search',
      'hasDrives',
      'approval',
    ]);

    const generatedCopy = {};

    ['SharePoint', 'Teams', 'OneDrive'].forEach((key) => {
      generatedCopy[`external${key}`] = (
        <>
          Import the file's metadata, but keep the file stored externally.
          <br />
          <strong>Note:</strong> The file will be downloaded directly from the
          source and the user will need access to the file in {key} to download
          it.
        </>
      );
      generatedCopy[`seamless${key}`] = (
        <>
          External files can be downloaded without the {key} UI.
          <br />
          <strong>Note:</strong> This feature will create a open download URL
          for your imported assets. This URL can be accessed by anonymous users
          or users within you organization based on the{' '}
          <strong>Seamless access</strong> setting.
        </>
      );
      generatedCopy[`seamlessType${key}`] = (
        <>
          <strong>Any:</strong> Any user can access the file.
          <br />
          <strong>Shared with:</strong> The user needs to be logged into a
          Microsoft account that has access to the file in {key} but will bypass
          the {key} UI when accessing the file.
        </>
      );
    });

    setCopy(generatedCopy);
  }, []);

  const SharepointAPI = useSharepointAPI({ API_ID, Graph: props.Graph });
  const Jobs = useJobs({ API_ID, API: SharepointAPI });
  return {
    ...SharepointAPI,
    ...Jobs,
    ...config,
  };
}
