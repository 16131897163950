import { AppUserInterface } from './AppUser';
import { getConfigStore, useConfigStoreCallback } from './store';

export function useAppUser() {
  const user = useConfigStoreCallback('APP_USER', 'user', (val) => val, []);
  const community = useConfigStoreCallback(
    'APP_USER',
    'community',
    (val) => val,
    [],
  );
  return {
    community,
    user,
  };
}

export function setCommunityData(data: AppUserInterface['community']['data']) {
  getConfigStore('APP_USER').setValue('community', {
    data,
  });
}

export function setUser(user: AppUserInterface['user']) {
  getConfigStore('APP_USER').setValue('user', user);
}

export function setCommunityPrefs(
  prefs: AppUserInterface['community']['prefs'],
) {
  getConfigStore('APP_USER').setValue(
    'community',
    {
      prefs,
    },
    {
      merge: true,
    },
  );
}
