import { useEventListener } from 'hooks';
import React, { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import './style.scss';

export function PinkToolbar(props) {
  const [isActive, setIsActive] = useState(false);
  const [parentRef, setParentRef] = useState();
  const className = ['Pixi__PinkToolbar Pixi brand__color__bg'];

  useEventListener(
    'mouseover',
    () => {
      setIsActive(true);
    },
    parentRef?.current,
  );
  useEventListener(
    'mouseleave',
    () => {
      setIsActive(false);
    },
    parentRef?.current,
  );

  useEffect(() => {
    setParentRef(props.parentRef);
  }, [props.parentRef]);

  if (isActive) {
    className.push('Pixi__PinkToolbar--active');
  }

  if (props.noPadding) {
    className.push('Pixi__PinkToolbar--noPadding');
  }

  if (props.position) {
    className.push(`Pixi__PinkToolbar--${props.position}`);
  }

  if (props.placement) {
    className.push(`Pixi__PinkToolbar--placement-${props.placement}`);
  }

  if (props.size) {
    className.push(`Pixi__PinkToolbar--${props.size}`);
  }
  if (props.overlay) {
    className.push(`Pixi__PinkToolbar--overlay`);
  }
  if (props.floating) {
    className.push(`Pixi__PinkToolbar--floating`);
  }
  if (props.brand) {
    className.push(`brand__color__bg`);
  }

  if (props.disabled) {
    return <></>;
  }

  if (props.usePortal) {
    function getCoords(elem) {
      // crossbrowser version
      const box = elem.getBoundingClientRect();

      const { body } = document;
      const docEl = document.documentElement;

      const scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
      const scrollLeft =
        window.pageXOffset || docEl.scrollLeft || body.scrollLeft;

      const clientTop = docEl.clientTop || body.clientTop || 0;
      const clientLeft = docEl.clientLeft || body.clientLeft || 0;

      const top = box.top + scrollTop - clientTop;
      const left = box.left + scrollLeft - clientLeft;

      return {
        top: Math.round(top),
        left: Math.round(left),
        width: box.width,
        height: box.height,
      };
    }
    const rects = getCoords(parentRef?.current);

    return createPortal(
      <div
        className="Pixi__PinkToolbar-Portal"
        style={{
          top: rects.top,
          left: rects.left,
          width: rects.width,
          height: rects.height,
        }}
      >
        <div className={className.join(' ')} style={props.style}>
          {props.children}
        </div>
      </div>,
      document.body,
    );
  }

  return (
    <div className={className.join(' ')} style={props.style}>
      {props.children}
    </div>
  );
}
