import { Storage } from 'services';
import ApiService from './_ApiService';

const CLIENT_ID = window.pickit.keys.AZURE_CLIENT_ID;

class Community extends ApiService {
  communitySlug = '';

  useStorage = true;

  constructor(props) {
    super(props);
    if (this.useStorage && window.pickit && window.pickit.community) {
      this.setSlug(window.pickit.community);
    }

    if (this.useStorage && Storage.getItem('communitySlug')) {
      this.setSlug(Storage.getItem('communitySlug'));
    }
  }

  request(path, props) {
    const args = [].slice.call(arguments);
    if (typeof props === 'undefined') {
      args.push({});
    }
    args.push(this.communitySlug || null);

    return super.request(...args);
  }

  async updateProfile(data) {
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });
    return this.request('/settings/profile/', {
      method: 'PUT',
      body: formData,
    }).then(this.handleJSONResponse);
  }

  async updateEmail(data) {
    return this.request('/users/update_email', {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    }).then(this.handleJSONResponse);
  }

  async setHost(host) {
    return this.request(`/community/${this.communitySlug}/`, {
      method: 'PUT',
      headers: {
        'content-type': 'application/json',
      },
      body: JSON.stringify({
        host,
      }),
    }).then(this.handleJSONResponse);
  }

  async msAuth(token, isAccessToken) {
    let access_token = 'false';
    if (isAccessToken) {
      access_token = 'true';
    }
    try {
      const request = await this.request(
        `/users/connect-ms-p4b?is_access_token=${access_token}&keep_me_logged_in=true&client_id=${CLIENT_ID}`,
        {
          headers: {
            'Pickit-Front-Token': `Bearer ${token}`,
          },
        }
      ).then(this.handleJSONResponse);
      return request;
    } catch (e) {
      throw e;
    }
  }

  getContributionDownloadLinks(slug, collectionSlug) {
    let collection_param;
    if (collectionSlug) {
      collection_param = `&collection_slug=${collectionSlug}`;
    }
    return this.request(
      `/contributions/${slug}/download_links/?with_token=1&show_initial=1${collection_param}`
    ).then(this.handleJSONResponse);
  }

  getContributionInsertLinks(slug, collectionSlug) {
    let collection_param;
    if (collectionSlug) {
      collection_param = `&collection_slug=${collectionSlug}`;
    }
    return this.request(
      `/contributions/${slug}/download_links/?response=base64&with_token=1&show_initial=1${collection_param}`
    ).then(this.handleJSONResponse);
  }

  checkConfigured() {}

  get getCollections() {
    return {
      hero: this.CollectionsRetriever.bind(
        this,
        `/collections/explore/hero/source/ibc/`
      ),
      featured: this.CollectionsRetriever.bind(
        this,
        `/collections/explore/featured/source/ibc/`
      ),
      search: this.CollectionsRetriever.bind(this, '/search/collections'),
    };
  }

  async getUserCollections(userId, page_nr) {
    return this.request(
      `/user/${userId}/collections/?page_limit=50&page_nr=${page_nr}`,
      {
        method: 'GET',
      }
    ).then(this.handleJSONResponse);
  }

  async createCollection(title) {
    return this.request('/collection/create/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      withoutApi: true,
      body: {
        title,
        slug: 'new-collection',
      },
    }).then(this.handleJSONResponse);
  }

  async CollectionsRetriever(endpoint, options, getAll) {
    try {
      options.status = 'published';
      const request = await this.request(endpoint + this.stringify(options));
      const json = await request.json();
      if (getAll && json.data.length >= options.page_limit) {
        options.page_nr += 1;
        json.data = [
          ...json.data,
          ...(await this.CollectionsRetriever(endpoint, options, true)),
        ];
      }
      return json.data || [];
    } catch (e) {
      throw new Error(e);
    }
  }

  getCollectionGroups() {
    return this.request('/collection_groups/').then(this.handleJSONResponse);
  }

  getCollectionGroup(slug) {
    return this.request(`/collection_groups/${slug}/`).then(
      this.handleJSONResponse
    );
  }

  getHeroCollections(params) {
    return this.request(
      `/collections/explore/hero/source/ibc/${this.stringify(params)}`
    ).then(this.handleJSONResponse);
  }

  getFeaturedCollections(params) {
    return this.request(
      `/collections/explore/featured/source/ibc/${this.stringify(params)}`
    ).then(this.handleJSONResponse);
  }

  getSearchContributions(params) {
    if (params?.filters) {
      params.filters = this.configureFilter(params.filters);
    }
    return this.request(`/search/contributions/${this.stringify(params)}`).then(
      this.handleJSONResponse
    );
  }

  getCollection(userId, slug, params) {
    return this.request(
      `/users/${userId}/collection/${slug}/${this.stringify(params)}`
    ).then(this.handleJSONResponse);
  }

  getCommunity(slug) {
    return this.request(`/community/${slug}/`).then(this.handleJSONResponse);
  }

  getSearchAutocomplete(params) {
    return this.request(`/search/autocomplete/${this.stringify(params)}`).then(
      this.handleJSONResponse
    );
  }

  getSearchCollections(params) {
    return this.request(`/search/collections${this.stringify(params)}`).then(
      this.handleJSONResponse
    );
  }

  getContribution(slug) {
    return this.request(`/contributions/${slug}/`).then(
      this.handleJSONResponse
    );
  }

  getContributionBase64(slug) {
    return this.request(`/contributions/${slug}/download/base64/`).then(
      this.handleJSONResponse
    );
  }

  async getUserInfo(userId) {
    const request = await this.request(`/users/${userId}/`).then(
      this.handleJSONResponse
    );
    return request;
  }

  getPartner(partnerId) {
    return this.request(`/csp/customers?plain_view=1&customer=${partnerId}`);
  }

  getContributionBase64FromUrl(url, width) {
    return this.request(url + (width ? `?width=${width}` : ''), null, null, {
      skipBaseUrl: true,
    }).then(this.handleTextResponse);
  }
}

export default Community;
