import * as yup from 'yup';
import React from 'react';
import EntrypointMenu from '../_icons/ENTRYPOINT_MENU.svg?react';

export default [
  {
    moduleId: 'BOARD_MENU',
    menuGroupIds: ['BOARDS'],
    moduleInterface: {
      boards: 'BOARDS',
      logo: 'FILE',
    },
    preferences: {
      hideLabel: true,
    },
    status: {
      isEmpty: (data) => {
        return !data?.boards?.length && !data?.logo?.fileId;
      },
    },
    moduleMetadataInterface: {
      textColor: yup.string(),
      borderColor: yup.string(),
      backgroundColor: yup.string(),
    },
    menuContent: {
      key: 'BOARD_MENU',
      icon: <EntrypointMenu />,
      name: 'Board menu',
    },
  },
  {
    moduleId: 'ENTRYPOINT_MENU',
    menuGroupIds: ['BOARDS'],
    moduleInterface: {
      boards: 'BOARDS',
      logo: 'FILE',
    },
    preferences: {
      hideLabel: true,
    },
    status: {
      isEmpty: (data) => {
        return !data?.boards?.length && !data?.logo?.fileId;
      },
    },
    moduleMetadataInterface: {
      textColor: yup.string(),
      borderColor: yup.string(),
      backgroundColor: yup.string(),
      logoSize: yup.string(),
    },
    menuContent: {
      key: 'ENTRYPOINT_MENU',
      icon: <EntrypointMenu />,
      name: 'Entrypoint Menu',
    },
  },
];
