import { useDocumentsContext, useMediaContext } from 'hooks';

export default function useIntegrationHelpers() {
  const libraries = {
    documents: useDocumentsContext(),
    media: useMediaContext(),
    brand: useMediaContext(),
  };

  // Fix this later
  function checkIfSupportedByAllLibraries(ext) {
    return Object.values(libraries).reduce((isOk, context) => {
      if (!isOk && context.isFileSupported(null, ext)) {
        isOk = true;
      }
      return isOk;
    }, false);
  }

  const createFileObject = (
    id = null,
    name = '',
    checksum = null,
    etag = null,
    path = null,
    thumb = '',
    identifier,
    externalMetadata = {},
    description = '',
    lastUpdated,
    parentId,
  ) => {
    return {
      media: {
        supported:
          etag === 'file'
            ? checkIfSupportedByAllLibraries(
                name.split('.').pop().toLowerCase(),
              )
            : true,
      },
      external: {
        id,
        name,
        identifier: identifier?.toString() || id?.toString(),
        checksum,
        description,
        metadata: externalMetadata,
        path,
        etag,
        thumb,
        ext: etag === 'file' ? name.split('.').pop().toLowerCase() : null,
        lastUpdated,
        parentId,
      },
    };
  };

  return {
    createFileObject,
  };
}
