import { PixiIconName } from '@pixi/elements/Icon';

export const BrandCategories = [
  'color',
  'text',
  'font',
  'icon',
  'logotype',
  'image',
  'package',
];

export function CategoryToIcon(category: string): PixiIconName | undefined {
  if (category === 'logotype') {
    return 'award';
  }
  if (category === 'color') {
    return 'palette';
  }
  if (category === 'text') {
    return 'file-lines';
  }
  if (category === 'icon') {
    return 'icons';
  }
  if (category === 'font') {
    return 'font';
  }
  if (category === 'image') {
    return 'image';
  }
  if (category === 'package') {
    return 'file-zip';
  }
  return undefined;
}

export function CategoryToAction(category: string): string {
  if (category === 'logotype') {
    return 'Upload logotype';
  }
  if (category === 'color') {
    return 'Add color';
  }
  if (category === 'text') {
    return 'Add text';
  }
  if (category === 'icon') {
    return 'Upload icons';
  }
  if (category === 'font') {
    return 'Add font family';
  }
  if (category === 'image') {
    return 'Upload media';
  }
  if (category === 'package') {
    return 'Upload zip';
  }
  return '';
}

export function CategoryToLabel(category: string): string {
  if (category === 'logotype') {
    return 'Logotype';
  }
  if (category === 'color') {
    return 'Color';
  }
  if (category === 'icon') {
    return 'Icon';
  }
  if (category === 'text') {
    return 'Text';
  }
  if (category === 'font') {
    return 'Font Family';
  }
  if (category === 'image') {
    return 'Media';
  }
  if (category === 'package') {
    return 'ZIP';
  }
  return 'Palette';
}
