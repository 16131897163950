import { Icon } from 'elements_v2';
import React, { forwardRef } from 'react';
import './style.scss';

const Section = forwardRef((props, ref) => {
  const className = ['ManageView__Section'];

  if (props.className) {
    className.push(props.className);
  }

  if (props.hidden) {
    className.push('ManageView__Section--hidden');

    if (props.hiddenNotch) {
      className.push('ManageView__Section--hidden-with-notch');
    }
  }
  if (props.sticky) {
    className.push('ManageView__Section--sticky');
  }
  if (props.auto) {
    className.push('ManageView__Section--auto');
  }

  const computedStyle = {};
  if (props.hidden && props.hiddenNotch) {
    computedStyle.maxWidth = props.hiddenNotch;
  }

  return (
    <div
      ref={ref}
      className={className.join(' ')}
      style={{ ...props.style, ...computedStyle }}
    >
      {props.onToggle && !props.noNotch && (
        <div
          className="ManageView__Section__toggle Pixi--clickable"
          onClick={props.onToggle}
        >
          <Icon name="CaretRightFill" />
        </div>
      )}
      {props.children}
    </div>
  );
});

function Topbar(props) {
  let className = 'ManageView__Topbar';
  if (props.singleline) {
    className += ' ManageView__Topbar--compact';
  }
  return (
    <div className={className} style={props.style}>
      {props.menu && (
        <div className="ManageView__Topbar__top">
          <Tabs items={props.menu} />
          {props.menuRight && (
            <div className="ManageView__Topbar__top__right">
              {props.menuRight}
            </div>
          )}
        </div>
      )}
      {!props.singleline && (
        <div className="ManageView__Topbar__bottom">{props.children}</div>
      )}
    </div>
  );
}

function Tabs(props) {
  return <div className="ManageView__Tabs">{props.items}</div>;
}

const TabItem = React.forwardRef((props, ref) => {
  const className = ['ManageView__TabItem'];
  if (props.active) {
    className.push('ManageView__TabItem--active');
  }
  if (props.disabled) {
    className.push('ManageView__TabItem--disabled');
  }
  if (props.href) {
    return (
      <a
        ref={ref}
        className={className.join(' ')}
        href={props.href}
        target={props.target}
        style={props.style}
      >
        {props.children}
      </a>
    );
  }
  return (
    <div
      className={className.join(' ')}
      ref={ref}
      style={props.style}
      onClick={props.onClick}
    >
      {props.children}
    </div>
  );
});

function Content(props) {
  /* React.useEffect(() => {
    return () => {
    }
  }, []); */

  return (
    <div
      className={`ManageView__Content ${props.className || ''}`}
      style={props.style}
    >
      {props.children}
    </div>
  );
}

function ContentToolbar(props) {
  return (
    <div className="ManageView__Content__Toolbar" style={props.style}>
      <div className="ManageView__Content__Toolbar__content">
        {props.content}
        <div
          className="ManageView__Content__Toolbar__content__section"
          style={props.leftStyle}
        >
          {props.left}
        </div>
        <div
          className="ManageView__Content__Toolbar__content__section"
          style={props.rightStyle}
        >
          {props.right}
        </div>
      </div>
    </div>
  );
}

function ContentView(props) {
  return (
    <div
      className="ManageView__Content__View"
      onClick={props.onClick}
      style={props.style}
    >
      {props.children}
    </div>
  );
}

const ManageView = forwardRef((props, ref) => {
  /* React.useEffect(() => {
    return () => {
    }
  }, []); */

  return (
    <div className="ManageView" ref={ref} style={props.style}>
      {props.children}
    </div>
  );
});

ManageView.Section = Section;
ManageView.Content = Content;
ManageView.Content.Toolbar = ContentToolbar;
ManageView.Content.View = ContentView;
ManageView.Topbar = Topbar;
ManageView.Tabs = Tabs;
ManageView.TabItem = TabItem;

export default ManageView;
