import React from 'react';
import { useDocumentsContext } from './useDocumentsContext';
import { useMediaContext } from './useMediaContext';

export default function useLibraries() {
  return {
    media: useMediaContext(),
    brandassets: useMediaContext(),
    documents: useDocumentsContext(),
  };
}
