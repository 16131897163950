import { useDocumentsContext } from 'hooks/useDocumentsContext';
import { useMediaContext } from 'hooks/useMediaContext';
import { googleMimetoExt } from 'utils';

const defaultFileFields = [
  'id',
  'name',
  'parents',
  'mimeType',
  'md5Checksum',
  'modifiedTime',
  'createdTime',
  'owners',
  'webViewLink',
  'webContentLink',
  'fileExtension',
  'fullFileExtension',
  'thumbnailLink',
  'size',
  'lastModifyingUser/displayName',
  'driveId',
];

export default function useGoogleDriveAPI(props) {
  const { Google } = props;
  const Media = useMediaContext();
  const Documents = useDocumentsContext();

  function getFoldersFromItems(items) {
    return items.filter(
      (item) =>
        item.mimeType === 'application/vnd.google-apps.folder' || item.folder
    );
  }

  /**
   * Get all files from list of drive items.
   * @param {array} items List of items fetch from folderItems functions
   */
  function getFilesFromItems(items) {
    return items.filter(
      (item) =>
        item.mimeType !== 'application/vnd.google-apps.folder' && !item.folder
    );
  }

  /**
   * Get all files from a specific folder
   * @param {string} driveId Leave undefined for users own drive or provide an ID for a drive shared with the user.
   * @param {string} itemId ID of a folder or alias "root" for the root folder
   */
  async function getAllFolderItemsNested(itemId, driveId, signal, onProgress, callback, path = '') {
    const result = await getAllFolderItems(
      itemId,
      driveId,
      signal,
      onProgress,
      undefined,
      undefined,
      true
    );
    const results = [];
    callback?.(path);
    const promises = result.map(async (item) => {
      if (item.mimeType === 'application/vnd.google-apps.folder') {
        const folderItems = await getAllFolderItemsNested(
          item.id,
          driveId,
          signal,
          onProgress,
          callback,
          path ? `${path}/${item.name}` : `${item.name}/`
        );
        folderItems.forEach((file) => results.push(file));
      }
      results.push(item);
    });

    await Promise.all(promises);
    return results;
  }
  /**
   * Get all shared drives. Shared drives are drives created by a Google Workplace and is not owned by a specific user
   */
  async function getAllDrives() {
    let result = await getSharedDrives();

    const results = [...result.drives];
    let { nextPageToken } = result;
    while (nextPageToken) {
      result = await getSharedDrives(nextPageToken);
      result.drives.forEach((drive) => results.push(drive));
      nextPageToken = result.nextPageToken;
    }

    return results;
  }
  /**
   * Get a specific file
   * @param {string} driveId Leave undefined for users own drive or provide an ID for a shared drive.
   * @param {string} itemId ID of a file or folder
   */
  async function getDriveItem(itemId, driveId) {
    const params = { fields: `${defaultFileFields.join(',')}` };
    const driveId_ = driveId === 'personal' ? undefined : driveId;
    if (itemId === 'personal') {
      return { id: 'personal', name: 'Personal' };
    }
    if (driveId_) {
      params.corpora = 'drive';

      params.driveId = driveId_;
      params.includeItemsFromAllDrives = true;
    }
    params.supportsAllDrives = true;
    const data = await Google.request(
      `drive/v3/files/${itemId}`,
      { params },
      'auth/drive.metadata.readonly'
    );

    if (data.mimeType === 'application/vnd.google-apps.folder') {
      data.folder = true;
    }
    return data;
  }
  async function getFolder(itemId, driveId) {
    return getDriveItem(itemId, driveId);
  }
  async function getDrive(driveId) {
    const data = await Google.request(
      `drive/v3/drives/${driveId}`,
      {},
      'auth/drive.metadata.readonly'
    );

    return data;
  }
  async function getDriveItems(itemId, driveId) {
    return getAllFolderItems(itemId, driveId);
  }
  /**
   * Get Items in a folder
   * @param {string} driveId Leave undefined for users own drive or provide an ID for a shared drive.
   * @param {string} itemId ID of a folder or alias "root" for the root folder
   * @param {string} nextPageToken Token provided from a previous request signifying the next page to get
   */
  async function getAllFolderItems(
    folderId,
    driveId, 
    signal,
    onProgress,
    nextPageToken,
    previousResult = [],
    doingNested
  ) {
    let _driveId = driveId;
    let _folderId = folderId;
    if (_driveId === 'personal') _driveId = undefined;
    if (_folderId === 'personal') _folderId = undefined;
    const params = {
      fields: `nextPageToken, files(${defaultFileFields.join(',')})`,
      q: `"${_folderId || _driveId || 'root'}" in parents`,
      pageSize: 500,
    };

    if (_driveId) {
      params.corpora = 'drive';
      params.driveId = _driveId;
      params.supportsAllDrives = true;

      params.includeItemsFromAllDrives = true;
    }
    if (nextPageToken) {
      params.pageToken = nextPageToken;
    }
    const data = await Google.request(
      `drive/v3/files`,
      { params },
      'auth/drive.metadata.readonly'
    );
    const files = data.files.map((item) => {
      if (item.mimeType === 'application/vnd.google-apps.folder') {
        item.folder = true;
      }
      return item;
    });
    if (!doingNested) {
      for (let index = 0; index < files.length; index++) {
        const item = files[index];
        if (item.folder) {
          item.isEmpty = await isFolderEmpty(item.id, _driveId);
        }
      }
    }

    const concatFiles = previousResult.concat(files);

    if(onProgress) {
      onProgress({
        total: concatFiles.length,
      });
    }

    if (data.nextPageToken) {
      return await getAllFolderItems(
        folderId,
        driveId,
        signal,
        onProgress,
        data.nextPageToken,
        concatFiles
      );
    }
    return concatFiles;
  }
  /**
   * Get Items in a folder
   * @param {string} driveId Leave undefined for users own drive or provide an ID for a shared drive.
   * @param {string} itemId ID of a folder or alias "root" for the root folder
   */
  async function isFolderEmpty(folderId, driveId) {
    const driveId_ = driveId === 'personal' ? undefined : driveId;
    const params = {
      fields: `nextPageToken, files(${defaultFileFields.join(',')})`,
      q: `"${folderId || driveId || 'root'}" in parents`,
      pageSize: 1,
    };
    if (driveId_) {
      params.corpora = 'drive';
      params.driveId = driveId_;
      params.supportsAllDrives = true;

      params.includeItemsFromAllDrives = true;
    }

    const data = await Google.request(
      `drive/v3/files`,
      { params },
      'auth/drive.metadata.readonly'
    );
    const files = data.files.map((item) => {
      if (item.mimeType === 'application/vnd.google-apps.folder') {
        item.folder = true;
      }
      return item;
    });

    return !files.length;
  }
  /**
   * Get recent files
   * @param {string} nextPageToken Token provided from a previous request signifying the next page to get
   */
  async function getRecentFiles(nextPageToken) {
    const params = {
      fields: `nextPageToken,  files(${defaultFileFields.join(',')})`,
      orderBy: 'modifiedByMeTime desc',
      q: "mimeType != 'application/vnd.google-apps.folder'",
    };
    params.supportsAllDrives = true;
    params.includeItemsFromAllDrives = true;

    if (nextPageToken) {
      params.pageToken = nextPageToken;
    }

    const data = await Google.request(
      `drive/v3/files`,
      { params },
      'auth/drive.metadata.readonly'
    );
    data.files = data.files.map((item) => {
      if (item.mimeType === 'application/vnd.google-apps.folder') {
        item.folder = true;
      }
      return item;
    });
    return data.files;
  }

  /**
   * Get a shared drive. Shared drives are drives created by a Google Workplace and is not owned by a specific user
   * @param {string} nextPageToken Token provided from a previous request signifying the next page to get
   */
  async function getSharedDrives(nextPageToken) {
    const params = {};
    if (nextPageToken) {
      params.pageToken = nextPageToken;
    }

    const data = await Google.request(
      `drive/v3/drives`,
      { params },
      'auth/drive.metadata.readonly'
    );

    return data;
  }

  /**
   * Get Items in a folder
   * @param {string} query Query to search for.
   * @param {bool} includeFromAllDrives Whether search should apply to all drives the user can access
   * @param {string} driveId Leave undefined for users own drive or provide an ID for a shared drive.
   * @param {string} nextPageToken Token provided from a previous request signifying the next page to get
   */
  async function search(
    query,
    includeFromAllDrives = true,
    params_,
    nextPageToken
  ) {
    const { driveId } = params_ || {};
    const driveId_ = driveId === 'personal' ? undefined : driveId;
    const params = {
      fields: `nextPageToken, files(${defaultFileFields.join(',')})`,
      q: `name contains "${query}"`,
    };
    if (driveId_) {
      params.corpora = 'drive';

      params.driveId = driveId_;
    }
    if (includeFromAllDrives || driveId_) {
      params.corpora = 'allDrives';
      params.includeItemsFromAllDrives = true;
      params.supportsAllDrives = true;
    }
    if (nextPageToken) {
      params.pageToken = nextPageToken;
    }

    const data = await Google.request(
      `drive/v3/files`,
      { params },
      'auth/drive.metadata.readonly'
    );
    data.files = data.files.map((item) => {
      if (item.mimeType === 'application/vnd.google-apps.folder') {
        item.folder = true;
      }
      return item;
    });
    return data;
  }
  /**
   * Find drives of name
   * @param {string} query Query to search for.
   * @param {string} nextPageToken Token provided from a previous request signifying the next page to get
   */
  async function searchForSharedDrive(query, nextPageToken) {
    const params = {
      q: `name contains '${query}'`,
    };

    if (nextPageToken) {
      params.pageToken = nextPageToken;
    }

    const data = await Google.request(
      `drive/v3/drives`,
      { params },
      'auth/drive.metadata.readonly'
    );

    return data.drives;
  }
  async function searchSites(query) {
    return searchForSharedDrive(query);
  }
  const translations = {
    FETCHING_DRIVE: 'Fetching Google Drive',
    FETCHING_ALL_FILES: 'Fetching all files from Google Drive',
    CHECKING_ALREADY_IMPORTED: 'Checking for any file changes',
    REPLACING_UPDATED_FILES: 'Replacing changed files',
    CONNECTED: 'Starting upload',
    UPLOADING: 'Uploading file',
    PROCESSING: 'Processing file',
    AI: 'Processing file (AI)',
    JOB_CHECKED: 'All files imported',
    IMPORTING_FILES: 'Uploading files',
    ADDING_FILES_TO_COLLECTION: 'Adding already uploaded files to collection',
    ERROR_COLLECTION_DELETED: 'Sync failed. Collection has been deleted.',
    FILE_FAILED: 'File failed to process',
    SOMETHING_WENT_WRONG: 'Sync failed due to an unknown reason.',
  };

  function translateCode(code) {
    if (translations[code]) {
      return translations[code];
    }
    return code;
  }
  function getAccessUrl(file, job) {
    let contentUrl = `https://www.googleapis.com/drive/v3/files/${file.id}?alt=media`;

    if (job?.preferences?.externalSeamlessDownload) {
      contentUrl = `https://drive.google.com/uc?export=download&id=${file.id}`;
    } else if (job?.preferences?.externalFiles || !file.webContentLink) {
      contentUrl = file.webViewLink;
    }
    return contentUrl;
  }

  function googleItemToFileObject(file, job) {
    const extension = file.fileExtension || googleMimetoExt(file.mimeType);

    if (extension) {
      return {
        url: getAccessUrl(file, job),
        open_url:
          job?.preferences?.externalFiles &&
          job?.preferences?.externalSeamlessDownload
            ? file.webViewLink
            : undefined,
        mimetype: file.mimeType,
        ext: extension?.toLowerCase(),
        external_file:
          !!googleMimetoExt(file.mimeType) || job?.preferences?.externalFiles,
        name: file.name,
      };
    }
  }
  async function processFileForImport(file, job) {
    const accessUrl = getAccessUrl(file, job);
    return {
      accessUrl,
      file: {
        name: file.name,
        file: googleItemToFileObject(file, job),
        import: {
          from: props.API_ID,
          reference: file.id,
          referenceLocation: file.parents?.[0], // Location id
          source: job?._id,
          checksum: file.md5Checksum,
        },
        uploadedAt: file.createdTime,
        collections: job?.libraryCollection
          ? [job.libraryCollection]
          : undefined,
        .../* job?.ownerOverrideRequireApproval ?? */ (job?.requireApproval
          ? {
              approval: {
                status: 'none',
                status_change_reason: 'imported',
              },
            }
          : {}),
      },

      params: {
        ...getImportParams(file, job),
      },
    };
  }

  function createItemPath(item, currentPath = [], items) {
    if (item?.parents?.[0]) {
      const parent = items.find((i) => i.id === item?.parents?.[0]);
      if (parent) {
        item.path = [parent?.id, ...currentPath];
        if (
          parent?.parents?.length &&
          items.find((i) => i.id === parent?.parents?.[0])
        ) {
          return createItemPath(parent, item.path, items);
        }
      } else {
        item.path = currentPath;
      }
    }
    return item.path.join('/');
  }

  function getValue(key, item, preferences = {}) {
    switch (key) {
      case 'id':
        return item?.id;
      case 'driveId':
        return item.driveId || item?.parents?.[0];
      case 'idPath':
        return createItemPath(item, [], preferences?.items);
      case 'isFolder':
        return item.folder;
      case 'isFolderEmpty':
        return !item.folder;
      case 'folderParentReferenceId':
        return item.parents?.[0]; // This is saved from nested folder
      case 'name':
        return item.name;
      case 'lastModified':
        return item?.modifiedTime;
      case 'createdBy':
        return item?.owners?.[0]?.displayName;
      case 'size':
        return item.size;
      case 'hash':
        return item.md5Checksum;
      case 'extension':
        return item.fileExtension || googleMimetoExt(item.mimeType);
      case 'downloadUrl':
        return item.webContentLink;
      case 'openUrl':
        return item.webViewLink;
      case 'pathString':
        return '';
      default:
        throw new Error(
          'failed getting api item value due to invalid or missing key'
        );
    }
  }
  function getSiteValue(key, item) {
    switch (key) {
      case 'id':
        return item?.id;
      case 'name':
        return item?.name;
      default:
        throw new Error(
          'failed getting api item value due to invalid or missing key'
        );
    }
  }

  const getImportParams = (file, job) => {
    return {
      external_file:
        !!googleMimetoExt(file.mimeType) || job?.preferences?.externalFiles,
      thumbnail_from: file.mimeType.includes('video')
        ? file.thumbnailLink
        : `https://www.googleapis.com/drive/v3/files/${file.id}?alt=media`,
      thumbnail_from_ext: file.mimeType.includes('video')
        ? 'jpg'
        : file.name?.split('.')?.pop(),
      requestHeaders: {
        authorization: `Bearer ${window.gapi.auth.getToken().access_token}`,
      },
    };
  };

  return {
    getAllFolderItems,
    getDriveItem,
    getDriveItems,
    getFolder,
    getAllFolderItemsNested,
    getRecentFiles,
    getSharedDrives,
    getAllDrives,
    getDrive,
    getFoldersFromItems,
    getFilesFromItems,
    search,
    searchForSharedDrive,
    searchSites,
    translateCode,
    processFileForImport,
    getValue,
    getSiteValue,
    googleItemToFileObject,

    isFolderEmpty,
    getImportParams,

    importedFiles: [
      ...(Media.data?.['imported_refs_google-drive'] || []),
      ...(Documents.data?.['imported_refs_google-drive'] || []),
    ],
    isConnected: Google.isConnected,
  };
}
