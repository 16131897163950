import { Icon } from 'elements_v2';
import React from 'react';
import './style.scss';

export default function Loader(props) {
  const className = ['Pixi Pixi__Loader'];
  if (props.row) {
    className.push('Pixi__Loader--row');
  }
  if (props.white) {
    className.push('Pixi__Loader--white');
  }
  if (props.small) {
    className.push('Pixi__Loader--small');
  }
  if (props.large) {
    className.push('Pixi__Loader--large');
  }
  if (props.horizontal) {
    className.push('Pixi__Loader--row');
  }
  if (props.compact) {
    className.push('Pixi__Loader--compact');
  }
  if (props.inline) {
    className.push('Pixi__Loader--inline');
  }
  const childrenIsNotBoolean =
    props.children !== true && props.children !== false;
  let style = props.style ? props.style : {};
  if (!childrenIsNotBoolean) {
    style.justifyContent = 'inherit';
  }

  return (
    <div className={className.join(' ')} style={style}>
      {props.completed ? (
        <Icon name="check2-square" />
      ) : (
        <div className="Pixi Pixi__Loader__spinner">
          <div className="bar1" style={{ background: props.color }} />
          <div className="bar2" style={{ background: props.color }} />
          <div className="bar3" style={{ background: props.color }} />
          <div className="bar4" style={{ background: props.color }} />
          <div className="bar5" style={{ background: props.color }} />
          <div className="bar6" style={{ background: props.color }} />
          <div className="bar7" style={{ background: props.color }} />
          <div className="bar8" style={{ background: props.color }} />
          <div className="bar9" style={{ background: props.color }} />
          <div className="bar10" style={{ background: props.color }} />
          <div className="bar11" style={{ background: props.color }} />
          <div className="bar12" style={{ background: props.color }} />
        </div>
      )}
      {props.children && childrenIsNotBoolean ? (
        <div className="Pixi__Loader__message">{props.children}</div>
      ) : (
        ''
      )}
    </div>
  );
}
