import HeaderImageTitle from './HeaderImageTitle/schema';
import ImageTitleText from './ImageTitleText/schema';
import TitleText from './TitleText/schema';
import FileTitleText from './FileTitleText/schema';
import Files from './Files/schema';
import Separator from './Separator/schema';
import Video from './Video/schema';
import Collections from './Collections/schema';
import CollectionTitleText from './CollectionTitleText/schema';
import Colors from './Colors/schema';
import Boards from './Boards/schema';
import Entrypoints from './Entrypoints/schema';
import ImageTitleTextBlocks from './ImageTitleTextBlocks/schema';
import RichText from './RichText/schema';
import Content from './Content/schema';
import ContentHeader from './ContentHeader/schema';

export default [
  TitleText,
  ...HeaderImageTitle,
  ContentHeader,
  Colors,
  CollectionTitleText,
  ...ImageTitleText,
  FileTitleText,
  ...Files,
  Separator,
  Video,
  Collections,
  ...Boards,
  ...Entrypoints,
  ImageTitleTextBlocks,
  RichText,
  ...Content,
];
