import ApiService from './_ApiService';

class Guideline extends ApiService {
  async getGuideline(slug) {
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'pickit-api-key': window.pickit.keys.PICKIT_API,
      },
      requestUrl: window.pickit.config.SERVICE_URL,
    };

    return this.request(`/guideline/${slug}`, options).then(
      this.handleJSONResponse
    );
  }

  async getAllGuidelineSlides(slug) {
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'pickit-api-key': window.pickit.keys.PICKIT_API,
      },
      requestUrl: window.pickit.config.SERVICE_URL,
    };

    return this.request(`/guideline/${slug}/slide`, options).then(
      this.handleJSONResponse
    );
  }

  async getGuidelineSamples() {
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'pickit-api-key': window.pickit.keys.PICKIT_API,
      },
      requestUrl: window.pickit.config.SERVICE_URL,
    };

    return this.request(`/guideline/pickit-sample/sample`, options).then(
      this.handleJSONResponse
    );
  }

  async getSlide(slug, slide) {
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'pickit-api-key': window.pickit.keys.PICKIT_API,
      },
      requestUrl: window.pickit.config.SERVICE_URL,
    };

    return this.request(`/guideline/${slug}/slide/${slide}`, options).then(
      this.handleJSONResponse
    );
  }

  async createGuideline(slug, data) {
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'pickit-api-key': window.pickit.keys.PICKIT_API,
      },
      requestUrl: window.pickit.config.SERVICE_URL,
      body: JSON.stringify(data),
    };

    return this.request(`/guideline/${slug}`, options).then(
      this.handleJSONResponse
    );
  }

  async updateGuideline(slug, data) {
    const options = {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'pickit-api-key': window.pickit.keys.PICKIT_API,
      },
      requestUrl: window.pickit.config.SERVICE_URL,
      body: JSON.stringify(data),
    };

    return this.request(`/guideline/${slug}`, options).then(
      this.handleJSONResponse
    );
  }

  async createSlide(slug, data) {
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'pickit-api-key': window.pickit.keys.PICKIT_API,
      },
      requestUrl: window.pickit.config.SERVICE_URL,
      body: JSON.stringify(data),
    };

    return this.request(`/guideline/${slug}/slide`, options).then(
      this.handleJSONResponse
    );
  }

  async updateSlide(id, slug, data) {
    const options = {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'pickit-api-key': window.pickit.keys.PICKIT_API,
      },
      requestUrl: window.pickit.config.SERVICE_URL,
      body: JSON.stringify(data),
    };

    return this.request(`/guideline/${slug}/slide/${id}`, options);
  }

  async uploadImage(slug, data) {
    const options = {
      method: 'POST',
      headers: {
        'pickit-api-key': window.pickit.keys.PICKIT_API,
      },
      requestUrl: window.pickit.config.SERVICE_URL,
      body: data,
    };

    return this.request(`/guideline/${slug}/slide/upload/image`, options).then(
      this.handleJSONResponse
    );
  }
}

export default Guideline;
