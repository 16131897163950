import React from 'react';
import Thumbnail from './Thumbnail';

const selectedImgStyle = {
  transform: 'translateZ(0px) scale3d(0.9, 0.9, 1)',
  transition: 'transform .135s cubic-bezier(0.0,0.0,0.2,1),opacity linear .15s',
};
const cont = {
  backgroundColor: '#eee',
  cursor: 'pointer',
  overflow: 'hidden',
  position: 'relative',
};

const Image = React.forwardRef((props, ref) => {
  const sx = (100 - (30 / props.photo.width) * 100) / 100;
  const sy = (100 - (30 / props.photo.height) * 100) / 100;
  selectedImgStyle.transform = `translateZ(0px) scale3d(${sx}, ${sy}, 1)`;
  const className = `ContributionsGridV2__Image brand__color__border__before ${
    props.isLoading ? 'ContributionsGridV2__Image--isLoading' : ''
  } ${props.isSelected ? 'ContributionsGridV2__Image--isSelected' : ''} ${
    props.photo.height === 'auto' ? 'ContributionsGridV2__Image--auto' : ''
  }`;
  // const [, drag] = useDrag({
  //   type: props.fileType,
  //   item: {
  //     id: props.photo?.data?._id,
  //     data: props?.photo?.data,
  //     type: props.fileType,
  //   },
  //   collect: (monitor) => ({
  //     isDragging: monitor.isDragging(),
  //   }),
  //   canDrag: () => {
  //     return props.draggable;
  //   },
  // });

  // drag(ref);

  const { margin } = props;

  return (
    <div
      className={className}
      ref={ref}
      onClick={props.onClick}
      style={{
        margin,
        height: props.photo.height,
        width: props.photo.width,
        ...(props.style || {}),
        ...cont,
      }}
    >
      <Thumbnail
        static={props.sticky && props.containerWidth}
        file={props?.photo?.data?.file}
        forceThumbnailUrl={props.forceThumbnailUrl}
        prefs={{ onlyImage: true }}
        context={props.context}
        photo={props?.photo}
        injectThumbnail={props.injectThumbnail}
        data={props?.photo?.data}
        isLoading={props.isLoading}
        customLoader={props.customLoader}
        loadingMessage={props.loadingMessage}
        preferSize={
          !props.gridSize
            ? 'small'
            : props.gridSize === 'small'
              ? 'tiny'
              : 'medium'
        }
      />
      {props.fileToolbar && (
        <div className="ContributionsGridV2__Image__toolbar">
          {props.fileToolbar(props?.photo?.data)}
        </div>
      )}
    </div>
  );
});

export default Image;
