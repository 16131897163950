import React from 'react';
import en from 'data/text/en.json';
import sv from 'data/text/sv.json';
import de from 'data/text/de.json';
import es from 'data/text/es.json';
import fr from 'data/text/fr.json';
import jp from 'data/text/jp.json';
import it from 'data/text/it.json';
import bg from 'data/text/bg.json';
import fi from 'data/text/fi.json';
import ru from 'data/text/ru.json';
import localStorageWrapper from 'utils/localStorageWrapper';
import { TextContext } from 'contexts/Text';

const Languages = [
  {
    id: 'en',
    name: 'English',
    data: en,
    flag: 'uk',
  },
  {
    id: 'sv',
    name: 'Svenska',
    data: sv,
    flag: 'se',
  },
  {
    id: 'de',
    name: 'Deutsch',
    data: de,
    flag: 'de',
  },
  {
    id: 'es',
    name: 'Español',
    data: es,
    flag: 'es',
  },
  {
    id: 'fr',
    name: 'Français',
    data: fr,
    flag: 'fr',
  },
  {
    id: 'bg',
    name: 'Български',
    data: bg,
    flag: 'bg',
  },
  {
    id: 'it',
    name: 'Italiano',
    data: it,
    flag: 'it',
  },
  {
    id: 'jp',
    name: '日本語',
    data: jp,
    flag: 'jp',
    searchKey: 'ja',
  },
  {
    id: 'fi',
    name: 'Suomi',
    data: fi,
    flag: 'fi',
  },
  {
    id: 'ru',
    name: 'Русский',
    data: ru,
    flag: 'ru',
  },
];

function compare(base, compared, language, deepSearch, errors) {
  if (!errors) {
    errors = [];
  }

  const compareProp = function (baseValue, comparedValue, path, deepSearch) {
    if (comparedValue === undefined || comparedValue === '##') {
      // console.error(`Missing text in ${language}\nKey: "${path.join('.')}"\nEnglish value: "${baseValue}"`);
      errors.push([language, path.join('.'), baseValue]);
      if (!deepSearch) {
        return;
      }
    }

    if (typeof baseValue === 'object') {
      Object.keys(baseValue).forEach(function (key) {
        compareProp(
          baseValue[key],
          comparedValue && comparedValue[key],
          path.concat(key),
          deepSearch,
        );
      });
    }
  };

  Object.keys(base).forEach(function (key) {
    compareProp(base[key], compared[key], [key], deepSearch);
  });

  return errors;
}

export function TextProvider(props) {
  const fallbackLanguage = Languages.find((lang) => lang.id === 'en');
  const [language, setLanguage] = React.useState(
    Languages.find(
      (lang) => lang.id === localStorageWrapper.getItem('selectedLanguage'),
    ) || fallbackLanguage,
  );

  function pathToString(path) {
    try {
      let text = path.split('/').reduce((obj, i) => obj[i], language.data);
      if (typeof text !== 'string') {
        text = path
          .split('/')
          .reduce((obj, i) => obj[i], fallbackLanguage.data);
      }
      return text;
    } catch (e) {
      try {
        return path
          .split('/')
          .reduce((obj, i) => obj[i], fallbackLanguage.data);
      } catch (e) {
        return false;
      }
    }
  }

  async function translate(options) {
    const subscriptionKey = '13cbaf1740324e5e9904d516e4d45511';
    const to = options.to || language.id;
    const from = options.from || 'en';
    if (to === from) {
      return false;
    }
    const queryParameters = new URLSearchParams({
      'api-version': '3.0',
      to,
      from,
    });
    const req = await fetch(
      `https://api.cognitive.microsofttranslator.com/translate?${queryParameters.toString()}`,
      {
        method: 'POST',
        headers: {
          'Ocp-Apim-Subscription-Key': subscriptionKey,
          'Content-type': 'application/json',
        },
        body: JSON.stringify(
          options.texts.map((text) => {
            return {
              text,
            };
          }),
        ),
      },
    );
    const data = await req.json();
    return data.map((row) => {
      return row.translations[0].text.toLowerCase();
    });
  }

  const values = {
    get: (path) => {
      const text = pathToString(path);

      if (typeof text !== 'string') {
        return null;
      }
      return text;
    },
    language,
    translate,
    availableLanguages: Languages,
    changeLanguage: (value) => {
      localStorageWrapper.setItem('selectedLanguage', value);
      setLanguage(Languages.find((lang) => lang.id === value));
    },
  };
  return (
    <TextContext.Provider value={values}>{props.children}</TextContext.Provider>
  );
}
