import {
  Button,
  Confirm,
  Dropdown,
  Flex,
  Form,
  Icon,
  Loader,
  Progress,
} from 'elements_v2';
import Input from 'elements_v2/Input/index_ts';
import { useMediaContext } from 'hooks';
import useStorageUrl from 'hooks/files/useStorageUrl';
import useUserStatus from 'hooks/useUserStatus';
import { useEffect, useReducer, useRef, useState } from 'react';
import { randomString } from 'utils';
import SrtEditor from './SRTEditor';

const languages: { code: string; name: string }[] = [
  {
    code: 'af',
    name: 'Afrikaans',
  },
  {
    code: 'af-ZA',
    name: 'Afrikaans (South Africa)',
  },
  {
    code: 'ar',
    name: 'Arabic',
  },
  {
    code: 'ar-AE',
    name: 'Arabic (U.A.E.)',
  },
  {
    code: 'ar-BH',
    name: 'Arabic (Bahrain)',
  },
  {
    code: 'ar-DZ',
    name: 'Arabic (Algeria)',
  },
  {
    code: 'ar-EG',
    name: 'Arabic (Egypt)',
  },
  {
    code: 'ar-IQ',
    name: 'Arabic (Iraq)',
  },
  {
    code: 'ar-JO',
    name: 'Arabic (Jordan)',
  },
  {
    code: 'ar-KW',
    name: 'Arabic (Kuwait)',
  },
  {
    code: 'ar-LB',
    name: 'Arabic (Lebanon)',
  },
  {
    code: 'ar-LY',
    name: 'Arabic (Libya)',
  },
  {
    code: 'ar-MA',
    name: 'Arabic (Morocco)',
  },
  {
    code: 'ar-OM',
    name: 'Arabic (Oman)',
  },
  {
    code: 'ar-QA',
    name: 'Arabic (Qatar)',
  },
  {
    code: 'ar-SA',
    name: 'Arabic (Saudi Arabia)',
  },
  {
    code: 'ar-SY',
    name: 'Arabic (Syria)',
  },
  {
    code: 'ar-TN',
    name: 'Arabic (Tunisia)',
  },
  {
    code: 'ar-YE',
    name: 'Arabic (Yemen)',
  },
  {
    code: 'az',
    name: 'Azeri (Latin)',
  },
  {
    code: 'az-AZ',
    name: 'Azeri (Latin) (Azerbaijan)',
  },
  {
    code: 'az-AZ',
    name: 'Azeri (Cyrillic) (Azerbaijan)',
  },
  {
    code: 'be',
    name: 'Belarusian',
  },
  {
    code: 'be-BY',
    name: 'Belarusian (Belarus)',
  },
  {
    code: 'bg',
    name: 'Bulgarian',
  },
  {
    code: 'bg-BG',
    name: 'Bulgarian (Bulgaria)',
  },
  {
    code: 'bs-BA',
    name: 'Bosnian (Bosnia and Herzegovina)',
  },
  {
    code: 'ca',
    name: 'Catalan',
  },
  {
    code: 'ca-ES',
    name: 'Catalan (Spain)',
  },
  {
    code: 'cs',
    name: 'Czech',
  },
  {
    code: 'cs-CZ',
    name: 'Czech (Czech Republic)',
  },
  {
    code: 'cy',
    name: 'Welsh',
  },
  {
    code: 'cy-GB',
    name: 'Welsh (United Kingdom)',
  },
  {
    code: 'da',
    name: 'Danish',
  },
  {
    code: 'da-DK',
    name: 'Danish (Denmark)',
  },
  {
    code: 'de',
    name: 'German',
  },
  {
    code: 'de-AT',
    name: 'German (Austria)',
  },
  {
    code: 'de-CH',
    name: 'German (Switzerland)',
  },
  {
    code: 'de-DE',
    name: 'German (Germany)',
  },
  {
    code: 'de-LI',
    name: 'German (Liechtenstein)',
  },
  {
    code: 'de-LU',
    name: 'German (Luxembourg)',
  },
  {
    code: 'dv',
    name: 'Divehi',
  },
  {
    code: 'dv-MV',
    name: 'Divehi (Maldives)',
  },
  {
    code: 'el',
    name: 'Greek',
  },
  {
    code: 'el-GR',
    name: 'Greek (Greece)',
  },
  {
    code: 'en',
    name: 'English',
  },
  {
    code: 'en-AU',
    name: 'English (Australia)',
  },
  {
    code: 'en-BZ',
    name: 'English (Belize)',
  },
  {
    code: 'en-CA',
    name: 'English (Canada)',
  },
  {
    code: 'en-CB',
    name: 'English (Caribbean)',
  },
  {
    code: 'en-GB',
    name: 'English (United Kingdom)',
  },
  {
    code: 'en-IE',
    name: 'English (Ireland)',
  },
  {
    code: 'en-JM',
    name: 'English (Jamaica)',
  },
  {
    code: 'en-NZ',
    name: 'English (New Zealand)',
  },
  {
    code: 'en-PH',
    name: 'English (Republic of the Philippines)',
  },
  {
    code: 'en-TT',
    name: 'English (Trinidad and Tobago)',
  },
  {
    code: 'en-US',
    name: 'English (United States)',
  },
  {
    code: 'en-ZA',
    name: 'English (South Africa)',
  },
  {
    code: 'en-ZW',
    name: 'English (Zimbabwe)',
  },
  {
    code: 'eo',
    name: 'Esperanto',
  },
  {
    code: 'es',
    name: 'Spanish',
  },
  {
    code: 'es-AR',
    name: 'Spanish (Argentina)',
  },
  {
    code: 'es-BO',
    name: 'Spanish (Bolivia)',
  },
  {
    code: 'es-CL',
    name: 'Spanish (Chile)',
  },
  {
    code: 'es-CO',
    name: 'Spanish (Colombia)',
  },
  {
    code: 'es-CR',
    name: 'Spanish (Costa Rica)',
  },
  {
    code: 'es-DO',
    name: 'Spanish (Dominican Republic)',
  },
  {
    code: 'es-EC',
    name: 'Spanish (Ecuador)',
  },
  {
    code: 'es-ES',
    name: 'Spanish (Castilian)',
  },
  {
    code: 'es-ES',
    name: 'Spanish (Spain)',
  },
  {
    code: 'es-GT',
    name: 'Spanish (Guatemala)',
  },
  {
    code: 'es-HN',
    name: 'Spanish (Honduras)',
  },
  {
    code: 'es-MX',
    name: 'Spanish (Mexico)',
  },
  {
    code: 'es-NI',
    name: 'Spanish (Nicaragua)',
  },
  {
    code: 'es-PA',
    name: 'Spanish (Panama)',
  },
  {
    code: 'es-PE',
    name: 'Spanish (Peru)',
  },
  {
    code: 'es-PR',
    name: 'Spanish (Puerto Rico)',
  },
  {
    code: 'es-PY',
    name: 'Spanish (Paraguay)',
  },
  {
    code: 'es-SV',
    name: 'Spanish (El Salvador)',
  },
  {
    code: 'es-UY',
    name: 'Spanish (Uruguay)',
  },
  {
    code: 'es-VE',
    name: 'Spanish (Venezuela)',
  },
  {
    code: 'et',
    name: 'Estonian',
  },
  {
    code: 'et-EE',
    name: 'Estonian (Estonia)',
  },
  {
    code: 'eu',
    name: 'Basque',
  },
  {
    code: 'eu-ES',
    name: 'Basque (Spain)',
  },
  {
    code: 'fa',
    name: 'Farsi',
  },
  {
    code: 'fa-IR',
    name: 'Farsi (Iran)',
  },
  {
    code: 'fi',
    name: 'Finnish',
  },
  {
    code: 'fi-FI',
    name: 'Finnish (Finland)',
  },
  {
    code: 'fo',
    name: 'Faroese',
  },
  {
    code: 'fo-FO',
    name: 'Faroese (Faroe Islands)',
  },
  {
    code: 'fr',
    name: 'French',
  },
  {
    code: 'fr-BE',
    name: 'French (Belgium)',
  },
  {
    code: 'fr-CA',
    name: 'French (Canada)',
  },
  {
    code: 'fr-CH',
    name: 'French (Switzerland)',
  },
  {
    code: 'fr-FR',
    name: 'French (France)',
  },
  {
    code: 'fr-LU',
    name: 'French (Luxembourg)',
  },
  {
    code: 'fr-MC',
    name: 'French (Principality of Monaco)',
  },
  {
    code: 'gl',
    name: 'Galician',
  },
  {
    code: 'gl-ES',
    name: 'Galician (Spain)',
  },
  {
    code: 'gu',
    name: 'Gujarati',
  },
  {
    code: 'gu-IN',
    name: 'Gujarati (India)',
  },
  {
    code: 'he',
    name: 'Hebrew',
  },
  {
    code: 'he-IL',
    name: 'Hebrew (Israel)',
  },
  {
    code: 'hi',
    name: 'Hindi',
  },
  {
    code: 'hi-IN',
    name: 'Hindi (India)',
  },
  {
    code: 'hr',
    name: 'Croatian',
  },
  {
    code: 'hr-BA',
    name: 'Croatian (Bosnia and Herzegovina)',
  },
  {
    code: 'hr-HR',
    name: 'Croatian (Croatia)',
  },
  {
    code: 'hu',
    name: 'Hungarian',
  },
  {
    code: 'hu-HU',
    name: 'Hungarian (Hungary)',
  },
  {
    code: 'hy',
    name: 'Armenian',
  },
  {
    code: 'hy-AM',
    name: 'Armenian (Armenia)',
  },
  {
    code: 'id',
    name: 'Indonesian',
  },
  {
    code: 'id-ID',
    name: 'Indonesian (Indonesia)',
  },
  {
    code: 'is',
    name: 'Icelandic',
  },
  {
    code: 'is-IS',
    name: 'Icelandic (Iceland)',
  },
  {
    code: 'it',
    name: 'Italian',
  },
  {
    code: 'it-CH',
    name: 'Italian (Switzerland)',
  },
  {
    code: 'it-IT',
    name: 'Italian (Italy)',
  },
  {
    code: 'ja',
    name: 'Japanese',
  },
  {
    code: 'ja-JP',
    name: 'Japanese (Japan)',
  },
  {
    code: 'ka',
    name: 'Georgian',
  },
  {
    code: 'ka-GE',
    name: 'Georgian (Georgia)',
  },
  {
    code: 'kk',
    name: 'Kazakh',
  },
  {
    code: 'kk-KZ',
    name: 'Kazakh (Kazakhstan)',
  },
  {
    code: 'kn',
    name: 'Kannada',
  },
  {
    code: 'kn-IN',
    name: 'Kannada (India)',
  },
  {
    code: 'ko',
    name: 'Korean',
  },
  {
    code: 'ko-KR',
    name: 'Korean (Korea)',
  },
  {
    code: 'kok',
    name: 'Konkani',
  },
  {
    code: 'kok-IN',
    name: 'Konkani (India)',
  },
  {
    code: 'ky',
    name: 'Kyrgyz',
  },
  {
    code: 'ky-KG',
    name: 'Kyrgyz (Kyrgyzstan)',
  },
  {
    code: 'lt',
    name: 'Lithuanian',
  },
  {
    code: 'lt-LT',
    name: 'Lithuanian (Lithuania)',
  },
  {
    code: 'lv',
    name: 'Latvian',
  },
  {
    code: 'lv-LV',
    name: 'Latvian (Latvia)',
  },
  {
    code: 'mi',
    name: 'Maori',
  },
  {
    code: 'mi-NZ',
    name: 'Maori (New Zealand)',
  },
  {
    code: 'mk',
    name: 'FYRO Macedonian',
  },
  {
    code: 'mk-MK',
    name: 'FYRO Macedonian (Former Yugoslav Republic of Macedonia)',
  },
  {
    code: 'mn',
    name: 'Mongolian',
  },
  {
    code: 'mn-MN',
    name: 'Mongolian (Mongolia)',
  },
  {
    code: 'mr',
    name: 'Marathi',
  },
  {
    code: 'mr-IN',
    name: 'Marathi (India)',
  },
  {
    code: 'ms',
    name: 'Malay',
  },
  {
    code: 'ms-BN',
    name: 'Malay (Brunei Darussalam)',
  },
  {
    code: 'ms-MY',
    name: 'Malay (Malaysia)',
  },
  {
    code: 'mt',
    name: 'Maltese',
  },
  {
    code: 'mt-MT',
    name: 'Maltese (Malta)',
  },
  {
    code: 'nb',
    name: 'Norwegian (Bokm?l)',
  },
  {
    code: 'nb-NO',
    name: 'Norwegian (Bokm?l) (Norway)',
  },
  {
    code: 'nl',
    name: 'Dutch',
  },
  {
    code: 'nl-BE',
    name: 'Dutch (Belgium)',
  },
  {
    code: 'nl-NL',
    name: 'Dutch (Netherlands)',
  },
  {
    code: 'nn-NO',
    name: 'Norwegian (Nynorsk) (Norway)',
  },
  {
    code: 'ns',
    name: 'Northern Sotho',
  },
  {
    code: 'ns-ZA',
    name: 'Northern Sotho (South Africa)',
  },
  {
    code: 'pa',
    name: 'Punjabi',
  },
  {
    code: 'pa-IN',
    name: 'Punjabi (India)',
  },
  {
    code: 'pl',
    name: 'Polish',
  },
  {
    code: 'pl-PL',
    name: 'Polish (Poland)',
  },
  {
    code: 'ps',
    name: 'Pashto',
  },
  {
    code: 'ps-AR',
    name: 'Pashto (Afghanistan)',
  },
  {
    code: 'pt',
    name: 'Portuguese',
  },
  {
    code: 'pt-BR',
    name: 'Portuguese (Brazil)',
  },
  {
    code: 'pt-PT',
    name: 'Portuguese (Portugal)',
  },
  {
    code: 'qu',
    name: 'Quechua',
  },
  {
    code: 'qu-BO',
    name: 'Quechua (Bolivia)',
  },
  {
    code: 'qu-EC',
    name: 'Quechua (Ecuador)',
  },
  {
    code: 'qu-PE',
    name: 'Quechua (Peru)',
  },
  {
    code: 'ro',
    name: 'Romanian',
  },
  {
    code: 'ro-RO',
    name: 'Romanian (Romania)',
  },
  {
    code: 'ru',
    name: 'Russian',
  },
  {
    code: 'ru-RU',
    name: 'Russian (Russia)',
  },
  {
    code: 'sa',
    name: 'Sanskrit',
  },
  {
    code: 'sa-IN',
    name: 'Sanskrit (India)',
  },
  {
    code: 'se',
    name: 'Sami (Northern)',
  },
  {
    code: 'se-FI',
    name: 'Sami (Northern) (Finland)',
  },
  {
    code: 'se-FI',
    name: 'Sami (Skolt) (Finland)',
  },
  {
    code: 'se-FI',
    name: 'Sami (Inari) (Finland)',
  },
  {
    code: 'se-NO',
    name: 'Sami (Northern) (Norway)',
  },
  {
    code: 'se-NO',
    name: 'Sami (Lule) (Norway)',
  },
  {
    code: 'se-NO',
    name: 'Sami (Southern) (Norway)',
  },
  {
    code: 'se-SE',
    name: 'Sami (Northern) (Sweden)',
  },
  {
    code: 'se-SE',
    name: 'Sami (Lule) (Sweden)',
  },
  {
    code: 'se-SE',
    name: 'Sami (Southern) (Sweden)',
  },
  {
    code: 'sk',
    name: 'Slovak',
  },
  {
    code: 'sk-SK',
    name: 'Slovak (Slovakia)',
  },
  {
    code: 'sl',
    name: 'Slovenian',
  },
  {
    code: 'sl-SI',
    name: 'Slovenian (Slovenia)',
  },
  {
    code: 'sq',
    name: 'Albanian',
  },
  {
    code: 'sq-AL',
    name: 'Albanian (Albania)',
  },
  {
    code: 'sr-BA',
    name: 'Serbian (Latin) (Bosnia and Herzegovina)',
  },
  {
    code: 'sr-BA',
    name: 'Serbian (Cyrillic) (Bosnia and Herzegovina)',
  },
  {
    code: 'sr-SP',
    name: 'Serbian (Latin) (Serbia and Montenegro)',
  },
  {
    code: 'sr-SP',
    name: 'Serbian (Cyrillic) (Serbia and Montenegro)',
  },
  {
    code: 'sv-FI',
    name: 'Swedish (Finland)',
  },
  {
    code: 'sv-SE',
    name: 'Swedish (Sweden)',
  },
  {
    code: 'sw',
    name: 'Swahili',
  },
  {
    code: 'sw-KE',
    name: 'Swahili (Kenya)',
  },
  {
    code: 'syr',
    name: 'Syriac',
  },
  {
    code: 'syr-SY',
    name: 'Syriac (Syria)',
  },
  {
    code: 'ta',
    name: 'Tamil',
  },
  {
    code: 'ta-IN',
    name: 'Tamil (India)',
  },
  {
    code: 'te',
    name: 'Telugu',
  },
  {
    code: 'te-IN',
    name: 'Telugu (India)',
  },
  {
    code: 'th',
    name: 'Thai',
  },
  {
    code: 'th-TH',
    name: 'Thai (Thailand)',
  },
  {
    code: 'tl',
    name: 'Tagalog',
  },
  {
    code: 'tl-PH',
    name: 'Tagalog (Philippines)',
  },
  {
    code: 'tn',
    name: 'Tswana',
  },
  {
    code: 'tn-ZA',
    name: 'Tswana (South Africa)',
  },
  {
    code: 'tr',
    name: 'Turkish',
  },
  {
    code: 'tr-TR',
    name: 'Turkish (Turkey)',
  },
  {
    code: 'tt',
    name: 'Tatar',
  },
  {
    code: 'tt-RU',
    name: 'Tatar (Russia)',
  },
  {
    code: 'ts',
    name: 'Tsonga',
  },
  {
    code: 'uk',
    name: 'Ukrainian',
  },
  {
    code: 'uk-UA',
    name: 'Ukrainian (Ukraine)',
  },
  {
    code: 'ur',
    name: 'Urdu',
  },
  {
    code: 'ur-PK',
    name: 'Urdu (Islamic Republic of Pakistan)',
  },
  {
    code: 'uz',
    name: 'Uzbek (Latin)',
  },
  {
    code: 'uz-UZ',
    name: 'Uzbek (Latin) (Uzbekistan)',
  },
  {
    code: 'uz-UZ',
    name: 'Uzbek (Cyrillic) (Uzbekistan)',
  },
  {
    code: 'vi',
    name: 'Vietnamese',
  },
  {
    code: 'vi-VN',
    name: 'Vietnamese (Viet Nam)',
  },
  {
    code: 'xh',
    name: 'Xhosa',
  },
  {
    code: 'xh-ZA',
    name: 'Xhosa (South Africa)',
  },
  {
    code: 'zh',
    name: 'Chinese',
  },
  {
    code: 'zh-CN',
    name: 'Chinese (S)',
  },
  {
    code: 'zh-HK',
    name: 'Chinese (Hong Kong)',
  },
  {
    code: 'zh-MO',
    name: 'Chinese (Macau)',
  },
  {
    code: 'zh-SG',
    name: 'Chinese (Singapore)',
  },
  {
    code: 'zh-TW',
    name: 'Chinese (T)',
  },
  {
    code: 'zu',
    name: 'Zulu',
  },
  {
    code: 'zu-ZA',
    name: 'Zulu (South Africa)',
  },
];

export default function VideoForm({
  file,
  onChange,
  onGeneratePreview,
  onSRTUpdate,
}: {
  file: Pickit.FileInterface;
  onChange: (data: any) => void;
  onGeneratePreview?: (isGenerating: boolean) => void;
  onSRTUpdate: (data: any) => void;
}) {
  const { generateUrl } = useStorageUrl();
  const [currentTime, setCurrentTime] = useState(0);
  const userStatus = useUserStatus() as any;
  const videoRef = useRef<HTMLVideoElement>(null);
  const [transcriptionFile, setTranscriptionFile] = useReducer(
    (
      state: { id: string; url: string },
      action: { id: string; url: string }
    ) => ({
      ...action,
    }),
    {} as { id: string; url: string }
  );
  const [language, setLanguage] = useState<string>('en-US');
  const [isUploading, setIsUploading] = useState(false);
  const [isGeneratingTranscription, setIsGeneratingTranscription] =
    useState(false);
  const [generatingTranscriptionProgress, setGeneratingTranscriptionProgress] =
    useState<{
      code?: string;
      progress?: {
        current?: number;
        total?: number;
      };
    }>({});
  const [isGeneratingPreview, setIsGeneratingPreview] = useState(false);
  const [generatingProgress, setGeneratingProgress] = useState<{
    code?: string;
    progress?: {
      current?: number;
      total?: number;
    };
  }>({});

  useEffect(() => {
    if (file?.file?.previewVideoTranscription) {
      setTranscriptionFile({
        id: file?.file?.previewVideoTranscription?.id,
        url: generateUrl(file?.file?.previewVideoTranscription?.url),
      });
    }
  }, [file]);

  useEffect(() => {
    // Grab the video element
    const video = videoRef.current;

    if (!video) return;

    // Define the event listener
    const handleTimeUpdate = () => {
      setCurrentTime(video.currentTime);
    };

    // Add the event listener to the video
    video.addEventListener('timeupdate', handleTimeUpdate);

    // Clean up: remove the event listener when the component is unmounted
    return () => {
      video.removeEventListener('timeupdate', handleTimeUpdate);
    };
  }, []); // The empty dependency array ensures this effect runs once after initial render
  const Media: any = useMediaContext();
  async function generatePreview() {
    setIsGeneratingPreview(true);
    onGeneratePreview?.(true);
    Media.request.generate(file._id, 'video-preview', {
      onProgress: (progress: any) => {
        if (progress.code === 'COMPLETED' && progress.file) {
          Media.stores.setData({
            store: 'files',
            type: 'update',
            key: '_id',
            data: progress.file,
          });
          onChange({
            file: {
              ...file.file,
              previewVideo: progress.file?.file?.previewVideo,
            },
          });
          setIsGeneratingPreview(false);
          onGeneratePreview?.(false);
        }
        setGeneratingProgress(progress);
      },
      onClose: () => {
        setIsGeneratingPreview(false);
        onGeneratePreview?.(false);
      },
    });
  }
  async function generateTranscription() {
    setIsGeneratingTranscription(true);
    Media.stores.setData({
      store: 'files',
      type: 'update',
      key: '_id',
      data: {
        ...file,
        processing: {
          ...file.processing,
          transcription: {
            isProcessing: true,
          },
        },
      },
    });
    Media.request.generate(file._id, 'transcription', {
      data: { language },
      onProgress: (progress: any) => {
        if (progress.code === 'COMPLETED' && progress.file) {
          Media.stores.setData({
            store: 'files',
            type: 'update',
            key: '_id',
            data: progress.file,
          });
          onChange({
            file: {
              ...file.file,
              previewVideoTranscription:
                progress.file?.file?.previewVideoTranscription,
            },
          });
          setIsGeneratingTranscription(false);
        }
        setGeneratingTranscriptionProgress(progress);
      },
      onClose: () => {
        setIsGeneratingTranscription(false);
      },
    });
  }

  function translateCode(code: any) {
    if (code === 'GENERATING') {
      return 'Generating video';
    }
    if (code === 'UPLOADING') {
      return 'Uploading video';
    }
    if (code === 'TRANSCRIBING') {
      return 'Analyzing audio with Pickit AI';
    }
    if (code === 'EXTRACTING_AUDIO') {
      return 'Extracting audio from video';
    }
    return code;
  }

  return (
    <>
      <Form.Tabs
        tabStyle={{
          marginTop: 10,
        }}
      >
        <Form.Tab id="preview" icon="eye" label="Preview">
          <Flex gap={20}>
            <Input.Field
              label="Browser preview"
              custom={
                <>
                  {!isGeneratingPreview && (
                    <video style={{ width: '100%' }} controls>
                      <source
                        src={generateUrl(file?.file?.previewVideo?.url)}
                      />
                    </video>
                  )}
                  <Flex column alignItems="flex-start" gap={2}>
                    <Button
                      size="small"
                      inline
                      outlined
                      onClick={() => {
                        generatePreview();
                      }}
                      loading={isGeneratingPreview}
                    >
                      {file?.file?.previewVideo?.id ? 'Regenerate' : 'Generate'}{' '}
                      browser preview
                    </Button>
                    {!!generatingProgress?.progress?.current && (
                      <Input.Field
                        label={translateCode(generatingProgress?.code)}
                        customStyle={{
                          padding: 0,
                        }}
                        custom={
                          <Progress
                            current={generatingProgress.progress.current}
                            max={generatingProgress.progress.total}
                            currentLabelPercentage
                          />
                        }
                      />
                    )}
                  </Flex>
                </>
              }
            />
          </Flex>
        </Form.Tab>
        <Form.Tab
          id="transcription"
          icon="chat-dots"
          label="Transcription"
          disabled={
            !file?.file?.previewVideo?.id ||
            !userStatus?.activeFeatures?.video_transcription
          }
          disabledMessage={
            !userStatus?.activeFeatures?.video_transcription
              ? 'Transcription is not enabled with your subscription'
              : 'You need to generate a browser preview before enabling transcription'
          }
        >
          {file?.processing?.transcription?.isProcessing ? (
            <>
              <Loader>Generating transcription...</Loader>
            </>
          ) : (
            <Flex column alignItems="flex-start" gap={2}>
              {file?.file?.previewVideoTranscription?.id && (
                <>
                  <video
                    style={{ width: '100%', maxHeight: 200 }}
                    controls
                    ref={videoRef}
                  >
                    <source src={generateUrl(file?.file?.previewVideo?.url)} />
                  </video>
                  <SrtEditor
                    fileId={file?.file?.previewVideoTranscription?.id}
                    key={file?.file?.previewVideoTranscription?.id}
                    url={generateUrl(
                      file?.file?.previewVideoTranscription?.url
                    )}
                    onSave={(data: string) => {
                      onSRTUpdate(data);
                    }}
                    videoRef={videoRef}
                    currentTime={currentTime}
                  />
                </>
              )}
              <Flex
                alignItems="center"
                justifyContent={
                  !file?.file?.previewVideoTranscription?.id
                    ? 'flex-end'
                    : 'flex-start'
                }
                style={{ marginTop: 10 }}
              >
                <Confirm
                  title={
                    <>
                      {file?.file?.previewVideoTranscription?.id
                        ? 'Regenerate'
                        : 'Generate'}{' '}
                      transcription
                    </>
                  }
                  confirm={
                    <>
                      {file?.file?.previewVideoTranscription?.id
                        ? 'Regenerate'
                        : 'Generate'}{' '}
                      transcription
                    </>
                  }
                  text={
                    <>
                      <Input.Field
                        card
                        label="Select video language"
                        custom={
                          <select
                            value={language}
                            onChange={(e) => {
                              setLanguage(e.target.value as any);
                            }}
                            className="Pixi__Input"
                            style={{ maxHeight: 250, overflow: 'auto' }}
                          >
                            {languages.map((l) => (
                              <option value={l.code} key={l.code}>
                                {l.name}
                              </option>
                            ))}
                          </select>
                        }
                      />
                      The current captions will be deleted and a new one will be
                      generated.
                    </>
                  }
                  disabled={!file?.file?.previewVideoTranscription?.id}
                  onConfirm={() => {
                    generateTranscription();
                  }}
                >
                  <Button
                    size={
                      !file?.file?.previewVideoTranscription?.id ? '' : 'small'
                    }
                    inline
                    onClick={() => {
                      generateTranscription();
                    }}
                    primary
                    loading={isGeneratingTranscription}
                  >
                    <Icon name="cpu" />
                    {file?.file?.previewVideoTranscription?.id
                      ? 'Regenerate'
                      : 'Generate'}{' '}
                    transcription
                  </Button>
                </Confirm>
                <Button
                  size={
                    !file?.file?.previewVideoTranscription?.id ? '' : 'small'
                  }
                  loading={isUploading}
                  onClick={() => {
                    const input = document.createElement('input');
                    input.type = 'file';
                    input.accept = '.srt';

                    input.onchange = async (event) => {
                      setIsUploading(true);
                      const newFile = (event.target as HTMLInputElement)
                        ?.files?.[0];
                      const fileId = randomString(8);
                      const createdFile = await Media.uploadNewFileById(
                        fileId,
                        'thumbnails',
                        newFile
                      );
                      setTranscriptionFile({
                        id: createdFile.id,
                        url: createdFile.url,
                      });
                      onChange({
                        file: {
                          ...file.file,
                          previewVideoTranscription: {
                            id: createdFile.id,
                            url: createdFile.url,
                          },
                        },
                      });
                      setIsUploading(false);
                    };

                    // Trigger the file input dialog
                    input.click();
                  }}
                  inline
                >
                  <Icon name="upload" />
                  Upload transcription (SRT)
                </Button>
                {file?.file?.previewVideoTranscription?.id && (
                  <>
                    <Confirm
                      title="Are you sure?"
                      text="The transcription file will be deleted. This can't be undone."
                      onConfirm={() => {
                        onChange({
                          file: {
                            ...file.file,
                            previewVideoTranscription: null,
                          },
                        });
                      }}
                    >
                      <Button inline size="small">
                        <Icon name="trash" />
                        Remove
                      </Button>
                    </Confirm>
                  </>
                )}
              </Flex>
              {!!generatingTranscriptionProgress?.progress?.current && (
                <Input.Field
                  label={translateCode(generatingTranscriptionProgress?.code)}
                  customStyle={{
                    padding: 0,
                  }}
                  custom={
                    <Progress
                      current={generatingTranscriptionProgress.progress.current}
                      max={generatingTranscriptionProgress.progress.total}
                      currentLabelPercentage
                      labelPosition="bottom"
                    />
                  }
                />
              )}
            </Flex>
          )}
        </Form.Tab>
      </Form.Tabs>
    </>
  );
}
