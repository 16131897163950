export function getSelectedSlideID() {
  return new window.OfficeExtension.Promise(function (resolve, reject) {
    window.Office.context.document.getSelectedDataAsync(
      window.Office.CoercionType.SlideRange,
      function (asyncResult) {
        try {
          if (asyncResult.status === window.Office.AsyncResultStatus.Failed) {
            reject(console.error(asyncResult.error.message));
          } else {
            resolve(asyncResult.value.slides?.[0]?.id);
          }
        } catch (error) {
          reject(console.error(error));
        }
      }
    );
  });
}
