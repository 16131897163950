import React from 'react';
import './style.scss';
import 'bootstrap-icons/font/bootstrap-icons.css';
import IconsMap from 'bootstrap-icons/font/bootstrap-icons.json';

export type PixiIcon = keyof typeof IconsMap;

interface InterfacePixiIcon extends React.ComponentPropsWithoutRef<'div'> {
  /** The icon will be rounded, in combination with button it will be a rounded button */
  bubble?: string | boolean;

  /** Name of the bootstrap icon */
  name?: keyof typeof IconsMap;

  /** The icon will be clickable  */
  button?: boolean;

  /** The icon will have branded background, or Pickit Pink in CAT. */
  primary?: boolean;

  /** The icon will get a disabled appearance. */
  disabled?: boolean;

  /** Draws attention to the icon with animations */
  highlight?: boolean;

  /** Element to be rendered instead of icon library */
  element?: JSX.Element;

  /** Adds a small badge to the icon. Used to demonstrate a value or attention. */
  badge?: string;

  /** Controls the size of the icon. For any other custom value, use font-size CSS */
  size?: 'tiny' | 'small' | 'large' | 'huge';
}

export default React.forwardRef<HTMLDivElement, InterfacePixiIcon>(
  (props, ref) => {
    function camelCaseToDash(str: string) {
      return str.replace(/([a-zA-Z])(?=[A-Z])/g, '$1-').toLowerCase();
    }

    const sizes = {
      tiny: 12,
      small: 15,
      large: 24,
      huge: 48,
    };

    const className = ['Pixi', 'Pixi__Icon'];

    if (props.bubble) {
      className.push('Pixi__Icon--clickable');

      if (props.bubble === 'large') {
        className.push('Pixi__Icon--largeBubble');
      }
    }
    if (props.button) {
      className.push('Pixi__Icon--button');
    }
    if (props.primary) {
      className.push('Pixi__Icon--primary');
      className.push('brand__color__bg');
    }
    if (props.disabled) {
      className.push('Pixi__Icon--disabled');
    }
    if (props.highlight) {
      className.push('Pixi__Icon--highlight');
    }

    if (props.className) {
      className.push(props.className);
    }

    const {
      name,
      color,
      style,
      size,
      highlight,
      badge,
      element,
      bubble,
      ...rest
    } = props;

    return (
      <div
        ref={ref}
        className={className.join(' ')}
        style={{
          fontSize: sizes[size as keyof typeof sizes],
          color,
          ...style,
        }}
        {...rest}
      >
        {highlight && (
          <div
            className="Pixi__Icon__highlight"
            style={{ background: props.style?.color || props.color }}
          />
        )}
        {badge ? (
          <div className="Pixi__Icon__badge brand__color__bg">{badge}</div>
        ) : (
          ''
        )}
        {element ||
          (name ? <i className={`bi-${camelCaseToDash(name)}`} /> : '')}
      </div>
    );
  },
);
