import { Image, ImageProps, ThemeIcon, ThemeIconProps } from '@mantine/core';
import useStorageUrl from 'hooks/files/useStorageUrl';
import useContextFromFile from 'hooks/useContextFromFile';
import { useState } from 'react';

export function useAssetThumbnail({
  file,
  size,
}: {
  file: Pickit.FileInterface;
  size?: 'large' | 'medium' | 'small' | 'tiny';
}) {
  const context = useContextFromFile(file);
  const { generateUrl } = useStorageUrl(context);
  const [isError, setIsError] = useState(false);

  let imageType: 'source' | 'preview' | 'icon' | 'svg' | 'blank' = 'source';
  let imageUrl =
    context?.fileIsPreviewable(file, true) &&
    file?.file?.contentLength &&
    file?.file?.contentLength < 2500000 &&
    !file?.file?.external_file
      ? generateUrl(file.file?.url)
      : null;

  const useOriginalFile =
    file?.file?.ext === 'svg' || file?.file?.ext === 'gif';

  if (useOriginalFile && !file?.file?.external_file) {
    imageType = file?.file?.ext === 'svg' ? 'svg' : 'source';
    imageUrl = generateUrl(file.file?.url);
  } else if (size && file?.file?.previews?.find((f) => f.name === size)?.url) {
    imageType = 'preview';
    imageUrl = generateUrl(
      file.file.previews?.find((f) => f.name === size)?.url ||
        file?.file?.previews?.[0]?.url,
    );
  } else if (!size && file?.file?.previews?.length) {
    imageType = 'preview';
    imageUrl = generateUrl(file.file.previews[0].url);
  }

  if (!imageUrl && file?.file?.previews?.length) {
    imageType = 'preview';
    imageUrl = generateUrl(file.file.previews[0].url);
  }

  if (
    !imageUrl &&
    !file?.file?.previews?.length &&
    file?.file?.ext === 'pptx' &&
    file?.file?.slides?.length &&
    file.file.slides?.[0].previews?.[0].url
  ) {
    imageType = 'preview';
    imageUrl = generateUrl(file.file.slides?.[0].previews?.[0].url);
  }

  if (!imageUrl) {
    imageType = 'icon';
    imageUrl = `https://embed.pickit.com/images/file-icons/${file.file?.ext || 'blank'}.svg`;
  }

  if (isError) {
    imageType = 'blank';
    imageUrl = `https://embed.pickit.com/images/file-icons/blank.svg`;
  }

  return {
    imageUrl,
    imageType,
  };
}
