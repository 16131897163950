import React from 'react';
import deepmerge from 'deepmerge';

export function useDeepState(defaultState, options) {
  const stateReducer = (state, update = {}) => {
    if (update !== null && update !== undefined) {
      update = deepmerge(state, update, options);
    }
    return { ...state, ...update };
  };
  return React.useReducer(stateReducer, defaultState);
}

export default useDeepState;
