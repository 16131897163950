import * as yup from 'yup';
import React from 'react';
import Icon from './icon.svg?react';

export default [
  {
    moduleId: 'RICH_TEXT',
    menuGroupIds: ['TEXT'],
    moduleInterface: {
      text: 'TEXT',
    },
    status: {
      isEmpty: (data) => {
        return !data?.text;
      },
    },
    moduleMetadataInterface: {
      noPadding: yup.boolean(),
      extraPadding: yup.boolean(),
      textAlign: yup.mixed().oneOf(['left', 'right', 'center']),
      titleSize: yup.mixed().oneOf(['small', 'medium', 'large']),
      textSize: yup.mixed().oneOf(['small', 'medium', 'large']),
      backgroundColor: yup.string(),
      textColor: yup.string(),
    },
    menuContent: { key: 'RICH_TEXT', icon: <Icon />, name: 'Rich text' },
  },
];
