import { useMediaContext } from 'hooks';
import { useFolders } from 'contexts/Folders';
import { Button, Flex, Dropdown, Icon } from 'elements_v2';
import React, { useEffect, useReducer, useState } from 'react';
import ColorForm from 'views/_Manage/BrandAssets/parts/forms/ColorForm';
import { uniqueArray } from 'utils';
import SectionActions from './SectionActions';

export default function AddColor(props) {
  const [colors, setColors] = useReducer(
    (state, action) => [...action],
    props.value || [],
  );
  const [isAddingNewColor, setIsAddingNewColor] = useState(false);
  const Brandassets = useMediaContext();

  useEffect(() => {
    if (Array.isArray(props.value)) {
      setColors(props.value);
    }
  }, [props.value]);

  if (props.onlyButton) {
    return (
      <Dropdown
        onChange={(colorId) => {
          setColors([...colors, { colorId }]);
          props.onChange([...colors, { colorId }]);
        }}
        noPopover={props.showDropdown}
        noTrigger={props.showDropdown}
        trigger={
          props.showDropdown
            ? undefined
            : props.trigger || (
                <Button inline pixi outline>
                  Select color
                </Button>
              )
        }
      >
        {uniqueArray(
          Brandassets.data.files?.filter(
            (file) =>
              file.data?.type === 'color' &&
              !props.value?.find((c) => c.colorId === file._id),
          ),
          '_id',
        ).map((color) => {
          return (
            <Dropdown.Option value={color._id} key={color._id}>
              <div>
                <Flex alignItems="center">
                  <div
                    style={{
                      width: 16,
                      height: 16,
                      background: color.data?.value?.color,
                      borderRadius: '50%',
                      marginRight: 10,
                      flexShrink: 0,
                    }}
                  />
                  {color.name}
                </Flex>
              </div>
            </Dropdown.Option>
          );
        })}
      </Dropdown>
    );
  }

  return (
    <SectionActions>
      <ColorForm
        onSave={(color) => setColors([...colors, { colorId: color._id }])}
        context={Brandassets}
        libraryId="brandassets"
        isOpen={isAddingNewColor}
        onClose={() => setIsAddingNewColor(false)}
      />
      <Dropdown
        onChange={(colorId) => setColors([...colors, { colorId }])}
        noPopover={props.showDropdown}
        trigger={
          <Button inline pixi outlined size="large">
            Select color
          </Button>
        }
      >
        {Brandassets.data.files
          ?.filter(
            (file) =>
              file.data?.type === 'color' &&
              !props.value?.find((c) => c.colorId === file._id),
          )
          .map((color) => {
            return (
              <Dropdown.Option value={color._id} key={color._id}>
                <div
                  style={{
                    width: 16,
                    height: 16,
                    background: color.data?.value,
                    borderRadius: '50%',
                    marginRight: 10,
                  }}
                />
                {color.name}
              </Dropdown.Option>
            );
          })}
      </Dropdown>
      <Button
        inline
        pixi
        outlined
        size="large"
        className="no_global_style"
        style={{ marginLeft: 10 }}
        onClick={() => setIsAddingNewColor(true)}
      >
        <Icon name="Palette2" />
        Add new color
      </Button>
    </SectionActions>
  );
}
