import { Icon } from 'elements_v2';
import { useRef } from 'react';
import Color from 'color';
import { PinkToolbar } from 'elements_v2/PinkToolbar';

export function ColorItem({
  showVariant,
  data,
  moduleData,
  onSort,
  metadata,
  Module,
  button,
  onRemove,
  onDisplayVariants,
}) {
  const colorRef = useRef();

  function renderColorInfo(name, color) {
    try {
      color = Color(color);
    } catch (e) {
      console.error(e);
    }
    return (
      <div
        className="ContentSection__color__info"
        style={{
          color:
            metadata?.colorInfo === 'outside'
              ? 'inherit'
              : color?.isDark?.() === true
                ? '#FFF'
                : color?.isDark?.() === false
                  ? '#000'
                  : '#FFF',
        }}
      >
        {button || (
          <>
            <div className="ContentSection__color__info__data">
              <div className="ContentSection__color__info__data__row">
                <span>{name}</span>
              </div>
              <div className="ContentSection__color__info__data__row">
                <span>HEX &nbsp;</span>
                <span>
                  {data?.data?.value?.color?.replace('#', '').toUpperCase()}
                </span>
              </div>
              <div className="ContentSection__color__info__data__row">
                <span>RGB &nbsp;</span>
                <span>{color.rgb?.().color.join(' ')}</span>
              </div>
              {data?.data?.value?.cmyk && (
                <div className="ContentSection__color__info__data__row">
                  <span>CMYK &nbsp;</span>
                  <span>{data?.data?.value?.cmyk}</span>
                </div>
              )}
              {data?.data?.value?.pantone && (
                <div className="ContentSection__color__info__data__row">
                  <span>PMS &nbsp;</span>
                  <span>{data?.data?.value?.pantone}</span>
                </div>
              )}
            </div>
          </>
        )}
      </div>
    );
  }

  const leftVariants = data?.data?.value?.variants?.slice(
    0,
    data?.data?.value?.variants?.indexOf('main'),
  );
  const rightVariants = data?.data?.value?.variants?.slice(
    data?.data?.value?.variants?.indexOf('main') + 1,
  );

  const className = ['ContentSection__color'];
  if (button && Module.isEditing) {
    className.push('ContentSection__color--button');
  }

  if (metadata?.colorNoSpace) {
    className.push('ContentSection__color--colorNoSpace');
  }

  if (metadata?.colorAlignment) {
    className.push(`ContentSection__color--${metadata?.colorAlignment}`);
  }

  if (metadata?.colorThumbnail) {
    className.push(`ContentSection__color--${metadata?.colorThumbnail}`);
  }

  return (
    <div
      className={className.join(' ')}
      ref={Module.isEditing ? colorRef : null}
      colorInfo={metadata?.colorInfo}
    >
      <div className="ContentSection__color__color">
        <div className="ContentSection__color__bg">
          {showVariant && !!leftVariants?.length && (
            <div className="ContentSection__color__bg__variants">
              {leftVariants.map((variant) => (
                <div
                  key={variant.color}
                  className="ContentSection__color__bg__variants__variant"
                  style={{
                    background: variant?.color,
                  }}
                >
                  {renderColorInfo(variant.name, variant.color)}
                </div>
              ))}
            </div>
          )}
          <div
            className="ContentSection__color__bg__color"
            style={{
              background:
                data?.data?.value?.color ||
                (Module?.isEditing ? '#EAEAEA' : undefined),
            }}
          >
            {(metadata?.colorInfo === 'overlay' ||
              !metadata?.colorInfo ||
              button) &&
              renderColorInfo(data?.name, data?.data?.value?.color)}
          </div>
          {showVariant && !!rightVariants?.length && (
            <div className="ContentSection__color__bg__variants">
              {rightVariants.map((variant) => (
                <div
                  key={variant.color}
                  className="ContentSection__color__bg__variants__variant"
                  style={{
                    background: variant?.color,
                  }}
                >
                  {renderColorInfo(variant.name, variant.color)}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      {data?._id && Module.isEditing && onRemove && (
        <PinkToolbar overlay parentRef={colorRef}>
          <Icon bubble button name="TrashFill" onClick={onRemove} />
          {!!data?.data?.value?.variants?.filter((v) => v !== 'main')
            ?.length && (
            <Icon bubble button name="Palette2" onClick={onDisplayVariants} />
          )}
        </PinkToolbar>
      )}
      {metadata?.colorInfo === 'outside' &&
        !button &&
        renderColorInfo(data?.name, data?.data?.value?.color)}
    </div>
  );
}
