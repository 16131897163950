import useLibraries from 'hooks/useLibraries';
import React from 'react';
// import "./style.scss";

export function useGetFileIdFromLibrary(library) {
  const Libraries = useLibraries();
  return (fileId) =>
    Libraries[library]?.data?.files?.find((f) => f._id === fileId);
}

export default function useParsedFile(props) {
  const [file, setFile] = React.useReducer(
    (state, action) => ({ ...action }),
    props.file || {}
  );
  React.useEffect(() => {
    if (!props?.file?._id || !props.context) {
      return;
    }
    if (props.context.data.files?.length) {
      setFile(
        props.context.data.files.find((file) => file._id === props.file._id) ||
          props.file
      );
    } else {
      setFile(props.file);
    }
  }, [props.context?.data?.files, props.file]);
  if (!props.context?.name) {
    return props?.file;
  }
  return file;
}
