import { Button, Flex, Icon, Input, Popup, Switch, Tooltip } from 'elements_v2';
import Card from 'elements_v2/Card';
import List from 'elements_v2/List';
import { useMediaContext, useUserContext } from 'hooks';
import React, { useEffect, useReducer, useState } from 'react';
import { slugify } from 'utils';

export function AttributeForm({
  onCancel,
  onSave,
  title,
  popup,
  trigger,
  addValue,
}) {
  const User = useUserContext();
  const Media = useMediaContext();
  const [newAttribute, setNewAttribute] = useReducer(
    (state, action) => ({ ...action }),
    {},
  );
  const [attributes, setAttributes] = useReducer(
    (state, action) => [...action],
    [],
  );
  const [isLoading, setIsLoading] = useState(false);

  async function getAttributes() {
    const data = await Media.request.getAttributes();
    setAttributes(data.attributes);
  }

  useEffect(() => {
    getAttributes();
  }, []);

  const keyExists =
    attributes.find((a) => a._id && a._id === newAttribute?.key) ||
    User.data.selectedCommunity?.settings?.attributes?.labels?.find(
      (a) => a.field === newAttribute?.key,
    );

  const form = (
    <>
      <Flex>
        <Input.Field
          style={{ margin: 0, marginRight: 10 }}
          value={newAttribute.label}
          autoFocus
          error={keyExists ? 'This metadata already exists.' : ''}
          label="Name"
          onChange={(val) => {
            setNewAttribute({
              ...newAttribute,
              label: val,
              key: slugify(val),
            });
          }}
        />
        {newAttribute?.key && (
          <Input.Field
            style={{ margin: 0, marginRight: 10 }}
            label="Attribute key"
            value={newAttribute.key}
            disabled
          />
        )}
        {addValue && (
          <Input.Field
            style={{ margin: 0 }}
            value={newAttribute.value}
            autoFocus
            label="Name"
            onChange={(val) => {
              setNewAttribute({
                ...newAttribute,
                value: val,
              });
            }}
          />
        )}
      </Flex>
      <Flex style={{ marginTop: 15 }}>
        <Input.Field
          label="Searchable"
          description="This attribute on files will be searchable"
          custom={
            <Switch
              label="Enabled"
              active={newAttribute.searchable}
              onChange={(val) => {
                setNewAttribute({
                  ...newAttribute,
                  searchable: val,
                });
              }}
            />
          }
        />
        <Input.Field
          label="Show on file preview"
          description="The attribute and its value will be visible when previewing files using it"
          custom={
            <Switch
              label="Enabled"
              active={newAttribute.previewable}
              onChange={(val) => {
                setNewAttribute({
                  ...newAttribute,
                  previewable: val,
                });
              }}
            />
          }
        />
      </Flex>
    </>
  );
  const Actions = ({ onClose, flex }) => (
    <Flex width="100%" justifyContent="space-between">
      <Button
        inline
        pixi={flex}
        onClick={() => {
          setNewAttribute({});
          onClose?.();
        }}
      >
        Cancel
      </Button>
      <Button
        inline
        primary
        pixi={flex}
        loading={isLoading}
        disabled={keyExists || !newAttribute?.key}
        onClick={async () => {
          setIsLoading(true);
          const searchable =
            User.data.selectedCommunity?.settings?.attributes?.searchable || [];
          const previewable =
            User.data.selectedCommunity?.settings?.attributes?.previewable ||
            [];
          const labels =
            User.data.selectedCommunity?.settings?.attributes?.labels || [];
          await User.saveSettings({
            attributes: {
              searchable: newAttribute?.searchable
                ? [...searchable, newAttribute.key]
                : searchable,
              previewable: newAttribute?.previewable
                ? [...previewable, newAttribute.key]
                : previewable,
              labels: [
                ...labels,
                {
                  field: newAttribute?.key,
                  label: newAttribute?.label,
                  isCustom: true,
                },
              ],
            },
          });
          setNewAttribute({});
          onSave?.(newAttribute?.key, newAttribute?.value);
          onClose?.();
          setIsLoading(false);
        }}
      >
        Save new attribute
      </Button>
    </Flex>
  );

  if (popup) {
    return (
      <Popup
        width={700}
        title={title}
        trigger={trigger}
        actions={(isOpen, setIsOpen) => (
          <Actions
            flex
            onClose={() => {
              onCancel?.();
              setIsOpen(false);
            }}
          />
        )}
      >
        {form}
      </Popup>
    );
  }
  return (
    <>
      {form}
      <Flex justifyContent="space-between" style={{ marginTop: 10 }}>
        <Actions onClose={onCancel} />
      </Flex>
    </>
  );
}

export default function FileAttributes({ metadata, onChange, onRemove }) {
  const User = useUserContext();
  const [isAllMetadata, setIsAllMetadata] = useState(true);
  const [isAddNewAttribute, setIsAddNewAttribute] = useState(false);
  const [newAttribute, setNewAttribute] = useReducer(
    (state, action) => ({ ...action }),
    {},
  );
  const [editing, setEditing] = useReducer((state, action) => {
    if (state.includes(action)) {
      return state.filter((key) => key !== action);
    }
    return [...state, action];
  }, []);

  const attributes = {
    ...(metadata.attributes || {}),
  };

  const searchable =
    User.data?.selectedCommunity?.settings?.attributes?.searchable || [];
  const previewable =
    User.data?.selectedCommunity?.settings?.attributes?.previewable || [];
  const labels =
    User.data?.selectedCommunity?.settings?.attributes?.labels || [];

  [...searchable, ...previewable].map((key) => {
    if (!attributes[key]) {
      attributes[key] = '_no_value_';
    }
  });

  labels
    .filter(
      (label) =>
        !previewable.includes(label.field) &&
        !searchable.includes(label.field) &&
        !metadata.attributes?.[label.field],
    )
    .forEach((label) => {
      attributes[label.field] = '_no_value_';
    });

  return (
    <>
      {isAddNewAttribute && (
        <div
          style={{
            boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.08)',
            padding: 15,
            marginBottom: 35,
          }}
        >
          <AttributeForm
            addValue
            onCancel={() => setIsAddNewAttribute(false)}
            onSave={(key, value) => {
              onChange(key, value);
              setNewAttribute({});
              setIsAddNewAttribute(false);
            }}
          />
        </div>
      )}
      <Flex style={{ marginBottom: 10 }} alignItems="center">
        <Switch
          active={isAllMetadata}
          onChange={setIsAllMetadata}
          label="Show all metadata"
        />
      </Flex>
      <List cards compact>
        {Object.keys(attributes)
          .filter((key) => {
            const value = attributes[key];
            if (!value) {
              return false;
            }
            if (!isAllMetadata) {
              return (
                User.data?.selectedCommunity?.settings?.attributes?.searchable?.includes(
                  key,
                ) ||
                User.data?.selectedCommunity?.settings?.attributes?.previewable?.includes(
                  key,
                )
              );
            }
            return true;
          })
          .map((attribute) => (
            <List.Item
              key={attribute}
              title={
                <Flex alignItems="center">
                  {User.data?.selectedCommunity?.settings?.attributes?.labels?.find(
                    (data) => data.field === attribute,
                  )?.label || attribute}
                  <small style={{ opacity: 0.5, marginLeft: 10 }}>
                    ({attribute})
                  </small>
                </Flex>
              }
              subtitleStyle={{ opacity: 1 }}
              subtitle={
                attributes[attribute] === '_no_value_' &&
                !editing.includes(attribute) ? (
                  <i>No value</i>
                ) : editing.includes(attribute) ? (
                  <>
                    <Flex>
                      <Input.Field
                        autoFocus
                        placeholder="Add metadata value"
                        value={
                          attributes[attribute] === '_no_value_'
                            ? ''
                            : attributes[attribute]
                        }
                        onKeyDown={(event) => {
                          if (event.keyCode === 13) {
                            setEditing(attribute);
                          }
                        }}
                        onChange={(value) => {
                          onChange(attribute, value);
                        }}
                      />
                      <Button
                        inline
                        onClick={() => setEditing(attribute)}
                        style={{ whiteSpace: 'nowrap', marginLeft: 5 }}
                        primary
                        type="submit"
                      >
                        Save metadata
                      </Button>
                    </Flex>
                  </>
                ) : (
                  attributes[attribute]
                )
              }
              actions={
                editing.includes(attribute) ? (
                  <></>
                ) : (
                  <Flex>
                    <Icon
                      name={
                        editing.includes(attribute)
                          ? 'check2'
                          : attributes[attribute] === '_no_value_'
                            ? 'pencil'
                            : 'pencil'
                      }
                      bubble
                      button
                      onClick={() => setEditing(attribute)}
                    />
                    {attributes[attribute] !== '_no_value_' && (
                      <Icon
                        name="trash"
                        bubble
                        button
                        onClick={() => {
                          onRemove(attribute);
                        }}
                      />
                    )}
                  </Flex>
                )
              }
            />
          ))}
      </List>
    </>
  );
}
