import { Group, Paper, TextInput } from '@mantine/core';
import PixiDropdown from './elements/Dropdown';
import AppInformationDropdown from './components/AppInformationDropdown';
import System from './System';
import PixiTooltip from './elements/Tooltip';
import { useRouter } from 'hooks/useRouter';
import { useAppUser } from './AppUserController';
import { useUserContext } from 'hooks';
import { useState } from 'react';
import localStorageWrapper from 'utils/localStorageWrapper';
import { useAppContext } from 'contexts/Providers/AppProvider';
import PixiText from './elements/Text';
import PixiButton from './elements/Button';

export default function AppPreviewBanner() {
  const Router = useRouter();
  const User = useUserContext();
  const appUser = useAppUser();
  const App = useAppContext();
  const [curatorLink, setCuratorLink] = useState('');
  if (
    !appUser.user?.djangoProfile?.profile?.is_staff &&
    !window.location.origin?.includes('localhost') &&
    !App.isDevToolsOpen
  ) {
    return <></>;
  }
  return (
    <PixiDropdown
      width={400}
      openDelay={1000}
      forceOpen={App.isDevToolsOpen}
      onOpen={() => {
        App.setIsDevToolsOpen(true);
      }}
      closeOnClickOutside={false}
      trigger="hover"
      target={
        <Paper
          w={40}
          h={40}
          pos="fixed"
          bg="transparent"
          style={{ zIndex: 9999, pointerEvents: 'auto' }}
          bottom={0}
          p={5}
          fw="bold"
          left={0}
          fz={10}
        />
      }
      closeOnItemClick={false}
      position="top"
    >
      <AppInformationDropdown />
      <PixiDropdown.Divider />
      <PixiDropdown.Label>Connect as curator</PixiDropdown.Label>
      <PixiDropdown.Item icon="unlock">
        <form
          method="POST"
          onSubmit={async (event) => {
            event.preventDefault();
            event.stopPropagation();
            localStorageWrapper.clear();
            const url = new URL(curatorLink);
            const searchParams = new URLSearchParams(
              url.hash.replace('#/', ''),
            );
            const token = searchParams.get('auth');
            await User.connectToken(
              token,
              `${Router.location.pathname}`,
              searchParams.get('community'),
            );
            window.location.reload();
          }}
        >
          <TextInput
            placeholder="Paste link from portal"
            value={curatorLink}
            onChange={(event) => {
              setCuratorLink(event.currentTarget?.value);
            }}
          />
        </form>
      </PixiDropdown.Item>
      <PixiDropdown.Divider />
      <PixiDropdown.Label>Developer tools</PixiDropdown.Label>
      {/* <PixiDropdown.Item
        icon="bug"
        onClick={() => {
          Router.history.push('/manage/experimental');
        }}
      >
        Experimental features
      </PixiDropdown.Item> */}
      <PixiTooltip label="Onboarding cards, tooltips etc.">
        <PixiDropdown.Item
          icon="circle-info"
          color="orange"
          onClick={async () => {
            await User.saveUserSettingsPartial({
              [`flags`]: {},
            });
          }}
        >
          Clear all onboarding flags
        </PixiDropdown.Item>
      </PixiTooltip>
      <PixiButton
        fullWidth
        variant="light"
        onClick={() => {
          App.setIsDevToolsOpen(false);
        }}
      >
        Close dev tools
      </PixiButton>
      {/* <PixiDropdown.Item
        icon="exclamation"
        color="red"
        onClick={async () => {
          await System.Report.logError(new Error('Fake error'));
        }}
      >
        Report fake error
      </PixiDropdown.Item> */}
    </PixiDropdown>
  );
}
