import { MouseEvent, useEffect, useRef } from 'react';

export const useClickOutside = (
  handler: (event: Event, node: Element) => void,
) => {
  const ref = useRef<HTMLDivElement>(null);
  const handleClickOutside: EventListenerOrEventListenerObject = (
    event: Event,
  ) => {
    if (
      ref?.current &&
      event?.target &&
      !ref?.current?.contains?.(event?.target as Element)
    ) {
      handler?.(event, event?.target as Element);
    }
  };

  useEffect(() => {
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });

  return ref;
};
