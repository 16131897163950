import React, { cloneElement, lazy } from 'react';

interface InterfacePixiFlagIcon extends React.ComponentPropsWithoutRef<'div'> {
  /** Name of the bootstrap icon */
  name: string;
}

export default React.forwardRef<HTMLImageElement, InterfacePixiFlagIcon>(
  ({ name, ...rest }, ref) => {
    if (!name) {
      return <></>;
    }
    return (
      <img
        {...rest}
        alt=""
        ref={ref}
        src={`https://embed.pickit.com/images/flags/${name}.svg`}
      />
    );
  },
);
