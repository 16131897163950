import React from 'react';
import { Icon, Input } from 'elements_v2';

const Thumbnail = ({
  id,
  url,
  data,
  index,
  moveCard,
  onDelete,
  disableRearrange,
  onChange,
  thumbnails,
  isAliasEditable,
  disableDelete,
  showAlias,
  CustomAction,
}) => {
  const ref = React.useRef(null);
  return (
    <div className="Files__Thumbnails__thumbnail" ref={ref}>
      <div className="Files__Thumbnails__thumbnail__content">
        <img
          alt=""
          src={url}
          style={{
            width: 248,
            height: 139,
            objectFit: 'cover',
            marginBottom: 5,
          }}
        />
        {showAlias && <p>{data.alias}</p>}
        {isAliasEditable && (
          <Input.Field
            placeholder="Name"
            value={data.alias}
            white
            onChange={(value) =>
              onChange(
                thumbnails.map((thumbnail) => {
                  if (data.id === thumbnail.id) {
                    thumbnail.alias = value;
                  }
                  return thumbnail;
                }),
              )
            }
          />
        )}
        <div className="Files__Thumbnails__thumbnail__toolbar">
          {!disableRearrange && (
            <a style={{ cursor: isDragging ? 'grabbing' : 'grab' }}>
              <Icon name="ArrowsMove" />
              Rearrange
            </a>
          )}
          {!disableDelete && (
            <a style={{ margin: 'auto' }} onClick={() => onDelete(data.id)}>
              <Icon name="Trash" />
              Delete
            </a>
          )}
          {CustomAction && <CustomAction file={data} />}
        </div>
      </div>
    </div>
  );
};

function array_move(arr, old_index, new_index) {
  if (new_index >= arr.length) {
    let k = new_index - arr.length + 1;
    while (k--) {
      arr.push(undefined);
    }
  }
  arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
  return arr; // for testing
}
function Thumbnails(props) {
  const [thumbnails, setThumbnails] = React.useReducer(
    (state, action) => [...action],
    props.thumbnails || [],
  );

  React.useEffect(() => {
    setThumbnails(props.thumbnails);
  }, [props.thumbnails]);

  const moveCard = React.useCallback(
    (dragIndex, hoverIndex) => {
      props.onChange([...array_move(thumbnails, dragIndex, hoverIndex)]);
      setThumbnails([...array_move(thumbnails, dragIndex, hoverIndex)]);
    },
    [thumbnails],
  );

  return (
    <div className="Files__Thumbnails">
      {thumbnails.map((thumb, index) => (
        <Thumbnail
          key={thumb.id}
          url={thumb.url}
          index={index}
          thumbnails={props.thumbnails}
          onChange={props.onChange}
          data={thumb}
          onDelete={props.onDelete}
          isAliasEditable={props.isAliasEditable}
          disableRearrange={props.disableRearrange}
          disableDelete={props.disableDelete}
          moveCard={moveCard}
          showAlias={props.showAlias}
          CustomAction={props.CustomAction}
        />
      ))}
    </div>
  );
}

export default Thumbnails;
