import { getStore, useKeySelector } from '@pixi/store';
import useContextFromFile from 'hooks/useContextFromFile';
import { useRouter } from 'hooks/useRouter';
import { createContext, useContext, useState } from 'react';
import { useAssetActions } from '@pixi/components/AssetActions/useAssetActions';

export interface AssetEditPanelContextInterface {
  file: Pickit.FileInterface;
  fileToolbar: ReturnType<typeof useAssetActions>;
  files: Pickit.FileInterface[];
  onClose?: () => void;
  onHide?: (isForceHidden?: boolean) => void;
  disable?: 'header'[];
  scanMetadata: () => Promise<unknown>;
  scanMetadataProgress: {
    current: number;
    total: number;
    percentage: number;
  };
  focus?: 'feedback' | 'approvals';
  context: ReturnType<typeof useContextFromFile>;
  isBrand?: boolean;
  disabledSections?: (
    | 'file'
    | 'approvals'
    | 'license'
    | 'feedback'
    | 'metadata'
    | 'collections'
    | 'embed'
    | 'thumbnail'
    | 'version'
    | 'uploaded'
    | 'sharing'
    | 'variants'
    | 'usage'
  )[];
}

export const AssetEditPanelContext =
  createContext<AssetEditPanelContextInterface>(
    {} as AssetEditPanelContextInterface,
  );

export function useAssetEditPanel() {
  return useContext(AssetEditPanelContext);
}
export default function AssetEditPanelProvider({
  children,
  files,
  onClose,
  onHide,
  disable,
  focus,
}: {
  children: React.ReactNode;
  files: Pickit.FileInterface[];
  onClose?: () => void;
  onHide?: (isForceHidden?: boolean) => void;
  disable?: AssetEditPanelContextInterface['disable'];
  focus?: AssetEditPanelContextInterface['focus'];
}) {
  const Router = useRouter();
  const [scanMetadataProgress, setScanMetadataProgress] = useState({
    current: 0,
    total: 0,
    percentage: 0,
  });
  const file = useKeySelector('FILES', files?.[0]?._id) || files?.[0];
  const fileToolbar = useAssetActions({
    file,
  });
  const context = useContextFromFile(file);

  async function scanMetadata() {
    return new Promise((resolve) => {
      context.request.generate(file?._id, 'metadata', {
        onProgress: (status: any) => {
          if (status.progress?.current) {
            setScanMetadataProgress({
              current: status.progress?.current,
              total: status.progress?.total,
              percentage:
                100 * (status.progress?.current / status.progress?.total),
            });
          }
          if (status.code === 'COMPLETED' && status.file) {
            context.stores.setData({
              store: 'files',
              type: 'update',
              key: '_id',
              data: status.file,
            });
            getStore('FILES').addOrUpdate(status.file);
            resolve(true);
            setScanMetadataProgress({
              current: 0,
              total: 0,
              percentage: 0,
            });
          }
        },
        onClose: () => {},
      });
    });
  }

  return (
    <AssetEditPanelContext.Provider
      value={{
        file: file || files[0],
        fileToolbar,
        files: files?.length === 1 ? [] : files,
        onClose,
        onHide,
        scanMetadata,
        scanMetadataProgress,
        context,
        disable,
        disabledSections: file?.data?.type
          ? [
              'approvals',
              'metadata',
              'license',
              'sharing',
              'collections',
              'embed',
              'feedback',
              'thumbnail',
              'version',
              'uploaded',
              'file',
              'variants',
              'usage',
            ]
          : [],
        focus: focus || undefined,
        isBrand: Router.location.pathname.includes('/manage/library/brand'),
      }}
    >
      {children}
    </AssetEditPanelContext.Provider>
  );
}
