import React from 'react';
import { useUserContext } from 'hooks';
import { Button, Tooltip } from 'elements_v2';
import { useRouter } from 'hooks/useRouter';
import useCloudStorage from 'hooks/useCloudStorage';
// import useRouterQuery from 'hooks/useRouterQuery';
import { analytics } from 'services';
import { MediaContext } from 'contexts/Media';
import { TutorialContext } from 'contexts/Tutorial';
// import { DocumentsContext } from './Documents';

export function TutorialProvider(props) {
  const User = useUserContext();
  const Media = React.useContext(MediaContext);
  const Router = useRouter();
  const cloudStorage = useCloudStorage();
  // const location = useRouterQuery();
  // const Documents = React.useContext(DocumentsContext);

  // const isReady = Media.isInitialized && Documents.isInitialized && !Media.isDown && !Documents.isDown;
  // const noCollections = isReady && !Documents.data.collections?.length && !Media.data.collections?.length;
  // const noDocuments = isReady && !Documents.data?.['files-count'] && !Documents.data.files?.length;
  // const noMedia = isReady && !Media.data?.['files-count'] && !Media.data.files?.length;
  // const isUploadingFirstDocument = noDocuments && (!!Documents.data?.['processing-files']?.length || !!Documents.data?.['uploading-files']?.length);
  // const isUploadingFirstMedia = noMedia && (!!Media.data?.['processing-files']?.length || !!Media.data?.['uploading-files']?.length);

  // const scenarios = {
  //   noCollections,
  //   noDocuments,
  //   noMedia,
  //   isUploadingFirstDocument,
  //   isUploadingFirstMedia
  // };

  const tutorialsCompleted = User.cloudStorage?.tutorialCompleted;
  const walkthroughCompleted = tutorialsCompleted?.walkthrough ?? true;

  React.useEffect(() => {
    if (
      !walkthroughCompleted &&
      User.cloudStorage?.manage?.walkthrough_started
    ) {
      analytics.trackEvent('CAT Quick Tour Started');
    }
  }, [User.cloudStorage?.manage?.walkthrough_started]);

  const tutorialData = {
    autoTag: {
      title: 'Save time with AI',
      description: 'Automatically tag your assets while uploading.',
      delay: 2000,
    },
    pickitMedia: {
      title: 'Access a million images',
      description:
        'Use unlimited content from the Pickit Stock library, all 100% legal and licensed to use for anything.',
      delay: 0,
      skipTrigger: true,
      position: 'bottom-center',
    },
    mediaMarkets: {
      title: 'Access more content',
      description:
        'Find and add media assets to your Pickit directly from other media markets',
      delay: 0,
      skipTrigger: true,
      position: 'bottom-center',
    },
    otherSources: {
      title: 'Synch, manage, share',
      description:
        'With Pickit you can access and control all your content — no matter where you have it stored',
      delay: 0,
      skipTrigger: true,
      position: 'bottom-center',
    },
    pickitMediaSearch: {
      title: 'Search it in seconds',
      description:
        'Use keywords to find content you can add to your media library.',
      delay: 0,
    },
    pickitMediaBrowse: {
      title: 'Have a look around',
      description:
        'Not sure what you’re after or what to add? Browse Pickit’s themed collections for inspiration.',
      delay: 0,
      position: 'top-end',
    },
    pickitMediaImport: {
      title: 'Click + to add a file',
      description: 'Add as many files as you like to your own media library.',
      delay: 2500,
      position: 'bottom-start',
    },
    collectionTemplates: {
      title: 'Best practise samples',
      description:
        'Choose a curation template based on your needs. Use the template as a starting point for your collections.',
      delay: 2500,
      position: 'bottom-center',
    },
    gettyImages: {
      title: 'Getty Images in Pickit',
      description: (
        <>
          With a Getty Images Premium Subscription you can access your download
          history, find and add new media assets.
          <br />
          &nbsp;
          <br />
          Import to your Pickit (Company Media) and share with your colleagues
        </>
      ),
      delay: 2500,
      position: 'bottom-start',
    },
    dropbox: {
      title: 'Access your Dropbox',
      description: (
        <>
          Connect to your Dropbox Business account and easily add any of your
          Dropbox files to your Pickit collections
        </>
      ),
      delay: 2500,
      position: 'bottom-start',
    },
  };

  const walkthroughData = {
    media: {
      short_title: 'Media',
      title: 'Add Media',
      message: (
        <>
          Upload, add, find and manage all your media content here.
          <br />
          &nbsp;
          <br />
          All assets are safely stored and can be shared as you want. Never
          worry about back up and lost content.
        </>
      ),
      active:
        User.cloudStorage?.manage?.walkthrough_started &&
        !walkthroughCompleted.media,
      completed: !!walkthroughCompleted.media,
    },
    documents: {
      short_title: 'Documents',
      title: 'Add Documents',
      message:
        'This is where you keep all your documents and templates safe and up to date.',
      active: !!walkthroughCompleted.media && !walkthroughCompleted.documents,
      completed: !!walkthroughCompleted.documents,
    },
    curation: {
      short_title: 'Curation',
      title: 'Curate content',
      message: (
        <>
          This feature is included for both Media and Documents and it helps you
          to share your content the best way.
          <br />
          &nbsp;
          <br />
          Create collections and customize your users’ frontpage experience in
          Office and on the web.
        </>
      ),
      active:
        !!walkthroughCompleted.media &&
        !!walkthroughCompleted.documents &&
        !walkthroughCompleted.curation,
      completed: !!walkthroughCompleted.curation,
    },
    guidelines: {
      short_title: 'Guidelines',
      title: 'Create guidelines',
      message: (
        <>
          Have your brand book or style guide in a pdf-file somewhere?
          <br />
          &nbsp;
          <br />
          This is where you share your guidelines, tips and manuals to help your
          team communicate and present with brand consistency.
        </>
      ),
      active:
        !!walkthroughCompleted.media &&
        !!walkthroughCompleted.documents &&
        !!walkthroughCompleted.curation &&
        !walkthroughCompleted.guidelines,
      completed: !!walkthroughCompleted.guidelines,
    },
    insights: {
      short_title: 'Insights',
      title: 'Use insights',
      message: (
        <>
          See what content people are searching for and use.
          <br />
          &nbsp;
          <br />
          The Insights Dashboard provides actionable analytics to help you
          understand what kind of content people want and need.
        </>
      ),
      active:
        !!walkthroughCompleted.media &&
        !!walkthroughCompleted.documents &&
        !!walkthroughCompleted.curation &&
        !!walkthroughCompleted.guidelines &&
        !walkthroughCompleted.insights,
      completed: !!walkthroughCompleted.insights,
    },
    users: {
      short_title: 'Users',
      title: 'Manage users',
      message:
        'Find, manage and invite users to your Pickit. Set roles and create user groups to control specific access rights for your content.',
      active:
        !!walkthroughCompleted.media &&
        !!walkthroughCompleted.documents &&
        !!walkthroughCompleted.curation &&
        !!walkthroughCompleted.guidelines &&
        !!walkthroughCompleted.insights &&
        !walkthroughCompleted.users,
      completed: !!walkthroughCompleted.users,
    },
    home: {
      title: 'Start here',
      short_title: 'Home',
      message: (
        <>
          Get a good overview and status.
          <br />
          &nbsp;
          <br />
          Use the checklist to maximize the usage and value of this system.
        </>
      ),
      active:
        !!walkthroughCompleted.media &&
        !!walkthroughCompleted.documents &&
        !!walkthroughCompleted.curation &&
        !!walkthroughCompleted.guidelines &&
        !!walkthroughCompleted.insights &&
        !!walkthroughCompleted.users &&
        !walkthroughCompleted.home,
      completed: !!walkthroughCompleted.home,
      nextButton: 'Complete tour',
      nextButtonOnClick: () => Router.history.push('/manage/home'),
    },
  };

  function renderWalkthrough(key, trigger) {
    const data = walkthroughData[key];

    if (!data.active) {
      return trigger;
    }
    return (
      <Tooltip.Walkthrough
        position="right-center"
        delay={0}
        isOpen={data.active}
        withDimmer
        trigger={trigger}
      >
        {(isOpen, setIsOpen) => (
          <>
            <Tooltip.Walkthrough.Information>
              <Tooltip.Walkthrough.Title>
                {walkthroughData[key].short_title}
              </Tooltip.Walkthrough.Title>
              <Tooltip.Walkthrough.Description>
                {walkthroughData[key].message}
              </Tooltip.Walkthrough.Description>
            </Tooltip.Walkthrough.Information>
            <Tooltip.Walkthrough.List
              title="Pickit Quick Tour"
              steps={walkthroughData}
            />
            <Tooltip.Walkthrough.Actions>
              {/* <Button inline primary transparent>Learn more</Button> */}
              <Button
                pixi
                inline
                onClick={() => {
                  cloudStorage.set(
                    'tutorialCompleted.walkthrough',
                    Object.keys(walkthroughData).reduce(
                      (obj, key) => ({ ...obj, [key]: true }),
                      {},
                    ),
                  );
                  // Object.keys(walkthroughData).map(key => closeTutorial(`walkthrough.${key}`))
                }}
              >
                Skip tour
              </Button>
              <div>
                <Button
                  primary
                  pixi
                  inline
                  onClick={() => {
                    setIsOpen(false);
                    closeTutorial(`walkthrough.${key}`);
                    if (data.nextButtonOnClick) {
                      data.nextButtonOnClick();
                    }
                  }}
                >
                  {data.nextButton || 'Next'}
                </Button>
              </div>
            </Tooltip.Walkthrough.Actions>
          </>
        )}
      </Tooltip.Walkthrough>
    );
  }

  function render(key, trigger, forceActive) {
    return (
      <Tooltip.Tutorial
        // triggerRender={
        //   tutorialData[key].skipTrigger
        //     ? box => (
        //       <>
        //         {trigger}
        //         {box}
        //       </>
        //     )
        //     : false
        // }
        position={tutorialData[key].position}
        delay={tutorialData[key].delay}
        isOpen={forceActive || isTutorialActive(key)}
        trigger={trigger}
        key={key}
      >
        <Tooltip.Tutorial.Title key={tutorialData[key].title}>
          {tutorialData[key].title}
        </Tooltip.Tutorial.Title>
        <Tooltip.Tutorial.Description key={tutorialData[key].description}>
          {tutorialData[key].description}
        </Tooltip.Tutorial.Description>
        <Tooltip.Tutorial.Actions
          closeButton
          onClose={() => closeTutorial(key)}
          key={`${tutorialData[key].title}close`}
        />
      </Tooltip.Tutorial>
    );
  }

  function isTutorialActive(key) {
    if (
      key === 'autoTag' &&
      Media.scenarios.noFiles &&
      !Media.scenarios.isUploadingFirstFile
    ) {
      return !User.cloudStorage.tutorialCompleted?.autoTag;
    }
    if (
      key === 'curation' &&
      tutorialsCompleted.autoTag &&
      tutorialsCompleted.pickitMedia
    ) {
      return !User.cloudStorage.tutorialCompleted?.curation;
    }
    if (key === 'pickitMedia' && tutorialsCompleted.autoTag) {
      return !User.cloudStorage.tutorialCompleted?.pickitMedia;
    }
    // if (key === 'mediaMarkets' && tutorialsCompleted.pickitMedia) {
    //   return !User.cloudStorage.tutorialCompleted?.mediaMarkets;
    // }
    // if (key === 'otherSources' && tutorialsCompleted.mediaMarkets) {
    //   return !User.cloudStorage.tutorialCompleted?.otherSources;
    // }
    // if (key === 'gettyImages' && location.params.preview === 'getty-images') {
    //   return !User.cloudStorage.tutorialCompleted?.gettyImages;
    // }
    // if (key === 'dropbox' && location.params.preview === 'dropbox') {
    //   return !User.cloudStorage.tutorialCompleted?.dropbox;
    // }
    // if (key === 'pickitMediaSearch') {
    //   return !tutorialsCompleted.pickitMediaSearch;
    // }
    // if (key === 'pickitMediaBrowse' && tutorialsCompleted.pickitMediaSearch) {
    //   return !tutorialsCompleted.pickitMediaBrowse;
    // }
    // if (key === 'pickitMediaImport') {
    //   return !tutorialsCompleted.pickitMediaImport;
    // }
    // if (key === 'collectionTemplates' && !Media.scenarios.noFiles) {
    //   return !tutorialsCompleted.collectionTemplates;
    // }
    return false;
  }

  function closeTutorial(key, value) {
    User.setCloudStorage(`tutorialCompleted.${key}`, value || true);
  }

  const values = {
    // scenarios,
    renderWalkthrough,
    closeTutorial,
    isTutorialActive,
    render,
    walkthrough: walkthroughData,
  };

  return (
    <TutorialContext.Provider value={values}>
      {props.children}
    </TutorialContext.Provider>
  );
}
