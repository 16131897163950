import React, { useEffect, useReducer, useState } from 'react';
import { differenceInHours, isAfter } from 'date-fns';
import { useDocumentsContext } from 'hooks/useDocumentsContext';
import { useMediaContext } from 'hooks/useMediaContext';
import { useUserContext } from 'hooks/useUserContext';

import asyncPool from 'utils/async-pool';
import { truncate } from 'utils';
import System from '@pixi/System';

export default function useSharepointExport(props) {
  const { Sharepoint } = props;
  const DAILY_SYNC_INTERVAL_HOURS = 4;
  const Media = useMediaContext();
  const Documents = useDocumentsContext();
  const User = useUserContext();
  const [rawJobs, setRawJobs] = useReducer((state, action) => [...action], []);
  const [jobs, setJobs] = useReducer((state, action) => {
    if (Array.isArray(action)) {
      return [...action];
    }
    if (action?.type === 'UPDATE_STATUS') {
      return state.map((j) =>
        j._id === action.jobId
          ? { ...j, status: { ...j.status, ...action.status } }
          : j,
      );
    }
    if (action?.type === 'UPDATE_FILE_STATUS') {
      return state.map((j) =>
        j._id === action.jobId
          ? {
              ...j,
              status: {
                ...j.status,
                files: {
                  ...(j.status?.files || {}),
                  [action.fileId]: action.status,
                },
              },
            }
          : j,
      );
    }
  }, []);

  const [forceJob, setForceJob] = useReducer(
    (state, action) => [...action],
    [],
  );
  const [jobQueue, setJobQueue] = useState([]);
  const [isRunningJobs, setIsRunningJobs] = useState(false);
  const [isLoadingJobs, setIsLoadingJobs] = useState(false);
  const [isDisconnectedWarning, setIsDisconnectedWarning] = useState(false);

  useEffect(() => {
    if (jobs.length) {
      setJobs(
        jobs.map((job) => {
          return {
            ...job,
          };
        }),
      );
    }
  }, [Media?.data?.collections?.length, Documents?.data?.collections?.length]);

  useEffect(() => {
    if (jobQueue?.length && !isRunningJobs) {
      runJobQueue();
    }
  }, [jobQueue, isRunningJobs]);

  async function parseJobs() {
    if (jobs.length && Sharepoint.isConnected) {
      const notChecked = jobs.filter((job) => {
        return (
          (!job.status || (!job.status?.isRunning && !job.status?.isChecked)) &&
          !jobQueue.includes(job._id) &&
          (job.occurence === 'daily' || !job.lastRun) &&
          job.status?.type !== 'ERROR' &&
          differenceInHours(new Date(), new Date(job.lastRun)) >=
            DAILY_SYNC_INTERVAL_HOURS
        );
      });
      setJobQueue([...jobQueue, ...notChecked.map((job) => job._id)]);
      setIsDisconnectedWarning(false);
    }
    if (
      !Sharepoint.isConnected &&
      !Sharepoint.isConnecting &&
      !isLoadingJobs &&
      !!rawJobs.filter((job) => job.userId === User.djangoProfile.id)?.length
    ) {
      setIsDisconnectedWarning(true);
    }
  }

  async function queueJob(job, force) {
    if (!jobQueue.includes(job._id)) {
      setJobQueue([...jobQueue, job._id]);
    }
    if (force && !forceJob.includes(job._id)) {
      setForceJob([...forceJob, job._id]);
    }
  }

  async function runJobQueue() {
    const firstJob = jobQueue?.[0];
    if (isRunningJobs) {
      return false;
    }
    if (!firstJob) {
      setIsRunningJobs(false);
      return false;
    }
    const activeJob = jobs.find((job) => job._id === firstJob);
    if (activeJob.status?.isRunning) {
      setJobQueue(jobQueue.filter((j, key) => !!key));
      return false;
    }
    setJobQueue((jobQueue) => jobQueue.filter((j, key) => !!key));
    setIsRunningJobs(true);
    await runJob(activeJob);
    setIsRunningJobs(false);
  }

  async function getJobs() {
    setIsLoadingJobs(true);
    try {
      let data = await User.request.files.export.getJobs('sharepoint-export');
      data = {
        ...data,
        jobs: data.jobs.filter((job) => job.destination.driveId !== 'dropbox'),
      };
      setRawJobs(data.jobs);
      const jobsToAdd = [];
      await asyncPool(10, data.jobs, async (job) => {
        try {
          const Library =
            job.library === 'media'
              ? Media
              : job.library === 'documents'
                ? Documents
                : null;
          let site;
          let drive;
          let folder;
          if (job.destination.siteId && job.destination.siteId !== 'dropbox') {
            site = await Sharepoint.getSite(job.destination.siteId);
            if (site?.error) {
              job.error = {
                code: 'SITE_DELETED',
              };
            }
          }
          if (
            job.destination.driveId &&
            job.destination.driveId !== 'dropbox'
          ) {
            drive = await Sharepoint.getDrive(job.destination.driveId);
            if (drive?.error) {
              job.error = {
                code: 'LIBRARY_DELETED',
              };
            }
          }
          if (
            job.destination.folderId &&
            job.destination.driveId !== 'dropbox'
          ) {
            try {
              folder = await Sharepoint.getDriveItem(
                job.destination.folderId,
                job.destination.driveId,
              );
            } catch (e) {
              console.error(e);
              folder = { name: 'Deleted Folder' };
            }
            if (folder?.error) {
              job.error = {
                code: 'FOLDER_DELETED',
              };
            }
          }
          jobsToAdd.push({
            ...job,
            metadata: {
              site,
              drive,
              folder,
              collections: Library?.data?.collections?.filter((col) =>
                job.collections.includes(col._id),
              ),
            },
          });
        } catch (e) {
          console.log(e);
        }
      });
      setJobs(jobsToAdd);
      const notChecked = jobsToAdd.filter((job) => {
        return (
          (!job.status || (!job.status?.isRunning && !job.status?.isChecked)) &&
          !jobQueue.includes(job._id) &&
          (job.occurence === 'daily' || !job.lastRun) &&
          differenceInHours(new Date(), new Date(job.lastRun)) >=
            DAILY_SYNC_INTERVAL_HOURS
        );
      });
      setJobQueue([...jobQueue, ...notChecked.map((job) => job._id)]);
      setIsLoadingJobs(false);
    } catch (e) {
      console.log(e);
      setIsLoadingJobs(false);
    }
  }

  async function runJob(job) {
    function updateJobStatus(status) {
      setJobs({ type: 'UPDATE_STATUS', jobId: job._id, status });
    }
    try {
      const Library =
        job.library === 'media'
          ? Media
          : job.library === 'documents'
            ? Documents
            : null;

      if (
        job.userId !== User.djangoProfile?.id &&
        !forceJob.includes(job._id)
      ) {
        updateJobStatus({ type: 'ERROR', code: 'NOT_CREATOR' });
        return false;
      }

      if (!job.metadata.drive?.id) {
        updateJobStatus({ type: 'ERROR', code: 'NO_PERMISSION' });
        return false;
      }
      if (!job.metadata.site?.id) {
        updateJobStatus({ type: 'ERROR', code: 'NO_PERMISSION' });
        return false;
      }
      if (job.destination?.folderId && !job.metadata.folder?.id) {
        updateJobStatus({ type: 'ERROR', code: 'NO_PERMISSION' });
        return false;
      }

      if (!Library) {
        updateJobStatus({
          type: 'ERROR',
          code: 'NO_LIBRARY_SELECTED',
          files: [],
        });
        return false;
      }

      if (job.error?.code) {
        return updateJobStatus({
          code: job.error?.code,
          isError: true,
          isRunning: true,
        });
      }

      updateJobStatus({ code: 'GETTING_PICKIT_FILES', isRunning: true });
      const map = [];
      let folderMap = job.maps?.collections || [];
      let filesMap = job.maps?.files || [];
      const allFiles = [];
      const allItems = [];

      const deletedCollections = [];
      let baseFolder;
      if (job.preferences.createFolders === false) {
        try {
          baseFolder = await Sharepoint.getDriveItem(
            job.destination.folderId || 'root',
            job.destination.driveId,
          );
        } catch (e) {
          console.error('Failed to get base folder', e);
        }
      }
      const useBaseFolder =
        job.preferences.createFolders === false && !!baseFolder;
      await asyncPool(2, job.collections, async (collection) => {
        let metadata = {};
        try {
          metadata = await Library.getCollection(collection);
        } catch (e) {
          if (e.status === 404) {
            try {
              await Sharepoint.deleteItem(
                job.destination.driveId,
                folderMap.find((f) => f.collectionId === collection)?.itemId,
              );
            } catch (e) {}
          }
          deletedCollections.push(collection);
          return false;
        }
        let collectionFiles = await Library.getAllFilesInCollection(collection);
        collectionFiles = collectionFiles.filter(
          (file) => !!file.file?.url && !file?.file?.external_file,
        );
        const mapItem = job.maps?.collections?.find(
          (map) => map.collectionId === collection,
        );
        let item;
        const duplicateNamedFiles = {};
        allFiles.push(...collectionFiles);
        allFiles.forEach((file) => {
          const split = (file.name ?? file.file.name).split('.');
          if (split.length === 1) {
            split.push('');
          }
          const ext = split.pop();
          const name = split.join('.');
          const key = `${name}.${ext}`;
          if (duplicateNamedFiles[key]) {
            duplicateNamedFiles[key] += 1;
            file.name = `${name} (${duplicateNamedFiles[key]})${
              ext ? `.${ext}` : ''
            }`;
          } else {
            duplicateNamedFiles[key] = 1;
          }
        });
        if (mapItem && !useBaseFolder) {
          item = await Sharepoint.getDriveItem(
            mapItem.itemId,
            job.destination.driveId,
          );
          if (item.error) {
            item = null;
            folderMap.splice(
              folderMap.findIndex((it) => it.collectionId === collection),
              1,
            );
          }
        }
        map.push({
          collection: metadata,
          files: collectionFiles,
          item: useBaseFolder ? baseFolder : item,
        });
      });

      if (deletedCollections.length) {
        folderMap = folderMap.filter(
          (f) => !deletedCollections.includes(f.collectionId),
        );
      }

      updateJobStatus({ code: 'CREATING_FOLDERS' });
      await asyncPool(2, map, async (mapItem) => {
        let { item } = mapItem;
        let name = mapItem.collection.name
          .trim()
          .replace(/^[._]|[!#%&*{}:<>?/|"~]|\.{2}|\.$|(?:\.files)/g, '_');
        if (!item) {
          try {
            // There is a bug at Sharepoint where folders with the name "Video" will be empty but give 200 OK. Renaming it to "Videos" helps.
            if (name === 'Video') {
              name = 'Videos';
            }
            item = await Sharepoint.createFolder(
              job.destination.driveId,
              job.destination.folderId || 'root',
              name,
            );
            if (!item.id) {
              throw new Error('Failed to create folder');
            }
          } catch (e) {
            item = await Sharepoint.getDriveItem(
              `${job.destination.folderId || 'root'}:/${name}`,
              job.destination.driveId,
            );
          }
          if (item?.id) {
            folderMap.push({
              itemId: item.id,
              collectionId: mapItem.collection._id,
            });
          }
        }
        if (
          item?.id &&
          item.name !== name &&
          !useBaseFolder &&
          name?.toLowerCase() !== 'video'
        ) {
          await Sharepoint.renameItem(job.destination.driveId, item.id, name);
        }
        // This might not work. Replace with findIndex if so.
        map[map.indexOf(mapItem)] = {
          ...mapItem,
          item,
        };
      });

      updateJobStatus({ code: 'GETTING_FILES_IN_FOLDERS' });
      await asyncPool(2, map, async (mapItem) => {
        const items = await Sharepoint.getFolderItems(
          job.destination.driveId,
          mapItem.item.id,
        );
        allItems.push(...(items || []));
        // Removing files which has been deleted in Sharepoint, hence the map is no longer valid.

        map[map.indexOf(mapItem)] = {
          ...mapItem,
          items,
        };
      });
      filesMap = filesMap.filter((mp) =>
        allItems.find((it) => it.id === mp.itemId),
      );
      updateJobStatus({ code: 'CHECKING_FILE_ALREADY_EXISTS' });
      await asyncPool(2, map, async (mapItem) => {
        const files = mapItem.files.map((file) => {
          return {
            ...file,
            alreadyUploaded: !!filesMap.find(
              (item) => item.fileId === file._id,
            ),
          };
        });
        map[map.indexOf(mapItem)] = {
          ...mapItem,
          files,
        };
      });

      const filesDeleted = filesMap.filter((fileMap) => {
        let isDeleted = true;
        map.find((mapItem) => {
          if (mapItem.files.find((file) => file._id === fileMap.fileId)) {
            isDeleted = false;
          }
        });
        return isDeleted;
      });

      if (filesDeleted?.length) {
        const current = 0;
        updateJobStatus({
          code: 'DELETING_REMOVED_FILES',
          current,
          total: filesDeleted?.length,
        });
        await asyncPool(2, filesDeleted, async (item) => {
          await Sharepoint.deleteItem(job.destination.driveId, item.itemId);
          filesMap = filesMap.filter((it) => it.itemId !== item.itemId);
          updateJobStatus({ current, total: filesDeleted?.length });
        });
      }

      let sasToken = await Library.getToken(Library.sasToken);

      const currentHeavyFiles = {};

      async function uploadFile(file, replace) {
        sasToken = await Library.getToken(sasToken);
        let { file: fileToUpload } = file;
        let token = sasToken?.token?.main;
        let fileName = file.name ?? file.file.name;
        const ext = file?.file?.ext?.toLowerCase();
        const fileSize =
          job.preferences.imageSizeSpecific?.[file._id] ||
          job.preferences.imageSize?.[ext] ||
          'source';
        if (!job.preferences.imageSize?.[ext]) {
          return null;
        }
        if (
          fileSize === 'largest-preview' &&
          fileToUpload.contentType !== 'images/gif' &&
          !fileToUpload.contentType?.includes('video') &&
          !fileToUpload.contentType?.includes('audio') &&
          file.file.previews?.[0]
        ) {
          fileToUpload = {
            ...file.file.previews?.[0],
            url: file.file.previews?.[0]?.url?.split('?')?.[0],
          };
          token = sasToken?.token?.thumbnails;
        }
        if (fileSize === 'ignore') {
          return null;
        }
        if (!fileToUpload) {
          return null;
        }
        if (fileToUpload.ext !== file.file.ext) {
          fileName = `${fileName.split('.')[0]}.jpg`;
        }
        if (!replace) {
          const uploadedFile = await Sharepoint.uploadFile(
            job.destination.driveId,
            file.destinationItem.id,
            fileName,
            `${fileToUpload.url?.split('?')?.[0]}?${token}`,
            (bytes, totalBytes) => {
              if (!currentHeavyFiles?.[file._id]) {
                currentHeavyFiles[file._id] = {
                  current: 0,
                  total: 0,
                  label: `Uploading ${truncate(fileName, 25, '...')}`,
                };
              } else {
                currentHeavyFiles[file._id].current = bytes;
                currentHeavyFiles[file._id].total = totalBytes;
                currentHeavyFiles[file._id].label = `Uploading ${truncate(
                  fileName,
                  25,
                  '...',
                )}`;
                updateJobStatus({
                  subProgress: currentHeavyFiles,
                });
              }
            },
            () => {
              delete currentHeavyFiles[file._id];
              updateJobStatus({
                subProgress: currentHeavyFiles,
              });
            },
          );
          filesMap.push({
            itemId: uploadedFile.id,
            fileId: file._id,
          });
        } else {
          await Sharepoint.uploadFile(
            job.destination.driveId,
            file.destinationItem.id,
            fileName,
            `${fileToUpload.url?.split('?')?.[0]}?${token}`,
            (bytes, totalBytes) => {
              updateJobStatus({ subCurrent: bytes, subTotal: totalBytes });
            },
          );
        }
      }
      const filesUpdated = filesMap
        .filter((fileMap) => {
          const file = allFiles.find((file) => file._id === fileMap.fileId);
          const item = allItems.find((item) => item.id === fileMap.itemId);

          const itemExportedDate = new Date(
            item.fileSystemInfo.lastModifiedDateTime,
          );
          const fileUploadedDate = new Date(file.file.uploaded_at);
          return isAfter(fileUploadedDate, itemExportedDate);
        })
        .map((fileMap) => ({
          ...allFiles.find((file) => file._id === fileMap.fileId),
          destinationItem: allItems.find((item) => item.id === fileMap.itemId),
        }));
      let current = 0;
      updateJobStatus({
        code: 'UPLOADING_FILE_CHANGES',
        current: 0,
        total: filesUpdated?.length,
      });
      await asyncPool(2, filesUpdated, async (file) => {
        current += 1;
        await uploadFile(file, true);
        updateJobStatus({ current, total: filesUpdated?.length });
      });

      const filesNotUploaded = map.reduce((files, mapItem) => {
        return [
          ...files,
          ...mapItem.files
            .filter((file) => !file.alreadyUploaded)
            .map((file) => ({
              ...file,
              destinationItem: mapItem.item,
            })),
        ];
      }, []);
      const totalFiles = filesNotUploaded?.length;

      updateJobStatus({
        code: 'UPLOADING_FILES',
        current,
        total: totalFiles,
      });
      await asyncPool(2, filesNotUploaded, async (file) => {
        await uploadFile(file);
        current += 1;
        updateJobStatus({ current, total: totalFiles });
      });

      const updatedJob = await User.request.files.export.saveJob(
        'sharepoint-export',
        {
          _id: job._id,
          lastRun: new Date(),
          collections: job.collections.filter(
            (collection) => !deletedCollections.includes(collection),
          ),
          maps: {
            collections: [...folderMap],
            files: [...filesMap],
          },
        },
      );

      updateJobStatus({
        code: 'JOB_CHECKED',
        isLoading: false,
        isRunning: false,
      });

      setJobs(
        jobs.map((job) =>
          job._id === updatedJob._id
            ? {
                ...job,
                ...updatedJob,
                status: {
                  code: 'JOB_CHECKED',
                  isLoading: false,
                  isRunning: false,
                  isChecked: true,
                  current: 0,
                  total: 0,
                },
              }
            : job,
        ),
      );
    } catch (e) {
      console.log(e);
      System.Report.logError(e);
      if (e?.error?.code === 'itemNotFound') {
        updateJobStatus({
          code: 'DESTINATION_DELETED',
          type: 'ERROR',
          isRunning: false,
          isLoading: false,
          isChecked: true,
          current: 0,
          total: 0,
        });
        const updatedJob = await User.request.files.export.saveJob(
          'sharepoint-export',
          {
            _id: job._id,
            maps: null,
          },
        );
        setJobs(
          jobs.map((job) =>
            job._id === updatedJob._id
              ? {
                  ...job,
                  ...updatedJob,
                  status: {
                    code: 'DESTINATION_DELETED',
                    type: 'ERROR',
                    isRunning: false,
                    isLoading: false,
                    isChecked: true,
                    current: 0,
                    total: 0,
                  },
                }
              : job,
          ),
        );
        return false;
      }
      if (e?.error?.code === 'accessDenied') {
        updateJobStatus({
          code: 'NO_PERMISSION',
          isLoading: false,
          type: 'ERROR',
          isRunning: false,
          isLoading: false,
          isChecked: true,
          current: 0,
          total: 0,
        });
        return false;
      }
      const updatedJob = await User.request.files.export.saveJob(
        'sharepoint-export',
        {
          _id: job._id,
          maps: {},
        },
      );
      setJobs(
        jobs.map((job) =>
          job._id === updatedJob._id
            ? {
                ...job,
                ...updatedJob,
                status: {
                  code: 'JOB_FAILED',
                  type: 'ERROR',
                  isRunning: false,
                  isLoading: false,
                  isChecked: true,
                  current: 0,
                  total: 0,
                },
              }
            : job,
        ),
      );
      updateJobStatus({
        type: 'ERROR',
        code: 'JOB_FAILED',
        error: e,
        isRunning: false,
        isLoading: false,
        isChecked: true,
        current: 0,
        total: 0,
      });
      return false;
    }
  }

  const translations = {
    GETTING_PICKIT_FILES: 'Fetching all Pickit files in collections',
    CREATING_FOLDERS: 'Creating folders at destination',
    GETTING_FILES_IN_FOLDERS: 'Getting files in created folders at destination',
    CHECKING_FILE_ALREADY_EXISTS: 'Checking which files already is uploaded',
    DELETING_REMOVED_FILES: 'Deleting removed Pickit files',
    UPLOADING_FILE_CHANGES: 'Uploading file changes from Pickit files',
    UPLOADING_FILES: 'Uploading files',
    JOB_CHECKED: 'Export completed successfully',
    DESTINATION_DELETED:
      "The destination has been deleted or you don't have access. Please change export destination and try again or contact your SharePoint owner.",
    NO_PERMISSION:
      'The destination you are trying it export to is not accessible by your account. Please contact your SharePoint owner',
    JOB_FAILED: 'Something went wrong. Please try again or contact support',
    QUEUED: 'Waiting for other export to finish...',
    NOT_CREATOR: (
      <>
        You are not the creator of this export.
        <br />
        You can force it to sync at your own risk.
      </>
    ),
  };

  function translateCode(code) {
    if (translations[code]) {
      return translations[code];
    }
    return code;
  }

  return {
    translateCode,

    saveJob: async (updates, clearStatus, metadata) => {
      try {
        const newJob = await User.request.files.export.saveJob(
          'sharepoint-export',
          updates,
        );
        if (!updates._id) {
          setJobs([
            ...jobs,
            {
              ...newJob,
              metadata,
            },
          ]);
          queueJob({
            ...newJob,
            metadata,
          });
          return newJob;
        }
        setJobs(
          jobs.map((job) =>
            job._id === newJob._id
              ? { ...job, ...newJob, status: clearStatus ? null : job.status }
              : job,
          ),
        );
        return newJob;
      } catch (e) {
        return e;
      }
    },

    deleteJob: async (jobId, prefs) => {
      await User.request.files.export.deleteJob(
        'sharepoint-export',
        jobId,
        prefs,
      );
      setJobs(jobs.filter((job) => job._id !== jobId));
    },

    isDisconnectedWarning,

    jobs,
    queueJob,
    jobQueue,

    isLoadingJobs,

    getJobs,
  };
}
