import { Dropdown } from 'elements_v2';
import Tags from 'elements_v2/Tags';
import { useUserContext } from 'hooks';
import React, { useEffect, useReducer, useState } from 'react';

export default function SelectGroupsDropdown({
  value,
  communitySlug,
  onChange,
  placeholder,
  style,
}) {
  const [groups, setGroups] = useReducer((state, action) => [...action], []);
  const [isLoading, setIsLoading] = useState(false);
  const User = useUserContext();

  async function getGroups() {
    setIsLoading(true);
    const groups = await User.request.proxy.getGroups(communitySlug);
    setGroups(groups.data);
    setIsLoading(false);
  }

  useEffect(() => {
    getGroups();
  }, []);

  return (
    <Tags
      onRemove={(tag) =>
        value.includes(tag)
          ? onChange(
              value.filter((t) => t !== tag),
              tag
            )
          : onChange([...value, tag], tag)
      }
      onTagClick={(tag) =>
        value.includes(tag)
          ? onChange(value.filter((t) => t !== tag))
          : onChange([...value, tag])
      }
      inputType="text"
      value={value}
      placeholder={placeholder}
      tagLabel={(groupId) =>
        groups.find((group) => group.slug === groupId)?.name
      }
      isDropdownOnly
      pixi
      style={style}
      dropdownProps={{
        onChange: (groups) => {
          onChange(groups);
        },
        width: 200,
        useV2: true,
        zIndex: 9999999,
        isLoadingOptions: isLoading,
        value,
        multiple: true,
        keepOpen: true,
        options: groups
          .filter((group) => !value.includes(group.slug))
          .map((group) => {
            return {
              label: group.name,
              value: group.slug,
              text: group.name,
            };
            // return (
            //   <Dropdown.Option onClick={() => onChange(user.id, user)} key={user.id} text={userToName(user)} value={user.id}>
            //     {userToName(user)}
            //     {user.is_api_user && <span style={{ opacity: 0.5 }}>API Integration</span>}
            //   </Dropdown.Option>
            // );
          }),
      }}
    />
  );
}
