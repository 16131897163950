import FileThumbnail, { FileThumbnailV2 } from 'components/FileThumbnail';

import DocumentCollection from 'elements/DocumentCollection';
import { Button, Dropdown, Flex, Loader, Popup } from 'elements_v2';
import React, { useEffect, useReducer } from 'react';
import transparent from 'images/1x1.png';
import useStorageUrl from 'hooks/files/useStorageUrl';
import { useUserContext } from 'hooks';

export default function SelectCollections(props) {
  const { generateUrl } = useStorageUrl();
  const [selectedCollections, setSelectedCollections] = useReducer(
    (state, action) => [...action],
    [],
  );
  const User = useUserContext();

  useEffect(() => {
    if (props.value) {
      setSelectedCollections(props.value);
    }
  }, [props.value]);

  const Collections = (
    props.context?.data?.collections?.filter(
      (collection) =>
        !props.disableCollections?.find((col) =>
          typeof col === 'string'
            ? col === collection._id
            : col._id === collection._id,
        ),
    ) || []
  )
    ?.filter((collection) => {
      if (props.ignoreFamily) {
        return (
          collection?.communitySlug ===
          User.data?.selectedCommunity?.communitySlug
        );
      }
      return true;
    })
    .sort((a, b) => {
      return a.name.localeCompare(b.name);
    });

  function onCollectionClick(collection) {
    if (!props.multiple) {
      if (props.onChange) {
        props.onChange(collection);
      }
    } else if (selectedCollections.find((f) => collection._id === f._id)) {
      setSelectedCollections(
        [...selectedCollections].filter((f) => f._id !== collection._id),
      );
    } else {
      setSelectedCollections([...selectedCollections, collection]);
    }
  }

  if (props.dropdown) {
    return (
      <Dropdown useV2 trigger={props.trigger} {...(props.dropdownProps || {})}>
        {Collections.map((collection) => (
          <Dropdown.Option
            key={collection._id}
            onClick={() => onCollectionClick(collection)}
          >
            <img
              style={{ background: '#323232' }}
              src={
                generateUrl(
                  props.context.getCollectionThumbnailUrl(collection),
                ) || transparent
              }
              alt={collection.name}
            />
            {collection.name}
          </Dropdown.Option>
        ))}
      </Dropdown>
    );
  }

  return (
    <Popup
      style={{ maxWidth: 800, maxHeight: '90vh' }}
      zIndex={99999999}
      title={props.title}
      onOpen={props.onOpen}
      description={props.description}
      actions={(_isOpen, setIsOpen) => (
        <>
          <Button
            inline
            pixi
            onClick={() => {
              setIsOpen(false);
            }}
          >
            Cancel
          </Button>
          {props.multiple ? (
            <Button
              inline
              primary
              pixi
              style={{ marginLeft: 'auto' }}
              onClick={() => {
                if (props.onChange) {
                  props.onChange(selectedCollections);
                }
                setSelectedCollections([]);
                setIsOpen(false);
              }}
            >
              Choose {selectedCollections.length} selected collection
              {selectedCollections.length > 1 ? 's' : ''}
            </Button>
          ) : (
            <></>
          )}
        </>
      )}
      trigger={props.trigger}
    >
      {(isOpen, setIsOpen) => (
        <>
          {isOpen && (
            <>
              {!props.context ? (
                <Loader>Loading library</Loader>
              ) : (
                <Flex flexWrap="wrap">
                  {Collections.map((collection) => {
                    return (
                      <DocumentCollection
                        key={collection._id}
                        collection={collection}
                        active={
                          !!selectedCollections.find(
                            (col) => col._id === collection._id,
                          )
                        }
                        thumbnail={
                          <FileThumbnailV2
                            cover
                            file={
                              collection.thumbnail?.id
                                ? collection.thumbnail
                                : collection.thumbnail_media?.file ||
                                  (collection.thumbnail_original
                                    ?.contentType === 'image/gif'
                                    ? collection.thumbnail_original
                                    : collection.thumbnail)
                            }
                            noIcon
                            forceFile={!!collection.thumbnail?.id}
                            media={!!collection.thumbnail_media?.file}
                            context={props.context}
                          />
                        }
                        onClick={() => {
                          if (!props.multiple) {
                            setIsOpen(false);
                          }
                          onCollectionClick(collection);
                        }}
                        noHero
                        forceName
                        context={props.context}
                      />
                    );
                  })}
                </Flex>
              )}
            </>
          )}
        </>
      )}
    </Popup>
  );
}
