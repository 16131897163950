import { Box, Paper, Slider, Stack, TextInput } from '@mantine/core';
import { AssetThumbnail } from '@pixi/components/AssetThumbnail';
import Droppable from '@pixi/components/Droppable';
import { useKeySelector } from '@pixi/store';
import { useState } from 'react';
import { useAssetGridContext } from '../../AssetGridContext';
import { useAssetGridShortcuts } from '../../hooks/useAssetGridShortcuts';

export default function AssetGridSimilarity() {
  const AssetGrid = useAssetGridContext();
  const activeSimilarityFilter =
    AssetGrid.activeShortcutsFilter?.$imageSimilarity?.[0];
  const activeTextSimilarityFilter =
    AssetGrid.activeShortcutsFilter?.$textSimilarity?.[0];
  const [name, setName] = useState<string>('');
  const [droppedFile, setDroppedFile] = useState<null | string>(null);
  const file = useKeySelector('FILES', droppedFile || undefined);
  return (
    <Stack p="md" gap="md">
      <Paper w="100%" h={250} bg="gray.2" radius="md" pos="relative">
        {file && (
          <AssetThumbnail size="medium" h="100%" radius="md" file={file} />
        )}
        <Droppable
          accepts="file"
          alwaysShowLabel={!activeSimilarityFilter}
          label={() => 'Drop a file to search similarity'}
          isDisabled={() => false}
          onDrop={async (data) => {
            setDroppedFile(data.id as string);
            AssetGrid.setActiveShortcuts([
              {
                id: 'ai.image_similarity',
                filter: {
                  $imageSimilarity: [
                    { value: data.id as string, prefs: { threshold: 0.7 } },
                  ],
                },
              },
            ]);
          }}
        />
      </Paper>
      {file?._id && (
        <Slider
          color="blue"
          defaultValue={
            ((activeSimilarityFilter?.prefs?.threshold || 0) * 100) as number
          }
          mb="lg"
          key={activeSimilarityFilter?.prefs?.threshold}
          onChangeEnd={(value) => {
            AssetGrid.setActiveShortcuts([
              {
                id: 'ai.image_similarity',
                filter: {
                  $imageSimilarity: [
                    {
                      value: file._id as string,
                      prefs: { threshold: value / 100 },
                    },
                  ],
                },
              },
            ]);
          }}
          marks={[
            { value: 20, label: '20%' },
            { value: 50, label: '50%' },
            { value: 80, label: '80%' },
          ]}
        />
      )}
      <form
        onSubmit={(event) => {
          event.preventDefault();
          AssetGrid.setActiveShortcuts([
            {
              id: 'ai.text_similarity',
              filter: {
                $textSimilarity: [
                  {
                    value: name as string,
                    prefs: {
                      threshold: activeTextSimilarityFilter?.prefs?.threshold,
                    },
                  },
                ],
              },
            },
          ]);
        }}
      >
        <TextInput
          placeholder="Search using AI"
          value={name}
          onChange={(event) => setName(event.currentTarget.value)}
        />
      </form>
    </Stack>
  );
}
