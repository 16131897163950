import React from 'react';
import { useEventListener } from 'hooks';
import { RouterHistoryContext } from 'contexts/RouterHistory';

export function RouterHistoryProvider(props) {
  const [routes, setRoutes] = React.useState([]);
  const [routeBeforeLoginRedirect, setRouteBeforeLoginRedirect] =
    React.useState(null);
  function handleHashChange() {
    const route = window.location.hash;
    if (routes[routes.length - 1] !== route) {
      if (
        route.indexOf('#/welcome') > -1 &&
        routes[routes.length - 1] &&
        routes[routes.length - 1] !== '#/logout' &&
        routes[routes.length - 1] !== '#/settings'
      ) {
        setRouteBeforeLoginRedirect(routes[routes.length - 1]);
      }
      routes.push(route);
      setRoutes([...routes]);
    }
  }
  useEventListener('hashchange', handleHashChange);

  React.useEffect(() => {
    handleHashChange();
  }, []);

  return (
    <RouterHistoryContext.Provider
      value={{
        routes,
        routeBeforeLoginRedirect,
      }}
    >
      {props.children}
    </RouterHistoryContext.Provider>
  );
}
