import { analytics, Storage } from 'services';

class ApiService {
  constructor(props) {
    this.checkForErrors = this.checkForErrors.bind(this);
    this.handleJSONResponse = this.handleJSONResponse.bind(this);
    this.handleTextResponse = this.handleTextResponse.bind(this);
    this.onError = this.onError.bind(this);
  }

  defaultHeaders = {};

  defaultParameters = {};

  errorListeners = [];

  preCheck = null;

  API_BASE_URL = `${window.pickit.config.BASE_URL}/api`;

  subDomain = undefined;

  baseUrl = undefined; // Allow overwrite base url, i.e. for docker reason

  proto = 'https';

  community = undefined;

  setSlug(slug, ignoreStorage) {
    this.communitySlug = slug;
    if (this.useStorage && !ignoreStorage) {
      Storage.setItem('communitySlug', slug);
    }
  }

  /**
   * Set a default header that will be used on ALL requests.
   * @param {[string]} key   Key of the header
   * @param {[string]} value Value of the header
   */
  setDefaultHeader(key, value) {
    if (!value && this.defaultHeaders[key]) {
      delete this.defaultHeaders[key];
    } else {
      this.defaultHeaders = {
        ...this.defaultHeaders,
        [key]: value,
      };
    }
  }

  setDefaultHeaders(headers) {
    this.defaultHeaders = {
      ...this.defaultHeaders,
      ...headers,
    };
  }

  /**
   * Set a default parameter.
   * @param {[string]} key   Key of the parameter
   * @param {[string]} value Value of the parameter
   */
  setDefaultParameter(key, value) {
    this.defaultParameters = {
      ...this.defaultParameters,
      [key]: value,
    };
  }

  convertRequestBody(data) {
    const requestBody = Object.keys(data)
      .map((key) => {
        return `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`;
      })
      .join('&');
    return requestBody;
  }

  /**
   * Send HTTP request
   * @param {string} path
   * @param props
   * @param community
   * @param {object | undefined} options Options for the request
   * @param alreadyRotated
   */
  async request(path, props, community, options, alreadyRotated) {
    if (!this.API_BASE_URL) {
      throw new Error(`Api.Config.BASE_URL is not specified.`);
    }

    if (this.preCheck) {
      await this.preCheck();
    }

    if (!props) {
      props = {};
    }

    Object.keys(this.defaultHeaders).forEach((key) => {
      if (!this.defaultHeaders[key]) {
        delete this.defaultHeaders[key];
      }
    });

    let headers = {
      ...props.headers,
    };

    if (props && props.headers) {
      headers = { ...(this.defaultHeaders || {}), ...headers };
    } else {
      headers = this.defaultHeaders || {};
    }

    if (
      props &&
      headers &&
      headers['Content-Type'] === 'application/x-www-form-urlencoded' &&
      props.body &&
      typeof props.body === 'object'
    ) {
      props.body = this.convertRequestBody(props.body);
    }

    let baseUrl = this.baseUrl ? this.baseUrl : this.API_BASE_URL;

    if (community) {
      headers['pickit-community-slug'] = community;
    }

    if (
      typeof this.community === 'string' &&
      !headers['pickit-community-slug']
    ) {
      headers['pickit-community-slug'] = community || this.community;
    }

    if (typeof this.subDomain === 'string') {
      baseUrl = `${this.subDomain}.${baseUrl}`;
    }

    if (props.withoutApi) {
      baseUrl = baseUrl.replace('/api', '');
    }

    let mainUrl = '';
    if (!(options && options.skipBaseUrl)) {
      mainUrl = `${this.proto}://${baseUrl}`;
    }

    const requestUrl = (props.requestUrl || mainUrl) + path;
    try {
      const request = await fetch(requestUrl, {
        ...props,
        headers,
        signal: props?.signal,
      });
      if (
        !alreadyRotated &&
        (request.status === 500 ||
          request.status === 403 ||
          request.status === 401) &&
        this.userContext
      ) {
        await this.userContext.checkToken(true);
        return this.request(path, props, community, options, true);
      }
      if (!request.ok) {
        analytics.trackError(request);
      }
      if (!request.ok) {
        throw request;
      }
      return request;
    } catch (e) {
      if (e.name === 'AbortError') throw 'AbortError';
      throw e;
    }
  }

  stringify(params) {
    if (typeof params !== 'object') {
      return '';
    }
    return `?${Object.keys(params)
      .map((k) => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`)
      .join('&')}`;
  }

  checkForErrors(response) {
    if (!response.ok) {
      this.callOnError(response);
      throw response;
    }
  }

  handleJSONResponse(response) {
    // try {
    this.checkForErrors(response);
    return response.json();
    // } catch(errorResponse){
    // }
  }

  handleTextResponse(response) {
    // try {
    this.checkForErrors(response);
    return response.text();
    // } catch(errorResponse){
    // }
  }

  onError(cb) {
    this.errorListeners.push(cb);
  }

  callOnError(res) {
    this.errorListeners.forEach((cb) => {
      cb(res);
    });
  }

  configureFilter(filter) {
    return JSON.stringify(
      Object.keys(filter).map((key) => {
        return {
          name: key,
          value: filter[key],
        };
      }),
    );
  }

  addContentFilter(params) {
    if (!params) {
      return false;
    }

    for (let i = 0, j = arguments.length; i < j; i++) {
      Object.assign(params, arguments[i]);
    }

    if (this.contentFilter && this.contentFilter !== '') {
      if (typeof params.filters === 'undefined') {
        params.filters = [];
      }

      if (!!params.filters && typeof params.filters === 'string') {
        params.filters = JSON.parse(params.filters);
      }

      if (
        params.filters &&
        params.filters.findIndex &&
        params.filters.findIndex(
          (filter) => filter.name === 'explicitly_level',
        ) === -1 &&
        !params.skipExplicity
      ) {
        params.filters.push({
          name: 'explicitly_level',
          value: this.contentFilter,
        });
      }
      params.filters = JSON.stringify(params.filters);
    }
    return params;
  }
}

export default ApiService;
