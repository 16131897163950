import Flag from 'elements_v2/Flag';
import { PinkToolbar } from 'elements_v2/PinkToolbar';
import React, { useRef } from 'react';
import Thumbnail from './Thumbnail';

export const File = React.forwardRef((props, ref) => {
  const className = `FileGrid__File ${
    props.file.isLoading ? 'FileGrid__File--isLoading' : ''
  } ${props.file.isSelected ? 'FileGrid__File--isSelected' : ''}`;

  const thumbnailRef = useRef();

  let filename = props.file.data.name;
  try {
    filename = decodeURIComponent(props.file.data.name);
  } catch (e) {}
  let filenameSliceLength = 13;
  if (props.gridSize === 'small') {
    filenameSliceLength = 8;
  }
  if (props.gridSize === 'large') {
    filenameSliceLength = 17;
  }
  if (filename?.length > filenameSliceLength * 3) {
    filename = `${filename.slice(0, filenameSliceLength)}...${filename.slice(
      filename.length - filenameSliceLength,
    )}`;
  }

  return (
    <div
      className={className}
      ref={ref}
      onClick={props.onClick}
      style={props.style}
    >
      <div className="FileGrid__File__content">
        <div
          className="FileGrid__File__thumbnail brand__color__border__before"
          ref={thumbnailRef}
        >
          {props.toolbar ? (
            <PinkToolbar
              parentRef={thumbnailRef}
              {...(props.toolbarProps || {})}
            >
              {props.toolbar}
            </PinkToolbar>
          ) : (
            <></>
          )}
          <Thumbnail
            file={props?.file?.data?.file}
            forceFileTypeLabel={props.forceFileTypeLabel}
            forceThumbnailUrl={props.forceThumbnailUrl}
            photo={{
              src: props.file.src,
            }}
            data={props?.file?.data}
            context={props.context}
            isLoading={props.isLoading}
            customLoader={props.customLoader}
            loadingMessage={props.loadingMessage}
            injectThumbnail={props.injectThumbnail}
            preferSize={
              !props.gridSize
                ? 'small'
                : props.gridSize === 'small'
                  ? 'tiny'
                  : 'medium'
            }
          />
          <div className="FileGrid__File__thumbnail__flag">
            <Flag name={props.file?.data?.country} />
          </div>
          {props.thumbnailOverlay}
        </div>
        {props.file?.data?.name && (
          <div className="FileGrid__File__detail">
            <div className="FileGrid__File__detail__name">
              <div className="FileGrid__File__detail__name__file">
                {filename}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
});

export default File;
