import localStorageWrapper from 'utils/localStorageWrapper';
import {
  inAdobeAddon,
  inGoogleDocs,
  inOffice,
  inTeams,
  isNative,
} from 'utils/platform';
import Storage from './Storage';
// import { ToastService } from "elements";
// import React from "react";
export default class Analytics {
  static engine = null;

  static userContext = '';

  static userStatus = '';

  static Types = {
    EVENT: 'EVENT',
    PAGE_VIEW: 'PAGE_VIEW',
  };

  static init() {
    window.ApplicationInsights =
      window.Microsoft.ApplicationInsights.ApplicationInsights;
    const appInsights = new window.ApplicationInsights({
      config: {
        instrumentationKey: window.pickit.keys.APP_INSIGHTS_KEY,
        autoTrackPageVisitTime: true,
        /* ...Other Configuration Options... */
      },
    });
    appInsights.loadAppInsights();
    Analytics.engine = appInsights;
  }

  static activeParams = {
    'Subscription CTA Name': 'None',
    'Contribution Search Query': 'None',
    'Contribution Search Image Type': 'None',
    'Contribution Collection Slug': 'None',
    'Contribution Category Slug': 'None',
    'Contribution Position': 'None',
    'Page Name': 'None',
    Referrer: 'None',
    'Image Bank Source': 'None',
  };

  static defaultParams = {};

  static setDefaultParam(key, value) {
    if (!value) {
      delete this.defaultParams[key];
      return false;
    }
    Analytics.defaultParams[key] = value;
  }

  static getActiveParam(name) {
    const { activeParams } = Analytics;
    return activeParams[name];
  }

  static setUserContext(userContext) {
    Analytics.userContext = userContext;
  }

  static setUserStatus(userStatus) {
    Analytics.userStatus = userStatus;
  }

  static log(type) {
    if (window.pickit.config.BASE_URL === 'pickit.com') {
      return false;
    }
    if (type === Analytics.Types.EVENT) {
    }
    if (type === Analytics.Types.PAGE_VIEW) {
    }
  }

  static trackException(exception) {
    if (!Analytics.engine) {
      return false;
    }
    const sendParams = {
      ...Analytics.getDefaultParams(),
      ...Analytics.activeParams,
    };
    Analytics.engine.trackException({ exception }, sendParams);
  }

  static trackEvent(name, params) {
    if (!Analytics.engine) {
      return false;
    }
    const sendParams = {
      ...Analytics.getDefaultParams(),
      ...params,
      ...Analytics.activeParams,
    };
    if (name === 'Application Started') {
      console.info('started track', sendParams);
    }
    Analytics.engine.trackEvent({ name }, sendParams);
    Analytics.log(Analytics.Types.EVENT, name, sendParams);
  }

  static trackPage(name, params) {
    if (!Analytics.engine) {
      return false;
    }
    if (!name || name === 'Index') {
      return false;
    }
    Analytics.setActiveParam('Referrer', Analytics.getActiveParam('Page Name'));
    Analytics.setActiveParam('Page Name', name);
    const sendParams = {
      ...Analytics.getDefaultParams(),
      ...params,
    };
    Analytics.engine.trackPageView({ name, properties: sendParams });
    Analytics.log(Analytics.Types.PAGE_VIEW, name, sendParams);

    if (name === 'Search Results' && params['Search Query']) {
      Analytics.setActiveParam(
        'Contribution Search Query',
        params['Search Query']
      );
    }
    if (name === 'Collection' && params['Collection Slug']) {
      Analytics.setActiveParam(
        'Contribution Collection Slug',
        params['Collection Slug']
      );
    }
    if (name === 'Category' && params['Category Slug']) {
      Analytics.setActiveParam(
        'Contribution Category Slug',
        params['Category Slug']
      );
    }
    if (name === 'Collection' && params['Image Bank Source']) {
      Analytics.setActiveParam(
        'Image Bank Source',
        params['Image Bank Source']
      );
    }
    if (name === 'Explore' && params['Image Bank Source']) {
      Analytics.setActiveParam(
        'Image Bank Source',
        params['Image Bank Source']
      );
    }
  }

  static getDefaultParams() {
    const { userContext, userStatus } = Analytics;
    const { djangoProfile, cloudStorage } = userContext;
    const defaultParams = {
      App: 'Pickit',
      'App Domain': window.location.hostname,
      'App Release': window.pickit.RELEASE,
      Channel: inOffice()
        ? 'Office Add-in'
        : inTeams()
        ? 'Teams App'
        : inGoogleDocs()
        ? 'Google Editor Add-on'
        : isNative()
        ? 'Pickit Desktop'
        : inAdobeAddon()
        ? 'Adobe Add-on'
        : 'Web App',
      'Page Name': Analytics.getActiveParam('Page Name'),
      Referrer: Analytics.getActiveParam('Referrer'),
      'User Django Id': 'None',
      'User Status': 'Unauthenticated',
      'User Type': 'Unauthenticated',
      'CAT Scenario Type': 'default',
      'User Language': localStorageWrapper.getItem('selectedLanguage') || 'en',
    };

    defaultParams['User Django Id'] = djangoProfile?.id?.toString() || 'None';

    /**
     * This will set the user to their Django Id, tracking them across platforms.
     */
    if (djangoProfile?.id) {
      Analytics.engine.setAuthenticatedUserContext(
        djangoProfile?.id?.toString(),
        '',
        true
      );
    }

    if (userStatus) {
      if (userStatus.product.type === 'pro') {
        defaultParams['User Type'] = 'Individual';
      }
      if (userStatus.product.type === 'free') {
        defaultParams['User Type'] = 'Legacy Free';
      }
      if (userStatus.product.type === 'LIV') {
        defaultParams['User Type'] = 'Starter';
      }
      if (userStatus?.onboardingScenario) {
        defaultParams['CAT Scenario Type'] =
          userStatus.onboardingScenario || 'default';
      }
      if (userStatus.product.type === 'business') {
        defaultParams['User Type'] = 'Community';
        defaultParams.Community = userContext?.spaces?.selected?.slug || 'None';
        defaultParams['Community Slug'] =
          userContext?.spaces?.selected?.slug || 'None';
        defaultParams['Community Self-Service'] = userStatus.product
          .isSelfServiced
          ? 'Yes'
          : 'No';
      }

      if (userStatus.product.isTrial) {
        defaultParams['User Status'] = 'Trial';
      }
      if (
        (userStatus.product.type === 'business' ||
          userStatus.product.type === 'pro') &&
        !userStatus.product.isTrial &&
        userStatus.product.isActive
      ) {
        defaultParams['User Status'] = 'Paying';
      }
      if (userStatus.product.type === 'free') {
        defaultParams['User Status'] = 'Free';
      }
      if (userStatus.product.type === 'LIV') {
        defaultParams['User Status'] = 'Starter';
      }
      if (userStatus.product.isCanceled) {
        defaultParams['User Status'] = 'Canceled';
      }
      if (userStatus.product.isTrialEnded) {
        defaultParams['User Status'] = 'Trial Ended';
      }
    }

    defaultParams.Variation = cloudStorage?.variation;
    defaultParams['Background Variation'] = Storage.getItem(
      'userBackgroundVariation'
    );
    defaultParams['Onboarding Variation'] = cloudStorage?.onboarding?.variation;

    return {
      ...defaultParams,
      ...Analytics.defaultParams,
    };
  }

  static async trackError(res) {
    if (!Analytics.engine) {
      return false;
    }
    try {
      const errorString = JSON.stringify({
        text: '',
        url: res.url || '',
        status: res.status || '',
      });
      Analytics.trackEvent('Error Request Failed', {
        'Error Details': errorString,
      });
    } catch (e) {
      Analytics.trackEvent('Error Request Failed', {
        'Error Details': 'Unknown',
      });
    }
  }

  static setActiveParam(name, value) {
    if (name === 'Contribution Category Slug') {
      Analytics.activeParams['Contribution Search Query'] = 'None';
      Analytics.activeParams['Contribution Search Image Type'] = 'None';
      Analytics.activeParams['Contribution Collection Slug'] = 'None';
    }
    if (name === 'Contribution Search Query') {
      Analytics.activeParams['Contribution Category Slug'] = 'None';
      Analytics.activeParams['Contribution Collection Slug'] = 'None';
    }
    if (name === 'Contribution Collection Slug') {
      Analytics.activeParams['Contribution Search Query'] = 'None';
      Analytics.activeParams['Contribution Search Image Type'] = 'None';
      Analytics.activeParams['Contribution Category Slug'] = 'None';
    }
    Analytics.activeParams[name] = value || 'None';
  }
}

Analytics.trackEvent('Window Opened');
