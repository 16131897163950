import { isAfter } from 'date-fns';
import { useEffect, useState } from 'react';
import localStorageWrapper from 'utils/localStorageWrapper';

export default function useAdobe() {
  const [isConnected, setIsConnected] = useState(false);
  const [isConnecting, setIsConnecting] = useState(false);
  const [token, setToken] = useState(
    JSON.parse(localStorageWrapper.getItem('adobe_cc')),
  );
  const [error, setError] = useState(undefined);
  const clientID = window.pickit.keys.ADOBE_CCL_CLIENT_ID;
  const secret = window.pickit.keys.ADOBE_CCL_CLIENT_SECRET;
  const DefaultScope =
    'openid,creative_sdk,profile,address,AdobeID,email,cc_files,cc_libraries,offline_access';
  const authOrigin = 'https://ims-na1.adobelogin.com/ims';
  const creativeCloudOrigin = window.pickit.config.API_URL;
  const authURL = `${authOrigin}/authorize/v2?client_id=${clientID}&scope=${DefaultScope}&response_type=code`;
  const params = {};
  let parameters = window.location.href?.split('?')[1];
  if (parameters) {
    parameters = parameters.split('&');
    parameters.forEach((param) => {
      const [key, value] = param.split('=');
      params[key] = value;
    });
  }
  useEffect(() => {
    const { error, code } = params;
    if (code) {
      setIsConnecting(true);
      getAuthInfo(code);
    }
    if (error) {
      setError(error);
    }
  }, [params.code]);
  useEffect(() => {
    setError(params.error);
  }, [params.error]);

  useEffect(() => {
    if (token && isAfter(new Date(token.expire_at), new Date())) {
      setIsConnected(true);
      return;
    }
    refreshToken();
  }, []);

  async function getAuthInfo(code) {
    try {
      setIsConnected(false);
      const response = await fetch(
        `${authOrigin}/token/v3?client_id=${clientID}`,
        {
          method: 'POST',
          body: `code=${code}&grant_type=authorization_code`,
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            Authorization: `Basic ${btoa(`${clientID}:${secret}`)}`,
          },
        },
      );
      const body = await response.json();
      if (!body.access_token) {
        throw new Error('bad');
      }
      const token = {
        ...body,
        expire_at: new Date(
          new Date().getTime() + body.expires_in * 1000,
        ).toISOString(),
      };
      setToken(token);
      localStorageWrapper.setItem('adobe_cc', JSON.stringify(token));
      window.location.href = window.location.href.replace(
        /&code=[a-zA-Z0-9.\-_]*/gm,
        '',
      );
      setIsConnecting(false);
      setIsConnected(true);
    } catch (e) {
      console.error(e);
      setError('Failed to authenticate with token');
      disconnect();
    }
  }

  async function connect() {
    window.location.href = encodeURI(authURL);
  }

  function disconnect() {
    setIsConnected(false);
    setIsConnecting(false);
    setToken(undefined);
    localStorageWrapper.removeItem('adobe_cc');
  }
  function getHeaders() {
    return {
      Authorization: token ? `Bearer ${token?.access_token}` : undefined,
      'x-api-key': clientID,
    };
  }

  async function request(path, options = {}, scope) {
    if (!token) {
      return false;
    }
    const headers = {
      ...getHeaders(),
      ...(options?.headers || {}),
    };

    let url = creativeCloudOrigin + path;

    if (options.type === 'auth') {
      url = authOrigin + path;
    }

    const req = await fetch(url, {
      method: 'GET',
      ...options,
      headers,
    });
    if (req.status === 401) {
      const token = await refreshToken(scope);
      if (!token) {
        return disconnect();
      }
      return request(
        path,
        {
          ...options,
          headers: {
            ...headers,
            Authorization: `Bearer ${token?.access_token}`,
          },
        },
        scope,
      );
    }
    if (!req.ok) {
      throw req;
    }
    try {
      const data = await req.json();
      return data;
    } catch (e) {
      throw req;
    }
  }

  async function refreshToken() {
    try {
      if (!localStorageWrapper.getItem('adobe_cc')) {
        return;
      }
      const { refresh_token } = JSON.parse(
        localStorageWrapper.getItem('adobe_cc') || '{}',
      );
      const response = await fetch(
        `${authOrigin}/token/v3?client_id=${clientID}`,
        {
          method: 'POST',
          body: `refresh_token=${refresh_token}&grant_type=refresh_token`,
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            Authorization: `Basic ${btoa(`${clientID}:${secret}`)}`,
          },
        },
      );
      const body = await response.json();
      const token = {
        ...body,
        expire_at: new Date(
          new Date().getTime() + body.expires_in * 1000,
        ).toISOString(),
      };
      if (!token.access_token) {
        throw new Error();
      }
      setToken(token);
      localStorageWrapper.setItem('adobe_cc', JSON.stringify(token));
    } catch (e) {
      setError('Failed to refresh token');
      disconnect();
    }
  }

  return {
    isConnected,
    isConnecting,
    connect,
    request,
    disconnect,
    error,
    getHeaders,
  };
}
