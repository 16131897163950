import React, { useEffect, useState } from 'react';

// react-doka
import { DokaImageEditorModal } from './react-doka';

// doka
import {
  // editor
  locale_en_gb,
  createDefaultImageReader,
  createDefaultImageWriter,

  // plugins
  setPlugins,
  plugin_crop,
  plugin_crop_locale_en_gb,
  plugin_crop_defaults,
  plugin_finetune,
  plugin_finetune_locale_en_gb,
  plugin_finetune_defaults,
  plugin_filter,
  plugin_filter_locale_en_gb,
  plugin_filter_defaults,
  plugin_decorate,
  plugin_decorate_defaults,
  plugin_decorate_locale_en_gb,
  component_shape_editor_locale_en_gb,
  plugin_sticker_defaults,
  plugin_sticker_locale_en_gb,
  plugin_sticker,
} from './doka';
import { DokaImageEditor } from './react-doka';
import { DokaImageEditorOverlay } from './react-doka';
import { LoadingOverlay } from '@mantine/core';

setPlugins(
  plugin_crop,
  plugin_finetune,
  plugin_filter,
  plugin_decorate,
  plugin_sticker,
);
const editorDefaults = {
  imageReader: createDefaultImageReader(),
  imageWriter: createDefaultImageWriter({
    mimeType: 'image/png',
  }),
  ...plugin_crop_defaults,
  ...plugin_finetune_defaults,
  ...plugin_filter_defaults,
  ...plugin_decorate_defaults,
  ...plugin_sticker_defaults,
  cropEnableInfoIndicator: true,
  stickers: ['🎉', '😄', '👍', '👎', '🍕'],
  locale: {
    ...locale_en_gb,
    ...plugin_crop_locale_en_gb,
    ...plugin_finetune_locale_en_gb,
    ...plugin_filter_locale_en_gb,
    ...plugin_decorate_locale_en_gb,
    ...component_shape_editor_locale_en_gb,
    ...plugin_sticker_locale_en_gb,
  },
};

export default function ImageEditor(props) {
  const [src, setSrc] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const preferences = {
    ...editorDefaults,
    ...(props.preferences || {}),
    locale: {
      ...editorDefaults.locale,
      ...(props.preferences?.locale || {}),
    },
  };

  useEffect(() => {
    if (props.src) {
      setSrc(props.src);
    }
  }, [props.src]);

  if (!src) {
    return <></>;
  }

  const filterNames = {};
  preferences.filterOptions.forEach((filterGroup) => {
    filterGroup[1].forEach((filter) => {
      filterNames[filter[0]] = filter[1](preferences.locale);
    });
  });

  const cropNames = [];
  preferences.cropSelectPresetOptions.forEach((cropSelectPresetOption) => {
    cropSelectPresetOption[1].forEach((option) => {
      cropNames.push({
        name: option[1],
        width: Array.isArray(option[0]) ? option[0][0] : undefined,
        height: Array.isArray(option[0]) ? option[0][1] : undefined,
        aspectRatio: typeof option[0] === 'number' ? option[0] : undefined,
      });
    });
  });

  const dokaProps = {
    ...preferences,
    src,
    onLoad: (res) => console.info('load modal image', res),
    onHide: () => props?.onHide?.(false),
    onProcess: async (output) => {
      setIsSaving(true);
      const filterKey = '';
      let filterName = '';
      let cropName = '';
      Object.keys(preferences.filterFunctions).forEach((key) => {
        const filter = preferences.filterFunctions[key];
        const matrix = filter();
        if (
          JSON.stringify(matrix) ===
          JSON.stringify(output.imageState.colorMatrix?.filter)
        ) {
          filterName = filterNames[filterKey];
        }
      });
      cropNames.forEach((crop) => {
        if (
          crop.width &&
          crop.height &&
          output.imageState?.targetSize?.width === crop.width &&
          output.imageState?.targetSize?.height === crop.height
        ) {
          cropName = crop.name;
        }
      });
      cropNames.forEach((crop) => {
        if (
          !cropName &&
          crop.aspectRatio &&
          crop.aspectRatio === output.imageState?.crop?.aspectRatio
        ) {
          cropName = crop.name;
        }
      });
      await props.onComplete(
        output.dest,
        {
          ...output.imageState,
          suggestedName: filterName || cropName,
        },
        output.src,
      );
      setIsSaving(false);
    },
  };

  return props.visible ? (
    <>
      {isSaving ? <LoadingOverlay color="dark" visible /> : null}
      {!props.inline && !props.overlay ? (
        <DokaImageEditorModal {...dokaProps} />
      ) : props.overlay ? (
        <DokaImageEditorOverlay {...dokaProps} />
      ) : (
        <DokaImageEditor {...dokaProps} />
      )}
    </>
  ) : (
    <></>
  );
}
