import { ReactElement, ReactNode } from 'react';
import PixiDropdown from '../Dropdown';
import PixiIcon from '../Icon';
import PixiText, { PixiTitle } from '../Text';

export default function DropdownInfo({
  target,
  text,
}: {
  target?: ReactElement;
  text: ReactNode;
}) {
  return (
    <PixiDropdown
      target={
        target || (
          <PixiIcon name="circle-info" variant="filled" color="primary" />
        )
      }
      position="bottom"
      trigger="hover"
      openDelay={500}
      width="auto"
      boxProps={{
        bg: 'primary',
        p: 'sm',
        px: 'md',
        bd: 0,
      }}
      withArrow
    >
      <PixiText size="sm" c="white" ta="center">
        {text}
      </PixiText>
    </PixiDropdown>
  );
}
