import { useEffect, useState } from 'react';

export const useMediaQuery = (query) => {
  const mediaMatch = window.matchMedia(query);
  const [matches, setMatches] = useState(mediaMatch.matches);

  useEffect(() => {
    const handler = (e) => setMatches(e.matches);
    mediaMatch.addEventListener
      ? mediaMatch.addEventListener('change', handler)
      : mediaMatch.addListener(handler);

    return () =>
      mediaMatch.addEventListener
        ? mediaMatch.removeEventListener('change', handler)
        : mediaMatch.removeListener(handler);
  });
  return matches;
};
