import React, { useEffect } from 'react';
import { Search as SearchBar } from 'elements_v2';
import { useTextContext } from 'hooks';

function Search(props) {
  const [query, setQuery] = React.useState('');
  const Text = useTextContext();

  useEffect(() => {
    if (query !== props.value) {
      setQuery(props.value);
    }
  }, [props.value]);

  React.useEffect(() => {
    props.onChange(query);
    // const searchTimeout = setTimeout(() => {
    //   props.onSearch(query);
    // }, 600);
    // return () => {
    //   clearTimeout(searchTimeout);
    // };
  }, [query]);

  return (
    <form
      style={{ width: '100%', ...(props.style || {}) }}
      onSubmit={(event) => {
        event.preventDefault();
        props.onSearch(query);
      }}
    >
      <SearchBar
        placeholder={Text.get('ui/search')}
        value={query}
        onChange={(query) => {
          setQuery(query);
          if (!query) {
            props.onSearch('');
          }
        }}
      />
    </form>
  );
}

export default Search;
