import * as yup from 'yup';
import React from 'react';
import Icon from './icon.svg?react';
import IconVideo from './icon_video.svg?react';

export default [
  {
    moduleId: 'IMAGE_TITLE_TEXT',
    menuGroupIds: ['TEXT', 'HEADER'],
    moduleInterface: {
      media_file: 'FILE',
      title: 'TITLE',
      text: 'TEXT',
      alt_text: 'TEXT',
      link: 'LINK',
    },
    status: {
      isEmpty: (data) => {
        return !data?.media_file?.fileId;
      },
    },
    moduleMetadataInterface: {
      imagePosition: yup.mixed().oneOf(['left', 'right']),
      noPadding: yup.boolean(),
      backgroundColor: yup.string(),
      textColor: yup.string(),
      fileVariantId: yup.string(),
    },
    menuContent: {
      key: 'IMAGE_TITLE_TEXT',
      icon: <Icon />,
      name: 'Image with text',
    },
  },
  {
    moduleId: 'VIDEO_TITLE_TEXT',
    menuGroupIds: ['VIDEO', 'TEXT'],
    moduleInterface: {
      media_file: 'FILE',
      title: 'TITLE',
      text: 'TEXT',
      alt_text: 'TEXT',
      embed: 'EMBED_VIDEO',
    },
    status: {
      isEmpty: (data) => {
        return !data?.media_file?.fileId && !data?.embed?.url;
      },
    },
    moduleMetadataInterface: {
      image_position: yup.mixed().oneOf(['left', 'right']),
      no_padding: yup.boolean(),
      titleSize: yup.mixed().oneOf(['small', 'medium', 'large']),
      textSize: yup.mixed().oneOf(['small', 'medium', 'large']),
    },
    menuContent: {
      key: 'VIDEO_TITLE_TEXT',
      icon: <IconVideo />,
      name: 'Video with text',
    },
  },
];
