import { useAppContext } from 'contexts/Providers/AppProvider';
import { Button, Dropdown, Icon, Intro } from 'elements_v2';
import * as React from 'react';
import { useNative } from 'views/Native/context';

interface ColorSchemeProps {
  next: () => void;
}

const ColorScheme: React.FC<ColorSchemeProps> = (props: ColorSchemeProps) => {
  const Native = useNative();
  const App = useAppContext();
  const { colorScheme } = Native.settings;
  const options = [
    {
      value: 'system',
      name: 'System default (Recommended)',
      option: (
        <>
          System default <span>Recommended</span>
        </>
      ),
    },
    { value: 'light', name: 'Light' },
    { value: 'dark', name: 'Dark' },
  ];
  return (
    <Intro noPadding>
      <Intro.Illustration
        style={{ width: 80, marginBottom: 15, textAlign: 'center' }}
      >
        <Icon name="sun" style={{ fontSize: 56 }} />
      </Intro.Illustration>
      <Intro.Message>
        <h1>Theme</h1>
        <p>Select color scheme for the app</p>
        <Dropdown
          useV2
          noTriggerWrapper
          value={colorScheme}
          width={270}
          trigger={
            <Button
              style={{
                maxWidth: 280,
                margin: '15px auto 0px auto',
              }}
              rightIcon={
                <Icon
                  name="caret-down-fill"
                  style={{ fontSize: 12, marginLeft: 15 }}
                />
              }
            >
              {options.find((opt) => opt.value === colorScheme)?.name}
            </Button>
          }
        >
          {options.map((option) => (
            <Dropdown.Option
              onClick={() =>
                Native.setSettingsValue('colorScheme', option.value)
              }
              key={option.value}
              value={option.value}
            >
              {option.option || option.name}
            </Dropdown.Option>
          ))}
        </Dropdown>
      </Intro.Message>
      <Button primary inline pixi size="large" onClick={props.next}>
        Continue
      </Button>
    </Intro>
  );
};

export default ColorScheme;
