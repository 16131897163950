import System from '@pixi/System';
import { useEffect } from 'react';
import { uniqueId } from 'utils';

export interface EventDataStructure {
  [key: string | number | symbol]: unknown[];
}

export type DispatchCallback<
  EventData extends EventDataStructure,
  K extends keyof EventData,
> = (key: K, ...args: EventData[K]) => unknown;

export type DispatchFunction<
  EventData extends EventDataStructure,
  K extends keyof EventData,
> = (...args: EventData[K]) => unknown;
type KeyListeners<EventData extends EventDataStructure> = Record<
  string | symbol | number,
  {
    cb: DispatchFunction<EventData, keyof EventData>;
    options?: { once?: boolean };
  }
>;
type Listeners<EventData extends EventDataStructure> = Record<
  keyof EventData,
  KeyListeners<EventData>
>;

export default class EventEmitter<ED extends EventDataStructure> {
  private listeners: Listeners<ED> = {} as Listeners<ED>;

  public cache: Record<keyof ED, unknown> = {} as Record<keyof ED, unknown>;

  public dispatch = <K extends keyof Listeners<ED>>(key: K, ...args: ED[K]) => {
    const state = this.listeners[key];
    this.cache[key] = args;
    Object.keys(state || {})?.forEach((k: keyof typeof state) => {
      try {
        state?.[k]?.cb(...args);
      } catch (e) {
        System.Report.logError(e as Error);
        throw e;
      }
      if (state[k]?.options?.once) {
        delete state[k];
      }
    });
  };

  public keys: string[] = Object.keys(this.listeners);

  public listenerByIdExists = <K extends keyof ED>(
    key: K,
    uniqueId: string,
  ) => {
    return !!this.listeners[key][uniqueId];
  };

  public countListeners = <K extends keyof ED>(key: K) => {
    return Object.keys(this.listeners[key] || {}).length;
  };

  public useListener = <K extends keyof ED>(
    key: K,
    fn: DispatchFunction<ED, K>,
    options?: { once?: boolean },
    deps?: unknown[],
  ) => {
    useEffect(() => {
      const id = this.on(key, fn, options);
      return () => {
        this.off(key, id);
      };
    }, deps || []);
  };

  public on = <K extends keyof ED>(
    key: K,
    fn: DispatchFunction<ED, K>,
    options?: { once?: boolean },
  ) => {
    const id = uniqueId();
    if (!this.listeners[key]) {
      this.listeners[key] = {
        [id]: {
          cb: fn,
          options,
        },
      } as KeyListeners<ED>;
    } else {
      this.listeners[key] = {
        ...this.listeners[key],
        [id]: {
          cb: fn,
          options,
        },
      };
    }
    // if(this.cache[key]){
    //   fn(...this.cache[key] as ED[K]);
    // }
    return id;
  };

  public off = <K extends keyof ED>(key: K, uniqueId: string) => {
    delete this.listeners[key][uniqueId];
  };
}
