import ApiService from './_ApiService';

class Pickit extends ApiService {
  channel = 'office_addin';

  constructor(props) {
    super(props);
    if (props.channel) {
      this.channel = props.channel;
    }
    if (props.contentFilter) {
      this.contentFilter = props.contentFilter;
    }
  }

  get getCollections() {
    return {
      hero: this.CollectionsRetriever.bind(this, 'hero'),
      featured: this.CollectionsRetriever.bind(this, 'featured'),
      public: this.CollectionsRetriever.bind(this, 'public'),
      promoted: this.CollectionsRetriever.bind(this, 'promoted'),
      explore: this.getExploreCollections.bind(this),
    };
  }

  async getUserSavedCollections(userId, page_nr) {
    return this.request(
      `/user/${userId}/collections/?page_limit=50&page_nr=${page_nr}`,
      {
        method: 'GET',
      }
    ).then(this.handleJSONResponse);
  }

  getContributionDownloadLinks(slug) {
    return this.request(`/contributions/${slug}/download_links/`).then(
      this.handleJSONResponse
    );
  }

  getCategoryContributions(params, categoryId) {
    params = {
      ...params,
      filters: JSON.stringify([
        {
          name: 'categories',
          value: categoryId,
        },
      ]),
    };
    return this.request(
      `/search/contributions/${this.stringify(
        Object.assign(params, {
          explicity_level: this.contentFilter,
          obj_output: true,
        })
      )}`
    ).then(this.handleJSONResponse);
  }

  getUserCollections(userId, params) {
    return this.request(
      `/user/${userId}/collections/public/${this.stringify(
        this.addContentFilter(params)
      )}`
    ).then(this.handleJSONResponse);
  }

  async getExploreCollections(options = {}) {
    try {
      const endpoint = `/collections/explore/source/${this.channel}/`;
      const request = await this.request(endpoint, {
        params: options,
      });
      const json = await request.json();
      return json;
    } catch (e) {
      throw new Error(e);
    }
  }

  async getUserInfo(userId) {
    const request = await this.request(`/users/${userId}/`).then(
      this.handleJSONResponse
    );
    return request;
  }

  async CollectionsRetriever(type, options, getAll) {
    try {
      const endpoint = `/collections/explore/${type}/source/${this.channel}/`;
      const request = await this.request(endpoint + this.stringify(options));
      const json = await request.json();
      if (getAll && json.collections.length >= options.page_limit) {
        options.page_nr += 1;
        json.collections = [
          ...json.collections,
          ...(await this.CollectionsRetriever(type, options, true)),
        ];
      }
      return json.collections || [];
    } catch (e) {
      throw new Error(e);
    }
  }

  getFeaturedCollections(params) {
    return this.request(
      `/collections/explore/featured/source/office_addin/${this.stringify(
        params
      )}`
    ).then(this.handleJSONResponse);
  }

  getSearchContributions(params) {
    if (params.filters) {
      params.filters = this.configureFilter(params.filters);
    }
    return this.request(`/search/contributions/${this.stringify(params)}`).then(
      this.handleJSONResponse
    );
  }

  getCategories() {
    return this.request(`/categories/`).then(this.handleJSONResponse);
  }

  getSearchCollections(params) {
    return this.request(
      `/search/collections${this.stringify(
        this.addContentFilter(
          Object.assign(params, {
            owner_id: '80293',
          })
        )
      )}`
    ).then(this.handleJSONResponse);
  }

  getUserContributions(userId, params) {
    return this.request(
      `/users/${userId}/contributions/${this.stringify(
        this.addContentFilter(params)
      )}`
    ).then(this.handleJSONResponse);
  }

  getUserCollection(userId, slug, params) {
    return this.request(
      `/user/${userId}/collection/${slug}/${this.stringify(
        this.addContentFilter(params)
      )}`
    ).then(this.handleJSONResponse);
  }

  getCollectionFollowers(collectionSlug) {
    return this.request(`/collections/${collectionSlug}/followers/`).then(
      this.handleJSONResponse
    );
  }

  getUserProfile(userId) {
    return this.request(`/users/${userId}/`).then(this.handleJSONResponse);
  }

  getSearchAutocomplete(params) {
    return this.request(`/search/autocomplete/${this.stringify(params)}`).then(
      this.handleJSONResponse
    );
  }

  getCollection(userId, slug, params) {
    return this.request(
      `/users/${userId}/collection/${slug}/${this.stringify(
        Object.assign(params, {
          explicity_level: this.contentFilter,
        })
      )}`
    ).then(this.handleJSONResponse);
  }

  getContribution(slug) {
    return this.request(`/contributions/${slug}`).then(this.handleJSONResponse);
  }

  getContributionBase64(slug) {
    return this.request(`/contributions/${slug}/download/base64/`).then(
      this.handleJSONResponse
    );
  }

  getContributionBase64FromUrl(url, width) {
    return this.request(url + (width ? `?width=${width}` : ''), null, null, {
      skipBaseUrl: true,
    }).then(this.handleTextResponse);
  }
}

export default Pickit;
