import { Icon } from 'elements_v2';
import Loader from 'elements_v2/Loader';
import { PinkToolbar } from 'elements_v2/PinkToolbar';
import React, { useRef, useState } from 'react';
import './style.scss';

const Item = React.forwardRef((props: any, ref: any) => {
  const [isOpen, setIsOpen] = useState(false);
  const className = ['Pixi Pixi__List__Item', props.className || ''];
  const contentRef = useRef(null);

  if (isOpen) {
    className.push('Pixi__List__Item--isOpen');
  }
  if (props.pronounced) {
    className.push('Pixi__List__Item--pronounced');
  }
  if (props.disabled) {
    className.push('Pixi__List__Item--disabled');
  }
  if (props.highlight) {
    className.push('Pixi__List__Item--highlight');
  }
  if (props.children) {
    className.push('Pixi__List__Item--withChildren');
  }

  async function onClick() {
    if (isOpen) {
      setIsOpen(false);
    }
    if (props.onClick) {
      await props.onClick();
    }
    if (!isOpen) {
      setIsOpen(true);
    }
  }

  return (
    <>
      <li className={className.join(' ')} style={props.style} ref={ref}>
        <div
          className={`Pixi__List__Item__wrapper ${
            props.onClick || props.children ? 'Pixi--clickable' : ''
          }`}
          style={props.wrapperStyle}
          ref={contentRef}
          onClick={onClick}
        >
          {!!props.toolbar && (
            <PinkToolbar parentRef={contentRef}>{props.toolbar}</PinkToolbar>
          )}
          <div
            className="Pixi Pixi__List__Item__content"
            style={props.contentStyle}
          >
            {props.image && (
              <div
                className="Pixi Pixi__List__Item__content__image"
                style={props.imageStyle}
              >
                {props.image}
              </div>
            )}
            {props.hiddenIcon && (
              <div
                className="Pixi Pixi__List__Item__content__icon Pixi__List__Item__content__icon--hidden"
                style={props.iconStyle}
              >
                {props.hiddenIcon}
              </div>
            )}
            {props.icon && (
              <div
                className="Pixi Pixi__List__Item__content__icon"
                style={props.iconStyle}
              >
                {props.icon}
              </div>
            )}
            {(props.title || props.subtitle || props.subtitleTop) && (
              <div
                className="Pixi Pixi__List__Item__content__text"
                style={props.textStyle}
              >
                {!!props.subtitleTop && (
                  <h2 style={props.subtitleStyle}>{props.subtitleTop}</h2>
                )}
                {!!props.title && <h1>{props.title}</h1>}
                {!!props.subtitle && (
                  <h2 style={props.subtitleStyle}>{props.subtitle}</h2>
                )}
              </div>
            )}
            {props.field && (
              <div className="Pixi Pixi__List__Item__content__field">
                {props.field}
              </div>
            )}
            {props.loading && (
              <div className="Pixi Pixi__List__Item__content__loader">
                <Loader compact small />
              </div>
            )}
            {!!props.actions && (
              <div
                className="Pixi Pixi__List__Item__content__actions"
                style={props.actionsStyle}
              >
                {props.actions}
              </div>
            )}
            {props.children && !props.loading && (
              <div className="Pixi__List__Item__content__caret">
                <Icon name={props.moreIcon || 'chevron-right'} />
              </div>
            )}
          </div>
        </div>
        {props.children && isOpen && (
          <ul className="Pixi__List__Item__children">{props.children}</ul>
        )}
      </li>
    </>
  );
});

List.Item = Item;

export default function List(props: any) {
  const className = ['Pixi Pixi__List'];
  if (props.compact) {
    className.push('Pixi__List--compact');
  }
  if (props.nested && props.cards) {
    className.push('Pixi__List--cardsNested');
  }
  if (props.cards) {
    className.push('Pixi__List--cards');
  }
  if (props.tight) {
    className.push('Pixi__List--tight');
  }
  return (
    <ul className={className.join(' ')} style={props.style}>
      {props.children}
    </ul>
  );
}

export { Item as ListItem };
