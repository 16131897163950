import { Button, Flex } from 'elements_v2';
import useUserStatus from 'hooks/useUserStatus';
import { useExternalSystems } from 'hooks/useExternalSystems';
import localStorageWrapper from 'utils/localStorageWrapper';
import DropboxLogo from './logos/Dropbox.svg';
import NetworkFileSystemLogo from './logos/NetworkFileSystem.svg';
import MicrosoftLogo from './logos/Microsoft.svg';
import BoxLogo from './logos/Box.svg';
import GettyImages from './logos/GettyImages.jpeg';
import CreativeCloud from './logos/creative-cloud.svg';
import Hubspot from './logos/hubspot.svg';
import GoogleDriveLogo from './logos/Google_Drive_2020.svg';
import AdobeLogo from './logos/Adobe.svg';
import ShutterstockLogo from './logos/shutterstock.svg';
import PickitStock from './logos/PickitStock.svg';

export const IntegrationData = [
  {
    value: 'dropbox',
    logo: DropboxLogo,
    name: 'Dropbox',
    color: '#285ff5',
  },
  {
    value: 'microsoft365_import',
    logo: MicrosoftLogo,
    name: 'Microsoft 365',
    color: '#285ff5',
    knownAs: ['microsoft'],
  },
  {
    value: 'box',
    logo: BoxLogo,
    name: 'Box',
    color: '#285ff5',
  },
  {
    value: 'microsoft365_export',
    knownAs: ['microsoft'],
    logo: MicrosoftLogo,
    name: 'Microsoft 365',
    color: '#285ff5',
  },
  {
    value: 'googledrive',
    knownAs: ['google-drive', 'googledrive'],
    logo: GoogleDriveLogo,
    name: 'Google Drive',
    color: '#285ff5',
  },
  {
    value: 'gettyimages',
    knownAs: ['getty-images', 'getty-images'],
    logo: GettyImages,
    name: 'Getty Images',
    color: '#285ff5',
  },
  {
    value: 'adobestock',
    logo: AdobeLogo,
    name: 'Adobe Stock',
    color: '#285ff5',
  },
  {
    value: 'shutterstock',
    logo: ShutterstockLogo,
    name: 'Shutterstock',
    color: '#285ff5',
  },
  {
    value: 'adobeccl',
    logo: CreativeCloud,
    color: '#285ff5',
    name: 'Creative Cloud',
  },
  {
    value: 'adobe-stock',
    logo: AdobeLogo,
    color: '#285ff5',
    name: 'Adobe Stock',
  },
  {
    value: 'hubspot_export',
    logo: Hubspot,
    name: 'HubSpot',
    color: '#285ff5',
  },
  {
    value: 'hubspot_import',
    logo: Hubspot,
    name: 'HubSpot',
    color: '#285ff5',
  },
  {
    value: 'pickit-market',
    knownAs: ['pickit-stock', 'pickit-market'],
    logo: PickitStock,
    name: 'Pickit Stock',
    color: '#285ff5',
  },
  {
    value: 'network_file_system',
    logo: NetworkFileSystemLogo,
    name: 'Network Drive',
    color: '#285ff5',
  },
];

export function useIntegrationApi(integration) {
  const externalSystems = useExternalSystems();
  let apiKey = integration?.value;

  if (integration?.value === 'microsoft365_import') {
    apiKey = 'Sharepoint';
  }
  if (integration?.value === 'dropbox') {
    apiKey = 'DropboxV2';
  }
  if (integration?.value === 'googledrive') {
    apiKey = 'GoogleDrive';
  }
  return externalSystems[apiKey];
}

export function useActiveIntegrations(integrations, preferences = {}) {
  const {
    Adobe,
    MicrosoftGraph: Graph,
    Google,
    Box,
    DropboxAuth,
    NetworkFileSystem,
    HubspotExport,
    HubspotImport,
  } = useExternalSystems();

  if (!integrations) {
    integrations = [];
  }

  const userStatus = useUserStatus();
  const MicrosoftConnected = Graph.isConnected;
  const DropboxConnected = DropboxAuth.isConnected;
  const HubspotExportConnected = HubspotExport.isConnected;
  const HubspotImportConnected = HubspotImport.isConnected;
  const ShutterstockConnected = !!localStorageWrapper.getItem('shutterstock');
  const GoogleConnected = Google.isConnected;
  const BoxNewConnected = Box.isConnected;

  const data = IntegrationData.map((row) => {
    if (row.value === 'dropbox') {
      row.isConnected = DropboxConnected;
    }
    if (
      row.value === 'microsoft365_import' ||
      row.value === 'microsoft365_export'
    ) {
      row.isConnected = MicrosoftConnected;
    }
    if (row.value === 'hubspot_export') {
      row.isConnected = HubspotExportConnected;
    }
    if (row.value === 'hubspot_import') {
      row.isConnected = HubspotImportConnected;
    }
    if (row.value === 'googledrive') {
      row.isConnected = GoogleConnected;
    }
    if (row.value === 'shutterstock') {
      row.isConnected = ShutterstockConnected;
    }
    if (row.value === 'box') {
      row.isConnected = BoxNewConnected;
    }
    if (row.value === 'adobeccl') {
      row.isConnected = Adobe.isConnected;
    }
    if (row.value === 'adobestock') {
      row.isConnected = !!localStorageWrapper.getItem('adobe');
    }
    if (row.value === 'gettyimages') {
      row.isConnected =
        userStatus.policies?.externalSystems?.getty?.apiKey &&
        userStatus.policies?.externalSystems?.getty?.apiSecret;
    }
    if (row.value === 'network_file_system') {
      row.isConnected = NetworkFileSystem.isConnected;
    }
    return row;
  });

  return {
    integrations: integrations
      .filter(
        (integration) =>
          userStatus.activeIntegrations[integration] &&
          data.find((int) => int?.value === integration),
      )
      .map((integration) => data.find((int) => int.value === integration))
      ?.filter((integration) => !!integration.value),
    integrationNotConnected: integrations
      .filter(
        (integration) =>
          userStatus.activeIntegrations[integration] &&
          data.find((int) => int?.value === integration),
      )
      .map((integration) => data.find((int) => int.value === integration))
      ?.filter(
        (integration) => !!integration.value && !integration.isConnected,
      ),
    connectedIntegrations: integrations
      .filter(
        (integration) =>
          userStatus.activeIntegrations[integration] &&
          data.find((int) => int?.value === integration),
      )
      .map((integration) => data.find((int) => int.value === integration))
      ?.filter((integration) => !!integration.value && integration.isConnected),
  };
}

export default function Integrations(props) {
  const { integrations: enabledIntegrations } = useActiveIntegrations(
    props.integrations,
  );

  return (
    <Flex alignItems="center" width="100%">
      {enabledIntegrations.map((integration) => (
        <Button
          key={integration.value}
          inline
          style={{
            background:
              props.activeIntegration !== integration.value
                ? 'rgba(0, 0, 0, 0.05)'
                : 'rgba(0, 0, 0, 0.15)',
          }}
          transparent={props.activeIntegration !== integration.value}
          onClick={() => props.onRedirect(integration)}
        >
          <img
            style={{ width: 'auto', height: 22, marginRight: 8, marginTop: -2 }}
            src={integration.logo}
            alt={integration.name}
          />
          {integration.name}
        </Button>
      ))}
    </Flex>
  );
}
