import React from 'react';

export function useHackedDeepState(defaultState) {
  function deepMerge(compare, obj) {
    Object.keys(obj).forEach((key) => {
      if (
        obj[key] &&
        typeof obj[key] === 'object' &&
        !obj[key].filter &&
        !obj[key].then
      ) {
        if (typeof compare[key] === 'object') {
          compare[key] = deepMerge(compare[key], obj[key]);
        } else {
          compare[key] = { ...compare[key], ...obj[key] };
        }
      } else {
        compare[key] = obj[key];
      }
    });
    return compare;
  }
  const stateReducer = (state, update = {}) => {
    if (update !== null && update !== undefined) {
      update = deepMerge(state, update);
    }
    return { ...state, ...update };
  };
  return React.useReducer(stateReducer, defaultState);
}

export default useHackedDeepState;
