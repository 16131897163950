import { Box, Divider, Group, Loader, Paper, Stack } from '@mantine/core';
import { useAssetGridContext } from '../../AssetGridContext';
import PixiText, { PixiTitle } from '@pixi/elements/Text';
import { bytesToSize } from 'utils';

export default function AssetGridMetadata() {
  const { metadata, ...AssetGrid } = useAssetGridContext();

  if (!metadata?.stats) {
    return <></>;
  }

  if (AssetGrid.status.isLoadingMetadata) {
    return (
      <Group w="100%" justify="center" mt="xl">
        <Loader mt="xl" color="gray" />
      </Group>
    );
  }

  return (
    <Stack w="100%" gap="md" p="md" py="lg">
      <Group w="100%" wrap="nowrap" gap="md">
        <Paper w="100%" ta="center" p="lg" variant="dark-glass">
          <PixiText fw="600">{metadata.stats?.totalAssets}</PixiText>
          <PixiText size="xs">assets</PixiText>
        </Paper>
        <Paper w="100%" ta="center" p="lg" variant="dark-glass">
          <PixiText fw="600">
            {bytesToSize(metadata?.stats?.totalSizeInBytes || 0) || 0}
          </PixiText>
          <PixiText size="xs">size</PixiText>
        </Paper>
      </Group>
      <Group w="100%" wrap="nowrap" gap="md">
        <Paper w="100%" ta="center" p="lg" variant="dark-glass">
          <PixiText fw="600">
            {AssetGrid.activeShortcutIds?.find((c) => c?.includes('trash'))
              ? 0
              : Math.round(metadata?.stats?.totalAssetsInCollections * 100)}
            %
          </PixiText>
          <PixiText size="xs">in collections</PixiText>
        </Paper>
        <Paper w="100%" ta="center" p="lg" variant="dark-glass">
          <PixiText fw="600">
            {Math.round(metadata?.stats?.adminsOnly)}%
          </PixiText>
          <PixiText size="xs">admins only</PixiText>
        </Paper>
      </Group>
    </Stack>
  );
}
