import React, { useState, useEffect } from 'react';

const SRTViewer = ({ url, videoRef }) => {
  const [captions, setCaptions] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [currentCaption, setCurrentCaption] = useState(null);
  const [currentTime, setCurrentTime] = useState(0); // Simulated currentTime

  useEffect(() => {
    // Check if the videoRef is valid
    if (videoRef && videoRef.current) {
      // Handler to update the current time state with video's currentTime
      const handleTimeUpdate = () => {
        setCurrentTime(videoRef.current?.currentTime);
      };

      // Add the event listener to the videoRef
      videoRef.current?.addEventListener('timeupdate', handleTimeUpdate);

      // Cleanup: remove the event listener when the component is unmounted
      return () => {
        videoRef.current?.removeEventListener('timeupdate', handleTimeUpdate);
      };
    }
  }, [videoRef]);

  async function loadUrl(url) {
    setIsFetching(true);
    try {
      const response = await fetch(url);
      const data = await response.text();
      const parsedCaptions = parseSrt(data);
      setCaptions(parsedCaptions);
    } catch (error) {
      console.error('Failed to fetch SRT:', error);
    } finally {
      setIsFetching(false);
    }
  }

  useEffect(() => {
    loadUrl(url);
  }, [url]);

  useEffect(() => {
    const findCaptionForTime = () => {
      for (const caption of captions) {
        const startTime = timeToSeconds(caption.startTime);
        const endTime = timeToSeconds(caption.endTime);
        if (currentTime >= startTime && currentTime <= endTime) {
          setCurrentCaption(caption);
          return;
        }
      }
      setCurrentCaption(null);
    };

    findCaptionForTime();
  }, [captions, currentTime]);

  const parseSrt = (data) => {
    const regex =
      /(\d+)\s*([\d:,]+)\s*-->\s*([\d:,]+)\s*([\s\S]*?)(?=\n{2,}|$)/g;
    let match;
    const results = [];

    while ((match = regex.exec(data))) {
      results.push({
        id: match[1],
        startTime: match[2],
        endTime: match[3],
        text: match[4].trim(),
      });
    }

    return results;
  };

  const timeToSeconds = (timeString) => {
    const [hours, minutes, seconds] = timeString.split(':');
    return +hours * 60 * 60 + +minutes * 60 + +seconds.replace(',', '.');
  };
  const splitIntoTwoLines = (text) => {
    const lines = text.split('\n');
    if (lines.length === 1) {
      const middle = Math.floor(lines[0].length / 2);
      const spaceIndex = lines[0].lastIndexOf(' ', middle);
      if (spaceIndex !== -1) {
        return [
          lines[0].substring(0, spaceIndex),
          lines[0].substring(spaceIndex + 1),
        ].join('\n');
      }
    }
    return lines.slice(0, 2).join('\n'); // Taking the first two lines
  };

  if (isFetching) {
    return <div>Loading...</div>;
  }

  return (
    <>
      {currentCaption && (
        <div
          style={{
            width: 'auto',
            position: 'absolute',
            bottom: '8%',
            left: 0,
            right: 0,
            marginLeft: 'auto',
            marginRight: 'auto',
            textAlign: 'center',
            padding: '5px',
            color: 'white',
            borderRadius: '2px',
            whiteSpace: 'pre-line',
            textShadow:
              '0px 0px 2px rgba(0, 0, 0, 0.7), 0px 0px 5px rgba(0, 0, 0, 0.4)',
            fontSize: 22,
            zIndex: 3,
            pointerEvents: 'none',
          }}
        >
          {splitIntoTwoLines(currentCaption.text)}
        </div>
      )}
    </>
  );
};

export default SRTViewer;
