import React, { useEffect, useReducer, useState } from 'react';
import { useUserContext } from './useUserContext';

export default function useUserTextFields() {
  const User = useUserContext();
  const [data, setData] = useReducer((state, action) => ({ ...action }), null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  async function saveFields(fields) {
    const newFields = await User.request.files.textFields.saveFields(fields);
    setData({ ...newFields, entities: data.entities || [] });
  }

  useEffect(() => {
    if (User.data?.userFields) {
      setData(User.data?.userFields);
    }
  }, [User.data?.userFields]);

  return {
    data,
    saveFields,
    isLoaded,
    isLoading,
  };
}
