import { Box, TextInput } from '@mantine/core';
import System from '@pixi/System';
import PixiButton from '@pixi/elements/Button';
import PixiDropdown, { PixiDropdownProps } from '@pixi/elements/Dropdown';
import PixiIcon from '@pixi/elements/Icon';
import { useUserContext } from 'hooks';
import React, { useEffect, useState } from 'react';

export default function SelectFolder({
  target,
  context,
  onFolderClick,
  onSearch,
  value,
  onlyDropdown,
  ...rest
}: {
  target: React.ReactElement;
  context: any;
  value?: string[];
  onSearch?: (query: string) => void | Promise<void>;
  onlyDropdown?: boolean;
  onFolderClick: (folder: Pickit.FolderInterface) => void | Promise<void>;
} & Partial<PixiDropdownProps>) {
  const [folders, setFolders] = useState<Pickit.FolderInterface[]>([]);
  const [query, setQuery] = useState('');
  const [isOpen, setIsOpen] = useState(!!onlyDropdown);
  const [searchedQuery, setSearchedQuery] = useState('');

  async function getFolders(query?: string) {
    try {
      setSearchedQuery(query || '');
      const newFolders = await context.request.folders.getFoldersByQuery(
        query || '',
      );
      setFolders(newFolders?.data || []);
    } catch (e) {
      System.Report.logError(e as Error);
      console.error(e);
    }
  }

  useEffect(() => {
    if (isOpen) {
      getFolders();
    }
  }, [isOpen]);

  const items = (
    <>
      <PixiDropdown.Label>Add to folder</PixiDropdown.Label>
      <Box p="xs">
        <form
          onSubmit={(event) => {
            event.preventDefault();
            event.stopPropagation();
            onSearch?.(query);
            getFolders(query);
          }}
        >
          <TextInput
            placeholder="Search folders"
            value={query}
            onChange={(event) => {
              if (!event.currentTarget.value) {
                getFolders('');
              }
              setQuery(event.currentTarget.value);
            }}
            rightSection={
              query !== searchedQuery && (
                <PixiButton size="compact-md" type="submit">
                  <PixiIcon name="magnifying-glass" size="sm" />
                </PixiButton>
              )
            }
          />
        </form>
      </Box>
      {folders.map((f) => (
        <PixiDropdown.Item
          icon="folder"
          key={f._id}
          onClick={async () => {
            await onFolderClick(f);
          }}
          disabled={value?.includes(f._id)}
          color={value?.includes(f._id) ? 'gray' : undefined}
          rightSection={
            value?.includes(f._id) ? <PixiIcon name="check" /> : undefined
          }
        >
          {f.name}
        </PixiDropdown.Item>
      ))}
    </>
  );
  if (onlyDropdown) {
    return items;
  }
  return (
    <PixiDropdown
      width={300}
      height={350}
      onOpen={() => {
        setIsOpen(true);
      }}
      onClose={() => {
        setIsOpen(false);
      }}
      target={target}
      {...rest}
    >
      {items}
    </PixiDropdown>
  );
}
