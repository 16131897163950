import {
  Box,
  Divider,
  Indicator,
  Paper,
  ScrollArea,
  Stack,
} from '@mantine/core';
import PixiButton from '@pixi/elements/Button';
import PixiIcon from '@pixi/elements/Icon';
import PixiTooltip from '@pixi/elements/Tooltip';
import { useEffect, useState } from 'react';
import { Title } from '../AssetPanel/elements';
import AssetGridFilters from './components/LeftPanels/AssetGridFilters';
import AssetGridFilterShortcuts from './components/LeftPanels/AssetGridFilterShortcuts';
import { AssetGridLeftPanelProps } from './Helpers';
import { useAssetGridContext } from './AssetGridContext';
import useFullViewportHeight from '@pixi/hooks/useFullViewportHeight';
import { useUserContext } from 'hooks';
import { selectFile, useColorScheme } from '@pixi/AppController';
import AssetGridSimilarity from './components/LeftPanels/AssetGridSimilarity';
import useUserStatus from 'hooks/useUserStatus';
import PixiConfirm from '@pixi/elements/Confirm';

export default function AssetGridLeftPanel({
  forceLeftPanelSection,
  assetGridFiltersProps,
  ...rest
}: AssetGridLeftPanelProps) {
  const User = useUserContext();
  const userStatus = useUserStatus();
  const colorScheme = useColorScheme();
  const AssetGrid = useAssetGridContext();
  const [sectionOpen, setSectionOpen] = useState<string | null>(
    forceLeftPanelSection || null,
  );
  const { ref, height } = useFullViewportHeight();

  useEffect(() => {
    if (!AssetGrid.activeShortcuts?.length && sectionOpen === 'trash') {
      setSectionOpen(null);
    }
  }, [AssetGrid.activeShortcuts]);

  return (
    <>
      <Paper
        w="100%"
        maw={sectionOpen ? 300 : 64}
        mih={1}
        pos="sticky"
        top={0}
        ref={ref}
        bg={
          colorScheme === 'dark'
            ? undefined
            : AssetGrid.disable?.includes('filters')
              ? 'transparent'
              : 'rgba(255, 255, 255, 0.5)'
        }
        style={{
          height,
          flexShrink: 0,
          transition: 'max-width .15s',
          overflow: 'hidden',
        }}
        {...rest}
      >
        {AssetGrid.leftPanelInjection}
        {!AssetGrid.disable?.includes('filters') && (
          <ScrollArea w="100%" maw={300} miw={1} h="100%" scrollbars="y">
            <Box
              w="100%"
              h="100%"
              pos="relative"
              style={{ zIndex: 0 }}
              maw={sectionOpen ? 300 : 64}
            >
              {!sectionOpen && (
                <Stack w="100%" p="xs" pt="lg" gap="xs">
                  <PixiTooltip label="Filters" position="right">
                    <PixiButton
                      px="xs"
                      size="md"
                      color={
                        Object.keys(AssetGrid?.activeFilters)?.length &&
                        !AssetGrid?.activeShortcuts?.length
                          ? 'primary'
                          : colorScheme === 'dark'
                            ? 'gray'
                            : 'dark'
                      }
                      variant="light"
                      onClick={() => {
                        setSectionOpen('filters');
                      }}
                    >
                      <PixiIcon name="filter" />
                    </PixiButton>
                  </PixiTooltip>
                  {userStatus?.product?.isBusinessAdmin &&
                    !AssetGrid.readOnly &&
                    userStatus.activeFeatures?.attributes &&
                    !!AssetGrid.currentLibrary?.preferences?.properties
                      ?.length && (
                      <PixiTooltip label="Properties" position="right">
                        <PixiButton
                          px="xs"
                          size="md"
                          color={
                            Object.keys(AssetGrid?.activeFilters)?.length &&
                            !AssetGrid?.activeShortcuts?.length &&
                            Object.keys(AssetGrid?.activeFilters)?.find((s) =>
                              s.startsWith('properties'),
                            )
                              ? 'primary'
                              : colorScheme === 'dark'
                                ? 'gray'
                                : 'dark'
                          }
                          variant="light"
                          onClick={() => {
                            setSectionOpen('properties');
                          }}
                        >
                          <PixiIcon name="filter-list" />
                        </PixiButton>
                      </PixiTooltip>
                    )}
                  {userStatus?.product?.isBusinessAdmin &&
                    !AssetGrid.readOnly && (
                      <PixiTooltip label="Shortcuts" position="right">
                        <PixiButton
                          px="xs"
                          size="md"
                          color={
                            AssetGrid?.activeShortcuts?.length &&
                            !!AssetGrid.shortcuts.filter(
                              (s) =>
                                !s.hidden &&
                                !!s?.data?.find((d) =>
                                  AssetGrid.activeShortcutIds?.includes(d.id),
                                ),
                            )?.length
                              ? 'primary'
                              : colorScheme === 'dark'
                                ? 'gray'
                                : 'dark'
                          }
                          variant="light"
                          onClick={() => {
                            setSectionOpen('filter-shortcuts');
                          }}
                        >
                          <PixiIcon name="star" />
                        </PixiButton>
                      </PixiTooltip>
                    )}

                  {!AssetGrid.readOnly &&
                    userStatus?.product?.isBusinessAdmin &&
                    !AssetGrid.disable?.includes('trash') && (
                      <PixiTooltip label="Trash" position="right">
                        <Indicator
                          label={AssetGrid.metadata?.stats?.trashCount}
                          size="xs"
                          offset={6}
                          color="red"
                          disabled={!AssetGrid.metadata?.stats?.trashCount}
                        >
                          <PixiButton
                            fullWidth
                            px="xs"
                            size="md"
                            color={
                              AssetGrid.activeShortcutIds?.includes(
                                'trash.active',
                              )
                                ? 'primary'
                                : colorScheme === 'dark'
                                  ? 'gray'
                                  : 'dark'
                            }
                            variant="light"
                            onClick={async () => {
                              await selectFile({
                                view: {
                                  title: 'Trash',
                                  icon: 'trash-can',
                                },
                                multiple: true,
                                libraryId: AssetGrid.currentLibrary?.nameId,
                                panelProps: {
                                  bg: 'gray.1',
                                },
                                assetGridProps: {
                                  forceFilterShortcuts: ['trash.active'],
                                  defaultView: {
                                    displayAs: 'table',
                                    groupBy: null,
                                    tableFields: [
                                      'file.previews',
                                      'name',
                                      'trash.addedAt',
                                      'trash.addedBy',
                                    ],
                                  },
                                  disable: [
                                    'toolbar',
                                    'rightPanel',
                                    'status',
                                    'leftPanel',
                                  ],
                                },
                                customActions: ({ selectedFiles, clear }) =>
                                  !selectedFiles?.length ? (
                                    <></>
                                  ) : (
                                    <>
                                      <PixiButton
                                        size="md"
                                        variant="light"
                                        onClick={async () => {
                                          await AssetGrid.context.recoverDocumentsFromTrash(
                                            selectedFiles,
                                          );
                                          clear();
                                        }}
                                        leftSection={
                                          <PixiIcon
                                            name="rotate"
                                            size="md"
                                            variant="filled"
                                          />
                                        }
                                      >
                                        Restore ({selectedFiles.length}) assets
                                      </PixiButton>

                                      <PixiConfirm
                                        title="Are you sure?"
                                        description="The selected assets will be deleted permanently. This can't be undone."
                                        confirmLabel="Delete assets"
                                        confirmProps={{
                                          color: 'red',
                                        }}
                                        onConfirm={async (close) => {
                                          await AssetGrid.context.deleteFiles(
                                            selectedFiles.map(
                                              (file) => file._id,
                                            ),
                                          );
                                          clear();
                                        }}
                                      >
                                        <PixiButton
                                          size="md"
                                          variant="filled"
                                          color="red"
                                          onClick={async () => {
                                            await AssetGrid.context.recoverDocumentsFromTrash(
                                              selectedFiles,
                                            );
                                          }}
                                          leftSection={
                                            <PixiIcon
                                              name="trash-can"
                                              size="md"
                                              variant="filled"
                                            />
                                          }
                                        >
                                          Permanentely delete (
                                          {selectedFiles.length}) assets
                                        </PixiButton>
                                      </PixiConfirm>
                                    </>
                                  ),
                              });
                              AssetGrid.getMetadata();
                            }}
                          >
                            <PixiIcon name="trash-can" />
                          </PixiButton>
                        </Indicator>
                      </PixiTooltip>
                    )}

                  {User.data?.selectedCommunity?.settings?.preferences
                    ?.experimentalFeatures?.imageVector ? (
                    <PixiTooltip label="Image similarity" position="right">
                      <PixiButton
                        px="xs"
                        size="md"
                        variant="light"
                        color={colorScheme === 'dark' ? 'gray' : 'dark'}
                        onClick={async () => {
                          setSectionOpen('similarity');
                        }}
                      >
                        <PixiIcon name="microchip-ai" />
                      </PixiButton>
                    </PixiTooltip>
                  ) : (
                    <></>
                  )}
                </Stack>
              )}
              <Box
                w="100%"
                style={{
                  opacity: sectionOpen ? 1 : 0,
                  transition: 'opacity .15s',
                  transitionDelay: sectionOpen ? '0.08s' : '0s',
                }}
              >
                {sectionOpen === 'filters' ? (
                  <>
                    {!forceLeftPanelSection && (
                      <>
                        <Title
                          title="Filters"
                          icon={<PixiIcon name="filter" />}
                          onClose={() => {
                            setSectionOpen(null);
                          }}
                        />
                        <Divider />
                      </>
                    )}
                    <AssetGridFilters
                      onClose={() => {
                        setSectionOpen(null);
                      }}
                      noBackground={rest?.bg === 'transparent'}
                      {...assetGridFiltersProps}
                    />
                  </>
                ) : null}
                {sectionOpen === 'trash' ? (
                  <>
                    <Title
                      title="Trash"
                      icon={<PixiIcon name="trash-can" />}
                      onClose={() => {
                        setSectionOpen(null);
                      }}
                    />
                    <Divider />
                    <AssetGridFilterShortcuts
                      onClose={() => {
                        setSectionOpen(null);
                      }}
                      specificShortcuts={['trash.user']}
                      forceShortcutGroup="trash"
                    />
                  </>
                ) : null}
                {sectionOpen === 'filter-shortcuts' ? (
                  <>
                    <Title
                      title="Shortcuts"
                      icon={<PixiIcon name="star" />}
                      onClose={() => {
                        setSectionOpen(null);
                      }}
                    />
                    <Divider />
                    <AssetGridFilterShortcuts
                      onClose={() => {
                        setSectionOpen(null);
                      }}
                    />
                  </>
                ) : null}
                {sectionOpen === 'properties' ? (
                  <>
                    <Title
                      title="Properties"
                      icon={<PixiIcon name="filter-list" />}
                      onClose={() => {
                        setSectionOpen(null);
                      }}
                    />
                    <Divider />
                    <AssetGridFilters
                      onClose={() => {
                        setSectionOpen(null);
                      }}
                      noBackground={rest?.bg === 'transparent'}
                      specificFilters={Object.keys(
                        AssetGrid.availableFilters,
                      ).filter((v) => v.startsWith('properties.'))}
                      {...assetGridFiltersProps}
                    />
                  </>
                ) : null}
                {sectionOpen === 'similarity' ? (
                  <>
                    <Title
                      title="Image Similarity search"
                      icon={<PixiIcon name="microchip-ai" />}
                      onClose={() => {
                        setSectionOpen(null);
                      }}
                    />
                    <Divider />
                    <AssetGridSimilarity />
                  </>
                ) : (
                  <></>
                )}
              </Box>
            </Box>
          </ScrollArea>
        )}
      </Paper>
    </>
  );
}
