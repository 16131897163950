import {
  ActionIcon,
  Alert,
  Badge,
  Box,
  Divider,
  Group,
  Image,
  Kbd,
  Loader,
  NavLink,
  Paper,
  rem,
  ScrollArea,
  ScrollAreaAutosize,
  Stack,
  TagsInput,
  TextInput,
  ThemeIcon,
} from '@mantine/core';
import { useColorScheme } from '@pixi/AppController';
import { SectionCollapse, Title } from '@pixi/components/AssetPanel/elements';
import PixiButton from '@pixi/elements/Button';
import PixiDropdown from '@pixi/elements/Dropdown';
import PixiIcon, { PixiIconName } from '@pixi/elements/Icon';
import PixiText from '@pixi/elements/Text';
import { DjangoIdToName, getDjangoIdToName } from 'components/DjangoIdToName';
import { useEffect, useState } from 'react';
import { useAssetGridContext } from '../../AssetGridContext';
import {
  AssetGridFiltersProps,
  filterRowValueIsActive,
  filterValueToString,
} from '../../Helpers';
import { useUserContext } from 'hooks';
import ErrorMessage from '@pixi/elements/ErrorMessage';
import { useDataStoreData, useKeySelector } from '@pixi/store';
import { IntegrationData } from 'views/_Manage/Integrations/Integrations';

function dataHasLabel(
  filter: Pickit.LibraryFilter['row'],
  data: Pickit.LibraryFilterDataRow,
  context: any,
) {
  return filter.field === 'file.uploaded_by'
    ? true
    : filter.field === 'collections' && !!data.value
      ? !!context.stores?.data?.collections?.find(
          (collection: Pickit.CollectionInterface) =>
            collection._id === data.value,
        )?.name
      : !data.value
        ? !!filter?.view?.emptyValue?.label
        : !!data.label || !!filterValueToString(data.value);
}

export function useFilterLabels(
  filter: Pickit.LibraryFilter['row'],
  data: Pickit.LibraryFilterDataRow[],
) {
  const collections = useDataStoreData('COLLECTIONS');
  const response: Record<string, string> = {};
  data.forEach(async (data) => {
    const text =
      filter.field === 'file.uploaded_by'
        ? await getDjangoIdToName(data.value, filter?.view?.emptyValue?.label)
        : filter.field === 'collections' && !!data.value
          ? collections.find(
              (collection: Pickit.CollectionInterface) =>
                collection._id === data.value,
            )?.name
          : !data.value
            ? filter?.view?.emptyValue?.label
            : filter.field === 'file.ext'
              ? (data.label || filterValueToString(data.value)).toUpperCase()
              : data.label || filterValueToString(data.value);
    response[data.value] = text;
  });
  return response;
}

export async function filterDataToLabel({
  filter,
  data,
  onlyText,
  context,
}: {
  filter: Pickit.LibraryFilter['row'];
  data: Pickit.LibraryFilterDataRow;
  onlyText?: boolean;
  context?: any;
}) {
  const text =
    filter.field === 'file.uploaded_by'
      ? await getDjangoIdToName(data.value, filter?.view?.emptyValue?.label)
      : filter.field === 'collections' && !!data.value
        ? context.stores.data.collections.find(
            (collection: Pickit.CollectionInterface) =>
              collection._id === data.value,
          )?.name
        : !data.value
          ? filter?.view?.emptyValue?.label
          : filter.field === 'file.ext'
            ? (data.label || filterValueToString(data.value)).toUpperCase()
            : data.label || filterValueToString(data.value);
  return text as string;
}
export function FilterDataRender({
  filter,
  data,
  onlyText,
}: {
  filter: Pickit.LibraryFilter['row'];
  data: Pickit.LibraryFilterDataRow;
  onlyText?: boolean;
}) {
  const { context } = useAssetGridContext();
  const collection = useKeySelector('COLLECTIONS', data.value?.toString());
  if (
    filter?.field === 'import.from' &&
    IntegrationData?.find(
      (s) =>
        s.value === data?.value || s.knownAs?.includes(data?.value as string),
    )?.name
  ) {
    data.label = IntegrationData?.find(
      (s) =>
        s.value === data?.value || s.knownAs?.includes(data?.value as string),
    )?.name;
  }

  let text: string | JSX.Element = (
    <>
      {filter.field === 'file.uploaded_by' ? (
        <DjangoIdToName value={data.value} fallback="(Not found)" />
      ) : filter.field === 'collections' && !!data.value ? (
        context.stores.data.collections.find(
          (collection: Pickit.CollectionInterface) =>
            collection._id === data.value,
        )?.name
      ) : !data.value ? (
        filter?.view?.emptyValue?.label
      ) : filter.field === 'file.ext' ? (
        <>{(data.label || filterValueToString(data.value)).toUpperCase()}</>
      ) : (
        data.label || filterValueToString(data.value)
      )}
    </>
  );
  if (filter.field === 'collections' && collection?.name) {
    text = collection?.name as string;
  }
  if (onlyText) {
    return text;
  }
  return (
    <PixiText
      size="sm"
      style={{
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        flexShrink: 0,
      }}
    >
      {text}
    </PixiText>
  );
}

const FilterSection = ({
  filter,
  isActive,
  row,
  preparedData,
  noBackground,
  view,
}: {
  filter: Pickit.LibraryFilter;
  isActive: boolean;
  row: Pickit.LibraryFilter['row'];
  preparedData: (Pickit.LibraryFilterDataRow & {
    onClick: () => void;
    isActive: boolean;
    count?: number;
    frozenCount?: number;
  })[];
  view: Pickit.LibraryFilter['row']['view'];
  noBackground?: boolean;
}) => {
  const colorScheme = useColorScheme();
  const { defaultFilters, activeFilters, setFilter, status } =
    useAssetGridContext();
  const labels = useFilterLabels(row, preparedData);

  const [page, setCurrentPage] = useState(0);
  const [searchFilter, setSearchFilter] = useState('');
  // State for sorting
  const [sortOrder, setSortOrder] = useState('Most hits');

  const sortedData = preparedData
    .map((s) => ({
      ...s,
      count: s.count || 0,
    }))
    .filter((s) => !s.isActive)
    .filter((s) =>
      searchFilter.length
        ? !s.value && row?.view?.emptyValue?.label
          ? row?.view?.emptyValue?.label
              ?.toLowerCase()
              ?.includes(searchFilter?.toLowerCase())
          : labels[s.value?.toString()]
              ?.toLowerCase()
              .includes?.(searchFilter?.toLowerCase())
        : true,
    )
    .sort((a, b) => {
      if (sortOrder === 'Most hits') {
        return b.count - a.count; // Sort by most hits
      }
      const aLabel = labels[a.value?.toString()];
      const bLabel = labels[b.value?.toString()];
      return aLabel?.localeCompare(bLabel || ''); // Sort alphabetically
    });

  return (
    <SectionCollapse
      key={row.key}
      label={view.name}
      defaultOpen={!!defaultFilters?.[row.field as keyof typeof defaultFilters]}
      padding="xs"
      noDivider={noBackground}
      rightSection={({ isOpen }) =>
        !isOpen &&
        !activeFilters?.[
          filter.row.field as keyof typeof activeFilters
        ] ? undefined : (
          <Group gap="2">
            {isOpen && (
              <>
                <PixiDropdown
                  target={
                    <PixiButton
                      size="compact-xs"
                      color={searchFilter?.length ? 'primary' : 'gray'}
                      variant="light"
                    >
                      <PixiIcon name="magnifying-glass" size="sm" />
                    </PixiButton>
                  }
                >
                  {({ setIsOpen }) => (
                    <>
                      <TextInput
                        placeholder="Search filter"
                        value={searchFilter}
                        onChange={(event) => {
                          setSearchFilter(event.target.value);
                        }}
                        autoFocus
                        rightSection={
                          searchFilter && (
                            <PixiButton
                              size="compact-xs"
                              color="dark"
                              variant="light"
                              radius="xl"
                              onClick={() => {
                                setSearchFilter('');
                                setIsOpen(false);
                              }}
                            >
                              <PixiIcon name="xmark" size="sm" />
                            </PixiButton>
                          )
                        }
                      />
                    </>
                  )}
                </PixiDropdown>
                <PixiDropdown
                  target={
                    <PixiButton size="compact-xs" color="gray" variant="light">
                      <PixiIcon name="arrow-up-wide-short" size="sm" />
                    </PixiButton>
                  }
                >
                  {({ setIsOpen }) => (
                    <>
                      <PixiDropdown.Label>Sort by</PixiDropdown.Label>
                      <PixiDropdown.Item
                        onClick={(event) => {
                          event.preventDefault();
                          event.stopPropagation();
                          setSortOrder('Most hits');
                          setIsOpen(false);
                        }}
                        color={
                          sortOrder === 'Most hits' ? 'primary' : undefined
                        }
                      >
                        Amount of results
                      </PixiDropdown.Item>
                      <PixiDropdown.Item
                        onClick={(event) => {
                          event.preventDefault();
                          event.stopPropagation();
                          setSortOrder('Alphabetical');
                          setIsOpen(false);
                        }}
                        color={
                          sortOrder === 'Alphabetical' ? 'primary' : undefined
                        }
                      >
                        Name
                      </PixiDropdown.Item>
                    </>
                  )}
                </PixiDropdown>
              </>
            )}
            {activeFilters?.[filter.row.field as keyof typeof activeFilters] ? (
              <PixiButton
                size="compact-xs"
                onClick={() => {
                  setFilter(filter.row.field as string, null);
                }}
                variant="light"
                ml="5"
              >
                Clear
              </PixiButton>
            ) : (
              <></>
            )}
            {isOpen && status.isLoadingFilters && (
              <Loader
                color={colorScheme === 'dark' ? 'white' : 'gray'}
                size="xs"
                ml="xs"
              />
            )}
          </Group>
        )
      }
      scrollAreaMah={300}
      noChevronOnOpen
      noChevron={
        !!activeFilters?.[filter.row.field as keyof typeof activeFilters]
      }
      rightSectionOnlyOnOpen={
        !activeFilters?.[filter.row.field as keyof typeof activeFilters]
      }
      isRightSectionBeforeChevron
      headerProps={{
        ...(isActive
          ? {
              c: 'primary',
              active: true,
              color: 'primary',
            }
          : {}),
        p: 'sm',
      }}
    >
      <Stack w="100%" gap="3">
        {!sortedData?.length && status?.isLoadingFilters ? (
          <Group w="100%" justify="center">
            <Loader size="sm" color="gray" />
          </Group>
        ) : (
          <></>
        )}
        {sortedData
          .slice(0, page * 10 + 10)
          .map(({ isActive, onClick, ...dataRow }) => {
            return (
              <NavLink
                w="100%"
                key={dataRow.value}
                color={isActive ? 'primary' : 'dark'}
                c={
                  isActive
                    ? 'primary'
                    : colorScheme === 'dark'
                      ? '#FFF'
                      : 'dark'
                }
                active={isActive}
                onClick={() => onClick()}
                leftSection={
                  <PixiIcon
                    name={isActive ? 'square-check' : 'square'}
                    variant={isActive ? 'filled' : undefined}
                  />
                }
                // disabled={status.isLoadingFilters}
                rightSection={
                  <Badge
                    color={
                      isActive
                        ? 'primary'
                        : colorScheme === 'dark'
                          ? 'gray'
                          : 'dark'
                    }
                    variant={isActive ? 'filled' : 'light'}
                  >
                    {dataRow.count}
                  </Badge>
                }
                label={<FilterDataRender filter={row} data={dataRow} />}
              />
            );
          })}
        {sortedData.length > 10 &&
          sortedData.slice(0, page * 10 + 10)?.length !==
            sortedData?.length && (
            <PixiButton
              size="compact-xs"
              color="primary"
              variant="subtle"
              onClick={() => {
                setCurrentPage((page) => page + 1);
              }}
            >
              Load more
            </PixiButton>
          )}
      </Stack>
    </SectionCollapse>
  );
};
export default function AssetGridFilters({
  onClose,
  disable,
  noBackground,
  specificFilters,
  asDropdown,
}: AssetGridFiltersProps) {
  const User = useUserContext();

  // Used when asDropdown is true, its basically the "dropdown page"
  const [openFilterSection, setOpenFilterSection] = useState('');

  const {
    availableFilters,
    frozenFilters,
    currentLibrary,
    setFilter,
    getAvailableFilters,
    activeFilters,
    status,
    currentPage,
    context,
  } = useAssetGridContext();

  const library = User.data?.selectedCommunity?.libraries?.find(
    (l: any) => l.nameId === context.type,
  );
  const orderedKeys = library?.preferences?.filters?.order || [];
  const filterKeys: string[] = [];

  orderedKeys.forEach((key: string) => {
    if (Object.keys(availableFilters).includes(key)) {
      filterKeys.push(key);
    }
  });

  Object.keys(availableFilters)
    .filter((a) => {
      if (specificFilters?.length) {
        return true;
      }
      const filter = availableFilters[a];
      return currentLibrary?.preferences?.filters?.enabled?.find(
        (a) => a.id === (filter.row?.field as string),
      );
    })
    .sort((a, b) => b.localeCompare(a))
    .forEach((key) => {
      if (!filterKeys.includes(key)) {
        filterKeys.push(key);
      }
    });

  const preparedFilters = filterKeys
    .filter((key) => {
      const field = availableFilters?.[key]?.row?.field as string;
      const isActive = !!activeFilters[field];
      const { row } =
        isActive && field !== 'tags'
          ? frozenFilters[key] || availableFilters[key]
          : availableFilters[key];

      if (!row.visible && !specificFilters?.length) {
        return false; // Remove filter
      }
      if (specificFilters?.length && !specificFilters.includes(key)) {
        return false; // Remove filter
      }

      return true; // keep filter
    })
    .map((key) => {
      const field = availableFilters?.[key]?.row?.field as string;
      const isActive = !!activeFilters[field];
      const activeFiltersValue = activeFilters[field];
      const { row, data } =
        isActive && field !== 'tags'
          ? frozenFilters[key] || availableFilters[key]
          : availableFilters[key];
      const { view } = row;

      const preparedData = data
        .filter((dataRow) => dataHasLabel(row, dataRow, context))
        .sort((a, b) => {
          const isAActive = filterRowValueIsActive(
            activeFilters[row.field as string],
            a.value,
          );
          const isBActive = filterRowValueIsActive(
            activeFilters[row.field as string],
            b.value,
          );

          // When both items are either active or not active, sort by .count in descending order.
          if (isAActive === isBActive) {
            // Subtracting counts to sort in descending order. For ascending, flip `b.count` and `a.count`.
            return (b.count as number) - (a.count as number);
          }

          // If only one item is active, it should come first.
          if (isAActive && !isBActive) {
            return -1;
          }
          if (!isAActive && isBActive) {
            return 1;
          }
          return 0;
        })
        .map((dataRow) => {
          const isDataRowActive = filterRowValueIsActive(
            activeFilters[row.field as string],
            dataRow.value,
          );

          const latestCount = availableFilters[key]?.data?.find(
            (filter) => filter.value === dataRow.value,
          )?.count;
          const frozenCount = frozenFilters[key]?.data?.find(
            (frozenFilter) => frozenFilter.value === dataRow.value,
          )?.count;

          return {
            ...dataRow,
            isActive: isDataRowActive,
            count: isDataRowActive ? latestCount || 0 : dataRow.count,
            frozenCount,
            onClick: () => {
              setFilter(row.field as string, [
                {
                  value: (dataRow.value as string) || null,
                  modifier: row.field === 'tags' ? '$and' : '',
                },
              ]);
            },
          };
        });

      return {
        key,
        view,
        data,
        preparedData,
        row,
        activeFiltersValue,
        isActive,
        field,
      };
    });

  if (
    status.errors?.find((v) => v.type === 'filters') &&
    !status.isLoadingFilters
  ) {
    return (
      <Box p="xs">
        <ErrorMessage
          title="An unexpected error occured"
          message="Something went wrong when trying to load your filters. We have been
          notified. Please try later or contact support."
          tryAgain={async () => {
            await getAvailableFilters();
          }}
        />
      </Box>
    );
  }

  if (asDropdown) {
    return preparedFilters.map((filter) => {
      const isOpen = openFilterSection === filter.key;
      return (
        <>
          <PixiDropdown.Item
            key={filter.key}
            onClick={() => {
              setOpenFilterSection(isOpen ? '' : filter.key);
            }}
            mih={0}
            py={0}
            h={42}
            opacity={!openFilterSection || isOpen ? 1 : 0}
            mah={!openFilterSection || isOpen ? 42 : 0}
            style={{
              overflow: 'hidden',
              transition: 'max-height .15s',
              transformOrigin: 'top',
            }}
            pos={openFilterSection ? 'sticky' : undefined}
            top={0}
            withChevron={!filter.isActive}
            color={filter.isActive ? 'primary' : undefined}
            bg={filter.isActive ? 'primary.0' : isOpen ? 'gray.1' : undefined}
            rightSection={
              filter.isActive ? (
                <PixiButton
                  size="compact-xs"
                  px={4}
                  onClick={() => {
                    setFilter(filter.row.field as string, null);
                  }}
                >
                  <PixiIcon name="xmark" size="sm" />
                </PixiButton>
              ) : undefined
            }
            styles={{
              itemSection: {
                transition: 'transform .15s',
                transform: isOpen ? 'rotate(90deg)' : 'rotate(0deg)',
              },
            }}
          >
            {filter.view.name}
          </PixiDropdown.Item>
          {isOpen && (
            <>
              {filter.preparedData.map(({ isActive, onClick, ...dataRow }) => (
                <PixiDropdown.Item
                  key={dataRow.key}
                  color={isActive ? 'primary' : undefined}
                  onClick={() => {
                    onClick();
                  }}
                  disabled={status.isLoadingFilters}
                  leftSection={
                    isActive ? (
                      <PixiIcon name="square-check" variant="filled" />
                    ) : (
                      <Badge size="xs" color="gray" variant="light">
                        {dataRow.count}
                      </Badge>
                    )
                  }
                >
                  <FilterDataRender filter={filter.row} data={dataRow} />
                </PixiDropdown.Item>
              ))}
            </>
          )}
        </>
      );
    });
  }

  return (
    <Stack w="100%" gap={noBackground ? '5' : 0}>
      {preparedFilters.map((filter) => (
        <FilterSection
          key={filter.key}
          filter={filter}
          isActive={filter.isActive}
          row={filter.row}
          preparedData={filter.preparedData}
          view={filter.view}
          noBackground={noBackground}
        />
      ))}
    </Stack>
  );
}
