import { Button } from 'elements_v2';
import Card from 'elements_v2/Card';

export default function Playground(props) {
  return (
    <div style={{ width: 1200, height: 800, background: '#FFF', padding: 45 }}>
      <Card>
        <Card.Header onClose={() => console.info('CLosable')}>
          <h1>This is a card title</h1>
          <h2>This is a card subtitle</h2>
        </Card.Header>
        <Card.Image>
          <img src="https://picsum.photos/400/250" />
        </Card.Image>
        <Card.Text>
          <p>HEllo!</p>
        </Card.Text>
        <Card.Actions>
          <Button inline>Cancel</Button>
          <Button inline primary>
            Save
          </Button>
        </Card.Actions>
      </Card>
      <Card>
        <Card.Header>
          <h1>This is a card title</h1>
          <h2>This is a card subtitle</h2>
        </Card.Header>
        <Card.Image>
          <img src="https://picsum.photos/400/250?id=251" />
        </Card.Image>
        <Card.Actions>
          <Button inline>Cancel</Button>
          <Button inline primary>
            Save
          </Button>
        </Card.Actions>
      </Card>
      <Card>
        <Card.Header>
          <h1>This is a card title</h1>
          <h2>This is a card subtitle</h2>
        </Card.Header>
        <Card.Actions>
          <Button inline>Cancel</Button>
          <Button inline primary>
            Save
          </Button>
        </Card.Actions>
      </Card>
    </div>
  );
}
