
import { useDocumentsContext } from 'hooks/useDocumentsContext';
import { useMediaContext } from 'hooks/useMediaContext';
import { sleep } from 'utils';
import asyncPool from 'utils/async-pool';

const fields = [
  'id',
  'path_collection',
  'type',
  'item_collection',
  'parent',
  'name',
  'content_modified_at',
  'created_by',
  'size',
  'sha1',
  'extension',
  'shared_link',
];

export default function useBoxAPI(props) {
  const { BoxAuth } = props;
  const Media = useMediaContext();
  const Documents = useDocumentsContext();

  function getFoldersFromItems(items) {
    return items.filter((i) => i?.type === 'folder');
  }
  function getFilesFromItems(items) {
    return items.filter((i) => i?.type === 'file');
  }

  async function getAllFolderItems(itemId, driveId, signal, onProgress, isNested, callback) {
    const items = await getDriveItems(itemId, true, undefined, signal);
    const results = [];
    if(signal?.aborted) return [];
    if(onProgress) {
      onProgress({
        total: items.length,
      });
    }
    if (!isNested) return items;
    try {
      await sleep(500);
      await asyncPool(10, items, async (item) => {
        if (item?.type === 'folder') {
          callback?.({
            code: 'FETCHING_ALL_FILES',
            data: item,
            isLoading: true,
          });
          const folderItems = await getAllFolderItems(
            item.id,
            driveId,
            signal,
            onProgress,
            true,
            callback
          );
          await sleep(500);
          results.push(...folderItems);
        }
        results.push({ ...item, folderId: itemId });
      });
    } catch (e) {
      
      console.error(e);
    }
    return results;
  }

  async function getAllFolderItemsNested(itemId, driveId, signal, onProgress, callback) {
    return getAllFolderItems(itemId, driveId, signal, onProgress, true, callback);
  }

  async function getDriveItems(id = 0, getEverything = true, offset = 0, signal) {
    if (getEverything === 0) {
      getEverything = true;
    }
    const data = await BoxAuth.request(
      `/2.0/folders/${id}/items?limit=1000&offset=${offset}&fields=${fields}`,
      {
        method: 'GET',
      },
      undefined,
      undefined,
      undefined,
      signal,
    );
    if(signal?.aborted) return [];
    if (getEverything && offset + data?.entries?.length < data?.total_count) {
      const items = await getDriveItems(
        id,
        true,
        offset + data?.entries?.length
      );
      data.entries = [...data.entries, ...items];
    }

    if (data?.entries?.length) {
      data.entries = data.entries.sort((a, b) => a.name.localeCompare(b.name));
    }
    return data.entries;
  }

  async function shareItem(driveId, itemId, seamlessType, seamless) {
    const data = await BoxAuth.request(
      `/2.0/files/${itemId}?fields=shared_link`,
      {
        method: 'PUT',
        body: {
          shared_link: {
            access: 'open',
            ...(seamless ? { permissions: { can_download: true } } : {}),
          },
        },
      }
    );
    return data;
  }

  async function getDriveItem(itemId, driveId) {
    if (driveId === 'folder') {
      const data = await BoxAuth.request(
        `/2.0/folders/${itemId}?fields=${fields.join(',')}`,
        {
          method: 'GET',
        }
      );
      return data;
    }

    const data = await BoxAuth.request(
      `/2.0/files/${itemId}?fields=${fields.join(',')}`,
      {
        method: 'GET',
      }
    );
    return data;
  }
  async function search(query, options) {
    const data = await BoxAuth.request(
      `/2.0/search?query=${encodeURIComponent(query)}&fields=${fields.join(
        ','
      )}${options.offset ? `&offset=${options.offset}` : ''}`,
      {
        method: 'GET',
      }
    );

    return data;
  }
  async function getFolder(itemId) {
    return getDriveItem(itemId, 'folder');
  }

  const translations = {
    FETCHING_DRIVE: 'Fetching folder from Box',
    FETCHING_ALL_FILES: 'Fetching all files from Box',
    CHECKING_ALREADY_IMPORTED: 'Checking for any file changes',
    REPLACING_UPDATED_FILES: 'Replacing changed files',
    CONNECTED: 'Starting upload',
    UPLOADING: 'Uploading file',
    PROCESSING: 'Processing file',
    AI: 'Processing file (AI)',
    JOB_CHECKED: 'All files imported',
    IMPORTING_FILES: 'Uploading files',
    ADDING_FILES_TO_COLLECTION: 'Adding already uploaded files to collection',
    ERROR_COLLECTION_DELETED: 'Sync failed. Collection has been deleted.',
    SOMETHING_WENT_WRONG: 'Sync failed due to an unknown reason.',
  };

  function translateCode(code, status) {
    if (translations[code]) {
      return (
        translations[code] +
        (code === 'IMPORTING_FILES' && status?.total
          ? ` (${status?.current}/${status?.total})`
          : '')
      );
    }
    return code;
  }

  async function processFileForImport(file, job, items) {
    const fileData = await getDriveItem(getValue('id', file));

    let sharedFile = {};
    if (job?.preferences?.externalFiles) {
      sharedFile = await shareItem(
        undefined,
        getValue('id', fileData),
        undefined,
        job?.preferences?.externalSeamlessDownload
      );
    }
    // Temporary access only
    let accessUrl = `https://api.box.com/2.0/files/${getValue(
      'id',
      fileData
    )}/content`;
    if (job?.preferences?.externalFiles) {
      accessUrl = sharedFile?.shared_link?.url;
      if (job?.preferences?.externalSeamlessDownload) {
        accessUrl = sharedFile?.shared_link?.download_url;
      }
    }
    return {
      accessUrl,
      file: {
        name: getValue('name', fileData),

        file: {
          url: accessUrl,
          open_url:
            job?.preferences?.externalFiles &&
            job?.preferences?.externalSeamlessDownload
              ? sharedFile?.shared_link?.url
              : undefined,
          ext: getValue('extension', fileData),
          external_file: job?.preferences?.externalFiles,
          name: getValue('name', fileData),
        },

        import: {
          from: props.API_ID,
          reference: getValue('id', fileData),
          referenceLocation: getValue('idPath', fileData, items),
          source: job?._id,
          checksum: getValue('hash', fileData),
        },
        uploadedAt: getValue('lastModified', fileData),
        collections: job?.libraryCollection
          ? [job?.libraryCollection]
          : undefined,
        .../* job?.ownerOverrideRequireApproval ?? */ (job?.requireApproval
          ? {
              approval: {
                status: 'none',
                status_change_reason: 'imported',
              },
            }
          : {}),
      },

      params: {
        ...getImportParams(fileData, job),
      },
    };
  }
  const getImportParams = (file, job) => {
    return {
      external_file: job?.preferences?.externalFiles,
      thumbnail_from: `https://api.box.com/2.0/files/${getValue(
        'id',
        file
      )}/content`,
      thumbnail_from_ext: getValue('extension', file),
      requestHeaders: {
        authorization: `Bearer ${BoxAuth.token.access_token}`,
      },
    };
  };
  function createItemPath(item, currentPath = [], items) {
    if (item?.parent.id) {
      const parent = items.find((i) => i.id === item?.parent.id);
      if (parent) {
        item.path = [parent?.id, ...currentPath];
        if (parent?.parent.id) {
          return createItemPath(parent, item.path, items);
        }
      } else {
        item.path = currentPath;
      }
    } else {
      item.path = [];
    }
    return item.path.join('/');
  }

  function getValue(key, item, preferences = {}) {
    switch (key) {
      case 'id':
        return item.id ?? 0;
      // TODO: chcek if correct
      case 'idPath':
        return preferences.items
          ? createItemPath(item, [], preferences?.items)
          : getValue('folderParentReferenceId', item);
      case 'driveId':
        return undefined;
      case 'isFolder':
        return item?.type === 'folder';
      case 'isFolderEmpty':
        return item?.item_collection?.total_count === 0;
      case 'folderParentReferenceId':
        return item.parent.id; // This is saved from nested folder
      case 'name':
        return item.name;
      case 'lastModified':
        return item.content_modified_at;
      case 'createdBy':
        return item.created_by?.name;
      case 'size':
        return item?.size;
      case 'hash':
        return item.sha1;
      case 'extension':
        return item.extension;
      case 'downloadUrl':
        return item.shared_link?.download_url;
      case 'openUrl':
        return item.shared_link?.download_url;
      case 'pathString':
        return;
      default:
        throw new Error(
          item,
          'failed getting api item value due to invalid or missing key'
        );
    }
  }

  return {
    getDriveItems,
    getAllFolderItemsNested,
    getDriveItem,
    search,
    getAllFolderItems,
    getFoldersFromItems,
    getFilesFromItems,
    getFolder,
    translateCode,
    processFileForImport,
    getValue,
    getImportParams,

    isConnected: BoxAuth.isConnected,

    importedFiles: [
      ...(Media.data.imported_refs_box || []),
      ...(Documents.data.imported_refs_box || []),
    ],
  };
}
