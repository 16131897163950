import * as yup from 'yup';
import React from 'react';
import Icon from './icon.svg?react';
import IconImage from './icon_image.svg?react';

export default [
  {
    moduleId: 'VIDEO',
    menuGroupIds: ['VIDEO'],
    moduleInterface: {
      file: 'FILE',
      embed: 'EMBED_VIDEO',
    },
    status: {
      isEmpty: (data) => {
        return !data?.file?.fileId && !data?.embed?.url;
      },
    },
    moduleMetadataInterface: {
      withPadding: yup.boolean(),
      backgroundColor: yup.string(),
      backgroundVideo: yup.boolean(),
    },
    menuContent: { key: 'VIDEO', icon: <Icon />, name: 'Video' },
  },
  {
    moduleId: 'IMAGE',
    menuGroupIds: ['HEADER'],
    moduleInterface: {
      file: 'FILE',
    },
    status: {
      isEmpty: (data) => {
        return !data?.file?.fileId;
      },
    },
    moduleMetadataInterface: {
      withPadding: yup.boolean(),
      backgroundColor: yup.string(),
      fileVariantId: yup.string(),
      imageDownloadable: yup.boolean(),
    },
    menuContent: { key: 'IMAGE', icon: <IconImage />, name: 'Image' },
  },
];
