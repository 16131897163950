import React, { useState } from 'react';
import { Button } from 'pickit-elements';
import { format, isValid } from 'date-fns';
import { useDocumentsContext, useTextContext } from 'hooks';
import useStorageUrl from 'hooks/files/useStorageUrl';
import { downloadUrl } from 'utils';

export function useLicenseObject(license) {
  const Text = useTextContext();
  const { generateUrl } = useStorageUrl();
  const mapper = {
    copyright: Text.get('view/contribution/license_fields/copyright'),
    licensor: Text.get('view/contribution/license_fields/licensor'),
    releaseAcquired: Text.get(
      'view/contribution/license_fields/releaseAcquired'
    ),
    license: Text.get('view/contribution/license_fields/license'),
    name: Text.get('view/contribution/license_fields/name'),
    startDate: Text.get('view/contribution/license_fields/startDate'),
    expirationDate: Text.get('view/contribution/license_fields/expirationDate'),
    usage: Text.get('view/contribution/license_fields/usage'),
    exclusive: Text.get('view/contribution/license_fields/rights'),
    geography: Text.get('view/contribution/license_fields/geography'),
    url: Text.get('view/contribution/license_fields/url'),
    attribution: Text.get('view/contribution/license_fields/attribution'),
    additionalNotes: Text.get(
      'view/contribution/license_fields/additionalNotes'
    ),
    propertyReleases: Text.get(
      'view/contribution/license_fields/propertyReleases'
    ),
    modelReleases: Text.get('view/contribution/license_fields/modelReleases'),
    licenseFiles: Text.get('view/contribution/license_fields/licenseFiles'),
  };

  const details = Object.keys(mapper).reduce((details, key) => {
    const label = mapper?.[key];
    let value = license?.[key];

    if (key === 'license' && value === 'Custom') {
      value = license?.name || 'Custom License';
    }

    if (
      (typeof value === 'string' || typeof value === 'number') &&
      isValid(new Date(value))
    ) {
      value = format(new Date(value), 'yyyy-MM-dd');
    }

    if (typeof value === 'string') {
      details[label] = value;
    }
    if (Array.isArray(value) && typeof value[0] === 'string') {
      details[label] = value.join(', ');
    }
    if (typeof value === 'boolean' && key === 'exclusive') {
      details[label] = value ? 'Exclusive' : 'Non Exclusive';
    }
    if (
      ['propertyReleases', 'modelReleases', 'licenseFiles'].includes(key) &&
      value
    ) {
      details[label] = value.map((file) => (
        <button
          onClick={(e) =>
            downloadUrl(generateUrl(file.url), decodeURI(file.name))
          }
          key={file.id}
          style={{
            maxWidth: '100%',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            cursor: 'pointer',
            margin: 0,
            paddding: 0,
            border: 'none',
            background: 'none',
            color: '#6716d8',
            display: 'inline',
            textAlign: 'right',
          }}
          title={decodeURI(file?.name)}
        >
          {decodeURI(file.name)}
        </button>
      ));
      if (details[label].length === 0) {
        delete details[label];
      }
    }

    return details;
  }, {});
  if (details.Name === details['Image license']) {
    delete details?.Name;
  }
  return details;
}

export function License({ license }) {
  const Documents = useDocumentsContext();
  const { generateUrl } = useStorageUrl(Documents, 'licenses');
  const Text = useTextContext();
  const [readMore, toggle] = useState(false);
  const mapper = {
    copyright: Text.get('view/contribution/license_fields/copyright'),
    licensor: Text.get('view/contribution/license_fields/licensor'),
    releaseAcquired: Text.get(
      'view/contribution/license_fields/releaseAcquired'
    ),
    license: Text.get('view/contribution/license_fields/license'),
    name: Text.get('view/contribution/license_fields/name'),
    startDate: Text.get('view/contribution/license_fields/startDate'),
    expirationDate: Text.get('view/contribution/license_fields/expirationDate'),
    usage: Text.get('view/contribution/license_fields/usage'),
    exclusive: Text.get('view/contribution/license_fields/rights'),
    geography: Text.get('view/contribution/license_fields/geography'),
    url: Text.get('view/contribution/license_fields/url'),
    attribution: Text.get('view/contribution/license_fields/attribution'),
    additionalNotes: Text.get(
      'view/contribution/license_fields/additionalNotes'
    ),
    propertyReleases: Text.get(
      'view/contribution/license_fields/propertyReleases'
    ),
    modelReleases: Text.get('view/contribution/license_fields/modelReleases'),
    licenseFiles: Text.get('view/contribution/license_fields/licenseFiles'),
  };
  const expression =
    /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;
  const regex = new RegExp(expression);
  return (
    <div className="LicenseDetails">
      <div className="LicenseDetails__content">
        <div className="LicenseDetails__content__table">
          {Object.entries(mapper).map(([key, value]) => {
            if (
              (!license[key] ||
                (!license[key].length && typeof license[key] !== 'number')) &&
              typeof license[key] !== 'boolean'
            ) {
              return false;
            }
            if (key === 'license' || key === 'name') {
              let name = license[key];
              if (name === 'Custom' && license.name) {
                name = license.name;
              } else if (name === 'Custom') {
                name = 'Custom License';
              }
              if (key === 'name') {
                return false;
              }
              return (
                <div className="LicenseDetails__content__table__row">
                  <span>{value}</span>
                  <span>{name}</span>
                </div>
              );
            }
            if (key === 'attribution') {
              return (
                <div className="LicenseDetails__content__table__column">
                  <span>{value}</span>
                  <div style={{ maxWidth: '200px', wordBreak: 'break-word' }}>
                    {license[key]}
                  </div>
                </div>
              );
            }
            if (key === 'url') {
              let url = license[key];
              if (url.includes('www.') && !url.includes('http')) {
                url = `https://${url}`;
              }
              return (
                <div className="LicenseDetails__content__table__row">
                  <span>{value}</span>
                  {url.match(regex) ? (
                    <a rel="noopener noreferrer" target="_blank" href={url}>
                      Link to terms
                    </a>
                  ) : (
                    <span>{url}</span>
                  )}
                </div>
              );
            }
            if (key === 'additionalNotes') {
              return (
                <div className="LicenseDetails__content__table__column">
                  <span>{value}</span>
                  <div
                    style={{
                      maxHeight: readMore ? 'none' : '100px',
                      overflow: 'hidden',
                      position: 'relative',
                      maxWidth: '200px',
                    }}
                  >
                    <span
                      style={{
                        margin: '8px 0',
                        display: 'block',
                        whiteSpace: 'pre-wrap',
                        wordWrap: 'break-word',
                      }}
                    >
                      {license[key]}
                    </span>
                    {!readMore && license[key].length > 160 && (
                      <div
                        style={{
                          backgroundImage:
                            'linear-gradient(to bottom, rgba(255,255,255,0), rgba(255,255,255,1))',
                          position: 'absolute',
                          top: 0,
                          bottom: 0,
                          right: 0,
                          left: 0,
                        }}
                      />
                    )}
                  </div>
                  {!readMore && license[key].length > 160 && (
                    <Button
                      onClick={() => toggle(true)}
                      color="primary"
                      size="small"
                      variant="link"
                    >
                      Read more
                    </Button>
                  )}
                </div>
              );
            }
            if (key === 'releaseAcquired') {
              return (
                <div className="LicenseDetails__content__table__row">
                  <span>Releases</span>
                  <span>
                    {license[key] ? 'Release acquired' : 'Releases missing'}
                  </span>
                </div>
              );
            }
            if (key.toLowerCase().includes('date')) {
              return (
                <div className="LicenseDetails__content__table__row">
                  <span>{value}</span>
                  <span>{format(new Date(license[key]), 'yyyy-MM-dd')}</span>
                </div>
              );
            }
            if (
              key === 'propertyReleases' ||
              key === 'licenseFiles' ||
              key === 'modelReleases'
            ) {
              return (
                <>
                  <div className="LicenseDetails__content__table__row">
                    <span>{value}</span>
                  </div>
                  <div
                    style={{
                      fontSize: '12px',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-end',
                    }}
                  >
                    {license[key].map((file) => (
                      <a
                        rel="noopener noreferrer"
                        href={generateUrl(file.url)}
                        key={file.url}
                        target="_BLANK"
                        download
                      >
                        {decodeURI(file?.name)}
                      </a>
                    ))}
                  </div>
                </>
              );
            }
            if (Array.isArray(license[key])) {
              return (
                <div className="LicenseDetails__content__table__row">
                  <span>{value}</span>
                  <span>{license[key].reduce((a, b) => `${a}, ${b}`)}</span>
                </div>
              );
            }
            if (key === 'exclusive') {
              return (
                <div className="LicenseDetails__content__table__row">
                  <span>{value}</span>
                  <span>{license[key] ? 'Exclusive' : 'Non Exclusive'}</span>
                </div>
              );
            }
            return (
              <div className="LicenseDetails__content__table__row" key={value}>
                <span>{value}</span>
                <span>{license[key]}</span>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
