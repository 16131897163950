import { Text, TextProps, Title, TitleProps } from '@mantine/core';
import { ForwardedRef, forwardRef } from 'react';

export interface PixiTextProps extends TextProps {
  children: React.ReactNode;
}
export interface PixiTitleProps extends TitleProps {
  children: React.ReactNode;
}

const PixiText = forwardRef(
  (props: PixiTextProps, ref: ForwardedRef<HTMLParagraphElement>) => {
    const { children, ...rest } = props;
    return (
      <Text {...rest} c={rest.c || 'inherit'} ref={ref}>
        {children}
      </Text>
    );
  },
);

export default PixiText;

export function PixiTitle({ children, ...rest }: PixiTitleProps) {
  return <Title {...rest}>{children}</Title>;
}
