import { Card, Group, Stack } from '@mantine/core';
import { useColorScheme } from '@pixi/AppController';
import PixiText from '@pixi/elements/Text';
import Color from 'color';
import Badge from 'elements_v2/Badge';
import { useTextContext, useUserContext } from 'hooks';
import useParsedFile from 'hooks/files/useParsedFile';
import useContextFromFile from 'hooks/useContextFromFile';
import useUserStatus from 'hooks/useUserStatus';
import { ReactNode } from 'react';
import { bytesToSize } from 'utils';
import { getFontWeights } from 'views/_Manage/BrandAssets/parts/forms/FontForm';
import PropertyValue from '@pixi/components/AssetWorkflows/Properties/PropertyValue';
import { useLicenseObject } from 'views/Files/parts/License';

export const AssetPreviewDetails = ({
  file,
  view,
}: {
  file: Pickit.FileInterface;
  view: string;
}) => {
  const colorScheme = useColorScheme();
  const context = useContextFromFile(file);
  const metadata: Pickit.FileInterface = useParsedFile({ context, file });
  const Text: any = useTextContext();
  const User = useUserContext();
  const license: any = useLicenseObject(metadata?.license || {});
  const userStatus: any = useUserStatus();

  let details: Record<string, ReactNode> = {};
  const metadataDetails: Record<string, ReactNode> = {};

  details = {
    ...details,
    Name: metadata?.name,
    [Text.get('ui/size')]: metadata?.file?.contentLength
      ? bytesToSize(metadata?.file?.contentLength)
      : undefined,
    [Text.get('ui/dimensions')]: metadata.file?.image_details?.width
      ? `${metadata.file?.image_details?.width} x ${metadata?.file?.image_details?.height}`
      : undefined,
    [Text.get('ui/type')]: (
      <Badge color={colorScheme === 'dark' ? 'gray' : 'dark'} variant="outline">
        {metadata?.file?.ext || metadata?.file?.contentType}
      </Badge>
    ),
    ...(metadata?.author ? { Author: metadata?.author } : {}),
    ...(metadata?.file?.version &&
      (metadata?.file?.version > 1
        ? { Version: metadata?.file?.version }
        : {})),
  };

  if (!metadata?.file?.ext || !metadata?.file?.contentType) {
    delete details[Text.get('ui/type')];
  }
  if (typeof details[Text.get('ui/size')] === 'undefined') {
    delete details[Text.get('ui/size')];
  }

  if (metadata.file?.image_details?.width) {
    details[Text.get('ui/dimensions')] =
      `${metadata.file?.image_details?.width} x ${metadata?.file?.image_details?.height}`;
  } else {
    delete details[Text.get('ui/dimensions')];
  }

  const previewableAttributes: any[] =
    User?.data?.selectedCommunity?.settings?.attributes?.previewable;
  if (previewableAttributes?.length) {
    previewableAttributes.forEach((row) => {
      const customLabel =
        User?.data?.selectedCommunity?.settings?.attributes?.labels?.find(
          (label: any) => label.field === row,
        )?.label;
      if (metadata?.attributes?.[row]) {
        metadataDetails[customLabel || row] =
          typeof metadata?.attributes?.[row] === 'string'
            ? metadata?.attributes?.[row]
            : '';
      }
    });
  }

  let color;
  if (metadata?.data?.type === 'color') {
    try {
      color = Color(metadata.data?.value?.color);
    } catch (e) {
      color = Color('#6716d8');
    }
  }

  const fontWeights = getFontWeights(true);

  function DetailCard({
    title,
    text,
    table,
  }: {
    title: ReactNode;
    text?: ReactNode;
    table?: { key: ReactNode; value: ReactNode; id: string }[];
  }) {
    return (
      <Card
        padding="lg"
        withBorder
        bg={colorScheme === 'dark' ? 'dark.6' : 'gray.0'}
      >
        <Group justify="space-between" mb="xs">
          <PixiText fw={500}>{title}</PixiText>
        </Group>
        {!!text && (
          <PixiText size="sm" c="dimmed">
            {text}
          </PixiText>
        )}
        {!!table?.length && (
          <Stack gap="xs">
            {table.map(({ key, value, id }) => (
              <Group
                justify="space-between"
                align="flex-start"
                key={id}
                wrap="nowrap"
              >
                <PixiText size="sm" c="dimmed">
                  {key}
                </PixiText>
                <PixiText
                  size="sm"
                  fw="500"
                  c={colorScheme === 'dark' ? 'white' : 'dark'}
                  ta="right"
                  style={{
                    wordWrap: 'break-word',
                    overflow: 'hidden',
                  }}
                >
                  {value}
                </PixiText>
              </Group>
            ))}
          </Stack>
        )}
      </Card>
    );
  }

  const currentLibrary = User.data?.selectedCommunity?.libraries?.find(
    (l: Pickit.CommunityLibraryInterface) =>
      metadata?.libraries?.includes(l.nameId),
  );
  const properties = User.data?.selectedCommunity?.properties?.data?.filter(
    (p: Pickit.CommunityPropertyInterface) => {
      return currentLibrary?.preferences?.properties?.includes(p._id);
    },
  ) as Pickit.CommunityPropertyInterface[];

  return (
    <Stack gap="xs" p="xs" pl="0">
      {metadata?.description && (
        <DetailCard
          title={Text.get('ui/description')}
          text={metadata?.description}
        />
      )}
      {!!metadata?.tags?.length && (
        <DetailCard
          title={Text.get('ui/tags')}
          text={metadata?.tags?.join(', ')}
        />
      )}
      <DetailCard
        title="Information"
        table={Object.keys(details).map((label) => ({
          key: label,
          value: details[label],
          id: label,
        }))}
      />
      {metadata?.data?.type === 'color' && (
        <DetailCard
          title="Color"
          table={[
            {
              key: 'Pantone',
              value: metadata.data?.value?.pantone,
              id: 'pantone',
            },
            { key: 'CMYK', value: metadata.data?.value?.cmyk, id: 'cmyk' },
            { key: 'RGB', value: color?.rgb().array().join(', '), id: 'rgb' },
            { key: 'HEX', value: color?.hex(), id: 'hex' },
          ]}
        />
      )}
      {metadata?.data?.type === 'font-family' && (
        <DetailCard
          title="Font"
          table={fontWeights.map((font) => ({
            key: font.text,
            value: metadata?.data?.value?.[font.value],
            id: font.value,
          }))}
        />
      )}
      {!!Object.keys(metadataDetails)?.length && (
        <DetailCard
          title="Metadata"
          table={Object.keys(metadataDetails).map((label) => ({
            key: label,
            value: metadataDetails[label],
            id: label,
          }))}
        />
      )}
      {!!properties?.filter((p) => {
        return !!metadata?.properties?.[p._id as any];
      })?.length && (
        <DetailCard
          title="Properties"
          table={properties
            .filter((p) => {
              return !!metadata?.properties?.[p._id as any];
            })
            .map((p) => ({
              key: p.name as string,
              value: (
                <PropertyValue
                  property={p}
                  value={
                    metadata?.properties?.[p._id as any] as Pickit.PropertyValue
                  }
                />
              ),
              id: p._id as string,
            }))}
        />
      )}
      {!!Object.keys(license)?.length &&
        userStatus?.activeServices?.license_management && (
          <DetailCard
            title="License"
            table={Object.keys(license).map((label) => ({
              key: label,
              value: license[label],
              id: label,
            }))}
          />
        )}
    </Stack>
  );
};
