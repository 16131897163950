import {
  DataStoresList,
  getConfigStore,
  getStore,
  useConfigStoreValue,
} from '@pixi/store';

import {
  AssetPreviewFacialRecognitionPreferences,
  AssetPreviewProps,
} from '@pixi/components/AssetPreview/types';
import { AssetGridProps } from './components/AssetGrid/Helpers';
import { PixiPopupProps } from './elements/Popup';
import { ReactNode } from 'react';
import { SelectFileProps } from './components/SelectFile';

export interface AppViewInterface {
  isManage: boolean;
  viewport: 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  colorScheme: 'light' | 'dark';
}
export interface AppConfigInterface {
  termsDownload?: {
    id?: string; // used to determine if new messag should be bound.
    active?: boolean;
    message?: ReactNode;
  };
  termsLogin?: {
    id?: string; // used to determine if new messag should be bound.
    active?: boolean;
    message?: ReactNode;
  };
  draggingAsset: {
    type?: 'file' | 'collection' | 'collectionGroup';
    id?: string;
  };
  collectionPreview: {
    collection?: Pickit.CollectionInterface;
    prefs?: {
      assetGridProps?: Partial<AssetGridProps>;
      popupProps?: Omit<Partial<PixiPopupProps>, 'onSelect'>;
      readOnly?: boolean;
      openAssetPreviewPrefs?: (
        file: Pickit.FileInterface,
      ) => Partial<AppConfigInterface['filePreview']['prefs']>;
    };
  };
  fileBrowser: {
    type?: 'media' | 'documents';
    isOpen: boolean;
    prefs?: {
      assetGridProps?: Partial<AssetGridProps>;
      popupProps?: Omit<Partial<PixiPopupProps>, 'onSelect'>;
    };
  };
  fileImageEditor: {
    file?: Pickit.FileInterface;
    prefs?: {
      onComplete?: (file: File, settings?: { design?: any }) => void;
      forceCropping?: {
        width: number;
        height: number;
      };
    };
  };
  fileSettings: {
    file?: Pickit.FileInterface;
    prefs?: {
      files?: Pickit.FileInterface[];
      defaultTab?: string;
    };
  };
  filePreview: {
    file?: Pickit.FileInterface;
    prefs?: {
      defaultView?: AssetPreviewProps['defaultView'];
      facialRecognition?: AssetPreviewFacialRecognitionPreferences;
      customSpecificActions?: AssetPreviewProps['customSpecificActions'];
      keys?: AssetPreviewProps['keys'];
      onClose?: () => void;
      previewFileProps?: Partial<AssetPreviewProps>;
      extraActions?: AssetPreviewProps['extraActions'];
      disableDownload?: boolean;
    };
  };
  filePanel: {
    files: Pickit.FileInterface[];
    prefs?: {};
  };
  selectFile: {
    selectButtonLabel?:
      | string
      | ((filesSelected: Pickit.FileInterface[]) => string);
    callback?: (file: Pickit.FileInterface[]) => void;
    onClose?: () => void;
    libraryId?: string;
    assetGridProps?: Partial<AssetGridProps>;
    multiple?: boolean;
    view?: SelectFileProps['view'];
    panelProps?: SelectFileProps['panelProps'];
    customActions?: SelectFileProps['customActions'];
    popupProps?: Omit<Partial<PixiPopupProps>, 'onSelect'>;
  };
  librarySettings: {
    isOpen?: boolean;
    defaultSection?: string;
    libraryId?: string;
  };
}
export const AppView: AppViewInterface = {
  isManage: false,
  viewport: 'xl',
  colorScheme: 'light',
};
export const AppConfig: AppConfigInterface = {
  draggingAsset: {},
  termsDownload: {},
  termsLogin: {},
  fileBrowser: {
    isOpen: false,
  },
  fileImageEditor: {
    file: undefined,
  },
  fileSettings: {
    file: undefined,
  },
  filePreview: {
    file: undefined,
  },
  collectionPreview: {
    collection: undefined,
  },
  filePanel: {
    files: [],
  },
  librarySettings: {
    isOpen: false,
  },
  selectFile: {},
};

export async function selectFile(
  props: Omit<AppConfigInterface['selectFile'], 'callback'>,
) {
  return new Promise<Pickit.FileInterface[]>((resolve, reject) => {
    getConfigStore('APP_CONFIG').setValue('selectFile', {
      ...props,
      callback: resolve,
      onClose: () => {
        props.onClose?.();
        resolve([]);
        getConfigStore('APP_CONFIG').setValue('selectFile', {});
      },
    });
  });
}

export function disableDownloadTerms() {
  getConfigStore('APP_CONFIG').setValue('termsDownload', {});
}
export function disableLoginTerms() {
  getConfigStore('APP_CONFIG').setValue('termsLogin', {});
}
export function setDownloadTerms(id: string, message: ReactNode) {
  getConfigStore('APP_CONFIG').setValue('termsDownload', {
    active: true,
    message,
    id,
  });
}
export function setLoginTerms(id: string, message: ReactNode) {
  getConfigStore('APP_CONFIG').setValue('termsLogin', {
    active: true,
    message,
    id,
  });
}

export function toggleColorScheme(colorScheme: 'light' | 'dark') {
  getConfigStore('APP_VIEW').setValue('colorScheme', colorScheme);
}
export function useColorScheme() {
  const colorScheme = useConfigStoreValue('APP_VIEW', 'colorScheme');
  return colorScheme;
}
export function useAppViewport() {
  const viewport = useConfigStoreValue('APP_VIEW', 'viewport');

  const sizeOrder = {
    xxs: 0,
    xs: 1,
    sm: 2,
    md: 3,
    lg: 4,
    xl: 5,
  };

  return {
    viewport,
    isLessThan: (size: keyof typeof sizeOrder) => {
      return sizeOrder[viewport] < sizeOrder[size];
    },
    isGreaterThan: (size: keyof typeof sizeOrder) => {
      return sizeOrder[viewport] > sizeOrder[size];
    },
  };
}

export function openAssetPreview(
  file: Pickit.FileInterface,
  prefs?: AppConfigInterface['filePreview']['prefs'],
) {
  getConfigStore('APP_CONFIG').setValue('filePreview', {
    file,
    prefs,
  });
}
export function closeAssetPreview() {
  getConfigStore('APP_CONFIG').setValue('filePreview', {
    file: undefined,
    prefs: {},
  });
}

export function openCollectionPreview(
  collection: Pickit.CollectionInterface,
  prefs?: AppConfigInterface['collectionPreview']['prefs'],
) {
  getConfigStore('APP_CONFIG').setValue('collectionPreview', {
    collection,
    prefs,
  });
}

export function closeCollectionPreview() {
  getConfigStore('APP_CONFIG').setValue('collectionPreview', {
    collection: undefined,
    prefs: {},
  });
}

export function openFileBrowser(
  type: 'media' | 'documents',
  prefs?: AppConfigInterface['fileBrowser']['prefs'],
) {
  getConfigStore('APP_CONFIG').setValue('fileBrowser', {
    isOpen: true,
    type,
    prefs,
  });
}

export function closeFileBrowser(
  prefs?: AppConfigInterface['fileBrowser']['prefs'],
) {
  getConfigStore('APP_CONFIG').setValue('fileBrowser', {
    isOpen: false,
  });
}
export function openFilePanel(
  files: Pickit.FileInterface | Pickit.FileInterface[],
  prefs?: {
    toggle?: boolean;
    replace?: boolean;
    reset?: boolean;
  },
) {
  const { toggle, replace, reset } = prefs || {};
  const openedFiles =
    getConfigStore('APP_CONFIG').getValue('filePanel')?.files || [];
  const newFiles = (Array.isArray(files) ? files : [files]).filter(
    (f) => !openedFiles.find((fa) => f._id === fa._id),
  );
  getConfigStore('APP_CONFIG').setValue('filePanel', {
    files: reset
      ? newFiles
      : [...openedFiles, ...newFiles].filter((file) => {
          const isNewFile = newFiles.find((f) => f._id === file._id);
          const isOldFile = openedFiles.find((f) => f._id === file._id);
          const isNewAndOldFile = isNewFile && isOldFile;

          if (toggle && isOldFile && isNewFile) {
            return true;
          }
          if (toggle && isOldFile) {
            return false;
          }

          return true;
        }),
  });
}
export function closeFilePanel() {
  getConfigStore('APP_CONFIG').setValue('filePanel', {
    files: [],
  });
}

export function openLibrarySettings(type: string, defaultSection?: string) {
  getConfigStore('APP_CONFIG').setValue('librarySettings', {
    isOpen: true,
    defaultSection,
    libraryId: type,
  });
}
export function closeLibrarySettings() {
  getConfigStore('APP_CONFIG').setValue('librarySettings', {
    isOpen: false,
  });
}

export function createAppToast(toast: DataStoresList['APP_TOASTS'][0]) {
  getStore('APP_TOASTS').add(toast);
  setTimeout(() => {
    // Mark the toast as visible
    getStore('APP_TOASTS').update({
      ...toast,
      visible: true,
    });

    // Set a timeout to automatically remove the toast after 5 seconds
    setTimeout(() => {
      getStore('APP_TOASTS').update({
        ...toast,
        visible: false,
      });
      setTimeout(() => {
        // Remove the toast from the store
        getStore('APP_TOASTS').removeByKey(toast.id);
      }, 500);
    }, 5000);
  }, 50);
}

export function openFileSettingsPopup(
  file: Pickit.FileInterface,
  prefs?: {
    defaultTab?: string;
    files?: Pickit.FileInterface[];
  },
) {
  getConfigStore('APP_CONFIG').setValue('fileSettings', {
    file,
    prefs,
  });
}
export function closeFileSettingsPopup() {
  getConfigStore('APP_CONFIG').setValue('fileSettings', {
    file: undefined,
    prefs: {},
  });
}

export function openFileImageEditor(
  file: Pickit.FileInterface,
  prefs?: AppConfigInterface['fileImageEditor']['prefs'],
) {
  getConfigStore('APP_CONFIG').setValue('fileImageEditor', {
    file,
    prefs,
  });
}
export function closeFileImageEditor() {
  getConfigStore('APP_CONFIG').setValue('fileImageEditor', {
    file: undefined,
    prefs: {},
  });
}
