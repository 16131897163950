import { useEffect } from 'react';
import useIntegrationConfig from '../useIntegrationConfig';
import useJobs from '../useJobs';
import useDropboxAPI from './useDropboxAPI';

export default function useDropbox(props) {
  const config = useIntegrationConfig('dropbox');
  const { API_ID, enableCapabilities, setCopy } = config;
  useEffect(() => {
    enableCapabilities([
      'jobs',
      'nested',
      'external',
      'seamless',
      'search',
      'approval',
    ]);
    setCopy({
      external: (
        <>
          Import the file's metadata, but keep the file stored externally.
          <br />
          <strong>Note:</strong> The user will be redirected to the source in
          Dropbox and will need access to the file there to download it.
        </>
      ),
      seamless: (
        <>
          External files can be downloaded without the Dropbox UI.
          <br />
          <strong>Note:</strong> This feature will create a open download URL
          for your imported assets. This URL can be accessed without
          authentication at Dropbox.
        </>
      ),
    });
  }, []);

  const DropboxAPI = useDropboxAPI({ API_ID, DropboxAuth: props.DropboxAuth });
  const Jobs = useJobs({ API_ID, API: DropboxAPI });
  return {
    ...DropboxAPI,
    ...Jobs,
    ...config,
  };
}
