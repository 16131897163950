/**
 * This function is protecting crash when localStorage is not accessible.
 */

const localStorageWrapper = {
  clear: (key) => {
    try {
      return localStorage.clear(key);
    } catch (e) {
      return null;
    }
  },
  removeItem: (key) => {
    try {
      return localStorage.removeItem(key);
    } catch (e) {
      return null;
    }
  },
  setItem: (key, value) => {
    try {
      return localStorage.setItem(key, value);
    } catch (e) {
      return null;
    }
  },
  getItem: (key) => {
    try {
      return localStorage.getItem(key);
    } catch (e) {
      return null;
    }
  },
};

export default localStorageWrapper;
