import * as React from 'react';
import Wizard from './Wizard';
import './style.scss';
import { useNative } from './context';

interface NativeProps {}

const Native = (props: NativeProps) => {
  return <div className="Native">Native</div>;
};

export function NativeDragWrapper({
  file,
  fileUrl,
  children,
  onLoading,
}: {
  file: Pickit.FileInterface;
  fileUrl: string;
  children: React.ReactElement;
  onLoading?: (isLoading: boolean) => void;
}) {
  const { updater: NativeEvent } = useNative();
  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    onLoading?.(isLoading);
  }, [isLoading]);
  return React.cloneElement(children, {
    draggable: 'true',
    onDragStart: async (event: MouseEvent) => {
      event.preventDefault();
      const alreadyDownloaded = NativeEvent.directoryMap.find(
        (f) => f.file._id === file._id,
      );
      if (alreadyDownloaded) {
        window.pickitNative?.startDrag(file, alreadyDownloaded.path);
        return;
      }
      setIsLoading(true);
      const path = await window.pickitNative?.downloadTempFile(file, fileUrl);
      setIsLoading(false);
      window.pickitNative?.startDrag(file, path);
    },
  });
}

Native.Wizard = Wizard;

export default Native;
