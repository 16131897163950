import deepmerge from 'deepmerge';
import { Map } from 'immutable';

export function createConfigStore<Interface extends Record<string, any>>(
  defaultData: Interface,
  _dispatch: (
    data: Interface[keyof Interface],
    oldData: Interface[keyof Interface],
    newState: Interface,
  ) => void,
) {
  let state = Map<Interface>(defaultData || {});
  return {
    state,
    setValue: <K extends keyof Interface>(
      key: K,
      value: Partial<Interface[K]>,
      options?: {
        merge?: boolean;
      },
    ) => {
      const currentValue = state.get(key) as Interface[K];
      let newValue = value;

      // Check if merge is true and perform a merging operation if applicable
      if (
        options?.merge &&
        typeof currentValue === 'object' &&
        currentValue &&
        typeof value === 'object'
      ) {
        newValue = deepmerge(currentValue, value); // Shallow merge of objects
      }

      const oldState = state;
      state = state.set(key, newValue as Interface[K]);
      _dispatch(
        state.get(key) as Interface[K],
        oldState.get(key) as Interface[K],
        state.toObject() as Interface,
      );
    },
    getValue: <K extends keyof Interface>(key: K) => {
      return state.get(key) as Interface[K];
    },
  };
}
