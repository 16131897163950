import { createContext, useContext } from 'react';

export type PixiDropdownRenderProps = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  setIsFreezeOpen: (isFreezeOpen: boolean) => void;
  isFreezeOpen: boolean;
  viewportRef: React.RefObject<HTMLDivElement>;
  closeOnItemClick?: boolean;
};
export const PixiDropdownContext = createContext<PixiDropdownRenderProps>(
  {} as PixiDropdownRenderProps,
);
export const useDropdownContext = () => useContext(PixiDropdownContext);
