import { useEffect } from 'react';
import useIntegrationConfig from '../useIntegrationConfig';
import useJobs from '../useJobs';
import useGoogleDriveAPI from './useGoogleDriveAPI';

export default function useGoogleDrive(props) {
  const config = useIntegrationConfig('google-drive');
  const { API_ID, enableCapabilities, addDescriptions, setCopy } = config;
  useEffect(() => {
    enableCapabilities([
      'jobs',
      'nested',
      'approval',
      // 'external',
      // 'seamless',
      'search',
      'recent',
      'hasDrives',
    ]);
    setCopy({
      external: (
        <>
          Import the file's metadata, but keep the file stored externally.
          <br />
          <strong>Note:</strong> The user will be redirected to the source in
          Google Drive and will need access to the file to download it.
        </>
      ),
      seamless: (
        <>
          External files can be downloaded without the Google Drive UI.
          <br />
          <strong>Note:</strong> The user needs to be logged into their Google
          account but doesn't need to have a tab open with a Google service.
        </>
      ),
    });
  }, []);

  const GoogleDriveAPI = useGoogleDriveAPI({ API_ID, Google: props.Google });
  const Jobs = useJobs({ API_ID, API: GoogleDriveAPI });

  return {
    ...GoogleDriveAPI,
    ...Jobs,
    ...config,
  };
}
