import {
  MultiSelect,
  Select,
  Switch,
  TagsInput,
  NumberInput,
  TextInput,
} from '@mantine/core';
import { isValid } from 'date-fns';
import { ReactElement, useEffect, useState } from 'react';
import PixiForm from '@pixi/elements/Form';
import { DateInput } from '@mantine/dates';
import { deepEqual } from '@pixi/helpers/utils';

type PropertyFieldProps = {
  property: Pickit.CommunityPropertyInterface;
  value: Pickit.PropertyValue;
  setData: (value: Pickit.PropertyValue) => void;
  onlyForm?: boolean;
  onBlur?: () => void;
};

function PropertyField({
  property,
  value,
  setData,
  onlyForm,
  onBlur,
}: PropertyFieldProps) {
  const [isMultiSelectOpen, setIsMultiSelectOpen] = useState(false);
  const renderField = () => {
    if (property?.type === 'dropdown') {
      if (property?.typeProps?.dropdown?.multiSelect) {
        return (
          <MultiSelect
            w="100%"
            dropdownOpened={isMultiSelectOpen}
            onDropdownClose={() => {
              setIsMultiSelectOpen(false);
            }}
            onDropdownOpen={() => {
              setIsMultiSelectOpen(true);
            }}
            label={onlyForm ? undefined : property.name}
            description={onlyForm ? undefined : property.description}
            data={property?.typeProps?.dropdown?.options || []}
            value={
              value?.dropdown?.map((m) => {
                const value = property?.typeProps?.dropdown?.options?.find(
                  (f) => f.label === m,
                )?.value;
                return value;
              }) as string[]
            }
            onBlur={() => onBlur?.()}
            onChange={(val) => {
              const labels = val.map(
                (v) =>
                  property?.typeProps?.dropdown?.options?.find(
                    (o) => o.value === v,
                  )?.label,
              ) as string[];
              setData({
                dropdown: labels,
              });
              setIsMultiSelectOpen(false);
            }}
          />
        );
      }
      return (
        <Select
          w="100%"
          label={onlyForm ? undefined : property.name}
          description={onlyForm ? undefined : property.description}
          data={property?.typeProps?.dropdown?.options || []}
          value={
            property?.typeProps?.dropdown?.options?.find(
              (o) => o.label === value?.dropdown?.[0],
            )?.value as string
          }
          onBlur={() => onBlur?.()}
          clearable
          onClear={() => {
            setData({
              dropdown: [],
            });
          }}
          onChange={(val) => {
            const label = property?.typeProps?.dropdown?.options?.find(
              (o) => o.value === val,
            );
            if (!label) {
              return;
            }
            setData({
              dropdown: [label.label],
            });
          }}
        />
      );
    }

    if (property?.type === 'boolean') {
      return (
        <Switch
          label={onlyForm ? undefined : property.name}
          description={onlyForm ? undefined : property.description}
          checked={value?.boolean || false}
          onBlur={() => onBlur?.()}
          onChange={(event) => {
            setData({
              boolean: event.currentTarget.checked,
            });
          }}
        />
      );
    }

    if (property?.type === 'date') {
      return (
        <DateInput
          w="100%"
          label={onlyForm ? undefined : property.name}
          placeholder="Select date"
          size="xs"
          description={onlyForm ? undefined : property.description}
          onBlur={() => onBlur?.()}
          value={
            value?.date && isValid(value?.date)
              ? new Date(value?.date)
              : undefined
          }
          onChange={(date) => {
            if (!date) {
              return;
            }
            const selectedDate = new Date(date.setHours(0, 0, 0, 0));
            const utcDate = new Date(
              selectedDate.getTime() - selectedDate.getTimezoneOffset() * 60000,
            );

            setData({
              date: utcDate,
            });
          }}
        />
      );
    }

    if (property?.type === 'tags') {
      return (
        <TagsInput
          w="100%"
          label={onlyForm ? undefined : property.name}
          description={onlyForm ? undefined : property.description}
          value={value?.tags || []}
          onBlur={() => onBlur?.()}
          acceptValueOnBlur
          onChange={(val) => {
            setData({
              tags: val,
            });
          }}
        />
      );
    }

    if (property?.type === 'number') {
      return (
        <NumberInput
          w="100%"
          label={onlyForm ? undefined : property.name}
          description={onlyForm ? undefined : property.description}
          value={value?.number || undefined}
          onBlur={() => onBlur?.()}
          onChange={(val) => {
            setData({
              number: parseInt(val as string),
            });
          }}
        />
      );
    }

    if (property?.type === 'text') {
      return (
        <TextInput
          w="100%"
          label={onlyForm ? undefined : property.name}
          description={onlyForm ? undefined : property.description}
          value={value?.text || ''}
          onBlur={() => onBlur?.()}
          placeholder="Enter text"
          onChange={(event) => {
            setData({
              text: event.currentTarget.value,
            });
          }}
        />
      );
    }

    return null;
  };

  return renderField();
}

export default function PropertyValueForm({
  property,
  target,
  onConfirm,
  value,
  inline,
  file,
}: {
  property: Pickit.CommunityPropertyInterface;
  target: ReactElement;
  onConfirm: (value: Pickit.PropertyValue) => Promise<Pickit.PropertyValue>;
  value?: Pickit.PropertyValue;
  inline?: boolean;
  file: Pickit.FileInterface;
}) {
  const [newValue, setNewValue] = useState(value);

  useEffect(() => {
    if (!deepEqual(value, newValue)) {
      setNewValue(value);
    }
  }, [value]);

  if (inline) {
    return (
      <PropertyField
        property={property}
        onlyForm
        key={file?._id}
        value={(newValue || value) as Pickit.PropertyValue}
        setData={(value) => {
          setNewValue(value as Pickit.PropertyValue);
        }}
        onBlur={() => {
          if (newValue !== value) {
            onConfirm(newValue as Pickit.PropertyValue);
          }
        }}
      />
    );
  }

  return (
    <PixiForm<{
      value: Pickit.PropertyValue;
    }>
      type="dropdown"
      key={JSON.stringify(value)}
      width={400}
      position="bottom-end"
      target={target}
      keepMounted
      onSubmit={async (data) => {
        const newVal = await onConfirm(data.value as Pickit.PropertyValue);
        setNewValue(newVal);
      }}
      form={{
        value: {
          key: 'value',
          value: value || {},
          render: ({ value, setValue }) => {
            return (
              <PropertyField
                property={property}
                value={value}
                setData={(value) => {
                  setValue(value);
                }}
              />
            );
          },
        },
      }}
    />
  );
}
