import { Icon, Tooltip } from 'elements_v2';
import React, { useEffect, useState } from 'react';
import './style.scss';

export default function CheckboxCards({
  cards,
  onChange,
  value,
  vertical,
  minified,
  style,
  contentStyle,
}) {
  return (
    <div
      className={[
        'Pixi Pixi__CheckboxCards',
        ...(vertical ? ['Pixi__CheckboxCards--vertical'] : []),
        ...(minified ? ['Pixi__CheckboxCards--minified'] : []),
      ].join(' ')}
      style={style}
    >
      <div className="Pixi Pixi__CheckboxCards__content" style={contentStyle}>
        {cards.map((card) => (
          <Tooltip
            message={card.disabledDescription}
            disabled={
              !card.disabled || (card.disabled && !card.disabledDescription)
            }
          >
            <div
              key={card.value}
              onClick={
                card.disabled
                  ? () => {}
                  : () => {
                      onChange(card.value);
                    }
              }
              className={[
                'Pixi',
                'Pixi__CheckboxCards__content__card',
                ...(value === card.value
                  ? ['Pixi__CheckboxCards__content__card--selected']
                  : []),
                ...(card.disabled
                  ? ['Pixi__CheckboxCards__content__card--disabled']
                  : []),
              ].join(' ')}
            >
              <div className="Pixi__CheckboxCards__content__card__header">
                <Icon
                  bubble
                  name={value === card.value ? 'check-circle-fill' : 'circle'}
                />
                <h1>{card.title}</h1>
              </div>
              <p>{card.description}</p>
            </div>
          </Tooltip>
        ))}
      </div>
    </div>
  );
}
