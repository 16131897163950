import { Button, Dropdown, Icon, Popover } from 'elements_v2';
import { useBrand, useTextContext } from 'hooks';
import React from 'react';
// import "./style.scss";

/**
 * TODO: Contributor, external and anonymous users
 */

function Filters(props) {
  const { files } = props;
  const Text = useTextContext();

  // const filters = (
  //   <>
  //     <Dropdown
  //       sameWidthAsTrigger
  //       placeholder={Text.get('ui/sort_by')}
  //       icon="FilterLeft"
  //       useV2
  //       position="right"
  //       style={{ marginBottom: 4 }}
  //       value={
  //         !props.sortBy?.column
  //           ? null
  //           : `${props.sortBy?.column}/${props?.sortBy?.direction}`
  //       }
  //       trigger={
  //         <Button
  //           rightIcon={
  //             <Icon
  //               name={props.sortBy?.column ? 'x-lg' : 'chevron-right'}
  //               bubble={props.sortBy?.column}
  //               button={props.sortBy?.column}
  //               style={props.sortBy?.column ? { marginRight: 0 } : {}}
  //               onClick={(event) => {
  //                 if (props.sortBy?.column) {
  //                   event.preventDefault();
  //                   event.stopPropagation();
  //                   props.onSort(null, null);
  //                 }
  //               }}
  //             />
  //           }
  //           outlined={!!props.sortBy?.column}
  //         >
  //           <Icon name="sort-down" />
  //           Sort by
  //         </Button>
  //       }
  //       onChange={(value) => {
  //         if (!value) {
  //           props.onSort(null);
  //           return false;
  //         }
  //         const [row, direction] = value?.split('/');
  //         props.onSort(row, direction);
  //       }}
  //     >
  //       <Dropdown.Option value="name/ascending" key="name/ascending">
  //         <Icon name="SortAlphaDown" style={{ marginRight: 5 }} />
  //         {Text.get('ui/collection_filter/a_z')}
  //       </Dropdown.Option>
  //       <Dropdown.Option value="name/descending" key="name/descending">
  //         <Icon name="SortAlphaUp" style={{ marginRight: 5 }} />
  //         {Text.get('ui/collection_filter/z_a')}
  //       </Dropdown.Option>
  //       <Dropdown.Option
  //         value="createdAt/descending"
  //         key="createdAt/descending"
  //       >
  //         <Icon name="SortDown" style={{ marginRight: 5 }} />
  //         {Text.get('ui/collection_filter/newest_first')}
  //       </Dropdown.Option>
  //       <Dropdown.Option value="createdAt/ascending" key="createdAt/ascending">
  //         <Icon name="SortUp" style={{ marginRight: 5 }} />
  //         {Text.get('ui/collection_filter/oldest_first')}
  //       </Dropdown.Option>
  //     </Dropdown>
  //     <Toolbar
  //       context={props.context}
  //       filters={files.availableFilters}
  //       toolbarLeftInjection={props.toolbarLeftInjection}
  //       toolbarRightInjection={props.toolbarRightInjection}
  //       isSearchProperties={props.isSearchProperties}
  //       activeFilters={files.filters}
  //       setFilter={(key, value) => {
  //         files.setIsReady(true);
  //         files.setFilter(key, value);
  //       }}
  //       hide={['color', 'favorites', 'clear_filters']}
  //       disableFilters={['approvals_status']}
  //       onlyFilters
  //       setFilters={files.setFilters}
  //       // totalFiles={props.context?.data?.['files-count']}
  //       displayTypes={props.toolbar?.displayTypes}
  //     />
  //   </>
  // );

  // return filters;
}

export default React.memo(Filters);
