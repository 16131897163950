import Community from './Community';
import { getConfigStore } from '@pixi/store';

export default class User {
  static rawData: any;

  static currentCommunity: Community;

  static token: {
    jwt: string;
    jwt_refresh_token: string;
  };

  static setToken(token: { jwt: string; jwt_refresh_token: string }) {
    User.token = token;
  }

  static setRawData(data: any) {
    User.rawData = data;
    if (data?.selectedCommunity) {
      User.currentCommunity = new Community(data.selectedCommunity);
      getConfigStore('APP_USER').setValue('community', {
        data,
        prefs: {
          isUsingUsersV2:
            data.selectedCommunity?.migration?.parts?.isUsersMigrated,
        },
      });
    }
    getConfigStore('APP_USER').setValue('user', data);
  }
}
