import { Button, Icon, Intro, Switch } from 'elements_v2';
import * as React from 'react';
import { useNative } from 'views/Native/context';

interface StartupProps {
  next: () => void;
}

const Startup: React.FC<StartupProps> = (props: StartupProps) => {
  const Native = useNative();
  const isActive = Native.settings.openAtLogin;
  return (
    <Intro noPadding>
      <Intro.Illustration
        style={{ width: 80, marginBottom: 15, textAlign: 'center' }}
      >
        <Icon name="laptop" style={{ fontSize: 56 }} />
      </Intro.Illustration>
      <Intro.Message>
        <h1>Startup</h1>
        <p>
          Start Pickit automatically when
          <br />
          booting your computer
        </p>
        <Icon
          button
          bubble
          name={isActive ? 'toggle-on' : 'toggle-off'}
          onClick={() => {
            Native.setSettings({ openAtLogin: !isActive });
          }}
          style={{
            fontSize: 42,
            marginTop: 15,
            color: isActive ? '#6716d8' : undefined,
          }}
        />
      </Intro.Message>
      <Button primary inline pixi size="large" onClick={props.next}>
        Continue
      </Button>
    </Intro>
  );
};

export default Startup;
