import { useEffect, useState } from 'react';

/**
 * Custom hook to use Intersection Observer API.
 * @param {React.RefObject} ref - The React ref of the element to observe.
 * @param {IntersectionObserverInit} options - Options for the Intersection Observer.
 * @returns {boolean} isInView - Whether the element is in the viewport.
 */
function useIntersectionObserver(ref, options = {}) {
  const [isInView, setIsInView] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      // Update our state when observer callback fires
      setIsInView(entry.isIntersecting);
    }, options);

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
    // Make sure the effect runs only once or when ref or options change
  }, [ref, JSON.stringify(options)]);

  return isInView;
}

export default useIntersectionObserver;
