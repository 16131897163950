import React, { ReactNode, useState, useEffect } from 'react';
import PixiPopup, {
  PixiPopupPanelProps,
  PixiPopupProps,
} from '@pixi/elements/Popup';
import PixiButton from '@pixi/elements/Button';
import PixiIcon, { PixiIconName } from '@pixi/elements/Icon';
import { useColorScheme } from '@pixi/AppController';
import { Box, Stack } from '@mantine/core';

interface PixiPopupTabsProps extends Partial<PixiPopupProps> {
  defaultTab?: string;
  isOpen?: boolean;
  onClose?: () => void;
  currentTab?: string;
  onTabChange?: (tabId: string) => void;
  title: ReactNode;
  icon?: PixiIconName | ReactNode;
  menuPanelProps?: Partial<PixiPopupPanelProps>;
  tabPanelProps?: Partial<PixiPopupPanelProps>;
}

interface PixiPopupTabsTabProps {
  children?:
    | ReactNode
    | ((props: {
        currentTab: string | undefined;
        setCurrentTab: (tabId: string) => void;
        onClose: () => void;
      }) => ReactNode);
  id: string;
  title: ReactNode;
  menuTitle?: ReactNode;
  icon?: PixiIconName;
  menuItemProps?: React.ComponentProps<typeof PixiButton>;
  panelProps?: Partial<PixiPopupPanelProps>;
}

const PixiPopupTabs: React.FC<PixiPopupTabsProps> & {
  Tab: React.FC<PixiPopupTabsTabProps>;
} = ({
  defaultTab,
  children,
  isOpen,
  onClose: _onClose,
  currentTab: _currentTab,
  title,
  menuPanelProps,
  tabPanelProps,
  icon,
  ...rest
}) => {
  const [currentTab, setCurrentTab] = useState<string | undefined>(defaultTab);
  const colorScheme = useColorScheme();

  const tabs = React.Children.toArray(children).filter(
    (child): child is React.ReactElement<PixiPopupTabsTabProps> =>
      React.isValidElement(child),
  );

  const onClose = () => {
    _onClose?.();

    // Avoid flickering while animation fades out
    setTimeout(() => {
      setCurrentTab(undefined);
    }, 500);
  };

  useEffect(() => {
    if (_currentTab) {
      setCurrentTab(_currentTab);
    }
  }, [_currentTab]);

  useEffect(() => {
    if (defaultTab) {
      setCurrentTab(defaultTab);
    } else if (tabs.length > 0 && !currentTab) {
      setCurrentTab(tabs[0].props.id);
    }
  }, [defaultTab, tabs, currentTab]);

  const activeTab = tabs.find((tab) => tab.props.id === currentTab) || tabs[0];

  return (
    <PixiPopup
      size={1000}
      opened={isOpen ?? false}
      fullHeight
      onClose={() => onClose?.()}
      bodyProps={{ p: 'lg' }}
      zIndex={10}
      isPanelPopup
      {...rest}
    >
      {/* Menu Panel */}
      <PixiPopup.Panel
        w={400}
        pb="xl"
        variant="outline"
        bg={colorScheme === 'dark' ? 'dark.7' : 'gray.1'}
        title={title}
        icon={icon}
        {...menuPanelProps}
      >
        <Stack p="md" gap="5">
          {tabs.map((tab, index) => {
            const tabId = tab.props.id || `tab-${index}`;
            const isActive = tabId === currentTab;
            return (
              <PixiButton
                key={tabId}
                color={
                  isActive
                    ? 'primary'
                    : colorScheme === 'dark'
                      ? 'gray'
                      : 'dark'
                }
                variant={
                  !isActive && colorScheme !== 'dark' ? 'subtle' : 'light'
                }
                py="sm"
                h="auto"
                mah="auto"
                styles={{ label: { overflow: 'visible' } }}
                leftSection={
                  tab.props.icon && (
                    <PixiIcon name={tab.props.icon || 'circle'} />
                  )
                }
                justify="flex-start"
                size="sm"
                onClick={() => {
                  setCurrentTab(tabId);
                }}
                {...tab.props.menuItemProps}
              >
                {tab.props.menuTitle || tab.props.title}
              </PixiButton>
            );
          })}
        </Stack>
      </PixiPopup.Panel>
      <PixiPopup.Panel
        title={activeTab?.props.title}
        icon={activeTab?.props.icon}
        w="100%"
        maw={900}
        withCloseButton
        bg={colorScheme === 'dark' ? 'dark.7' : 'gray.1'}
        {...tabPanelProps}
        {...(activeTab?.props.panelProps || {})}
      >
        <Box p="lg" h="100%" key={activeTab?.props?.id}>
          {typeof activeTab.props.children === 'function'
            ? activeTab.props.children({ currentTab, setCurrentTab, onClose })
            : activeTab.props.children}
        </Box>
      </PixiPopup.Panel>
    </PixiPopup>
  );
};

PixiPopupTabs.Tab = ({ children, ...props }: PixiPopupTabsTabProps) => {
  return null; // The Tab component is a placeholder for props and doesn't render anything
};

export default PixiPopupTabs;
