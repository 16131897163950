import { Dropdown, Popover, Search } from 'elements_v2';
import React, { useEffect, useReducer, useRef, useState } from 'react';
import Loader from 'elements_v2/Loader';
import { useBoards } from 'contexts/Providers/BoardsProvider';
import SelectBoards from 'components/SelectBoards';

export function AddBoard({
  onBoardClick,
  trigger,
  type,
  name,
  value,
  isPopup,
  multipleAssets,
  onClose,
}) {
  const [boards, setBoards] = useReducer((state, action) => [...action], []);
  const [query, setQuery] = useState('');
  const [isSearching, setIsSearching] = useState(false);
  const searchRef = useRef();

  if (isPopup) {
    return (
      <SelectBoards
        style={{ maxWidth: 800 }}
        onChange={(data) => {
          onBoardClick(data);
        }}
        onClose={onClose}
        type={type}
        multiple={multipleAssets}
        title={`Select ${name || 'board'}`}
        disableBoards={value}
        trigger={trigger}
      />
    );
  }

  return (
    <>
      <Popover position="right-start" trigger={trigger}>
        <Search
          placeholder={`Search ${name || 'board'}`}
          searchRef={searchRef}
          autoFocus
          value={query}
          onChange={setQuery}
        />
        {isSearching ? <Loader /> : ''}
        <div style={{ maxHeight: 200, overflow: 'auto' }}>
          {boards
            .filter((board) =>
              board.name.toLowerCase().includes(query.toLowerCase()),
            )
            .map((board) => (
              <Dropdown.Option
                key={board._id}
                onClick={() => {
                  onBoardClick(board);
                }}
              >
                {board.name}
              </Dropdown.Option>
            ))}
        </div>
      </Popover>
    </>
  );
}
