import { useDraggable } from '@dnd-kit/core';
import {
  Badge,
  Box,
  Checkbox,
  Group,
  Loader,
  LoadingOverlay,
  Overlay,
  Paper,
  PaperProps,
  Stack,
  Table,
} from '@mantine/core';
import { useIntersection, useMergedRef } from '@mantine/hooks';
import { useAppViewport, useColorScheme } from '@pixi/AppController';
import PixiButton from '@pixi/elements/Button';
import PixiIcon from '@pixi/elements/Icon';
import PixiText, { PixiTitle } from '@pixi/elements/Text';
import PixiTooltip from '@pixi/elements/Tooltip';
import { TransparentPattern } from '@pixi/elements/TransparentPattern';
import { getFileExtension } from '@pixi/helpers/FileUtils';
import { useAssetThumbnail } from '@pixi/hooks/useAssetThumbnail';
import {
  getMultiStore,
  useConfigStoreCallback,
  useConfigStoreValue,
  useKeySelector,
  useMultiDataStoreCallback,
  useMultiStoreData,
} from '@pixi/store';
import {
  ForwardedRef,
  forwardRef,
  KeyboardEvent,
  MouseEvent,
  ReactNode,
  RefObject,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { truncate } from 'utils';
import { AssetThumbnail, AssetThumbnailProps } from '../../AssetThumbnail';
import { AssetContextMenu, AssetDropdownRender } from '../../AssetActions';
import { AssetGridProps } from '../Helpers';
import AssetTableRow from './AssetTableRow';
import { useAssetGridContext } from '../AssetGridContext';
import { useAppContext } from 'contexts/Providers/AppProvider';
import PixiDropdown from '@pixi/elements/Dropdown';
import { useAssetActions } from '@pixi/components/AssetActions/useAssetActions';
import { getDefaultAssetToolbar } from '@pixi/Vars';
import { isNative } from 'utils/platform';
import useStorageUrl from 'hooks/files/useStorageUrl';
import { NativeDragWrapper } from 'views/Native';
import { isBefore } from 'date-fns';
import { AssetActionIds } from '@pixi/components/AssetActions/Types';
import { formatDuration } from '@pixi/helpers/utils';

export default function AssetCard(
  props: {
    onClick?: (
      event: MouseEvent<HTMLElement> | KeyboardEvent<HTMLElement>,
      fileToolbar: ReturnType<typeof useAssetActions>,
    ) => void;
    fileId: string;
    gridId?: string;
    as?: 'image' | 'card' | 'table';
    fields?: string[];
    fileToolbar?: AssetGridProps['fileToolbar'];
    fileToolbarCustom?: AssetGridProps['fileToolbarCustom'];
    containerRef?: React.RefObject<HTMLDivElement>;
    style?: React.CSSProperties;
    thumbnailProps?: Partial<AssetThumbnailProps>;
  } & PaperProps,
) {
  const file = useKeySelector('FILES', props.fileId);
  const { id, viewportRef } = useAssetGridContext();
  const { ref: intersectionRef, entry } = useIntersection({
    threshold: 0,
    rootMargin: '500px',
    root: viewportRef?.current,
  });
  const [isVisible, setIsVisible] = useState(false);
  const colorScheme = useColorScheme();

  useEffect(() => {
    if (entry?.isIntersecting !== isVisible) {
      setIsVisible(entry?.isIntersecting || false);
    }
  }, [entry?.isIntersecting, isVisible]);

  const fileCardWrapper = useMemo(() => {
    if (!isVisible) {
      return <></>;
    }
    if (!props.fileId) {
      return <></>;
    }
    if (!file) {
      return;
    }
    return <FileCardWrapper isVisible={isVisible} file={file} {...props} />;
  }, [isVisible, file]);

  if (!file?._id) {
    return <></>;
  }

  if (props.as === 'table') {
    return (
      <Table.Tr
        h={68}
        ref={intersectionRef}
        pos="relative"
        style={{
          transform: 'translate(0)', // Fix for position relative not working on Safari
          clipPath: 'inset(0)', // Fix for position relative not working on Safari
        }}
      >
        {isVisible && (
          <FileCardWrapper
            ref={intersectionRef}
            isVisible={isVisible}
            file={file}
            {...props}
          />
        )}
      </Table.Tr>
    );
  }

  return (
    <Box
      w="100%"
      style={{
        aspectRatio: '4/3.5',
        ...props.style,
      }}
      bg={
        !isVisible ? (colorScheme === 'dark' ? 'gray.8' : 'gray.0') : undefined
      }
      ref={intersectionRef}
      pos="relative"
    >
      {fileCardWrapper}
    </Box>
  );
}
const FileCardWrapper = forwardRef(
  (
    {
      file,
      onClick,
      gridId,
      as,
      containerRef,
      isVisible,
      thumbnailProps,
      ...rest
    }: {
      onClick?: (
        event: MouseEvent<HTMLElement> | KeyboardEvent<HTMLElement>,
        fileToolbar: ReturnType<typeof useAssetActions>,
      ) => void;
      file: Pickit.FileInterface;
      gridId?: string;
      as?: 'image' | 'card' | 'table';
      containerRef?: React.RefObject<HTMLDivElement>;
      isVisible: boolean;
      thumbnailProps?: Partial<AssetThumbnailProps>;
    } & PaperProps,
    _ref: ForwardedRef<HTMLDivElement>,
  ) => {
    const {
      activeShortcutIds,
      disable,
      id,
      readOnly,
      onFileSelect,
      fileToolbar,
      fileToolbarCustom,
    } = useAssetGridContext();
    const { imageType } = useAssetThumbnail({
      file,
    });
    const viewport = useAppViewport();
    const App = useAppContext();
    const draggingAsset = useConfigStoreValue('APP_CONFIG', 'draggingAsset');
    const ref = useRef<HTMLDivElement | HTMLTableRowElement>(null);
    const colorScheme = useColorScheme();
    const fileToolbarHook = useAssetActions({
      file,
    });
    const { specificActions } = fileToolbarHook;
    const {
      attributes,
      listeners,
      setNodeRef,
      isDragging: _isDragging,
    } = useDraggable({
      id: file._id,
      data: {
        type: 'file',
      },
      disabled: isNative(),
    });
    const { generateUrl } = useStorageUrl();
    const isDragging = draggingAsset?.id === file._id && _isDragging;
    const combinedRef = useMergedRef(ref, setNodeRef, _ref);
    const buttonRef = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const _isSelected = useMultiDataStoreCallback(
      'FILES_SELECTED',
      gridId || 'general',
      (data) => {
        return !!data.getByRow(file);
      },
      [gridId, file._id],
    );
    const [isContextMenuOpen, setIsContextMenuOpen] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isMouseOver, setIsMouseOver] = useState(false);
    const [isFocused, setIsFocused] = useState(false);

    const isSelected = _isSelected;

    const probablyHasAlpha =
      imageType === 'svg' ||
      (imageType === 'preview' &&
        (file?.file?.ext === 'ai' ||
          file?.file?.ext === 'eps' ||
          file?.file?.ext === 'png'));

    const hasPreviewVideo = !!file?.file?.previewVideo?.id;
    const isHighlight = isSelected;

    let render = <></>;

    if (as === 'table') {
      render = (
        <>
          <Table.Td w={0} p={0} miw={0}>
            <PixiButton
              bg={isMouseOver && !isHighlight ? 'dark' : 'primary'}
              opacity={isHighlight ? 0.3 : isMouseOver ? 0.05 : 0}
              top={0}
              left={0}
              w="100%"
              h="100%"
              right={0}
              bottom={0}
              m="auto"
              pos="absolute"
              onFocus={() => {
                setIsFocused(true);
              }}
              ref={buttonRef}
              onBlur={(event) => {
                if (!event.currentTarget.contains(event.relatedTarget)) {
                  setIsFocused(false);
                }
              }}
              onKeyDown={(event) => {
                if (event.key === 'Enter' && isFocused) {
                  onClick?.(event, fileToolbarHook);
                }
              }}
              onMouseOver={() => setIsMouseOver(true)}
              onMouseOut={() => setIsMouseOver(false)}
              type="button"
              style={{
                borderRadius: 0,
                zIndex: 5,
              }}
              onClick={(event) => {
                setIsFocused(false);
                onClick?.(event, fileToolbarHook);
              }}
              {...listeners}
              {...attributes}
            />
            {isVisible && (isMouseOver || isContextMenuOpen) && (
              <AssetContextMenu
                onTrigger={setIsContextMenuOpen}
                contextMenuRef={buttonRef}
                file={file}
                zIndex={150}
                readOnly={readOnly}
              />
            )}
          </Table.Td>
          <AssetTableRow
            isHighlight={isHighlight}
            file={file}
            fileToolbarHook={fileToolbarHook}
          />
        </>
      );
    }

    const approvalNeeded =
      file?.approval?.status &&
      file?.approval?.status !== 'approved' &&
      file?.approval?.status !== 'rejected';

    let warning = '';

    const specificFileToolbar =
      typeof fileToolbar === 'function' ? fileToolbar(file) : fileToolbar;
    if (
      file?.approval?.status === 'rejected' ||
      (file?.license?.expirationDate &&
        isBefore(new Date(file?.license?.expirationDate), new Date())) ||
      file?.share_to === 'none'
    ) {
      warning =
        file?.approval?.status === 'rejected'
          ? 'Asset has been rejected'
          : file?.share_to === 'none'
            ? 'Asset is shared to admins only'
            : 'License has expired';
    }

    if (as !== 'table') {
      render = (
        <Paper
          w="100%"
          style={{
            aspectRatio: '4/3.5',
            overflow: 'hidden',
            cursor: 'pointer',
            userSelect: 'none',
            transition: 'transform .15s',
            transform: 'scale(1)',
            ...(isContextMenuOpen || isFocused
              ? {
                  outline: '2px solid var(--mantine-color-primary-5)',
                }
              : {}),
            ...(isDragging
              ? {
                  transform: 'scale(0.5)',
                  opacity: 0.2,
                }
              : {}),
          }}
          pos="relative"
          bg={isHighlight ? 'primary.0' : 'transparent'}
          ref={combinedRef}
          onMouseOver={() => setIsMouseOver(true)}
          onMouseLeave={() => setIsMouseOver(false)}
          {...rest}
          {...listeners}
          {...attributes}
        >
          <>
            <PixiButton
              bg="primary"
              opacity={isHighlight ? 0.3 : 0}
              top={0}
              left={0}
              tabIndex={0}
              onFocus={() => {
                setIsFocused(true);
              }}
              onBlur={(event) => {
                if (!event.currentTarget.contains(event.relatedTarget)) {
                  setIsFocused(false);
                }
              }}
              w="100%"
              h="100%"
              pos="absolute"
              style={{ zIndex: 5 }}
              onKeyDown={(event) => {
                if (event.key === 'Enter' && isFocused) {
                  onClick?.(event, fileToolbarHook);
                }
              }}
              onClick={(event) => {
                setIsFocused(false);
                onClick?.(event, fileToolbarHook);
              }}
            />
            {isVisible && (isMouseOver || isContextMenuOpen) && (
              <AssetContextMenu
                onTrigger={setIsContextMenuOpen}
                contextMenuRef={ref}
                file={file}
                zIndex={150}
                readOnly={readOnly}
              />
            )}
            {(!!fileToolbarCustom?.length ||
              !!fileToolbar?.length ||
              !!specificFileToolbar?.length ||
              App.isManage) && (
              <Box
                w="100%"
                pos="absolute"
                top={0}
                style={{
                  zIndex: 8,
                }}
              >
                <Group w="100%" gap="0" justify="space-between" p="5">
                  <Group gap="3">
                    {!disable?.includes('selection') &&
                      onFileSelect &&
                      isMouseOver && (
                        <PixiTooltip label="Select file">
                          <PixiButton
                            px="5"
                            size="compact-xs"
                            color="primary"
                            bg={colorScheme === 'dark' ? 'dark.8' : 'white'}
                            variant="white"
                            onClick={(event) => {
                              onFileSelect?.(event, file, true);
                            }}
                          >
                            <PixiIcon
                              name={isSelected ? 'check' : 'square'}
                              variant="filled"
                              c={
                                isSelected
                                  ? 'primary'
                                  : colorScheme === 'dark'
                                    ? 'dark.8'
                                    : 'white'
                              }
                              size="xs"
                            />
                          </PixiButton>
                        </PixiTooltip>
                      )}
                    {/* {approvalNeeded && App.isManage && (
                      <PixiTooltip label="Approval needed">
                        <PixiButton
                          px="5"
                          size="compact-xs"
                          color="primary"
                          bg={colorScheme === 'dark' ? 'dark.8' : 'white'}
                          c={colorScheme === 'dark' ? 'gray.4' : 'dark'}
                          onClick={() => {
                            getMultiStore('FILES_SELECTED', id).toggle({
                              _id: file._id,
                            });
                          }}
                        >
                          <PixiIcon
                            name="triangle-exclamation"
                            variant="filled"
                          />
                        </PixiButton>
                      </PixiTooltip>
                    )} */}
                    {warning && (
                      <PixiTooltip label={warning}>
                        <PixiButton
                          px="5"
                          size="compact-xs"
                          color="primary"
                          bg={colorScheme === 'dark' ? 'dark.8' : 'white'}
                          c={colorScheme === 'dark' ? 'gray.4' : 'dark'}
                        >
                          <PixiIcon
                            name="triangle-exclamation"
                            variant="filled"
                          />
                        </PixiButton>
                      </PixiTooltip>
                    )}
                    {file?._modifiers?.cosineSimilarity &&
                      activeShortcutIds?.find((s) => s.includes('ai.')) && (
                        <PixiTooltip label="Similarity score">
                          <PixiButton
                            px="5"
                            size="compact-xs"
                            color="primary"
                            bg={colorScheme === 'dark' ? 'dark.8' : 'white'}
                            c={colorScheme === 'dark' ? 'gray.4' : 'dark'}
                          >
                            {Math.floor(
                              file?._modifiers?.cosineSimilarity * 100,
                            )}
                            %
                          </PixiButton>
                        </PixiTooltip>
                      )}
                    {file?.file?.external_file && (
                      <PixiTooltip label="External file">
                        <PixiButton
                          px="5"
                          size="compact-xs"
                          color="primary"
                          bg={colorScheme === 'dark' ? 'dark.8' : 'white'}
                          c={colorScheme === 'dark' ? 'gray.4' : 'dark'}
                        >
                          <PixiIcon name="link-horizontal" />
                        </PixiButton>
                      </PixiTooltip>
                    )}
                  </Group>
                  <Group
                    style={{
                      gap: viewport.isLessThan('sm') ? 0 : 3,
                      opacity: isMouseOver || isFocused ? 1 : 0,
                      transition: 'opacity .1s',
                      transformOrigin: 'top right',
                    }}
                  >
                    {specificActions(
                      specificFileToolbar?.filter((p) => p !== 'menu') || [],
                      false,
                    )?.map((tool) => {
                      const trigger = (
                        <PixiTooltip label={tool.label} key={tool.id}>
                          <PixiButton
                            variant={tool.color ? 'filled' : 'white'}
                            bg={colorScheme === 'dark' ? 'dark.8' : 'white'}
                            c={colorScheme === 'dark' ? 'gray.4' : 'dark'}
                            size="compact-xs"
                            px="5"
                            color={tool.color || 'dark'}
                            onClick={(event) => {
                              tool.onClick?.(event);
                            }}
                            radius="xs"
                          >
                            <PixiIcon
                              size="sm"
                              name={tool.icon || '00'}
                              {...tool.iconProps}
                            />
                          </PixiButton>
                        </PixiTooltip>
                      );
                      if (tool.customRender) {
                        return tool.customRender(trigger, {
                          isOpen: true,
                          onClose: () => {},
                          setIsFreezeDropdown: () => {},
                        });
                      }
                      return trigger;
                    })}
                    {(isMouseOver || isContextMenuOpen || isMenuOpen) &&
                      specificFileToolbar?.includes('menu') && (
                        <PixiDropdown
                          width={240}
                          zIndex={150}
                          target={
                            <PixiButton
                              bg={colorScheme === 'dark' ? 'dark.8' : 'white'}
                              c={colorScheme === 'dark' ? 'gray.4' : 'dark'}
                              size="compact-xs"
                              px="4"
                              radius="xs"
                            >
                              <PixiIcon name="ellipsis" />
                            </PixiButton>
                          }
                          onOpen={() => {
                            setIsMenuOpen(true);
                            setIsContextMenuOpen(true);
                          }}
                          onClose={() => {
                            setIsMenuOpen(false);
                            setIsContextMenuOpen(false);
                          }}
                          customRender={({ isOpen, setIsOpen }) => (
                            <AssetDropdownRender
                              isOpen={isOpen}
                              rootzIndex={150}
                              onClose={() => setIsOpen(false)}
                              file={file}
                              actions={specificActions(
                                getDefaultAssetToolbar(file, {
                                  readOnly,
                                }),
                                false,
                              )}
                            />
                          )}
                        />
                      )}
                    {fileToolbarCustom?.map((tool) => {
                      const trigger = (
                        <PixiTooltip label={tool.label}>
                          <PixiButton variant="white" size="compact-xs" px="5">
                            <PixiIcon name={tool.icon} size="xs" />
                          </PixiButton>
                        </PixiTooltip>
                      );
                      if (tool.wrapper) {
                        return tool.wrapper(trigger, file);
                      }
                      return trigger;
                    })}
                  </Group>
                </Group>
              </Box>
            )}
            <Stack h="100%" gap={0}>
              <Group
                w="100%"
                h="100%"
                mih={1}
                justify="center"
                align="center"
                p={
                  as !== 'image' && imageType !== 'icon' ? undefined : undefined
                }
                bg={colorScheme === 'dark' ? 'dark.5' : 'gray.1'}
                pos="relative"
              >
                {isLoading && <LoadingOverlay top={0} visible={isLoading} />}
                {isVisible && (
                  <>
                    {file?.processing?.thumbnail?.isProcessing && (
                      <Overlay
                        w="100%"
                        h="100%"
                        color="#FFF"
                        zIndex={99}
                        bg={
                          isHighlight
                            ? colorScheme === 'dark'
                              ? 'primary.8'
                              : 'primary.1'
                            : colorScheme === 'dark'
                              ? 'dark.5'
                              : 'gray.0'
                        }
                        onClick={async (event) => {
                          setIsLoading(true);
                          await onClick?.(event, fileToolbarHook);
                          setIsLoading(false);
                        }}
                      >
                        <Stack
                          align="center"
                          justify="center"
                          h="100%"
                          c="dark"
                          gap="xs"
                        >
                          <Loader
                            color={colorScheme === 'dark' ? 'white' : 'dark'}
                            size="sm"
                          />
                          <PixiText fw="600" size="xs">
                            Generating thumbnail
                          </PixiText>
                        </Stack>
                      </Overlay>
                    )}
                    {!!file?.file?.previews?.length && (
                      <TransparentPattern
                        color={colorScheme === 'dark' ? 'dark.7' : 'gray.2'}
                      />
                    )}
                    <Group
                      w="100%"
                      h="100%"
                      pos="relative"
                      justify="center"
                      align="center"
                    >
                      {(isMouseOver || imageType === 'icon') && (
                        <Badge
                          pos="absolute"
                          variant="default"
                          style={{ zIndex: 3 }}
                          bottom={5}
                          left={5}
                        >
                          {getFileExtension(file)}
                        </Badge>
                      )}
                      {file?.file?.media_details?.video?.format?.duration && (
                        <Badge
                          pos="absolute"
                          style={{ zIndex: 4 }}
                          bottom={5}
                          right={5}
                          color="dark"
                        >
                          {formatDuration(
                            parseInt(
                              file?.file?.media_details?.video?.format
                                ?.duration,
                            ),
                          )}
                        </Badge>
                      )}
                      {hasPreviewVideo && isMouseOver && (
                        <video
                          style={{
                            width: '100%',
                            height: '100%',
                            position: 'absolute',
                            zIndex: 3,
                            background: 'transparent',
                            objectFit: 'contain',
                            objectPosition: 'center',
                          }}
                          muted
                          playsInline
                          autoPlay
                        >
                          <source src={file?.file?.previewVideo?.url} />
                        </video>
                      )}
                      <AssetThumbnail
                        fit={
                          as === 'image'
                            ? 'cover'
                            : probablyHasAlpha
                              ? 'contain'
                              : 'contain'
                        }
                        size="small"
                        file={file}
                        w="100%"
                        h="100%"
                        pos="absolute"
                        left={0}
                        top={0}
                        right={0}
                        bottom={0}
                        m="auto"
                        {...thumbnailProps}
                        style={(type) => ({
                          pointerEvents: 'none',
                          objectPosition: 'center',
                          ...(type === 'icon' || type === 'blank'
                            ? {
                                objectFit: 'contain',
                                maxWidth: '40%',
                                maxHeight: '40%',
                              }
                            : {}),
                          ...(type === 'svg'
                            ? {
                                objectFit: 'contain',
                                maxHeight: '70%',
                                maxWidth: '70%',
                              }
                            : {}),
                          ...thumbnailProps?.style?.(type),
                        })}
                      />
                    </Group>
                  </>
                )}
              </Group>
              {as !== 'image' && (
                <Box
                  w="100%"
                  miw={1}
                  style={{ overflow: 'hidden' }}
                  ta="center"
                  p="sm"
                >
                  <PixiTitle
                    maw="100%"
                    size={13}
                    fw="400"
                    c="dimmed"
                    style={{
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {truncate(file.name, 50, '...')}
                  </PixiTitle>
                </Box>
              )}
            </Stack>
          </>
        </Paper>
      );
    }

    if (isNative()) {
      return (
        <NativeDragWrapper
          file={file}
          fileUrl={generateUrl(file?.file?.url)}
          onLoading={setIsLoading}
        >
          {render}
        </NativeDragWrapper>
      );
    }

    return render;
  },
);
