import React, { useEffect, useRef, useState } from 'react';
import { Button, Flex, Icon } from 'elements_v2';

export default function EditThumbnail(props) {
  const uploadButton = useRef();
  const [file, setFile] = useState();

  useEffect(() => {
    props.onChange(file);
  }, [file]);

  return (
    <>
      <input
        type="file"
        style={{ display: 'none' }}
        ref={uploadButton}
        accept="images/*"
        onChange={(event) => {
          const files = Array.from(event.target.files);
          const uploadedFile = files[0];
          setFile(uploadedFile);
        }}
      />
      <Flex alignItems="center" style={{ marginTop: 15 }}>
        {file && (
          <>
            <Button
              as="div"
              pixi
              inline
              onClick={() => {
                props.onRestore();
                setFile(null);
              }}
            >
              Revert changes
            </Button>
          </>
        )}
        {!file && (
          <Button
            as="div"
            pixi
            inline
            onClick={() => uploadButton?.current?.click()}
          >
            {!props?.value?.url ? 'Upload thumbnail' : 'Replace thumbnail'}
          </Button>
        )}
        {(props.context.type === 'media' ||
          props.file?.file?.ext === 'pdf' ||
          (props.library === 'brandassets' && props.file?.file?.id)) &&
        !props.file?.file?.external_file &&
        !props.file?.file?.contentType?.includes('audio') ? (
          <Button
            pixi
            as="div"
            inline
            style={{ marginLeft: 10 }}
            onClick={() =>
              props.context.reParseFile(props.file?._id, {
                thumbnail: true,
              })
            }
          >
            <Icon name="LightningChargeFill" />
            {!props.file?.file?.previews?.length
              ? 'Autogenerate'
              : 'Regenerate thumbnail'}
          </Button>
        ) : (
          <></>
        )}
      </Flex>
    </>
  );
}
