import React from 'react';
import './style.scss';

export function Intro(props) {
  return (
    <div className="Intro" style={props.style}>
      <div
        className={`Intro__wrapper ${
          props.padding ? 'Intro__wrapper--withPadding' : ''
        }`}
        style={props.wrapperStyle}
      >
        {props.children}
      </div>
    </div>
  );
}

function Illustration(props) {
  return (
    <div
      className={`Intro__illustration ${
        props.size ? 'Intro__illustration--' + props.size : ''
      }`}
      style={props.style}
    >
      {props.children}
    </div>
  );
}
function Message(props) {
  return (
    <div className="Intro__message" style={props.style}>
      {props.children}
    </div>
  );
}
function Actions(props) {
  return (
    <div className="Intro__actions" style={props.style}>
      {props.children}
    </div>
  );
}

Intro.Illustration = Illustration;
Intro.Message = Message;
Intro.Actions = Actions;

export default Intro;
