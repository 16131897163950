import { useReducer } from 'react';

export default function useDispatch() {
  const [listeners, setListeners] = useReducer((state, action) => {
    if (!state[action.key]) {
      state[action.key] = [];
    }
    if (action.type === 'add') {
      state[action.key].push(action.fn);
    }
    if (action.type === 'remove') {
      state[action.key].splice(state[action.key].indexOf(action.fn), 1);
    }
    return state;
  }, {});

  function dispatch(key, data) {
    if (!listeners[key]) {
      return false;
    }
    listeners[key].forEach((fn) => fn(data));
  }

  return {
    addEventListener: (key, fn) => {
      setListeners({
        type: 'add',
        key,
        fn,
      });
    },
    removeEventListener: (key, fn) => {
      setListeners({
        type: 'remove',
        key,
        fn,
      });
    },
    dispatch,
  };
}
