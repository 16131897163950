import * as yup from 'yup';
import React from 'react';
import BoardGrid from '../_icons/BOARD_GRID.svg?react';

export default [
  {
    moduleId: 'BOARDS',
    menuGroupIds: ['BOARDS'],
    moduleInterface: {
      boards: 'BOARDS',
    },
    status: {
      isEmpty: (data) => {
        return !data?.boards?.length;
      },
    },
    moduleMetadataInterface: {
      thumbnailSizes: yup.mixed().oneOf(['normal', 'large']),
      backgroundColor: yup.string(),
      thumbnailType: yup.mixed().oneOf(['standard', 'textOutside', 'gallery']),
    },
    menuContent: { key: 'BOARDS', icon: <BoardGrid />, name: 'Boards' },
  },
  {
    moduleId: 'BRAND_GUIDES',
    menuGroupIds: ['BRAND_GUIDES'],
    moduleInterface: {
      boards: 'BOARDS',
    },
    status: {
      isEmpty: (data) => {
        return !data?.boards?.length;
      },
    },
    moduleMetadataInterface: {
      thumbnailSizes: yup.mixed().oneOf(['normal', 'large']),
      backgroundColor: yup.string(),
      thumbnailType: yup.mixed().oneOf(['standard', 'textOutside', 'gallery']),
    },
    menuContent: {
      key: 'BRAND_GUIDES',
      icon: <BoardGrid />,
      name: 'Sub-guides',
    },
  },
];
