import React from 'react';
import countries from 'data/countries';
import { Dropdown } from 'elements_v2';
import { useTextContext } from 'hooks';

function SelectCountry(props) {
  const Text = useTextContext();
  const items = JSON.parse(JSON.stringify(countries)).countries;
  items.unshift({ Name: 'Admins only', Code: '' });
  return (
    <Dropdown
      search
      name="country"
      value={props.value}
      useV2
      onChange={(value) => props.onChange(value)}
      placeholder={Text.get('ui/country')}
      dropdownProps={props.dropdownProps}
      zIndex={9999}
      options={countries.countries
        .filter((country) => country.Name !== 'All over the world')
        .map((country) => {
          return {
            key: country.Name,
            text: country.Name,
            value: props.valueKey
              ? country[props.valueKey]
              : country.Code.toLowerCase(),
            flag: country.Code.toLowerCase(),
          };
        })}
    />
  );
}

export default React.memo(SelectCountry, (prevProps, nextProps) => {
  return prevProps.value === nextProps.value;
});
