import { EventContext } from 'contexts/Event';
import React, { useEffect, useReducer } from 'react';

export function useEventContext() {
  return React.useContext(EventContext);
}

export function useEventListener(eventName, callback) {
  const Event = useEventContext();
  useEffect(() => {
    Event.addEventListener?.(eventName, callback);
    return () => {
      Event.removeEventListener?.(eventName, callback);
    };
  }, [eventName]);
}

export function EventProvider(props) {
  const [events, setEvents] = useReducer((state, action) => {
    if (action.type === 'add' && !state.includes(action.event)) {
      return [...state, action.event];
    }
    if (action.type === 'remove') {
      return state.filter((event) => event !== action.event);
    }
    return state;
  }, []);
  const [listeners, setListeners] = useReducer((state, action) => {
    if (!action?.event) {
      return state;
    }
    const eventListeners = state?.[action?.event] || [];
    if (action.type === 'add' && !eventListeners.includes(action.callback)) {
      return { ...state, [action.event]: [...eventListeners, action.callback] };
    }
    if (action.type === 'remove') {
      return {
        ...state,
        [action.event]: eventListeners.filter((cb) => cb !== action.callback),
      };
    }
    return state;
  }, {});

  return (
    <EventContext.Provider
      value={{
        addEventListener: (eventName, callback) => {
          setListeners({
            type: 'add',
            event: eventName,
            callback,
          });
        },
        removeEventListener: (eventName, callback) => {
          setListeners({
            type: 'remove',
            event: eventName,
            callback,
          });
        },
        dispatch: (eventName, value) => {
          const eventListeners = listeners[eventName] || [];
          eventListeners.forEach((cb) => cb(value));
        },
        createEvent: (name) => {
          setEvents({
            type: 'add',
            event: name,
          });
        },
      }}
    >
      {props.children}
    </EventContext.Provider>
  );
}
