import {
  Button,
  Dropdown,
  EditPanel,
  Flex,
  Icon,
  Loader,
  Popover,
  Popup,
  Tooltip,
} from 'elements_v2';
import Tags from 'elements_v2/Tags';
import { useUserContext } from 'hooks';
import useCloudStorage from 'hooks/useCloudStorage';
import React, { useEffect, useReducer } from 'react';

export default function EditTags(props) {
  const Media = props.context;
  const User = useUserContext();
  const cloudStorage = useCloudStorage();
  const [value, setValue] = React.useReducer(
    (state, action) => [...action.map((tag) => tag?.toLowerCase())],
    [],
  );
  const [favoriteTags, setFavoriteTags] = React.useReducer(
    (state, action) => [...action.map((tag) => tag?.toLowerCase())],
    cloudStorage.get('manage.favoriteTags') || [],
  );
  const [communityTags, setCommunityTags] = useReducer(
    (state, action) => [...action.map((tag) => tag?.toLowerCase())],
    User?.data?.selectedCommunity.communityTags || [],
  );
  const [editedFavoriteTags, setEditedFavoriteTags] = React.useReducer(
    (state, action) => [...action.map((tag) => tag?.toLowerCase())],
    cloudStorage.get('manage.favoriteTags') || [],
  );
  const [editedCommunityTags, setEditedCommunityTags] = useReducer(
    (state, action) => [...action.map((tag) => tag?.toLowerCase())],
    User?.data?.selectedCommunity.communityTags || [],
  );
  const [originalTags, setOriginalTags] = React.useReducer(
    (state, action) => [...action],
    [],
  );
  const [isShowingAllTags, setIsShowingAllTags] = React.useState(false);
  const [isEditingFavoriteTags, setIsEditingFavoriteTags] =
    React.useState(false);
  const [isEditingGlobalTags, setIsEditingGlobalTags] = React.useState(false);

  const [isEditingTags, setIsEditingTags] = React.useState(false);

  async function saveData(tags) {
    if (props.files) {
      await Media.saveDocuments(
        props.files.map((file) => file._id),
        {
          tags: value,
          tags_remove: originalTags.filter((tag) => !value.includes(tag)),
        },
      );
    } else {
      await Media.saveDocument({
        tags: tags || value,
        _id: props.file._id,
      });
    }
  }

  useEffect(() => {
    props.onChange?.(value);
  }, [value]);

  React.useEffect(() => {
    if (isEditingTags) {
      return;
    }
    const countTags = {};
    let tags = [];
    if (props.files?.length) {
      props.files.forEach((file) => {
        if (file.tags?.length) {
          file.tags.forEach((tag) => {
            if (!countTags[tag]) {
              countTags[tag] = 0;
            }
            countTags[tag] += 1;
          });
        }
      });
      Object.keys(countTags).forEach((tag) => {
        const amount = countTags[tag];
        if (amount === props.files?.length) {
          tags.push(tag);
        }
      });
    }

    if (props.file?.tags) {
      tags = props.file.tags;
    }

    if (props.isContentTags) {
      tags = props.file.file.content_tags;
    }

    setValue(tags);
    setOriginalTags(tags);
  }, [
    props.file?.tags,
    props.isContentTags,
    props.files,
    props.file,
    isEditingTags,
  ]);

  const control = (
    <>
      {!props.isContentTags && (
        <>
          <Popup
            title="Edit global tags"
            description={
              <>Changes to global tags are shared with all other admins.</>
            }
            onClose={() => setIsEditingGlobalTags(false)}
            onOpen={() => setIsEditingGlobalTags(true)}
            isOpen={isEditingGlobalTags}
            zIndex={9999}
            actions={
              <>
                <Button
                  inline
                  pixi
                  style={{ marginRight: 'auto' }}
                  onClick={() => setIsEditingGlobalTags(false)}
                >
                  Cancel
                </Button>
                <Button
                  pixi
                  primary
                  inline
                  onClick={async () => {
                    const newTags = editedCommunityTags.filter(
                      (tag) => !communityTags.includes(tag),
                    );
                    const removedTags = communityTags.filter(
                      (tag) => !editedCommunityTags.includes(tag),
                    );
                    await User.saveSettingsPartial({
                      $addToSet: {
                        communityTags: newTags,
                      },
                    });
                    setCommunityTags(editedCommunityTags);
                    setIsEditingGlobalTags(false);
                  }}
                >
                  Save
                </Button>
              </>
            }
          >
            <Tags
              inputType="text"
              value={editedCommunityTags}
              placeholder="Add global tag"
              inputAtTop
              height={350}
              onChange={(value) => setEditedCommunityTags(value)}
            />
          </Popup>
          <Popup
            title="Edit favorite tags"
            description={<>Your favorite tags are not shared with anyone</>}
            onClose={() => setIsEditingFavoriteTags(false)}
            onOpen={() => setIsEditingFavoriteTags(true)}
            isOpen={isEditingFavoriteTags}
            triggerStyle={{ display: 'inline-block' }}
            overlay
            zIndex={9999}
            actions={
              <>
                <Button
                  inline
                  pixi
                  style={{ marginRight: 'auto' }}
                  onClick={() => setIsEditingFavoriteTags(false)}
                >
                  Cancel
                </Button>
                <Button
                  pixi
                  primary
                  inline
                  onClick={async () => {
                    cloudStorage.set('manage.favoriteTags', editedFavoriteTags);
                    setFavoriteTags(editedFavoriteTags);
                    setIsEditingFavoriteTags(false);
                  }}
                >
                  Save
                </Button>
              </>
            }
          >
            <Tags
              inputType="text"
              value={editedFavoriteTags}
              placeholder="Add favorite tag"
              inputAtTop
              height={350}
              onChange={(value) => setEditedFavoriteTags(value)}
            />
          </Popup>
        </>
      )}
      <Tags
        inputType="text"
        isAddingDisabled={props.isContentTags}
        value={value}
        placeholder="Add tag..."
        onChange={(value) => setValue(value)}
      />
      <Flex style={{ marginTop: 10, gap: 5 }}>
        <Popover
          width={300}
          useV2
          fixed
          trigger={
            <Button
              size="small"
              inline
              dropdown={(isOpen, setIsOpen) => (
                <>
                  <Dropdown.Option
                    onClick={(event) => {
                      event.preventDefault();
                      event.stopPropagation();
                      setIsEditingGlobalTags(true);
                      setIsOpen(false);
                    }}
                  >
                    <Icon name="pencil" />
                    Edit global tags
                  </Dropdown.Option>
                </>
              )}
            >
              <Icon name="circle-plus" />
              Add from global tags
            </Button>
          }
        >
          <Dropdown.Label>Click on a tag to add it to the file</Dropdown.Label>
          <Tags
            isAddingDisabled
            isDeletingDisabled
            inputType="text"
            height={150}
            value={communityTags}
            highlight={value.filter((tag) =>
              communityTags.map((t) => t.trim()).includes(tag.trim()),
            )}
            placeholder="Add global tag"
            onChange={(value) => setFavoriteTags(value)}
            onTagClick={(tag) =>
              value.includes(tag)
                ? setValue(value.filter((t) => t !== tag))
                : setValue([...value, tag])
            }
          />
        </Popover>
        <Popover
          width={300}
          useV2
          fixed
          trigger={
            <Button
              size="small"
              inline
              dropdown={(isOpen, setIsOpen) => (
                <>
                  <Dropdown.Option
                    onClick={(event) => {
                      event.preventDefault();
                      event.stopPropagation();
                      setIsEditingFavoriteTags(true);
                      setIsOpen(false);
                    }}
                  >
                    <Icon name="pencil" />
                    Edit my tags
                  </Dropdown.Option>
                </>
              )}
            >
              <Icon name="circle-plus" />
              Add from my tags
            </Button>
          }
        >
          <Dropdown.Label>Click on a tag to add it to the file</Dropdown.Label>
          <Tags
            isAddingDisabled
            isDeletingDisabled
            inputType="text"
            height={150}
            value={favoriteTags}
            highlight={value.filter((tag) =>
              favoriteTags.map((t) => t.trim()).includes(tag.trim()),
            )}
            placeholder="Add favorite tag"
            onChange={(value) => setFavoriteTags(value)}
            onTagClick={(tag) =>
              value.includes(tag)
                ? setValue(value.filter((t) => t !== tag))
                : setValue([...value, tag])
            }
          />
        </Popover>
      </Flex>
    </>
  );

  if (props.onlyInput) {
    return <div style={{ width: '100%' }}>{control}</div>;
  }

  return (
    <EditPanel.Field
      value=""
      title={
        props.isContentTags ? (
          <Flex alignItems="center">
            <Tooltip message="Generated from the contents of the document">
              <Icon style={{ margin: '3px 4px 0 0' }} name="InfoCircle" />
            </Tooltip>
            Content tags
          </Flex>
        ) : (
          'Tags'
        )
      }
      display={() => (
        <>
          <p>
            {value.length > 10 && !isShowingAllTags
              ? value.slice(0, 10).join(', ')
              : value.join(', ')}
            {value.length > 10 && (
              <span onClick={() => setIsShowingAllTags(!isShowingAllTags)}>
                {isShowingAllTags ? 'Show less' : 'Show all'}
              </span>
            )}
          </p>
          {props.file?.processing?.metadata?.isProcessing && (
            <Loader>
              Searching for tags
              <br />
              in metadata of the file
            </Loader>
          )}
        </>
      )}
      onEdit={(value) => setIsEditingTags(value)}
      hideEditButton={props.hideEditButton}
      control={() => control}
      onSave={saveData}
    />
  );
}
