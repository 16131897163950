import { Button, Flex, Icon, Popover } from 'elements_v2';
import Loader from 'elements_v2/Loader';
import { useMediaContext } from 'hooks';
import useColor from 'hooks/files/useColor';
import useUserStatus from 'hooks/useUserStatus';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from 'react';
import { HexColorPicker } from 'react-colorful';
import { randomString, isNumeric } from 'utils';
import './style.scss';
import { Wysiwyg, WysiwygPreview } from './Wysiwyg';

export const Form = React.forwardRef((props, ref) => {
  return (
    <form className="Pixi__Form" ref={ref} {...props}>
      {props.children}
    </form>
  );
});

function componentToHex(c) {
  const hex = c.toString(16);
  return hex.length === 1 ? `0${hex}` : hex;
}

function rgbToHex(r, g, b) {
  return `#${componentToHex(r)}${componentToHex(g)}${componentToHex(b)}`;
}

function hexToRgb(hex) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
}

export const Color = function (props) {
  const Media = useMediaContext();
  const { getColor } = useColor();
  const [selectedHexColor, setSelectedHexColor] = useState(props.value);
  const [hexColorInput, setHexColorInput] = useState(false);
  const [rgbTemp, setRgbTemp] = useReducer(
    (state, action) => ({ ...action }),
    {}
  );
  const [rgb, setRgb] = useReducer((state, action) => ({ ...action }), {});
  const [applyColor, setApplyColor] = useState(false);
  const [activeSection, setActiveSection] = useState('');
  const [selectedColorId, setSelectedColorId] = useState('');
  const userStatus = useUserStatus();

  useEffect(() => {
    if (
      rgb.r >= 0 &&
      rgb.g >= 0 &&
      rgb.b >= 0 &&
      rgbToHex(rgb.r, rgb.g, rgb.b)
    ) {
      const hex = rgbToHex(rgb.r, rgb.g, rgb.b);
      setSelectedHexColor(hex);
      setHexColorInput(hex);
      if (applyColor) {
        props.onChange(hex);
      }
    }
  }, [rgb, applyColor]);

  useEffect(() => {
    setRgbTemp(hexToRgb(selectedHexColor));
    const timeout = setTimeout(() => {
      if (selectedHexColor) {
        let returnValue = selectedHexColor;
        if (!returnValue.includes('#')) {
          returnValue = `#${selectedHexColor}`;
        }
        props.onChange(
          returnValue,
          activeSection === 'brand-assets' && selectedColorId
            ? selectedColorId
            : undefined
        );
      }
    }, 200);
    return () => {
      clearTimeout(timeout);
    };
  }, [selectedHexColor]);

  useEffect(() => {
    // Check if value is a database id
    if (props.value?.length && props.value?.length > 7) {
      setSelectedColorId(props.value);
      setSelectedHexColor(getColor(props.value));
      setActiveSection('brand-assets');
      setHexColorInput(getColor(props.value));
    } else {
      setSelectedColorId('');
      setSelectedHexColor(props.value || '');
      setActiveSection('custom');
      setHexColorInput(props.value);
    }
  }, [props.value]);

  const hexPicker = (
    <>
      <Flex>
        <input
          type="text"
          onChange={(event) => {
            setHexColorInput(event.target.value);
            if (
              /^[0-9A-F]{6}$/i.test(event.target.value) ||
              /^#[0-9A-F]{6}$/i.test(event.target.value)
            ) {
              setSelectedHexColor(event.target.value);
            }
          }}
          value={hexColorInput}
          maxLength={7}
          placeholder="Hex"
          style={{
            width: '100%',
            marginBottom: 5,
            border: 0,
            padding: 3,
            background: 'rgba(0, 0, 0, 0.05)',
            borderRadius: 4,
          }}
        />
        {['r', 'g', 'b'].map((v) => (
          <input
            key={v}
            type="text"
            onChange={(event) => {
              let value = parseInt(event.target.value);
              if (value <= 0) {
                value = 0;
              }
              if (value > 255) {
                value = 255;
              }
              setRgbTemp({
                ...rgbTemp,
                [v]: value,
              });
            }}
            value={rgbTemp?.[v] || 0}
            maxLength={7}
            placeholder={v.toUpperCase()}
            onBlur={() => {
              if (
                rgbTemp.r >= 0 &&
                rgbTemp.g >= 0 &&
                rgbTemp.b >= 0 &&
                rgbToHex(rgbTemp.r, rgbTemp.g, rgbTemp.b)
              ) {
                setRgb(rgbTemp);
                setApplyColor(true);
              } else {
                setApplyColor(false);
              }
            }}
            style={{
              width: '100%',
              maxWidth: 30,
              marginBottom: 5,
              marginLeft: 3,
              textAlign: 'center',
              border: 0,
              padding: 3,
              background: 'rgba(0, 0, 0, 0.05)',
              borderRadius: 4,
            }}
            // value={selectedColor?.toUpperCase()}
          />
        ))}
      </Flex>
      <Flex
        alignItems="flex-start"
        style={{ width: '100%' }}
        className="InputColor"
      >
        <HexColorPicker
          style={{ width: '100%' }}
          color={selectedHexColor}
          onChange={(color) => {
            if (!color.includes('NaN')) {
              setHexColorInput(color);
              setSelectedHexColor(color);
            }
          }}
        />
      </Flex>
    </>
  );

  const Content = (setIsOpen) => (
    <>
      {activeSection === 'custom' && hexPicker}
      {activeSection === 'brand-assets' && (
        <Flex
          column
          style={{
            width: '100%',
            gap: 5,
            maxHeight: 200,
            marginBottom: 10,
            overflow: 'auto',
          }}
        >
          {Media.data.files
            ?.filter((file) => file.data?.type === 'color')
            .map((c) => (
              <Button
                key={c._id}
                onClick={() => {
                  props.onChange(c.data?.value?.color, c._id);
                  setIsOpen?.(false);
                }}
              >
                <span
                  style={{
                    width: '100%',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    display: 'block',
                  }}
                >
                  {c.name}
                </span>
                <div
                  style={{
                    width: 42,
                    height: 16,
                    background: c.data?.value?.color,
                    borderRadius: 5,
                    flexShrink: 0,
                    marginLeft: 'auto',
                  }}
                />
              </Button>
            ))}
        </Flex>
      )}
      <Flex
        column
        style={{ gap: 5, ...(activeSection ? { marginTop: 10 } : {}) }}
      >
        {activeSection !== 'brand-assets' &&
          userStatus.activeServices?.brand_assets &&
          !props.hideBrandAssets && (
            <Button onClick={() => setActiveSection('brand-assets')}>
              <Icon name="palette" />
              Select from Brand Assets
            </Button>
          )}
        {activeSection !== 'custom' && (
          <Button onClick={() => setActiveSection('custom')}>
            <Icon name="eyedropper" />
            Pick custom color
          </Button>
        )}
      </Flex>
    </>
  );

  if (props.inline) {
    return Content();
  }

  return (
    <div className="Pixi__Input--color">
      <Popover
        width={props.fullWidth ? '100%' : 250}
        boxStyle={props.fullWidth ? {} : { maxWidth: 250 }}
        boxContentStyle={props.fullWidth ? {} : { maxWidth: 250, padding: 15 }}
        defaultOpen={props.openIfEmpty && !props.value}
        keepOpen
        trigger={
          props.hideInput ? (
            <></>
          ) : (
            <Input
              className="Pixi__Input--color"
              icon={props.icon && <Icon name={props.icon} />}
              statusStyle={{
                zoom: '100%',
                pointerEvents: 'auto',
                ...(props.statusStyle || {}),
              }}
              placeholder={props.placeholder || 'Select color'}
              style={{ cursor: 'pointer' }}
              status={
                props.value && (
                  <Icon
                    bubble
                    button
                    name="x-lg"
                    style={{ width: 20, height: 20, fontSize: 12 }}
                    onClick={() => {
                      props.onChange?.(null);
                    }}
                  />
                )
              }
              field={
                selectedHexColor || props.value ? (
                  <div
                    style={{
                      width: '100%',
                      height: 15,
                      marginRight: 10,
                      background: selectedHexColor || props.value,
                      borderRadius: '5px',
                      cursor: 'pointer',
                    }}
                  />
                ) : (
                  <div>{props.placeholder || 'Select color'}</div>
                )
              }
            />
          )
        }
        useV2
        hideOnClickOutside
        noPopover={props.inline}
        largeStatusOnActive
        placeholder="Select color"
      >
        {(isOpen, setIsOpen) => Content(setIsOpen)}
      </Popover>
    </div>
  );
};

const Digits = function (props) {
  const { onChange, numbers, ...rest } = props;
  const [value, setValue] = useReducer((state, action) => {
    if (typeof action.key === 'number') {
      state[action.key] = action.value;
    }
    return { ...state };
  }, {});
  const id = randomString(10);

  useEffect(() => {
    const mergedValue = Object.values(value).join('');
    if (mergedValue?.length === numbers) {
      onChange(mergedValue);
    }
  }, [value]);

  const fields = [...Array(numbers)].map((number, key) => {
    return (
      <Input
        key={number}
        {...rest}
        maxlength={1}
        field={
          <input
            id={id + key}
            maxLength={1}
            type="text"
            value={typeof value[key] === 'number' ? value[key] : ''}
            onPaste={(e) => {
              const value = e.clipboardData.getData('Text');
              if (isNumeric(value)) {
                value.split('').forEach((number, key) => {
                  setValue({
                    key,
                    value: parseInt(number),
                  });
                });
              }
            }}
            onChange={(event) => {
              if (!event.target.value) {
                setValue({
                  key,
                  value: '',
                });
              }
              if (!isNumeric(event.target.value)) {
                return false;
              }
              setValue({
                key,
                value: parseInt(event.target.value),
              });
              const nextElement = document.getElementById(id + (key + 1));
              if (nextElement) {
                nextElement.focus();
              }
            }}
          />
        }
      />
    );
  });
  return <div className="Pixi__Digits">{fields}</div>;
};

const Field = React.forwardRef((props, ref) => {
  const {
    onChange,
    textarea,
    icon,
    style,
    inputStyle,
    colors,
    inputRef,
    ...rest
  } = props;
  const [isFocused, setIsFocused] = useState(false);
  const elementProps = {
    ...rest,
    style: inputStyle,
    ref: inputRef,
    onChange: (event) => {
      if (props.type === 'file') {
        return onChange(event.target.files, event);
      }
      return onChange(event.target.value, event);
    },
    onFocus: () => {
      setIsFocused(true);
      if (props.onFocus) {
        props.onFocus();
      }
    },
    onBlur: () => {
      setIsFocused(false);
      if (props.onBlur) {
        props.onBlur();
      }
    },
    value: props.value,
    placeholder: props.placeholder,
  };

  const uniqueId = useMemo(() => randomString(10), []);

  if (props.type === 'color') {
    rest.custom = (
      <Color
        onChange={(hex, id) => {
          onChange(hex, id);
        }}
        hideBrandAssets={props.hideBrandAssets}
        hideInput={props.hideInput}
        openIfEmpty={elementProps.openIfEmpty}
        position={elementProps.position}
        inline={elementProps.inline}
        value={elementProps.value}
        isForceOpen={elementProps.isForceOpen}
        fullWidth={elementProps.fullWidth}
        placeholder={elementProps.placeholder}
      />
    );
  }

  if (props.type === 'wysiwyg') {
    rest.className = 'Pixi__Input--wysiwyg';
    rest.field = (
      <Wysiwyg
        id={uniqueId}
        onChange={onChange}
        advanced={props.advanced}
        value={props.value}
        placeholder={props.placeholder}
        {...rest}
      />
    );
  }

  if (props.type === 'digits') {
    return <Digits numbers={props.maxlength} onChange={onChange} {...rest} />;
  }

  if (props.type === 'palette' && Array.isArray(colors)) {
    rest.custom = (
      <Flex width="100%" style={{ padding: '6px 0 7px' }}>
        {colors.map((color) => (
          <div
            key={color}
            onClick={() => {
              props.onChange(color);
            }}
            style={{
              width: 24,
              height: 24,
              cursor: 'pointer',
              borderRadius: 6,
              background: color,
              marginRight: 5,
            }}
          />
        ))}
      </Flex>
    );
  }

  return (
    <Input
      {...rest}
      style={style}
      white={props.whiteOnFocus && isFocused}
      ref={ref}
      icon={icon}
      loading={props.loading}
      field={
        textarea ? (
          <textarea className="Pixi" {...elementProps}>
            {elementProps.value}
          </textarea>
        ) : !props.hideInput ? (
          rest.field || <input type="text" className="Pixi" {...elementProps} />
        ) : (
          <></>
        )
      }
    />
  );
});

const Input = React.forwardRef((props, ref) => {
  const className = [
    'Pixi',
    'Pixi__Input',
    ...(Array.isArray(props.className)
      ? []
      : props.className
      ? [props.className]
      : []),
  ];

  if (props.active) {
    className.push('Pixi__Input--active');
  }
  if (props.outlined) {
    className.push('Pixi__Input--outlined');
  }
  if (props.disabled) {
    className.push('Pixi__Input--disabled');
  }
  if (props.largeStatusOnActive) {
    className.push('Pixi__Input--largeStatusOnActive');
  }
  if (props.largeStatus) {
    className.push('Pixi__Input--largeStatus');
  }
  if (props.error) {
    className.push('Pixi__Input--error');
  }
  if (props.warning) {
    className.push('Pixi__Input--warning');
  }
  if (props.white) {
    className.push('Pixi__Input--white');
  }
  if (props.size) {
    className.push(`Pixi__Input--${props.size}`);
  }

  const wrapperClassName = ['Pixi Pixi__InputWrapper', props.className];

  if (props.disabled) {
    wrapperClassName.push('Pixi__InputWrapper--disabled');
  }
  if (props.row) {
    wrapperClassName.push('Pixi__InputWrapper--row');
  }
  if (props.white) {
    wrapperClassName.push('Pixi__InputWrapper--white');
  }
  if (props.error) {
    wrapperClassName.push('Pixi__InputWrapper--error');
  }
  if (props.warning) {
    wrapperClassName.push('Pixi__InputWrapper--warning');
  }
  if (props.addonRight) {
    wrapperClassName.push('Pixi__InputWrapper--addonRight');
  }

  if (props.card) {
    wrapperClassName.push('Pixi__InputWrapper--card');
  }
  return (
    <div className={wrapperClassName.join(' ')} ref={ref} style={props.style}>
      {props.label && (
        <label className="Pixi Pixi__InputLabel" style={props.labelStyle}>
          {props.label}
        </label>
      )}
      {props.custom ? (
        <div className="Pixi__InputCustom" style={props.customStyle}>
          {props.custom}
        </div>
      ) : (
        <div className="Pixi Pixi__InputRow">
          <div
            className={className.join(' ')}
            onClick={props.onClick}
            style={props.inputWrapperStyle}
          >
            {props.icon && (
              <div className="Pixi Pixi__Input__icon">{props.icon}</div>
            )}
            <div className="Pixi Pixi__Input__field">
              {props.field || props.control || props.children}
            </div>
            {props.status && !props.loading && (
              <div
                className="Pixi Pixi__Input__status"
                style={{
                  ...(props?.statusStyle || {}),
                  ...(props?.status?.props?.onClick
                    ? { pointerEvents: 'all' }
                    : {}),
                }}
              >
                {props.status}
              </div>
            )}
            {props.loading && (
              <div className="Pixi Pixi__Input__status Pixi__Input__status--loader">
                <Loader />
              </div>
            )}
          </div>
          {props.addonRight}
        </div>
      )}
      {props.description && (
        <div className="Pixi Pixi__InputDescription">{props.description}</div>
      )}
      {(props.errorMessage || typeof props.error === 'string') && (
        <div className="Pixi Pixi__InputError">
          {props.errorMessage || props.error}
        </div>
      )}
      {props.warning && (
        <div className="Pixi Pixi__InputWarning">
          <Icon name="ExclamationTriangleFill" />
          {props.warning}
        </div>
      )}
    </div>
  );
});

Input.Field = Field;
Input.Wysiwyg = Wysiwyg;
Input.WysiwygPreview = WysiwygPreview;

export default Input;
