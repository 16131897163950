import {
  Box,
  BoxProps,
  Collapse,
  Divider,
  Group,
  GroupProps,
  Paper,
  PaperProps,
  ScrollArea,
  ScrollAreaProps,
  Stack,
  StackProps,
  TextProps,
  UnstyledButton,
  UnstyledButtonProps,
} from '@mantine/core';
import { useColorScheme } from '@pixi/AppController';
import PixiButton from '@pixi/elements/Button';
import PixiIcon, { PixiIconName } from '@pixi/elements/Icon';
import PixiText from '@pixi/elements/Text';
import PixiTooltip from '@pixi/elements/Tooltip';
import {
  ForwardedRef,
  forwardRef,
  ReactElement,
  ReactNode,
  useState,
} from 'react';

export function Section({ children }: { children: React.ReactNode }) {
  return (
    <>
      <Box w="100%" px="lg" py="lg">
        {children}
      </Box>
      <Divider />
    </>
  );
}

export function SectionCollapse({
  children,
  label,
  icon,
  defaultOpen,
  forceOpen,
  leftSection,
  rightSection: _rightSection,
  isRightSectionBeforeChevron,
  rightSectionOnlyOnOpen,
  noChevronOnOpen,
  openColor,
  noChevron,
  noDivider,
  padding,
  onToggle,
  headerProps,
  paperProps,
  isFocused,
  scrollAreaMah,
  ...rest
}: {
  children?: React.ReactNode;
  label?: ReactNode;
  icon?: PixiIconName;
  defaultOpen?: boolean;
  forceOpen?: boolean;
  leftSection?: ReactNode;
  rightSection?: ReactNode | (({ isOpen }: { isOpen: boolean }) => ReactNode);
  openColor?: string;
  isRightSectionBeforeChevron?: boolean;
  rightSectionOnlyOnOpen?: boolean;
  noChevronOnOpen?: boolean;
  noChevron?: boolean;
  noDivider?: boolean;
  onToggle?: (isOpen: boolean) => void;
  padding?: StackProps['px'];
  headerProps?: Partial<Omit<UnstyledButtonProps, 'onClick'>>;
  paperProps?: Partial<PaperProps>;
  scrollAreaMah?: ScrollAreaProps['mah'];
  isFocused?: boolean;
} & BoxProps) {
  const [_isOpen, setIsOpen] = useState(defaultOpen ?? false);
  const isOpen = forceOpen || _isOpen;
  const [isTriggerHover, setIsTriggerHover] = useState(false);
  const colorScheme = useColorScheme();

  const rightSection =
    typeof _rightSection === 'function'
      ? _rightSection({
          isOpen,
        })
      : _rightSection;

  return (
    <>
      <Box w="100%" {...rest}>
        <Paper
          pos="sticky"
          style={{ zIndex: 2 }}
          radius={0}
          top={0}
          {...paperProps}
        >
          <UnstyledButton
            px="lg"
            py="md"
            fw="400"
            onMouseOver={() => {
              setIsTriggerHover(true);
            }}
            onMouseLeave={() => {
              setIsTriggerHover(false);
            }}
            bg={
              isOpen
                ? colorScheme === 'dark'
                  ? 'dark.8'
                  : 'gray.0'
                : undefined
            }
            color="dark"
            c={colorScheme === 'dark' ? 'white' : 'dark'}
            // styles={{
            //   label: {w
            //     fontSize: 'var(--mantine-font-size-sm)',
            //     whiteSpace: 'nowrap',
            //     textOverflow: 'ellipsis',
            //     overflow: 'hidden',
            //     width: '100%',
            //   }
            // }}
            w="100%"
            onClick={() => {
              onToggle?.(!isOpen);
              setIsOpen(!isOpen);
            }}
            {...headerProps}
          >
            <Group w="100%" wrap="nowrap">
              {(icon || leftSection) && (
                <Group gap="xs" style={{ flexShrink: 0 }}>
                  {leftSection}
                  {!!icon && <PixiIcon name={icon} />}
                </Group>
              )}
              <PixiText w="100%" size="sm" fw="500">
                {label}
              </PixiText>
              <Group wrap="nowrap" style={{ flexShrink: 0 }}>
                <Group
                  gap="0"
                  onClick={(event) => {
                    event.stopPropagation();
                  }}
                  wrap="nowrap"
                  style={!rightSection ? { pointerEvents: 'none' } : undefined}
                >
                  {isRightSectionBeforeChevron ? (
                    !rightSectionOnlyOnOpen || isOpen ? (
                      rightSection
                    ) : (
                      <></>
                    )
                  ) : noChevron || (noChevronOnOpen && isOpen) ? (
                    <>{rightSection}</>
                  ) : (
                    !forceOpen &&
                    !noChevron && (
                      <>
                        <PixiIcon
                          name={isOpen ? 'chevron-down' : 'chevron-right'}
                          size="sm"
                          color="gray"
                        />
                      </>
                    )
                  )}
                  {!isRightSectionBeforeChevron ? (
                    !rightSectionOnlyOnOpen || isOpen ? (
                      <></>
                    ) : (
                      rightSection
                    )
                  ) : noChevronOnOpen && isOpen ? (
                    <></>
                  ) : (
                    !noChevron && (
                      <PixiIcon
                        name={isOpen ? 'chevron-down' : 'chevron-right'}
                        size="sm"
                        color="gray"
                      />
                    )
                  )}
                </Group>
              </Group>
            </Group>
          </UnstyledButton>
        </Paper>
        <Collapse w="100%" in={isOpen}>
          {isOpen && (
            <ScrollArea
              styles={
                scrollAreaMah
                  ? {
                      viewport: {
                        maxHeight: scrollAreaMah as string,
                      },
                    }
                  : undefined
              }
              style={{ overflow: 'hidden' }}
            >
              <Stack
                w="100%"
                miw={1}
                py="lg"
                pt="5"
                px={padding || 'lg'}
                bg={
                  isTriggerHover || isOpen
                    ? colorScheme === 'dark'
                      ? 'dark.8'
                      : openColor || 'gray.0'
                    : undefined
                }
                style={{ overflow: 'hidden' }}
              >
                {isOpen ? children : <></>}
              </Stack>
            </ScrollArea>
          )}
        </Collapse>
      </Box>
      {!noDivider && (
        <Divider style={{ flexShrink: 0, position: 'relative', zIndex: 5 }} />
      )}
    </>
  );
}

export const Detail = forwardRef(
  (
    props: {
      left: ReactNode;
      icon?: PixiIconName | ReactNode;
      right?: ReactNode;
      rightProps?: TextProps;
      rightNoText?: boolean;
      tooltip?: ReactNode;
      leftTooltip?: ReactNode;
      column?: boolean;
      noDivider?: boolean;
      actions?: {
        label: string;
        icon: PixiIconName;
        asButton?: boolean;
        tooltip?: ReactNode;
        onClick?: () => void;
        wrapper?: (target: ReactElement) => ReactElement;
      }[];
      actionsProps?: Partial<GroupProps>;
    },
    ref: ForwardedRef<HTMLDivElement>,
  ) => {
    const {
      left,
      right,
      rightProps,
      rightNoText,
      icon,
      tooltip,
      actions,
      leftTooltip,
      column,
      noDivider,
    } = props;
    const leftRender = (
      <PixiText
        size="xs"
        c="dimmed"
        w="100%"
        maw={!column ? 'none' : 200}
        miw={1}
        style={{
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          flexShrink: 1,
        }}
      >
        {left}
      </PixiText>
    );
    const rightRender = !right ? undefined : rightNoText ? (
      (right as ReactNode)
    ) : (
      <PixiText
        w="100%"
        maw={column ? 'none' : 160}
        miw={1}
        size="xs"
        ta={column ? 'left' : 'right'}
        style={
          !column
            ? {
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                flexShrink: 1,
              }
            : {
                wordBreak: 'break-word',
              }
        }
        {...rightProps}
      >
        {right}
      </PixiText>
    );
    return (
      <Stack w="100%" gap="0" ref={ref}>
        <Group w="100%" justify="space-between" align="center" wrap="nowrap">
          <Group
            w="100%"
            style={{ overflow: 'hidden' }}
            gap="xs"
            justify="flex-start"
            wrap="nowrap"
          >
            {icon && typeof icon === 'string' ? (
              <PixiIcon name={icon as PixiIconName} size="sm" />
            ) : (
              icon
            )}

            {leftTooltip ? (
              <PixiTooltip
                label={leftTooltip}
                w="auto"
                maw={300}
                multiline
                style={{ wordWrap: 'break-word' }}
              >
                {leftRender}
              </PixiTooltip>
            ) : (
              leftRender
            )}
          </Group>
          {((!column && rightRender) || actions) && (
            <Group
              w="100%"
              style={{ overflow: 'hidden' }}
              gap="xs"
              justify="flex-end"
              align="center"
              wrap="nowrap"
            >
              {rightRender && !column && (
                <>
                  {tooltip ? (
                    <PixiTooltip
                      label={tooltip}
                      w="auto"
                      maw={300}
                      multiline
                      style={{ wordWrap: 'break-word' }}
                    >
                      {rightRender as ReactElement}
                    </PixiTooltip>
                  ) : (
                    rightRender
                  )}
                </>
              )}
              {actions ? (
                <Group
                  w="auto"
                  gap="5"
                  wrap="nowrap"
                  align="flex-start"
                  miw="unset"
                  justify="flex-end"
                  {...(props.actionsProps || {})}
                >
                  {actions.map((action) => {
                    let target = (
                      <PixiButton
                        px={action.asButton ? undefined : '5'}
                        size={action.asButton ? 'compact-xs' : 'compact-sm'}
                        color="dark"
                        fz={12}
                        radius={action.asButton ? 'xl' : undefined}
                        variant={action.asButton ? 'outline' : 'light'}
                        key={action.icon}
                        onClick={() => {
                          action.onClick?.();
                        }}
                      >
                        {!action.asButton && (
                          <PixiIcon size="sm" name={action.icon} />
                        )}
                        {!action.asButton ? null : <>{action.label}</>}
                      </PixiButton>
                    );
                    if (action.tooltip) {
                      target = (
                        <PixiTooltip label={action.tooltip} key={action.icon}>
                          {target}
                        </PixiTooltip>
                      );
                    }
                    if (action.wrapper) {
                      return action.wrapper(target);
                    }
                    return target;
                  })}
                </Group>
              ) : (
                <></>
              )}
            </Group>
          )}
        </Group>
        {column && rightRender ? (
          <Box w="100%" mt="xs">
            {tooltip ? (
              <PixiTooltip label={tooltip}>
                {rightRender as ReactElement}
              </PixiTooltip>
            ) : (
              rightRender
            )}
            {!noDivider && <Divider mt="xs" />}
          </Box>
        ) : (
          <></>
        )}
      </Stack>
    );
  },
);

export function Title({
  title,
  icon,
  onClose,
  reversed,
  rightSection,
}: {
  title: string;
  icon?: PixiIconName | ReactNode;
  onClose?: () => void;
  reversed?: boolean;
  rightSection?: ReactNode;
}) {
  const hideButton = (
    <PixiButton
      px="xs"
      size="xs"
      color="dark"
      variant="subtle"
      onClick={() => {
        onClose?.();
      }}
      style={{ flexShrink: 0 }}
    >
      <PixiIcon name={`arrow-${reversed ? 'right' : 'left'}-from-line`} />
    </PixiButton>
  );
  return (
    <Group w="100%" justify="space-between" p="lg" py="md" wrap="nowrap">
      {reversed && hideButton}
      <Group w="100%" wrap="nowrap">
        {typeof icon === 'string' ? <PixiIcon name="filter" /> : icon}
        <PixiText fw={400} fz="sm">
          {title}
        </PixiText>
      </Group>
      {!reversed && hideButton}
      {rightSection}
    </Group>
  );
}
