import EventEmitter, { EventDataStructure } from '@pixi/helpers/EventEmitter';
import { AppConfigInterface } from './AppController';

// Event data structure example
export interface GlobalEventsData extends EventDataStructure {
  assetDrop: [droppedId: string, asset: AppConfigInterface['draggingAsset']];
  messageReceived: [message: string, channelId: string];
}

const AppGlobalEvents = new EventEmitter<GlobalEventsData>();

export default AppGlobalEvents;
