import React from 'react';
import { Storage } from 'services';
import { UserContext } from 'contexts/User';
import { BrandContext } from 'contexts/Brand';

const BRAND_STYLE_ID = 'brand-control';
const BRAND_CLASSNAME_BACKGROUND = 'brand__color__bg';
const BRAND_CLASSNAME_BACKGROUND_BEFORE = 'brand__color__bg__before';
const BRAND_CLASSNAME_BACKGROUND_AFTER = 'brand__color__bg__after';
const BRAND_CLASSNAME_COLOR = 'brand__color__text';
const BRAND_CLASSNAME_FILL = 'brand__color__fill';
const BRAND_CLASSNAME_FILL_HOVER = 'brand__color__fill__hover';
const BRAND_CLASSNAME_BORDERCOLOR = 'brand__color__border';
const BRAND_CLASSNAME_BORDERCOLOR_AFTER = 'brand__color__border__after';
const BRAND_CLASSNAME_BORDERCOLOR_BEFORE = 'brand__color__border__before';
const BRAND_CLASSNAME_BACKGROUND_IMAGE = 'brand__background__image';
const BRAND_CLASSNAME_BACKGROUND_COLOR = 'brand__background__color';

const DEFAULT_BRAND = {
  color: '#6716d8',
};
export function BrandProvider(props) {
  const [data, setData] = React.useReducer((state, action) => {
    return { ...DEFAULT_BRAND, ...action };
  }, DEFAULT_BRAND);

  const [isDisabled, setIsDisabled] = React.useState(false);
  const [slug, setSlug] = React.useState(null);
  const User = React.useContext(UserContext);
  React.useEffect(() => {
    if (
      Storage.getItem('userSelectedSpace') &&
      !Storage.getItem('userSpaces')
    ) {
      setFromSlug(Storage.getItem('userSelectedSpace'));
    }
  }, []);

  React.useEffect(() => {
    if (User.spaces?.selected) {
      setBrand(User.spaces?.selected);
    }
  }, [
    User.spaces?.selected,
    User.spaces?.selected?.color,
    User.spaces?.selected?.logo,
  ]);

  React.useEffect(() => {
    setDOM(data);
  }, [isDisabled]);

  React.useEffect(() => {
    setDOM(data);
  }, [data]);

  function setBrand(data) {
    setData({
      color: data.color || '#6716d8',
      logo: data.logo,
      disable_email_login: data.disable_email_login,
      background_photo: data.background_photo,
      name: data.name,
    });
  }

  function clear() {
    setBrand(DEFAULT_BRAND);
  }

  async function setFromSlug(slug) {
    if (slug) {
      const brand = await User.request.proxy.getCommunityV2(slug);
      if (brand) {
        setBrand(brand);
        setSlug(slug);
      }
    }
  }

  async function getBrand(slug) {
    return User.request.proxy.getCommunityV2(slug);
  }

  function hexToRgbA(hex) {
    let c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
      c = hex.substring(1).split('');
      if (c.length === 3) {
        c = [c[0], c[0], c[1], c[1], c[2], c[2]];
      }
      c = `0x${c.join('')}`;
      return `rgba(${[(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',')},1)`;
    }
    throw new Error('Bad Hex');
  }

  function setDOM(brandData) {
    if (isDisabled) {
      brandData = DEFAULT_BRAND;
    }
    const body = document.getElementsByTagName('body')[0];
    let brandElement = document.getElementById(BRAND_STYLE_ID);
    const metadata = document.getElementsByName('theme-color')?.[0];
    if (!brandElement) {
      brandElement = document.createElement('style');
      brandElement.setAttribute('id', BRAND_STYLE_ID);
      body.appendChild(brandElement);
    }
    if (metadata?.setAttribute) {
      metadata.setAttribute('content', brandData.color);
    }
    brandElement.innerHTML = `
        .${BRAND_CLASSNAME_BACKGROUND} { background: ${brandData.color}; }
        .${BRAND_CLASSNAME_BACKGROUND_BEFORE}:before { background: ${
          brandData.color
        } !important;}
        .${BRAND_CLASSNAME_BACKGROUND_AFTER}:after { background: ${
          brandData.color
        } !important;}
        .${BRAND_CLASSNAME_COLOR} { color: ${brandData.color} !important; }
        .${BRAND_CLASSNAME_BORDERCOLOR} { border-color: ${
          brandData.color
        } !important; }
        .${BRAND_CLASSNAME_BORDERCOLOR_BEFORE}:before { border-color: ${
          brandData.color
        } !important; }
        .${BRAND_CLASSNAME_BORDERCOLOR_AFTER}:after { border-color: ${
          brandData.color
        } }
        .${BRAND_CLASSNAME_BACKGROUND_IMAGE} { background-image: url(${
          brandData.background_photo
        })}
        .${BRAND_CLASSNAME_BACKGROUND_COLOR} { background-color: ${
          brandData.color
        }}
        .${BRAND_CLASSNAME_FILL} { fill: ${brandData.color} } 
        .${BRAND_CLASSNAME_FILL_HOVER}:hover { fill: ${brandData.color} } 
        .Collection__body:before { border-color: ${
          brandData.color
        } !important; }
        .Hero__element:after { border-color: ${brandData.color}; }
        .DownloadLinks__option__radio--active:before { background: ${
          brandData.color
        } }
        .DownloadLinks__option__radio--active:after { background: ${
          brandData.color
        } }
        .Tag--link:hover { color: ${brandData.color}; border-color: ${
          brandData.color
        } }
        .Tag--link:active { background: ${brandData.color} }
        .Button--white.Button--primary { color: ${brandData.color}; }
        .Button--white.Button--primary:hover { color: ${
          brandData.color
        } !important; }
        .sub-nav__items__item--active { border-color: ${
          brandData.color
        } !important; }
        .CollectionLink__content__image:before { border-color: ${
          brandData.color
        } !important; }
        a { color: ${brandData.color} }
        a:hover { color: ${brandData.color} }
        .Pixi__CollectionGroup__toolbar .Pixi__Icon, 
        .Pixi__CollectionGroup__content__item__toolbar .Pixi__Icon {
          background: ${brandData.color} !important;
        }
        .ui.primary.button {
          background-color: ${brandData.color};
        }
        .ui.primary.button:hover {
          background-color: ${brandData.color};
          opacity: 0.8;
        }
        .ui.primary.button:active, .ui.primary.button:focus {
          background-color: ${brandData.color};
        }
        .ui.primary.button.basic {
          color: ${brandData.color} !important;
          box-shadow: 0px 0px 0px 1px ${hexToRgbA(brandData.color)} !important;
        }
        .ui.primary.button.basic:hover {
          color: ${brandData.color} !important;
          box-shadow: 0px 0px 0px 1px ${hexToRgbA(brandData.color)} !important;
          opacity: 0.6;
        }
        .ui.button.primary.white {
          color: ${brandData.color};
        }
        .ui.selection.active.dropdown, .ui.selection.dropdown:focus, .ui.selection.active.dropdown .menu {
          border-color: ${brandData.color} !important;
        }
      `;
  }

  const values = {
    setDOM,
    data,
    slug,
    setFromSlug,
    setIsDisabled,
    isDisabled,
    clear,
    getBrand,
  };
  return (
    <BrandContext.Provider value={values}>
      {props.children}
    </BrandContext.Provider>
  );
}
