import { useDocumentsContext } from './useDocumentsContext';
import { useMediaContext } from './useMediaContext';

export const useContextFromCollection = (collection) => {
  const contexts = {
    media: useMediaContext(),
    documents: useDocumentsContext(),
    brand: useMediaContext(),
  };

  if (!collection?.libraryType) {
    if (
      contexts.media?.stores?.data?.collections?.find(
        (c) => c?._id === collection?._id,
      )
    ) {
      return contexts.media;
    }
  }

  return contexts[collection?.libraryType];
};

export const useGetContextFromCollectionId = () => {
  const contexts = {
    media: useMediaContext(),
    documents: useDocumentsContext(),
    brand: useMediaContext(),
  };

  return (collectionId) =>
    Object.keys(contexts).reduce((context, contextKey) => {
      if (
        !context &&
        contexts[contextKey].data.collections.find(
          (c) => c._id === collectionId,
        )
      ) {
        return contexts[contextKey];
      }
      return null;
    }, null);
};

export const useGetContextFromCollection = () => {
  const contexts = {
    media: useMediaContext(),
    documents: useDocumentsContext(),
    brand: useMediaContext(),
  };

  return (collection) => contexts[collection?.libraryType];
};
