import { Image, Input } from '@mantine/core';
import PixiButton from '@pixi/elements/Button';
import PixiForm from '@pixi/elements/Form';
import PixiIcon from '@pixi/elements/Icon';
import useContextFromFile from 'hooks/useContextFromFile';
import { ReactElement } from 'react';

export default function VariantForm({
  file,
  variant,
  target,
}: {
  file: Pickit.FileInterface;
  variant?: Pickit.InterfacePreviewFile;
  target: ReactElement;
}) {
  const context = useContextFromFile(file);
  if (!variant) {
    return <></>;
  }
  return (
    <PixiForm<{
      name: string;
    }>
      width={300}
      type="dropdown"
      title="Set variant name"
      form={{
        name: {
          key: 'name',
          value: variant.alias || '',
          render: ({ value, setValue }) => (
            <Input
              placeholder="Set a name"
              value={value}
              onChange={(event) => setValue(event.currentTarget.value)}
            />
          ),
        },
      }}
      color="gray"
      onSubmit={async (data) => {
        await context.savePartial(
          {
            _id: file._id,
            'file.variants.$.alias': data.name,
          },
          {
            'file.variants.id': variant.id,
          },
        );
      }}
      target={target}
    />
  );
}
