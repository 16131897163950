import * as React from 'react';
import { useDocumentsContext } from './useDocumentsContext';
import { useMediaContext } from './useMediaContext';

const useContextFromType = (type) => {
  const contexts = {
    media: useMediaContext(),
    documents: useDocumentsContext(),
    brand: useMediaContext(),
    brandassets: useMediaContext(),
  };

  if (!type) {
    return null;
  }
  if (!contexts[type]) {
    return null;
  }
  if (type !== 'documents') {
    return contexts.media;
  }

  return contexts[type];
};

export default useContextFromType;
