import * as React from 'react';
import ColorScheme from './steps/ColorScheme';
import Finish from './steps/Finish';
import Clipboard from './steps/Clipboard';
import Startup from './steps/Startup';
import Welcome from './steps/Welcome';
import Window from './steps/Window';
import './style.scss';
import { Button } from 'elements_v2';

interface WizardProps {}

const Wizard: React.FC<WizardProps> = (props: WizardProps) => {
  const [currentStep, setCurrentStep] = React.useState(0);
  function next() {
    setCurrentStep(currentStep + 1);
  }
  const steps = [Welcome, Window, ColorScheme, Startup, Clipboard, Finish];
  const CurrentStep = steps[currentStep];
  return (
    <div className="Wizard">
      <div className="Wizard__content">
        <CurrentStep next={next} />
      </div>
      <div className="Wizard__footer">
        {currentStep !== 0 && (
          <Button inline pixi onClick={() => setCurrentStep(currentStep - 1)}>
            Go back
          </Button>
        )}
      </div>
    </div>
  );
};

export default Wizard;
