import { useEffect } from 'react';
import useIntegrationConfig from '../useIntegrationConfig';
import useJobs from '../useJobs';
import useHubspotImportAPI from './useHubspotImportAPI';

export default function useHubspotImport(props) {
  const config = useIntegrationConfig('hubspot_import');
  const { API_ID, enableCapabilities } = config;
  useEffect(() => {
    enableCapabilities([
      'jobs',
      'nested',
      'recent',
      'search',
      'hasDrives',
      'approval',
    ]);
  }, []);

  const HubspotImportAPI = useHubspotImportAPI({
    API_ID,
    HubspotImportAuth: props.HubspotImportAuth,
  });
  const Jobs = useJobs({ API_ID, API: HubspotImportAPI });

  return {
    ...HubspotImportAPI,
    ...Jobs,
    ...config,
  };
}
