import { Image, ThemeIcon } from '@mantine/core';
import { useDocumentsContext, useMediaContext } from 'hooks';
import { useContextFromCollection } from 'hooks/useContextFromCollection';

export default function CollectionThumbnail({
  collection: _collection,
  id,
}: {
  collection?: Pickit.CollectionInterface;
  id?: string;
}) {
  const media: any = useMediaContext();
  const documents: any = useDocumentsContext();
  const collection = _collection?._id
    ? _collection
    : media?.stores.data.collections.find((c: any) => c._id === id) ||
      documents?.stores.data.collections.find((c: any) => c._id === id) ||
      _collection;
  const context = useContextFromCollection(collection);
  if (!collection) {
    return <></>;
  }
  return (
    <ThemeIcon variant="light" color="dark" style={{ overflow: 'hidden' }}>
      <Image
        w="100%"
        h="100%"
        fit="cover"
        src={context.getCollectionThumbnailUrl(collection)}
      />
    </ThemeIcon>
  );
}
