/**
 * This function is protecting crash when localStorage is not accessible.
 */

export default {
  getItem: (key) => {
    try {
      return sessionStorage.getItem(key);
    } catch (e) {
      return null;
    }
  },
};
