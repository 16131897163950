import {
  Box,
  Group,
  Loader,
  Paper,
  PaperProps,
  Stack,
  Switch,
  SwitchProps,
  UnstyledButton,
  UnstyledButtonProps,
} from '@mantine/core';
import PixiText from '../Text';
import {
  ForwardedRef,
  forwardRef,
  ReactNode,
  useEffect,
  useState,
} from 'react';
import PixiTooltip from '../Tooltip';
import PixiIcon, { PixiIconName } from '../Icon';
import { useColorScheme } from '@pixi/AppController';

const ToggleCard = forwardRef(
  (
    {
      isActive: _isActive,
      onChange,
      title,
      description,
      rightSection,
      paperProps,
      tooltip,
      onlySwitchClick,
      switchProps,
      icon,
      iconTooltip,
      ...rest
    }: {
      isActive?: boolean;
      onChange?: (isActive: boolean) => Promise<void> | void;
      title: ReactNode;
      description?: ReactNode;
      rightSection?: ReactNode;
      paperProps?: Partial<PaperProps>;
      onlySwitchClick?: boolean;
      tooltip?: boolean;
      icon?: PixiIconName;
      iconTooltip?: string;
      switchProps?: Partial<SwitchProps>;
    } & Partial<UnstyledButtonProps>,
    ref: ForwardedRef<HTMLButtonElement>,
  ) => {
    const [isActive, setIsActive] = useState(_isActive);
    const [isLoading, setIsLoading] = useState(false);
    const [isMouseOver, setIsMouseOver] = useState(false);
    const colorScheme = useColorScheme();

    useEffect(() => {
      setIsActive(_isActive);
    }, [_isActive]);

    return (
      <UnstyledButton
        w="100%"
        onMouseEnter={() => {
          setIsMouseOver(true);
        }}
        onMouseLeave={() => {
          setIsMouseOver(false);
        }}
        ref={ref}
        style={{
          cursor: onChange ? 'pointer' : 'not-allowed',
        }}
        onClick={async () => {
          if (!onChange) {
            return;
          }
          if (onlySwitchClick) {
            return false;
          }
          if (isLoading) {
            return;
          }
          setIsLoading(true);
          setIsActive(!isActive);
          await onChange?.(!isActive);
          setIsLoading(false);
        }}
        {...rest}
      >
        <Paper
          w="100%"
          radius="md"
          p="lg"
          bg={
            isMouseOver && !!onChange && !onlySwitchClick
              ? colorScheme === 'dark'
                ? 'dark.5'
                : 'gray.0'
              : colorScheme === 'dark'
                ? 'dark.6'
                : 'white'
          }
          {...paperProps}
        >
          <Group justify="space-between">
            <Group gap="md">
              {icon && iconTooltip ? (
                <PixiTooltip label={iconTooltip}>
                  <PixiIcon name={icon} />
                </PixiTooltip>
              ) : (
                icon && <PixiIcon name={icon} />
              )}
              <Stack gap="5">
                <PixiText size="sm">{title}</PixiText>
                {description && (
                  <PixiText size="sm" c="dimmed">
                    {description}
                  </PixiText>
                )}
              </Stack>
            </Group>
            <Group gap="xs">
              {rightSection}
              {isLoading && (
                <Loader
                  size="xs"
                  color={colorScheme === 'dark' ? 'white' : 'gray'}
                />
              )}
              <PixiTooltip label={tooltip} disabled={!tooltip}>
                <Box>
                  <Switch
                    size="md"
                    height={100}
                    mr="md"
                    checked={isActive}
                    onClick={async () => {
                      if (onlySwitchClick) {
                        setIsLoading(true);
                        setIsActive(!isActive);
                        await onChange?.(!isActive);
                        setIsLoading(false);
                      }
                    }}
                    style={
                      onlySwitchClick
                        ? { cursor: 'pointer' }
                        : { pointerEvents: 'none' }
                    }
                    {...switchProps}
                  />
                </Box>
              </PixiTooltip>
            </Group>
          </Group>
        </Paper>
      </UnstyledButton>
    );
  },
);

export default ToggleCard;
