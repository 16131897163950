import {
  Box,
  Divider,
  Indicator,
  Paper,
  PaperProps,
  Stack,
} from '@mantine/core';
import PixiButton from '@pixi/elements/Button';
import PixiIcon from '@pixi/elements/Icon';
import { getMultiStore, useKeysSelector } from '@pixi/store';
import { useEffect, useState } from 'react';
import AssetEditPanel from '../AssetPanel';
import { SectionCollapse, Title } from '../AssetPanel/elements';
import { useAssetGridContext } from './AssetGridContext';
import { AssetGridRightPanelProps } from './Helpers';
import useFullViewportHeight from '@pixi/hooks/useFullViewportHeight';
import AssetGridMetadata from './components/RightPanels/AssetGridMetadata';
import { useColorScheme } from '@pixi/AppController';

export default function AssetGridRightPanel({
  keepPlaceholder,
  ...rest
}: AssetGridRightPanelProps) {
  // const [isHidden, setIsHidden] = useState(true);
  // const [isForceHidden, setIsForceHidden] = useState(false);
  const {
    selectedFiles,
    id,
    activeShortcutIds,
    getFiles,
    getAvailableFilters,
    status,
    getMetadata,
  } = useAssetGridContext();
  const colorScheme = useColorScheme();
  const { ref, height } = useFullViewportHeight();
  const [isHidden, setIsHidden] = useState(false);
  const selectedFilesData =
    useKeysSelector('FILES', [...selectedFiles.map((f) => f._id)]) || [];

  if (keepPlaceholder) {
    return (
      <Box
        w="100%"
        maw={350}
        style={{
          flexShrink: 0,
          overflow: 'hidden',
        }}
      />
    );
  }

  return (
    <>
      <Paper
        w="100%"
        maw={!isHidden ? 350 : 64}
        mih={1}
        pos="sticky"
        top={0}
        ref={ref}
        variant="glass"
        style={{
          height,
          flexShrink: 0,
          transition: 'max-width .15s',
          overflow: 'hidden',
        }}
        {...rest}
      >
        {isHidden || !selectedFilesData?.length ? (
          <Stack
            w="100%"
            justify="center"
            align="center"
            p={isHidden ? 'xs' : undefined}
          >
            {!selectedFilesData?.length && !isHidden ? (
              <Stack w="100%" gap="0">
                <Title
                  title="Status"
                  onClose={() => {
                    setIsHidden(true);
                  }}
                  reversed
                />
                <Divider />
                <AssetGridMetadata />
                {!status.isLoading && (
                  <Box w="100%" p="md">
                    <PixiButton
                      fullWidth
                      variant="glass"
                      onClick={() => {
                        getFiles(true);
                        getAvailableFilters();
                        getMetadata();
                      }}
                      color="gray"
                      leftSection={<PixiIcon name="arrow-rotate-right" />}
                    >
                      Reload
                    </PixiButton>
                  </Box>
                )}
              </Stack>
            ) : (
              <Box w="100%" mt="9">
                <Indicator
                  offset={2}
                  disabled={!selectedFiles?.length}
                  size="18"
                  label={selectedFiles?.length}
                >
                  <PixiButton
                    fullWidth
                    color="dark"
                    variant="light"
                    px="xs"
                    size="md"
                    onClick={() => {
                      setIsHidden(false);
                    }}
                  >
                    <PixiIcon
                      name={selectedFiles?.length ? 'circle-info' : 'chart-pie'}
                    />
                  </PixiButton>
                </Indicator>
              </Box>
            )}
          </Stack>
        ) : (
          <AssetEditPanel
            files={selectedFilesData}
            onClose={() => {
              getMultiStore('FILES_SELECTED', id).reset();
            }}
            onHide={() => {
              setIsHidden(true);
            }}
            focus={
              activeShortcutIds?.find((p) => p && p?.includes('approvals'))
                ? 'approvals'
                : activeShortcutIds?.find((p) => p && p.includes('feedback'))
                  ? 'feedback'
                  : undefined
            }
          />
        )}
      </Paper>
    </>
  );
}
