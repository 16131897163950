import { format } from 'date-fns';

export function propertyValueToString({
  value,
  property,
}: {
  value: Pickit.PropertyValue;
  property: Pickit.CommunityPropertyInterface;
}): string {
  const printValue =
    property.type === 'boolean' && typeof value?.boolean === 'boolean'
      ? ''
      : property.type === 'number'
        ? value?.number?.toString()
        : property.type === 'text'
          ? value?.text
          : property.type === 'dropdown' &&
              !property.typeProps?.dropdown?.multiSelect
            ? value?.dropdown?.[0]
            : property.type === 'dropdown' &&
                property.typeProps?.dropdown?.multiSelect
              ? value?.dropdown?.join?.(', ')
              : value?.boolean
                ? 'true'
                : property.type === 'tags'
                  ? value?.tags?.join(', ')
                  : property.type === 'date' && value?.date
                    ? format(new Date(value?.date), 'yyyy-MM-dd')
                    : '';

  return printValue || '';
}
