import ApiService from './_ApiService';

class License extends ApiService {
  async updateLicense(contributionId, license) {
    const options = {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      skipBaseUrl: true,
      requestUrl: window.pickit.config.SERVICE_URL,
      body: JSON.stringify(license),
    };
    return this.request(`/contribution/${contributionId}/license`, options);
  }

  async replaceLicense(contributionId, license) {
    const options = {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      skipBaseUrl: true,
      requestUrl: window.pickit.config.SERVICE_URL,
      body: JSON.stringify(license),
    };
    return this.request(`/contribution/${contributionId}/license`, options);
  }

  async getImageRights(slugs) {
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      skipBaseUrl: true,
      requestUrl: window.pickit.config.SERVICE_URL,
      body: JSON.stringify({ slugs }),
    };
    return this.request(`/contribution/search`, options).then(
      this.handleJSONResponse
    );
  }

  async createLicense(contributionId, license) {
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      skipBaseUrl: true,
      requestUrl: window.pickit.config.SERVICE_URL,
      body: JSON.stringify(license),
    };
    return this.request(`/contribution/${contributionId}/license`, options);
  }

  async getLicense(contributionId) {
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      requestUrl: window.pickit.config.SERVICE_URL,
      skipBaseUrl: true,
    };
    return this.request(
      `/contribution/${contributionId}/license`,
      options
    ).then(this.handleJSONResponse);
  }

  async removeLicenseFile(contributionId, fileId, type) {
    const options = {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
      requestUrl: window.pickit.config.SERVICE_URL,
      skipBaseUrl: true,
    };
    return this.request(
      `/contribution/${contributionId}/license/file/${fileId.replace(
        '/',
        '__'
      )}?type=${type}`,
      options
    );
  }
}

export default License;
