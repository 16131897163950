import * as yup from 'yup';
import React from 'react';
import Icon from './icon.svg?react';

export default [
  {
    moduleId: 'HEADER_IMAGE_TITLE',
    menuGroupIds: ['HEADER'],
    moduleInterface: {
      media_file: 'FILE',
      title: 'TITLE',
      text: 'TEXT',
      link: 'LINK',
    },
    status: {
      isEmpty: (data) => {
        return !data?.media_file?.fileId;
      },
    },
    moduleMetadataInterface: {
      titlePositionX: yup.mixed().oneOf(['left', 'center', 'right']),
      titlePositionY: yup.mixed().oneOf(['top', 'center', 'bottom']),
      withPadding: yup.boolean(),
      backgroundColor: yup.string(),
      textColor: yup.string(),
      textBackgroundGradient: yup.boolean(),
      titleSize: yup.mixed().oneOf(['small', 'medium', 'large']),
      textSize: yup.mixed().oneOf(['small', 'medium', 'large']),
      linkSize: yup.mixed().oneOf(['small', 'medium', 'large']),
      fileVariantId: yup.string(),
      imageDownloadable: yup.boolean(),
      autoScaleMedia: yup.boolean(),
    },
    menuContent: {
      key: 'HEADER_IMAGE_TITLE',
      icon: <Icon />,
      name: 'Text on image',
    },
  },
];
