import './config';
import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.scss';
import { analytics } from 'services';
import { inTeams } from 'utils/platform';
import { loadScript } from 'utils';
import Root from 'Root';
import localStorageWrapper from 'utils/localStorageWrapper';

const polyfills = [];
if (!window.fetch) {
  polyfills.push(
    import(/* webpackChunkName: "polyfill-fetch" */ './utils/polyfills/fetch'),
  );
}

if (window.location?.href?.includes('?embed')) {
  window.addEventListener('hashchange', () => {
    window?.parent?.postMessage(
      JSON.stringify({
        key: 'REDIRECT',
        value: window.location.hash,
      }),
      '*',
    );
  });
}

async function runIfOffice() {
  if (window.Office) {
    window.Office.initialize = function () {};
    const info = await window.Office.onReady();
    console.info(`Office.js is now ready in ${info.host} on ${info.platform}`);
    window.pickit.config.host = info.host;
    window.pickit.config.platform = info.platform;
    render();
  } else {
    setTimeout(runIfOffice, 200);
  }
}

async function runApp() {
  if (inTeams()) {
    await loadScript(
      'https://res.cdn.office.net/teams-js/2.23.0/js/MicrosoftTeams.min.js',
    );
    window.microsoftTeams?.initialize?.();
  }
  if (
    window.location.href.includes('_host_Info') ||
    !!Array.from(window.location?.ancestorOrigins || []).find((str) =>
      str.includes('officeapps'),
    ) ||
    localStorageWrapper.getItem('office') === 'true'
  ) {
    localStorageWrapper.setItem('office', 'true');
    loadScript('https://appsforoffice.microsoft.com/lib/1/hosted/Office.js');
    runIfOffice();
  } else {
    render();
  }
}

runApp();

function render() {
  const rootContainer = document.getElementById('root');
  const root = createRoot(rootContainer as HTMLElement);
  root.render(
    <React.Suspense fallback={<></>}>
      <Root />
    </React.Suspense>,
  );
  analytics.trackEvent('Application Started');
}
