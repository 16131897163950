import {
  Alert,
  Anchor,
  Avatar,
  Badge,
  Box,
  Card,
  Collapse,
  ColorSwatch,
  Divider,
  Group,
  GroupProps,
  Image,
  Input,
  JsonInput,
  Loader,
  NavLink,
  Progress,
  Stack,
  TextInput,
  Textarea,
  ThemeIcon,
  Tooltip,
  UnstyledButton,
} from '@mantine/core';
import FeedbackForm from '@pixi/components/AssetQuickForms/FeedbackForm';
import VariantForm from '@pixi/components/AssetQuickForms/VariantForm';
import PixiButton from '@pixi/elements/Button';
import PixiConfirm from '@pixi/elements/Confirm';
import PixiDropdown from '@pixi/elements/Dropdown';
import PixiIcon from '@pixi/elements/Icon';
import PixiText from '@pixi/elements/Text';
import { formatDuration } from '@pixi/helpers/utils';
import { DjangoIdToName, useDjangoIdToEmail } from 'components/DjangoIdToName';
import { format, formatDistanceToNow } from 'date-fns';
import { useUserContext } from 'hooks';
import { useEffect, useState } from 'react';
import {
  bytesToSize,
  copyTextToClipboard,
  openInNewTab,
  truncate,
} from 'utils';
import { useAssetEditPanel } from '../context';
import { Detail, SectionCollapse } from '../elements';
import { useAssetGridContext } from '@pixi/components/AssetGrid/AssetGridContext';
import PixiForm from '@pixi/elements/Form';
import { AssetThumbnail } from '@pixi/components/AssetThumbnail';
import { TransparentPattern } from '@pixi/elements/TransparentPattern';
import {
  createAppToast,
  openCollectionPreview,
  openFileSettingsPopup,
} from '@pixi/AppController';
import PixiCollapse from '@pixi/elements/Collapse';
import PixiTooltip from '@pixi/elements/Tooltip';
import AssetEditPanelUsage from './Sections/Usage';
import AssetEditPanelApprovals from './Sections/Approvals';
import AssetEditPanelFeedback from './Sections/Feedback';
import AssetEditPanelMetadata from './Sections/Metadata';
import AssetEditPanelVariants from './Sections/Variants';
import AssetEditPanelSlides from './Sections/Slides';
import { fileSourceToText } from '../../../../../../@common/functions/fileSourceToText';

export default function AssetEditPanelOverview() {
  const {
    file,
    files,
    fileToolbar,
    scanMetadata,
    scanMetadataProgress,
    context,
    disabledSections,
    focus,
  } = useAssetEditPanel();
  const AssetGrid = useAssetGridContext();
  const User = useUserContext();
  const [approvalComment, setApprovalComment] = useState<string>(
    file?.approval?.message || '',
  );
  const videoInfo = file?.file?.media_details?.video?.streams?.find(
    (s) => s.codec_type === 'video',
  );

  useEffect(() => {
    setApprovalComment(file.approval?.message || '');
  }, [file?.approval?.message]);

  const feedbackCollapse = <AssetEditPanelFeedback />;
  const approvalsCollapse = <AssetEditPanelApprovals />;

  const uploadNewVersionAction = fileToolbar.toolbarFlattened?.find(
    (a) => a.id === 'upload_new_version',
  );

  return (
    <>
      {focus === 'feedback' && feedbackCollapse}
      {(focus === 'approvals' ||
        (file?.approval?.status &&
          file?.approval?.status !== 'approved' &&
          file?.approval?.status !== 'rejected')) &&
        approvalsCollapse}
      <SectionCollapse
        label="File details"
        defaultOpen={focus !== 'feedback' && focus !== 'approvals'}
      >
        <Stack>
          <Detail
            left="Name"
            right={truncate(file?.name, 30, '...')}
            tooltip={file?.name}
            actions={[
              {
                label: 'edit',
                icon: 'pen',
                wrapper: (target) => (
                  <PixiForm<{ name: string }>
                    type="dropdown"
                    position="bottom-end"
                    title="Change name"
                    onSubmit={async (data) => {
                      await context.savePartial({
                        _id: file._id,
                        name: data.name,
                      });
                    }}
                    form={{
                      name: {
                        key: 'name',
                        value: file.name || '',
                        render: ({ value, setValue }) => (
                          <TextInput
                            value={value}
                            onChange={(event) => {
                              setValue(event.currentTarget.value);
                            }}
                            autoFocus
                            onFocus={(event) => event.currentTarget?.select()}
                          />
                        ),
                      },
                    }}
                    target={target}
                  />
                ),
              },
              {
                label: 'Copy to clipboard',
                icon: 'copy',
                tooltip: 'Copy to clipboard',
                onClick: () => {
                  copyTextToClipboard(file?.name);
                  createAppToast({
                    message: 'File name copied to clipboard',
                    id: file?._id as string,
                  });
                },
              },
            ]}
          />
          {!disabledSections?.includes('file') && (
            <>
              <Detail left="File Type" right={file?.file?.ext?.toUpperCase()} />
              <Detail
                left="Size"
                right={bytesToSize(file?.file?.contentLength)}
              />
            </>
          )}

          <PixiCollapse
            target={(isOpen) => (
              <PixiButton size="xs" color="dark" variant="subtle">
                Show {!isOpen ? 'more' : 'less'}
              </PixiButton>
            )}
            disabled={disabledSections?.includes('file')}
            reversed
          >
            <Stack>
              {!disabledSections?.includes('file') && (
                <Detail
                  left="Source"
                  right={fileSourceToText(file?.import?.from || '')}
                />
              )}
              <Detail
                left={
                  disabledSections?.includes('uploaded')
                    ? 'Created'
                    : 'Uploaded'
                }
                right={`${formatDistanceToNow(new Date(file?.createdAt))} ago`}
                tooltip={format(new Date(file?.createdAt), 'yyyy-MM-dd HH:mm')}
              />
              <Detail
                left={
                  disabledSections?.includes('uploaded')
                    ? 'Created by'
                    : 'Uploaded by'
                }
                right={
                  <>
                    <DjangoIdToName
                      value={file?.createdBy || file?.file?.uploaded_by}
                    />
                    {/* <Avatar
                    size={20}
                    radius="xs"
                    src={DjangoIdToImage({ value: file?.createdBy })}
                  /> */}
                  </>
                }
                tooltip={
                  <DjangoIdToName
                    value={file?.createdBy || file?.file?.uploaded_by}
                  />
                }
              />
            </Stack>
          </PixiCollapse>
        </Stack>
      </SectionCollapse>
      {file?.file?.image_details &&
        file?.file?.ext !== 'pdf' &&
        !file?.file?.contentType?.includes('video') && (
          <SectionCollapse label="Image details">
            <Stack>
              <Detail
                left="Resolution"
                right={`${file?.file?.image_details?.width}x${file?.file?.image_details?.height}`}
              />
              <Detail
                left="Orientation"
                rightProps={{ tt: 'capitalize' }}
                right={
                  <>
                    {file?.file?.image_details?.orientation}
                    <ThemeIcon variant="transparent" size="sm" color="gray">
                      <PixiIcon
                        size="xs"
                        name={
                          file?.file?.image_details?.orientation === 'landscape'
                            ? 'arrows-left-right'
                            : 'arrows-up-down'
                        }
                      />
                    </ThemeIcon>
                  </>
                }
              />
              <Detail
                left="Thumbnails"
                right={
                  <>{file?.file?.previews?.map((p) => p.name).join(', ')}</>
                }
              />
              <Detail
                left="Palette"
                right={
                  <Group align="center" gap="5" key={file._id}>
                    {Object.values(
                      file?.file?.image_details?.palette || {},
                    ).map((hex) => {
                      return <ColorSwatch key={hex} color={hex} size={12} />;
                    })}
                  </Group>
                }
              />
              {/* {file?.file?.exiftool?.DateCreated && (
                <Detail
                  left="Picture taken"
                  right={format(
                    new Date(file?.file?.exiftool?.DateCreated),
                    'yyyy-MM-dd HH:mm',
                  )}
                />
              )} */}
            </Stack>
          </SectionCollapse>
        )}
      {file?.file?.contentType?.includes('video') ? (
        <>
          <SectionCollapse label="Video details">
            {videoInfo ? (
              <Stack>
                <Detail
                  left="Codec"
                  tooltip={videoInfo.codec_long_name}
                  rightProps={{
                    ff: 'mono',
                  }}
                  right={videoInfo?.codec_name?.toUpperCase()}
                />
                {typeof videoInfo.duration === 'number' && (
                  <Detail
                    left="Duration"
                    rightProps={{
                      ff: 'mono',
                    }}
                    right={<>{formatDuration(videoInfo.duration)}</>}
                  />
                )}
                <Detail
                  left="Resolution"
                  right={`${videoInfo.width}x${videoInfo.height}`}
                  rightProps={{
                    ff: 'mono',
                  }}
                />
                {file?.file?.media_details?.video?.format?.tags
                  ?.creation_time && (
                  <Detail
                    left="Recorded"
                    right={format(
                      new Date(
                        file?.file?.media_details?.video?.format?.tags?.creation_time,
                      ),
                      'yyyy-MM-dd HH:mm',
                    )}
                  />
                )}
              </Stack>
            ) : (
              <>
                <Alert color="primary">
                  <Stack gap={5}>
                    <PixiButton
                      fullWidth
                      size="xs"
                      leftSection={<PixiIcon name="rotate" variant="filled" />}
                      onClick={async () => {
                        await scanMetadata();
                      }}
                    >
                      Scan details
                    </PixiButton>
                    {!!scanMetadataProgress?.percentage && (
                      <Progress
                        value={scanMetadataProgress?.percentage}
                        radius="xs"
                        animated
                      />
                    )}
                  </Stack>
                </Alert>
              </>
            )}
          </SectionCollapse>
        </>
      ) : (
        <></>
      )}
      {!disabledSections?.includes('thumbnail') && (
        <SectionCollapse label="Thumbnail">
          <Box
            style={{ aspectRatio: '4/3', overflow: 'hidden' }}
            pos="relative"
          >
            {!!file?.file?.previews?.length && (
              <TransparentPattern color="gray.2" />
            )}
            <AssetThumbnail
              size="small"
              file={file}
              h="100%"
              w="100%"
              style={() => ({
                objectFit: 'contain',
                position: 'relative',
                zIndex: 2,
              })}
            />
          </Box>
          <PixiButton
            variant="light"
            size="xs"
            onClick={() => {
              openFileSettingsPopup(file, {
                defaultTab: 'thumbnail',
              });
            }}
          >
            Edit
          </PixiButton>
        </SectionCollapse>
      )}
      <AssetEditPanelVariants />
      {!disabledSections?.includes('version') && (
        <SectionCollapse label="Version">
          <Stack>
            <Detail left="Version" right={file?.file?.version || 1} />
            {file?.file?.uploaded_at && (
              <Detail
                left="Updated"
                tooltip={format(file?.file?.uploaded_at, 'yyyy-MM-dd HH:mm')}
                right={`${formatDistanceToNow(file?.file?.uploaded_at)} ago`}
              />
            )}
            {file?.file?.uploaded_by && (
              <Detail
                left="Updated by"
                right={<DjangoIdToName value={file?.file?.uploaded_by} />}
              />
            )}
          </Stack>
          {uploadNewVersionAction ? (
            uploadNewVersionAction.customRender?.(
              <PixiButton variant="light" size="xs">
                {uploadNewVersionAction.label}
              </PixiButton>,
              {
                setIsFreezeDropdown: () => {},
                isOpen: true,
                onClose: () => {},
              },
            )
          ) : (
            <></>
          )}
        </SectionCollapse>
      )}
      <AssetEditPanelSlides />
      <AssetEditPanelMetadata />
      {focus !== 'feedback' &&
        !disabledSections?.includes('feedback') &&
        feedbackCollapse}
      {!disabledSections?.includes('usage') && (
        <SectionCollapse label={<>Usage</>}>
          <AssetEditPanelUsage />
        </SectionCollapse>
      )}
      {/* <SectionCollapse label="Usage" >
        <Usage />
      </SectionCollapse> */}
    </>
  );
}
