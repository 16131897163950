import React from 'react';
import { Flex, Icon, Tooltip } from 'elements_v2';
import FileThumbnail, { FileThumbnailV2 } from 'components/FileThumbnail';
import { inOffice } from 'utils/platform';
import Loader from 'elements_v2/Loader';
import { truncate } from 'utils';
import { differenceInHours, isBefore } from 'date-fns';
import {
  CategoryToIcon,
  CategoryToLabel,
} from 'views/_Manage/BrandAssets/helpers/Categories';
import { useTextContext, useUserContext } from 'hooks';
import useUserStatus from 'hooks/useUserStatus';

export default function Thumbnail(props) {
  const [thumbnail, setThumbnail] = React.useState(null);
  const [icon, setIcon] = React.useState(null);
  const Text = useTextContext();
  const UserStatus = useUserStatus();
  const User = useUserContext();

  let thumb = (
    <img alt="" style={props.style} src={props.photo.src || thumbnail} />
  );
  let forceThumb = null;

  if (props?.file?.contentType?.includes('audio')) {
    thumb = (
      <div className="ContributionsGridV2__Thumbnail__icon">
        <Icon name="MusicNote" />
      </div>
    );
  }

  let isIconThumb =
    !props.file?.previews?.length &&
    props.file?.contentType !== 'image/png' &&
    props.file?.contentType !== 'image/jpeg' &&
    !thumbnail;

  if (
    !props.data?.preferences?.show_preview_as_thumbnail &&
    props.data?.type === 'documents'
  ) {
    isIconThumb = true;
  }

  if (props.data?.data?.type === 'font-family') {
    forceThumb = (
      <div className="ContributionsGridV2__Thumbnail__icon">
        <Icon name="Type" style={{ marginBottom: 5 }} />
        {props.data?.name}
      </div>
    );
  }

  if (!props.data?.file?.id && props.data?.data?.type === 'color') {
    return (
      <div
        className={`ContributionsGridV2__Thumbnail ${
          props.static && 'ContributionsGridV2__Thumbnail--static'
        }`}
      >
        <div
          className="ContributionsGridV2__Thumbnail__color"
          style={{ background: props.data?.data?.value?.color }}
        />
      </div>
    );
  }
  if (!props.data?.file?.id && props.data?.data?.type === 'text') {
    forceThumb = (
      <div className="ContributionsGridV2__Thumbnail__icon">
        <Icon name="justify-left" style={{ marginBottom: 5 }} />
        Text
      </div>
    );
  }

  if (isIconThumb && icon) {
    thumb = (
      <div className="ContributionsGridV2__Thumbnail__icon">
        <img alt="icon" src={icon} />
      </div>
    );
  }

  let fileTypeLabel = '';

  if (
    props.file?.ext !== 'jpg' &&
    props.file?.ext !== 'png' &&
    props.file?.ext !== 'gif' &&
    props.file?.ext !== 'jpeg'
  ) {
    fileTypeLabel = props?.file?.ext || props?.file?.name?.split('.')?.pop();
  }
  if (props?.file?.ext === 'pptxslide') {
    fileTypeLabel = 'PowerPoint Slide';

    if (inOffice()) {
      fileTypeLabel = 'Slide';
    }
  }
  const isContributorUser =
    props.context?.isContributorUser(
      User.djangoProfile?.id,
      User.djangoProfile?.email
    ) && props.data.file.uploaded_by === User.djangoProfile?.id;
  const showQuickApprovalsStatus = ['rejected', 'none'];
  if (isContributorUser) {
    showQuickApprovalsStatus.push('approved');
  }

  // const processing = Object.values(props.data?.processing || {}).filter(
  //   (val) => val?.isProcessing
  // );
  return (
    <div
      data-ext={props.data?.file?.ext}
      className={`ContributionsGridV2__Thumbnail ${
        props.prefs?.cover ? 'ContributionsGridV2__Thumbnail--cover' : ''
      } ${props.static && 'ContributionsGridV2__Thumbnail--static'}`}
    >
      {props.isLoading && (
        <div className="ContributionsGridV2__Thumbnail__loader">
          {props.customLoader || <Loader white>{props.loadingMessage}</Loader>}
        </div>
      )}
      {props.injectThumbnail}
      {/* {!!processing.length && (
        <div className="ContributionsGridV2__Thumbnail__processing">
          {processing?.[0]?.status?.message}
        </div>
      )} */}
      {props.data?.processing?.thumbnail?.isProcessing ? (
        <>
          <div className="ContributionsGridV2__Thumbnail__icon">
            <Loader style={{ padding: 0 }}>
              Processing{' '}
              <strong>
                {truncate(
                  decodeURIComponent(props.data?.file?.name)?.split('.')?.[0],
                  10
                )}
                .{props.data?.file?.ext}
              </strong>
            </Loader>
          </div>
        </>
      ) : (
        <>
          {forceThumb ||
            (props.file && props.context ? (
              <FileThumbnailV2
                file={props.file}
                forceThumbnailUrl={props.forceThumbnailUrl}
                prefs={props.prefs}
                noVideo
                noAudio
                context={props.context}
                preferSize={props.preferSize}
              />
            ) : (
              <>{thumb}</>
            ))}
          {!!fileTypeLabel && props.context?.type !== 'brandassets' && (
            <div className="ContributionsGridV2__Thumbnail__label brand__color__bg">
              {fileTypeLabel}
            </div>
          )}
          <Flex className="ContributionsGridV2__Thumbnail__status__Wrapper">
            {props.data?.license?.expirationDate &&
              isBefore(
                new Date(props.data?.license?.expirationDate),
                new Date()
              ) && (
                <div className="ContributionsGridV2__Thumbnail__status brand__color__bg">
                  <Tooltip message={Text.get('ui/license_has_expired')}>
                    <Icon name="ExclamationTriangleFill" />
                  </Tooltip>
                </div>
              )}
            {props.data?.file?.external_file && (
              <div className="ContributionsGridV2__Thumbnail__status brand__color__bg">
                <Tooltip message="External file">
                  <Icon name="Link" />
                </Tooltip>
              </div>
            )}
            {(UserStatus?.product?.isBusinessAdmin || isContributorUser) &&
              UserStatus?.activeServices?.approval &&
              showQuickApprovalsStatus.includes(
                props.data?.approval?.status
              ) && (
                <div className="ContributionsGridV2__Thumbnail__status brand__color__bg">
                  <Tooltip
                    message={
                      props.data?.approval?.status === 'rejected'
                        ? 'Rejected'
                        : props.data?.approval?.status === 'approved'
                        ? 'Approved'
                        : 'Needs approval'
                    }
                  >
                    <Icon
                      name={
                        props.data?.approval?.status === 'rejected'
                          ? 'clipboard-x'
                          : props.data?.approval?.status === 'approved'
                          ? 'clipboard-check'
                          : 'clipboard-minus'
                      }
                    />
                  </Tooltip>
                </div>
              )}
          </Flex>
          {props.context?.type === 'brandassets' && (
            <div className="ContributionsGridV2__Thumbnail__category">
              <Tooltip message={CategoryToLabel(props.data?.categories?.[0])}>
                <Icon name={CategoryToIcon(props.data?.categories?.[0])} />
                {CategoryToLabel(props.data?.categories?.[0])}
              </Tooltip>
            </div>
          )}
        </>
      )}
    </div>
  );
}
