import React from 'react';
import { Button, Icon, Tooltip } from 'elements_v2';
import './style.scss';
import { Box } from '@mantine/core';

function MainNav(props) {
  return (
    <Box
      className={`Pixi Pixi__MainNav ${props.white && 'Pixi__MainNav--white'}`}
    >
      {props.children}
    </Box>
  );
}

function Item(props) {
  const className = ['Pixi', 'Pixi__MainNav__item'];

  if (props.active) {
    className.push('Pixi__MainNav__item--active');
    className.push('brand__color__text');
  }
  if (!props.disabled) {
    className.push('Pixi--clickable');
  }
  if (props.disabled) {
    className.push('Pixi__MainNav__item--disabled');
  }
  if (props.className) {
    className.push(props.className);
  }

  return (
    <div className={className.join(' ')}>
      <Tooltip delay={0.5} message={props.name}>
        <Button
          href={props.href}
          transparent
          target={props.target}
          onClick={props.onClick}
          ref={props.ref}
        >
          <div className="Pixi__MainNav__item__icon">
            {props.customIcon ? props.icon : <Icon name={props.icon} />}
          </div>
          <span className="Pixi__MainNav__item__name">{props.name}</span>
          {props.caret && (
            <div className="Pixi__MainNav__item__caret">
              <Icon name="CaretDownFill" />
            </div>
          )}
          {props.notification && (
            <Tooltip
              className="Pixi__MainNav__item__notification"
              message={props.notification}
            >
              <div className="Pixi__MainNav__item__notification__box brand__color__bg" />
            </Tooltip>
          )}
        </Button>
      </Tooltip>
    </div>
  );
}

MainNav.Item = Item;

export default MainNav;
