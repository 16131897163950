import { useMediaContext } from 'hooks/useMediaContext';
import React from 'react';

export default function useColor() {
  const Media = useMediaContext();
  return {
    getColor: (colorId) => {
      return Media.data.files.find((f) => f._id === colorId)?.data?.value
        ?.color;
    },
  };
}
