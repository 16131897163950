import React, { useEffect } from 'react';
import { useCustomerContext, useUserContext, useDateFns } from 'hooks';
import { differenceInCalendarDays } from 'date-fns';
import Analytics from 'services/analytics';
import { insert } from 'services';
import useCloudStorage from 'hooks/useCloudStorage';
import useUserTextFields from 'hooks/useUserTextFields';
import localStorageWrapper from 'utils/localStorageWrapper';
import { loadScript } from 'utils';
import { PixiIconName } from '@pixi/elements/Icon';
import { UserStatusContext, UserStatusInterface } from 'contexts/UserStatus';

let stripIsLoading = false;

interface UserStatusProviderProps {
  children: React.ReactNode;
}

export function UserStatusProvider(props: UserStatusProviderProps) {
  const defaultUserStatus: Partial<UserStatusInterface> = {
    show: {
      demo: false,
      public_images: false,
      changePassword: true,
    },
    hide: {
      company_images: false,
    },
    product: {
      isActive: false,
      isEnded: false,
      hasProAndBusiness: false,
      isCanceled: false,
      isManagedByPickit: false,
      isPayingStripe: false,
      isSelfServiced: false,
      isLimited: false,

      isTrial: false,
      isTrialEnded: false,
      trialDaysLeft: null,
      /* These are used only when type is set to business */
      isBusinessOwner: false,
      isBusinessAdmin: false,
      isBusinessStripe: false,
      isBusinessITadmin: false,
      isExternalUser: false,

      // Invitation flow
      isInvitationPendingAcceptance: false,
    },
    permissions: [],
    isActive: false,
    products: [],
  };
  const [isReady, setIsReady] = React.useState(false);
  const [userStatus, setUserStatus] = React.useReducer(
    (
      state: Partial<UserStatusInterface>,
      action: Partial<UserStatusInterface>,
    ) => {
      return { ...action };
    },
    defaultUserStatus,
  );
  const cloudStorage = useCloudStorage();
  const textFields = useUserTextFields();

  const Customer: any = useCustomerContext();
  const User = useUserContext();
  const { format } = useDateFns();

  const nullProductType = () => {
    const status = {
      ...userStatus,
      product: {
        ...userStatus.product,
        type: undefined,
        isBusinessAdmin: false,
        subscriptionDaysLeft: Customer.settings.NEXT_EVENT_DAYS,
        isPayingStripe: true,
        isCanceled:
          Customer.settings.STATUS === 'canceled' ||
          Customer.settings.STATUS === 'pending_cancelation',
      },
      show: {
        ...userStatus.show,
        public_images: false,
      },
      hide: {
        company_images:
          cloudStorage.get('onboarding.explore.hideCompanyImagesTab') || false,
      },
    };
    setUserStatus(status as Partial<UserStatusInterface>);
  };

  const inactivateBusiness = () => {
    const status = {
      ...userStatus,
      product: {
        ...userStatus.product,
        type: 'pro',
        isBusinessAdmin: false,
        subscriptionDaysLeft: Customer.settings.NEXT_EVENT_DAYS,
        isPayingStripe: true,
        isCanceled:
          Customer.settings.STATUS === 'canceled' ||
          Customer.settings.STATUS === 'pending_cancelation',
      },
      show: {
        ...userStatus.show,
        public_images: true,
      },
      hide: {
        company_images:
          cloudStorage.get('onboarding.explore.hideCompanyImagesTab') || false,
      },
    };
    setUserStatus(status as Partial<UserStatusInterface>);
  };
  const activateBusiness = () => {
    const status: Partial<UserStatusInterface> = {
      ...userStatus,
      product: {
        ...userStatus.product,
        type: 'business',
        isBusinessAdmin: true,
      },
      show: {
        ...(userStatus.show as UserStatusInterface['show']),
        public_images: true,
      },
      hide: {
        company_images:
          cloudStorage.get('onboarding.explore.hideCompanyImagesTab') || false,
      },
    };
    setUserStatus(status as Partial<UserStatusInterface>);
  };

  const activateProduct = (product: UserStatusInterface['product']) => {
    setUserStatus({
      ...userStatus,
      product,
    });
  };

  const activatePro = () => {
    const status = {
      ...userStatus,
      product: {
        ...getProduct('pro'),
      },
      show: {
        ...userStatus.show,
        public_images: true,
      },
      hide: {
        company_images:
          cloudStorage.get('onboarding.explore.hideCompanyImagesTab') || false,
      },
    };
    setUserStatus(status as Partial<UserStatusInterface>);
  };
  function getProduct(
    type: string,
    data?: any,
  ): UserStatusInterface['product'] {
    const spaces = User?.spaces || {};
    const product: Partial<UserStatusInterface['product']> = {};

    if (type === 'business') {
      product.type = 'business';
      const permissions =
        spaces?.selected?.groups?.map((group: string) => group.split('_')[1]) ||
        [];
      /* Check if the business is setup by Pickit as a partner. Either by self-service or by Portal */
      if (
        !data?.customer ||
        (data?.customer?.has_self_service &&
          (data.customer.self_service_market === 'add-in' ||
            data.customer.self_service_market === 'manage-ui'))
      ) {
        product.isSelfServiced = true;
        product.isManagedByPickit = true;
      }

      if (data?.customer?.status === 'nfr') {
        product.isNFR = true;
      }
      if (data?.customer?.status) {
        product.customerStatus = data?.customer?.status;
      }

      /* If selected image bank has demo disabled and
        is not a demo image bank user has Business Product activated */
      if (data.is_demo_disabled && !data.is_demo) {
        product.type = 'business';
        product.isActive = data.active;
      }
      if (data.is_demo_disabled) {
        product.isTrial = false;
      } else {
        product.type = 'pro';
        product.isTrial = true;
        product.trialDaysLeft = differenceInCalendarDays(
          new Date(data.subscription.end_date),
          new Date(),
        );
      }

      /* Check if user is owner of the image bank */
      if (product.type === 'business' && permissions.includes('owner')) {
        product.isBusinessOwner = true;

        product.isBusinessAdmin = true;
      }

      /* Check if user is IT-admin of the image bank */
      if (product.type === 'business' && permissions.includes('it')) {
        product.isBusinessOwner = false;

        product.isBusinessITadmin = true;
      }

      /* Users who are in the "creator" permission should have manage access */
      if (product.type === 'business' && permissions.includes('creator')) {
        product.isBusinessAdmin = true;
      }

      /* Users who are an external user must have no access to anything except collections */
      if (
        product.type === 'business' &&
        permissions.includes('limbo') &&
        !permissions.includes('user')
      ) {
        product.isExternalUser = true;
      }

      /* If business is activated and trial is true, the user has a business trial */
      if (product.type === 'business' && data.subscription?.trial) {
        product.isTrial = true;
        product.trialDaysLeft = differenceInCalendarDays(
          new Date(data.subscription.end_date),
          new Date(),
        );
      }

      if (
        Customer.settings.ACTIVE &&
        Customer.settings.ACCOUNT_TYPE === 'business'
      ) {
        product.type = 'business';
        product.isActive =
          Customer.settings.ACTIVE && Customer.settings.STATUS !== 'past_due';
        product.isPayingStripe =
          Customer.settings.STATUS !== 'pending_cancelation';
        product.isTrial = false;
        product.trialDaysLeft = 0;
        product.isCanceled =
          Customer.settings.STATUS === 'canceled' ||
          Customer.settings.STATUS === 'pending_cancelation';
        product.subscriptionDaysLeft = Customer.settings.NEXT_EVENT_DAYS;
        product.subscriptionRenewsAt = format(
          new Date(Customer.settings.NEXT_EVENT_EPOCH),
          'yyyy-MM-dd',
        );
        product.isSelfServiced = true;
      }
    }

    if (type === 'pro' && Customer.settings.ACTIVE) {
      product.type = 'pro';
      product.isActive =
        Customer.settings.ACTIVE && Customer.settings.STATUS !== 'past_due';
      product.isPayingStripe =
        Customer.settings.STATUS !== 'pending_cancelation';
      product.isTrial = false;
      product.isCanceled =
        Customer.settings.STATUS === 'canceled' ||
        Customer.settings.STATUS === 'pending_cancelation';
      product.subscriptionDaysLeft = Customer.settings.NEXT_EVENT_DAYS;
      product.subscriptionRenewsAt = format(
        new Date(Customer.settings.NEXT_EVENT_EPOCH),
        'yyyy-MM-dd',
      );
      product.isSelfServiced = true;
      product.isBusinessOwner = false;

      product.isBusinessAdmin = false;
    }

    product.hasProAndBusiness =
      Customer.settings.PRO_AND_BUSINESS ||
      /* (Customer.settings.ACCOUNT_TYPE === 'pro' && !!spaces?.selected) ||*/
      (Customer.settings.ACCOUNT_TYPE === 'pro' &&
        spaces?.activeCompanies?.length > 0);

    return { ...product, data };
  }

  function setStatus() {
    let userStatus = defaultUserStatus;
    let notSelected = false;

    const spaces = User?.spaces || {};

    let { permissions, product } = userStatus;
    const { show, hide } = userStatus as UserStatusInterface;
    permissions =
      spaces?.selected?.groups?.map((group: string) => group.split('_')[1]) ||
      [];
    let isActive =
      User.djangoProfile?.active !== false &&
      spaces?.selected?.active !== false;
    const products = [];

    if (!product) {
      product = {};
    }

    if (spaces?.selected?.is_demo_disabled) {
      product = getProduct('business', spaces?.selected);
    }

    if (spaces.activeCompanies?.length) {
      spaces.activeCompanies.forEach((company: any) => {
        products.push(getProduct('business', company));
      });
    }

    if (User.djangoProfile?.active) {
      product.isActive = true;
    }

    /* If business is not activated but user still has trial on that space, the user is on a trial for pro */
    if (product.type !== 'business' && spaces?.selected?.subscription?.trial) {
      product.type = 'pro';
      product.isTrial = true;
      product.trialDaysLeft = differenceInCalendarDays(
        new Date(spaces?.selected?.subscription.end_date),
        new Date(),
      );
    }

    /* User has a demo space and is not a business account */
    if (product.type !== 'business') {
      show.demo = true;
    }

    /* If demo is visible, public images should be as well */
    if (show.demo) {
      show.public_images = true;
      hide.company_images = false;
    }

    if (User.djangoProfile?.sso_only) {
      show.changePassword = false;
    }

    /* If product is business we need to check if public images is enabled */
    if (
      (product.type === 'business' && permissions?.includes('market')) ||
      (product.type === 'business' &&
        spaces?.selected?.pickit_market_access === 'admin' &&
        (permissions?.includes('owner') || permissions?.includes('creator')))
    ) {
      show.public_images = true;
    }

    /* Check if user is owner of the image bank */
    if (product.type === 'business' && permissions?.includes('owner')) {
      product.isBusinessOwner = true;
      product.isBusinessAdmin = true;
    }

    /* Users who are in the "creator" permission should have manage access */
    if (product.type === 'business' && permissions?.includes('creator')) {
      product.isBusinessAdmin = true;
    }

    /* If business is activated and trial is true, the user has a business trial */
    if (
      product.type === 'business' &&
      spaces?.selected?.subscription?.trial &&
      !Customer.settings?.ACTIVE &&
      Customer.settings?.ACCOUNT_TYPE !== 'business'
    ) {
      product.isTrial = true;
      product.trialDaysLeft = differenceInCalendarDays(
        new Date(spaces?.selected?.subscription.end_date),
        new Date(),
      );
    }

    /* If business is not activated but user still has trial on that space, the user is on a trial for pro */
    if (product.type !== 'business' && spaces?.selected?.subscription?.trial) {
      product = getProduct('pro', spaces?.selected);
      product.type = 'pro';
      product.isTrial = true;
      product.isSelfServiced = true;
      product.trialDaysLeft = differenceInCalendarDays(
        new Date(spaces?.selected?.subscription.end_date),
        new Date(),
      );
      products.push(product);
    }

    product.hasProAndBusiness =
      Customer.settings.PRO_AND_BUSINESS ||
      /* (Customer.settings.ACCOUNT_TYPE === 'pro' && !!spaces?.selected) ||*/
      (Customer.settings.ACCOUNT_TYPE === 'pro' &&
        Customer.settings.ACTIVE &&
        spaces?.selected?.is_demo_disabled &&
        !spaces?.selected?.is_demo);

    if (
      Customer.settings?.ACTIVE &&
      Customer.settings?.ACCOUNT_TYPE === 'pro'
    ) {
      const subscription = getProduct('pro', spaces?.selected);
      products.push(subscription);
      isActive = true;
      if (product.type !== 'business') {
        product = subscription;
      } else {
        product.hasProAndBusiness = true;
      }
    }
    if (
      Customer.settings?.ENDED ||
      (User.spaces?.selected &&
        !User.spaces?.selected?.active &&
        product.type !== 'pro')
    ) {
      product.isActive = false;
      product.isEnded = true;
    }

    if (
      User.spaces?.selected?.customer?.status === 'trialactive' ||
      User.spaces?.selected?.customer?.status === 'active' ||
      User.spaces?.selected?.customer?.status === 'nfr' ||
      User.spaces?.selected?.customer?.status === 'free'
    ) {
      product.isActive = true;
      product.isEnded = false;
    }
    product.license_display_name =
      User.spaces?.selected?.customer?.license_display_name || 'business';

    if (
      (!spaces?.selected &&
        (User.djangoProfile?.reason_not_active === 'trial-ended' ||
          spaces?.tempCompany?.reason_not_active === 'trial-ended')) ||
      spaces?.selected?.subscription?.reason_not_active === 'trial-ended' ||
      spaces?.selected?.reason_not_active === 'trial-ended'
    ) {
      product.isTrialEnded = true;
    }

    if (
      ((product.isTrial && product.type === 'business') ||
        !spaces?.selected?.active) &&
      product.isBusinessOwner &&
      product.isManagedByPickit
    ) {
      product.isBusinessStripe = true;
    }

    if (product.isTrial && !product.trialDaysLeft) {
      product.isTrialEnded = true;
      product.isEnded = true;
    }

    if (product.isActive) {
      product.isEnded = false;
    }

    if (!product.type && products.length) {
      notSelected = true;
    }
    if (
      User?.spaces?.selected?.customer?.status?.toLowerCase() === 'starter' ||
      permissions?.includes('starter')
    ) {
      product.isStarter = true;
    }
    if (
      User.authenticated &&
      Customer.isSubscriptionReady &&
      !product.trialDaysLeft &&
      (!product.type ||
        (product.type !== 'business' && product.isTrial) ||
        User.forceLIV ||
        (product.isTrialEnded && !product.isActive) ||
        product.isEnded ||
        Customer.settings.STATUS === 'past_due' ||
        product.isStarter)
    ) {
      if (product.isTrial && !product.isTrialEnded) {
        User.setLIVType('ProTrial');
        User.setCloudStorage('LIV.accepted', true);
      } else if (
        product.isTrialEnded &&
        User.cloudStorage.LIV.type === 'ProTrial'
      ) {
        User.setLIVType('Individual');
        User.setCloudStorage('LIV.accepted', false);
      } else {
        User.setLIVType('Individual');
      }
      product.type = 'LIV';
      product.isLimited = true;
      product.isTrial = false;
      product.isBusinessStarter = permissions?.includes('starter');
    }
    if (spaces?.selected?.is_pending_to_owner) {
      product.pendingOwnership = true;
    }

    const selected = localStorageWrapper.getItem('SELECTED_ACCOUNT');
    if (selected) {
      if (
        selected === 'pro' &&
        Customer.subscriptions.find(
          (sub: any) => sub.productType === 'individual',
        )
      ) {
        product.type = 'pro';
        product.isBusinessAdmin = false;
        product.isBusinessOwner = false;
        product.subscriptionDaysLeft = Customer.settings.NEXT_EVENT_DAYS;
        product.isPayingStripe = true;
        product.isCanceled =
          Customer.settings.STATUS === 'canceled' ||
          Customer.settings.STATUS === 'pending_cancelation';
        product = getProduct('pro');
        product.hasProAndBusiness = products.length > 0;
        Customer.activatePro();
      } else if (
        Customer.subscriptions.find((sub: any) => sub.productType === 'team') ||
        (spaces?.selected?.is_demo_disabled && !spaces?.selected?.is_demo)
      ) {
        product.type = selected;
      }
    }

    // If invitations wait user's decision
    product.isInvitationPendingAcceptance =
      !!User?.djangoProfile?.invitations?.length;

    userStatus = {
      isActive,
      show,
      hide,
      product,
      permissions,
      products,
      notSelected,
    };

    const newUserStatus = User?.data?.userStatus;

    if (
      userStatus.product?.isTrial &&
      userStatus?.product?.type === 'pro' &&
      (newUserStatus?.contributor?.isContributor ||
        newUserStatus?.external?.isExternal)
    ) {
      userStatus.product.isTrial = false;
      userStatus.product.type = 'external';
      userStatus.product.trialDaysLeft = -1;
    }

    Analytics.setUserStatus(userStatus);
    insert.setUserStatus(userStatus);

    setUserStatus(userStatus);
    setIsReady(true);
  }

  useEffect(() => {
    if (isReady) {
      if (User.authenticated && !stripIsLoading) {
        loadScript('https://js.stripe.com/v3/');
        stripIsLoading = true;
      }
    }
  }, [isReady, User.authenticated]);

  React.useEffect(() => {
    setStatus();
  }, [
    Customer.settings,
    User.djangoProfile,
    User.authenticated,
    User.initialized,
    User.spaces?.selected,
    Customer.subscription.productType,
    Customer.subscription.status,
    Customer.isSubscriptionReady,
    User.forceLIV,
    ...Object.values(Customer.settings),
  ]);

  const policies = userStatus?.product?.data?.policy || {};

  const activeFeatures = {
    audio_video: policies?.access?.features?.audio_video !== false,
    external_sharing: policies?.access?.features?.external_sharing !== false,
    custom_branding: policies?.access?.features?.custom_branding !== false,
    it_admin: policies?.access?.features?.it_admin !== false,
    api_connections: policies?.access?.features?.api_connections !== false,
    aad_profile: policies?.access?.features?.aad_profile,
    anonymous_boards: policies?.access?.features?.anonymous_boards,
    custom_email_templates: policies?.access?.features?.custom_email_templates,
    ocr_and_fulltext_search:
      policies?.access?.features?.ocr_and_fulltext_search,
    attributes: policies?.access?.features?.attributes || false,
    attributes_ai: policies?.access?.features?.attributes_ai || false,
    pickit_vision_ai: policies?.access?.features?.pickit_vision_ai || false,
    custom_boards_overview:
      policies?.access?.features?.custom_boards_overview || false,
    user_activity_insights:
      policies?.access?.features?.user_activity_insights || false,
    disable_pickit_board_promotion:
      policies?.access?.features?.disable_pickit_board_promotion || false,
    inspection_tool: policies?.access?.features?.inspection_tool || false,
    video_transcription:
      policies?.access?.features?.video_transcription || false,
  };

  const activeServices = {
    media: policies?.access?.services?.media !== false,
    documents: policies?.access?.services?.documents !== false,
    insights: policies?.access?.services?.insights !== false,
    image_rights: policies?.access?.services?.image_rights !== false,
    guidelines: policies?.access?.services?.guidelines !== false,
    boards: policies?.access?.services?.boards || false,
    family: policies?.access?.services?.family || false,
    approval: policies?.access?.services?.approval || false,
    brand_assets: policies?.access?.services?.brand_assets || false,
    assets: policies?.access?.services?.assets || false,
    license_management:
      policies?.access?.services?.license_management !== false,
  };

  const activeIntegrations: UserStatusInterface['activeIntegrations'] = {
    pickit: policies?.access?.integrations?.pickit !== false,
    dropbox: policies?.access?.integrations?.dropbox !== false,
    gettyimages: policies?.access?.integrations?.gettyimages !== false,
    box: policies?.access?.integrations?.box !== false,
    microsoft365_import:
      policies?.access?.integrations?.microsoft365_import !== false,
    microsoft365_export:
      policies?.access?.integrations?.microsoft365_export !== false,
    adobestock: policies?.access?.integrations?.adobestock !== false,
    shutterstock: policies?.access?.integrations?.shutterstock !== false,
    googledrive: policies?.access?.integrations?.googledrive !== false,
    hubspot_export: policies?.access?.integrations?.hubspot_export !== false,
    hubspot_import: policies?.access?.integrations?.hubspot_import !== false,
    adobeccl: policies?.access?.integrations?.adobeccl !== false,
    network_file_system:
      policies?.access?.integrations?.network_file_system !== false,
  };

  const onboardingScenario = policies?.metadata?.self_service_scenario || '';

  const isMediaMarketsActive =
    activeIntegrations.gettyimages ||
    activeIntegrations.adobestock ||
    activeIntegrations.shutterstock;
  const isOtherSourcesActive =
    activeIntegrations.dropbox ||
    activeIntegrations.box ||
    activeIntegrations.googledrive ||
    activeIntegrations.microsoft365_import ||
    activeIntegrations.hubspot_import ||
    activeIntegrations.network_file_system;

  userStatus.hide = {
    ...userStatus.hide,
    company_images: cloudStorage.get('onboarding.explore.hideCompanyImagesTab'),
  };

  const products: {
    id: string;
    active: boolean;
    defaultPage?: string;
    metadata: {
      name: string;
      icon: PixiIconName;
    };
  }[] = [
    {
      id: 'metadata',
      active: activeFeatures?.attributes,
      metadata: {
        name: 'Metadata',
        icon: 'code',
      },
    },
    {
      id: 'properties',
      active: activeFeatures?.attributes,
      metadata: {
        name: 'Properties',
        icon: 'filter-list',
      },
    },
    {
      id: 'approvals',
      active: activeServices?.approval,
      defaultPage: 'approval-needed',
      metadata: {
        name: 'Approvals',
        icon: 'badge-check',
      },
    },
    {
      id: 'license_management',
      active: activeServices?.license_management,
      metadata: {
        name: 'License Management',
        icon: 'shield-check',
      },
    },
    {
      id: 'insights',
      active: activeServices?.insights,
      metadata: {
        name: 'Insights & Statistics',
        icon: 'chart-simple',
      },
    },
    {
      id: 'feedback',
      active: true,
      metadata: {
        name: 'Feedback',
        icon: 'message',
      },
    },
    {
      id: 'inspection_tool',
      active: activeFeatures?.inspection_tool,
      metadata: {
        name: 'Inspection Tool',
        icon: 'binoculars',
      },
    },
  ];

  return (
    <UserStatusContext.Provider
      value={
        {
          ...userStatus,
          products,
          isReady,
          inactivateBusiness,
          activateBusiness,
          nullProductType,
          activatePro,
          activateProduct,
          activeFeatures,
          activeServices,
          activeIntegrations,
          isMediaMarketsActive,
          isOtherSourcesActive,
          policies,
          onboardingScenario,
          textFields,
        } as UserStatusInterface
      }
    >
      {props.children}
    </UserStatusContext.Provider>
  );
}
