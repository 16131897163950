import localStorageWrapper from 'utils/localStorageWrapper';

export default class Cache {
  static fromStorage = localStorageWrapper.getItem('_useCache');

  static data = Cache.fromStorage ? JSON.parse(Cache.fromStorage) : {};

  static stateData = {};

  static save() {
    localStorageWrapper.setItem('_useCache', JSON.stringify(Cache.data));
  }

  static setData(prefs) {
    let newData = {};
    if (prefs.stateOnly) {
      newData = { ...Cache.stateData };
    } else {
      newData = { ...Cache.data };
    }
    newData = {
      ...newData,
      [prefs.id]: {
        data: prefs.data,
        expires: prefs.expires || null,
      },
    };
    if (prefs.stateOnly) {
      Cache.stateData = newData;
    } else {
      Cache.data = newData;
      Cache.save();
    }
  }

  static getData(id) {
    const row = Cache.data[id] || Cache.stateData[id];
    if (!row) {
      return false;
    }
    if (!row.data) {
      return false;
    }
    if (row.expires && new Date().getTime() > new Date(row.expires).getTime()) {
      return false;
    }
    return Cache.data[id] || Cache.stateData[id];
  }
}
