import React, { useEffect, useRef, useState } from 'react';
import { Button, Icon, Loader, Progress, Tooltip } from 'elements_v2';
import { isNative } from 'utils/platform';
import { PinkToolbar } from 'elements_v2/PinkToolbar';
import useStorageUrl from 'hooks/files/useStorageUrl';
import { NativeDragWrapper } from 'views/Native';
import { useNative } from 'views/Native/context';
import { AssetToolbar } from '../../../@pixi/components/AssetActions';
import { useAssetActions } from '@pixi/components/AssetActions/useAssetActions';

const Image = (props) => {
  const { generateUrl } = useStorageUrl();
  const fileRef = useRef();
  const [ref, setRef] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isHover, setIsHover] = useState(false);
  const [isPreparing, setIsPreparing] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('');
  const { availability, api, primaryAction } = useAssetActions({
    file: props.data.photo.data,
    includeDownloadBag: props.includeDownloadBag,
    downloadWrapper: props.downloadWrapper,
  });
  const { updater: NativeEvent } = useNative();
  const NativeDownloadingEvent = NativeEvent.downloadingFile?.find(
    (f) => f.fileId === props.data?.photo?.data?._id,
  );

  useEffect(() => {
    setRef(fileRef);
  }, [fileRef?.current]);

  const className = ['Files__Image'];

  if (props.isSelected) {
    className.push('Files__Image--selected');
  }
  if (props.context.type === 'documents') {
    className.push('Files__Image--document');
  }
  let alreadyClicked = false;
  async function downloadFile() {
    if (isNative() && availability.copy) {
      setIsLoading(true);
      setLoadingMessage('Copying');
      await api.copyFile();
      setLoadingMessage('');
      setIsLoading(false);
    } else {
      primaryAction.onClick();
    }
  }

  async function handeClick(event) {
    if ((event.metaKey || event.ctrlKey) && props.setSelectedFiles) {
      props.setSelectedFiles(props.data.photo.data);
      return false;
    }
    if (!alreadyClicked) {
      alreadyClicked = true;
      setTimeout(() => {
        if (alreadyClicked) {
          if (props.altLink) {
            props.altLink(props.data.photo.data);
          } else if (props.onFileRedirect) {
            props.onFileRedirect(props.data.photo.data, '', event);
          }
        }
        alreadyClicked = false;
      }, 200);
    } else {
      alreadyClicked = false;
      downloadFile();
    }
  }
  let image = (
    <div
      className="Files__Image__content"
      onClick={handeClick}
      onMouseOver={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      <PinkToolbar
        parentRef={ref}
        overlay
        size="small"
        brand
        floating
        style={{
          top: props.detailed ? -1 : 6,
          right: props.detailed ? -1 : 6,
        }}
      >
        <AssetToolbar
          actions={[
            'download',
            'copy',
            'download_external',
            'open_external',
            'open_slides',
            'open_document',
            'insert',
            'insert_themed_slide',
            ...(props.includeDownloadBag ? ['add_to_bag'] : []),
          ]}
          onFileActionStart={() => setIsLoading(true)}
          onFileActionEnd={() => setIsLoading(false)}
          downloadWrapper={props.downloadWrapper}
          file={props.data.photo?.data}
          includeDownloadBag={props.includeDownloadBag}
          onPreview={(file, scenario) => props.onFileRedirect(file, scenario)}
        />
      </PinkToolbar>
      {props.image(null, {
        isLoading: !!NativeDownloadingEvent || props.isLoading || isLoading,
        loadingMessage,
        customLoader: NativeDownloadingEvent && (
          <div
            className="Files__Image__content__overlay no-before brand__color__bg__before"
            style={{
              background:
                NativeDownloadingEvent?.progress?.percent === 1
                  ? 'linear-gradient(177deg, #20c997 0%, #20c997 100%)'
                  : 'rgba(0, 0, 0, 0.5)',
            }}
          >
            <div className="Files__Image__content__overlay__text">
              {NativeDownloadingEvent?.progress?.percent !== 1 ? (
                <>
                  <Loader white style={{ paddingBottom: 5 }}>
                    Downloading
                  </Loader>
                  <Progress
                    white
                    style={{ maxWidth: '70%', margin: '0 auto' }}
                    barStyle={{ height: 16 }}
                    percentage={NativeDownloadingEvent.progress.percent * 100}
                  />
                </>
              ) : (
                <>
                  <Icon name="check-circle-fill" style={{ fontSize: 22 }} />
                  <div style={{ marginTop: 5 }}>
                    {isPreparing ? 'Ready to drag-n-drop' : 'Ready'}
                  </div>
                </>
              )}
            </div>
          </div>
        ),
        injectThumbnail: props.isSelected ? (
          <div className="Files__Image__content__overlay brand__color__bg__before">
            <div className="Files__Image__content__overlay__text">
              <Icon name="check-circle-fill" style={{ fontSize: 22 }} />
              <div style={{ marginTop: 5 }}>Selected</div>
            </div>
          </div>
        ) : (
          isNative() &&
          isHover &&
          props.data?.photo?.data?.file?.contentLength > 3000000 &&
          !NativeEvent.directoryMap.find(
            (f) => f.file._id === props.data?.photo?.data?._id,
          ) && (
            <div
              className="Files__Image__content__overlay no-before brand__color__bg__before"
              style={{
                background: 'rgba(0, 0, 0, 0.1)',
              }}
            >
              <div className="Files__Image__content__overlay__text">
                <Tooltip delay={0} message="Prepare file for drag-n-drop">
                  <Button
                    inline
                    size="small"
                    compact
                    primary
                    onClick={async (event) => {
                      event.preventDefault();
                      event.stopPropagation();
                      setIsPreparing(true);
                      await window.pickitNative?.downloadTempFile(
                        props.data?.photo?.data,
                        generateUrl(props.data.photo?.data?.file?.url),
                      );
                      return false;
                    }}
                  >
                    <Icon name="cloud-plus-fill" />
                    Load
                  </Button>
                </Tooltip>
              </div>
            </div>
          )
        ),
      })}
    </div>
  );

  if (isNative() && availability.download) {
    return (
      <NativeDragWrapper
        file={props.data?.photo?.data}
        fileUrl={generateUrl(props.data.photo?.data?.file?.url)}
      >
        <div className={className.join(' ')} ref={fileRef}>
          {image}
        </div>
      </NativeDragWrapper>
    );
  }
  image = (
    <div className="Files__Image__content" onClick={handeClick}>
      <PinkToolbar
        parentRef={ref}
        overlay
        size="small"
        brand
        floating
        style={{
          top: props.detailed ? -1 : 6,
          right: props.detailed ? -1 : 6,
        }}
      >
        <AssetToolbar
          actions={[
            'download',
            'copy',
            'download_external',
            'open_external',
            'open_slides',
            'open_document',
            'insert',
            'insert_slide',
            'insert_themed_slide',
            ...(props.includeDownloadBag ? ['add_to_bag'] : []),
          ]}
          onFileActionStart={() => setIsLoading(true)}
          onFileActionEnd={() => setIsLoading(false)}
          downloadWrapper={props.downloadWrapper}
          file={props.data.photo?.data}
          includeDownloadBag={props.includeDownloadBag}
          onPreview={(file, scenario) => props.onFileRedirect(file, scenario)}
        />
      </PinkToolbar>
      {props.image(null, {
        isLoading: props.isLoading || isLoading,
        loadingMessage,
        injectThumbnail: props.isSelected ? (
          <div className="Files__Image__content__overlay brand__color__bg__before">
            <div className="Files__Image__content__overlay__text">
              <Icon name="check-circle-fill" style={{ fontSize: 22 }} />
              <div style={{ marginTop: 5 }}>Selected</div>
            </div>
          </div>
        ) : (
          ''
        ),
      })}
    </div>
  );
  return (
    <div className={className.join(' ')} ref={fileRef}>
      {props.downloadWrapper ? (
        <>
          {props.downloadWrapper(
            image,
            () => {
              downloadFile();
            },
            undefined,
            true,
          )}
        </>
      ) : (
        <>{image}</>
      )}
    </div>
  );
};

export default Image;
