import System from '@pixi/System';
import {
  BoxProps,
  Button,
  ButtonCssVariables,
  ButtonGroup,
  ButtonProps,
  ButtonStylesNames,
  ButtonVariant,
  polymorphicFactory,
  PolymorphicFactory,
} from '@mantine/core';
import { isPromise } from '@pixi/helpers/utils';
import { ForwardedRef, MouseEvent, useState } from 'react';
import PixiIcon from '../Icon';
import PixiDropdown, { PixiDropdownProps } from '../Dropdown';

export type PixiButtonProps = ButtonProps & {
  dropdown?: PixiDropdownProps['children'];
  dropdownProps?: Omit<PixiDropdownProps, 'target'>;
  onClick?: (
    event: MouseEvent<HTMLButtonElement>,
  ) => unknown | Promise<unknown>;
  rootProps?: BoxProps;
};

export type ButtonFactory = PolymorphicFactory<{
  props: PixiButtonProps;
  defaultRef: unknown;
  defaultComponent: 'button';
  stylesNames: ButtonStylesNames;
  vars: ButtonCssVariables;
  variant: ButtonVariant;
  staticComponents: {
    Group: typeof ButtonGroup;
  };
}>;

const PixiButton = polymorphicFactory<ButtonFactory>(
  (props, ref: ForwardedRef<unknown>) => {
    const { children, onClick, dropdown, dropdownProps, rootProps, ...rest } =
      props;
    const [isLoading, setIsLoading] = useState(false);

    const handleClick = async (event: MouseEvent<HTMLButtonElement>) => {
      if (onClick instanceof Function) {
        const onClickEvent = onClick(event);
        const isAsync = isPromise(onClickEvent);

        if (isAsync) {
          try {
            setIsLoading(true);
            await onClickEvent;
            setIsLoading(false);
          } catch (error) {
            System.Report.logError(error as Error);
            setIsLoading(false);
            // CATCH ERROR
          }
        }
      }
    };

    const button = (
      <Button
        {...rest}
        ref={!dropdown ? (ref as ForwardedRef<HTMLButtonElement>) : undefined}
        loading={rest.loading || isLoading}
        style={{
          ...(!dropdown ? rest.style : {}),
          cursor: rest.loading || isLoading ? 'progress' : undefined,
        }}
        onClick={onClick ? handleClick : undefined}
        {...(!dropdown ? { ...rootProps } : {})}
        mr={dropdown ? 0 : rest?.mr}
      >
        {children}
      </Button>
    );

    if (dropdown) {
      return (
        <Button.Group
          ref={ref as ForwardedRef<HTMLDivElement>}
          style={rest.style}
          {...rootProps}
        >
          {button}
          <PixiDropdown
            {...(dropdownProps || {})}
            target={
              <Button
                size={rest.size}
                pl="xs"
                pr="xs"
                variant={rest.variant}
                color={rest.color}
                ml={0}
                style={{
                  ...(rest.variant === 'outline'
                    ? { borderLeftColor: 'transparent' }
                    : {}),
                }}
              >
                <PixiIcon name="chevron-down" variant="filled" size="xs" />
              </Button>
            }
          >
            {dropdown}
          </PixiDropdown>
        </Button.Group>
      );
    }

    return button;
  },
);

export default PixiButton;
