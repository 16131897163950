import PixiIcon, { PixiIconName } from '@pixi/elements/Icon';
import PixiPopup from '@pixi/elements/Popup';
import { useUserContext } from 'hooks';
import useContextFromType from 'hooks/useContextFromType';
import useUserStatus from 'hooks/useUserStatus';
import { ReactNode, useEffect, useState } from 'react';
import { Face } from 'views/_Manage/Media/parts/Attributes';
import ApprovalFlows from '../Files/pages/Approvals/parts/ApprovalFlows';
import {
  Badge,
  Box,
  Group,
  NavLink,
  Paper,
  Stack,
  Tabs,
  TextInput,
} from '@mantine/core';
import PixiText from '@pixi/elements/Text';
import Sortable from '@pixi/elements/Sortable';
import PixiButton from '@pixi/elements/Button';
import PixiConfirm from '@pixi/elements/Confirm';
import PresetForm from '@pixi/components/AssetQuickForms/PresetForm';
import { truncate } from 'utils';
import ToggleCard from '@pixi/elements/ToggleCard';
import PixiDropdown from '@pixi/elements/Dropdown';
import PixiTooltip from '@pixi/elements/Tooltip';
import DropdownInfo from '@pixi/elements/DropdownInfo/Info';
import { useColorScheme } from '@pixi/AppController';
import PixiPopupTabs from '@pixi/elements/Popup/PopupTabs';

export default function LibrarySettings({
  isOpen,
  libraryId,
  onClose,
  defaultTab,
}: {
  isOpen?: boolean;
  libraryId?: string;
  onClose?: () => void;
  defaultTab?: string;
}) {
  const [currentTab, setCurrentTab] = useState(defaultTab || '');
  const context = useContextFromType(libraryId);
  const [filters, setFilters] = useState<any>([]);
  const User = useUserContext();
  const userStatus = useUserStatus();
  const [searchQuery, setSearchQuery] = useState('');
  const colorScheme = useColorScheme();

  useEffect(() => {
    if (defaultTab) {
      setCurrentTab(defaultTab || '');
    }
  }, [defaultTab]);

  const library = User.data?.selectedCommunity?.libraries?.find(
    (l: any) => l.nameId === context?.type,
  );

  useEffect(() => {
    if (!User.data?.selectedCommunity?.libraries?.length) {
      return;
    }
    const filters = library?.preferences?.filters;
    const enabledFilters =
      filters?.enabled?.filter(
        (f: any) => !!allFilters?.find((ff) => ff.field === f.id),
      ) || [];
    const orderedKeys = library?.preferences?.filters?.order || [];
    setFilters(() => [
      ...orderedKeys.map((key: string) =>
        enabledFilters.find((f: any) => f.id === key),
      ),
      ...enabledFilters.filter((f: any) => !orderedKeys.includes(f.id)),
    ]);
  }, [User.data?.selectedCommunity, libraryId]);

  if (!User?.data?.selectedCommunity || !libraryId) {
    return <></>;
  }
  const imageEditorPresets = (User.data?.selectedCommunity?.settings
    ?.imageEditorPresets ||
    []) as Pickit.CommunityInterface['settings']['imageEditorPresets'];

  const disableImageEditEndUser = User.data.selectedCommunity.libraries.find(
    (library: any) => library.nameId === 'media',
  ).preferences.disableImageEditEndUser
    ? User.data.selectedCommunity.libraries.find(
        (library: any) => library.nameId === 'media',
      ).preferences.disableImageEditEndUser
    : false;
  const { enableTagsSearch, enableUserFeedback, enableExternalUserFeedback } =
    library.preferences;

  const allFilters: Pickit.LibraryFilter['row']['view'][] =
    User.data?.filters?.all;
  const allFiltersNotActive = allFilters?.filter(
    (f) =>
      !library?.preferences?.filters?.enabled?.find(
        (ff: any) => ff.id === f.field,
      ),
  );

  return (
    <PixiPopupTabs
      opened={isOpen}
      currentTab={currentTab}
      onTabChange={setCurrentTab}
      onClose={() => onClose?.()}
      title="Library Settings"
    >
      <PixiPopupTabs.Tab id="general" title="General" icon="sliders">
        <>
          <Stack>
            <Stack gap="xs">
              <PixiText fw="500" size="xs" mt="xs" ml="xs" c="primary">
                AUTOCOMPLETE SEARCH
              </PixiText>
              <ToggleCard
                title="Tags"
                description="Will appear as suggestions in the search field"
                onChange={async () => {
                  await User.saveLibrary(library._id, {
                    'preferences.enableTagsSearch': !enableTagsSearch,
                    ...(enableTagsSearch
                      ? {
                          'preferences.enableGlobalTagsSearch': false,
                        }
                      : {}),
                  });
                }}
                isActive={enableTagsSearch}
              />
              {/* {enableTagsSearch && (
                    <ToggleCard
                      title="Only global tags"
                      description="Only tags in your global tags will be used in autocomplete"
                      onChange={async () => {
                        await User.saveLibrary(library._id, {
                          'preferences.enableGlobalTagsSearch':
                            !enableGlobalTagsSearch,
                        });
                      }}
                      isActive={enableGlobalTagsSearch}
                    />
                  )} */}
            </Stack>
            <Stack gap="xs">
              <PixiText fw="500" size="xs" mt="xs" ml="xs" c="primary">
                FEEDBACK
              </PixiText>
              <ToggleCard
                title="Feedback"
                description="Gives all users the ability to leave feedback on assets"
                onChange={async () => {
                  await User.saveLibrary(library._id, {
                    'preferences.enableUserFeedback': !enableUserFeedback,
                  });
                }}
                isActive={
                  typeof enableUserFeedback === 'boolean'
                    ? enableUserFeedback
                    : User.data.selectedCommunity.settings?.preferences?.feedback?.find(
                        (prefs: any) => prefs.library === context.type,
                      )?.allowFileFeedback
                }
              />
              <ToggleCard
                title="External feedback"
                description="Allow external users to leave feedback on assets"
                isActive={
                  typeof enableExternalUserFeedback === 'boolean'
                    ? enableExternalUserFeedback
                    : User.data.selectedCommunity.settings?.preferences?.feedback?.find(
                        (prefs: any) => prefs.library === context.type,
                      )?.allowFeedbackFromExternal
                }
                onChange={async () => {
                  await User.saveLibrary(library._id, {
                    'preferences.enableExternalUserFeedback':
                      !enableExternalUserFeedback,
                  });
                }}
              />
            </Stack>
          </Stack>
        </>
      </PixiPopupTabs.Tab>
      <PixiPopupTabs.Tab id="filters" title="Filters" icon="filter">
        <>
          <Tabs defaultValue="active_filters" variant="pills">
            <Tabs.List mb="md">
              <Tabs.Tab value="active_filters">Active filters</Tabs.Tab>
              <Tabs.Tab
                value="inactive_filters"
                rightSection={
                  <Badge color="dark">{allFiltersNotActive.length}</Badge>
                }
              >
                Inactive filters
              </Tabs.Tab>
            </Tabs.List>

            <Tabs.Panel value="active_filters">
              <Stack gap="xs">
                <Sortable
                  initialData={filters.filter(
                    (f: Pickit.LibraryFilterDataRow) => {
                      return !!f;
                    },
                  )}
                  onRearrange={(newOrder) => {
                    setFilters(() => [...newOrder]);
                    User.saveLibrary(library._id, {
                      'preferences.filters.order': newOrder.map(
                        (f: any) => f.id,
                      ),
                    });
                  }}
                  render={({ dataRow, props, isDragging }) => {
                    const data = dataRow?.data;
                    const view = allFilters?.find(
                      (f) => f.field === data.id || f.id === data.id,
                    );
                    if (!view) {
                      return <></>;
                    }
                    return (
                      <ToggleCard
                        title={truncate(view.name, 50, '...')}
                        description={view.description}
                        key={data.id}
                        isActive
                        paperProps={{ withBorder: true }}
                        onlySwitchClick
                        tooltip="Deactivate filter"
                        icon={
                          view.field?.startsWith('properties')
                            ? 'filter-list'
                            : view.field?.startsWith('attributes')
                              ? 'code'
                              : 'tag'
                        }
                        iconTooltip={
                          view.field?.startsWith('properties')
                            ? 'Custom property'
                            : view.field?.startsWith('attributes')
                              ? 'Metadata property'
                              : 'System property'
                        }
                        rightSection={
                          <PixiDropdown
                            target={
                              <PixiButton
                                size="xs"
                                w="auto"
                                variant={data.onlyAdmins ? 'light' : 'light'}
                                color={!data.onlyAdmins ? 'primary' : 'dark'}
                                radius="xl"
                                mr="xl"
                                rightSection={
                                  <PixiIcon name="chevron-down" size="xs" />
                                }
                                onClick={async (event) => {
                                  event.preventDefault();
                                  event.stopPropagation();
                                  await User.saveLibrary(
                                    library._id,
                                    {
                                      'preferences.filters.enabled.$[filter].onlyAdmins':
                                        !data.onlyAdmins,
                                    },
                                    [{ 'filter._id': data._id }],
                                  );
                                }}
                              >
                                {data.onlyAdmins ? 'Admins only' : 'All users'}
                              </PixiButton>
                            }
                          >
                            <PixiDropdown.Item
                              color={!data.onlyAdmins ? 'primary' : undefined}
                              onClick={async () => {
                                await User.saveLibrary(
                                  library._id,
                                  {
                                    'preferences.filters.enabled.$[filter].onlyAdmins':
                                      false,
                                  },
                                  [{ 'filter._id': data._id }],
                                );
                              }}
                            >
                              All users
                            </PixiDropdown.Item>
                            <PixiDropdown.Item
                              color={data.onlyAdmins ? 'primary' : undefined}
                              onClick={async () => {
                                await User.saveLibrary(
                                  library._id,
                                  {
                                    'preferences.filters.enabled.$[filter].onlyAdmins':
                                      true,
                                  },
                                  [{ 'filter._id': data._id }],
                                );
                              }}
                            >
                              Admins only
                            </PixiDropdown.Item>
                          </PixiDropdown>
                        }
                        onChange={async () => {
                          await User.saveLibrary(library._id, {
                            $pull: {
                              'preferences.filters.enabled': {
                                _id: data._id,
                              },
                              'preferences.filters.order': data.id,
                            },
                          });
                        }}
                        {...props}
                        style={{
                          ...props.style,
                          cursor: isDragging ? 'grabbing' : 'grab',
                          zIndex: isDragging ? 5 : 4,
                        }}
                      />
                    );
                  }}
                />
              </Stack>
            </Tabs.Panel>

            <Tabs.Panel value="inactive_filters">
              <Stack gap="xs">
                <TextInput
                  maw={280}
                  autoFocus
                  variant="default"
                  leftSection={<PixiIcon name="magnifying-glass" />}
                  placeholder="Search"
                  value={searchQuery}
                  onChange={(event) => {
                    setSearchQuery(event.currentTarget.value);
                  }}
                />
                {allFiltersNotActive
                  .filter((view) => {
                    if (searchQuery && view) {
                      return (
                        view?.name
                          ?.toLowerCase()
                          ?.includes(searchQuery.toLowerCase()) ||
                        view?.description
                          ?.toLowerCase()
                          ?.includes(searchQuery.toLowerCase())
                      );
                    }
                    return true;
                  })
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map((view) => {
                    if (!view) {
                      return <></>;
                    }
                    return (
                      <ToggleCard
                        key={view.field}
                        title={view.name}
                        description={view.description}
                        icon={
                          view.field?.startsWith('properties')
                            ? 'filter-list'
                            : view.field?.startsWith('attributes')
                              ? 'code'
                              : 'tag'
                        }
                        iconTooltip={
                          view.field?.startsWith('properties')
                            ? 'Custom property'
                            : view.field?.startsWith('attributes')
                              ? 'Metadata property'
                              : 'System property'
                        }
                        onChange={async () => {
                          await User.saveLibrary(library._id, {
                            $push: {
                              'preferences.filters.enabled': {
                                id: view.field,
                                type: view.field.includes('attributes')
                                  ? 'attributes'
                                  : 'standard',
                              },
                            },
                          });
                        }}
                      />
                    );
                  })}
              </Stack>
            </Tabs.Panel>
          </Tabs>
        </>
      </PixiPopupTabs.Tab>
      <PixiPopupTabs.Tab
        id="downloads"
        title="Downloads"
        icon="arrow-down-to-line"
      >
        <>
          <Stack>
            {context.type === 'documents' && (
              <>
                <Stack gap="xs">
                  <PixiText fw="500" size="xs" mt="xs" ml="xs" c="primary">
                    POWERPOINT
                  </PixiText>
                  <ToggleCard
                    title="PowerPoint slides"
                    description={
                      <>
                        Enable your users to insert slides individually from a
                        PowerPoint file.
                        <br />
                        This setting can be overriden on a file per file basis.
                      </>
                    }
                    isActive={
                      User.data.selectedCommunity.settings?.preferences
                        ?.enableQuickSlidePreview
                    }
                    onChange={async (isActive) => {
                      await User.saveSettings({
                        preferences: {
                          enableQuickSlidePreview:
                            !User.data.selectedCommunity.settings?.preferences
                              ?.enableQuickSlidePreview,
                        },
                      });
                    }}
                  />
                </Stack>
              </>
            )}
            {context.type !== 'documents' && (
              <Stack gap="xs">
                <Group align="center" gap="xs" mt="xs" ml="xs">
                  <PixiText fw="500" size="xs" c="primary">
                    CROPPING FORMATS
                  </PixiText>
                  <DropdownInfo text="Add and organize customized download options" />
                </Group>
                <Stack gap="5">
                  <Sortable
                    initialData={imageEditorPresets || []}
                    onRearrange={async (data) => {
                      await User.saveSettingsPartial({
                        imageEditorPresets: data,
                      });
                    }}
                    render={({ dataRow, props, isDragging }) => {
                      const c = dataRow.data;
                      return (
                        <Paper
                          key={c._id}
                          p="xs"
                          pl="lg"
                          radius="md"
                          withBorder
                          {...props}
                          bg={colorScheme === 'dark' ? 'dark.6' : 'white'}
                          style={{
                            ...props.style,
                            cursor: isDragging ? 'grabbing' : 'grab',
                          }}
                        >
                          <Group>
                            <PixiIcon name="crop" />
                            <PixiText w="30%" size="sm" fw="500">
                              {c.name}
                            </PixiText>
                            <PixiText size="sm" fw="500" ml="auto">
                              {c.width}x{c.height}
                            </PixiText>
                            <Group gap="5" ml="auto">
                              <PresetForm
                                data={c}
                                target={
                                  <PixiButton
                                    size="xs"
                                    px="xs"
                                    variant="light"
                                    color="dark"
                                  >
                                    <PixiIcon name="pencil" />
                                  </PixiButton>
                                }
                              />
                              <PixiConfirm
                                title="Delete format"
                                description="Are you sure? This can't be reverted"
                                confirmLabel="Delete format"
                                confirmProps={{
                                  color: 'red',
                                }}
                                onConfirm={async () => {
                                  await User.saveSettingsPartial({
                                    $pull: {
                                      imageEditorPresets: {
                                        _id: c._id,
                                      },
                                    },
                                  });
                                }}
                              >
                                <PixiButton
                                  size="xs"
                                  px="xs"
                                  color="red"
                                  variant="light"
                                >
                                  <PixiIcon name="trash-can" />
                                </PixiButton>
                              </PixiConfirm>
                            </Group>
                          </Group>
                        </Paper>
                      );
                    }}
                  />
                </Stack>
                <Box w="100%">
                  <PresetForm
                    target={
                      <PixiButton
                        leftSection={<PixiIcon name="plus" />}
                        size="xs"
                        ml="xs"
                      >
                        Create format
                      </PixiButton>
                    }
                  />
                </Box>
              </Stack>
            )}
            {library?.nameId === 'media' && (
              <Stack gap="xs">
                <PixiText fw="500" size="xs" mt="xs" ml="xs" c="primary">
                  IMAGE EDITOR
                </PixiText>
                <ToggleCard
                  title="Image editor for users"
                  description="Users can create custom variants and cropped versions from the original file"
                  isActive={!disableImageEditEndUser}
                  onChange={() => {
                    User.saveLibrary(library._id, {
                      'preferences.disableImageEditEndUser':
                        !disableImageEditEndUser,
                    });
                  }}
                />
              </Stack>
            )}
            {context.type !== 'documents' && (
              <Stack gap="xs">
                <Group align="center" gap="xs" mt="xs" ml="xs">
                  <PixiText fw="500" size="xs" c="primary">
                    VECTOR FILES
                  </PixiText>
                  <DropdownInfo text="SVG, EPS and AI files will also have a PNG download option." />
                </Group>
                <ToggleCard
                  title="Enable PNG"
                  isActive={
                    User.data.selectedCommunity.settings?.preferences
                      ?.disableVectorDownloadRestriction
                  }
                  onChange={async () => {
                    await User.saveSettings({
                      preferences: {
                        disableVectorDownloadRestriction:
                          !User.data.selectedCommunity.settings?.preferences
                            ?.disableVectorDownloadRestriction,
                      },
                    });
                  }}
                />
              </Stack>
            )}
          </Stack>
        </>
      </PixiPopupTabs.Tab>
      <PixiPopupTabs.Tab id="ai" title="AI" icon="microchip-ai">
        <>
          <Stack>
            {context.type === 'media' && (
              <>
                <ToggleCard
                  title="Auto-tag"
                  description={
                    <>
                      In addition to the files metadata, we use our Vision AI to
                      tag your images.
                    </>
                  }
                  isActive={
                    !User.data.selectedCommunity.settings?.preferences
                      ?.disableAutoTag
                  }
                  onChange={async () => {
                    await User.saveSettings({
                      preferences: {
                        disableAutoTag:
                          !User.data.selectedCommunity.settings?.preferences
                            ?.disableAutoTag,
                      },
                    });
                  }}
                />
                <PixiTooltip
                  disabled={!userStatus.activeFeatures?.pickit_vision_ai}
                  label="Included in your subscription. Contact your account manager to disable"
                >
                  <ToggleCard
                    title="Facial recognition"
                    description={
                      !userStatus.activeFeatures?.pickit_vision_ai
                        ? 'Contact your account manager to enable this feature'
                        : undefined
                    }
                    switchProps={{
                      disabled: userStatus.activeFeatures?.pickit_vision_ai,
                    }}
                    isActive={userStatus.activeFeatures?.pickit_vision_ai}
                  />
                </PixiTooltip>
              </>
            )}
            <PixiTooltip
              disabled={!userStatus.activeFeatures?.pickit_vision_ai}
              label="Included in your subscription. Contact your account manager to disable"
            >
              <ToggleCard
                title="Optical Character Recognition (OCR)"
                description={
                  !userStatus.activeFeatures?.ocr_and_fulltext_search
                    ? 'Contact your account manager to enable this feature'
                    : undefined
                }
                isActive={userStatus.activeFeatures?.ocr_and_fulltext_search}
                switchProps={{
                  disabled: userStatus.activeFeatures?.ocr_and_fulltext_search,
                }}
              />
            </PixiTooltip>
            <PixiTooltip
              disabled={!userStatus.activeFeatures?.pickit_vision_ai}
              label="Included in your subscription. Contact your account manager to disable"
            >
              <ToggleCard
                title="Full-Text search"
                description={
                  !userStatus.activeFeatures?.ocr_and_fulltext_search
                    ? 'Contact your account manager to enable this feature'
                    : undefined
                }
                isActive={userStatus.activeFeatures?.ocr_and_fulltext_search}
                switchProps={{
                  disabled: userStatus.activeFeatures?.ocr_and_fulltext_search,
                }}
              />
            </PixiTooltip>
            <PixiTooltip
              disabled={!userStatus.activeFeatures?.video_transcription}
              label="Included in your subscription. Contact your account manager to disable"
            >
              <ToggleCard
                title="Video transcription"
                description={
                  !userStatus.activeFeatures?.video_transcription
                    ? 'Contact your account manager to enable this feature'
                    : undefined
                }
                isActive={userStatus.activeFeatures?.video_transcription}
                switchProps={{
                  disabled: userStatus.activeFeatures?.video_transcription,
                }}
              />
            </PixiTooltip>
          </Stack>
        </>
      </PixiPopupTabs.Tab>
      {libraryId !== 'documents' && (
        <PixiPopupTabs.Tab
          id="facial_recognition"
          title="Facial recognition"
          icon="face-viewfinder"
        >
          <Face />
        </PixiPopupTabs.Tab>
      )}
    </PixiPopupTabs>
  );
}
