import React from 'react';

/**
 * This component is NOT TypeScript ready.
 * I had to convert it to fix some lint errors.
 * – Jake
 */
const getFlexDirection = (column: any, reverse: any) => {
  let direction = 'row';
  if (column) direction = 'column';
  if (reverse) direction += '-reverse';
  return direction;
};

const Flex = ({
  style = {},
  className,
  item,
  justifyContent,
  minWidth,
  column,
  reverse,
  flexGrow,
  flexBasis,
  flexShrink,
  flexWrap,
  flex,
  alignItems,
  margin,
  padding,
  width,
  height,
  maxWidth,
  children,
  gap,
  ...rest
}: any) => (
  <div
    {...rest}
    className={`Pixi__Flex ${className}`}
    style={{
      display: item ? 'block' : 'flex',
      justifyContent: justifyContent || 'flex-start',
      flexDirection: getFlexDirection(column, reverse),
      flexGrow: flexGrow || 0,
      flexBasis: flexBasis || 'auto',
      flexShrink: flexShrink || 1,
      flexWrap: flexWrap || 'nowrap',
      flex: flex || '0 1 auto',
      alignItems: alignItems || 'stretch',
      margin,
      padding,
      minWidth,
      width: width || 'auto',
      height: height || 'auto',
      maxWidth: maxWidth || 'none',
      gap,
      ...style,
    }}
  >
    {children}
  </div>
);

export default Flex;
