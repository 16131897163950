import React, { createContext, useEffect, useState, useReducer } from 'react';
import { format, isAfter, subMonths, addSeconds } from 'date-fns';
import { useDocumentsContext, useUserContext } from 'hooks';
import { ExternalSystemsContext, MediaContext } from 'contexts';
import useSharepointExport from 'hooks/import/Microsoft/useSharepointExport';
import useDropboxExport from 'hooks/import/Dropbox/useDropboxExport';
import useHubspotExport from 'hooks/import/Hubspot/useHubspotExport';
import useHubspotImport from 'hooks/import/HubspotImport/useHubspotImport';
import useHubspotApi from 'hooks/import/Hubspot/useHubspotApi';
import useHubspotImportAuth from 'hooks/import/HubspotImport/useHubspotImportAuth';
import useMicrosoftGraph from 'hooks/import/Microsoft/useMicrosoftGraph';
import useNetworkFileSystem from 'hooks/import/NetworkFileSystem/useNetworkFileSystem';
import useGoogleDrive from 'hooks/import/Google/useGoogleDrive';
import useSharepoint from 'hooks/import/Microsoft/useSharepoint';
import useShutterStock from 'hooks/import/useShutterstock';
import useIntegrationHelpers from 'hooks/import/useIntegrationHelpers';
import useGoogle from 'hooks/import/Google/useGoogle';
import useAdobe from 'hooks/import/Adobe/useAdobe';

import { ToastService } from 'elements/Toast';
import useAdobeCCL from 'hooks/import/Adobe/useAdobeCCL';
import useDropboxAuth from 'hooks/import/Dropbox/useDropboxAuth';
import useDropbox from 'hooks/import/Dropbox/useDropbox';
// Still used by export and possibly other systems
import useDropboxApiDEPRICATED from 'hooks/import/Dropbox/Old/useDropboxApi';
import useDropboxDEPRICATED from 'hooks/import/Dropbox/Old/useDropbox';
import localStorageWrapper from 'utils/localStorageWrapper';
import useNetworkFileSystemAuth from 'hooks/import/NetworkFileSystem/useNetworkFileSystemAuth';
import useBoxAuth from 'hooks/import/Box/useBoxAuth';
import useBox from 'hooks/import/Box/useBox';

let DROPBOX_ACCOUNT = null;

const DEFAULT_DATA_STATE = {
  page: 1,
  total: 0,
  count: 0,
  data: [],
  isAtEnd: false,
};

const ALLOWED_IMAGE_EXT = [
  'jpg',
  'jpeg',
  'png',
  'tiff',
  'tif',
  'gif',
  'bmp',
  'cr2',
];
const ALLOWED_EXT = [
  'jpg',
  'jpeg',
  'cr2',
  'png',
  'psd',
  'tiff',
  'tif',
  'eps',
  'gif',
  'svg',
  'mp4',
  'mp3',
  'ai',
  'mov',
  'dotx',
  'bmp',
  'key',
  'pages',
  'numbers',
  'doc',
  'docx',
  'pdf',
  'odt',
  'xls',
  'xlsx',
  'xltx',
  'potx',
  'ppa',
  'pps',
  'ppsx',
  'ppt',
  'pptx',
  // Premiere Pro
  'prproj',
  // After Effects
  'aep',
  'aepx',
  // InDesign
  'indd',
  'indt',
];

export const ExternalSystemsProvider = ({ children }) => {
  const Media = React.useContext(MediaContext);
  /**
   * Microsoft
   */
  const MicrosoftGraph = useMicrosoftGraph();
  const Sharepoint = useSharepoint({ Graph: MicrosoftGraph });
  /**
   * Dropbox
   */
  const DropboxAuth = useDropboxAuth();
  const Dropbox = useDropbox({ DropboxAuth });
  /**
   * Google
   */
  const Google = useGoogle();
  const GoogleDrive = useGoogleDrive({ Google });

  /**
   * Adobe
   * TODO: port to unified integration solution
   */
  const Adobe = useAdobe();
  const AdobeCCL = useAdobeCCL({ Adobe });

  const BoxAuth = useBoxAuth();
  const Box = useBox({ BoxAuth });

  const Documents = useDocumentsContext();
  const ShutterstockHook = useShutterStock();
  const User = useUserContext();

  const IntegrationHelpers = useIntegrationHelpers();

  // Network Drive
  const NetworkFileSystemAuth = useNetworkFileSystemAuth();
  const NetworkFileSystem = useNetworkFileSystem({ NetworkFileSystemAuth });

  const DropboxApiDEPRICATED = useDropboxApiDEPRICATED();
  const DropboxDEPRICATED = useDropboxDEPRICATED({
    Dropbox: DropboxApiDEPRICATED,
  });

  const SharepointExport = useSharepointExport({
    Sharepoint,
    Graph: MicrosoftGraph,
  });
  const DropboxExport = useDropboxExport({ Dropbox: DropboxDEPRICATED });

  // Hubspot
  const HubspotApi = useHubspotApi();
  const HubspotExport = useHubspotExport({ Hubspot: HubspotApi });

  const HubspotImportAuth = useHubspotImportAuth();
  const HubspotImport = useHubspotImport({ HubspotImportAuth });

  const API_BASE =
    window.location.href.includes('.dev') ||
    window.location.href.includes('localhost')
      ? 'https://api-sandbox.shutterstock.com'
      : 'https://api.shutterstock.com';

  const [dateFrom, setDateFrom] = useState(
    format(subMonths(new Date(), 3), 'yyyy-MM-dd'),
  );
  const [boxCache, setBoxCache] = useState(Date.now());
  const [dateTo, setDateTo] = useState(format(new Date(), 'yyyy-MM-dd'));
  const [errorShown, setErrorShown] = useState(false);
  const [currentSearchPhrase, setCurrentSearchPhrase] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [auth, setAuth] = React.useReducer(
    (state, action) => ({ ...action }),
    null,
  );
  const [syncJobs, setSyncJobs] = useState([]);
  const [dropboxCache, setDropboxCache] = useState(Date.now());
  const [dropboxFiles, setDropboxFiles] = React.useReducer((state, action) => {
    if (!action.data) return state;
    if (action.type === 'RESET') {
      return {
        [action.data.path]: action.data,
      };
    }
    if (action.type === 'SET') {
      return {
        ...state,
        [action.data.path]: action.data,
      };
    }
    if (action.type === 'PUSH') {
      return {
        ...state,
        [action.data.path]: {
          ...state[action.data.path],
          cursor: action.data.cursor,
          hasMore: action.data.hasMore,
          data: [...state[action.data.path].data, ...action.data.data],
        },
      };
    }
  }, {});

  const [boxFiles, setBoxFiles] = React.useReducer((state, action) => {
    const copyState = JSON.parse(JSON.stringify(state));
    if (!action.data) return state;
    if (action.type === 'RESET') {
      return {
        [action.data.parentId]: action.data,
      };
    }
    if (action.type === 'SET') {
      return {
        ...copyState,
        [action.data.parentId]: action.data,
      };
    }
    if (action.type === 'PUSH') {
      return {
        ...state,
        [action.data.parentId]: {
          ...copyState[action.data.path],
          cursor: action.data.cursor,
          hasMore: action.data.hasMore,
          data: [
            ...(copyState[action.data.parentId]?.data ?? []),
            ...action.data.data,
          ],
        },
      };
    }
  }, {});

  useEffect(() => {
    (async () => {
      if (userContext.spaces?.selected?.slug) {
        setIsLoading(true);
        dispatchDownloadHistory({ type: 'RESET' });
        await getDownloadHistory(DEFAULT_DATA_STATE);
        setIsLoading(false);
      }
    })();
  }, [dateTo, dateFrom]);

  function legacyIntegrations() {
    this.helpers = IntegrationHelpers;
    this.getFiles = async (path, cursor, teamSpace, account) => {
      const token = await this.getToken();
      const URL = cursor
        ? 'https://api.dropboxapi.com/2/files/list_folder/continue'
        : 'https://api.dropboxapi.com/2/files/list_folder';
      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      };

      if (teamSpace) {
        headers['Dropbox-API-Path-Root'] =
          `{".tag": "root", "root": "${account.root_info.root_namespace_id}"}`;
      }

      const OPTIONS = {
        method: 'post',
        headers,
        body: cursor
          ? JSON.stringify({ cursor })
          : JSON.stringify({
              path,
              recursive: false,
              include_media_info: true,
              limit: 2000,
              include_deleted: false,
              include_has_explicit_shared_members: false,
              include_mounted_folders: true,
              include_non_downloadable_files: false,
            }),
      };
      const jsonResult = await fetch(URL, OPTIONS);
      return await jsonResult.json();
    };
    this.getAlreadyImported = async (ids, from = 'dropbox') => {
      const mediaFiles = await User.request.media.getDocuments({
        filter: {
          'import.from': [from],
          'import.reference': ids,
        },
      });
      const documentFiles = await User.request.documents.getDocuments({
        filter: {
          'import.from': [from],
          'import.reference': ids,
        },
      });
      return [...mediaFiles.documents, ...documentFiles.documents];
    };
    this.getToken = async () => {
      const { expires, refreshToken, accessToken } = JSON.parse(
        localStorageWrapper.getItem('dropbox'),
      );
      const isExpired = Date.now() > expires;
      if (isExpired) {
        const { data: result } =
          await userContext.request.policy.dropboxRefreshToken(refreshToken);
        const dropbox = {
          accessToken: result.access_token,
          refreshToken,
          expires: result.expires_in * 1000 + Date.now(),
        };
        localStorageWrapper.setItem('dropbox', JSON.stringify(dropbox));
        return result.access_token;
      }
      return accessToken;
    };
    this.generateThumbnailQueries = async (images) => {
      const thumbnailQuery = images
        .map((image) => {
          const ext = image.external.name.split('.')[1];
          if (ext && ALLOWED_IMAGE_EXT.includes(ext)) {
            return image;
          }
          return undefined;
        })
        .filter((item) => !!item)
        .map((image) => ({
          path: image.external.path,
          format: 'jpeg',
        }));
      const thumbnailQueries = [[]];
      let index = 0;
      while (thumbnailQuery.length) {
        if (thumbnailQueries[index] < 25) {
          thumbnailQueries[index].push(thumbnailQuery.pop());
        } else {
          index++;
          thumbnailQueries.push([]);
          thumbnailQueries[index].push(thumbnailQuery.pop());
        }
      }
      return thumbnailQueries;
    };
    this.getThumbsnails = async (files) => {
      const token = await this.getToken();
      const supportedImages = files.filter(
        (file) => file.external.type !== 'folder' && file.media.supported,
      );
      const thumbnailQueries =
        await this.generateThumbnailQueries(supportedImages);
      const promises = [];
      thumbnailQueries.forEach((query) => {
        const THUMB_URL =
          'https://content.dropboxapi.com/2/files/get_thumbnail_batch';
        const THUMB_OPTIONS = {
          method: 'post',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            entries: query,
          }),
        };
        promises.push(fetch(THUMB_URL, THUMB_OPTIONS));
      });
      const thumbnailsJSON = await Promise.all(promises);
      const thumbnailsAll = await Promise.all(
        thumbnailsJSON.map((res) => res.clone().json()),
      );
      return thumbnailsAll
        .flat()
        .map((all) => all.entries)
        .flat();
    };
    this.replaceMedia = async (contributionData) => {
      const token = await this.getToken();

      const GET_TMP_LINK_URL =
        'https://api.dropboxapi.com/2/files/get_temporary_link';
      const OPTIONS = {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          path: contributionData.path_lower,
        }),
      };
      const linkJSON = await fetch(GET_TMP_LINK_URL, OPTIONS);
      const { link } = await linkJSON.json();
      if (link) {
        const imageExt = ['jpg', 'jpeg', 'png', 'tiff', 'tif', 'gif', 'bmp'];
        if (imageExt.includes(contributionData.name.split('.')[1])) {
          await Media.replaceMedia(
            link,
            contributionData._id,
            'dropbox',
            contributionData.content_hash,
          );
        } else {
          await Documents.replaceMedia(
            link,
            contributionData.id,
            'dropbox',
            contributionData.content_hash,
          );
        }
      }
    };
    this.processFiles = (files) =>
      files.map((file) => {
        if (file['.tag'] === 'folder') return { ...file, supported: true };
        if (
          ALLOWED_EXT.includes(file.name.split('.').pop()?.toLowerCase()) &&
          !file.name.includes('.zip')
        )
          return { ...file, supported: true };
        return { ...file, supported: false };
      });
    this.getAllJobs = async () => {
      const { data: jobs } = await this.getJobs();
      setSyncJobs(jobs);
      return jobs;
    };
    this.removeSyncJob = (id) =>
      setSyncJobs(syncJobs.filter((job) => job._id !== id));
    this.search = async (value, cursor, foldersOnly) => {
      const token = await this.getToken();
      let URL = '';
      let OPTIONS = {};
      if (cursor) {
        URL = 'https://api.dropboxapi.com/2/files/search/continue_v2';
        OPTIONS = {
          method: 'post',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            cursor,
          }),
        };
      } else {
        const bodyData = {
          query: value,
          options: {
            path: '',
            max_results: 20,
            file_status: 'active',
            filename_only: false,
          },
          match_field_options: { include_highlights: false },
        };
        if (foldersOnly) {
          bodyData.options.file_categories = ['folder'];
        }
        URL = 'https://api.dropboxapi.com/2/files/search_v2';
        OPTIONS = {
          method: 'post',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(bodyData),
        };
      }
      const JSONresult = await fetch(URL, OPTIONS);
      return JSONresult.json();
    };
    this.searchWithParsedData = async (value, cursor) => {
      const result = await this.search(value, cursor);
      let parsedFiles = await this.processFiles(
        result.matches.map((match) => match.metadata.metadata),
      );
      parsedFiles = parsedFiles.map((file) =>
        this.helpers.createFileObject(
          file.path_lower,
          file.name,
          file.content_hash,
          file['.tag'],
          file.path_lower,
          '',
          file.path_lower,
        ),
      );
      const thumbnails = await this.getThumbsnails(parsedFiles);
      const parsedResultsWithThumb = this.mergeFilesAndThumbnails(
        thumbnails,
        parsedFiles,
      );
      const { data: jobs } = await this.getJobs();
      const alreadyImported = await this.getAlreadyImported(
        result.matches.map((resultFile) => resultFile.metadata.metadata.id),
      );
      const withChangeAndSyncIncludes = parsedResultsWithThumb.map((file) => {
        const isImported = alreadyImported.findIndex(
          (dbFile) => dbFile.import.reference === file.external.id,
        );
        let sync = {
          active: false,
          id: null,
        };
        const foundItem = jobs
          .filter((job) => job.scriptSource === 'dropbox')
          .find(
            (item) => item.metadata.identifier === file.external.identifier,
          );
        if (foundItem) {
          sync = { ...foundItem };
          sync.active = true;
          sync.id = foundItem._id;
          sync.manual = foundItem.type === 'manual';
        }
        const hasChanged =
          file.external.checksum !==
            alreadyImported[isImported]?.import.checksum &&
          typeof alreadyImported[isImported]?.import.checksum === 'string';

        return {
          ...file,
          media: {
            ...file.media,
            isImported: isImported >= 0,
          },
          hasChanged,
          sync,
          _id: alreadyImported[isImported]?._id,
        };
      });

      return {
        hasMore: result.has_more,
        alreadyImported,
        cursor: result.cursor,
        data: withChangeAndSyncIncludes,
      };
    };
    this.getTemporaryLink = async (path) => {
      const token = await this.getToken();
      const GET_TMP_LINK_URL =
        'https://api.dropboxapi.com/2/files/get_temporary_link';
      const OPTIONS = {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          path,
        }),
      };
      const linkJSON = await fetch(GET_TMP_LINK_URL, OPTIONS);
      const result = await linkJSON.json();
      return result?.link;
    };
    this.getAccount = async () => {
      if (DROPBOX_ACCOUNT) return DROPBOX_ACCOUNT;
      const token = await this.getToken();
      const GET_TMP_LINK_URL =
        'https://api.dropboxapi.com/2/users/get_current_account';
      const OPTIONS = {
        method: 'post',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await fetch(GET_TMP_LINK_URL, OPTIONS);
      const result = await response.json();
      DROPBOX_ACCOUNT = result;
      return result;
    };
    this.mergeFilesAndThumbnails = (thumbnails, files) => {
      const parsedResultsWithThumb = [];
      for (const file of files) {
        const thumb = thumbnails.find(
          (thumb) => thumb.metadata?.path_lower === file.external.path,
        );
        parsedResultsWithThumb.push({
          ...file,
          thumb: thumb?.thumbnail,
        });
      }
      return parsedResultsWithThumb;
    };
    this.getJobs = async () =>
      userContext.request.policy.getJobs(userContext.spaces?.selected?.slug);
    this.disconnectFolder = async (id, collectionId) =>
      userContext.request.policy.disconnectFolder(
        userContext.spaces?.selected?.slug,
        id,
        collectionId,
      );
    this.refreshPath = async (path) => {
      const files = await this.getProcessedFiles(path, false, false, true);
      while (
        files.data.length <
          dropboxFiles[path.replaceAll('/', '')]?.data.length ??
        100
      ) {
        const conFiles = await this.getProcessedFiles(
          path,
          files.cursor,
          false,
          true,
        );
        files.hasMore = conFiles.hasMore;
        files.alreadyImported = [
          ...files.alreadyImported,
          ...conFiles.alreadyImported,
        ];
        files.cursor = conFiles.cursor;
        files.data = [...files.data, ...conFiles.data];
      }
      setDropboxFiles({
        type: 'SET',
        data: {
          path: path.replaceAll('/', ''),
          ...files,
        },
      });
      await this.getAllJobs();
      return files;
    };
    this.getRefreshToken = async (code) => {
      const { data: result } =
        await userContext.request.policy.dropboxCodeToToken(
          code,
          `${window.location.origin}/genericoauth.html`,
        );
      if (result.error) return;
      const dropbox = {
        accessToken: result.access_token,
        refreshToken: result.refresh_token,
        expires: result.expires_in * 1000 + Date.now(),
      };
      localStorageWrapper.setItem('dropbox', JSON.stringify(dropbox));
    };
    this.removeAllJobs = async () =>
      userContext.request.policy.removeAllJobs(
        userContext.spaces?.selected?.slug,
      );
    this.getProcessedFiles = async (path, cursor, refresh, skipCache) => {
      if (refresh) {
        return await this.refreshPath(path);
      }
      const account = await this.getAccount();
      const hasTeamSpaces =
        account?.root_info?.['.tag'] === 'team' ||
        account.root_info.root_namespace_id !==
          account.root_info.home_namespace_id;

      const noCursor = !cursor;
      const hasCache = dropboxFiles[path.replaceAll('/', '')];
      const cacheExpired = path === '' && Date.now() > dropboxCache && !cursor;
      if (noCursor && hasCache && !cacheExpired && !skipCache) {
        return dropboxFiles[path.replaceAll('/', '')];
      }
      const files = await this.getFiles(path, cursor, hasTeamSpaces, account);
      let parsedFiles = await this.processFiles(files.entries);
      parsedFiles = parsedFiles.map((file) =>
        this.helpers.createFileObject(
          file.path_lower,
          file.name,
          file.content_hash,
          file['.tag'],
          file.path_lower,
          '',
          file.path_lower,
        ),
      );
      const thumbnails = await this.getThumbsnails(parsedFiles);
      const parsedResultsWithThumb = this.mergeFilesAndThumbnails(
        thumbnails,
        parsedFiles,
      );
      const ids = parsedResultsWithThumb
        .filter((file) => file.external.etag === 'file')
        .map((file) => file.external.id);
      const { data: jobs } = await this.getJobs();
      const alreadyImported = await this.getAlreadyImported(ids);
      const withChangeAndSyncIncludes = parsedResultsWithThumb.map((file) => {
        const isImported = alreadyImported.findIndex(
          (dbFile) => dbFile.import.reference === file.external.identifier,
        );
        let sync = {
          active: false,
          id: null,
        };
        const foundItem = jobs
          .filter((job) => job.scriptSource === 'dropbox')
          .find(
            (item) => item.metadata.identifier === file.external.identifier,
          );
        if (foundItem) {
          sync = { ...foundItem };
          sync.active = true;
          sync.id = foundItem._id;
          sync.manual = foundItem.type === 'manual';
        }
        const hasChanged =
          file.content_hash !== alreadyImported[isImported]?.import.checksum &&
          typeof alreadyImported[isImported]?.import.checksum === 'string';

        return {
          ...file,
          hasChanged,
          sync,
          _id: alreadyImported[isImported]?._id,
        };
      });

      const data = {
        hasMore: files.has_more,
        alreadyImported,
        cursor: files.cursor,
        data: withChangeAndSyncIncludes,
      };

      if (cacheExpired) {
        setDropboxCache(Date.now() + 30 * 60 * 1000);
        setDropboxFiles({
          type: 'RESET',
          data: {
            path: path.replaceAll('/', ''),
            ...data,
          },
        });
      }

      if (noCursor) {
        setDropboxFiles({
          type: 'SET',
          data: {
            path: path.replaceAll('/', ''),
            ...data,
          },
        });
      } else {
        setDropboxFiles({
          type: 'PUSH',
          data: {
            path: path.replaceAll('/', ''),
            ...data,
          },
        });
      }

      return data;
    };
  }

  async function getAuth(force) {
    try {
      const cachedAuth = auth;
      if (
        cachedAuth &&
        !isAfter(new Date(), new Date(cachedAuth.expireDate)) &&
        !force
      ) {
        return cachedAuth;
      }
      const newAuth = await getGettyAuth();
      newAuth.data.expireDate = addSeconds(
        new Date(),
        parseInt(newAuth.data.expiresIn),
      );
      setAuth(newAuth.data);
      return newAuth.data;
    } catch (e) {
      console.error(e);
    }
  }

  const [purchaseHistory, dispatchPurchaseHistory] = useReducer(
    (state, action) => {
      if (!action.data) {
        return state;
      }
      if (action.type === 'PUSH') {
        return {
          ...state,
          data: [...state.data, ...action.data].filter(
            (thing, index, self) =>
              index ===
              self.findIndex(
                (t) => t.place === thing.place && t.id === thing.id,
              ),
          ),
        };
      }
      if (action.type === 'SET_CURRENT_PAGE') {
        return {
          ...state,
          page: action.data,
        };
      }
      if (action.type === 'SET_TOTAL_PAGES') {
        return {
          ...state,
          total: action.data,
        };
      }
      return [...state];
    },
    {
      page: 1,
      total: 0,
      data: [],
    },
  );
  const [search, dispatchSearch] = useReducer(
    (state, action) => {
      if (action.type === 'PUSH') {
        return {
          ...state,
          data: [...state.data, ...action.data].filter(
            (thing, index, self) =>
              index ===
              self.findIndex(
                (t) => t.place === thing.place && t.id === thing.id,
              ),
          ),
        };
      }
      if (action.type === 'RESET') {
        return {
          ...state,
          page: 1,
          total: 0,
          count: 0,
          data: [],
          query: '',
        };
      }
      if (action.type === 'SET_CURRENT_PAGE') {
        return {
          ...state,
          page: action.data,
        };
      }
      if (action.type === 'SET_QUERY') {
        return {
          ...state,
          query: action.data,
        };
      }
      if (action.type === 'SET_COUNT') {
        return {
          ...state,
          count: action.data,
        };
      }
      if (action.type === 'SET_TOTAL_PAGES') {
        return {
          ...state,
          total: action.data,
        };
      }
      return { ...state };
    },
    {
      page: 1,
      total: 0,
      count: 0,
      data: [],
      query: '',
    },
  );
  const [downloadHistory, dispatchDownloadHistory] = useReducer(
    (state, action) => {
      if (action.type === 'PUSH') {
        return {
          ...state,
          data: [...state.data, ...action.data].filter(
            (thing, index, self) =>
              index === self.findIndex((t) => t.id === thing.id),
          ),
        };
      }
      if (action.type === 'REPLACE') {
        return {
          ...state,
          data: action.data.filter(
            (thing, index, self) =>
              index === self.findIndex((t) => t.id === thing.id),
          ),
        };
      }
      if (action.type === 'RESET') {
        return {
          ...state,
          page: 1,
          total: 0,
          data: [],
          isAtEnd: false,
        };
      }
      if (action.type === 'SET_AT_END') {
        return {
          ...state,
          isAtEnd: action.data,
        };
      }
      if (action.type === 'SET_CURRENT_PAGE') {
        return {
          ...state,
          page: action.data,
        };
      }
      if (action.type === 'SET_TOTAL_PAGES') {
        return {
          ...state,
          total: action.data,
        };
      }
      return [...state];
    },
    {
      page: 1,
      total: 0,
      data: [],
      isAtEnd: false,
    },
  );
  const userContext = useUserContext();

  const resetSearch = () => dispatchSearch({ type: 'RESET' });

  const getGettyDownloadLinks = async (id) => {
    const gettyAuth = await getAuth();
    const url = `https://api.gettyimages.com/v3/downloads/images/${id}?auto_download=false`;
    const options = {
      method: 'POST',
      headers: {
        'Api-Key': gettyAuth.apiKey,
        authorization: `Bearer ${gettyAuth.token}`,
      },
    };
    const res = await fetch(url, options);
    const json = await res.json();
    if (json.ErrorCode) {
      if (json.ErrorCode === 'NoAgreement') {
        return ToastService.createToast({
          message: 'Your Getty API Keys have expired',
        });
      }
      return ToastService.createToast({
        message:
          "Couldn't request resource from Getty. Please contact support!",
      });
    }
    return json.uri;
  };

  const save = (system, type = 'community') => {
    const data = {
      policy: {
        externalSystems: {
          ...system,
        },
      },
    };
    if (type === 'community') {
      return userContext.request.policy.update(
        userContext.spaces?.selected?.slug,
        data,
      );
    }
    if (type === 'user') {
      return userContext.request.policy.updateUserPolicy(
        userContext.spaces?.selected?.slug,
        data,
      );
    }
  };

  const getGettyAuth = async () => {
    return userContext.request.policy.getGettyAuth(
      userContext.spaces?.selected?.slug,
    );
  };

  const ValidateGettyKeys = async (apiKey, apiSecret) => {
    return userContext.request.policy.ValidateGettyKeys(
      apiKey,
      apiSecret,
      userContext.spaces?.selected?.slug,
    );
  };

  const searchBox = async (searchQuery, offset) => {
    return userContext.request.policy.searchBox(searchQuery, offset);
  };

  const getBoxFolder = async (id) => {
    return userContext.request.policy.getBoxFolder(id);
  };

  const getBoxFiles = async (parentId, offset = 0) => {
    return userContext.request.policy.getBoxFiles(parentId, offset);
  };

  const reportGettyUsage = async (gettyId) => getGettyDownloadLinks(gettyId);

  const getImagesMetaData = async (ids) => {
    const gettyAuth = await getAuth();
    const url = `https://api.gettyimages.com/v3/images?ids=${ids}`;
    const options = {
      headers: {
        'Api-Key': gettyAuth.apiKey,
        authorization: `Bearer ${gettyAuth.token}`,
      },
    };
    const res = await fetch(url, options);
    const { images } = await res.json();
    return images;
  };

  const getDownloadHistory = async (forceDownloadHistory) => {
    const state = forceDownloadHistory || downloadHistory;
    if (!forceDownloadHistory && (state.isAtEnd || isLoading)) {
      return;
    }
    setIsLoading(true);
    const gettyAuth = await getAuth(forceDownloadHistory);
    if (!gettyAuth) return;
    const url = `https://api.gettyimages.com/v3/downloads?company_downloads=true&date_from=${dateFrom}&date_to=${dateTo}&&page_size=20&page=${state.page}`;
    const options = {
      headers: {
        'Api-Key': gettyAuth?.apiKey,
        authorization: `Bearer ${gettyAuth?.token}`,
      },
    };
    const res = await fetch(url, options);
    const result = await res.json();

    if (result.ErrorCode === 'InsufficientPermissions') {
      setIsLoading(false);
      if (!errorShown) {
        ToastService.createToast({
          message: result.ErrorMessage,
        });
        setErrorShown(true);
      }
      dispatchDownloadHistory({ type: 'RESET' });
      return setIsLoading(false);
    }
    if (result.ErrorCode) {
      ToastService.createToast({
        message: 'Something went wrong. Please refresh the page.',
      });
      dispatchDownloadHistory({ type: 'RESET' });
      return setIsLoading(false);
    }

    const { downloads, result_count } = result;

    if (
      !forceDownloadHistory &&
      downloads.length &&
      !downloads.filter(
        (item) =>
          !downloadHistory.data.find(
            (historyItem) => historyItem.id === item.id,
          ),
      )?.length
    ) {
      return getDownloadHistory({ page: state.page + 1 });
    }

    if (state.page === 1) {
      dispatchDownloadHistory({
        type: 'SET_TOTAL_PAGES',
        data: Math.round(result_count / 20),
      });
    }

    dispatchDownloadHistory({ type: 'SET_CURRENT_PAGE', data: state.page + 1 });

    if (!downloads.length || downloads.length < 20) {
      dispatchDownloadHistory({ type: 'SET_AT_END', data: true });
    }

    const ids = downloads?.map((image) => image.id).join(',') ?? [];
    const metadata = await getImagesMetaData(ids);

    let data =
      downloads?.map((image) => {
        const data = { ...image };
        const meta = metadata.find((data) => data.id === image.id);
        data.metadata = meta;
        data.original_date = data.date_downloaded;
        const [date] = image.date_downloaded.split('T');
        data.date_downloaded = format(new Date(date), 'yyyy-MM-dd');
        return data;
      }) ?? [];
    let imported =
      (await Media.importCheckReferences(
        'getty-images',
        downloads?.map((image) => image.id),
      )) ?? [];
    imported = imported.map((i) => i.ref);
    imported = [...new Set(imported)];
    data = data.map((image) => {
      image.isImported = imported.includes(image.id);
      return image;
    });
    setIsLoading(false);
    dispatchDownloadHistory({
      type: forceDownloadHistory?.data ? 'REPLACE' : 'PUSH',
      data,
    });
  };

  const gettySearch = async (phrase, skip) => {
    if (!skip && search.page !== 1 && search.page > search.total) {
      return;
    }
    if (skip) {
      search.page = 1;
    }
    setIsLoading(true);
    setCurrentSearchPhrase(phrase);
    const gettyAuth = await getAuth();
    const url = `https://api.gettyimages.com/v3/search/images/creative?exclude_nudity=true&phrase=${phrase}&page_size=40&page=${search.page}`;
    const options = {
      headers: {
        'Api-Key': gettyAuth?.apiKey,
        authorization: `Bearer ${gettyAuth?.token}`,
        'accept-language': 'en-US',
      },
    };
    const res = await fetch(url, options);
    const result = await res.json();

    if (result.ErrorCode === 'InsufficientPermissions') {
      setIsLoading(false);
      return ToastService.createToast({
        message: result.ErrorMessage,
      });
    }
    if (result.ErrorCode) {
      setIsLoading(false);
      return ToastService.createToast({
        message: 'Something went wrong. Please refresh the page.',
      });
    }

    const { images, result_count } = result;

    if (search.page === 1) {
      dispatchSearch({
        type: 'SET_TOTAL_PAGES',
        data: Math.round(result_count / 40),
      });
    }

    dispatchSearch({ type: 'SET_COUNT', data: result_count });

    dispatchSearch({ type: 'SET_CURRENT_PAGE', data: search.page + 1 });

    dispatchSearch({ type: 'SET_QUERY', data: phrase });

    const imagesData = images || [];

    const ids = imagesData.map((image) => image.id).join(',');
    const metadata = await getImagesMetaData(ids);

    let data = imagesData.map((image) => {
      const data = { ...image };
      const meta = metadata.find((data) => data.id === image.id);
      data.metadata = meta;
      return data;
    });
    let imported = await Media.importCheckReferences(
      'getty-images',
      imagesData.map((image) => image.id),
    );
    imported = imported.map((i) => i.ref);
    imported = [...new Set(imported)];
    data = data.map((image) => {
      image.isImported = imported.includes(image.id);
      return image;
    });
    dispatchSearch({ type: 'PUSH', data });
    setIsLoading(false);
  };

  const getPurchaseHistory = async () => {
    if (
      purchaseHistory.page !== 1 &&
      purchaseHistory.page > purchaseHistory.total
    ) {
      return;
    }
    const gettyAuth = await getAuth();
    const url = `https://api.gettyimages.com/v3/purchased-assets?date_from=${dateFrom}&date_to=${dateTo}&&page_size=20&page=${purchaseHistory.page}`;
    const options = {
      headers: {
        'Api-Key': gettyAuth.apiKey,
        authorization: `Bearer ${gettyAuth.token}`,
      },
    };
    const res = await fetch(url, options);
    const { previous_purchases, result_count } = await res.json();

    if (purchaseHistory.page === 1) {
      dispatchPurchaseHistory({
        type: 'SET_TOTAL_PAGES',
        data: Math.round(result_count / 20),
      });
    }

    dispatchPurchaseHistory({
      type: 'SET_CURRENT_PAGE',
      data: purchaseHistory.page + 1,
    });

    const ids = previous_purchases.map((image) => image.id).join(',');
    const metadata = await getImagesMetaData(ids);

    let data = previous_purchases.map((image) => {
      const data = { ...image };
      const meta = metadata.find((data) => data.id === image.id);
      data.metadata = meta;
      return data;
    });
    let imported = await Media.importCheckReferences(
      'getty-images',
      previous_purchases.map((image) => image.id),
    );
    imported = imported.map((i) => i.ref);
    imported = [...new Set(imported)];
    data = data.map((image) => {
      image.isImported = imported.includes(image.id);
      return image;
    });
    dispatchPurchaseHistory({ type: 'PUSH', data });
  };

  function Shutterstock() {
    this.getTokenFromCode = async (code) => {
      try {
        const result =
          await userContext.request.policy.getShutterstockTokenFromCode(code);
        const response = await fetch(`${API_BASE}/v2/user/subscriptions`, {
          headers: { Authorization: `Bearer ${result.data.access_token}` },
        });
        const { data: subscriptions } = await response.json();
        const userResponse = await fetch(`${API_BASE}/v2/user`, {
          headers: { Authorization: `Bearer ${result.data.access_token}` },
        });
        const customer = await userResponse.json();
        const adobe = {
          accessToken: result.data.access_token,
          subscription: subscriptions[0],
          customer,
        };
        localStorageWrapper.setItem('shutterstock', JSON.stringify(adobe));
      } catch (e) {
        console.error(e);
        return ToastService.createToast({
          message: 'Login failed. Please try again.',
        });
      }
    };

    this.getToken = async () => {
      return JSON.parse(localStorageWrapper.getItem('shutterstock'))
        .accessToken;
    };

    this.getCollections = async () => {
      const token = await this.getToken();
      const imageResponse = await fetch(`${API_BASE}/v2/images/collections`, {
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
      const images = await imageResponse.json();
      const videoResponse = await fetch(`${API_BASE}/v2/videos/collections`, {
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
      const videos = await videoResponse.json();
      const audioResponse = await fetch(`${API_BASE}/v2/audio/collections`, {
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
      // const audio = await audioResponse.json();
      const result = {
        data: [
          ...images.data.map((col) => ({ ...col, type: 'images' })),
          ...videos.data.map((col) => ({ ...col, type: 'videos' })),
          // ...audio.data.map(col => ({ ...col, type: 'audio' })),
        ],
      };
      return result;
    };

    this.getCollectionImages = async (id, type) => {
      const token = await this.getToken();
      const response = await fetch(
        `${API_BASE}/v2/${type}/collections/${id}/items`,
        {
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`,
          },
        },
      );
      const result = await response.json();
      if (!result.data) return [];
      const imageIds = result.data.map((image) => image.id);
      const promises = imageIds.map((id) =>
        fetch(`${API_BASE}/v2/${type}/${id}`, {
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }),
      );
      const promisesResponses = await Promise.all(promises);
      const promisesResults = await Promise.all(
        promisesResponses.map((chain) => chain.json()),
      );
      // eslint-disable-next-line no-restricted-syntax
      let finalResult = null;
      if (promisesResults) {
        finalResult = promisesResults.map((image) =>
          IntegrationHelpers.createFileObject(
            image.id,
            image.id,
            image.id,
            'file',
            null,
            image.assets?.large_thumb?.url ||
              image.assets?.huge_thumb?.url ||
              image.assets?.small_thumb?.url ||
              image.assets?.thumb_jpg?.url ||
              '',
            image.id,
            { originalFilename: image.original_filename },
            image.description,
          ),
        );
      }

      return finalResult;
    };

    this.getImageDetails = async (id) => {
      const token = await this.getToken();
      const response = await fetch(`${API_BASE}/v2/images/${id}`, {
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
      const result = await response.json();
      return result;
    };

    this.includeImageData = async (licenses) => {
      const token = await this.getToken();
      const ids = licenses.map((license) => license.image.id);
      const promises = ids.map((id) =>
        fetch(`${API_BASE}/v2/images/${id}`, {
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }),
      );
      const promisesResponses = await Promise.all(promises);
      const promisesResults = await Promise.all(
        promisesResponses.map((chain) => chain.json()),
      );
      const result = licenses.map((license) => {
        const image = promisesResults.find(
          (image) => license.image.id === image.id,
        );
        return {
          ...license,
          image,
        };
      });
      return result;
    };

    this.history = async (offset = 0) => {
      const page = offset / 64 + 1;
      const token = await this.getToken();
      const URL = `${API_BASE}/v2/images/licenses?page=${page}&per_page=64`;
      const OPTIONS = {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      };

      let response;
      try {
        response = await fetch(URL, OPTIONS);
      } catch {
        // this.sessionExpired();
      }
      if (!response.ok) {
        // return this.sessionExpired();
      }
      const result = await response.json();
      const licenses = await this.includeImageData(result.data);
      let data = licenses.map((license) =>
        IntegrationHelpers.createFileObject(
          license.image.id,
          license.image.id,
          license.image.id,
          'file',
          null,
          license.image.assets.large_thumb.url,
          license.image.id,
          {
            isLicensed: true,
            licenseId: license.id,
            originalFilename: license.image.original_filename,
          },
          license.image.description,
        ),
      );
      let imported =
        (await Media.importCheckReferences(
          'shutterstock',
          data?.map((image) => image.external.identifier),
        )) || [];
      imported = imported.map((i) => i.import.reference);
      imported = [...new Set(imported)];
      data = data.map((image) => {
        image.media = {
          isImported: imported.includes(image.external.identifier.toString()),
        };
        return image;
      });
      const returnObj = {
        data,
        count: result.total_count,
        offset,
        hasMore: result.total_count > offset && result.total_count > 64,
      };
      return returnObj;
    };

    this.search = async (value, offset = 0) => {
      const page = offset / 64 + 1;
      const token = await this.getToken();
      const URL = `${API_BASE}/v2/images/search?query=${value}&page=${page}&per_page=64`;
      const OPTIONS = {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      let response;
      try {
        response = await fetch(URL, OPTIONS);
      } catch (e) {
        console.error(e);
        this.sessionExpired();
      }
      if (!response.ok) {
        return this.sessionExpired();
      }
      const result = await response.json();
      let data = result.data.map((file) =>
        IntegrationHelpers.createFileObject(
          file.id,
          file.id,
          file.id,
          'file',
          null,
          file.assets.large_thumb.url,
          file.id,
          {},
          file.description,
        ),
      );
      let imported =
        (await Media.importCheckReferences(
          'shutterstock',
          data?.map((image) => image.external.identifier),
        )) || [];
      imported = imported.map((i) => i.import.reference);
      imported = [...new Set(imported)];
      data = data.map((image) => {
        image.media = {
          isImported: imported.includes(image.external.identifier.toString()),
        };
        return image;
      });
      const returnObj = {
        data,
        count: result.total_count,
        offset,
        hasMore: result.total_count > offset && result.total_count > 64,
        query: value,
      };
      return returnObj;
    };

    this.getAssetURL = async (id, inHistory) => {
      const data = {};
      /* if (!inHistory) {
        const licensingInfo = await this.getLicensingInfo(id);
        data.credits = licensingInfo.available_entitlement.quota;
        data.canLicense = licensingInfo.purchase_options.state === 'possible';
        data.licensingMessage = licensingInfo.purchase_options.message;
      }*/
      if (!inHistory) {
        const assetURL = await this.licenseAsset(id);
        data.url = assetURL;
      } else if (inHistory) {
        const assetURL = await this.getAlreadyDownloadedURL(id);
        data.url = assetURL;
      }
      return data;
    };
    this.licenseAsset = async (id) => {
      const token = await this.getToken();
      const { subscription, customer } = JSON.parse(
        localStorageWrapper.getItem('shutterstock'),
      );
      const URL = `${API_BASE}/v2/images/licenses?subscription_id=${subscription.id}`;
      const OPTIONS = {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          images: [
            {
              image_id: id,
              metadata: {
                customer_id: customer.id,
                purchase_order: id,
              },
            },
          ],
        }),
      };
      const response = await fetch(URL, OPTIONS);
      const result = await response.json();
      return result.data[0].download.url;
    };
    this.checkLicense = async (id) => {
      const token = await this.getToken();
      const URL = `${API_BASE}/v2/images/licenses?image_id=${id}`;
      const OPTIONS = {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: 'application/json',
        },
      };
      const response = await fetch(URL, OPTIONS);
      const result = await response.json();
      return !!result.data.length;
    };
    this.getAlreadyDownloadedURL = async (id) => {
      const token = await this.getToken();
      const URL = `${API_BASE}/v2/images/licenses/${id}/downloads`;
      const OPTIONS = {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        body: JSON.stringify({
          size: 'huge',
        }),
      };
      const response = await fetch(URL, OPTIONS);
      const result = await response.json();
      return result.url;
    };
  }

  function AdobeStock() {
    this.getTokenFromCode = async (code) => {
      try {
        const result =
          await userContext.request.policy.getAdobeTokenFromCode(code);
        const adobe = {
          accessToken: result.data.access_token,
          refreshToken: result.data.id_token,
          expires: Date.now() + result.data.expires_in * 1000,
        };
        localStorageWrapper.setItem('adobe', JSON.stringify(adobe));
      } catch (e) {
        console.error(e);
        return ToastService.createToast({
          message: 'Login failed. Please try again.',
        });
      }
    };
    this.sessionExpired = () => {
      localStorageWrapper.removeItem('adobe');
      window.dispatchEvent(new Event('storage'));
      return ToastService.createToast({
        message: 'Session Expired',
      });
    };
    this.getToken = async () => {
      const adobe = JSON.parse(localStorageWrapper.getItem('adobe'));
      if (Date.now() > adobe.expires) {
        this.sessionExpired();
      }
      return adobe.accessToken;
    };
    this.getLicensingInfo = async (stockId) => {
      const token = await this.getToken();
      const URL = `https://stock.adobe.io/Rest/Libraries/1/Member/Profile?content_id=${stockId}&license=Standard&locale=en_US`;
      const OPTIONS = {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'x-api-key': window.pickit.keys.ADOBE_CLIENT_ID,
          'X-Product': 'Pickit App',
        },
      };

      let response;
      try {
        response = await fetch(URL, OPTIONS);
      } catch {
        this.sessionExpired();
      }
      if (!response.ok) {
        return this.sessionExpired();
      }
      return response.json();
    };
    this.getDownloadURL = async (stockId) => {
      const token = await this.getToken();
      const URL = `https://stock.adobe.io/Rest/Libraries/1/Content/License?content_id=${stockId}&license=Standard`;
      const OPTIONS = {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'x-api-key': window.pickit.keys.ADOBE_CLIENT_ID,
          'X-Product': 'Pickit App',
        },
      };

      let response;
      try {
        response = await fetch(URL, OPTIONS);
      } catch {
        this.sessionExpired();
      }
      if (!response.ok) {
        return this.sessionExpired();
      }
      const result = await response.json();
      const assetURL = result.contents[stockId].purchase_details.url;
      return `${assetURL}?token=${token}`;
    };
    this.getHistoryURL = async (id) => {
      const token = await this.getToken();
      /* As of time of writing there is no way in the Adobe Stock to get a specific asset from the Licensed History.
      Therefore the entire history needs to be fetched to find a specific asset. 
      */
      let offset = 0;
      let assetURL = '';
      let hasMore = true;
      while (hasMore) {
        const URL = `https://stock.adobe.io/Rest/Libraries/1/Member/LicenseHistory?search_parameters[]=""&search_parameters[limit]=64&search_parameters[offset]=${offset}&all=true`;
        const OPTIONS = {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
            'x-api-key': window.pickit.keys.ADOBE_CLIENT_ID,
            'X-Product': 'Pickit App',
          },
        };

        let response;

        try {
          response = await fetch(URL, OPTIONS);
        } catch {
          this.sessionExpired();
        }
        if (!response.ok) {
          return this.sessionExpired();
        }
        const result = await response.json();

        const foundIndex = result.files.findIndex((file) => file.id === id);
        const found = foundIndex >= 0;

        if (found) {
          assetURL = result.files[foundIndex].download_url;
          break;
        }

        if (result.nb_results < offset) {
          hasMore = false;
        } else {
          offset += 64;
        }
      }

      return `${assetURL}?token=${token}`;
    };
    this.getAssetURL = async (id, inHistory) => {
      const data = {};
      if (!inHistory) {
        const licensingInfo = await this.getLicensingInfo(id);
        data.credits = licensingInfo.available_entitlement.quota;
        data.canLicense = licensingInfo.purchase_options.state === 'possible';
        data.licensingMessage = licensingInfo.purchase_options.message;
      }
      if (data.canLicense && !inHistory) {
        const assetURL = await this.getDownloadURL(id);
        data.url = assetURL;
      } else if (inHistory) {
        const assetURL = await this.getDownloadURL(id);
        data.url = assetURL;
      }
      return data;
    };
    this.search = async (value, offset = 0) => {
      const token = await this.getToken();
      const URL = `https://stock.adobe.io/Rest/Media/1/Search/Files?search_parameters[words]=${value}&search_parameters[limit]=64&search_parameters[offset]=${offset}&result_columns[]=is_licensed&result_columns[]=id&result_columns[]=title&result_columns[]=thumbnail_url&result_columns[]=stock_id&result_columns[]=nb_results&result_columns[]=content_type&search_parameters[filters][content_type:photo]=1&search_parameters[filters][content_type:video]=0&search_parameters[order]=nb_downloads`;
      const OPTIONS = {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'x-api-key': window.pickit.keys.ADOBE_CLIENT_ID,
          'X-Product': 'Pickit App',
        },
      };

      let response;
      try {
        response = await fetch(URL, OPTIONS);
      } catch {
        this.sessionExpired();
      }
      if (!response.ok) {
        return this.sessionExpired();
      }
      const result = await response.json();
      let data = result.files
        .filter((file) => file.content_type.includes('image'))
        .map((file) =>
          IntegrationHelpers.createFileObject(
            file.id,
            file.title,
            file.stock_id,
            'file',
            null,
            file.thumbnail_url,
            file.id,
            {
              license: file.is_licensed,
            },
          ),
        );
      let imported =
        (await Media.importCheckReferences(
          'adobe-stock',
          data?.map((image) => image.external.identifier),
        )) || [];
      imported = imported.map((i) => i.import.reference);
      imported = [...new Set(imported)];
      data = data.map((image) => {
        image.media = {
          isImported: imported.includes(image.external.identifier.toString()),
        };
        return image;
      });
      const returnObj = {
        data,
        count:
          result.nb_results -
          result.files.filter((file) => !file.content_type.includes('image'))
            .length,
        offset,
        hasMore: result.nb_results > offset && result.nb_results > 64,
        query: value,
      };
      return returnObj;
    };

    this.history = async (offset = 0) => {
      const token = await this.getToken();
      const URL = `https://stock.adobe.io/Rest/Libraries/1/Member/LicenseHistory?search_parameters[]=""&search_parameters[limit]=64&search_parameters[offset]=${offset}&result_columns[]=thumbnail_220_url&search_parameters[filters][content_type:photo]=1&search_parameters[filters][content_type:video]=0&all=true`;
      const OPTIONS = {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'x-api-key': window.pickit.keys.ADOBE_CLIENT_ID,
          'X-Product': 'Pickit App',
        },
      };

      let response;
      try {
        response = await fetch(URL, OPTIONS);
      } catch {
        this.sessionExpired();
      }
      if (!response.ok) {
        return this.sessionExpired();
      }
      const result = await response.json();
      let data = result.files
        .filter((file) => file.content_type.includes('image'))
        .map((file) =>
          IntegrationHelpers.createFileObject(
            file.id,
            file.title,
            file.stock_id,
            'file',
            null,
            file.thumbnail_220_url,
            file.id,
            {
              license: 'Standard',
            },
          ),
        );
      let imported =
        (await Media.importCheckReferences(
          'adobe-stock',
          data?.map((image) => image.external.identifier),
        )) || [];
      imported = imported.map((i) => i.import.reference);
      imported = [...new Set(imported)];
      data = data.map((image) => {
        image.media = {
          isImported: imported.includes(image.external.identifier.toString()),
        };
        return image;
      });
      const returnObj = {
        data,
        count: result.nb_results,
        offset,
        hasMore: result.nb_results > offset && result.nb_results > 64,
      };
      return returnObj;
    };
  }

  return (
    <ExternalSystemsContext.Provider
      value={{
        save,
        HubspotExport,
        HubspotImport,
        Box,
        BoxAuth,
        AdobeStock: new AdobeStock(),
        Shutterstock: new Shutterstock(),
        getDownloadHistory,
        getGettyDownloadLinks,
        dateFrom,
        dateTo,
        gettySearch,
        currentSearchPhrase,
        search,
        getAuth,
        reportGettyUsage,
        legaceIntegrations: new legacyIntegrations(),
        setDateFrom,
        isLoading,
        setDateTo,
        downloadHistory,
        purchaseHistory,
        getPurchaseHistory,
        resetSearch,
        ValidateGettyKeys,
        Sharepoint,
        SharepointExport,
        DropboxExport,
        MicrosoftGraph,
        Dropbox,
        DropboxDEPRICATED,
        DropboxApiDEPRICATED,
        DropboxAuth,
        NetworkFileSystem,
        GoogleDrive,
        Google,
        ShutterstockHook,
        Adobe,
        AdobeCCL,
      }}
    >
      {children}
    </ExternalSystemsContext.Provider>
  );
};
