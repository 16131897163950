import ApiService from './_ApiService';
import { Storage } from 'services';

class Insight extends ApiService {
  communitySlug = '';
  userId = '';
  useStorage = true;

  constructor(props) {
    super(props);
    if (this.useStorage && window.pickit && window.pickit.community) {
      this.setSlug(window.pickit.community);
    }

    if (this.useStorage && Storage.getItem('communitySlug')) {
      this.setSlug(Storage.getItem('communitySlug'));
    }
  }

  async getSingleSearchResult(params) {
    return this.request(
      '/search/contributions/' + this.stringify(params),
      undefined,
      undefined,
      {
        isPublic: true,
      }
    ).then(this.handleJSONResponse);
  }
  async getSingleCommunitySearchResult(params) {
    return this.request(
      '/search/contributions/' + this.stringify(params),
      undefined,
      this.communitySlug
    ).then(this.handleJSONResponse);
  }
  async getSingleContribution(slug) {
    return this.request('/contributions/' + slug, undefined, undefined, {
      isPublic: true,
    }).then(this.handleJSONResponse);
  }
  async getSingleCommunityContribution(slug) {
    return this.request(
      '/contributions/' + slug,
      undefined,
      this.communitySlug
    ).then(this.handleJSONResponse);
  }
  async getSingleCollectionSearchResult(collection_slug, params) {
    return this.request(
      `/users/${this.userId}/collection/${collection_slug}/` +
        this.stringify(params)
    ).then(this.handleJSONResponse);
  }
  async getSingleCommunityCollectionSearchResult(collection_slug, params) {
    return this.request(
      `/users/${this.userId}/collection/${collection_slug}/` +
        this.stringify(params),
      undefined,
      this.communitySlug
    ).then(this.handleJSONResponse);
  }
  async getUnusedContributions(params) {
    return this.request(
      '/search/contributions/' + this.stringify(this.addContentFilter(params)),
      undefined,
      this.communitySlug
    ).then(this.handleJSONResponse);
  }
  async getPublicCollections(params) {
    return this.request('/search/collections' + this.stringify(params)).then(
      this.handleJSONResponse
    );
  }
  async getCollections(params) {
    return this.request(
      '/search/collections' + this.stringify(params),
      undefined,
      this.communitySlug
    ).then(this.handleJSONResponse);
  }
  async getFeaturedCollections(params) {
    return this.request(
      '/collections/explore/featured/source/ibc/' + this.stringify(params),
      undefined,
      this.communitySlug
    ).then(this.handleJSONResponse);
  }
  async getHeroCollections(params) {
    return this.request(
      '/collections/explore/hero/source/ibc/' + this.stringify(params),
      undefined,
      this.communitySlug
    ).then(this.handleJSONResponse);
  }
  async getCollectionGroups() {
    return this.request(
      '/collection_groups/',
      undefined,
      this.communitySlug
    ).then(this.handleJSONResponse);
  }
  async getSearches(params, props) {
    return this.request(
      '/statistic/counter/' + this.stringify(params),
      props,
      this.communitySlug,
      undefined,
      undefined,
      'POST'
    ).then(this.handleJSONResponse);
  }
  async getContributions(params, props) {
    return this.request(
      '/statistic/contributions/' + this.stringify(params),
      props,
      this.communitySlug,
      undefined,
      undefined,
      'POST'
    ).then(this.handleJSONResponse);
  }
  async updateContribution(params, props, slug) {
    return this.request(
      `/contributions/${slug}/update`,
      props,
      this.communitySlug,
      undefined,
      undefined,
      'POST'
    ).then(this.handleJSONResponse);
  }
  async featureCollection(params, props, slug) {
    return this.request(
      `/collections/${slug}/feature/`,
      props,
      this.communitySlug,
      undefined,
      undefined,
      'POST'
    );
  }
  async unFeatureCollection(params, props, slug) {
    return this.request(
      `/collections/${slug}/unfeature/`,
      props,
      this.communitySlug,
      undefined,
      undefined,
      'POST'
    );
  }
  async makeHeroCollection(params, props, slug) {
    return this.request(
      `/collections/${slug}/hero/`,
      props,
      this.communitySlug,
      undefined,
      undefined,
      'POST'
    );
  }
  async removeHeroCollection(params, props, slug) {
    return this.request(
      `/collections/${slug}/simple/`,
      props,
      this.communitySlug,
      undefined,
      undefined,
      'POST'
    );
  }
  async changeCollectionGroup(params, props, slug) {
    return this.request(
      `/collections/${slug}/simple/`,
      props,
      this.communitySlug,
      undefined,
      undefined,
      'POST'
    );
  }
  async removeCollectionGroup(params, props, slug) {
    return this.request(
      `/collection_groups/${slug}/collections/`,
      props,
      this.communitySlug,
      undefined,
      undefined,
      'DELETE'
    );
  }
}

export default Insight;
