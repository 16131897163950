import {
  Alert,
  AlertProps,
  Divider,
  Group,
  Paper,
  PaperProps,
} from '@mantine/core';
import { ReactNode } from 'react';
import PixiIcon, { PixiIconName } from '../Icon';
import PixiButton from '../Button';
import PixiText from '../Text';

export default function ErrorMessage({
  title,
  message,
  icon,
  tryAgain,
  ...rest
}: {
  title: ReactNode;
  message?: ReactNode;
  tryAgain?: () => Promise<void> | void;
  icon?: PixiIconName | null;
} & Omit<Partial<PaperProps>, 'icon'>) {
  return (
    <Paper color="red" bg="red.0" p="md" c="red" {...rest}>
      <Group gap="xs" mb={message ? 'sm' : undefined} fw="500" fz="md">
        {icon === null ? undefined : (
          <PixiIcon name={icon || 'triangle-exclamation'} variant="filled" />
        )}
        {title}
      </Group>
      {message ? (
        <PixiText size="xs" color="red">
          {message}
        </PixiText>
      ) : undefined}
      {tryAgain && (
        <Group mt="md" color="red">
          <PixiButton
            onClick={async () => {
              await tryAgain();
            }}
            color="red"
            leftSection={<PixiIcon name="arrow-rotate-right" />}
          >
            Try again
          </PixiButton>
        </Group>
      )}
    </Paper>
  );
}
