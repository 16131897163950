import React, { useEffect, useRef, useMemo } from 'react';
import './style.scss';

export default function Progress(props) {
  const percentage =
    props.percentage !== undefined
      ? props.percentage
      : props.max
      ? (props.current / props.max) * 100
      : 0;

  const displayPercentage = props.noRounding
    ? percentage
    : Math.ceil(percentage);

  const progress = useRef(null);
  const parent = useRef(null);

  useEffect(() => {
    if (progress.current && parent.current) {
      if (props.infinite) {
        progress.current.style.transition = 'none';
        progress.current.style.width = `${
          parent.current.getBoundingClientRect().width -
          (parent.current.getBoundingClientRect().width % 28)
        }px`;
        progress.current.style.margin = 'auto';
      } else {
        progress.current.style.width = `${displayPercentage}%`;
        progress.current.style.transition = '';
        progress.current.style.margin = '';
      }
    }
  }, [props.infinite, displayPercentage]);

  const currentProgress = useMemo(() => {
    const style = {
      ...(props.currentStyle ?? {}),
    };
    if (props.countdown) {
      style.animationDuration = `${props.countdownDuration ?? props.max}s`;
    } else {
      style.maxWidth = `${props.infinite ? 100 : displayPercentage}%`;
    }

    return (
      <div
        className={`Pixi Pixi__Progress__bar__current${
          props.infinite ? ' Pixi__Progress__bar__current--infinite' : ''
        }${props.countdown ? ' Pixi__Progress__bar__current--countdown' : ''}`}
        style={style}
        ref={progress}
      >
        <span>
          {props.currentLabel ||
            (props.currentLabelPercentage &&
              `${Math.floor(displayPercentage)}%`)}
        </span>
      </div>
    );
  }, [
    displayPercentage,
    props.infinite,
    props.countdown,
    props.currentStyle,
    props.countdownDuration,
    props.currentLabel,
    props.currentLabelPercentage,
  ]);

  return (
    <div
      className={[
        'Pixi Pixi__Progress',
        ...(props.white ? ['Pixi__Progress--white'] : []),
      ].join(' ')}
      style={props.style}
    >
      {props.labelPosition === 'top' && (
        <span style={{ ...(props.maxLabelStyle ?? {}) }}>{props.maxLabel}</span>
      )}
      <div
        className="Pixi Pixi__Progress__bar"
        style={props.barStyle}
        ref={parent}
      >
        {currentProgress}
        {!props.labelPosition && (
          <span
            style={{ ...(props.maxLabelStyle ?? {}) }}
            className="Pixi__Progress__bar__current__label"
          >
            {props.maxLabel}
          </span>
        )}
      </div>
      {props.labelPosition === 'bottom' && (
        <span style={{ ...(props.maxLabelStyle ?? {}) }}>{props.maxLabel}</span>
      )}
    </div>
  );
}
