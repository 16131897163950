import objectPath from 'object-path';
import { useUserContext } from './useUserContext';
// import "./style.scss";

export default function useCloudStorage() {
  const User = useUserContext();

  /**
   * Set cloud storage.
   * @param {string} path String by dot notation. Example: show.public_images.card
   * @param {*} value Any value.
   */
  function set(path, value) {
    User.setCloudStorage(path, value);
  }

  /**
   * Get cloud storage.
   * @param {string} path String by dot notation. Example: show.public_images.card
   */
  function get(path) {
    return objectPath.get(User.cloudStorage, path);
  }
  return {
    set,
    get,
    data: User.cloudStorage,
  };
}
