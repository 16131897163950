import { Divider, Stack } from '@mantine/core';
import PixiButton from '@pixi/elements/Button';
import { useAssetEditPanel } from '../../context';
import { ReactNode, useState } from 'react';
import FontForm from 'views/_Manage/BrandAssets/parts/forms/FontForm';

export default function AssetSpecificActions() {
  const { file } = useAssetEditPanel();
  const [isFontFormOpen, setIsFontFormOpen] = useState(false);

  let actions: ReactNode = undefined;

  if (file?.data?.type === 'font-family') {
    actions = (
      <>
        <FontForm
          metadata={file}
          isOpen={isFontFormOpen}
          onClose={() => {
            setIsFontFormOpen(false);
          }}
        />
        <PixiButton
          onClick={() => {
            setIsFontFormOpen(true);
          }}
          variant="light"
        >
          Edit font family
        </PixiButton>
      </>
    );
  }

  if (!actions) {
    return <></>;
  }
  return (
    <>
      <Divider />
      <Stack p="md">{actions}</Stack>
    </>
  );
}
