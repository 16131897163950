import { inAdobeAddon, inOffice, isNative } from './platform';

export const getDateString = (time) => {
  const monthName = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  const day = time.getDate();
  const month = monthName[time.getMonth()];
  const year = time.getFullYear();
  return `${month} ${day}, ${year}`;
};

export async function fetchWithTimeout(resource, options = {}) {
  const { timeout = 8000 } = options;

  const controller = new AbortController();
  const id = setTimeout(() => controller.abort(), timeout);
  const response = await fetch(resource, {
    ...options,
    signal: controller.signal,
  });
  clearTimeout(id);
  return response;
}

// a little function to help us with reordering the result
export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

/* eslint-disable eqeqeq */
export function bytesToSize(bytes) {
  if (!bytes) return;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes == 0) return '0 Byte';
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  return `${Math.round(bytes / Math.pow(1024, i), 2)} ${sizes[i]}`;
}
/* eslint-enable eqeqeq */

/*
 * @param source - Object or Array to make deep clone of
 * @returns {object|array} Cloned array or object
 * @desc OBS does not work it is an Object or array that contains Symbol or function
 */
export const deepClone = (source) => JSON.parse(JSON.stringify(source));

const isObject = (obj) => obj && typeof obj === 'object';

/**
 * Immutable deep merge of objects.
 *
 * @param {...object} objects - Objects to merge
 * @returns {object} New object with merged key/values
 */
export const deepMerge = (...objects) => {
  return objects.reduce((prev, obj) => {
    Object.keys(obj).forEach((key) => {
      const pVal = prev[key];
      const oVal = obj[key];

      if (Array.isArray(pVal) && Array.isArray(oVal)) {
        prev[key] = pVal.concat(...oVal);
      } else if (isObject(pVal) && isObject(oVal)) {
        prev[key] = deepMerge(pVal, oVal);
      } else {
        prev[key] = oVal;
      }
    });

    return prev;
  }, {});
};

export function uniqueArray(array, uniqueField) {
  return array.filter(
    (thing, index, self) =>
      index ===
      self.findIndex((t) =>
        typeof uniqueField === 'function'
          ? uniqueField(t) === uniqueField(thing)
          : t[uniqueField] === thing[uniqueField],
      ),
  );
}

export const chunkArray = (array, chunk_size) =>
  Array(Math.ceil(array.length / chunk_size))
    .fill()
    .map((_, index) => index * chunk_size)
    .map((begin) => array.slice(begin, begin + chunk_size));

export function truncate(fullStr, strLen, separator, noBack) {
  try {
    if (fullStr.length <= strLen) return fullStr;

    separator = separator || '...';

    const sepLen = separator.length;
    const charsToShow = strLen - sepLen;
    if (noBack) {
      return fullStr.substr(0, charsToShow) + separator;
    }
    const frontChars = Math.ceil(charsToShow / 2);
    const backChars = Math.floor(charsToShow / 2);

    return (
      fullStr.substr(0, frontChars) +
      separator +
      fullStr.substr(fullStr.length - backChars)
    );
  } catch (e) {
    return fullStr;
  }
}

/* eslint-disable eqeqeq */
export function uniqueId() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0;
    const v = c == 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}
/* eslint-enable eqeqeq */

export function randomNumberString(length) {
  let result = '';
  const characters = '0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export function randomTextString(length) {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

/* eslint-disable eqeqeq */
export function randomString(len, an) {
  an = an && an.toLowerCase();
  let str = '';
  let i = 0;
  const min = an == 'a' ? 10 : 0;
  const max = an == 'n' ? 10 : 62;
  for (; i++ < len; ) {
    let r = (Math.random() * (max - min) + min) << 0;
    str += String.fromCharCode((r += r > 9 ? (r < 36 ? 55 : 61) : 48));
  }
  return str;
}
/* eslint-enable eqeqeq */

/* eslint-disable no-useless-escape */
export const emailRegex =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
/* eslint-enable no-useless-escape */

export function advancedReducerStorage(state, actions) {
  if (!Array.isArray(actions)) {
    actions = [actions];
  }
  for (let i = 0; i < actions.length; i++) {
    const action = actions[i];
    if (!state[action.store]) {
      state[action.store] = [];
    }
    if (action.type === 'replace' && !action.store) {
      state = action.data;
    } else if (action.type === 'replace') {
      state[action.store] = [...action.data];
    }
    if (action.type === 'push') {
      if (Array.isArray(action.data)) {
        state[action.store] = [...state[action.store], ...action.data];
      } else {
        state[action.store] = [...state[action.store], action.data];
      }
    }
    if (action.type === 'prepend') {
      if (Array.isArray(action.data)) {
        state[action.store] = [...action.data, ...state[action.store]];
      } else {
        state[action.store] = [action.data, ...state[action.store]];
      }
    }
    if (action.type === 'delete') {
      state[action.store] = state[action.store].filter(
        (file) => file[action.key] !== action.data[action.key],
      );
    }
    if (action.type === 'update') {
      const data = state[action.store].find(
        (doc) => doc[action.key] === action.data[action.key],
      );
      const dataIndex = state[action.store].findIndex(
        (doc) => doc[action.key] === action.data[action.key],
      );
      state[action.store][dataIndex] = {
        ...data,
        ...action.data,
      };
      state[action.store] = [...state[action.store]];
    }
  }
  return {
    ...state,
  };
}

export async function loadScript(url) {
  return new Promise((resolve, reject) => {
    (function (d, script) {
      script = d.createElement('script');
      script.type = 'text/javascript';
      script.async = true;
      script.onload = function () {
        resolve(true);
      };
      script.src = url;
      d.getElementsByTagName('head')[0].appendChild(script);
    })(document);
  });
}

export function deleteAllCookies() {
  const cookies = document.cookie.split(';');

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];
    const eqPos = cookie.indexOf('=');
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT`;
  }
}

export function mergeRefs(refs) {
  return (value) => {
    refs.forEach((ref) => {
      if (typeof ref === 'function') {
        ref(value);
      } else if (ref != null) {
        ref.current = value;
      }
    });
  };
}
export function openInNewTab(url) {
  if (inAdobeAddon()) {
    openFromAdobe(url);

    return;
  }
  if (isNative() && window.pickitNative?.openInBrowser) {
    window.pickitNative.openInBrowser(url);
    return;
  }
  const element = document.createElement('a');
  element.setAttribute('href', url);
  element.setAttribute('target', '_BLANK');

  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}
export async function downloadUrl(file, name) {
  if (inAdobeAddon()) {
    openFromAdobe(file, name);
    return;
  }
  if (inOffice()) {
    window.Office.context.ui.openBrowserWindow(file);
    return;
  }
  if (!name) {
    const element = document.createElement('a');
    element.setAttribute('href', file);
    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
    return;
  }
  const blob = await fetch(file).then(async (r) => {
    return await r.blob();
  });
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.style.display = 'none';
  a.href = url;
  a.download =
    decodeURIComponent(name) +
    (!name.includes('.') ? (blob.type?.includes('png') ? '.png' : '.jpg') : '');
  document.body.appendChild(a);
  a.click();
  window.URL.revokeObjectURL(url);
  // Remove "a" tag from the body
  a.remove();
}
export function downloadMultiple(files) {
  /**
   * Useless function. Downloading files one-by-one. Has non-cachable issue when we download files less than requested.
   * Please, use downloadBag.downloadFiles(...) instead
   * @param i
   */
  function download_next(i) {
    if (i >= files.length) {
      return;
    }
    const a = document.createElement('a');
    a.href = files[i].url;
    a.target = '_parent';
    // Use a.download if available, it prevents plugins from opening.
    if ('name' in a) {
      a.download = files[i].name;
    }
    // Add a to the doc for click to work.
    (document.body || document.documentElement).appendChild(a);
    if (a.click) {
      a.click(); // The click method is supported by most browsers.
    }
    // Delete the temporary link.
    a.parentNode.removeChild(a);
    // Download the next file with a small timeout. The timeout is necessary
    // for IE, which will otherwise only download the first file.
    setTimeout(function () {
      download_next(i + 1);
    }, 500);
  }
  // Initiate the first download.
  download_next(0);
}
export function downloadBase64(filename, text) {
  const element = document.createElement('a');
  element.setAttribute('href', encodeURIComponent(text));
  element.setAttribute('download', filename);

  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}

export function openLink(url, sameWindow = false) {
  if (inAdobeAddon()) {
    openFromAdobe(url);

    return;
  }
  if (isNative() && window.pickitNative?.openInBrowser) {
    window.pickitNative.openInBrowser(url);
    return;
  }
  try {
    const link = document.createElement('a');
    link.href = url;
    if (!sameWindow) {
      link.target = '_blank';
    }
    document.body.appendChild(link);
    link.click();
    link.remove();
    return true;
  } catch (e) {
    return false;
  }
}
export function googleMimetoExt(mimeType) {
  const map = {
    'application/vnd.google-apps.document': 'gdocs',
    'application/vnd.google-apps.spreadsheet': 'gsheets',
    'application/vnd.google-apps.presentation': 'gslides',
    'application/vnd.google-apps.script': 'gscript',
    'application/vnd.google-apps.shortcut': 'glnk',
  };
  return map[mimeType];
}
export function adobeMimetoExt(mimeType) {
  const type = mimeType.split('/')[1].split('+')[0];
  const map = {
    photoshop: 'psd',
    illustrator: 'ai',
  };
  return map[type] || type;
}

export function slugify(text) {
  return text
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(/\-\-+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, ''); // Trim - from end of text
}

export function arrayToCSV(objArray, name) {
  const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;

  const uniqueColumns = Object.assign({}, ...array);

  const newArray = array.map((element) => {
    const newArrayObject = {};
    Object.keys(uniqueColumns).forEach((columnKey) => {
      newArrayObject[columnKey] = element[columnKey] || '';
    });
    return newArrayObject;
  });

  const str = `${Object.keys(uniqueColumns)
    .map((value) => `"${value}"`)
    .join(';')}\r\n`;

  const BOM = decodeURIComponent('%ef%bb%bf');

  const CSVData =
    BOM +
    newArray.reduce((str, next) => {
      str += `${Object.values(next)
        .map((value) => `"${value}"`)
        .join(';')}\r\n`;

      return str;
    }, str);

  const blob = new Blob([CSVData], { type: 'text/csv;charset=utf-8;' });
  if (navigator.msSaveBlob) {
    // IE10 +
    navigator.msSaveBlob(blob, `${name}.csv`);
  } else {
    const link = document.createElement('a');
    if (link.download !== undefined) {
      // feature detection
      // Browsers that support HTML5 download attribute
      const url = URL.createObjectURL(blob);
      link.href = url;
      link.download = `${name}.csv`;
      link.click();
    }
  }
}

function fallbackCopyTextToClipboard(text) {
  const textArea = document.createElement('textarea');
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = '0';
  textArea.style.left = '0';
  textArea.style.position = 'fixed';

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  document.execCommand('copy');

  document.body.removeChild(textArea);
}
export function copyTextToClipboard(text) {
  try {
    if (!navigator.clipboard) {
      fallbackCopyTextToClipboard(text);
      return;
    }
    navigator.clipboard.writeText(text);
  } catch (e) {
    fallbackCopyTextToClipboard(text);
  }
}

export function isNumeric(str) {
  if (typeof str !== 'string') return false; // we only process strings!
  return (
    !isNaN(str) && !isNaN(parseFloat(str)) // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
  ); // ...and ensure strings of whitespace fail
}

export function fileIsPreviewable(file, ignorePreviews, noVideo, noAudio) {
  if (file?.url) {
    return fileIsPreviewable({ file }, ignorePreviews, noVideo, noAudio);
  }
  if (!file?.file) {
    return false;
  }
  const ext = file.file?.ext || file.file?.name?.split('.')?.pop();
  return (
    (!ignorePreviews && !!file.file?.previews?.length) ||
    ext === 'jpg' ||
    ext === 'png' ||
    ext === 'gif' ||
    (ext === 'mp4' && !noVideo) ||
    (ext === 'mp3' && !noAudio) ||
    ext === 'jpeg' ||
    ext === 'webm' ||
    ext === 'svg'
  );
}

export function openFromAdobe(url, name) {
  window.parent.postMessage(
    {
      url: `${window.location.origin}/redirect.html?name=${encodeURIComponent(
        name,
      )}&redirect=${encodeURIComponent(url)}`,
      type: 'url',
    },
    '*',
  );
}
