import { Box, Grid, Group, Stack, Table } from '@mantine/core';
import { useAssetGridContext } from './AssetGridContext';
import AssetCard from './components/AssetCard';
import { Gallery, ThumbnailImageProps } from 'react-grid-gallery';
import { RefObject } from 'react';
import { TableFields } from './Helpers';
import { useKeySelector } from '@pixi/store';

const AssetGridRender = ({
  files,
  containerRef,
}: {
  files: Pickit.FileInterface[];
  containerRef?: RefObject<HTMLDivElement>;
}) => {
  const AssetGrid = useAssetGridContext();
  if (AssetGrid.view.displayAs === 'table') {
    return (
      <Table
        w="100%"
        styles={{
          td: {
            padding: 5,
            fontSize: 'var(--mantine-font-size-xs)',
          },
        }}
        verticalSpacing={0}
        horizontalSpacing={0}
      >
        {AssetGrid.disable?.includes('tableHeader') ? (
          <></>
        ) : (
          <Table.Thead>
            <Table.Tr c="dimmed" fw="400" h={52}>
              {!AssetGrid.disable?.includes('selection') && (
                <Table.Th id="selection" />
              )}
              <Table.Th w="0" id="context_menu" />
              {AssetGrid.view.tableFields?.map((field) => (
                <Table.Th id={field} key={field} fw="500">
                  {AssetGrid.disable?.includes('tableHeader')
                    ? ''
                    : field === 'file.previews'
                      ? ''
                      : TableFields[field]?.label || field}
                  {/* {files.sortBy?.column === field && sortIcon} */}
                </Table.Th>
              ))}
            </Table.Tr>
          </Table.Thead>
        )}
        <Table.Tbody>
          {files.map((file) => (
            <AssetCard
              key={file._id}
              gridId={AssetGrid.id}
              fileId={file._id}
              onClick={async (event, fileToolbar) => {
                await AssetGrid.onFileClick?.(
                  event,
                  file,
                  fileToolbar,
                  AssetGrid.allFiles,
                );
              }}
              as="table"
              fileToolbar={AssetGrid.fileToolbar}
              fileToolbarCustom={AssetGrid.fileToolbarCustom}
            />
          ))}
        </Table.Tbody>
      </Table>
    );
  }
  const span = () => {
    if (AssetGrid.view.displaySize === 'large') {
      // This checks for larger screens and adjusts the span to prevent the images from being too large.
      switch (AssetGrid.viewport) {
        case 'xs':
        case 'sm':
        case 'md':
          return 12; // Full width on smaller screens
        case 'lg':
        case 'xl':
          return 6; // One third width on extra-large screens, for better appearance
        default:
          return 12;
      }
    }
    if (AssetGrid.view.displaySize === 'small') {
      switch (AssetGrid.viewport) {
        case 'xs':
        case 'sm':
          return 4; // Adjust the values as per your design requirements
        case 'md':
        case 'lg':
          return 2;
        case 'xl':
          return 1.5; // Small grids on extra-large screens can be smaller
        default:
          return 3;
      }
    } else {
      switch (AssetGrid.viewport) {
        case 'xxs':
          return 6;
        case 'xs':
          return 6;
        case 'sm':
          return 6;
        case 'md':
          return 4;
        case 'lg':
        case 'xl':
          return 3; // Slightly larger than 'lg' but still maintains multiple columns
        default:
          return 3;
      }
    }
  };
  if (AssetGrid.view.displayAs === 'grid') {
    return (
      <Grid w="100%" gutter="xs">
        {files.map((file) => {
          return (
            <Grid.Col span={span()} key={file._id}>
              <AssetCard
                fileId={file._id}
                gridId={AssetGrid.id}
                onClick={async (event, fileToolbar) => {
                  await AssetGrid.onFileClick?.(
                    event,
                    file,
                    fileToolbar,
                    AssetGrid.allFiles,
                  );
                }}
                fileToolbar={AssetGrid.fileToolbar}
                fileToolbarCustom={AssetGrid.fileToolbarCustom}
              />
            </Grid.Col>
          );
        })}
      </Grid>
    );
  }
  if (AssetGrid.view.displayAs === 'gallery') {
    // Determine the number of columns based on the viewport or any other logic
    const columnCount = (() => {
      switch (AssetGrid.viewport) {
        case 'xs':
        case 'sm':
          return 2;
        case 'md':
          return 3;
        case 'lg':
        case 'xl':
          return 4;
        default:
          return 3;
      }
    })();

    // Initialize columns
    const columns: Pickit.FileInterface[][] = Array.from(
      { length: columnCount },
      () => [],
    );

    // Distribute files into columns
    files.forEach((file, index) => {
      columns[index % columnCount].push(file);
    });

    return (
      <Group w="100%" gap={5} align="flex-start">
        {columns.map((columnFiles, colIndex) => (
          <Stack
            key={colIndex}
            gap={5}
            style={{
              flex: 1,
            }}
          >
            {columnFiles.map((file) => {
              const width = file?.file?.image_details?.width || 200;
              const height = file?.file?.image_details?.height || 200;
              const aspectRatio = width / height;
              return (
                <AssetCard
                  as="image"
                  fileId={file._id}
                  key={file._id}
                  w="100%"
                  h="auto"
                  style={{
                    width: '100%',
                    height: 'auto',
                    aspectRatio,
                  }}
                  gridId={AssetGrid.id}
                  onClick={async (event, fileToolbar) => {
                    await AssetGrid.onFileClick?.(
                      event,
                      file,
                      fileToolbar,
                      AssetGrid.allFiles,
                    );
                  }}
                  thumbnailProps={{
                    pos: 'relative',
                    mt: 'auto',
                    styles: {
                      root: {
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      },
                    },
                  }}
                  fileToolbar={AssetGrid.fileToolbar}
                  fileToolbarCustom={AssetGrid.fileToolbarCustom}
                />
              );
            })}
          </Stack>
        ))}
      </Group>
    );
  }
  return <></>;
};

export default AssetGridRender;
