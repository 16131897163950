import { useEffect, useState } from 'react';
import { useRouter } from './useRouter'; // Assuming useRouter is properly typed in its own file

interface RouterQueryProps {
  disabled?: boolean;
}

interface RouterQueryParams {
  [key: string]: string;
}

function useRouterQuery(props?: RouterQueryProps) {
  const Router = useRouter();
  const [searchParams, setSearchParams] = useState<string>(
    Router.location.search,
  );
  const params = new URLSearchParams(
    !props?.disabled ? Router.location.search : searchParams,
  );

  useEffect(() => {
    if (Router.location) {
      setSearchParams(Router.location.search);
    }
  }, [Router.location.search]);

  const values: RouterQueryParams = {};
  params.forEach((value, key) => {
    values[key] = value;
  });

  function redirect(): void {
    if (props?.disabled) {
      setSearchParams(params.toString());
      return;
    }
    Router.history.replace(`${Router.location.pathname}?${params.toString()}`);
  }

  return {
    params: values,
    clear: () => {
      new URLSearchParams(Router.location.search).forEach((value, key) => {
        params.delete(key);
      });
      redirect();
    },
    set: (
      name: string | { [key: string]: string } | [string, string][],
      value?: string,
    ) => {
      if (
        typeof name === 'object' &&
        !Array.isArray(name) &&
        Object.keys(name).length
      ) {
        Object.keys(name).forEach((field) => {
          const fieldValue = name[field];
          if (!fieldValue) {
            params.delete(field);
          } else {
            params.set(field, fieldValue);
          }
        });
        return redirect();
      }
      if (Array.isArray(name)) {
        name.forEach((vars) => {
          if (!vars[1]) {
            params.delete(vars[0]);
          } else {
            params.set(vars[0], vars[1]);
          }
        });
        return redirect();
      }
      if (value !== undefined) {
        params.set(name as string, value);
        redirect();
      }
    },
    delete: (key: string) => {
      params.delete(key);
      redirect();
    },
  };
}

export default useRouterQuery;
