import { Button, Flex, Icon, Loader, Progress } from 'elements_v2';
import { useNative } from 'views/Native/context';
import { isNative } from 'utils/platform';
import { useUserContext } from 'hooks';
import { useEffect, useState } from 'react';

const ServiceWorkerWrapper = () => {
  const Native = useNative();
  const User = useUserContext();
  const [isQuitAndInstall, setIsQuitAndInstall] = useState(false);
  const [isNewVersion, setIsNewVersion] = useState(false);

  async function checkAppInfo() {
    const req = await fetch(
      `${window.location.origin}/app.json?id=${Math.random()}`,
    );
    const data = await req.json();
    if (data?.RELEASE_ID !== window.pickit?.RELEASE_ID) {
      setIsNewVersion(true);
    }
  }

  useEffect(() => {
    checkAppInfo();
  }, []);

  if (isNative() && Native?.updater?.updateNotAvailable?.sha512) {
    <div className={['App__status', 'App__status--completed'].join(' ')}>
      <div className="App__status__content" style={{ padding: 15 }}>
        <div className="App__status__content__text">
          <div style={{ width: '100%' }}>You are on the latest update</div>
        </div>
      </div>
    </div>;
  }

  if (
    isNative() &&
    (Native.updater?.isActive || Native.updater?.isUpdateReady)
  ) {
    return (
      <div className="App__status">
        <div className="App__status__content" style={{ padding: 15 }}>
          <div
            className="App__status__content__text"
            style={{ marginBottom: 5 }}
          >
            {!Native.updater?.isUpdateReady
              ? 'An update of Pickit Desktop is being downloaded'
              : 'An update of Pickit Desktop is available'}
          </div>
          {!Native.updater?.isUpdateReady ? (
            <div className="App__status__content__text">
              {Native.updater?.downloadProgress?.transferred ? (
                <Progress
                  white
                  barStyle={{ height: 12, padding: 2 }}
                  current={Native.updater?.downloadProgress?.transferred}
                  max={Native.updater?.downloadProgress?.total}
                />
              ) : (
                <Loader />
              )}
            </div>
          ) : (
            <Button
              background="#FFF"
              color="#6813bf"
              loading={isQuitAndInstall}
              onClick={() => {
                setIsQuitAndInstall(true);
                window.pickitNative?.quitAndInstall();

                setTimeout(() => {
                  window.pickitNative?.restartApp();
                }, 6000);
              }}
            >
              Install and relaunch
            </Button>
          )}
        </div>
      </div>
    );
  }

  if (isNative() && Native.updater?.syncingFileChanges?.length) {
    const currentProgress = Native.updater?.syncingFileChanges[0];
    if (!currentProgress?.file?.name) {
      return <></>;
    }
    return (
      <div
        className={[
          'App__status',
          ...(currentProgress?.status === 'finished'
            ? ['App__status--completed']
            : []),
        ].join(' ')}
      >
        <div className="App__status__content" style={{ padding: 15 }}>
          <div className="App__status__content__text">
            <div style={{ width: '100%' }}>
              {currentProgress?.status !== 'finished' ? (
                'Saving updated file to Pickit'
              ) : (
                <Flex alignItems="center">
                  <Icon name="check-circle-fill" />{' '}
                  {currentProgress?.file?.name}
                  <br />
                  updated to version{' '}
                  {(currentProgress?.file?.file?.version || 1) + 1}
                </Flex>
              )}
              {currentProgress?.status === 'uploading' && (
                <Progress
                  white
                  barStyle={{ height: 12, padding: 2 }}
                  current={currentProgress?.progress?.loadedBytes}
                  max={currentProgress?.progress?.total}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }

  return <></>;
};

export default ServiceWorkerWrapper;
