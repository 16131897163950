import { Button, Dropdown, Icon, Input, List } from 'elements_v2';
import { useEffect, useReducer, useRef, useState } from 'react';
import Discover from 'views/_Manage/Settings/Billing/MaskedCard/logos/Discover';
import './style.scss';

export default function Tags(props) {
  const [inputValue, setInputValue] = useState();
  const [showAll, setShowAll] = useState(false);
  const scrollRef = useRef();
  const [tags, setTags] = useReducer((state, action) => {
    if (Array.isArray(action.value)) {
      action.value = action.value.map((t) => {
        if (typeof t === 'string') {
          return t.trim();
        }
        return t;
      });
    } else {
      action.value = action.value.trim();
    }
    if (action.type === 'replace') {
      return [...action.value];
    }
    if (action.type === 'add') {
      if (action.value.includes(',')) {
        state.push(...action.value.split(',').map((t) => t.trim()));
      } else {
        state.push(action.value);
      }
    }
    if (action.type === 'remove' && state.indexOf(action.value) > -1) {
      state.splice(state.indexOf(action.value), 1);
    }
    const newState = [...state].filter((v, i) => state.indexOf(v) === i);
    if (props.onChange) {
      props.onChange(newState);
    }
    return newState;
  }, props.value || []);
  useEffect(() => {
    if (props.onInputValue) {
      props.onInputValue(inputValue);
    }
  }, [props.onInputValue, inputValue]);

  useEffect(() => {
    if (Array.isArray(props.value)) {
      setTags({ type: 'replace', value: props.value });
      if (scrollRef?.current && !props.isAddingDisabled) {
        scrollRef.current.scrollTop = scrollRef?.current.scrollHeight;
      }
    }
  }, [props.value]);
  function renderTag(tag) {
    return (
      <div
        className={`Pixi__Tags__values__value ${
          props.highlight?.includes(tag) || inputValue?.toLowerCase() === tag
            ? 'Pixi__Tags__values__value--highlight'
            : ''
        } ${props.onTagClick ? 'Pixi--clickable' : ''}`}
        onClick={props.onTagClick ? () => props.onTagClick(tag) : undefined}
        key={tag}
      >
        {props.tagLabel?.(tag) || tag}
        {!props.isDeletingDisabled && (
          <Icon
            name="X"
            bubble
            onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();
              if (props.onRemove) {
                props.onRemove(tag);
                return false;
              }
              setTags({ type: 'remove', value: tag });
            }}
          />
        )}
      </div>
    );
  }
  const allTags = tags.slice(
    0,
    props.limit && !showAll ? props.limit : tags?.length
  );
  let render = (
    <>
      <div className="Pixi__Tags">
        {props.inputAtTop ? (
          <>
            <Input.Field
              value={inputValue}
              disabled={props.disabled}
              onChange={(value) => setInputValue(value)}
              placeholder={props.placeholder}
              style={{
                marginBottom: 10,
              }}
              onKeyDown={(event) => {
                if (event.keyCode === 13 && !props.isDropdownOnly) {
                  event.preventDefault();
                  event.stopPropagation();
                  setTags({ type: 'add', value: event.target.value });
                  setInputValue('');
                }
              }}
            />
          </>
        ) : (
          <></>
        )}
        <div
          className="Pixi__Tags__values"
          style={{
            marginBottom: tags.length && !props.isAddingDisabled ? 5 : 0,
            maxHeight: props.height || undefined,
            overflow: 'auto',
          }}
          ref={scrollRef}
        >
          {props.asList ? (
            <>
              {allTags.map((tag) =>
                props.tagWrapper
                  ? props.tagWrapper(renderTag(tag), tag)
                  : renderTag(tag)
              )}
            </>
          ) : (
            allTags.map((tag) =>
              props.tagWrapper
                ? props.tagWrapper(renderTag(tag), tag)
                : renderTag(tag)
            )
          )}
        </div>
        {props.limit && props.limit < tags.length && (
          <>
            <button
              style={{
                border: 'none',
                background: 'transparent',
                color: '#6716d8',
                cursor: 'pointer',
                marginBottom: 5,
              }}
              onClick={() => setShowAll(!showAll)}
              type="button"
            >
              {showAll ? 'Show less' : 'Show all'}
            </button>
            <br />
          </>
        )}
        {!props.isAddingDisabled && !props.inputAtTop && (
          <>
            <Input.Field
              value={inputValue}
              disabled={props.disabled}
              onChange={(value) => setInputValue(value)}
              placeholder={props.placeholder}
              onKeyDown={(event) => {
                if (event.keyCode === 13 && !props.isDropdownOnly) {
                  event.preventDefault();
                  event.stopPropagation();
                  setTags({ type: 'add', value: event.target.value });
                  setInputValue('');
                }
              }}
            />
          </>
        )}
      </div>
    </>
  );

  if (props.pixi) {
    render = <Input style={props.style} control={render} />;
  }

  if (props.isDropdownOnly) {
    return <Dropdown {...props.dropdownProps} trigger={render} />;
  }

  return render;
}
