import { Box, Container, Group, Paper } from '@mantine/core';
import PixiButton from '@pixi/elements/Button';
import PixiIcon from '@pixi/elements/Icon';
import PixiText from '@pixi/elements/Text';
import { differenceInSeconds, formatDuration } from 'date-fns';
import { useEffect } from 'react';
import { useRegisterSW } from 'virtual:pwa-register/react';

function AppUpdater() {
  const {
    offlineReady: [offlineReady, setOfflineReady],
    needRefresh: [needRefresh, setNeedRefresh],
    updateServiceWorker,
  } = useRegisterSW({
    onRegistered(r) {
      // eslint-disable-next-line prefer-template
      console.log('SW Registered: ' + r);
    },
    onRegisterError(error) {
      console.log('SW registration error', error);
    },
    onRegisteredSW: (swUrl, r) => {
      r &&
        setInterval(async () => {
          if (!(!r.installing && navigator)) return;

          if ('connection' in navigator && !navigator.onLine) return;

          const resp = await fetch(swUrl, {
            cache: 'no-store',
            headers: {
              cache: 'no-store',
              'cache-control': 'no-cache',
            },
          });

          if (resp?.status === 200) await r.update();
        }, 60 * 1000);
    },
  });

  const secondsAlive = differenceInSeconds(
    new Date(),
    window.pickitSessionDate,
  );
  useEffect(() => {
    // Reload if session is fresh :)
    const secondsAlive = differenceInSeconds(
      new Date(),
      window.pickitSessionDate,
    );
    if (secondsAlive < 10 && needRefresh) {
      console.log('Automatic update', secondsAlive);
      updateServiceWorker(true);
      setTimeout(() => {
        location.reload();
      }, 1000);
    }
  }, [needRefresh]);

  if (needRefresh && secondsAlive < 10) {
    return <></>;
  }

  return (
    <Box
      w="100%"
      pos="fixed"
      p="sm"
      bottom={0}
      style={{
        zIndex: 9999,
        transform: `translateY(${!needRefresh ? '100%' : '0'})`,
        transition: 'transform 0.25s',
      }}
    >
      <Container size="sm">
        <Paper bg="dark" p="sm" px="sm" radius="lg">
          <Group w="100%">
            <PixiText c="white" size="md" fw="500" ml="md">
              New version available. Reload to install
            </PixiText>
            <PixiButton
              color="dark"
              variant="white"
              ml="auto"
              size="md"
              onClick={() => {
                updateServiceWorker(true);
                setTimeout(() => {
                  window.location.reload();
                }, 2000);
              }}
              leftSection={<PixiIcon name="arrow-rotate-right" />}
            >
              Reload
            </PixiButton>
          </Group>
        </Paper>
      </Container>
    </Box>
  );
}

export default AppUpdater;
