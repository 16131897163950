import useUserStatus from 'hooks/useUserStatus';
import { useFileProvider } from 'contexts/File';
import { MediaContext } from 'contexts/Media';

export function MediaProvider(props) {
  const userStatus = useUserStatus();
  const fileProvider = useFileProvider({
    type: 'media',
    name: 'Media',
    fileType: 'Media',
    mainContainerId: 'main',
    uploadLabel: 'Add media',
    libraryName: 'Company Media',
    integrations: [
      'microsoft365_import',
      'box',
      'dropbox',
      'googledrive',
      'adobeccl',
      'network_file_system',
      'hubspot_import',
    ],
    markets: ['gettyimages', 'adobestock', 'shutterstock'],
    fileTypesAllowed: [
      'image/*',
      'application/octet-stream',
      'application/postscript',
      'image/vnd.adobe.photoshop',
      'application/x-photoshop',
      'application/x-indesign',
      'application/pdf',
      ...(userStatus.policies?.access?.features?.audio_video !== false
        ? ['video/*', 'audio/*']
        : []),
      // Adobe ccl mimetypes because why use standard mimetypes when you can create your own!
      'application/photoshop',
      'application/illustrator',
      'application/vnd.adobe.sparkler.project+dcxucf', // Adobe XD
      'application/zip',
      'application/octet-stream',
      'application/x-zip-compressed',
      'multipart/x-zip',
      'application/mxf',
    ],
    extensionsAllowed: [
      'jpeg',
      'jpg',
      'gif',
      'eps',
      'tif',
      'tiff',
      'ai',
      'svg',
      'png',
      'psd',
      'bmp',
      'inx',
      'pdf',
      'zip',
      // Premiere Pro
      'prproj',
      // After Effects
      'aep',
      'aepx',
      // InDesign
      'indd',
      'indt',

      // Raw formats
      'cr2',
      'arw',
      'srf',
      'sr2',
      'braw',
      'nef',
      'nrw',
      'dng',
      'raf',
      'gpr',
      'heif',
      'heic',
      'srw',
      'cap',
      'iiq',
      'eip',
      'r3d',
      'pef',
      'ptx',
      'orf',
      'pdf',
      'raw',
      'rw2',
      'xd',
      'mxf',
      'dxf',
      'dwg',
      'skp',
      // ---
      ...(userStatus.policies?.access?.features?.audio_video !== false
        ? [
            'mov',
            'mp4',
            'mp3',
            'wav',
            'mkv',
            'avi',
            'webm',
            'pek',
            'mcvideoindex',
          ]
        : []),
    ],
  });

  return (
    <MediaContext.Provider value={fileProvider}>
      {props.children}
    </MediaContext.Provider>
  );
}
