import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { useParams, useRouter } from 'hooks/useRouter';
import { generatePath } from 'react-router-dom';
import { useFolders } from 'contexts/Folders';
import { useConfigStoreValue, useKeySelector } from '@pixi/store';
import { useDndMonitor, useDraggable, useDroppable } from '@dnd-kit/core';
import { useMergedRef } from '@mantine/hooks';
import PixiButton from '@pixi/elements/Button';
import PixiDropdown from '@pixi/elements/Dropdown';
import PixiIcon from '@pixi/elements/Icon';
import {
  Alert,
  Breadcrumbs,
  Divider,
  Group,
  Paper,
  Stack,
  TextInput,
} from '@mantine/core';
import PixiForm from '@pixi/elements/Form';
import PixiConfirm from '@pixi/elements/Confirm';
import { createAppToast } from '@pixi/AppController';
import { useAssetGridContext } from '../../AssetGridContext';
// import "./style.scss";

function CreateFolder({
  target,
  parentFolders,
}: {
  target: ReactElement;
  parentFolders?: string[];
}) {
  const AssetGrid = useAssetGridContext();
  const Folders = useFolders();
  return (
    <PixiForm<{ name: string }>
      type="dropdown"
      submitLabel="Create folder"
      onSubmit={async ({ name }) => {
        await Folders.saveFolder({
          name,
          library: AssetGrid.currentLibrary?._id,
          parentFolders: parentFolders || [],
        });
      }}
      submitProps={(form) => {
        return {
          disabled: !form.name,
        };
      }}
      form={{
        name: {
          key: 'name',
          value: '',
          render: ({ value, setValue }) => {
            return (
              <TextInput
                value={value}
                placeholder="Folder name"
                onChange={(e) => setValue(e.target.value)}
                maxLength={50}
              />
            );
          },
        },
      }}
      target={target}
    />
  );
}

function Folder({
  folder,
  onClick,
  droppable,
}: {
  folder: Pickit.FolderInterface;
  onClick: (folder: Pickit.FolderInterface) => void;
  droppable: boolean;
}) {
  const AssetGrid = useAssetGridContext();
  const Folders = useFolders();
  const draggingAsset = useConfigStoreValue('APP_CONFIG', 'draggingAsset');
  const { setNodeRef, attributes, transform, listeners, isDragging } =
    useDraggable({
      id: folder?._id,
      data: {
        folder,
      },
    });
  const draggingFile = useKeySelector('FILES', draggingAsset?.id);
  useDndMonitor({
    async onDragEnd(event) {
      const over = event.over?.id;
      const data = event.active?.data?.current as any;
      if (data?.folder && over === folder._id) {
        if (data?.folder?._id === folder?._id) {
          return;
        }
        const item = data?.folder;
        await Folders.saveFolder({
          _id: item._id,
          parentFolders: [...(item.parentFolders || []), folder._id],
        });
        return;
      }
      if (over === folder._id) {
        await AssetGrid.context.savePartial({
          _id: draggingFile?._id,
          $addToSet: {
            folders: folder._id,
          },
        });
        createAppToast({
          id: `file${draggingFile?._id}to_folder${folder._id}`,
          message: 'Asset added to folder',
          type: 'success',
        });
      }
    },
  });

  const {
    setNodeRef: droppableRef,
    isOver,
    active,
  } = useDroppable({
    id: folder._id,
    data: {
      accepts: [AssetGrid.context.fileType],
    },
  });

  const combinedRefs = useMergedRef(droppableRef, setNodeRef);
  const isOverAndItsOk =
    isOver &&
    (!!draggingFile?._id || (active?.id && active?.id !== folder?._id));

  return (
    <PixiButton
      ref={combinedRefs}
      key={folder._id}
      onClick={() => onClick(folder)}
      size="sm"
      style={{
        transform: transform
          ? `translate(${transform.x}px, ${transform.y}px)`
          : undefined,
        zIndex: isDragging ? 99999 : 1,
      }}
      color={isOverAndItsOk ? 'primary' : 'dark'}
      variant={isOverAndItsOk ? 'light' : isDragging ? 'light' : 'glass'}
      leftSection={<PixiIcon name="folder" />}
      dropdownProps={{
        width: 260,
      }}
      dropdown={
        <>
          <PixiForm<{ name: string }>
            type="dropdown"
            key={folder?.updatedAt}
            title="Rename folder"
            submitLabel="Rename folder"
            position="bottom-end"
            onSubmit={async ({ name }) => {
              await Folders.saveFolder({
                _id: folder._id,
                name,
                library: folder?.library,
              });
            }}
            form={{
              name: {
                key: 'name',
                value: folder.name,
                render: ({ value, setValue }) => {
                  return (
                    <TextInput
                      value={value}
                      onChange={(e) => setValue(e.target.value)}
                      maxLength={25}
                    />
                  );
                },
              },
            }}
            target={
              <PixiDropdown.Item icon="pencil">Rename folder</PixiDropdown.Item>
            }
          />
          <PixiConfirm
            title="Delete folder"
            description={
              <>
                Are you sure? All {Folders.countFoldersInFolder(folder._id)}{' '}
                folders within this folder will also be deleted
                <Alert
                  color="dark"
                  icon={
                    <PixiIcon name="triangle-exclamation" variant="filled" />
                  }
                >
                  This will <strong>not</strong> delete the assets within the
                  folders
                </Alert>
              </>
            }
            onConfirm={async () => {
              await Folders.deleteFolder(folder);
            }}
            confirmLabel="Delete"
            confirmProps={{
              leftSection: <PixiIcon name="trash-can" />,
              color: 'red',
            }}
            position="bottom-end"
          >
            <PixiDropdown.Item variant="glass" color="dark" icon="trash-can">
              Delete folder
            </PixiDropdown.Item>
          </PixiConfirm>
        </>
      }
      {...attributes}
      {...listeners}
    >
      {folder.name}
    </PixiButton>
  );
}

function CurrentFolderToolbar() {
  const {
    currentFolder: folder,
    currentFolderId,
    setCurrentFolderId,
  } = useAssetGridContext();
  const Folders = useFolders();
  const currentPath = !currentFolderId
    ? []
    : Folders.getFolderPath(currentFolderId);
  if (!currentPath?.length) {
    return <></>;
  }
  return (
    <Stack mb="sm">
      <Divider />
      <Breadcrumbs
        separator={<PixiIcon name="chevron-right" size="xs" />}
        separatorMargin="5"
      >
        {['', ...currentPath].map((folderId, index) => {
          const folder = Folders.findFolderById(folderId);
          if (!folder && folderId) {
            return <></>;
          }
          return !folderId ? (
            <PixiButton
              color="dark"
              variant="glass"
              size="xs"
              onClick={() => {
                setCurrentFolderId('');
              }}
            >
              Home
            </PixiButton>
          ) : (
            <Group gap="5">
              <PixiButton
                color={folderId === currentFolderId ? 'primary' : 'dark'}
                variant={folderId === currentFolderId ? 'light' : 'glass'}
                size="xs"
                key={folderId}
                leftSection={
                  folderId === currentFolderId ? (
                    <PixiIcon name="folder-closed" />
                  ) : undefined
                }
                dropdown={
                  folderId === currentFolderId ? (
                    <>
                      <PixiForm<{ name: string }>
                        type="dropdown"
                        key={folder?.updatedAt}
                        title="Rename folder"
                        submitLabel="Rename folder"
                        position="bottom-end"
                        onSubmit={async ({ name }) => {
                          await Folders.saveFolder({
                            _id: folder?._id,
                            name,
                            library: folder?.library,
                          });
                        }}
                        form={{
                          name: {
                            key: 'name',
                            value: folder?.name as string,
                            render: ({ value, setValue }) => {
                              return (
                                <TextInput
                                  value={value}
                                  onChange={(e) => setValue(e.target.value)}
                                  maxLength={25}
                                />
                              );
                            },
                          },
                        }}
                        target={
                          <PixiDropdown.Item icon="pencil">
                            Rename folder
                          </PixiDropdown.Item>
                        }
                      />
                      <PixiConfirm
                        title="Delete folder"
                        description={
                          <>
                            Are you sure? All{' '}
                            {Folders.countFoldersInFolder(folderId)} folders
                            within this folder will also be deleted
                            <Alert
                              color="dark"
                              icon={
                                <PixiIcon
                                  name="triangle-exclamation"
                                  variant="filled"
                                />
                              }
                            >
                              This will <strong>not</strong> delete the assets
                              within the folders
                            </Alert>
                          </>
                        }
                        onConfirm={async () => {
                          await Folders.deleteFolder(
                            folder as Pickit.FolderInterface,
                          );
                        }}
                        confirmLabel="Delete"
                        confirmProps={{
                          leftSection: <PixiIcon name="trash-can" />,
                          color: 'red',
                        }}
                        position="bottom-end"
                      >
                        <PixiDropdown.Item
                          variant="glass"
                          color="dark"
                          icon="trash-can"
                        >
                          Delete folder
                        </PixiDropdown.Item>
                      </PixiConfirm>
                    </>
                  ) : undefined
                }
                onClick={() => {
                  setCurrentFolderId(folderId);
                }}
              >
                {folder?.name}
              </PixiButton>
            </Group>
          );
        })}
      </Breadcrumbs>
    </Stack>
  );
}

export default function AssetGridFolders() {
  const AssetGrid = useAssetGridContext();
  const Folders = useFolders();
  const folders = AssetGrid.currentFolderId
    ? Folders.findFoldersByParent(AssetGrid.currentFolderId)
    : Folders.findFoldersByLibrary(AssetGrid.currentLibrary)?.filter(
        (folder: Pickit.FolderInterface) => !folder.parentFolders?.length,
      );
  const [isAddingNewLibrary, setIsAddingNewLibrary] = useState(false);
  return (
    <>
      <Stack gap="2">
        {!!AssetGrid.currentFolderId && <CurrentFolderToolbar />}
        <Divider mb="xs" />
        <Paper w="100%">
          <>
            <Group gap="5">
              {folders
                ?.sort((a, b) => a.placement - b.placement)
                .map((folder) => {
                  return (
                    <Folder
                      droppable
                      onClick={() => {
                        AssetGrid.setCurrentFolderId(folder._id);
                      }}
                      key={folder._id}
                      folder={folder}
                    />
                  );
                })}
              <CreateFolder
                parentFolders={
                  AssetGrid.currentFolderId ? [AssetGrid.currentFolderId] : []
                }
                target={
                  <PixiButton
                    onClick={() => setIsAddingNewLibrary(true)}
                    leftSection={<PixiIcon name="plus" />}
                    color="dark"
                    variant={!folders?.length ? 'glass' : 'subtle'}
                    size="sm"
                  >
                    Create {AssetGrid.currentFolderId ? 'sub-' : ''}folder
                  </PixiButton>
                }
              />
            </Group>
          </>
        </Paper>
      </Stack>
    </>
  );
}
