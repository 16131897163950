import React, { useMemo, useState } from 'react';
import './style.scss';
import Tooltip from 'rc-tooltip';

function Simple(props) {
  const className = ['Pixi__Tooltip'];
  if (props.noShrink) {
    className.push('Pixi__Tooltip--noShrink');
  }
  if (props.small) {
    className.push('Pixi__Tooltip--small');
  }

  const message = (
    <div
      className={['Pixi', 'Pixi__Tooltip__message'].join(' ')}
      style={props.style}
    >
      {props.message}
    </div>
  );

  if (props.disabled) {
    return props.children;
  }

  return (
    <>
      <Tooltip
        overlayStyle={props.tooltipStyle || props.triggerStyle}
        overlayClassName={props.className}
        placement={props.position || 'bottom'}
        trigger={['hover']}
        mouseEnterDelay={props.delay ?? 0.5}
        mouseLeaveDelay={0}
        overlay={message}
      >
        {props.noTriggerWrapper ? (
          props.children
        ) : (
          <div style={props.triggerStyle}>{props.children}</div>
        )}
      </Tooltip>
    </>
  );
}

export default Simple;
