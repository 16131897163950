import React, { useState, ReactNode } from 'react';
import { useDocumentsContext, useMediaContext, useUserContext } from 'hooks';
import Flows, {
  FlowData,
  InterfaceFlowState,
  InterfaceFlowStep,
} from 'services/api/Flows';
import { FlowsContext } from 'contexts/Flows';

type Props = {
  children: ReactNode;
};

export function FlowsProvider(props: Props) {
  const User = useUserContext();

  const flowsApi = User.request.flows as Flows;

  const [isLoading, setIsLoading] = useState<Boolean>(false);
  const [flows, setFlows] = useState<FlowData[]>([]);
  const [stateMap, setStateMap] = useState<any>({});
  const [userTasks, setUserTasks] = useState<InterfaceFlowState[]>([]);
  const getFlows = async (reset?: boolean) => {
    if (isLoading) return;
    if (reset) {
      setFlows([]);
      setStateMap({});
      setUserTasks([]);
    }
    setIsLoading(true);
    let res;
    try {
      res = await (await flowsApi.get()).json();
    } catch (error) {
      res = { data: [] };
    }
    updateStateMap(res.data, true);

    setFlows(res.data);
    const tasks = await getUserTasks();
    setUserTasks(tasks);
    setIsLoading(false);
  };
  const updateStateMap = (flowsData: FlowData[], replace?: boolean) => {
    const newStateMap = { ...(replace ? {} : stateMap) } as any;
    flowsData.forEach((flow: FlowData) => {
      const key = flow?._id ?? 'null';
      flow.steps.forEach((step) => {
        if (!newStateMap[key]) newStateMap[key] = {};
        newStateMap[key][step._id] = {
          docCount: 0,
          mediaCount: 0,
          data: [],
        };
      });
      flow?.state?.forEach?.((state) => {
        if (!newStateMap[key]) {
          newStateMap[key] = {};
        }
        state?.currentSteps?.forEach((stepId) => {
          if (!newStateMap[key][stepId]) {
            newStateMap[key][stepId] = {
              docCount: 0,
              mediaCount: 0,
              data: [],
            };
          }
          newStateMap[key][stepId].data.push(state.file);
          newStateMap[key][stepId].docCount +=
            state.library === 'documents' ? 1 : 0;
          newStateMap[key][stepId].mediaCount +=
            state.library === 'media' ? 1 : 0;
        });
      });
    });

    setStateMap(newStateMap);
  };

  const getUserTasksInternal = async (count?: boolean, split?: boolean) => {
    if (isLoading) return;
    setIsLoading(true);
    let res;
    try {
      res = await (await flowsApi.getUserTasks(count, split)).json();
    } catch (error) {
      res = { data: [], count: [] };
    }

    setIsLoading(false);
    return res;
  };
  const getUserTasks = async () => {
    const { data } = await getUserTasksInternal(false, false);
    return data ?? [];
  };
  const getSplitUserTasks = async () => {
    return (
      (await getUserTasksInternal(false, true)).data ?? {
        media: [],
        documents: [],
      }
    );
  };
  const getSplitUserTasksCount = async () => {
    return (
      (await getUserTasksInternal(true, true)).count ?? {
        media: [],
        documents: [],
      }
    );
  };
  const getUserTasksCount = async () => {
    return (await getUserTasksInternal(true, false)).count ?? 0;
  };

  const getStateContributor = async (fileId: string) => {
    if (isLoading) return;
    setIsLoading(true);
    let res;
    try {
      res = await (await flowsApi.getStateContributor(fileId)).json();
    } catch (error) {
      res = { data: undefined };
    }

    setIsLoading(false);
    return res;
  };

  const createFlow = async (data: FlowData) => {
    setIsLoading(true);
    const resp = await flowsApi.create(data);
    const success = resp.status === 200;
    const flow = await resp.json();
    setFlows([...flows, flow]);
    setIsLoading(false);
    return success;
  };

  const updateFlow = async (data: FlowData) => {
    setIsLoading(true);
    const resp = await flowsApi.update(data);
    const success = resp.status === 200;

    const flow = await resp.json();
    setFlows(
      flows.map((currentFlow) =>
        currentFlow._id === flow._id ? flow : currentFlow,
      ),
    );
    setIsLoading(false);
    return success;
  };
  const deleteFlow = async (id: string) => {
    if (isLoading) return;
    setIsLoading(true);
    await flowsApi.delete(id);
    setFlows(flows.filter((currentFlow) => currentFlow._id !== id));
    setIsLoading(false);
  };

  const doAction = async (
    flowId: string,
    fileIds: string[],
    action: string,
    stepId: string,
    message: string,
    doUpdateStateMap = true,
  ) => {
    // if (isLoading) return;
    setIsLoading(true);
    const res = await (
      await flowsApi.action(flowId, fileIds, action, stepId, message)
    ).json();
    setFlows(
      flows.map((currentFlow) =>
        currentFlow._id === res.data._id ? res.data : currentFlow,
      ),
    );
    setIsLoading(false);
    if (doUpdateStateMap) updateStateMap([res.data]);
    return res;
  };
  const doActionMultiple = async (
    states: InterfaceFlowState[],
    action: string,
    message: string,
    doUpdateStateMap = true,
  ) => {
    // if (isLoading) return;
    setIsLoading(true);
    const res = await (
      await flowsApi.actionMultiple(states, action, message)
    ).json();
    setFlows(
      flows.map(
        (currentFlow) =>
          res.data.find((flow: FlowData) => flow._id === currentFlow._id) ||
          currentFlow,
      ),
    );
    if (doUpdateStateMap) updateStateMap(res.data);
    setIsLoading(false);
    return res;
  };
  const doActionFiles = async (
    fileIds: string[],
    action: string,
    message: string,
    doUpdateStateMap = true,
  ) => {
    // if (isLoading) return;
    setIsLoading(true);
    const res = await (
      await flowsApi.actionFiles(fileIds, action, message)
    ).json();
    setFlows(
      flows.map(
        (currentFlow) =>
          res.data.find((flow: FlowData) => flow._id === currentFlow._id) ||
          currentFlow,
      ),
    );
    setIsLoading(false);
    if (doUpdateStateMap) updateStateMap(res.data);
    return res;
  };
  const getFlowFromStepId = (stepId: string) => {
    return flows.find((flow) => flow.steps.find((step) => step._id === stepId));
  };
  const hasAccessToStep = (step: InterfaceFlowStep | undefined) => {
    if (!step) return false;
    switch (step?.responsibility.responsibilityType) {
      case 'people':
        return !!step?.responsibility?.ids?.includes(
          User.djangoProfile.id.toString(),
        );

      case 'groups':
        let access = false;
        User.spaces.selected.groups.forEach((group: string) => {
          const split = group.split('_');
          split.shift();
          if (step?.responsibility?.ids?.includes(split.join('_'))) {
            access = true;
          }
        });
        return access;

      case 'admins':
        return true;
      default:
        return false;
    }
  };
  return (
    <FlowsContext.Provider
      value={{
        getFlows,
        createFlow,
        updateFlow,
        deleteFlow,
        flows,
        isLoading,
        stateMap,
        doAction,
        getFlowFromStepId,
        userTasks,
        doActionMultiple,
        doActionFiles,
        getStateContributor,
        getUserTasks,
        getUserTasksCount,
        getSplitUserTasks,
        getSplitUserTasksCount,
        hasAccessToStep,
      }}
    >
      {props.children}
    </FlowsContext.Provider>
  );
}
