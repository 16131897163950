import { format, isToday, isYesterday, startOfDay } from 'date-fns';

import { MouseEvent, useEffect, useMemo, useRef, useState } from 'react';
import PixiIcon from '@pixi/elements/Icon';
import {
  Alert,
  Box,
  Card,
  Group,
  Loader,
  Overlay,
  Paper,
  rgba,
  Stack,
} from '@mantine/core';
import PixiTooltip from '@pixi/elements/Tooltip';
import PixiText from '@pixi/elements/Text';
import PixiButton from '@pixi/elements/Button';
import fastDeepEqual from 'fast-deep-equal/es6';
import {
  getMultiStore,
  getStore,
  useConfigStoreValue,
  useKeySelector,
  useKeysSelector,
  useMultiStoreData,
} from '@pixi/store';
import { useIntersection } from '@mantine/hooks';
import {
  AssetGridCenterTabs,
  AssetGridContextInterface,
  AssetGridDisplayAsTypes,
  AssetGridGroupByTypes,
  AssetGridProps,
  AssetGridSortByActive,
  AssetGridTableFields,
  filterRowValueIsActive,
  filterValueToString,
  ShortcutDataRow,
  ShortcutIds,
} from './Helpers';
import { AssetGridContext } from './AssetGridContext';
import AssetGridRightPanel from './AssetGridRightPanel';
import AssetGridLeftPanel from './AssetGridLeftPanel';
import useElementViewport from '@pixi/hooks/useElementViewport';
import {
  createAppToast,
  openAssetPreview,
  useColorScheme,
} from '@pixi/AppController';
import { AssetGridToolbar } from './components/CenterPanels/AssetGridToolbar';
import AssetGridRender from './AssetGridRender';
import System from '@pixi/System';
import AssetGridTags from './components/CenterPanels/AssetGridTags';
import AssetGridStatusBar from './components/CenterPanels/AssetGridStatusBar';
import { AssetGridUpload } from './components/CenterPanels/AssetGridUpload';
import { AssetGridUploadProgress } from './components/CenterPanels/AssetGridUploadProgress';
import AssetGridContributorToolbar from './components/CenterPanels/AssetGridContributorToolbar';
import { useAssetGridShortcuts } from './hooks/useAssetGridShortcuts';
import {
  getPreviewPrefs,
  useAssetActions,
} from '../AssetActions/useAssetActions';
import PixiConfirm from '@pixi/elements/Confirm';
import AssetGridGroup from './components/AssetGridGroup';
import ErrorMessage from '@pixi/elements/ErrorMessage';
import { useUserContext } from 'hooks';
import { deepEqual } from '@pixi/helpers/utils';
import useUserStatus from 'hooks/useUserStatus';
import AssetGridUploadBrandAsssets from './components/CenterPanels/AssetGridUploadBrandAssets';
import AssetGridFolders from './components/CenterPanels/AssetGridFolders';

export function AssetGrid(props: AssetGridProps) {
  // const [isAtBottom, setIsAtBottom] = useState(false);
  const User = useUserContext();
  const { ref, entry } = useIntersection({
    threshold: 1,
  });
  const colorScheme = useColorScheme();
  const { ref: containerRef, viewport } = useElementViewport();
  const [disable, setDisable] = useState<AssetGridProps['disable']>(
    props.defaultDisable || props.disable || [],
  );

  // const [groupBy, setGroupBy] = useState<AssetGridContextInterface['groupBy']>(
  //   props.groupBy === null ? null : 'createdAt',
  // );
  // const [displayAs, setDisplayAs] = useState<AssetGridDisplayAsTypes>(
  //   props.displayAs || 'grid',
  // );
  // const [sortBy, setSortBy] = useState<AssetGridSortByActive | null>(null);
  const findSettings = useRef<
    Partial<{
      groupBy: AssetGridGroupByTypes | null;
      sortBy: AssetGridSortByActive | null;
    }>
  >({
    groupBy: null,
    sortBy: null,
  });
  const [activeShortcuts, setActiveShortcuts] = useState<
    {
      id: ShortcutIds;
      filter?: Pickit.LibraryActiveFilters;
    }[]
  >(
    props.forceFilterShortcuts?.map((f) => ({ id: f })) ||
      props.defaultFilterShortcuts?.map((f) => ({ id: f })) ||
      [],
  );
  const [metadata, setMetadata] =
    useState<AssetGridContextInterface['metadata']>(undefined);
  const renderId = props.id;
  const selectedFiles = useMultiStoreData('FILES_SELECTED', renderId, [
    renderId,
  ]);
  const userViewSettings =
    User.data?.user?.settings?.assetGrid?.view?.[props.id];
  const [view, setView] = useState<AssetGridContextInterface['view']>(
    props.persistentViewSettings && userViewSettings
      ? userViewSettings
      : {
          sortBy: props.defaultView?.sortBy || null,
          displayAs: props.defaultView?.displayAs || 'grid',
          groupBy:
            props.defaultView?.groupBy === null
              ? null
              : props.defaultView?.displayAs === 'gallery'
                ? null
                : 'createdAt',
          displaySize: 'medium',
          tableFields: props.defaultView?.tableFields || [],
        },
  );
  const [activeCenterTabId, setActiveCenterTabId] = useState<string>(
    props.defaultCenterTab || '',
  );
  const [currentPage, setCurrentPage] = useState(0);
  const [availableFilters, setAvailableFilters] = useState<
    Record<string, Pickit.LibraryFilter>
  >({});
  const [frozenFilters, setFrozenFilters] = useState<
    Record<string, Pickit.LibraryFilter>
  >({});
  const isLoadingRef = useRef<boolean>(false);
  const [status, setStatus] = useState<AssetGridContextInterface['status']>({
    isLoading: false,
    isLoadingMore: false,
    isFiltersLoaded: false,
    isLoadingMetadata: false,
    isLoadingFilters: false,
    isLoadingAssets: false,
    isError: false,
    errors: [],
    isAllFilesLoaded: false,
    totalFiles: null,
    isReady: false,
  });
  const [assetGridUserStatus, setAssetGridUserStatus] = useState<
    AssetGridContextInterface['userStatus']
  >({
    isContributor: false,
    isExternal: false,
  });
  const allFilesRef = useRef<Pickit.FileInterface[]>([]);
  const [filters, setFilters] = useState<Pickit.LibraryActiveFilters>({
    ...(props.filters || {}),
    ...(props.onlyFileTypes ? { 'file.ext': props.onlyFileTypes } : {}),
    ...(props.defaultFilters || {}),
  });
  const currentCommunitySlug = useRef<string | null>(null);
  const [currentCollection, setCurrentCollection] =
    useState<Pickit.CollectionInterface | null>(null);
  const [currentFolderId, setCurrentFolderId] = useState<string | undefined>(
    props.folderId,
  );
  const currentFolder = useKeySelector('FOLDERS', currentFolderId);
  const controller = useRef<AbortController | null>(null);
  const controllerFilters = useRef<AbortController | null>(null);
  const controllerMetadata = useRef<AbortController | null>(null);
  const preShortcutSettings = useRef<{
    shortcutId: ShortcutIds;
    displayAs?: AssetGridDisplayAsTypes;
    groupBy?: AssetGridGroupByTypes | null;
    sortBy?: AssetGridSortByActive | null;
    tableFields?: AssetGridTableFields[];
  } | null>(null);
  // const [tableFields, setTableFields] = useState<AssetGridTableFields[]>(
  //   props.fields || [],
  // );
  const [forceFilters, setForceFilters] = useState<Pickit.LibraryActiveFilters>(
    {
      ...(props.forceFilters || {}),
    },
  );

  const userStatus = useUserStatus();

  const lastClickedIndex = useRef(-1);
  const lastClickedTime = useRef(0);
  const [_currentLibrary, setCurrentLibrary] =
    useState<Pickit.CommunityLibraryInterface | null>(null);
  const currentLibrary = (User.data?.selectedCommunity?.libraries?.find(
    (l: Pickit.CommunityLibraryInterface) =>
      l._id ===
        (_currentLibrary
          ? _currentLibrary?._id
          : props.libraryId || props.context.type) ||
      l.nameId ===
        (_currentLibrary
          ? _currentLibrary?._id
          : props.libraryId || props.context.type),
  ) || _currentLibrary) as Pickit.CommunityLibraryInterface;

  const [params, setParams] = useState<Record<string, any>>({});

  const assetPreview = useConfigStoreValue('APP_CONFIG', 'filePreview');
  const { data: shortcuts } = useAssetGridShortcuts({
    setFilter,
  });

  // Handles loading more assets in the fullscreen preview when reaching end
  // It will of course be a bit buggy if user is in another session and same files appears, but I think its edge-case for now
  async function bindNewKeysToPreview() {
    if (!assetPreview?.file?._id) {
      return;
    }
    const allFiles = await getFiles();
    if (allFiles?.length) {
      openAssetPreview(assetPreview.file, {
        ...assetPreview.prefs,
        ...getPreviewPrefs({
          file: assetPreview.file,
          allFiles,
        }),
      });
    }
  }

  useEffect(() => {
    setCurrentFolderId(props.folderId);
  }, [props.folderId]);

  useEffect(() => {
    if (currentFolderId) {
      setForceFilters((f) => ({
        ...f,
        folders: [{ value: currentFolderId }],
      }));
    } else {
      setForceFilters((f) => ({
        ...f,
        folders: [],
      }));
    }
  }, [currentFolderId]);

  useEffect(() => {
    if (
      assetPreview.file?._id &&
      allFiles?.find((f) => f._id === assetPreview.file?._id)
    ) {
      const keys = assetPreview.prefs?.keys;
      if (!keys?.find((k) => k.eventKey === 'ArrowRight')) {
        bindNewKeysToPreview();
      }
    }
  }, [assetPreview]);

  useEffect(() => {
    if (props.onStatusChange) {
      props.onStatusChange(status);
    }
  }, [status]);

  async function savePersistentViewSettings() {
    // View settings are forced by a shortcut, lets not save
    if (preShortcutSettings?.current) {
      return;
    }
    const userExistingSettings =
      User.data?.user?.settings?.assetGrid?.view?.[props.id];
    if (deepEqual(userExistingSettings, view)) {
      return;
    }
    await User.saveUserSettingsPartial({
      ['assetGrid.view.' + props.id]: {
        groupBy: view.groupBy,
        sortBy: view.sortBy,
        displayAs: view.displayAs,
        displaySize: view.displaySize,
      },
    });
  }

  useEffect(() => {
    if (props.persistentViewSettings) {
      savePersistentViewSettings();
    }
  }, [props.persistentViewSettings, view]);

  const shortCutsDataRows = shortcuts.reduce<ShortcutDataRow[]>(
    (acc, group) => [...acc, ...(group.data || [])],
    [],
  );

  const customFilter = (file: Pickit.FileInterface) => {
    if (
      file?.trash?.isTrash &&
      !activeShortcuts?.find((s) => s.id?.includes('trash'))
    ) {
      return false;
    }
    if (props.customFilter) {
      return props.customFilter(file);
    }
    return true;
  };

  const _allFiles = useMultiStoreData('ASSETGRID_ASSETS', props.id);
  const allFilesFromHook =
    useKeysSelector(
      'FILES',
      _allFiles.map((f) => f._id),
    ) || [];
  const allFiles = allFilesFromHook.filter((file) => {
    if (customFilter) {
      return customFilter(file);
    }
    return true;
  });

  const activeShortcutsData = shortCutsDataRows.filter(
    (f) => activeShortcuts.find((s) => s.id === f.id)?.id,
  );
  const activeShortcutsFilter = activeShortcutsData
    .filter(
      (f) => !!f.filter || !!activeShortcuts.find((s) => s.id === f.id)?.filter,
    )
    .map(
      (f) =>
        (activeShortcuts.find((s) => s.id === f.id)?.filter ||
          f.filter) as Pickit.LibraryActiveFilters,
    )
    .reduce((a, b) => ({ ...a, ...b }), {});

  const combinedFilters = {
    ...filters,
    ...(forceFilters || {}),
    ...activeShortcutsFilter,
  };

  function getSettings(clearCache?: boolean) {
    return {
      ...params,
      page: clearCache ? 0 : currentPage || '',
      limit: 50,
      filters: {
        ...filters,
        ...(forceFilters || {}),
        ...activeShortcutsFilter,
      },
      collectionId: props?.collectionId,
      libraryId: props?.libraryId,
      communitySlug: props?.communitySlug || currentCommunitySlug.current,
      ...(props.forceSortBy
        ? {
            sort_by: props.forceSortBy.field,
            sort_direction: props.forceSortBy.direction,
          }
        : view?.sortBy?.field
          ? {
              sort_by: view?.sortBy?.field,
              sort_direction: view?.sortBy?.direction,
            }
          : {}),
      ...(disable?.includes('rightPanel')
        ? {
            getTotalResults: true,
          }
        : {}),
    };
  }

  async function getMetadata() {
    try {
      if (props.mounted === false) {
        return;
      }

      // Right now we need metadata when using global tags search.
      // if (disable?.includes('rightPanel')) {
      //   return;
      // }
      const settings = {
        ...getSettings(true),
      };
      setMetadata(undefined);
      setStatus((status) => ({
        ...status,
        isLoadingMetadata: true,
      }));
      if (controllerMetadata.current?.signal) {
        controllerMetadata.current.abort();
      }
      controllerMetadata.current = new AbortController();
      const newMetadata = await props.context.request.getAssetGridMetadata({
        settings,
        raw: true,
        signal: controllerMetadata.current?.signal,
      });
      setMetadata(newMetadata);
      setStatus((status) => ({
        ...status,
        isLoadingMetadata: false,
        errors: status.errors?.filter((e) => e.type !== 'metadata'),
      }));
    } catch (e) {
      if (
        e === 'AbortError' ||
        (e as string)?.includes('AbortError') ||
        (e as any)?.message?.includes('AbortError')
      ) {
        return;
      }
      if (!(e instanceof Response)) {
        System.Report.logError(e as Error);
      }
    }
  }
  async function getAvailableFilters({
    currentLibrary: forceCurrentLibrary,
  }: {
    currentLibrary?: Pickit.CommunityLibraryInterface;
  } = {}) {
    try {
      if (props.mounted === false) {
        return;
      }
      if (disable?.includes('filters')) {
        return;
      }
      const currentLibraryFilters = forceCurrentLibrary || currentLibrary;
      const settings = {
        ...getSettings(true),
      };
      setStatus((status) => ({
        ...status,
        isLoadingFilters: true,
      }));
      if (controllerFilters.current?.signal) {
        controllerFilters.current.abort();
      }
      controllerFilters.current = new AbortController();
      const libraryFilters =
        currentLibraryFilters?.preferences?.filters?.enabled
          ?.filter((r) => {
            if (!userStatus.product?.isBusinessAdmin && r.onlyAdmins) {
              return false;
            }
            return true;
          })
          .map((r) => r.id);
      const properties = currentLibraryFilters?.preferences?.properties;
      if (!Object.keys(availableFilters).length) {
        setAvailableFilters(
          currentLibraryFilters?.preferences?.filters?.order?.reduce<
            Record<string, Pickit.LibraryFilter>
          >((acc, field) => {
            const f = User?.data?.filters?.all?.find(
              (f: any) => f.field === field,
            );
            if (f) {
              acc[f.field] = {
                data: [],
                row: {
                  name: f.name,
                  field: f.field,
                  key: f.field,
                  visible: true,
                  view: f,
                },
              };
            }
            return acc;
          }, {}) || {},
        );
      }
      const newFilters = await props.context.request.getAssetGridFilters({
        fields: [
          ...(libraryFilters || []),
          ...(properties?.map((p) => `properties.${p}`) || []),
          'tags',
        ],
        settings,
        raw: true,
        signal: controllerFilters.current?.signal,
      });
      setAvailableFilters(newFilters);
      setFrozenFilters((oldFilters) => {
        const activeFields = Object.keys(filters);
        const frozenFilters = Object.keys(newFilters).reduce(
          (frozen: Record<string, Pickit.LibraryFilter>, key) => {
            if (activeFields.includes(newFilters[key]?.row?.field as string)) {
              frozen[key] = oldFilters[key] || newFilters[key];
            } else {
              frozen[key] = newFilters[key];
            }
            return frozen;
          },
          {},
        );
        return frozenFilters;
      });
      setStatus((status) => ({
        ...status,
        isLoadingFilters: false,
        errors: status.errors?.filter((e) => e.type !== 'filters'),
        isFiltersLoaded: true,
      }));
    } catch (e) {
      if (
        e !== 'AbortError' &&
        !(e as string)?.includes?.('AbortError') &&
        !(e as any)?.message?.includes?.('AbortError')
      ) {
        if (!(e instanceof Response)) {
          System.Report.logError(e as Error);
        }
        setStatus((status) => ({
          ...status,
          isLoadingFilters: false,
          errors: [
            ...(status.errors || []).filter((a) => a.type !== 'assets'),
            {
              type: 'filters',
              message: e instanceof Response ? e.statusText : 'Error',
            },
          ],
        }));
      }
    }
  }

  async function getFiles(clearCache?: boolean) {
    try {
      if (status.isAllFilesLoaded && !clearCache) {
        return;
      }
      isLoadingRef.current = true;
      if (clearCache) {
        getMultiStore('ASSETGRID_ASSETS', props.id).clear();
        setCurrentPage(0);
      }
      setStatus((status) => ({
        ...status,
        isLoading: clearCache || false,
        isLoadingMore: true,
        isLoadingAssets: true,
        totalFiles: null,
      }));
      const settings = {
        ...getSettings(clearCache),
      };
      controller.current?.abort();
      controller.current = new AbortController();
      const newData = await props.context.request.getAssetGridAssets(settings, {
        signal: controller.current?.signal,
      });
      setAssetGridUserStatus(newData.userStatus);
      if (newData?.collection) {
        setCurrentCollection(newData.collection);
        currentCommunitySlug.current = newData.collection.communitySlug;
      }
      if (newData?.currentLibrary) {
        setCurrentLibrary(newData.currentLibrary);
      }
      if (clearCache) {
        getAvailableFilters({
          currentLibrary: newData.currentLibrary,
        });
      }
      setCurrentPage((page) => page + 1);
      const totalFiles = [...(clearCache ? [] : allFiles), ...newData.data]
        .length;
      if (!clearCache) {
        getMultiStore('ASSETGRID_ASSETS', renderId).addOrUpdate(
          newData.data.map((i: Pickit.FileInterface) => ({ _id: i._id })),
        );
      } else {
        getMultiStore('ASSETGRID_ASSETS', renderId).replace(
          newData.data.map((i: Pickit.FileInterface) => ({ _id: i._id })),
        );
      }
      props.context.setData({
        store: 'files',
        type: 'merge',
        key: '_id',
        data: newData.data,
      });
      allFilesRef.current = [
        ...(clearCache ? [] : allFilesRef.current),
        ...newData.data,
      ];
      setStatus((status) => ({
        ...status,
        isLoadingMore: false,
        isLoading: false,
        isLoadingAssets: false,
        isAllFilesLoaded: !newData.nextPageExists,
        totalFiles:
          clearCache || !status.totalFiles
            ? newData.totalResults
            : status.totalFiles,
        isError: false,
        errors: status.errors?.filter((e) => e.type !== 'assets'),
        isReady: true,
      }));
      isLoadingRef.current = false;
      return allFilesRef.current;
    } catch (e) {
      isLoadingRef.current = false;
      if (
        e === 'AbortError' ||
        (e as string)?.includes?.('AbortError') ||
        (e as any)?.message?.includes?.('AbortError')
      ) {
        return;
      }
      if (!(e instanceof Response)) {
        System.Report.logError(e as Error);
      }
      isLoadingRef.current = false;
      setStatus((status) => ({
        ...status,
        isLoadingMore: false,
        isLoading: false,
        isAllFilesLoaded: true,
        isLoadingAssets: false,
        errors: [
          ...(status.errors || []).filter((a) => a.type !== 'assets'),
          {
            type: 'assets',
            message: e instanceof Response ? e.statusText : 'Error',
            code: e instanceof Response ? e.status : 0,
          },
        ],
        isReady: true,
      }));
    }
  }

  async function load(clearCache: boolean) {
    controller.current?.abort();
    controllerFilters.current?.abort();
    controllerMetadata.current?.abort();
    getFiles(clearCache);
    getMetadata();
  }

  useEffect(() => {
    if (props.onCollection && currentCollection?._id) {
      props.onCollection(currentCollection);
    }
  }, [currentCollection]);

  useEffect(() => {
    if (
      entry?.isIntersecting &&
      !status.errors?.find((v) => v.type === 'assets') &&
      !status.isLoading &&
      !status.isLoadingMore &&
      allFilesRef?.current?.length
    ) {
      getFiles();
    }
  }, [entry?.isIntersecting, status]);

  useEffect(() => {
    setFilters({
      ...(props.filters || {}),
      ...(props.onlyFileTypes ? { 'file.ext': props.onlyFileTypes } : {}),
      ...(props.defaultFilters || {}),
    });
  }, [props.filters]);

  useEffect(() => {
    const isEqual = fastDeepEqual(props.forceFilters, forceFilters);
    if (!isEqual) {
      setForceFilters(props.forceFilters || {});
    }
  }, [props.forceFilters]);

  useEffect(() => {
    if (props.disable) {
      setDisable(props.disable);
    }
  }, [props.disable]);

  useEffect(() => {
    load(true);
  }, [props.communitySlug]);

  useEffect(() => {
    load(true);
  }, [filters, activeShortcuts, forceFilters, view.sortBy]);

  function groupByParse(file: Pickit.FileInterface) {
    const key = view.groupBy || 'createdAt';
    if (!file?.[key]) {
      return;
    }
    return {
      key: startOfDay(new Date(file?.[key] as string)).getTime(),
      title: isToday(new Date(file?.[key] as string))
        ? 'Today'
        : isYesterday(new Date(file?.[key] as string))
          ? 'Yesterday'
          : format(
              file?.[key] ? new Date(file?.[key] as string) : new Date(),
              'LLLL do, yyyy',
            ),
    };
  }

  let renderFiles = [...allFiles];
  if (props.maxResults) {
    renderFiles = renderFiles.slice(0, props.maxResults);
  }

  const groups = useMemo(() => {
    if (view.groupBy) {
      let groups = [];
      for (let i = 0; i < renderFiles.length; i++) {
        const file = renderFiles[i];
        const parse = groupByParse(file);
        if (!parse) {
          continue;
        }
        const existingGroup = groups.find((group) => group.key === parse.key);
        if (!existingGroup) {
          groups.push({
            key: parse.key,
            title: parse.title,
            data: [file],
          });
        } else {
          existingGroup.data.push(file);
        }
      }
      if (view.sortBy?.field === 'createdAt' || !view.sortBy?.field) {
        groups = groups.map((group) => {
          group.data = group.data.sort(
            (a, b) =>
              new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
          );
          if (view.sortBy?.direction === 'ascending') {
            group.data = group.data.reverse();
          }
          return {
            ...group,

            data: group.data,
          };
        });
      }
      if (view.sortBy?.field === 'createdAt' || !view.sortBy?.field) {
        groups = groups.sort((a, b) => b.key - a.key);
        if (view.sortBy?.direction === 'ascending') {
          groups = groups.reverse();
        }
      }
      return groups;
    }
    return null;
  }, [view.groupBy, renderFiles, viewport]);

  async function onFileSelect(
    event: MouseEvent,
    file: Pickit.FileInterface,
    forceSelect?: boolean,
  ) {
    const isMultiSelect =
      props.selectBehaviour === 'multiple' ||
      event.metaKey ||
      event.ctrlKey ||
      event.shiftKey ||
      forceSelect;
    const isOtherAssetsSelected =
      getMultiStore('FILES_SELECTED', renderId).state?.length > 1;
    const isSelectActive = !!getMultiStore('FILES_SELECTED', renderId)?.state
      ?.length;
    const isSelected = getMultiStore('FILES_SELECTED', renderId).getByKey(
      file._id,
    );

    if (props.multiSelect === false) {
      getMultiStore('FILES_SELECTED', renderId).replace([
        {
          _id: file._id,
          selectedAt: new Date().getTime(),
        },
      ]);
      return;
    }

    if (!event.shiftKey) {
      const currentIndex = allFiles.findIndex((f) => f._id === file._id);
      lastClickedIndex.current = currentIndex;
    }

    if (event.shiftKey && !event.metaKey && !event.ctrlKey && isSelectActive) {
      const items = getMultiStore('ASSETGRID_ASSETS', renderId)?.state; // Your array of all file items
      const currentIndex = items.findIndex((f) => f._id === file._id);

      // Shift is held down, find the nearest selected index and select everything between
      let nearestSelectedIndex = -1;
      const direction = currentIndex > lastClickedIndex.current ? -1 : 1;

      // Determine the range to search for the nearest selected index
      const start = Math.min(currentIndex, lastClickedIndex.current);
      const end = Math.max(currentIndex, lastClickedIndex.current);

      // Look for the nearest selected index in the direction from lastClickedIndex to currentIndex
      for (let i = start; i <= end; i += direction) {
        if (getMultiStore('FILES_SELECTED', renderId).getByKey(items[i]?._id)) {
          nearestSelectedIndex = i;
          break;
        }
      }

      // If no selected index is found in the direction, use the other end of the range
      if (nearestSelectedIndex === -1) {
        nearestSelectedIndex = lastClickedIndex.current;
      }

      // Select all files between the nearest selected index and the current index
      const rangeStart = Math.min(nearestSelectedIndex, currentIndex);
      const rangeEnd = Math.max(nearestSelectedIndex, currentIndex);
      const filesToSelect = items.slice(rangeStart, rangeEnd + 1);
      getMultiStore('FILES_SELECTED', renderId).addOrUpdate(
        filesToSelect.map((f) => ({
          _id: f._id,
          selectedAt: new Date().getTime(),
        })),
      );
      return;
    }

    if (!isMultiSelect && !isSelected) {
      getMultiStore('FILES_SELECTED', renderId).replace([
        {
          _id: file._id,
          selectedAt: new Date().getTime(),
        },
      ]);
      return;
    }
    if (!isMultiSelect && isSelected) {
      if (isOtherAssetsSelected) {
        getMultiStore('FILES_SELECTED', renderId).replace([
          {
            _id: file._id,
            selectedAt: new Date().getTime(),
          },
        ]);
        return;
      }
      getMultiStore('FILES_SELECTED', renderId).replace([]);
      return;
    }

    // Toggle = add or remove, replace = clear everything else
    getMultiStore('FILES_SELECTED', renderId)[
      isMultiSelect ? 'toggle' : 'replace'
    ]({
      _id: file._id,
    });
  }

  async function onFileClick(
    event: MouseEvent<HTMLElement>,
    file: Pickit.FileInterface,
    fileToolbar: ReturnType<typeof useAssetActions>,
  ) {
    event.preventDefault();
    event.stopPropagation();
    if (
      lastClickedTime &&
      new Date().getTime() - lastClickedTime.current < 200 &&
      props.onFileDoubleClick
    ) {
      await props.onFileDoubleClick(event, file, fileToolbar, renderFiles);
      return;
    }
    lastClickedTime.current = new Date().getTime();
    if (!props.onFileClick) {
      const isSelectActive = !!getMultiStore('FILES_SELECTED', renderId)?.state
        ?.length;

      if (props.multiSelect === false) {
        getMultiStore('FILES_SELECTED', renderId).replace([
          {
            _id: file._id,
            selectedAt: new Date().getTime(),
          },
        ]);
        return;
      }

      if (!event.shiftKey) {
        const currentIndex = allFiles.findIndex((f) => f._id === file._id);
        lastClickedIndex.current = currentIndex;
      }

      if (
        event.shiftKey &&
        !event.metaKey &&
        !event.ctrlKey &&
        isSelectActive
      ) {
        onFileSelect(event, file);
        return;
      }

      onFileSelect(event, file);
      return;
    }
    if (props.onFileDoubleClick) {
      setTimeout(() => {
        props.onFileClick?.(event, file, fileToolbar, renderFiles);
      }, 200);
    } else {
      props.onFileClick(event, file, fileToolbar, renderFiles);
    }
  }

  if (typeof props.mounted === 'boolean' && !props.mounted) {
    return <></>;
  }

  function setFilter(
    filterName: string,
    row: Pickit.LibraryActiveFilterRow[] | null,
    prefs?: { replace?: boolean },
  ) {
    setStatus((status) => ({
      ...status,
      isLoadingFilters: true,
    }));
    setFilters((filters) => {
      if (row === null) {
        delete filters[filterName];
        return { ...filters };
      }
      if (prefs?.replace) {
        filters[filterName] = row;
        return { ...filters };
      }
      row.forEach((r) => {
        const isActive = filterRowValueIsActive(filters[filterName], r.value);
        if (r.modifier === '$and') {
          const existingValue = filters[filterName]?.[0]?.value as string[];
          const valueIsAlreadySelected = existingValue?.includes(
            filterValueToString(r.value),
          );
          if (valueIsAlreadySelected && existingValue?.length === 1) {
            delete filters[filterName];
            return;
          }
          filters[filterName] = [
            {
              ...r,
              value: [
                ...((existingValue || []) as string[]).filter((val) => {
                  return !(r.value as string[]).includes(val);
                }),
                ...(valueIsAlreadySelected
                  ? []
                  : Array.isArray(r.value)
                    ? r.value
                    : [r.value]),
              ],
            },
          ];
        } else if (
          r.value === '' ||
          (isActive && filters[filterName]?.length === 1)
        ) {
          delete filters[filterName];
        } else if (isActive) {
          filters[filterName] = filters[filterName]?.filter(
            (val) =>
              filterValueToString(val.value) !== filterValueToString(r.value),
          );
        } else if (!filters[filterName]) {
          filters[filterName] = Array.isArray(r.value) ? r.value : [r];
        } else if (prefs?.replace) {
          filters[filterName] = Array.isArray(r.value) ? r.value : [r];
        } else {
          filters[filterName] = [...filters[filterName], r];
        }
      });
      return { ...filters };
    });
  }

  const centerTabs: AssetGridCenterTabs[] = [
    {
      id: 'tags',
      name: 'Tags',
      icon: 'tags',
      content: <AssetGridTags />,
      disabled:
        metadata?.metaFilters?.tags?.filter((d) => !!d.value)?.length === 0,
      disabledTooltip: 'No assets with tags found',
    },
    ...(currentLibrary?.preferences?.enableFolders ||
    currentLibrary?.nameId === 'brandassets'
      ? ([
          {
            id: 'folders',
            name: 'Folders',
            icon: 'folder',
            content: <AssetGridFolders />,
            disabled: false,
            disabledTooltip: 'No assets with tags found',
          },
        ] as AssetGridCenterTabs[])
      : []),
    ...(props.customCenterTabs || []),
  ];

  const activeCenterTab = centerTabs.find((c) => c.id === activeCenterTabId);

  function applyShortcut(id: ShortcutIds) {
    const row = shortCutsDataRows.find((r) => r.id === id);

    if (row?.settings) {
      preShortcutSettings.current = {
        shortcutId: row.id,
        ...view,
      };
      setView((v) => ({
        ...v,
        ...row.settings,
      }));
    }
  }

  function clearShortcutGroup(groupId: string) {
    setActiveShortcuts(
      activeShortcuts.filter((s) => !s.id.startsWith(groupId)),
    );
    const settings = preShortcutSettings.current;

    if (settings?.shortcutId.startsWith(groupId)) {
      setView((v) => ({
        ...v,
        ...settings,
      }));
      preShortcutSettings.current = null;
    }
  }

  const isFiltering =
    !!Object.keys(filters || {})?.length || !!activeShortcuts?.length;
  const statusBarColor = activeShortcuts?.find((p) => p?.id?.includes('trash'))
    ? 'red'
    : 'primary';

  const assetsError = status.errors?.find((f) => f.type === 'assets');
  const filtersError = status.errors?.find((f) => f.type === 'filters');

  return (
    <AssetGridContext.Provider
      value={{
        id: renderId,
        mounted: props.mounted ?? true,
        context: props.context,

        userStatus: assetGridUserStatus,

        currentPage,
        allFiles,
        getFiles,
        getAvailableFilters,
        getMetadata,
        metadata,

        viewport,

        disable,
        setDisable: (disable) => {
          setDisable(disable);
        },

        // groupBy,
        // setGroupBy: (groupBy) => {
        //   findSettings.current.groupBy = groupBy;
        //   setGroupBy(groupBy);
        //   getFiles(true);
        // },

        // displayAs,
        // setDisplayAs,
        // gridSize,
        // setGridSize,

        // sortBy,
        // setSortBy: (sortBy) => {
        //   findSettings.current.sortBy = sortBy;
        //   setSortBy(sortBy);
        //   getFiles(true);
        // },

        view: {
          ...view,
          tableFields: view.tableFields?.length
            ? view.tableFields
            : viewport === 'xs' || viewport === 'sm'
              ? ['file.previews', 'name', 'file.ext', 'menu']
              : ['file.previews', 'name', 'file.ext', 'menu'],
        },
        setView(key, value) {
          setView((v) => ({
            ...v,
            [key]: value,
          }));
        },

        activity: {
          isFiltering,
        },

        selectedFiles,

        availableFilters,
        frozenFilters,

        currentLibrary,
        currentCollection,
        currentFolder,
        currentFolderId,
        setCurrentFolderId: (folderId: string | undefined) => {
          setCurrentFolderId(folderId);
        },
        communitySlug: props.communitySlug || currentCollection?.communitySlug,

        onFileClick,
        onFileSelect,
        fileToolbar: props.fileToolbar,
        fileToolbarCustom: props.fileToolbarCustom,

        activeFilters: filters,
        forceFilters: props.forceFilters,
        defaultFilters: props.defaultFilters,
        combinedFilters,
        setFilter,
        setFilters: (filters) => {
          setFilters({ ...filters });
        },

        setParam: (key, data) => {
          setParams((params) => ({
            ...params,
            [key]: data,
          }));
        },

        status,

        shortcuts,
        setActiveShortcuts: (
          shortcuts: {
            id: ShortcutIds;
            filter?: Pickit.LibraryActiveFilters;
          }[],
          prefs,
        ) => {
          const ids = shortcuts.map((s) => s.id);
          setActiveShortcuts([...shortcuts]);

          const settings = preShortcutSettings.current;

          if (settings?.shortcutId && !ids?.includes(settings?.shortcutId)) {
            setView((v) => ({
              ...v,
              ...settings,
            }));
            preShortcutSettings.current = null;
          }

          ids.filter((id) => !!id).forEach((id) => applyShortcut(id));
        },
        toggleActiveShortcut: (shortcut) => {
          const { id, group } = shortcut;
          if (!activeShortcuts.find((s) => s.id === id) && id) {
            applyShortcut(id);
          }

          setActiveShortcuts((active) => {
            const ids = active.map((s) => s.id);
            const settings = preShortcutSettings.current;

            // Handle group uniqueness: remove shortcuts that start with group.id
            let updatedActive = active;
            if (group?.unique) {
              const groupIdPrefix = `${group.id}.`; // Example: "approvals.needed."
              updatedActive = active.filter(
                (a) => !a.id.startsWith(groupIdPrefix),
              );

              // If removing shortcuts, apply their settings before removing
              const removedShortcuts = active.filter((a) =>
                a.id.startsWith(groupIdPrefix),
              );
              removedShortcuts.forEach((removed) => {
                const removedSettings = preShortcutSettings.current;
                if (removedSettings?.shortcutId === removed.id) {
                  setView((v) => ({
                    ...v,
                    ...removedSettings,
                  }));
                }
              });
            }

            if (settings?.shortcutId === id && ids.includes(id)) {
              setView((v) => ({
                ...v,
                ...settings,
              }));
              preShortcutSettings.current = null;
            }

            // Toggle the shortcut
            if (ids.includes(id)) {
              return updatedActive.filter((a) => a.id !== id);
            }

            return [...updatedActive, shortcut];
          });
        },

        activeShortcuts,
        activeShortcutIds: activeShortcuts.map((s) => s.id),
        activeShortcutsData,
        activeShortcutsFilter,
        forceFilterShortcuts: props.forceFilterShortcuts,

        leftPanelInjection: props.leftPanelInjection,

        isInPopup: props.isInPopup,

        viewportRef: props.viewportRef,

        readOnly: props.readOnly,

        multiSelect: props.multiSelect !== false,

        uploadProps: props.uploadProps || {},
      }}
    >
      <Group
        w="100%"
        gap="xs"
        wrap="nowrap"
        align="flex-start"
        pos="relative"
        style={{ overflow: 'visible' }}
      >
        {!disable?.includes('leftPanel') && (
          <AssetGridLeftPanel
            forceLeftPanelSection={props.forceLeftPanelSection}
            {...(props.panelProps?.leftPanel || {})}
          />
        )}
        {!disable?.includes('centerPanel') && (
          <Stack
            w="100%"
            style={{
              gap: '2px', // Override mantine weird rem logic
              overflow: 'visible',
            }}
            pos="static"
          >
            {props.toolbarTopInjection}
            {(!disable?.includes('toolbar') ||
              !!props.toolbarLeftInjection ||
              !!props.toolbarLeftAfterUploadInjection ||
              !!props.toolbarRightInjection) && (
              <Paper
                p={viewport === 'xxs' || viewport === 'xs' ? 'md' : 'lg'}
                pos={isFiltering ? 'relative' : 'sticky'}
                top={-1}
                style={{ zIndex: 10 }}
              >
                <Group w="100%" wrap="nowrap" align="stretch">
                  <Group w="100%">
                    {activeShortcuts?.find((f) =>
                      f?.id?.startsWith('trash'),
                    ) ? (
                      <>
                        <PixiButton
                          size="md"
                          color="dark"
                          variant="light"
                          leftSection={<PixiIcon name="chevron-left" />}
                          onClick={() => {
                            clearShortcutGroup('trash');
                          }}
                        >
                          Go back
                        </PixiButton>
                        <PixiConfirm
                          title="Are you sure?"
                          description="This will permenently remove all files in the trash can. This can't be undone."
                          confirmLabel="Delete files"
                          confirmProps={{
                            color: 'red',
                          }}
                          onConfirm={async (close) => {
                            if (allFiles.length > 0) {
                              await props.context.deleteFiles(
                                allFiles.map((file) => file._id),
                              );
                            }
                            close(true);
                            createAppToast({
                              id: `deleted_files${allFiles.map((file) => file._id).join('&')}`,
                              message: 'Files were deleted successfully',
                              icon: 'trash-can',
                            });
                          }}
                        >
                          <PixiButton
                            size="wide-md"
                            color="red"
                            variant="filled"
                            loading={false}
                            style={{
                              flexShrink: 1,
                              marginRight: 0,
                            }}
                            leftSection={
                              <PixiIcon
                                name="trash-can"
                                size="lg"
                                variant="filled"
                              />
                            }
                          >
                            Empty trash can
                          </PixiButton>
                        </PixiConfirm>
                      </>
                    ) : (
                      <>
                        {props.toolbarLeftInjection ? (
                          props.toolbarLeftInjection
                        ) : (
                          <></>
                        )}

                        {!disable?.includes('upload') && !props.readOnly && (
                          <>
                            <AssetGridUpload
                              onUpload={(file) => {
                                getMultiStore(
                                  'ASSETGRID_ASSETS',
                                  props.id,
                                ).addOrUpdate(file, true);
                              }}
                            />
                            <AssetGridUploadBrandAsssets
                              onUpload={(files) => {
                                getMultiStore(
                                  'ASSETGRID_ASSETS',
                                  props.id,
                                ).addOrUpdate(files, true);
                              }}
                            />
                          </>
                        )}

                        {props.toolbarLeftAfterUploadInjection ? (
                          props.toolbarLeftAfterUploadInjection
                        ) : (
                          <></>
                        )}
                      </>
                    )}
                  </Group>
                  <Group
                    align="stretch"
                    gap="5"
                    wrap="nowrap"
                    justify="flex-end"
                    ml="auto"
                    style={{ flexShrink: 0 }}
                  >
                    {props.toolbarRightInjection}
                    {!disable?.includes('toolbar') && <AssetGridToolbar />}
                  </Group>
                </Group>
              </Paper>
            )}
            <AssetGridContributorToolbar />
            <AssetGridUploadProgress />
            {props.toolbarBelowInjection}
            {!disable?.includes('status') && isFiltering && (
              <Box
                w="100%"
                pos={!isFiltering ? 'relative' : 'sticky'}
                top={0}
                style={{
                  zIndex: isFiltering ? 10 : 9,
                }}
              >
                <Paper p={isFiltering ? 'xs' : 0}>
                  <Paper
                    py={isFiltering ? 'lg' : 'sm'}
                    px={
                      isFiltering
                        ? 'lg'
                        : viewport === 'xxs' || viewport === 'xs'
                          ? 'md'
                          : 'lg'
                    }
                    styles={(theme) => ({
                      root: {
                        background: isFiltering
                          ? colorScheme === 'dark'
                            ? rgba(theme.colors[statusBarColor][5], 0.2)
                            : theme.colors[statusBarColor][0]
                          : undefined,
                      },
                    })}
                    style={{
                      transition: 'background .5s, borderColor .5s',
                    }}
                  >
                    <AssetGridStatusBar
                      isFiltering={isFiltering}
                      color={statusBarColor}
                    />
                  </Paper>
                </Paper>
              </Box>
            )}
            {props.withCenterTabs && !!centerTabs?.length && (
              <Paper
                p="sm"
                px={viewport === 'xxs' || viewport === 'xs' ? 'md' : 'lg'}
              >
                <Group gap="xs" mb={activeCenterTabId ? 'sm' : undefined}>
                  {centerTabs.map((tab) => (
                    <PixiTooltip
                      key={tab.id}
                      label={tab.disabledTooltip}
                      disabled={!tab.disabled || !tab.disabledTooltip}
                    >
                      <PixiButton
                        onClick={() => {
                          if (tab.disabled) {
                            return;
                          }
                          setActiveCenterTabId(
                            activeCenterTabId === tab.id ? '' : tab.id,
                          );
                        }}
                        styles={
                          tab.disabled
                            ? {
                                root: {
                                  opacity: 0.4,
                                  pointerEvents: 'auto',
                                },
                                inner: {
                                  cursor: 'auto',
                                },
                              }
                            : {}
                        }
                        size="xs"
                        variant={
                          activeCenterTabId === tab.id ? 'light' : 'transparent'
                        }
                        pl={activeCenterTabId !== tab.id ? 0 : undefined}
                        color={
                          activeCenterTabId === tab.id ? 'primary' : 'dark'
                        }
                        rightSection={
                          <PixiIcon
                            name={
                              activeCenterTabId === tab.id
                                ? 'chevron-up'
                                : 'chevron-down'
                            }
                            size={10}
                          />
                        }
                      >
                        {tab.name}
                      </PixiButton>
                    </PixiTooltip>
                  ))}
                </Group>
                {activeCenterTab && activeCenterTab?.content}
              </Paper>
            )}
            {!disable?.includes('assets') && (
              <Box w="100%" pos="relative">
                <Overlay
                  ref={containerRef}
                  style={{ pointerEvents: 'none', opacity: 0 }}
                />
                {(status.isLoading || !viewport) && !status.isLoadingMore ? (
                  <Paper w="100%" p="lg">
                    <Group w="100%" justify="center">
                      <Loader />
                    </Group>
                  </Paper>
                ) : assetsError ? (
                  <>
                    <Paper
                      w="100%"
                      p={viewport === 'xxs' || viewport === 'xs' ? 'md' : 'xl'}
                      {...props.panelProps?.centerPanel}
                    >
                      <ErrorMessage
                        title={
                          assetsError?.code === 403
                            ? 'Access denied'
                            : 'An unexpected error occured'
                        }
                        message={
                          assetsError?.code === 403
                            ? 'You do not have access to this collection.'
                            : 'Something went wrong when trying to load the files. We have been notified. Please try later or contact support.'
                        }
                        tryAgain={async () => {
                          await getFiles(true);
                        }}
                      />
                    </Paper>
                  </>
                ) : (
                  !status.isLoading && (
                    <>
                      {view.groupBy &&
                      view.sortBy?.field !== 'name' &&
                      groups?.length ? (
                        <Stack
                          style={{
                            gap: '2px', // Override mantine weird rem logic
                          }}
                        >
                          {groups
                            .filter((session) =>
                              customFilter
                                ? !!session.data.filter(customFilter)?.length
                                : !!session.data?.length,
                            )
                            .map((session) => {
                              return (
                                <AssetGridGroup
                                  title={session.title}
                                  key={session.title}
                                  files={session.data}
                                >
                                  {customFilter ? (
                                    <AssetGridRender
                                      files={session.data.filter(customFilter)}
                                      containerRef={containerRef}
                                    />
                                  ) : (
                                    <AssetGridRender
                                      files={session.data}
                                      containerRef={containerRef}
                                    />
                                  )}
                                </AssetGridGroup>
                              );
                            })}
                        </Stack>
                      ) : (
                        <Paper
                          p={
                            viewport === 'xxs' || viewport === 'xs'
                              ? 'sm'
                              : 'lg'
                          }
                          {...props.panelProps?.centerPanel}
                        >
                          <AssetGridRender
                            files={renderFiles}
                            containerRef={containerRef}
                          />
                          {status.isAllFilesLoaded &&
                          (metadata?.stats?.totalAssets === 0 ||
                            status.totalFiles === 0) &&
                          props.collectionId ? (
                            <PixiText ta="center" c="dimmed" p="md">
                              There are no assets in this collection
                            </PixiText>
                          ) : (
                            <></>
                          )}
                          {status.isAllFilesLoaded &&
                            !status.isLoading &&
                            (metadata?.stats?.totalAssets === 0 ||
                              status.totalFiles === 0) &&
                            !props.collectionId && (
                              <PixiText c="dimmed" ta="center" my="xl">
                                No results
                              </PixiText>
                            )}
                          {status.isAllFilesLoaded &&
                          !status.isLoadingMore &&
                          !status.isLoading &&
                          !Object.keys(filters || {})?.length ? (
                            props.emptyView
                          ) : (
                            <></>
                          )}
                          {status.isAllFilesLoaded &&
                          !status.isLoadingMore &&
                          !status.isLoading &&
                          status.totalFiles === 0 ? (
                            props.noResultsView
                          ) : (
                            <></>
                          )}
                        </Paper>
                      )}
                    </>
                  )
                )}
                {status.isAllFilesLoaded &&
                  !status.isLoading &&
                  !!status.totalFiles && (
                    <PixiText c="dimmed" ta="center" my="xl">
                      All assets loaded
                    </PixiText>
                  )}
                {status.isLoadingAssets && (
                  <Group w="100%" my="xl" justify="center">
                    <Loader />
                  </Group>
                )}
                {!status.isLoadingMore &&
                  !status.isLoading &&
                  !status.isAllFilesLoaded && (
                    <>
                      <div
                        style={{
                          width: '100%',
                          height: 100,
                          marginBottom: 40,
                        }}
                        ref={ref}
                      />
                    </>
                  )}
              </Box>
            )}
          </Stack>
        )}
        {(!disable?.includes('rightPanel') ||
          props.panelProps?.rightPanel?.keepPlaceholder) && (
          <AssetGridRightPanel {...(props.panelProps?.rightPanel || {})} />
        )}
      </Group>
    </AssetGridContext.Provider>
  );
}
