import { useMediaContext } from 'hooks';
import { Button, Choice, Flex, Icon, Input, Switch } from 'elements_v2';
import * as yup from 'yup';
import React, { useEffect, useReducer, useRef, useState } from 'react';
import Color from 'color';
import { PinkToolbar } from 'elements_v2/PinkToolbar';
import { randomString } from 'utils';
import ColorForm from 'views/_Manage/BrandAssets/parts/forms/ColorForm';
import AddColor from './AddColor';
import { ModuleMetadataInputGroup } from '../modules/ModuleMetadata';

export const ColorSectionData = {
  colors: 'COLORS',
};
export const ColorSectionMetadataProperties = {
  colorSection: yup.object().shape({
    colorInfo: yup.mixed().oneOf(['outside', 'overlay']),
    colorPerRow: yup.number(),
    colorNoSpace: yup.boolean(),
    colorInfoDisplay: yup.array(['hex', 'rgb', 'pms', 'cmyk']),
    colorAlignment: yup.mixed().oneOf(['fill', 'top', 'center', 'bottom']),
    colorThumbnail: yup.mixed().oneOf(['rectangle', 'square', 'circle']),
  }),
};

export function ColorSectionMetadata({ Module, error, columns }) {
  return (
    <>
      <ModuleMetadataInputGroup label="Thumbnail">
        <Input.Field
          label="No space between colors"
          error={error?.path === 'colorSection.colorNoSpace' && error?.message}
          custom={
            <Switch
              active={Module.metadata?.colorSection?.colorNoSpace}
              label="Enabled"
              onChange={(value) =>
                Module.setMetadata({
                  colorSection: {
                    ...(Module?.metadata?.colorSection || {}),
                    colorNoSpace: value,
                  },
                })
              }
            />
          }
        />
        <Input.Field
          label="Columns"
          error={error?.path === 'colorSection.colorPerRow' && error?.message}
          custom={
            <Choice
              value={Module.metadata?.colorSection?.colorPerRow || 2}
              onChange={(value) =>
                Module.setMetadata({
                  colorSection: {
                    ...(Module?.metadata?.colorSection || {}),
                    colorPerRow: value,
                  },
                })
              }
              options={new Array(columns).fill(1).map((_, key) => ({
                key: key + 1,
                value: key + 1,
                label: `${key + 1} column`,
              }))}
            />
          }
        />
      </ModuleMetadataInputGroup>
      <ModuleMetadataInputGroup label="Colors">
        <Input.Field
          label="Color info"
          disabled={Module.metadata?.colorSection?.colorAlignment === 'fill'}
          error={error?.path === 'colorSection.colorInfo' && error?.message}
          custom={
            <Choice
              value={Module.metadata?.colorSection?.colorInfo || 'overlay'}
              onChange={(value) =>
                Module.setMetadata({
                  colorSection: {
                    ...(Module?.metadata?.colorSection || {}),
                    colorInfo: value,
                  },
                })
              }
              options={[
                { key: 'overlay', value: 'overlay', label: 'Text overlay' },
                { key: 'outside', value: 'outside', label: 'Text outside' },
              ]}
            />
          }
        />
        <Input.Field
          label="Color thumbnail"
          disabled={Module.metadata?.colorSection?.colorAlignment === 'fill'}
          error={
            error?.path === 'colorSection.colorThumbnail' && error?.message
          }
          custom={
            <Choice
              value={
                Module.metadata?.colorSection?.colorThumbnail || 'rectangle'
              }
              onChange={(value) =>
                Module.setMetadata({
                  colorSection: {
                    ...(Module?.metadata?.colorSection || {}),
                    colorThumbnail: value,
                  },
                })
              }
              options={[
                { key: 'rectangle', value: 'rectangle', label: 'Rectangle' },
                { key: 'square', value: 'square', label: 'Square' },
                { key: 'circle', value: 'circle', label: 'Circle' },
              ]}
            />
          }
        />
      </ModuleMetadataInputGroup>
      <ModuleMetadataInputGroup label="Alignment">
        {(Module.module.type === 'COLORS_WITH_IMAGE' ||
          Module.module.type === 'COLORS_WITH_TEXT') && (
          <Input.Field
            label={`${
              Module.module.type === 'COLORS_WITH_IMAGE' ? 'Image' : 'Text'
            } position`}
            error={error?.path === 'contentPosition' && error?.message}
            custom={
              <Choice
                value={Module?.metadata.contentPosition || 'right'}
                onChange={(value) =>
                  Module?.setMetadata({
                    contentPosition: value,
                  })
                }
                options={[
                  { key: 'left', value: 'left', label: 'Left' },
                  { key: 'right', value: 'right', label: 'Right' },
                ]}
              />
            }
          />
        )}
        {Module.module.type !== 'COLORS_GRID' && (
          <Input.Field
            label="Alignment"
            error={
              error?.path === 'colorSection.colorAlignment' && error?.message
            }
            custom={
              <Choice
                value={
                  Module.metadata?.colorSection?.colorAlignment || 'center'
                }
                onChange={(value) =>
                  Module.setMetadata({
                    colorSection: {
                      ...(Module.metadata?.colorSection || {}),
                      colorAlignment: value,
                      colorThumbnail:
                        value === 'fill'
                          ? 'rectangle'
                          : Module.metadata?.colorSection?.colorThumbnail,
                      colorInfo:
                        value === 'fill'
                          ? 'overlay'
                          : Module.metadata?.colorSection?.colorInfo,
                    },
                  })
                }
                options={[
                  { key: 'top', value: 'top', label: 'Top' },
                  { key: 'center', value: 'center', label: 'Center' },
                  { key: 'bottom', value: 'bottom', label: 'Bottom' },
                  { key: 'fill', value: 'fill', label: 'Fill' },
                ]}
              />
            }
          />
        )}
      </ModuleMetadataInputGroup>
    </>
  );
}

export function ColorItem({
  data,
  moduleData,
  onSort,
  metadata,
  Module,
  button,
  onRemove,
  onDisplayVariants,
}) {
  const colorRef = useRef();
  function renderColorInfo(name, color) {
    try {
      color = Color(color);
    } catch (e) {
      console.error(e);
    }
    return (
      <div
        className="ColorSection__color__info"
        style={{
          color:
            metadata?.colorInfo === 'outside'
              ? 'inherit'
              : color?.isDark?.() === true
                ? '#FFF'
                : color?.isDark?.() === false
                  ? '#000'
                  : '#FFF',
        }}
      >
        {button || (
          <>
            <div className="ColorSection__color__info__data">
              <div className="ColorSection__color__info__data__row">
                <span>{name}</span>
              </div>
              <div className="ColorSection__color__info__data__row">
                <span>HEX &nbsp;</span>
                <span>
                  {data?.data?.value?.color?.replace('#', '').toUpperCase()}
                </span>
              </div>
              <div className="ColorSection__color__info__data__row">
                <span>RGB &nbsp;</span>
                <span>{color.rgb?.().color.join(' ')}</span>
              </div>
              {data?.data?.value?.cmyk && (
                <div className="ColorSection__color__info__data__row">
                  <span>CMYK &nbsp;</span>
                  <span>{data?.data?.value?.cmyk}</span>
                </div>
              )}
              {data?.data?.value?.pantone && (
                <div className="ColorSection__color__info__data__row">
                  <span>PMS &nbsp;</span>
                  <span>{data?.data?.value?.pantone}</span>
                </div>
              )}
            </div>
          </>
        )}
      </div>
    );
  }

  const leftVariants = data?.data?.value?.variants?.slice(
    0,
    data?.data?.value?.variants?.indexOf('main'),
  );
  const rightVariants = data?.data?.value?.variants?.slice(
    data?.data?.value?.variants?.indexOf('main') + 1,
  );

  return (
    <div
      className={`ColorSection__color ${
        button && Module.isEditing ? 'ColorSection__color--button' : ''
      }`}
      ref={Module.isEditing ? colorRef : null}
      colorInfo={metadata?.colorInfo}
    >
      <div className="ColorSection__color__color">
        <div className="ColorSection__color__bg">
          {moduleData?.showVariants && !!leftVariants?.length && (
            <div className="ColorSection__color__bg__variants">
              {leftVariants.map((variant) => (
                <div
                  key={variant.color}
                  className="ColorSection__color__bg__variants__variant"
                  style={{
                    background: variant?.color,
                  }}
                >
                  {renderColorInfo(variant.name, variant.color)}
                </div>
              ))}
            </div>
          )}
          <div
            className="ColorSection__color__bg__color"
            style={{
              background:
                data?.data?.value?.color ||
                (Module?.isEditing ? '#EAEAEA' : undefined),
            }}
          >
            {(metadata?.colorInfo === 'overlay' ||
              !metadata?.colorInfo ||
              button) &&
              renderColorInfo(data?.name, data?.data?.value?.color)}
          </div>
          {moduleData?.showVariants && !!rightVariants?.length && (
            <div className="ColorSection__color__bg__variants">
              {rightVariants.map((variant) => (
                <div
                  key={variant.color}
                  className="ColorSection__color__bg__variants__variant"
                  style={{
                    background: variant?.color,
                  }}
                >
                  {renderColorInfo(variant.name, variant.color)}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      {data?._id && Module.isEditing && onRemove && (
        <PinkToolbar overlay parentRef={colorRef}>
          <Icon bubble button name="TrashFill" onClick={onRemove} />
          {!!data?.data?.value?.variants?.filter((v) => v !== 'main')
            ?.length && (
            <Icon bubble button name="Palette2" onClick={onDisplayVariants} />
          )}
        </PinkToolbar>
      )}
      {metadata?.colorInfo === 'outside' &&
        !button &&
        renderColorInfo(data?.name, data?.data?.value?.color)}
    </div>
  );
}

export default function ColorSection({ Module }) {
  const Brandassets = useMediaContext();
  const [colorGroups, setColorGroups] = useReducer(
    (state, action) => [...action],
    [],
  );
  const [isAddColor, setIsAddColor] = useState(false);

  const colors =
    Module?.data?.colors?.map((color) => {
      return Brandassets.data?.files?.find((f) => f._id === color.colorId);
    }) || [];
  const colorPerRow = Module.metadata?.colorSection?.colorPerRow || 2;

  async function getColors() {
    await Brandassets.getFiles({
      filter: JSON.stringify({
        categories: 'color',
      }),
      sort_by: 'createdAt',
      sort_direction: 'descending',
      limit: 200,
    });
  }

  useEffect(() => {
    if (!Brandassets?.data?.files) {
      getColors();
    }
  }, [Brandassets?.data?.files]);

  useEffect(() => {
    const colors =
      Module?.data?.colors
        ?.map((color) => {
          return Brandassets.data?.files?.find((f) => f._id === color.colorId);
        })
        ?.filter((c) => !!c?._id) || [];
    const colorPerRow = Module.metadata?.colorSection?.colorPerRow || 2;

    // Split into chunks, from stackoverflow
    let groups = [...Array(Math.ceil(colors.length / colorPerRow))].map((_) =>
      colors.splice(0, colorPerRow),
    );

    groups = groups.map((colors) => {
      const arr = new Array(colorPerRow).fill(1);
      colors.forEach((color, index) => (arr[index] = color));
      return { colors: arr, id: randomString(5) };
    });

    if (!groups.length) {
      groups = [
        {
          colors: new Array(colorPerRow).fill(1),
          id: randomString(10),
        },
      ];
    }

    setColorGroups(groups);
  }, [
    Module?.data,
    Brandassets.data?.files,
    Module.metadata?.colorSection?.colorPerRow,
  ]);

  const AddColorItem = (
    <ColorItem
      metadata={Module.metadata?.colorSection}
      Module={Module}
      button={
        Module.isEditing ? (
          <>
            <Flex
              style={{ width: '100%', marginBottom: 10 }}
              justifyContent="space-between"
              alignItems="center"
            >
              <h1 style={{ color: '#000', fontSize: 16 }}>Add color</h1>
              <Button
                inline
                size="small"
                onClick={() => setIsAddColor(true)}
                className="no_global_style"
              >
                Add new color
              </Button>
            </Flex>

            {isAddColor && (
              <ColorForm
                context={Brandassets}
                libraryId="brandassets"
                isOpen={isAddColor}
                onClose={() => setIsAddColor(false)}
                onSave={(color) => {
                  Module.setData('colors', [
                    ...(Module.data?.colors || []),
                    { colorId: color._id },
                  ]);
                }}
              />
            )}
            <AddColor
              value={Module?.data?.colors}
              showDropdown
              trigger={
                <Button
                  primary
                  background="#515151"
                  className="no_global_style"
                >
                  Add color
                </Button>
              }
              onlyButton
              onChange={(colors) => {
                Module.setData('colors', colors);
              }}
            />
          </>
        ) : (
          <></>
        )
      }
    />
  );

  const className = ['ColorSection__colors'];

  if (Module.metadata?.colorSection?.colorNoSpace) {
    className.push('ColorSection__colors--colorNoSpace');
  }

  if (Module.metadata?.colorSection?.colorAlignment) {
    className.push(
      `ColorSection__colors--${Module.metadata?.colorSection?.colorAlignment}`,
    );
  }

  if (Module.metadata?.colorSection?.colorThumbnail) {
    className.push(
      `ColorSection__colors--${Module.metadata?.colorSection?.colorThumbnail}`,
    );
  }
  return (
    <div
      className="ColorSection"
      columns={Module.metadata?.colorSection?.colorPerRow}
    >
      {/* <div className="ColorSection__tabs">
      <Button inline><Icon name="plus-lg" /> Add tab</Button>
    </div> */}
      <div
        className={className.join(' ')}
        style={{ alignItems: Module.metadata?.colorSection?.alignItems }}
      >
        {colorGroups.map((data) => {
          return (
            <>
              <div className="ColorSection__colors__row">
                {data?.colors
                  ?.filter((color) => !!color)
                  .map((_color) => {
                    const color =
                      colors.find((c) => c?._id === _color?._id) || _color;
                    return (
                      <>
                        {color !== 1 ? (
                          <ColorItem
                            data={color}
                            moduleData={Module?.data?.colors?.find(
                              (c) => c.colorId === color?._id,
                            )}
                            onSort={(dragFile, dropFile) => {
                              if (!dropFile?._id || !dragFile?._id) {
                                return false;
                              }
                              const dropIndex = Module.data.colors.findIndex(
                                (f) => f.colorId === dropFile._id,
                              );
                              const dragIndex = Module.data.colors.findIndex(
                                (f) => f.colorId === dragFile._id,
                              );
                              const dropItem = Module.data.colors[dropIndex];
                              const dragItem = Module.data.colors[dragIndex];
                              Module.data.colors.splice(dropIndex, 1, dragItem);
                              Module.data.colors.splice(dragIndex, 1, dropItem);
                              Module.setData('colors', [...Module.data.colors]);
                            }}
                            onRemove={() =>
                              Module.setData(
                                'colors',
                                Module?.data?.colors.filter(
                                  (c) => c.colorId !== color?._id,
                                ),
                              )
                            }
                            Module={Module}
                            onDisplayVariants={() => {
                              Module.setData(
                                'colors',
                                Module?.data?.colors.map((c) =>
                                  c.colorId === color?._id
                                    ? {
                                        ...c,
                                        showVariants: !c.showVariants,
                                      }
                                    : c,
                                ),
                              );
                            }}
                            metadata={Module.metadata?.colorSection}
                          />
                        ) : (
                          AddColorItem
                        )}
                      </>
                    );
                  })}
              </div>
            </>
          );
        })}
        {colorGroups?.[colorGroups?.length - 1]?.colors?.filter((r) => r !== 1)
          ?.length === colorPerRow &&
          Module.isEditing && (
            <Flex width="100%" padding="10px 0 0 0">
              <Button
                as="div"
                onClick={() =>
                  setColorGroups([
                    ...colorGroups,
                    {
                      colors: new Array(colorPerRow).fill(1),
                      id: randomString(10),
                    },
                  ])
                }
                pixi
                transparent
                style={{ marginTop: 0, height: 39 }}
                className="no_global_style"
              >
                <Icon name="plus-lg" />
                Add row
              </Button>
            </Flex>
          )}
      </div>
    </div>
  );
}
