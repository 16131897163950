import { useEffect, useRef } from 'react';

/**
 * Check if value has changed.
 * @returns {boolean} The value has changed.
 */
export const useCompare = (val) => {
  const prevVal = usePrevious(val);
  return prevVal !== val;
};

const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};
