import { Button, Intro } from 'elements_v2';
import * as React from 'react';

interface WelcomeProps {
  next: () => void;
}

const Welcome: React.FC<WelcomeProps> = (props: WelcomeProps) => {
  return (
    <Intro noPadding>
      <Intro.Message>
        <h1>Pickit Desktop</h1>
        <p>Thank you for downloading the Pickit Desktop application.</p>
      </Intro.Message>
      <Button primary inline pixi size="xl" onClick={props.next}>
        Continue
      </Button>
    </Intro>
  );
};

export default Welcome;
