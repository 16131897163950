import { Box, Group } from '@mantine/core';
import PixiButton from '@pixi/elements/Button';
import PixiPopup, { PixiPopupProps } from '@pixi/elements/Popup';
import { openAssetPreview } from '@pixi/AppController';
import { useContextFromCollection } from 'hooks/useContextFromCollection';
import * as React from 'react';
import FilesWithHook from '../../views/Files/parts/FilesWithHook';
import { AssetGrid } from './AssetGrid/AssetGrid';
import { AssetGridProps } from './AssetGrid/Helpers';
import useLibraries from 'hooks/useLibraries';

const FileBrowserPopup = ({
  onClose,
  type,
  isOpen,
  zIndex,
  popupProps,
  assetGridProps,
}: {
  type?: 'media' | 'documents';
  isOpen: boolean;
  zIndex?: number;
  onClose?: () => void;
  popupProps?: Partial<PixiPopupProps>;
  assetGridProps?: Partial<AssetGridProps>;
}) => {
  const Libraries = useLibraries();
  const [selectedFile, setSelectedFile] = React.useReducer(
    (
      state: null | Pickit.FileInterface,
      action: null | Pickit.FileInterface,
    ) => (!action ? null : action),
    null,
  );

  React.useEffect(() => {
    if (selectedFile?._id) {
      openAssetPreview(selectedFile);
    }
  }, [selectedFile?._id]);

  return (
    <>
      <PixiPopup
        size={1400}
        fullHeight
        onClose={() => {
          onClose?.();
        }}
        zIndex={zIndex}
        bodyProps={{
          w: 1400,
          maw: '100%',
          h: '100%',
        }}
        title="Viewing"
        actions={
          <Group w="100%">
            <PixiButton
              ml="auto"
              size="wide-lg"
              color="dark"
              variant="glass"
              onClick={() => onClose?.()}
            >
              Done
            </PixiButton>
          </Group>
        }
        opened={isOpen}
        {...popupProps}
      >
        {type && (
          <Group w="100%" h="100%" bg="gray.1" pl="xs">
            <AssetGrid
              id="fileBrowserPopup"
              context={Libraries[type]}
              isInPopup
              {...assetGridProps}
            />
          </Group>
        )}
      </PixiPopup>
    </>
  );
};

export default FileBrowserPopup;
