import * as yup from 'yup';
import React from 'react';
import { randomString } from 'utils';
import Icon from './icon.svg?react';
import IconVideo from './icon_video.svg?react';

export default [
  {
    moduleId: 'IMAGE_TITLE_TEXT_BLOCKS',
    menuGroupIds: ['TEXT', 'HEADER'],
    moduleInterface: {
      blocks: 'IMAGE_TITLE_TEXT_BLOCKS',
    },
    status: {
      isEmpty: (data) => {
        return false;
      },
    },
    getModuleDefaultData: () => ({
      blocks: [
        {
          blockId: randomString(10),
          file: null,
          title: 'Column #1',
          text: 'Edit this text',
        },
        {
          blockId: randomString(10),
          file: null,
          title: 'Column #2',
          text: 'Edit this text',
        },
        {
          blockId: randomString(10),
          file: null,
          title: 'Column #3',
          text: 'Edit this text',
        },
      ],
    }),
    moduleMetadataInterface: {
      columnSize: yup.mixed().oneOf(['two', 'three', 'four']),
      thumbnailType: yup.mixed().oneOf(['circle', 'square', 'auto', 'gallery']),
      thumbnailFit: yup.mixed().oneOf(['contain', 'cover']),
      noPadding: yup.boolean(),
      backgroundColor: yup.string(),
      textColor: yup.string(),
      fileVariantId: yup.string(),
    },
    menuContent: {
      key: 'IMAGE_TITLE_TEXT_BLOCKS',
      icon: <Icon />,
      name: 'Columns',
    },
  },
];
