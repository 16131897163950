import React from 'react';
// import "./style.scss";

export default function useDataStore(defaultStore) {
  const memoizedReducer = React.useCallback((state, actions) => {
    if (!Array.isArray(actions)) {
      actions = [actions];
    }
    for (let i = 0; i < actions.length; i++) {
      const action = actions[i];
      if (action.type === 'replace') {
        if (!action.store) {
          return { ...action.data };
        }
        if (typeof action.data === 'function') {
          action.data = action.data(state[action.store]);
        }
        state[action.store] = action.data;
      }
      if (typeof state[action.store] === 'undefined') {
        state[action.store] = [];
      }
      if (action.type === 'parse') {
        state[action.store] = [...action.func(state[action.store])];
      }
      if (action.type === 'merge') {
        if (!action.key) {
          const newArray = [...state[action.store], ...action.data];
          state[action.store] = newArray.filter((item, pos) => {
            return newArray.indexOf(item) === pos;
          });
        } else {
          action.data.forEach((item) => {
            const existingItem = state[action.store].find(
              (row) => row[action.key] === item[action.key],
            );
            const existingItemKey = state[action.store].findIndex(
              (row) => row[action.key] === item[action.key],
            );
            if (!existingItem) {
              state[action.store] = [...state[action.store], item];
            } else {
              state[action.store][existingItemKey] = {
                ...existingItem,
                ...action.data.find(
                  (t2) => t2[action.key] === existingItem[action.key],
                ),
              };
            }
          });
          state[action.store] = [...state[action.store]];
        }
      }
      if (action.type === 'push') {
        if (Array.isArray(action.data)) {
          state[action.store] = [...state[action.store], ...action.data];
        } else {
          state[action.store] = [...state[action.store], action.data];
        }
      }
      if (action.type === 'prepend') {
        if (Array.isArray(action.data)) {
          state[action.store] = [...action.data, ...state[action.store]];
        } else {
          state[action.store] = [action.data, ...state[action.store]];
        }
      }
      if (action.type === 'delete') {
        if (Array.isArray(action.data)) {
          state[action.store] = state[action.store].filter(
            (file) =>
              !action.data.find((f) => f[action.key] === file[action.key]),
          );
        } else {
          state[action.store] = state[action.store].filter(
            (file) => file[action.key] !== action.data[action.key],
          );
        }
      }
      if (action.type === 'update') {
        const data = state[action.store].find(
          (doc) => doc[action.key] === action.data?.[action?.key],
        );
        const dataIndex = state[action.store].findIndex(
          (doc) => doc[action.key] === action.data?.[action?.key],
        );
        state[action.store][dataIndex] = {
          ...(data || {}),
          ...action.data,
        };
        state[action.store] = [...state[action.store]];
      }
    }
    return {
      ...state,
    };
  }, []);
  const [data, setData] = React.useReducer(memoizedReducer, defaultStore);

  /**
   * Parse the data to always use latest stateData.
   * @param {*} prefs
   * @param {*} rows
   */
  function parseData(prefs, rows) {
    if (!data[prefs.store]?.length) {
      return rows;
    }
    return rows
      .filter((row) => {
        return !!data[prefs.store].find(
          (dataRow) => dataRow[prefs.key] === row[prefs.key],
        );
      })
      .map((row) => ({
        ...data[prefs.store].find(
          (dataRow) => dataRow[prefs.key] === row[prefs.key],
        ),
      }));
  }

  function reset() {
    setData({
      type: 'replace',
      data: defaultStore,
    });
  }

  return {
    setData,
    data,
    parseData,
    reset,
  };
}
