import { Button, Icon, Intro } from 'elements_v2';
import { useRouter } from 'hooks/useRouter';
import * as React from 'react';
import { useNative } from 'views/Native/context';

interface FinishProps {
  next: () => void;
}

const Finish: React.FC<FinishProps> = (props: FinishProps) => {
  const Native = useNative();
  const Router = useRouter();
  return (
    <Intro noPadding>
      <Intro.Illustration
        style={{ width: 80, marginBottom: 15, textAlign: 'center' }}
      >
        <Icon name="check-circle" style={{ fontSize: 56 }} />
      </Intro.Illustration>
      <Intro.Message>
        <h1>All done!</h1>
        <p>Enjoy Pickit Desktop</p>
      </Intro.Message>
      <Button
        primary
        inline
        pixi
        size="large"
        onClick={async () => {
          await window.pickitNative.install();
          Router.history.replace('/');
          if (Native.settings.openAtLogin) {
            Native.openAtLogin(Native.settings.openAtLogin);
          }
          Native.setSettingsValue('wizardCompleted', true, true);
        }}
      >
        Start using Pickit
      </Button>
    </Intro>
  );
};

export default Finish;
