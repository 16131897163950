import React, { ReactNode, useContext, useReducer } from 'react';
import { useUserContext } from 'hooks/useUserContext';
import asyncPool from 'utils/async-pool';
import { FoldersContext, FoldersContextInterface } from 'contexts/Folders';
import { getStore } from '@pixi/store';

export const useFolders = (): FoldersContextInterface =>
  useContext(FoldersContext);
export const FoldersProvider = ({ children }: { children: ReactNode }) => {
  const User = useUserContext();
  const request = User.request.files;
  const [data, setData] = useReducer(
    (
      state: Pickit.FolderInterface[],
      action:
        | { type: string; data: Pickit.FolderInterface }
        | Pickit.FolderInterface[],
    ) => {
      let newState = [];
      if (!Array.isArray(action) && action.type === 'update') {
        newState = state.map((r) =>
          r._id === action.data?._id ? action.data : r,
        );
      } else {
        newState = [...(action as Pickit.FolderInterface[])];
      }

      getStore('FOLDERS').addOrUpdate(newState);
      return newState;
    },
    [],
  );

  async function saveFolder(
    folderData: Partial<Pickit.FolderInterface>,
  ): Promise<Pickit.FolderInterface> {
    const isNew = !folderData?._id;
    const newFolder = await request.folders.saveFolder(folderData);
    if (isNew) {
      setData([...data, newFolder]);
    } else {
      setData({
        type: 'update',
        data: newFolder,
      });
    }
    return newFolder;
  }
  function findFolders(libraryId: string): Pickit.FolderInterface[] {
    return data.filter((folder) => folder.library === libraryId);
  }
  function getFolderPath(folderId: string): string[] {
    const path: string[] = [];
    let folder = findFolderById(folderId);
    while (folder) {
      path.unshift(folder._id);
      if (!folder.parentFolders?.length) {
        break;
      }
      folder = findFolderById(folder.parentFolders?.[0]);
    }
    return path;
  }
  function findFoldersByLibrary(
    library?: Pickit.CommunityLibraryInterface,
  ): Pickit.FolderInterface[] {
    if (!library) {
      return [];
    }
    return data.filter(
      (folder) =>
        folder.library === library._id || folder.library === library.nameId,
    );
  }
  async function getFolders(): Promise<void> {
    const response = await request.folders.getFolders();
    setData(response.data);
  }

  async function deleteFolder(folder: Pickit.FolderInterface): Promise<void> {
    setData(data.filter((d) => d._id !== folder._id));
    await request.folders.deleteFolder(folder._id);
    await asyncPool(
      5,
      data.filter((d) => {
        return d.parentFolders?.includes(folder._id);
      }),
      async (folder: Pickit.FolderInterface) => {
        await request.folders.deleteFolder(folder._id);
      },
    );
  }

  function findFoldersByParent(parentId: string): Pickit.FolderInterface[] {
    return data.filter((folder) => folder.parentFolders?.includes(parentId));
  }

  function findFolderById(id: string): Pickit.FolderInterface | undefined {
    const existingFolder = data.find((folder) => folder._id === id);
    return existingFolder;
  }

  function countFoldersInFolder(
    id: string,
    alreadyCounted: string[] = [],
  ): number {
    return data.reduce((acc, folder) => {
      if (alreadyCounted.includes(folder._id)) return acc;
      if (folder.parentFolders?.includes(id)) {
        acc += 1 + countFoldersInFolder(folder._id, [...alreadyCounted, id]);
      }
      return acc;
    }, 0);
  }

  const values: FoldersContextInterface = {
    data,
    findFolders,
    getFolders,
    findFolderById, // Gets folder from State only.
    findFoldersByParent,
    findFoldersByLibrary,
    getFolderPath,
    saveFolder,
    deleteFolder,
    countFoldersInFolder,
  };
  return (
    <FoldersContext.Provider value={values}>{children}</FoldersContext.Provider>
  );
};
