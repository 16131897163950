import { useEffect } from 'react';
import useIntegrationConfig from '../useIntegrationConfig';
import useJobs from '../useJobs';
import useNetworkFileSystemAPI from './useNetworkFileSystemAPI';

export default function useNetworkFileSystem(props) {
  const config = useIntegrationConfig('network_file_system');
  const { API_ID, enableCapabilities } = config;
  useEffect(() => {
    enableCapabilities(['jobs', 'nested', 'recent', 'search', 'hasDrives', 'approval']);
  }, []);

  const NetworkFileSystemAPI = useNetworkFileSystemAPI({
    API_ID,
    NetworkFileSystemAuth: props.NetworkFileSystemAuth,
  });
  const Jobs = useJobs({ API_ID, API: NetworkFileSystemAPI });

  return {
    ...NetworkFileSystemAPI,
    ...Jobs,
    ...config,
  };
}
