import { Button, Icon, Input, Popup } from 'elements_v2';
import React, { useEffect, useState } from 'react';

export default function TextForm(props) {
  const [name, setName] = useState(props.metadata?.name);
  const [value, setValue] = useState(props.metadata?.data?.value || '');
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    if (props.onlyForm) {
      props.onChange({
        name,
        value,
      });
    }
  }, [props.onlyForm, name, value]);

  const form = (
    <>
      <Input.Field
        inline
        label="Name"
        placeholder="Name this text"
        autoFocus
        value={name}
        onChange={setName}
      />
      <Input.Field
        inline
        label="Content"
        type="wysiwyg"
        advanced
        style={{ marginTop: 15 }}
        autoFocus
        value={value}
        onChange={setValue}
      />
    </>
  );
  if (props.onlyForm) {
    return form;
  }

  return (
    <Popup
      isOpen={props.isOpen}
      width={750}
      contentStyle={{ minHeight: '55vh' }}
      title={props.title || (props.metadata?._id ? 'Editing text' : 'Add text')}
      description={props.description}
      actions={
        <>
          <Button
            inline
            style={{ marginRight: 'auto' }}
            onClick={props.onClose}
          >
            Cancel
          </Button>
          <Button
            inline
            primary
            loading={isSaving}
            disabled={!value || !name}
            onClick={async () => {
              setIsSaving(true);
              const data = {
                value,
                type: 'text',
              };
              const doc = await props.context.saveDocument({
                _id: props.metadata?._id,
                data,
                name,
                folders: props.toFolder,
                libraries: [props.libraryId],
                categories: ['text'],
              });
              props.onSave?.(doc);
              props.onClose();
              setIsSaving(false);
              setValue('');
            }}
          >
            <Icon name="justify-left" />{' '}
            {props.submitLabel ||
              (props.metadata?._id ? 'Save changes' : 'Add text')}
          </Button>
        </>
      }
      onClose={props.onClose}
    >
      {form}
    </Popup>
  );
}
