
import { useDocumentsContext } from 'hooks/useDocumentsContext';
import { useMediaContext } from 'hooks/useMediaContext';
import { googleMimetoExt } from 'utils';
import asyncPool from 'utils/async-pool';

export default function useNetworkFileSystemAPI(props) {
  const { NetworkFileSystemAuth } = props;
  const Media = useMediaContext();
  const Documents = useDocumentsContext();

  function getFoldersFromItems(items) {
    return items.filter((item) => getValue('isFolder', item));
  }

  async function getAllDrives() {
    /**
     * Getting all available drivers
     */

    return await NetworkFileSystemAuth.getConnections();
  }

  async function getAllFolderItems(itemId, driveId, signal, includeFolders = true) {
    /**
     * Getting all folder items
     */
    const items = await getFolderItems(driveId, itemId, signal);
    let results = items;
    try {
      if (!includeFolders) {
        results = items.filter((item) => !getValue('isFolder', item));
      }
    } catch (e) {}
    return results;
  }

  function getFilesFromItems(items) {
    /**
     * Get all files
     */
    if(items) {
      return items
        .filter((item) => !getValue('isFolder', item))
        .map((item) => item);
    } else {
      return [];
    }
  }

  async function getAllFolderItemsNested(itemId,driveId,signal,onProgress) {
    /**
     * Explore drive
     * @type {*}
     */
    const items = await getAllFolderItems(itemId, driveId,signal);
    if(signal?.aborted) {
      return items;
    }
    if(onProgress) {
      onProgress({
        total: items?.length,
      });
    }
    let results = [];
    try {
      // Limit by 3 for very long paths with a lot of folders
      if(items) {
        await asyncPool(3, items, async (item) => {
          if (getValue('isFolder', item)) {
            const folderItems = await getAllFolderItemsNested(
              getValue('id', item),
              driveId
            );
            folderItems?.forEach((file) => results.push(file));
          }
          results.push(item);
        });
      }
    } catch (e) {
      
      console.error(e);
    }

    return results;
  }

  async function getRecentFiles(driveId) {
    /**
     * Getting files that were changed less than a week ago
     * @type {*}
     */
    const data = await NetworkFileSystemAuth.request(
      driveId,
      'recentFiles',
      '/'
    );
    return data?.data;
  }

  async function getDrive(driveId) {
    /**
     * Getting drive details
     * @type {*}
     */
    const data = await NetworkFileSystemAuth.getConnectionDetails(driveId);
    return data;
  }

  async function getDriveItems(itemId, driveId) {
    /**
     * Getting all items for the folder
     * @type {*}
     */
    const data = await NetworkFileSystemAuth.request(
      driveId,
      'getDriveItems',
      ''
    );
    return data?.data;
  }

  async function getDriveItem(itemId, driveId) {
    /**
     * Getting exact item from drive
     * @type {*}
     */
    const data = await NetworkFileSystemAuth.request(
      driveId,
      'getDriveItem',
      itemId?.startsWith('/') ? itemId : getPathFromId(itemId)
    );
    return data?.data?.length ? data?.data[0] : {};
  }

  async function getFolder(itemId, driveId) {
    /**
     * Getting exact folder details
     */
    return getDriveItem(itemId, driveId);
  }

  async function getFolderItems(driveId, folderId, signal) {
    /**
     * Getting items list based on folder path
     * @type {*}
     */
    const data = await NetworkFileSystemAuth.request(
      driveId,
      'getDriveItems',
      getPathFromId(folderId),
      "",
      signal,
    );
    return data?.data;
  }

  async function search(driveId, query) {
    /**
     * Search files in NFS
     * @type {*}
     */

    const data = await NetworkFileSystemAuth.request(
      driveId,
      'search',
      '/',
      query
    );
    return data?.data || [];
  }

  async function getImportedReferences(items) {
    /**
     * Checking which items were imported
     * @type {{documents: *[], media: *[]}}
     */
    const libraries = {
      media: [],
      documents: [],
    };
    items.forEach((item) => {
      if (item.name && !item.folder) {
        const extension = item.fileExtension || googleMimetoExt(item.mimeType);

        if (Media.isFileSupported(null, extension?.toLowerCase())) {
          libraries.media.push(item.id);
        }
        if (Documents.isFileSupported(null, extension?.toLowerCase())) {
          libraries.documents.push(item.id);
        }
      }
    });
    const mediaFiles = await Media.importCheckReferences(
      'network_file_system',
      libraries.media
    );
    const documentFiles = await Documents.importCheckReferences(
      'network_file_system',
      libraries.documents
    );
    return [...mediaFiles, ...documentFiles];
  }

  const translations = {
    FETCHING_DRIVE: `Fetching drive from ${NetworkFileSystemAuth.connectionType}`,
    FETCHING_ALL_FILES: `Fetching all files from ${NetworkFileSystemAuth.connectionType}`,
    CHECKING_ALREADY_IMPORTED: 'Checking for any file changes',
    REPLACING_UPDATED_FILES: 'Replacing changed files',
    CONNECTED: 'Starting upload',
    UPLOADING: 'Uploading file',
    PROCESSING: 'Processing file',
    AI: 'Processing file (AI)',
    JOB_CHECKED: 'All files imported',
    IMPORTING_FILES: 'Uploading files',
    ADDING_FILES_TO_COLLECTION: 'Adding already uploaded files to collection',
    ERROR_COLLECTION_DELETED: 'Sync failed. Collection has been deleted.',
  };

  function translateCode(code) {
    if (translations[code]) {
      return translations[code];
    }
    return code;
  }

  function getSiteValue(key, item) {
    return getValue(key, item);
  }

  function getDriveValue(key, item) {
    return getValue(key, item);
  }

  function createItemPath(item, currentPath = [], items) {
    if (item.parent?.full_path) {
      item.folderId = createIdFromPath(item.parent?.full_path);
    }
    if (item?.folderId) {
      const parent = items.find((i) => getItemId(i) === item?.folderId);
      if (parent) {
        if (parent?.parent?.full_path) {
          parent.folderId = createIdFromPath(parent.parent?.full_path);
        }
        item.path = [getItemId(parent), ...currentPath];
        if (
          parent?.folderId &&
          items.find((i) => getItemId(i) === parent?.folderId)
        ) {
          return createItemPath(parent, item.path, items);
        }
      } else {
        item.path = currentPath;
      }
    }
    return item.path.join('/');
  }

  function getPathFromId(id) {
    return decodeURIComponent(atob(id));
  }

  function createIdFromPath(path) {
    return btoa(encodeURIComponent(path));
  }

  function getItemId(item) {
    return createIdFromPath(item?.full_path);
  }

  function getSite() {
    return {};
  }

  function getValue(key, item, preferences) {
    switch (key) {
      case 'id':
        return getItemId(item);
      case 'parentId':
        return getItemId(item?.parent);
      case 'driveId':
        return item?.tenant_id;
      case 'parentName':
        return {
          name: item?.parent?.full_path.split('/').pop() || '',
        };
      case 'idPath':
        return createItemPath(item, [], preferences?.items);
      case 'isFolder':
        return item?.is_directory;
      case 'isFolderEmpty':
        return item?.is_folder_empty;
      case 'folderParentReferenceId':
        return createIdFromPath(item?.parent?.full_path);
      case 'name':
        return item?.name || 'Unreachable or removed remote name';
      case 'lastModified':
        return item?.is_directory
          ? undefined
          : new Date(item?.last_modified * 1000);
      case 'size':
        return item?.size;
      case 'hash':
        return item?.hash;
      case 'extension':
        return item?.name?.split?.('.')?.pop() ?? ' ';
      case 'downloadUrl':
        return item?.download_url
          ? NetworkFileSystemAuth.getDownloadURL(item?.download_url)
          : undefined;
      case 'openUrl':
        return undefined;
      case 'createdBy':
        return undefined;
      case 'pathString':
        return item?.full_path || 'Unreachable or removed remote path';
      default:
        throw new Error(
          `failed getting api item value due to invalid or missing key: ${key}`
        );
    }
  }

  async function processFileForImport(file, job) {
    const fileData = await getDriveItem(getValue('id', file), file.tenant_id);

    return {
      accessUrl: getValue('downloadUrl', fileData),
      file: {
        name: file.name,
        import: {
          from: props.API_ID,
          reference: getValue('id', fileData),
          referenceLocation: job?.itemId, // reference to the location in the api service
          source: job?._id,
          checksum: file?.hash,
        },
        uploadedAt: getValue('lastModified', fileData),
        collections: job?.libraryCollection
          ? [job?.libraryCollection]
          : undefined,
        .../* job?.ownerOverrideRequireApproval ?? */ (job?.requireApproval
          ? {
              approval: {
                status: 'none',
                status_change_reason: 'imported',
              },
            }
          : {}),
      },

      params: {
        ...getImportParams(file, job),
      },
    };
  }

  const getImportParams = (file, job) => {
    return {
      external_file: job?.preferences?.externalFiles,
      thumbnail_from: getValue('downloadUrl', file),
      thumbnail_from_ext: file.name.split('.').pop(),
    };
  };

  return {
    createIdFromPath,
    getAllFolderItems,
    getAllFolderItemsNested,
    getAllDrives,
    getFilesFromItems,
    getFolderItems,
    getFolder,
    getFoldersFromItems,
    getDrive,
    getDriveItem,
    getDriveItems,
    getDriveValue,
    getImportParams,
    getImportedReferences,
    getRecentFiles,
    getSiteValue,
    getSite,
    getValue,
    getPathFromId,
    processFileForImport,
    search,
    translateCode,

    isConnected: NetworkFileSystemAuth.isConnected,
    isConnecting: NetworkFileSystemAuth.isConnecting,
    connectionType: NetworkFileSystemAuth.connectionType,
    connect: NetworkFileSystemAuth.connect,
    disconnect: NetworkFileSystemAuth.disconnect,
    connectedDrivers: NetworkFileSystemAuth.connectedDrivers,
    getConnectionDetails: NetworkFileSystemAuth.getConnectionDetails,

    importedFiles: [
      ...(Media.data.imported_refs_network_file_system || []),
      ...(Documents.data.imported_refs_network_file_system || []),
    ],
  };
}
