import { createWorkerFactory, useWorker } from '@shopify/react-web-worker';
import { usePickitDrive } from 'contexts/Providers/PickitDriveProvider';
import { differenceInHours, isAfter } from 'date-fns';
import { useGetContextFromFile } from 'hooks/useContextFromFile';
import { useDocumentsContext } from 'hooks/useDocumentsContext';
import { useMediaContext } from 'hooks/useMediaContext';
import { useUserContext } from 'hooks/useUserContext';
import { useEffect, useReducer, useState } from 'react';
import asyncPool from 'utils/async-pool';
import { fileToFolderItem } from 'views/_Manage/Drive/Helpers';

const createWorker = createWorkerFactory(() => import('./_workers/ImportFile'));
const createWorkerV2 = createWorkerFactory(
  () => import('./_workers/ImportFileV2'),
);

export default function useJobs(props) {
  // API_ID: 'sharepint' 'dropbox' etc.
  const { API_ID, API } = props;
  const DAILY_SYNC_INTERVAL_HOURS = 4;
  const Media = useMediaContext();
  const Documents = useDocumentsContext();
  const Assets = usePickitDrive();
  const getContextFromFile = useGetContextFromFile();
  const worker = useWorker(createWorker);
  const workerV2 = useWorker(createWorkerV2);
  const libraries = {
    media: Media,
    documents: Documents,
  };
  const User = useUserContext();
  const [forceJob, setForceJob] = useReducer(
    (state, action) => [...action],
    [],
  );
  const [jobs, setJobs] = useReducer((state, action) => {
    if (Array.isArray(action)) {
      return [...action];
    }
    if (action?.type === 'UPDATE_STATUS') {
      return state.map((j) =>
        j._id === action.jobId
          ? { ...j, status: { ...j.status, ...action.status } }
          : j,
      );
    }
    if (action?.type === 'UPDATE_FILE_STATUS') {
      return state.map((j) =>
        j._id === action.jobId
          ? {
              ...j,
              status: {
                ...j.status,
                files: {
                  ...(j.status?.files || {}),
                  [action.fileId]: action.status,
                },
              },
            }
          : j,
      );
    }
  }, []);

  const [jobQueue, setJobQueue] = useReducer(
    (state, action) => [...action],
    [],
  );
  const [isRunningJobs, setIsRunningJobs] = useState(false);

  useEffect(() => {
    if (jobQueue?.length && !isRunningJobs) {
      runJobQueue();
    }
  }, [jobQueue, isRunningJobs]);

  useEffect(() => {
    if (jobs.length && !jobQueue?.length && API.isConnected) {
      const notChecked = jobs.filter((job) => {
        return (
          (!job.status || (!job.status?.isRunning && !job.status?.isChecked)) &&
          !jobQueue.includes(job._id) &&
          (job.occurence === 'daily' || !job.lastRun) &&
          job.status?.type !== 'ERROR' &&
          differenceInHours(new Date(), new Date(job.lastRun)) >=
            DAILY_SYNC_INTERVAL_HOURS
        );
      });
      setJobQueue([...jobQueue, ...notChecked.map((job) => job._id)]);
    }
  }, [jobs]);

  async function getImportedFiles() {
    await Promise.all(
      ['media', 'documents'].map(async (type) => {
        if (type === 'media') {
          await Media.importGetFiles(API_ID);
          if (API_ID === 'microsoft') {
            await Media.importGetFiles('sharepoint');
          }
        }
        if (type === 'documents') {
          await Documents.importGetFiles(API_ID);
          if (API_ID === 'microsoft') {
            await Documents.importGetFiles('sharepoint');
          }
        }
      }),
    );
  }
  async function queueJob(job, force) {
    if (typeof job === 'string') {
      job = jobs.find((j) => j._id === job);
    }
    if (!job?._id) {
      return false;
    }
    setJobQueue([...jobQueue, job._id]);

    if (force && !forceJob.includes(job._id)) {
      setForceJob([...forceJob, job._id]);
    }
  }

  async function runJobQueue() {
    const firstJob = jobQueue?.[0];

    if (isRunningJobs) {
      return false;
    }
    if (!firstJob) {
      setIsRunningJobs(false);
      return false;
    }
    let activeJob = jobs.find((job) => job._id === firstJob);
    if (activeJob.status?.isRunning) {
      setJobQueue(jobQueue.filter((j, key) => !!key));
      return false;
    }
    if (activeJob.jobType === 'multi-folder') {
      setIsRunningJobs(true);

      setJobQueue(jobQueue.filter((j, key) => !!key));
      for (let i = 0; i < activeJob.multiFolderItems.length; i++) {
        const subJob = activeJob.multiFolderItems[i];

        await runJob(
          { ...activeJob, ...subJob, libraryFolder: subJob.libraryFolder },
          (completedJob) => {
            activeJob = completedJob;
          },
        );
      }
      setIsRunningJobs(false);

      return;
    }

    setJobQueue(jobQueue.filter((j, key) => !!key));
    setIsRunningJobs(true);
    await runJob(activeJob);
    setIsRunningJobs(false);
  }

  async function getJobs() {
    const data = await User.request.files.importJobs.getJobs(API_ID);

    if (API_ID === 'microsoft') {
      const backup = await User.request.files.importJobs.getJobs('sharepoint');
      data.jobs = [...data.jobs, ...backup.jobs];
    }

    const jobs = await asyncPool(10, data.jobs, async (job) => {
      if (job.jobType === 'multi-folder') {
        try {
          if (API_ID === 'hubspot_import') {
            // For disconnected drives we stop showing and work with the job
            const availableDrivers = API.connectedDrivers.map(
              (drive) => drive.tenant_id,
            );
            if (!availableDrivers.includes(job.multiFolderItems[0].driveId)) {
              return {};
            }
          }

          for (let i = 0; i < job.multiFolderItems.length; i++) {
            const metadata = await API.getFolder(
              job.multiFolderItems[i].itemId,
              API_ID === 'dropbox'
                ? undefined
                : job.multiFolderItems[i].driveId,
            );
            if (API_ID === 'dropbox' && metadata) {
              job.multiFolderItems[i].itemId = metadata.id;
            }
            job.multiFolderItems[i].metadata = metadata;
          }
        } catch (e) {
          console.error(e);
        }
        return { ...job };
      }
      let metadata;

      switch (API_ID) {
        case 'dropbox':
          metadata = await API.getFolder(job.itemId);
          if (metadata) {
            job.itemId = metadata.id;
          }

          break;

        case 'hubspot_import':
          const availableDrivers = API.connectedDrivers.map(
            (drive) => drive.tenant_id,
          );
          if (availableDrivers.includes(job.driveId)) {
            metadata = await API.getFolder(job.itemId, job.driveId);
          }
          break;

        default:
          try {
            metadata = await API.getFolder(job.itemId, job.driveId);
          } catch (e) {
            console.error(e);
          }
          break;
      }
      return {
        ...job,
        metadata,
      };
    });

    let newJobs = jobs.filter(
      (job) => !!job.metadata || job.jobType === 'multi-folder',
    );
    if (API_ID === 'dropbox') {
      newJobs = jobs.filter(
        // Dropbox filtering
        (job) =>
          (!!job?.metadata?.id && job?.metadata?.['.tag'] !== 'file') ||
          (job.jobType === 'multi-folder' &&
            !!job.multiFolderItems?.find(
              (item) => item.metadata?.['.tag'] !== 'file',
            )?.metadata),
      );
    }
    setJobs(newJobs);
    // setJobQueue(newJobs.map((job) => job._id));
  }

  async function runJob(job, onJobComplete = (completedJob) => {}) {
    if (job?.implementationVersion === 2) {
      await workerV2.runJob(
        job,
        {
          Media,
          Documents,
          API,
          Assets,
          User,
          API_ID,
          fileToFolderItem,
          getContextFromFile,
          libraries,
          setJobs,
          jobs,
        },
        onJobComplete,
      );
      return;
    }
    await worker.runJob(job, {
      Media,
      Documents,
      API,
      Assets,
      User,
      API_ID,
      fileToFolderItem,
      getContextFromFile,
      libraries,
      setJobs,
      jobs,
    });
  }

  async function syncFileChanges(file, onStatus = () => {}) {
    onStatus({ code: 'GETTING_FILE', message: 'Getting file' });
    const item = await API.getDriveItem(
      file.import.reference,
      file.import.referenceLocation,
    );
    let Library;
    if (API.getValue('name', item)) {
      const extension = API.getValue('extension', item);
      if (Media.isFileSupported(null, extension.toLowerCase())) {
        Library = Media;
      }
      if (Documents.isFileSupported(null, extension.toLowerCase())) {
        Library = Documents;
      }
    }
    const hasChanges =
      new Date(file.file.uploaded_at).getTime() <
        new Date(API.getValue('lastModified', item)).getTime() ||
      API.getValue('size', item) !== file.file.contentLength;

    if (hasChanges && !file?.file?.external_file) {
      onStatus({
        code: 'CHANGES_DETECTED',
        message: 'File has been changed. Uploading...',
      });
      const processedItem = API.processFileForImport(item);
      await Library.replaceMedia(
        processedItem.accessUrl,
        file._id,
        API_ID,
        API.getValue('hash', item),
      );
      onStatus({
        code: 'FILE_UPDATED',
        message: 'File updated',
      });
    } else {
      onStatus({ code: 'NO_CHANGES', message: 'No changes' });
    }
  }

  async function importFolder(folder, prefs) {
    const id = API.getValue('id', folder);
    const existingJob = jobs.find(
      (job) =>
        ((job.itemId === id && prefs.jobType !== 'multi-folder') ||
          job.multiFolderItems?.find((item) =>
            prefs.multiFolderItems?.find(
              (propItem) => API.getValue('id', propItem ?? {}) === item.itemId,
            ),
          )) &&
        job.library === prefs.library,
    );
    if (existingJob) {
      return existingJob;
    }
    const job = await User.request.files.importJobs.saveJob(API_ID, {
      jobType: prefs.jobType,
      itemId: prefs.jobType === 'multi-folder' ? undefined : id,
      driveId: API.getValue('driveId', folder),
      library: prefs.library,
      libraryCollection: prefs.libraryCollection,
      occurence: prefs.occurence,
      requireApproval: prefs.requireApproval,
      useApprovalFlow: prefs.useApprovalFlow,
      /*  ownerOverrideRequireApproval: prefs.ownerOverrideRequireApproval,
      ownerOverrideUseApprovalFlow: prefs.ownerOverrideUseApprovalFlow, */
      fileExtensionMap: prefs.fileExtensionMap,
      multiFolderItems: prefs.multiFolderItems,
      preferences: prefs.preferences || {},
      _cache: prefs._cache,
    });
    job.multiFolderItems = job?.multiFolderItems?.map((item) => ({
      ...item,
      metadata:
        prefs.multiFolderItems?.find(
          (prefitem) => prefitem.itemId === item.itemId,
        )?.metadata ?? {},
    }));
    setJobs([
      ...jobs,
      {
        ...job,
        metadata: folder,
      },
    ]);
  }

  return {
    syncFileChanges,

    getImportedFiles,
    getJobs,

    saveJob: async (updates, clearStatus) => {
      const newJob = await User.request.files.importJobs.saveJob(
        API_ID,
        updates,
      );
      setJobs(
        jobs.map((job) =>
          job._id === newJob._id
            ? {
                ...job,
                ...newJob,
                status: clearStatus ? null : job.status,
                multiFolderItems: newJob?.multiFolderItems?.map((item) => ({
                  ...item,
                  metadata:
                    job.multiFolderItems?.find(
                      (oldItem) => oldItem.itemId === item.itemId,
                    )?.metadata ?? {},
                })),
              }
            : job,
        ),
      );
      return newJob;
    },

    deleteJob: async (jobId, prefs) => {
      worker.kill();
      workerV2.kill();
      try {
        setJobQueue([]);
        let timeout = 0;
        await new Promise((resolve) => {
          var interval = setInterval(async () => {
            const killed = await worker.isWorkerKilled();
            const workerV2Killed = await workerV2.isWorkerKilled();
            if ((killed && workerV2Killed) || timeout > 120000) {
              resolve();
              clearInterval(interval);
            }
            timeout += 200;
          }, 200);
        });
        await User.request.files.importJobs.deleteJob(jobId, prefs);
        if (prefs.deleteAllSyncedFiles) {
          Documents.setData({
            type: 'replace',
            store: 'files',
            data: Documents.data.files.filter(
              (file) => file?.import?.source !== jobId,
            ),
          });
          Media.setData({
            type: 'replace',
            store: 'files',
            data: Media.data.files.filter(
              (file) => file?.import?.source !== jobId,
            ),
          });
        }
        setJobs(jobs.filter((job) => job._id !== jobId));
        worker.activate();
        workerV2.activate();
      } catch (e) {
        worker.activate();
        workerV2.activate();

        console.error(e);
        throw e;
      }
    },

    getImportedReferences: async (items) => {
      const libraries = {
        media: [],
        documents: [],
      };
      items.forEach((item) => {
        const name = API.getValue('name', item);
        const isFolder = API.getValue('isFolder', item);
        if (name && !isFolder) {
          const id = API.getValue('id', item);
          const extension = API.getValue('extension', item);
          if (Media.isFileSupported(null, extension.toLowerCase())) {
            libraries.media.push(id);
          }
          if (Documents.isFileSupported(null, extension.toLowerCase())) {
            libraries.documents.push(id);
          }
        }
      });
      let mediaFiles = await Media.importCheckReferences(
        API_ID,
        libraries.media,
        true,
      );
      let documentFiles = await Documents.importCheckReferences(
        API_ID,
        libraries.documents,
        true,
      );
      if (API_ID === 'microsoft') {
        const additionalMediaFiles = await Media.importCheckReferences(
          'sharepoint',
          libraries.media,
          true,
        );
        const additionalDocumentFiles = await Media.importCheckReferences(
          'sharepoint',
          libraries.media,
          true,
        );
        mediaFiles = mediaFiles.concat(additionalMediaFiles);
        documentFiles = documentFiles.concat(additionalDocumentFiles);
      }
      return [...mediaFiles, ...documentFiles];
    },

    jobs,
    queueJob,
    jobQueue,

    importFolder,
  };
}
