import Icon from 'elements_v2/Icon';
import React from 'react';

const AvailabilityError = () => {
  return <></>;
  return (
    <div
      style={{
        zIndex: 999999999999999,
        position: 'fixed',
        backgroundColor: 'rgba(255, 255, 255, 0.9)',
        color: 'red',
        width: '100%',
        textAlign: 'center',
        padding: 20,
        fontSize: 16,
        bottom: 0,
      }}
    >
      <Icon
        name="exclamation-triangle"
        style={{
          color: 'red',
          fontSize: 16,
          marginRight: 10,
        }}
      />
      Some of our customers can right now experience issues with the Pickit
      service due to a Microsoft Western Europe-wide Azure issue. Microsoft is
      actively working to resolve the situation. We appreciate your
      understanding.
    </div>
  );
};

export default AvailabilityError;
