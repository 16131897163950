import { useAppContext } from 'contexts/Providers/AppProvider';
import { Button, Dropdown, Icon, Intro, Message } from 'elements_v2';
import * as React from 'react';
import { isMac, isWindows } from 'utils/platform';
import { useNative } from 'views/Native/context';

interface ColorSchemeProps {
  next: () => void;
}

const ColorScheme: React.FC<ColorSchemeProps> = (props: ColorSchemeProps) => {
  const Native = useNative();
  const { mode } = Native.settings?.window || {};
  const options = [
    {
      value: 'tray',
      name: 'Menu application',
      option: <>Menu application</>,
    },
    { value: 'floating', name: 'Window' },
  ];

  React.useEffect(() => {
    if (!mode) {
      Native.setSettingsValue('window.mode', 'tray');
    }
  }, []);

  return (
    <Intro noPadding>
      <Intro.Illustration
        style={{ width: 80, marginBottom: 15, textAlign: 'center' }}
      >
        <Icon name="window-desktop" style={{ fontSize: 56 }} />
      </Intro.Illustration>
      <Intro.Message>
        <h1>Window</h1>
        <p>
          You can either have the app always accessible through the menu bar or
          have it as a normal application.
        </p>
        <Dropdown
          useV2
          noTriggerWrapper
          value={mode}
          width={270}
          trigger={
            <Button
              style={{
                maxWidth: 280,
                margin: '15px auto 0px auto',
              }}
              rightIcon={
                <Icon
                  name="caret-down-fill"
                  style={{ fontSize: 12, marginLeft: 15 }}
                />
              }
            >
              {options.find((opt) => opt.value === mode)?.name}
            </Button>
          }
        >
          {options.map((option) => (
            <Dropdown.Option
              onClick={() =>
                Native.setSettingsValue('window.mode', option.value)
              }
              key={option.value}
              value={option.value}
            >
              {option.option || option.name}
            </Dropdown.Option>
          ))}
        </Dropdown>
        <Message size="small" style={{ background: 'transparent' }}>
          <p style={{ opacity: 0.65, fontSize: 12 }}>
            {isWindows() &&
              'Menu applications are all the small icons bottom right corner of your computer, next to the clock.'}
            {isMac() &&
              'Menu applications are all the small icons top right corner of your computer, next to the clock.'}
          </p>
        </Message>
      </Intro.Message>
      <Button primary inline pixi size="large" onClick={props.next}>
        Continue
      </Button>
    </Intro>
  );
};

export default ColorScheme;
