import { Icon } from 'elements_v2';
import IconFlag from 'elements_v2/IconFlag';
import React from 'react';

export default React.forwardRef((props, ref) => {
  const { name, ...rest } = props;

  return (
    <Icon
      ref={ref}
      element={
        <IconFlag name={name} style={{ width: '1.5em', height: 'auto' }} />
      }
      {...rest}
    />
  );
});
