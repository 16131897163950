import { useUserContext } from 'hooks';
import { useAssetGridContext } from '../../AssetGridContext';
import { Group, Paper } from '@mantine/core';
import { AssetGridUpload } from './AssetGridUpload';
import PixiText from '@pixi/elements/Text';
import PixiButton from '@pixi/elements/Button';
import PixiIcon from '@pixi/elements/Icon';
import PixiTooltip from '@pixi/elements/Tooltip';
import { getMultiStore, useKeySelector } from '@pixi/store';
import { useColorScheme } from '@pixi/AppController';
import ColorScheme from 'views/Native/Wizard/steps/ColorScheme';
import { useEffect, useState } from 'react';

export default function AssetGridContributorToolbar() {
  const {
    context,
    combinedFilters,
    id,
    currentCollection,
    toggleActiveShortcut,
    activeShortcutIds,
    userStatus,
    setActiveShortcuts,
  } = useAssetGridContext();
  const User = useUserContext();
  const colorScheme = useColorScheme();
  const [contributorInfo, setContributorInfo] = useState<{
    token: Record<string, string>;
    containers: Record<string, string>;
    url: string;
  }>({
    token: {},
    containers: {},
    url: '',
  });
  const contributorUserStatus =
    currentCollection?.permissions?.contributor_users?.find(
      (c) => c.email === User.data?.user?.profile?.email,
    );

  async function getAssetGridContributorInfo() {
    const res = await context.request.getAssetGridContributorInfo({
      collectionId: currentCollection?._id,
    });
    setContributorInfo(res);
  }

  useEffect(() => {
    if (userStatus?.isContributor) {
      getAssetGridContributorInfo();
    }
  }, [userStatus?.isContributor]);

  if (!userStatus?.isContributor) {
    return <></>;
  }
  return (
    <Paper p="sm">
      <Paper
        p="lg"
        bg={colorScheme === 'dark' ? 'primary.9' : 'primary.0'}
        radius="md"
      >
        <Group>
          <AssetGridUpload
            forceTokens={contributorInfo.token}
            forceContainers={contributorInfo.containers}
            forceUrl={contributorInfo.url}
            onUpload={(file) => {
              getMultiStore('ASSETGRID_ASSETS', id).addOrUpdate(file, true);
            }}
          />
          <PixiTooltip label="An admin has given you access to upload to this collection">
            <PixiButton
              size="md"
              variant="transparent"
              leftSection={<PixiIcon name="lock-open" />}
            >
              Contributor access
            </PixiButton>
          </PixiTooltip>
          <Group ml="auto">
            <PixiButton
              variant={
                activeShortcutIds.includes('user_uploaded') ? 'filled' : 'light'
              }
              onClick={() => {
                toggleActiveShortcut({ id: 'user_uploaded' });
              }}
              ml="auto"
              leftSection={<PixiIcon name="loader" />}
            >
              Your uploaded assets
            </PixiButton>
            {contributorUserStatus?.uploads_need_approval ? (
              <PixiButton
                variant={
                  activeShortcutIds.includes('approvals.approved')
                    ? 'filled'
                    : 'light'
                }
                onClick={() => {
                  setActiveShortcuts(
                    activeShortcutIds?.includes('approvals.approved')
                      ? []
                      : [{ id: 'user_uploaded' }, { id: 'approvals.approved' }],
                  );
                }}
                ml="auto"
                leftSection={<PixiIcon name="badge-check" variant="filled" />}
              >
                Your approved assets
              </PixiButton>
            ) : (
              <></>
            )}
          </Group>
        </Group>
      </Paper>
    </Paper>
  );
}
