import ApiService from './_ApiService';

class Customer extends ApiService {
  async getPlans(plans, region) {
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'pickit-api-key': window.pickit.keys.PICKIT_API,
      },
      requestUrl: `${window.pickit.config.SERVICE_URL}/payment`,
    };
    const promises = plans.map((plan) =>
      this.request(`/plan/${plan}?region=${region}`, options).then(
        this.handleJSONResponse
      )
    );
    return Promise.all(promises);
  }

  async getInfoFromVatId(vatId) {
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'pickit-api-key': window.pickit.keys.PICKIT_API,
      },
      requestUrl: `${window.pickit.config.SERVICE_URL}/payment`,
    };
    return this.request(`/vat/${vatId}`, options).then(this.handleJSONResponse);
  }

  async getTaxRates(region) {
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'pickit-api-key': window.pickit.keys.PICKIT_API,
      },
      requestUrl: `${window.pickit.config.SERVICE_URL}/payment`,
    };
    return this.request(`/taxrate?region=${region}`, options).then(
      this.handleJSONResponse
    );
  }

  async getInvoices(customerId, region) {
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'pickit-api-key': window.pickit.keys.PICKIT_API,
      },
      requestUrl: `${window.pickit.config.SERVICE_URL}/payment`,
    };
    return this.request(
      `/customer/${customerId}/invoice?region=${region}`,
      options
    ).then(this.handleJSONResponse);
  }

  async getCoupon(coupon, region) {
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'pickit-api-key': window.pickit.keys.PICKIT_API,
      },
      requestUrl: `${window.pickit.config.SERVICE_URL}/payment`,
    };
    return this.request(`/coupon/${coupon}?region=${region}`, options).then(
      this.handleJSONResponse
    );
  }

  async getCustomer(id, identifier) {
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'pickit-api-key': window.pickit.keys.PICKIT_API,
      },
      requestUrl: `${window.pickit.config.SERVICE_URL}/payment`,
    };
    let endpoint = '';
    if (identifier) {
      endpoint = `/customer/${id}?identifier=${identifier}`;
    } else {
      endpoint = `/customer/${id}`;
    }
    return this.request(endpoint, options).then(this.handleJSONResponse);
  }

  async getSubscription(id) {
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'pickit-api-key': window.pickit.keys.PICKIT_API,
      },
      requestUrl: `${window.pickit.config.SERVICE_URL}/payment`,
    };
    return this.request(`/customer/${id}/subscription`, options).then(
      this.handleJSONResponse
    );
  }

  async getSources(id, region) {
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'pickit-api-key': window.pickit.keys.PICKIT_API,
      },
      requestUrl: `${window.pickit.config.SERVICE_URL}/payment`,
    };
    return this.request(
      `/customer/${id}/source?region=${region}`,
      options
    ).then(this.handleJSONResponse);
  }

  async removeSource(customerId, sourceId, region) {
    const options = {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'pickit-api-key': window.pickit.keys.PICKIT_API,
      },
      requestUrl: `${window.pickit.config.SERVICE_URL}/payment`,
    };
    return this.request(
      `/customer/${customerId}/source/${sourceId}?region=${region}`,
      options
    ).then(this.handleJSONResponse);
  }

  async cancelSubscription(customerId, subscriptionId, region) {
    const options = {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'pickit-api-key': window.pickit.keys.PICKIT_API,
      },
      requestUrl: `${window.pickit.config.SERVICE_URL}/payment`,
    };
    return this.request(
      `/customer/${customerId}/subscription/${subscriptionId}?region=${region}`,
      options
    );
  }

  async createCustomer(data, region) {
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'pickit-api-key': window.pickit.keys.PICKIT_API,
      },
      requestUrl: `${window.pickit.config.SERVICE_URL}/payment`,
      body: JSON.stringify(data),
    };
    return this.request(`/customer?region=${region}`, options).then(
      this.handleJSONResponse
    );
  }

  async updateSubscription(data, customerId, subscriptionId, region) {
    const options = {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'pickit-api-key': window.pickit.keys.PICKIT_API,
      },
      requestUrl: `${window.pickit.config.SERVICE_URL}/payment`,
      body: JSON.stringify(data),
    };
    return this.request(
      `/customer/${customerId}/subscription/${subscriptionId}?region=${region}`,
      options
    );
  }

  async createSource(source, customerId, region) {
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'pickit-api-key': window.pickit.keys.PICKIT_API,
      },
      requestUrl: `${window.pickit.config.SERVICE_URL}/payment`,
      body: JSON.stringify({ source }),
    };
    return this.request(
      `/customer/${customerId}/source?region=${region}`,
      options
    ).then(this.handleJSONResponse);
  }

  async setDefaultSource(id, customerId, region) {
    const options = {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'pickit-api-key': window.pickit.keys.PICKIT_API,
      },
      requestUrl: `${window.pickit.config.SERVICE_URL}/payment`,
    };
    return this.request(
      `/customer/${customerId}/source/${id}?region=${region}`,
      options
    ).then(this.handleJSONResponse);
  }

  async createSubscription(customerId, data, region) {
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'pickit-api-key': window.pickit.keys.PICKIT_API,
      },
      requestUrl: `${window.pickit.config.SERVICE_URL}/payment`,
      body: JSON.stringify(data),
    };
    return this.request(
      `/customer/${customerId}/subscription?region=${region}`,
      options
    ).then(this.handleJSONResponse);
  }

  async updateCustomer(customerId, data, region) {
    const options = {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'pickit-api-key': window.pickit.keys.PICKIT_API,
      },
      requestUrl: `${window.pickit.config.SERVICE_URL}/payment`,
      body: JSON.stringify(data),
    };
    return this.request(`/customer/${customerId}?region=${region}`, options);
  }
}

export default Customer;
