import Info from 'elements/Info';
import { Icon, Tooltip } from 'elements_v2';
import React from 'react';
import './style.scss';

function Box(props) {
  const className = ['Pixi__AppView__box', props.className || ''];

  if (props.desktop) {
    className.push('Pixi__AppView__box--desktop');
  }

  return (
    <div className={className.join(' ')} style={props.style}>
      {!!props.title && (
        <h2>
          {props.title}
          {!!props.info && (
            <Tooltip.Tutorial
              triggerOpen
              triggerStyle={{ marginLeft: 'auto' }}
              trigger={<Icon name="InfoCircle" size="small" />}
            >
              <Tooltip.Tutorial.Title>
                {props.info?.title}
              </Tooltip.Tutorial.Title>
              <Tooltip.Tutorial.Description>
                {props.info?.text}
              </Tooltip.Tutorial.Description>
            </Tooltip.Tutorial>
          )}
        </h2>
      )}
      {props.children}
    </div>
  );
}

function Toolbox(props) {
  const className = ['Pixi__AppView__toolbox', props.className || ''];
  return <div className={className.join(' ')}>{props.children}</div>;
}

function Details(props) {
  const className = ['Pixi__AppView__details', props.className || ''];
  return <div className={className.join(' ')}>{props.children}</div>;
}

function DetailsDetail(props) {
  return (
    <div className="Pixi__AppView__details__detail">
      <span>{props.label}</span>
      <span>{props.value}</span>
    </div>
  );
}

Details.Detail = DetailsDetail;

function Column(props) {
  const className = ['Pixi__AppView__column', props.className || ''];

  if (props.sticky) {
    className.push('Pixi__AppView__column--sticky');
  }

  return (
    <div className={className.join(' ')} style={props.style}>
      {props.children}
    </div>
  );
}
const Main = React.forwardRef((props, ref) => {
  const className = ['Pixi__AppView__main', props.className || ''];
  return (
    <div className={className.join(' ')} ref={ref} style={props.style}>
      {props.children}
    </div>
  );
});
function AppView(props) {
  const className = ['Pixi__AppView', props.className || ''];

  if (props.ignoreResponsive) {
    className.push('Pixi__AppView--ignoreResponsive');
  }

  return (
    <div className={className.join(' ')} style={props.style}>
      <div className="Pixi__AppView__content" style={props.contentStyle}>
        {props.children}
      </div>
    </div>
  );
}
function Header(props) {
  return (
    <div className="Pixi__AppView__header">
      <div className="Pixi__AppView__header__content" style={props.style}>
        <div className="Pixi__AppView__header__content__left">{props.left}</div>
        <div className="Pixi__AppView__header__content__center">
          {props.center}
        </div>
        <div className="Pixi__AppView__header__content__right">
          {props.right}
        </div>
      </div>
    </div>
  );
}

AppView.Column = Column;
AppView.Main = Main;
AppView.Box = Box;
AppView.Toolbox = Toolbox;
AppView.Details = Details;
AppView.Header = Header;

export default AppView;
