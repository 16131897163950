import React from 'react';
import './Loader.css';
import PropTypes from 'prop-types';

const propTypes = {
  className: PropTypes.string,
  active: PropTypes.bool,
};

const defaultProps = {
  className: '',
  active: true,
};

export const Loader = ({ active, big, className = '' }) => {
  let classNameString = 'loader';
  classNameString += className ? ` ${className}` : '';
  classNameString += active ? ' loader--active' : '';
  classNameString += big ? ' loader--big' : '';

  return (
    <div className={classNameString}>
      <div className="loader__dot brand__color__bg"></div>
    </div>
  );
};

Loader.propTypes = propTypes;
Loader.defaultProps = defaultProps;
