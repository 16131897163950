// import useUserStatus from 'hooks/useUserStatus';
import React from 'react';
import { useFileProvider } from 'contexts/File';
import { DocumentsContext } from 'contexts/Documents';

export function DocumentsProvider(props) {
  // const userStatus = useUserStatus();
  const fileProvider = useFileProvider({
    type: 'documents',
    name: 'Documents',
    fileType: 'Document',
    mainContainerId: 'main',
    uploadLabel: 'Add documents',
    libraryName: 'Company Documents',
    integrations: [
      'microsoft365_import',
      'box',
      'dropbox',
      'googledrive',
      'adobeccl',
      'network_file_system',
      'hubspot_import',
    ],
    fileTypesAllowed: [
      'text/csv',
      'application/vnd.openxmlformats-officedocument.presentationml.presentation',
      'application/vnd.openxmlformats-officedocument.presentationml.template',
      'application/pdf',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.template',
      'application/msword',
      'application/vnd.ms-powerpoint',

      /* Excel */
      'application/vnd.ms-excel',
      'application/msexcel',
      'application/x-msexcel',
      'application/x-ms-excel',
      'application/x-excel',
      'application/x-dos_ms_excel',
      'application/xls',
      'application/x-xls',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.template',

      /* iWork */
      'application/vnd.apple.keynote',
      'application/x-iwork-keynote-sffkey',
      'application/vnd.apple.pages',
      'application/x-iwork-pages-sffpages',
      'application/vnd.apple.numbers',
      'application/x-iwork-numbers-sffnumbers',

      /* Google Drive */
      'application/vnd.google-apps.file',
      'application/vnd.google-apps.document',
      'application/vnd.google-apps.presentation',
      'application/vnd.google-apps.spreadsheet',

      /* OpenDocument text document */
      'application/vnd.oasis.opendocument.text',
    ],
    extensionsAllowed: [
      'potx',
      'pptx',
      'ppt',
      'xlsx',
      'xltx',
      'csv',
      'pdf',
      'dotx',
      'doc',
      'docx',
      'key',
      'pages',
      'numbers',
      'gdocs',
      'gslides',
      'gsheets',
      'odt',
    ],
  });

  return (
    <DocumentsContext.Provider value={fileProvider}>
      {props.children}
    </DocumentsContext.Provider>
  );
}
