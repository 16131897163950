import { withRouter } from 'react-router-dom';
import AppStatus from 'AppStatus';
import System from '@pixi/System';
import { ErrorBoundary } from 'react-error-boundary';
import PixiButton from '@pixi/elements/Button';

export const ScrollToTop = withRouter((props) => {
  props.history.listen(() => {
    if (window.location.hash.includes('brand')) {
      return false;
    }
    if (window.location.hash.includes('board')) {
      return false;
    }
    if (window.location.hash.includes('file=')) {
      return false;
    }
    if (window.location.hash.includes('filter=')) {
      return false;
    }
    window.scrollTo(0, 0);
  });
  return props.children;
});

export function ErrorBoundaryView({ children }) {
  return (
    <ErrorBoundary
      fallback={
        <div className="ErrorPage">
          <div className="ErrorPage__overlay">
            <div className="ErrorPage__content">
              <h1>We’re sorry!</h1>
              <p>
                There are some bits of data that didn’t load properly, either
                because of some updates from us or a poor internet connection.
                Your best shot is to reload.
              </p>
              <PixiButton
                onClick={() => {
                  window.location.href = window.origHref || '/';
                }}
                variant="white"
                color="primary"
                fullWidth
              >
                Reload Pickit
              </PixiButton>
            </div>
          </div>
          <AppStatus forceReload />
        </div>
      }
      onError={(error) => {
        System.Report.logError(error);
      }}
    >
      {children}
    </ErrorBoundary>
  );
}
