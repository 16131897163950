import {
  FC,
  ReactNode,
  ReactElement,
  Ref,
  useEffect,
  useState,
  CSSProperties,
  forwardRef,
  cloneElement,
} from 'react';
import { Icon, Tooltip } from 'elements_v2';
import './style.scss';
import { PixiIcon } from 'elements_v2/Icon';

interface TabProps {
  isActive?: boolean;
  hasTabs?: boolean;
  id?: string;
  icon?: PixiIcon;
  label: string | ReactNode;
  wrapperStyle?: CSSProperties;
  style?: CSSProperties;
  header?: string | ReactNode;
  headerStyle?: CSSProperties;
  key?: string;
  children?: ReactNode | ((args: { isActive: boolean }) => ReactNode);
  hidden?: boolean;
  onlyIconsWhenActive?: boolean;
  disabled?: boolean;
  disabledMessage?: ReactNode;
  // any other properties you expect can be added here
}

const Tab = forwardRef<HTMLDivElement, TabProps>(
  (
    {
      isActive = false,
      hasTabs = false,
      id,
      wrapperStyle,
      style,
      header,
      headerStyle,
      children,
    },
    ref,
  ) => {
    const className = ['Pixi Pixi__Form__Tabs__Tab'];
    const wrapperClassName = ['Pixi Pixi__Form__Tabs__TabWrapper'];

    if (isActive) {
      className.push('Pixi__Form__Tabs__Tab--active');
      wrapperClassName.push('Pixi__Form__Tabs__TabWrapper--active');
    }

    if (hasTabs) {
      className.push('Pixi__Form__Tabs__Tab--hasTabs');
    }

    return (
      <div
        className={wrapperClassName.join(' ')}
        id={id}
        style={wrapperStyle}
        ref={ref}
      >
        <div className={className.join(' ')} style={style}>
          {!!header && (
            <div className="Pixi__Form__Tabs__Tab__header" style={headerStyle}>
              {header}
            </div>
          )}
          {typeof children === 'function' ? children({ isActive }) : children}
        </div>
      </div>
    );
  },
);

interface TabsProps {
  children: ReactElement<TabProps>[];
  onChange?: (index: number, id?: string) => void;
  activeTab?: number;
  activeTabId?: string;
  menuStyle?: CSSProperties;
  menuLabel?: string | ReactNode;
  menuLabelIcon?: PixiIcon;
  contentStyle?: CSSProperties;
  tabStyle?: CSSProperties;
  defaultTabRedirect?: () => void;
}

const Tabs: FC<TabsProps> = ({
  children,
  onChange,
  activeTab,
  activeTabId,
  menuStyle,
  menuLabel,
  menuLabelIcon,
  contentStyle,
  tabStyle,
  defaultTabRedirect,
}) => {
  const [activeTabIndex, setActiveTab] = useState<number>(0);
  const tabs = children
    .filter((tab) => !!tab?.props?.children && tab?.props?.hidden !== true)
    .map((child) => child.props);

  useEffect(() => {
    if (onChange) {
      onChange(0, tabs?.[0]?.id || ''); // `0` is the index of the first tab
    }
  }, []);

  useEffect(() => {
    if (typeof activeTab === 'number' && tabs[activeTab]) {
      setActiveTab(activeTab);
      onChange?.(activeTab, tabs[activeTab]?.id);
    }
  }, [activeTab, children]);

  useEffect(() => {
    if (activeTabId) {
      const key = tabs.findIndex((tab) => tab.id === activeTabId);
      setActiveTab(key);
      if (onChange) {
        onChange(key, tabs[key]?.id);
      }
      defaultTabRedirect?.();
    }
  }, [activeTabId, children]);

  const currentTab = children?.filter(
    (tab) => !!tab?.props?.children && tab?.props?.hidden !== true,
  )?.[activeTabIndex];

  return (
    <div
      className={[
        'Pixi Pixi__Form__Tabs',
        ...(currentTab?.props?.onlyIconsWhenActive
          ? ['Pixi__Form__Tabs--onlyIconsMenu']
          : []),
        ...(currentTab?.props?.hasTabs
          ? ['Pixi__Form__Tabs--nestedTabsActive']
          : []),
      ].join(' ')}
    >
      <div className="Pixi Pixi__Form__Tabs__menu" style={menuStyle}>
        {menuLabel ? (
          <div className="Pixi__Form__Tabs__menu__label">
            {menuLabelIcon ? <Icon name={menuLabelIcon} /> : ''}
            <span>{menuLabel}</span>
          </div>
        ) : (
          ''
        )}
        {tabs.map((tab, key) => {
          const className = [
            'Pixi Pixi--clickable Pixi__Form__Tabs__menu__item',
          ];
          if (activeTabIndex === key) {
            className.push('Pixi__Form__Tabs__menu__item--active');
          }
          if (tab.disabled) {
            className.push('Pixi__Form__Tabs__menu__item--disabled');
          }
          const element = (
            <div
              className={className.join(' ')}
              onClick={() => {
                if (onChange) {
                  onChange(key, tab.id);
                }
                setActiveTab(key);
              }}
              key={tab.key || tab.id}
            >
              {tab.icon ? <Icon name={tab.icon} /> : ''}
              {tab.label}
            </div>
          );
          if (tab.disabled && tab.disabledMessage) {
            return (
              <Tooltip
                key={tab.key}
                position="bottom"
                message={tab.disabledMessage}
                delay={0}
              >
                {element}
              </Tooltip>
            );
          }
          return element;
        })}
      </div>
      <div className="Pixi Pixi__Form__Tabs__content" style={contentStyle}>
        {children
          .filter(
            (child) =>
              !!child &&
              !!child?.props?.children &&
              child?.props?.hidden !== true,
          )
          .map((child, key) =>
            cloneElement(child, {
              isActive: activeTabIndex === key,
              style: child.props?.style || tabStyle,
            }),
          )}
      </div>
    </div>
  );
};

interface FormProps {
  children?: ReactNode;
  onSubmit?: () => void;
}

const Form: FC<FormProps> & {
  Tabs: typeof Tabs;
  Tab: typeof Tab;
} = ({ children, onSubmit }) => {
  return <form onSubmit={onSubmit}>{children}</form>;
};

Form.Tabs = Tabs;
Form.Tab = Tab;

export default Form;
