import ApiService from './_ApiService';

class Policy extends ApiService {
  async getPolicy(slug) {
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'pickit-api-key': window.pickit.keys.PICKIT_API,
      },
      requestUrl: window.pickit.config.SERVICE_URL,
    };

    return this.request(`/policy/community/${slug}`, options).then(
      this.handleJSONResponse
    );
  }

  async update(slug, data) {
    const options = {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'pickit-api-key': window.pickit.keys.PICKIT_API,
      },
      requestUrl: window.pickit.config.SERVICE_URL,
      body: JSON.stringify(data),
    };

    return this.request(`/policy/community/${slug}`, options).then(
      this.handleJSONResponse
    );
  }

  async boxCodeToToken(code) {
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'pickit-api-key': window.pickit.keys.PICKIT_API,
      },
      requestUrl: window.pickit.config.SERVICE_URL,
    };

    return this.request(
      `/external-systems/box/auth/code/${code}`,
      options
    ).then(this.handleJSONResponse);
  }

  async updateUserPolicy(slug, data) {
    const options = {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'pickit-api-key': window.pickit.keys.PICKIT_API,
      },
      requestUrl: window.pickit.config.SERVICE_URL,
      body: JSON.stringify(data),
    };

    return this.request(`/policy/user`, options).then(this.handleJSONResponse);
  }

  async getGettyAuth(slug) {
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'pickit-api-key': window.pickit.keys.PICKIT_API,
      },
      requestUrl: window.pickit.config.SERVICE_URL,
    };

    return this.request(
      `/external-systems/community/${slug}/getty/auth`,
      options
    ).then(this.handleJSONResponse);
  }

  async ValidateGettyKeys(apiKey, apiSecret, slug) {
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'pickit-api-key': window.pickit.keys.PICKIT_API,
      },
      requestUrl: window.pickit.config.SERVICE_URL,
      body: JSON.stringify({ apiKey, apiSecret }),
    };

    return this.request(
      `/external-systems/community/:${slug}/getty/validate`,
      options
    ).then(this.handleJSONResponse);
  }

  async getAdobeTokenFromCode(code) {
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'pickit-api-key': window.pickit.keys.PICKIT_API,
      },
      requestUrl: window.pickit.config.SERVICE_URL,
    };

    return this.request(`/external-systems/adobe/token/${code}`, options).then(
      this.handleJSONResponse
    );
  }

  async getHubspotRefreshToken(refreshToken, uri) {
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'pickit-api-key': window.pickit.keys.PICKIT_API,
      },
      requestUrl: window.pickit.config.SERVICE_URL,
      body: JSON.stringify({
        uri,
        refreshToken,
      }),
    };

    return this.request(
      `/external-systems/hubspot/token/rotate/${refreshToken}`,
      options
    ).then(this.handleJSONResponse);
  }

  async hubspotProxy(reqObj) {
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'pickit-api-key': window.pickit.keys.PICKIT_API,
      },
      requestUrl: window.pickit.config.SERVICE_URL,
      body: JSON.stringify(reqObj),
    };

    return this.request(`/external-systems/hubspot/proxy`, options).then(
      this.handleJSONResponse
    );
  }

  async getHubspotTokenFromCode(code, uri) {
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'pickit-api-key': window.pickit.keys.PICKIT_API,
      },
      requestUrl: window.pickit.config.SERVICE_URL,
      body: JSON.stringify({
        uri,
        code,
      }),
    };

    return this.request(
      `/external-systems/hubspot/token/${code}`,
      options
    ).then(this.handleJSONResponse);
  }

  async getShutterstockTokenFromCode(code) {
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'pickit-api-key': window.pickit.keys.PICKIT_API,
      },
      requestUrl: window.pickit.config.SERVICE_URL,
    };

    return this.request(
      `/external-systems/shutterstock/token/${code}`,
      options
    ).then(this.handleJSONResponse);
  }

  async adobeRotateToken(refreshToken) {
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'pickit-api-key': window.pickit.keys.PICKIT_API,
      },
      requestUrl: window.pickit.config.SERVICE_URL,
    };

    return this.request(
      `/external-systems/adobe/token/rotate/${refreshToken}`,
      options
    ).then(this.handleJSONResponse);
  }

  async getBoxFiles(parentId, offset) {
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'pickit-api-key': window.pickit.keys.PICKIT_API,
      },
      requestUrl: window.pickit.config.SERVICE_URL,
    };

    return this.request(
      `/external-systems/box/files/${parentId}?offset=${offset}`,
      options
    ).then(this.handleJSONResponse);
  }

  async searchBox(searchQuery, offset) {
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'pickit-api-key': window.pickit.keys.PICKIT_API,
      },
      requestUrl: window.pickit.config.SERVICE_URL,
    };

    return this.request(
      `/external-systems/box/files/search/${searchQuery}?offset=${offset}`,
      options
    ).then(this.handleJSONResponse);
  }

  async getBoxFolder(id) {
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'pickit-api-key': window.pickit.keys.PICKIT_API,
      },
      requestUrl: window.pickit.config.SERVICE_URL,
    };

    return this.request(`/external-systems/box/folder/${id}`, options).then(
      this.handleJSONResponse
    );
  }

  async getJobs(slug) {
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'pickit-api-key': window.pickit.keys.PICKIT_API,
      },
      requestUrl: window.pickit.config.SERVICE_URL,
    };

    return this.request(
      `/external-systems/dropbox/community/${slug}/import/job`,
      options
    ).then(this.handleJSONResponse);
  }

  async dropboxRefreshToken(refreshToken) {
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'pickit-api-key': window.pickit.keys.PICKIT_API,
      },
      requestUrl: window.pickit.config.SERVICE_URL,
    };

    return this.request(
      `/external-systems/dropbox/community/token/rotate/${refreshToken}`,
      options
    ).then(this.handleJSONResponse);
  }

  async dropboxCodeToToken(code, uri) {
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'pickit-api-key': window.pickit.keys.PICKIT_API,
      },
      requestUrl: window.pickit.config.SERVICE_URL,
      body: JSON.stringify({
        uri,
        code,
      }),
    };

    return this.request(
      `/external-systems/dropbox/community/token/code`,
      options
    ).then(this.handleJSONResponse);
  }

  async disconnectFolder(slug, id, collectionId) {
    const options = {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'pickit-api-key': window.pickit.keys.PICKIT_API,
      },
      requestUrl: window.pickit.config.SERVICE_URL,
    };

    if (collectionId) {
      return this.request(
        `/external-systems/dropbox/community/${slug}/import/job/${collectionId}?identifier=collection`,
        options
      ).then(this.handleJSONResponse);
    }
    return this.request(
      `/external-systems/dropbox/community/${slug}/import/job/${id}`,
      options
    ).then(this.handleJSONResponse);
  }

  async removeAllJobs(slug) {
    const options = {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'pickit-api-key': window.pickit.keys.PICKIT_API,
      },
      requestUrl: window.pickit.config.SERVICE_URL,
    };

    return this.request(
      `/external-systems/dropbox/community/${slug}/import/jobs`,
      options
    ).then(this.handleJSONResponse);
  }
}

export default Policy;
