import React from 'react';
import './style.scss';

export function Intro(props) {
  const className = ['Pixi__Intro'];

  if (props.horizontal) {
    className.push('Pixi__Intro--horizontal');
  }
  if (props.small) {
    className.push('Pixi__Intro--small');
  }
  if (props.large) {
    className.push('Pixi__Intro--large');
  }
  if (props.left) {
    className.push('Pixi__Intro--left');
  }
  return (
    <div className={className.join(' ')} style={props.style}>
      <div
        className={`Pixi Pixi__Intro__wrapper ${
          props.padding ? 'Pixi__Intro__wrapper--withPadding' : ''
        }`}
        style={props.wrapperStyle}
      >
        {props.children}
      </div>
    </div>
  );
}

function Illustration(props) {
  return (
    <div
      className={`Pixi Pixi__Intro__illustration ${
        props.size ? `Pixi__Intro__illustration--${props.size}` : ''
      }`}
      style={props.style}
    >
      {props.children}
    </div>
  );
}
function Message(props) {
  return (
    <div className="Pixi Pixi__Intro__message" style={props.style}>
      {props.children}
    </div>
  );
}
function Actions(props) {
  return (
    <div className="Pixi Pixi__Intro__actions" style={props.style}>
      {props.children}
    </div>
  );
}

Intro.Illustration = Illustration;
Intro.Message = Message;
Intro.Actions = Actions;

export default Intro;
