import * as yup from 'yup';
import React from 'react';
import Icon from './icon.svg?react';

export default {
  moduleId: 'ONE_FILE',
  menuGroupIds: ['FILES', 'TEXT'],
  moduleInterface: {
    file: 'FILE',
    title: 'TITLE',
    text: 'TEXT',
  },
  status: {
    isEmpty: (data) => {
      return !data?.file?.fileId;
    },
  },
  moduleMetadataInterface: {
    onlyFileIcon: yup.boolean(),
    thumbnailPosition: yup.mixed().oneOf(['left', 'right']),
    backgroundColor: yup.string(),
    textColor: yup.string(),
    titleSize: yup.mixed().oneOf(['small', 'medium', 'large']),
    textSize: yup.mixed().oneOf(['small', 'medium', 'large']),
  },
  menuContent: { key: 'ONE_FILE', icon: <Icon />, name: 'File with text' },
};
