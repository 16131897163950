import { Button, Flex, Icon, Popover } from 'elements_v2';
import { WysiwygPreview } from 'elements_v2/Input/Wysiwyg';
import { cloneElement, useEffect, useState } from 'react';

const TermsConfirm = ({
  trigger,
  content,
  onConfirm,
  label,
  doOnDoubleClick,
}) => {
  const [isAgreed, setIsAgreed] = useState(window.tempPickit?.termsAgreed);
  const [isOpenParent, setIsOpenParent] = useState(
    doOnDoubleClick ? false : undefined
  );

  useEffect(() => {
    if (!window.tempPickit) {
      window.tempPickit = {};
    }
    window.tempPickit.termsAgreed = isAgreed;
  }, [isAgreed]);

  let alreadyClicked = false;
  const onClickEvent = trigger?.props?.onClick;

  async function handleClick(event) {
    if (!doOnDoubleClick) {
      onClickEvent?.(event);
      return;
    }

    if (!alreadyClicked) {
      alreadyClicked = true;
      setTimeout(() => {
        if (alreadyClicked) {
          onClickEvent?.(event);
        }
        alreadyClicked = false;
      }, 200);
    } else {
      alreadyClicked = false;
      setIsOpenParent(true);
    }
  }

  // termsOfUse={
  //   boardPreferences?.terms?.login?.active ? (
  //     <WysiwygPreview
  //       value={boardPreferences?.terms?.login?.message}
  //     />
  //   ) : (
  //     false
  //   )
  // }
  return (
    <Popover
      useV2
      width={400}
      noTriggerWrapper
      style={{ padding: 0 }}
      position="topLeft"
      overlay
      customOnClick={doOnDoubleClick}
      isOpen={isOpenParent}
      onClose={() => {
        if (doOnDoubleClick) setIsOpenParent(false);
      }}
      trigger={cloneElement(trigger, {
        onClick: doOnDoubleClick ? handleClick : () => {},
      })}
      zIndex={999999999}
    >
      {(isOpen, setIsOpen) => (
        <div
          style={{
            width: '100%',
            padding: 15,
            borderRadius: 5,
            background: 'rgba(0, 0, 0, 0.05)',
            margin: '0',
            textAlign: 'left',
          }}
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();
          }}
        >
          {!!content?.length && (
            <div style={{ maxHeight: 200, overflow: 'auto', marginBottom: 20 }}>
              <WysiwygPreview value={content} />
            </div>
          )}
          <Button
            onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();
              setIsAgreed(!isAgreed);
            }}
          >
            <Icon name={isAgreed ? 'check-square-fill' : 'square'} />I agree to
            the terms and conditions
          </Button>
          <Flex style={{ gap: 10, padding: '10px 0 0 0' }}>
            <Button
              onClick={(event) => {
                event.stopPropagation();
                event.preventDefault();
                setIsOpen(false);
                if (doOnDoubleClick) {
                  setIsOpenParent(false);
                }
                return false;
              }}
            >
              Cancel
            </Button>
            <Button
              primary
              disabled={!isAgreed}
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
                setIsOpen(false);
                if (doOnDoubleClick) {
                  setIsOpenParent(false);
                }
                onConfirm?.();
                onClickEvent?.();
                return false;
              }}
            >
              {label || 'Download'}
            </Button>
          </Flex>
        </div>
      )}
    </Popover>
  );
};

export default TermsConfirm;
