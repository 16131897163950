import BoardCard from 'components/BoardCard';
import FileThumbnail from 'components/FileThumbnail';
import { useBoards } from 'contexts/Providers/BoardsProvider';
import DocumentCollection from 'elements/DocumentCollection';
import { Button, Flex, Icon, Loader, Popup } from 'elements_v2';
import React, { useEffect, useReducer } from 'react';

export default function SelectBoards(props) {
  const Boards = useBoards();
  const [selectedBoards, setSelectedBoards] = useReducer(
    (state, action) => [...action],
    [],
  );

  useEffect(() => {
    if (props.value) {
      setSelectedBoards(props.value);
    }
  }, [props.value]);

  return (
    <Popup
      style={{ maxWidth: 800, maxHeight: '90vh' }}
      zIndex={99999999}
      title={props.title}
      description={props.description}
      onOpen={() => {
        props.onOpen?.();
        Boards.api.getBoards(props.type);
      }}
      onClose={props.onClose}
      actions={(_isOpen, setIsOpen) => (
        <>
          <Button
            inline
            pixi
            style={{ marginRight: 'auto' }}
            onClick={() => {
              setIsOpen(false);
            }}
          >
            Cancel
          </Button>
          {props.maxlength && props.maxlength === selectedBoards?.length ? (
            <Button
              style={{ pointerEvents: 'none' }}
              className="no_global_style"
              inline
              transparent
            >
              <Icon name="ExclamationTriangleFill" />
              Maximum reached
            </Button>
          ) : (
            <></>
          )}
          {props.multiple ? (
            <Button
              inline
              primary
              pixi
              onClick={() => {
                if (props.onChange) {
                  props.onChange(selectedBoards);
                }
                setSelectedBoards([]);
                setIsOpen(false);
              }}
            >
              Add {selectedBoards?.length} selected boards
            </Button>
          ) : (
            <></>
          )}
        </>
      )}
      trigger={props.trigger}
    >
      {(isOpen, setIsOpen) => (
        <>
          {isOpen && (
            <>
              <Flex flexWrap="wrap">
                {Boards.data
                  ?.filter(
                    (board) =>
                      !props.disableBoards?.find((col) =>
                        typeof col === 'string'
                          ? col === board?._id
                          : col?._id === board?._id,
                      ) && (props.type ? board.type === props.type : true),
                  )
                  ?.map((board) => {
                    return (
                      <BoardCard
                        key={board._id}
                        board={board}
                        onClick={() => {
                          if (
                            props.maxlength &&
                            selectedBoards?.length === props.maxlength
                          ) {
                            return false;
                          }
                          if (!props.multiple) {
                            setIsOpen(false);
                            if (props.onChange) {
                              props.onChange(board);
                            }
                          } else if (
                            selectedBoards.find((f) => board._id === f._id)
                          ) {
                            setSelectedBoards(
                              [...selectedBoards].filter(
                                (f) => f._id !== board._id,
                              ),
                            );
                          } else {
                            setSelectedBoards([...selectedBoards, board]);
                          }
                        }}
                        selected={selectedBoards.find(
                          (b) => b._id === board._id,
                        )}
                        thumbnailType={
                          props.type === 'brand' ? 'textOutside' : ''
                        }
                        noHero
                        forceName
                        context={props.context}
                      />
                    );
                  })}
              </Flex>
            </>
          )}
        </>
      )}
    </Popup>
  );
}
