
import { useUserContext } from 'hooks/useUserContext';
import { useEffect, useState } from 'react';
import { ToastService } from '../../../elements';

export default function useNetworkFileSystemAuth() {
  const User = useUserContext();
  const [isConnected, setIsConnected] = useState(false);
  const [connectedDrivers, setConnectedDrivers] = useState([]);
  const [isConnecting, setIsConnecting] = useState(false);
  const [connectionType, setConnectionType] = useState(undefined);

  async function connect() {
    /**
     * getting all active connections
     */
    setIsConnecting(true);
    const nfsConnections = await User.request.proxy.getConnections(['nfs', 'smb', 'ftp', 'sftp']);
    setConnectedDrivers(nfsConnections?.data || []);
    setIsConnected(!!nfsConnections?.data?.length);
    setIsConnecting(false);
  }

  function getConnectionDetails(tenant_id) {
    /**
     * getting details
     * @type {*[]}
     */
    const connectedDrive = connectedDrivers.filter(
      (drive) => drive.tenant_id === tenant_id
    );
    if (connectedDrive.length) {
      setConnectionType(connectedDrive[0].connection_details?.connection_type ?? connectedDrive[0]?.connection_type);
      return connectedDrive[0];
    }
  }

  async function getConnections() {
    return connectedDrivers;
  }

  async function disconnect(tenant_id) {
    /**
     * disconnect specific connection
     */
    setIsConnecting(true);
    await User.request.proxy.deleteConnection(tenant_id);
    await connect();
    setIsConnecting(false);
  }

  function getDownloadURL(url) {
    /**
     * getting the url with JWT and community slug
     */

    url = url.replace('pickit-nfs.loc', 'nfs-service:8003');
    return `${url}&community_slug=${User.request.community.communitySlug}`;
  }

  useEffect(() => {
    if (User.request.community.communitySlug) {
      (async () => {
        await connect();
      })();
    }
  }, [User.request.community.communitySlug]);

  async function request(tenant_id, action, path, query = '',signal) {
    if (!isConnected || path === undefined) {
      return false;
    }
    await getConnectionDetails(tenant_id);
    try {
      return await User.request.proxy.exploreNFSConnection({
        action,
        path,
        tenant_id,
        query,
        signal,
      });
    } catch (e) {
      if(e === "AbortError") return undefined;
      if (e.status !== 401) {
        ToastService.createToast({
          message: 'Connection is not valid. Please, check the configuration.',
          error: true,
          delay: 6000,
        });
      }
      return { status: 'error', data: [] };
    }
  }

  return {
    isConnecting,
    isConnected,
    connectedDrivers,
    connectionType,
    getDownloadURL,
    getConnectionDetails,
    getConnections,
    request,
    connect,
    disconnect,
  };
}
