import React from 'react';
import './styles.scss';

function Table(props) {
  const { singleLine, striped, style, children, selectable } = props;
  let { className = '' } = props;

  className += ' Pixi__Table';

  if (striped) {
    className += ' striped';
  }
  if (singleLine) {
    className += ' singleLine';
  }
  if (selectable) {
    className += ' Pixi__Table--selectable';
  }

  return <table {...{ className, style }}>{children}</table>;
}

function Body(props) {
  const { className, style, children } = props;

  return <tbody {...{ className, style }}>{children}</tbody>;
}
function Header(props) {
  const { className, style, children } = props;

  return <thead {...{ className, style }}>{children}</thead>;
}
function Footer(props) {
  const { className, style, children } = props;

  return <tfoot {...{ className, style }}>{children}</tfoot>;
}
function Row(props) {
  const { style, children, onClick, selected } = props;
  let { className } = props;
  className += ' Pixi__Table__Row';
  if (selected) {
    className += ' Pixi__Table__Row--selected';
  }
  return <tr {...{ className, style, onClick }}>{children}</tr>;
}
function Cell(props) {
  const { className, style, children, onClick } = props;

  return <td {...{ className, style, onClick }}>{children}</td>;
}
function HeaderCell(props) {
  const { className, style, children, onClick } = props;

  return <th {...{ className, style, onClick }}>{children}</th>;
}
Table.Body = Body;
Table.Header = Header;
Table.Footer = Footer;
Table.Row = Row;
Table.Cell = Cell;
Table.HeaderCell = HeaderCell;

export default Table;
