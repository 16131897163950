import React from 'react';
import './style.scss';
import { Button, Icon } from 'elements_v2';

function Choice(props) {
  const className = ['Pixi Pixi__Choice'];

  function onChange(value) {
    props.onChange(value);
  }

  if (props.disabled) {
    className.push('Pixi__Choice--disabled');
  }

  return (
    <>
      <div className={className.join(' ')}>
        <div className="Pixi Pixi__Choice__content" style={props.style}>
          {props.options
            .filter((option) => !option.hidden)
            .map((option) => (
              <Button
                key={option.value}
                inline
                primary={option.value === props.value}
                background="transparent"
                disabled={props.disabled}
                onClick={(e) => {
                  e.preventDefault();
                  // moveActiveOverlay(e.target.parentNode);
                  onChange(option.value);
                }}
              >
                {option.icon && <Icon name={option.icon} />}
                <span>{option.label}</span>
              </Button>
            ))}
        </div>
      </div>
    </>
  );
}

export default Choice;
