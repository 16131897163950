import * as yup from 'yup';
import React from 'react';
import ColorText from '../_icons/COLOR_TEXT.svg?react';
import ColorMedia from '../_icons/COLOR_MEDIA.svg?react';
import ColorGrid from '../_icons/COLOR_GRID.svg?react';
import ColorColumns from '../_icons/COLOR_COLUMN.svg?react';
import {
  ColorSectionMetadataProperties,
  ColorSectionData,
} from '../../helpers/BlockColor';
import { ContentSectionData } from '../../helpers/Blocks/Text';

export default [
  {
    moduleId: 'COLORS_WITH_TEXT',
    menuGroupIds: ['COLORS'],
    moduleInterface: {
      colors: 'COLORS',
      ...ColorSectionData,
      ...ContentSectionData,
    },
    status: {
      isEmpty: (data) => {
        return !data?.colors?.length;
      },
    },
    moduleMetadataInterface: {
      backgroundColor: yup.string().nullable(),
      textColor: yup.string().nullable(),
      contentPosition: yup.mixed().oneOf(['left', 'right']),
      contentLayout: yup.mixed().oneOf(['fullscreen', 'centered']),
      ...ColorSectionMetadataProperties,
    },
    menuContent: {
      key: 'COLORS_WITH_TEXT',
      icon: <ColorText />,
      name: 'Colors with text',
    },
  },
  {
    moduleId: 'COLORS_WITH_IMAGE',
    menuGroupIds: ['COLORS'],
    moduleInterface: {
      colors: 'COLORS',
      ...ContentSectionData,
    },
    status: {
      isEmpty: (data) => {
        return !data?.colors?.length;
      },
    },
    moduleMetadataInterface: {
      backgroundColor: yup.string().nullable(),
      textColor: yup.string().nullable(),
      contentPosition: yup.mixed().oneOf(['left', 'right']),
      contentLayout: yup.mixed().oneOf(['fullscreen', 'centered']),
      ...ColorSectionMetadataProperties,
    },
    menuContent: {
      key: 'COLORS_WITH_IMAGE',
      icon: <ColorMedia />,
      name: 'Colors with image',
    },
  },
  {
    moduleId: 'COLORS_GRID',
    menuGroupIds: ['COLORS'],
    moduleInterface: {
      colors: 'COLORS',
      ...ColorSectionData,
    },
    status: {
      isEmpty: (data) => {
        return !data?.colors?.length;
      },
    },
    moduleMetadataInterface: {
      backgroundColor: yup.string().nullable(),
      textColor: yup.string().nullable(),
      contentLayout: yup.mixed().oneOf(['fullscreen', 'centered']),
      ...ColorSectionMetadataProperties,
    },
    menuContent: {
      key: 'COLORS_GRID',
      icon: <ColorGrid />,
      name: 'Colors grid',
    },
  },
];
