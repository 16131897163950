import * as yup from 'yup';
import React from 'react';
import Icon from './icon.svg?react';

export default [
  {
    moduleId: 'SEPARATOR',
    menuGroupIds: ['LINE_BREAK'],
    moduleInterface: {},
    moduleMetadataInterface: {
      separatorHeight: yup.string(),
      lineHeight: yup.string(),
      backgroundColor: yup.string(),
      fullscreen: yup.boolean(),
      contentLayout: yup.mixed().oneOf(['fullWidth', 'centered', 'fullscreen']),
    },
    menuContent: { key: 'SEPARATOR', icon: <Icon />, name: 'Separator' },
  },
  {
    moduleId: 'SEPARATOR_TALL',
    menuGroupIds: ['LINE_BREAK'],
    moduleInterface: {},
    moduleMetadataInterface: {
      separatorHeight: yup.string(),
      lineHeight: yup.string(),
      backgroundColor: yup.string(),
      lineColor: yup.string(),
      fullscreen: yup.boolean(),
      contentLayout: yup.mixed().oneOf(['fullWidth', 'centered', 'fullscreen']),
    },
    menuContent: {
      key: 'SEPARATOR_TALL',
      icon: <Icon />,
      name: 'Tall separator',
    },
  },
  {
    moduleId: 'SEPARATOR_LINE',
    menuGroupIds: ['LINE_BREAK'],
    moduleInterface: {},
    moduleMetadataInterface: {
      separatorHeight: yup.string(),
      lineHeight: yup.string(),
      backgroundColor: yup.string(),
      lineColor: yup.string(),
      fullscreen: yup.boolean(),
      contentLayout: yup.mixed().oneOf(['fullWidth', 'centered', 'fullscreen']),
    },
    menuContent: {
      key: 'SEPARATOR_LINE',
      icon: <Icon />,
      name: 'Separator with line',
    },
  },
  {
    moduleId: 'SEPARATOR_ANGLED',
    menuGroupIds: ['LINE_BREAK'],
    moduleInterface: {},
    moduleMetadataInterface: {
      separatorHeight: yup.string(),
      backgroundColor: yup.string(),
      fullscreen: yup.boolean(),
      contentLayout: yup.mixed().oneOf(['fullWidth', 'centered', 'fullscreen']),
    },
    menuContent: {
      key: 'SEPARATOR_ANGLED',
      icon: <Icon />,
      name: 'Angled separator ',
    },
  },
];
