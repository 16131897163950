import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useReducer,
} from 'react';
import { format } from 'date-fns';
import {
  Button,
  Flex,
  Input,
  Popup,
  Dropdown,
  Switch,
  Icon,
} from 'elements_v2';
import { useDocumentsContext, useMediaContext } from 'hooks';
import countries from 'data/countries';
import './style.scss';
import './inputs.scss';

import { useClickOutside } from 'hooks/useClickOutside';
import UploadButton from 'components/UploadButton';
import Loader from 'elements_v2/Loader';
import Form from 'elements_v2/Form';
import SelectCountry from 'components/SelectCountry';
import Radio from '../Payment/Radio';

const countryOptions = countries.countries.map(({ Name }) => ({
  key: Name,
  text: Name,
  value: Name,
}));

const geographyOptions = [
  { key: 'Worldwide', text: 'Worldwide', value: 'Worldwide' },
  {
    key: 'See license terms',
    text: 'See license terms',
    value: 'See license terms',
  },
  {
    key: 'Select countries...',
    text: 'Select countries...',
    value: 'Select countries...',
  },
];

const usageOptions = [
  { key: 'Editorial', text: 'Editorial', value: 'Editorial' },
  { key: 'Commercial', text: 'Commercial', value: 'Commercial' },
];

const creativeCommonsLicenseUrls = {
  'Creative Commons - CC BY': 'https://creativecommons.org/licenses/by/4.0/',
  'Creative Commons - CC BY-SA':
    'https://creativecommons.org/licenses/by-sa/4.0/',
  'Creative Commons - CC BY-ND':
    'https://creativecommons.org/licenses/by-nd/4.0/',
  'Creative Commons - CC BY-NC':
    'https://creativecommons.org/licenses/by-nc/4.0/',
  'Creative Commons - CC BY-NC-SA':
    'https://creativecommons.org/licenses/by-nc-sa/4.0/',
  'Creative Commons - CC BY-NC-ND':
    'https://creativecommons.org/licenses/by-nc-nd/4.0/',
};

const licenseOptions = [
  {
    key: 'No license available',
    text: 'No license available',
    value: 'No license available',
  },
  { key: 'Custom', text: 'Custom', value: 'Custom' },
  { key: 'Rights Managed', text: 'Rights Managed', value: 'Rights Managed' },
  { key: 'Royalty free', text: 'Royalty free', value: 'Royalty free' },
  {
    key: 'Creative Commons - CC BY',
    text: 'Creative Commons - CC BY',
    value: 'Creative Commons - CC BY',
  },
  {
    key: 'Creative Commons - CC BY-SA',
    text: 'Creative Commons - CC BY-SA',
    value: 'Creative Commons - CC BY-SA',
  },
  {
    key: 'Creative Commons - CC BY-ND',
    text: 'Creative Commons - CC BY-ND',
    value: 'Creative Commons - CC BY-ND',
  },
  {
    key: 'Creative Commons - CC BY-NC',
    text: 'Creative Commons - CC BY-NC',
    value: 'Creative Commons - CC BY-NC',
  },
  {
    key: 'Creative Commons - CC BY-NC-SA',
    text: 'Creative Commons - CC BY-NC-SA',
    value: 'Creative Commons - CC BY-NC-SA',
  },
  {
    key: 'Creative Commons - CC BY-NC-ND',
    text: 'Creative Commons - CC BY-NC-ND',
    value: 'Creative Commons - CC BY-NC-ND',
  },
  {
    key: 'Public Domain Mark',
    text: 'Public Domain Mark',
    value: 'Public Domain Mark',
  },
  {
    key: 'CC0 - Creative Commons zero',
    text: 'CC0 - Creative Commons zero',
    value: 'CC0 - Creative Commons zero',
  },
];

function EditLicense({ file, onClose, inline, onChange }) {
  const [license, setLicense] = useReducer(
    (state, action) => ({ ...action }),
    {}
  );
  const initState = {
    toggleParameters: false,
    activeParameters: ['license'],
  };

  const Documents = useDocumentsContext();
  const form = React.useRef();

  const [state, setState] = useState(initState);
  const [isChanged, setIsChanged] = useState(false);
  const licenseLink = useRef(null);
  const wrapper = useRef(null);
  const Media = useMediaContext();
  const [, toggleSave] = useState(false);
  const [countrySearch, setCountrySearch] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const [files, setFiles] = useReducer(
    (state, action) => ({
      ...state,
      [action.field]: state[action.field]
        ? [...state[action.field], action.file].filter((file) =>
            action.remove ? file.name !== action.file.name : true
          )
        : [action.file],
    }),
    {
      licenseFiles: file.license?.licenseFiles || [],
      modelReleases: file.license?.modelReleases || [],
      propertyReleases: file.license?.propertyReleases || [],
    }
  );

  const getDefaultParams = (license) => {
    switch (license) {
      case 'No license available':
        return ['license'];
      case 'Custom':
        return [
          'name',
          'copyright',
          'parameters',
          'releases',
          'license',
          'copyright',
          'licensor',
          'usage',
          'geography',
          'url',
          'expirationDate',
          'attribution',
          'parameters',
          'startDate',
          'licenseFile',
          'additionalNotes',
          'exclusive',
          'releases',
          'license',
        ];
      case 'Rights Managed':
        return [
          'copyright',
          'licensor',
          'usage',
          'geography',
          'url',
          'expirationDate',
          'attribution',
          'parameters',
          'releases',
          'license',
        ];
      case 'Royalty free':
        return [
          'copyright',
          'licensor',
          'usage',
          'geography',
          'url',
          'expirationDate',
          'attribution',
          'parameters',
          'releases',
          'license',
        ];
      case 'Creative Commons - CC BY':
        return [
          'geography',
          'copyright',
          'url',
          'expirationDate',
          'attribution',
          'license',
        ];
      case 'Creative Commons - CC BY-SA':
        return [
          'geography',
          'copyright',
          'url',
          'expirationDate',
          'attribution',
          'license',
        ];
      case 'Creative Commons - CC BY-ND':
        return [
          'geography',
          'copyright',
          'url',
          'expirationDate',
          'attribution',
          'license',
        ];
      case 'Creative Commons - CC BY-NC':
        return [
          'geography',
          'copyright',
          'url',
          'expirationDate',
          'attribution',
          'license',
        ];
      case 'Creative Commons - CC BY-NC-SA':
        return [
          'geography',
          'copyright',
          'url',
          'expirationDate',
          'attribution',
          'license',
        ];
      case 'Creative Commons - CC BY-NC-ND':
        return [
          'geography',
          'copyright',
          'url',
          'expirationDate',
          'attribution',
          'license',
        ];
      case 'Public Domain Mark':
        return ['geography', 'expirationDate', 'attribution', 'license'];
      case 'CC0 - Creative Commons zero':
        return ['geography', 'expirationDate', 'attribution', 'license', 'url'];
      default:
        return ['license'];
    }
  };
  useEffect(() => {
    if (file?.license && !license?.license) {
      setParams();
    }
  }, [file]);
  const setParams = () => {
    const newLicense = {
      ...file.license,
      expirationDate: file.license?.expirationDate
        ? format(new Date(file.license.expirationDate), 'yyyy-MM-dd')
        : false,
      startDate: file.license?.startDate
        ? format(new Date(file.license.startDate), 'yyyy-MM-dd')
        : format(new Date(), 'yyyy-MM-dd'),
      releaseAcquired:
        file.license.modelReleases?.length > 0 ||
        file.license.propertyReleases?.length > 0,
    };

    const incomingParams = Object.entries(file.license)
      .map(([key, value]) => {
        if (Array.isArray(value) && value.length > 0) {
          return key;
        }
        if (!Array.isArray(value) && (value || typeof value === 'boolean')) {
          return key;
        }
      })
      .filter((value) => value !== undefined);
    const activeParameters = [
      ...getDefaultParams(file.license.license ?? license),
      ...incomingParams,
    ];

    if (newLicense.license === 'Custom') {
      activeParameters.push(
        ...[
          'name',
          'copyright',
          'parameters',
          'releases',
          'license',
          'copyright',
          'licensor',
          'usage',
          'geography',
          'url',
          'expirationDate',
          'attribution',
          'parameters',
          'startDate',
          'licenseFile',
          'additionalNotes',
          'exclusive',
          'releases',
          'license',
        ]
      );
    }
    if (
      activeParameters.includes('licenseFiles') &&
      !activeParameters.includes('licenseFile')
    ) {
      activeParameters.push('licenseFile');
    }
    setState({ ...state, activeParameters });
    setLicense({
      ...newLicense,
      license: newLicense.license,
      geography: newLicense.geography?.length ? 'Select countries...' : [],
      countries: newLicense.geography?.length ? newLicense.geography : [],
    });
  };

  const {
    startDate,
    geography,
    countries,
    copyright,
    exclusive,
    name,
    licensor,
    attribution,
    additionalNotes,
    releasesNeeded,
    releaseAcquired,
    usage,
    expirationDate,
    url,
  } = license;

  const { activeParameters } = state;

  async function uploadFiles(files) {
    const uploadedFiles = [];
    if (!files?.length) {
      return [];
    }
    for (let i = 0; i < files.length; i++) {
      const controller = new AbortController();
      const file = files[i];
      if (file.id) {
        uploadedFiles.push(file);
      } else {
        const uploadedFile = await Documents.uploadFile(file, 'licenses', {
          onProgress: () => {},
          abort: controller,
          onFinish: () => {},
          onError: () => {},
        });
        uploadedFiles.push(uploadedFile);
      }
    }
    return uploadedFiles;
  }

  const handleSave = async () => {
    setIsSaving(true);
    const license = {};
    activeParameters.forEach((key) => {
      if (!state[key] && typeof state[key] !== 'boolean' && key !== 'releases')
        return;
      if (key === 'startDate' || key === 'expirationDate') {
        const date = new Date(state[key]);
        if (date.getTime() > 0) {
          license[key] = date.getTime();
        }
      } else if (key === 'releases') {
        license.releasesNeeded = releasesNeeded;
      } else if (key === 'geography') {
        license[key] = state.countries;
      } else {
        license[key] = state[key];
      }
    });

    const deletedFiles = [
      ...(file.license?.licenseFiles?.filter(
        (file) => !files.licenseFiles?.find((f) => f.id === file.id)
      ) || []),
      ...(file.license?.modelReleases?.filter(
        (file) => !files.modelReleases?.find((f) => f.id === file.id)
      ) || []),
      ...(file.license?.propertyReleases?.filter(
        (file) => !files.propertyReleases?.find((f) => f.id === file.id)
      ) || []),
    ];

    for (const file of deletedFiles) {
      await Documents.deleteFile(file.id, 'licenses');
    }

    license.licenseFiles = await uploadFiles(files.licenseFiles);
    license.modelReleases = await uploadFiles(files.modelReleases);
    license.propertyReleases = await uploadFiles(files.propertyReleases);

    await Media.saveDocument({
      _id: file._id,
      license,
    });

    onClose();
  };
  useEffect(() => {
    if (onChange && isChanged) {
      const newLicense = {};
      activeParameters.forEach((key) => {
        if (
          !license[key] &&
          typeof license[key] !== 'boolean' &&
          key !== 'releases'
        )
          return;
        if (key === 'startDate' || key === 'expirationDate') {
          const date = new Date(license[key]);
          if (date.getTime() > 0) {
            newLicense[key] = date.getTime();
          }
        } else if (key === 'releases') {
          newLicense.releasesNeeded = releasesNeeded;
        } else if (key === 'geography') {
          newLicense[key] = countries?.length
            ? countries
            : typeof license[key] === 'object' && license[key].length === 0
            ? []
            : [license[key]];
        } else {
          newLicense[key] = license[key];
        }
      });
      onChange(newLicense, files);
    }
  }, [license, files, isChanged]);

  const onClickOutSide = useCallback(() => {
    setState((prev) => ({ ...prev, toggleParameters: false }));
  }, []);

  useClickOutside(wrapper, onClickOutSide);

  const handleChange = (field) => (value) => {
    toggleSave(true);
    setIsChanged(true);
    if (value === 'seperator') return;
    if (field === 'license') {
      const newState = {
        ...state,
        activeParameters: getDefaultParams(value),
      };
      if (value.includes('Creative Commons')) {
        license.url = creativeCommonsLicenseUrls[value];
      }
      setLicense({
        ...license,
        [field]: value,
        url: '',
        attribution: `This file ${
          getDefaultParams(value).includes('copyright') && copyright
            ? `by ${copyright}, `
            : ''
        }is licensed under ${value}`,
      });
      return setState(newState);
    }

    if (field === 'copyright') {
      return setLicense({
        ...license,
        attribution: `This file${value && `, by ${value},`} is licensed under ${
          license.license
        }`,
        copyright: value,
      });
    }

    if (field === 'expirationDate' && typeof value === 'boolean' && !value) {
      return setLicense({
        ...license,
        [field]: format(new Date(), 'yyyy-MM-dd'),
      });
    }
    if (field === 'expirationDate' && typeof value === 'boolean' && value) {
      return setLicense({ ...license, [field]: null });
    }
    if (field === 'countries') {
      if (value.includes('remove')) {
        const country = value.split(':')[1];

        return setLicense({
          ...license,
          [field]: countries.filter((item) => item !== country),
        });
      }
      return setLicense({
        ...license,
        [field]: [...(countries || []), value],
      });
    }
    setLicense({
      ...license,
      [field]: value,
    });
    setState({ ...state, [field]: value });
  };

  const hasMoreParams =
    !activeParameters.includes('startDate') ||
    !activeParameters.includes('expirationDate') ||
    !activeParameters.includes('usage') ||
    !activeParameters.includes('geography') ||
    !activeParameters.includes('url') ||
    (!activeParameters.includes('licenseFile') &&
      !activeParameters.includes('licenseFiles')) ||
    !activeParameters.includes('attribution') ||
    !activeParameters.includes('additionalNotes') ||
    !activeParameters.includes('exclusive');

  const fields = [
    { text: 'Start date', param: 'startDate', tab: 1 },
    { text: 'Expiration date', param: 'expirationDate', tab: 1 },
    { text: 'Usage type', param: 'usage', tab: 1 },
    { text: 'Geography', param: 'geography', tab: 1 },
    { text: 'License URL', param: 'url', tab: 0 },
    { text: 'License file', param: 'licenseFile', tab: 0 },
    { text: 'Attribution', param: 'attribution', tab: 1 },
    { text: 'Additional notes', param: 'additionalNotes', tab: 1 },
    { text: 'Exclusive or non exclusive', param: 'exclusive', tab: 1 },
  ];

  const filteredFields = fields.filter(
    (field) => !activeParameters.includes(field.param)
  );

  const actions = (
    <Flex width="100%" justifyContent="space-between">
      <Button as="div" inline pixi onClick={() => onClose()}>
        Cancel
      </Button>
      {isSaving ? (
        <Loader />
      ) : (
        <Button as="div" inline pixi primary onClick={handleSave}>
          Save license
        </Button>
      )}
    </Flex>
  );

  function FileRow({ file, onRemove }) {
    return (
      <Flex
        key={file.name}
        justifyContent="space-between"
        alignItems="center"
        style={{ marginBottom: 10 }}
      >
        {decodeURI(file.name)}
        <Button
          as="div"
          inline
          size="small"
          onClick={() => {
            onRemove();
          }}
        >
          Remove file
        </Button>
      </Flex>
    );
  }

  const formElement = (
    <div className="LicenseV2">
      <div className="LicenseV2__content">
        <Form
          style={{ width: '100%' }}
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <Button
            as="div"
            ref={form}
            type="submit"
            style={{ display: 'none' }}
          />
          {(activeParameters.includes('license') ||
            activeParameters.includes('name')) && (
            <>
              {activeParameters.includes('license') && (
                <Dropdown
                  useV2
                  onChange={handleChange('license')}
                  label="Image license"
                  zIndex={999999}
                  options={licenseOptions}
                  value={license.license || 'No license available'}
                  noClear
                  optionsMaxHeight={300}
                  noActive
                />
              )}
              {hasMoreParams && license?.license === 'Custom' && (
                <Input.Field
                  custom={
                    <Dropdown
                      placeholder="Add custom field"
                      disabled={license?.license !== 'Custom' && hasMoreParams}
                      primary
                      largeStatus
                      style={{ marginTop: 10 }}
                      status={<Icon name="plus" style={{ fontSize: 20 }} />}
                      optionsMaxHeight={200}
                    >
                      {filteredFields.map((field) => (
                        <Dropdown.Option
                          key={field.param}
                          transparent
                          onClick={() =>
                            setState({
                              ...state,
                              activeParameters: [
                                ...activeParameters,
                                field.param,
                              ],
                            })
                          }
                        >
                          {field.text}
                        </Dropdown.Option>
                      ))}
                    </Dropdown>
                  }
                />
              )}
              {activeParameters.includes('name') && (
                <Input.Field
                  label="License name"
                  onChange={handleChange('name')}
                  value={name}
                  placeholder="Name your license"
                />
              )}
            </>
          )}
          {(activeParameters.includes('copyright') ||
            activeParameters.includes('licensor') ||
            activeParameters.includes('url')) && (
            <>
              {activeParameters.includes('copyright') && (
                <Input.Field
                  label="Copyright"
                  onChange={handleChange('copyright')}
                  value={copyright}
                  placeholder="Who has the copyright?"
                />
              )}
              {activeParameters.includes('licensor') && (
                <Input.Field
                  label="Licensor"
                  onChange={handleChange('licensor')}
                  value={licensor}
                  placeholder="Who provided the license?"
                />
              )}
              {(activeParameters.includes('licenseFiles') ||
                activeParameters.includes('licenseFile')) && (
                <Input
                  label="License files"
                  custom={
                    <>
                      {files?.licenseFiles?.map((file, key) => (
                        <FileRow
                          file={file}
                          index={key}
                          key={file.name}
                          onRemove={() => {
                            setIsChanged(true);
                            setFiles({
                              field: 'licenseFiles',
                              remove: true,
                              file,
                            });
                          }}
                        />
                      ))}
                      <UploadButton
                        accept={[
                          'application/pdf',
                          'application/msword',
                          'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                        ]}
                        onChange={(event) => {
                          setIsChanged(true);
                          setFiles({
                            field: 'licenseFiles',
                            file: event.target.files[0],
                          });
                        }}
                      >
                        <Button as="div" size="small" inline pixi>
                          Upload license file
                        </Button>
                      </UploadButton>
                    </>
                  }
                />
              )}
              {activeParameters.includes('url') && (
                <Input.Field
                  label="License URL"
                  ref={licenseLink}
                  onChange={handleChange('url')}
                  readOnly={license.license?.includes('Creative Commons')}
                  value={url}
                  placeholder="Insert link to license"
                />
              )}
            </>
          )}
          {(activeParameters.includes('usage') ||
            activeParameters.includes('geography')) && (
            <>
              {activeParameters.includes('usage') && (
                <Input.Field
                  label="Usage"
                  custom={
                    <Dropdown
                      onChange={handleChange('usage')}
                      error={
                        usage === 'Commercial' &&
                        !releaseAcquired &&
                        releasesNeeded
                      }
                      options={usageOptions}
                      noClear
                      value={usage || usageOptions[1].value}
                      noActive
                    />
                  }
                />
              )}
              {activeParameters.includes('geography') && (
                <Input.Field
                  label="Geography"
                  custom={
                    <Dropdown
                      onChange={handleChange('geography')}
                      disabled={license.license?.includes('Creative Commons')}
                      options={geographyOptions}
                      noClear
                      value={geography || geographyOptions[0].value}
                      noActive
                    />
                  }
                />
              )}
              {geography === 'Select countries...' && (
                <>
                  <Dropdown
                    useV2
                    label="Select Countries"
                    noClear
                    onChange={(value) => handleChange('countries')(value)}
                    fluid
                    query={countrySearch}
                    searchable
                    options={countryOptions
                      .filter((item) =>
                        item.text
                          ?.toLowerCase()
                          ?.includes(countrySearch?.toLowerCase())
                      )
                      .filter(
                        (item) =>
                          !countries?.find(
                            (value) =>
                              item.text?.toLowerCase() === value.toLowerCase()
                          )
                      )}
                    value={countries}
                    onSearch={(value) => setCountrySearch(value)}
                    noActive
                    keepOpen
                    optionsMaxHeight={200}
                  />
                  <Flex flexWrap="wrap" style={{ gap: 5, marginTop: 4 }}>
                    {countries?.map((country) => {
                      return (
                        <Button
                          size="tiny"
                          compact
                          key={country}
                          onClick={() =>
                            handleChange('countries')(`remove:${country}`)
                          }
                          inline
                          style={{ margin: 0 }}
                        >
                          {country}
                          <Icon name="x-lg" style={{ marginLeft: '10px' }} />
                        </Button>
                      );
                    })}
                  </Flex>
                </>
              )}
            </>
          )}
          {(activeParameters.includes('expirationDate') ||
            activeParameters.includes('startDate')) && (
            <>
              {activeParameters.includes('startDate') && (
                <Input.Field
                  label="Start date"
                  type="date"
                  min="1979-12-31"
                  max="9999-12-31"
                  onChange={handleChange('startDate')}
                  disabled={license.license?.includes('Creative Commons')}
                  value={format(
                    startDate ? new Date(startDate) : new Date(),
                    'yyyy-MM-dd'
                  )}
                  placeholder="Start date"
                />
              )}
              {activeParameters.includes('expirationDate') && (
                <Input.Field
                  label="License expiration"
                  custom={
                    <>
                      <Switch
                        style={{ padding: 0 }}
                        label="Use forever"
                        disabled={license.license?.includes('Creative Commons')}
                        onChange={() =>
                          handleChange('expirationDate')(!!expirationDate)
                        }
                        active={
                          typeof expirationDate === 'string' &&
                          expirationDate.length === 0
                            ? false
                            : !expirationDate
                        }
                      />
                      {typeof expirationDate === 'string' && (
                        <Input.Field
                          label="Expiration date"
                          type="date"
                          min="1979-12-31"
                          max="9999-12-31"
                          onChange={handleChange('expirationDate')}
                          disabled={license.license?.includes(
                            'Creative Commons'
                          )}
                          value={format(new Date(expirationDate), 'yyyy-MM-dd')}
                          placeholder="Expiration date"
                        />
                      )}
                    </>
                  }
                />
              )}
            </>
          )}
          {(activeParameters.includes('attribution') ||
            activeParameters.includes('additionalNotes')) && (
            <>
              {activeParameters.includes('attribution') && (
                <Input.Field
                  label="Attribution"
                  textarea
                  onChange={handleChange('attribution')}
                  value={attribution}
                  rows={3}
                />
              )}
              {activeParameters.includes('additionalNotes') && (
                <Input.Field
                  label="Additional notes"
                  textarea
                  onChange={handleChange('additionalNotes')}
                  value={additionalNotes}
                  rows={3}
                />
              )}
            </>
          )}
          {(activeParameters.includes('releases') ||
            activeParameters.includes('licenseFile') ||
            activeParameters.includes('exclusive')) && (
            <>
              {activeParameters.includes('exclusive') && (
                <div
                  style={{ alignItems: 'flex-start', flexDirection: 'column' }}
                  className="radio-wrapper"
                >
                  <Radio
                    onClick={() => handleChange('exclusive')(true)}
                    on={exclusive}
                    label="Exclusive"
                  />
                  <Radio
                    onClick={() => handleChange('exclusive')(false)}
                    on={!exclusive}
                    label="Non exclusive"
                  />
                </div>
              )}
              {activeParameters.includes('releases') && (
                <>
                  <Input.Field
                    custom={
                      <Switch
                        active={releasesNeeded}
                        onChange={(value) =>
                          handleChange('releasesNeeded')(value)
                        }
                        label="Releases needed"
                      />
                    }
                  />
                  {releasesNeeded && (
                    <Input.Field
                      style={{ marginTop: 0 }}
                      custom={
                        <Switch
                          active={releaseAcquired}
                          onChange={(value) =>
                            handleChange('releaseAcquired')(value)
                          }
                          label="Releases acquired"
                        />
                      }
                      description={
                        usage === 'Commercial' &&
                        !releaseAcquired &&
                        releasesNeeded &&
                        'Images should only be used for editorial use'
                      }
                    />
                  )}
                  {releasesNeeded && releaseAcquired && (
                    <>
                      <Input
                        label="Property release"
                        custom={
                          <>
                            {files?.propertyReleases?.map((file, key) => (
                              <FileRow
                                file={file}
                                index={key}
                                key={file.name}
                                onRemove={() => {
                                  setIsChanged(true);
                                  setFiles({
                                    field: 'propertyReleases',
                                    remove: true,
                                    file,
                                  });
                                }}
                              />
                            ))}
                            <UploadButton
                              accept={[
                                'application/pdf',
                                'application/msword',
                                'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                              ]}
                              onChange={(event) => {
                                setIsChanged(true);
                                setFiles({
                                  field: 'propertyReleases',
                                  file: event.target.files[0],
                                });
                              }}
                            >
                              <Button as="div" size="small" inline flex>
                                Upload document
                              </Button>
                            </UploadButton>
                          </>
                        }
                      />
                      <Input
                        label="Model release"
                        custom={
                          <>
                            {files?.modelReleases?.map((file, key) => (
                              <FileRow
                                file={file}
                                index={key}
                                key={file.name}
                                onRemove={() => {
                                  setIsChanged(true);
                                  setFiles({
                                    field: 'modelReleases',
                                    remove: true,
                                    file,
                                  });
                                }}
                              />
                            ))}
                            <UploadButton
                              accept={[
                                'application/pdf',
                                'application/msword',
                                'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                              ]}
                              onChange={(event) => {
                                setIsChanged(true);
                                setFiles({
                                  field: 'modelReleases',
                                  file: event.target.files[0],
                                });
                              }}
                            >
                              <Button as="div" size="small" inline flex>
                                Upload document
                              </Button>
                            </UploadButton>
                          </>
                        }
                      />
                    </>
                  )}
                </>
              )}
            </>
          )}
        </Form>
      </div>
    </div>
  );

  if (inline) {
    return <>{formElement}</>;
  }

  return (
    <Popup
      onClose={onClose}
      isOpen
      style={{ maxWidth: 500, height: '80vh' }}
      title={
        <div style={{ fontWeight: 400 }}>
          {file.license?.name ? 'Editing license for ' : 'Adding license to '}
          <br />
          <strong>{decodeURI(file?.file?.name)}</strong>
        </div>
      }
      actions={actions}
    >
      {formElement}
    </Popup>
  );
}

export default EditLicense;
