import { Group } from '@mantine/core';
import { AppConfigInterface, useColorScheme } from '@pixi/AppController';
import PixiButton from '@pixi/elements/Button';
import PixiPopup, {
  PixiPopupPanelProps,
  PixiPopupProps,
} from '@pixi/elements/Popup';
import {
  getMultiStore,
  getStore,
  useMultiDataStoreCallback,
} from '@pixi/store';
import useContextFromType from 'hooks/useContextFromType';
import { AssetGrid } from './AssetGrid/AssetGrid';
import { AssetGridProps } from './AssetGrid/Helpers';
import { ReactNode } from 'react';
import { PixiIconName } from '@pixi/elements/Icon';

export interface SelectFileProps
  extends Omit<Partial<PixiPopupProps>, 'onSelect'> {
  view?: {
    title?: ReactNode;
    icon?: PixiIconName;
  };
  onSelect?: (file: Pickit.FileInterface[]) => void;
  onClose?: () => void;
  libraryId?: string;
  assetGridProps?: Partial<AssetGridProps>;
  selectButtonLabel?: AppConfigInterface['selectFile']['selectButtonLabel'];
  multiple?: boolean;
  panelProps?: Partial<PixiPopupPanelProps>;
  customActions?: ({
    selectedFiles,
    clear,
  }: {
    selectedFiles: Pickit.FileInterface[];
    clear: () => void;
  }) => ReactNode;
}

export default function SelectFile({
  onSelect,
  onClose,
  libraryId,
  assetGridProps,
  multiple,
  selectButtonLabel,
  customActions,
  view,
  panelProps,
  ...rest
}: SelectFileProps) {
  const colorScheme = useColorScheme();
  const context = useContextFromType(libraryId);
  const selectedFiles = useMultiDataStoreCallback(
    'FILES_SELECTED',
    'selectFileGlobal',
    (data) =>
      data.state
        .filter((row) => !!getStore('FILES').getByKey(row._id))
        .map(
          (row) => getStore('FILES').getByKey(row._id) as Pickit.FileInterface,
        ),
  );

  return (
    <>
      <PixiPopup
        fullHeight
        opened={!!onSelect}
        onClose={() => {
          getMultiStore('FILES_SELECTED', 'selectFileGlobal').replace([]);
          onClose?.();
        }}
        zIndex={15}
        size={1400}
        bodyProps={{
          w: 1400,
          maw: '100%',
          h: '100%',
        }}
        isPanelPopup
        {...rest}
        title={undefined}
      >
        <PixiPopup.Panel
          w="100%"
          h="100%"
          title={view?.title || rest.title}
          icon={view?.icon}
          withCloseButton
          actions={
            customActions ? (
              customActions({
                selectedFiles,
                clear: () =>
                  getMultiStore('FILES_SELECTED', 'selectFileGlobal').replace(
                    [],
                  ),
              })
            ) : (
              <>
                <PixiButton
                  size="wide-md"
                  disabled={!selectedFiles?.length}
                  ml="auto"
                  onClick={() => {
                    onSelect?.(selectedFiles);
                    getMultiStore('FILES_SELECTED', 'selectFileGlobal').replace(
                      [],
                    );
                    onClose?.();
                  }}
                >
                  {typeof selectButtonLabel === 'function'
                    ? selectButtonLabel(selectedFiles)
                    : selectButtonLabel ||
                      (multiple ? 'Select files' : 'Select file')}
                </PixiButton>
              </>
            )
          }
          {...panelProps}
        >
          <Group
            w="100%"
            h="100%"
            bg={colorScheme === 'dark' ? 'dark.7' : 'gray.1'}
            p="xs"
          >
            {context && (
              <AssetGrid
                id="selectFileGlobal"
                context={context}
                multiSelect={multiple || false}
                defaultView={{
                  groupBy: null,
                }}
                isInPopup
                libraryId={libraryId}
                readOnly
                {...assetGridProps}
                disable={[
                  'status',
                  'rightPanel',
                  'fullscreen',
                  ...(assetGridProps?.disable || []),
                ]}
              />
            )}
          </Group>
        </PixiPopup.Panel>
      </PixiPopup>
    </>
  );
}
