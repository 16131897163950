import React from 'react';
import './style.scss';
import { Button } from 'elements_v2';
import Tooltip from 'rc-tooltip';
import Icon from 'elements_v2/Icon';

function Title(props) {
  return <h1 className="Pixi__Tooltip-Tutorial__title">{props.children}</h1>;
}

function Description(props) {
  return (
    <p className="Pixi__Tooltip-Tutorial__description">{props.children}</p>
  );
}

function Actions(props) {
  const Buttons = [];
  if (props.closeButton) {
    Buttons.push(
      <Button
        primary
        background="#FFF"
        color="#3e3c54"
        onClick={() => {
          props.onClose && props.onClose();
        }}
      >
        Got it
      </Button>
    );
  }
  return <div className="Pixi__Tooltip-Tutorial__actions">{Buttons}</div>;
}

Tutorial.Title = Title;
Tutorial.Description = Description;
Tutorial.Actions = Actions;

export default function Tutorial(props) {
  const className = ['Pixi__Tooltip--Arrow Pixi__Tooltip-Tutorial'];
  if (props.noShrink) {
    className.push('Pixi__Tooltip-Tutorial--noShrink');
  }
  if (props.small) {
    className.push('Pixi__Tooltip-Tutorial--small');
  }

  const tooltipProps = {
    mouseEnterDelay: props.delay,
    visible: props.visible,
    onVisibleChange: props.onClose,
  };

  return (
    <>
      <Tooltip
        {...tooltipProps}
        placement="bottom"
        overlayClassName="Pixi__Tooltip-Tutorial"
        trigger={props.triggerOpen ? ['click'] : props.hover ? ['hover'] : []}
        mouseLeaveDelay={props.hover ? 0 : undefined}
        overlay={
          <>
            <div className="Pixi Pixi__Tooltip-Tutorial__message">
              {Array.isArray(props.children)
                ? props.children.map((el) =>
                    React.cloneElement(el, {
                      onClose: props.onClose,
                    })
                  )
                : React.cloneElement(props.children, {
                    onClose: props.onClose,
                  })}
            </div>
          </>
        }
      >
        {props.noTriggerWrapper ? (
          props.trigger
        ) : (
          <div style={props.triggerStyle}>{props.trigger}</div>
        )}
      </Tooltip>
    </>
  );
}
