import React, { useEffect, useReducer } from 'react';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  rectSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

function SortableItem({
  render,
  id,
  item,
  index,
  disabled,
  contained,
  containedStyle,
  wobble,
}) {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id, disabled });

  if (transform?.scaleY) {
    transform.scaleY = 1;
    transform.scaleX = 1;
  }

  const style = disabled
    ? {}
    : {
        transform: CSS.Transform.toString(transform),
        transition,
        display: 'inline-block',
        cursor: !disabled && 'grab',
        ...(containedStyle || {}),
      };

  if (contained) {
    return (
      <div
        ref={setNodeRef}
        onClick={(event) => {
          event.preventDefault();
        }}
        className={wobble ? 'SortableWobble' : ''}
        style={style}
        {...listeners}
        {...attributes}
      >
        {render(item)}
      </div>
    );
  }

  return (
    <>
      {render(
        item,
        {
          ref: setNodeRef,
          attributes,
          listeners,
          style,
        },
        index
      )}
    </>
  );
}

export function SortableV2({
  items,
  onChange,
  render,
  disabled,
  contained,
  containedStyle,
  injectLeft,
  injectRight,
  wobble,
}) {
  const [stateItems, setStateItems] = useReducer(
    (state, action) => [...action],
    items || []
  );
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 50,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  useEffect(() => {
    setStateItems(items);
  }, [items]);

  function handleDragEnd(event) {
    const { active, over } = event;

    if (!active?.id || !over?.id) {
      return false;
    }

    if (active.id !== over.id) {
      const oldIndex = stateItems.findIndex((i) => i.id === active.id);
      const newIndex = stateItems.findIndex((i) => i.id === over.id);

      const newItems = arrayMove(stateItems, oldIndex, newIndex);
      onChange(newItems);
      setStateItems(newItems);
    }
  }

  if (disabled) {
    return stateItems.map((item, index) => (
      <SortableItem
        item={item}
        key={item.id}
        disabled
        index={index}
        id={item.id}
        render={render}
      />
    ));
  }

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
    >
      {injectLeft}
      <SortableContext items={items} strategy={rectSortingStrategy}>
        {stateItems.map((item, index) => (
          <SortableItem
            item={item}
            key={item.id || item._id}
            index={index}
            id={item.id || item._id}
            render={render}
            contained={contained}
            wobble={wobble}
            containedStyle={containedStyle}
            disabled={disabled || item._isDraggingDisabled}
          />
        ))}
      </SortableContext>
      {injectRight}
    </DndContext>
  );
}
