import useHackedDeepState from './useHackedDeepState';

export function useRequest(request) {
  const [state, setState] = useHackedDeepState({
    isError: false,
    isLocked: false,
    isLoading: false,
    data: undefined,
    pendingRequest: undefined,
    stats: {
      SUCCESSFUL_REQUESTS: 0,
      FAILED_REQUESTS: 0,
    },
    latestError: undefined,
  });

  const sendRequest = async (requestProps) => {
    try {
      const requestData = await request({
        requestProps: requestProps,
        state: state,
        setState: setState,
      });
      setState({
        isLoading: false,
        pendingRequest: null,
        data: requestData,
        stats: {
          SUCCESSFUL_REQUESTS: state.stats.SUCCESSFUL_REQUESTS + 1,
        },
      });
      return requestData;
    } catch (e) {
      console.error(e);
      const data = { status: e.status, statusText: e.statusText };
      setState({
        isError: true,
        isLoading: false,
        pendingRequest: null,
        stats: {
          FAILED_REQUESTS: state.stats.FAILED_REQUESTS + 1,
        },
        error: data,
      });
    }
  };

  return {
    request: async (props) => {
      if (state.isLocked) {
        return false;
      }
      if (state.pendingRequest) {
        return state.pendingRequest;
      }
      let pendingRequest = sendRequest(props);
      setState({ pendingRequest, isError: false, isLoading: true });
      return pendingRequest;
    },
    ...state,
  };
}
