import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import './style.scss';

import arrowLeft from 'images/left-arrow-icon-white.svg';
import arrowRight from 'images/right-arrow-icon-white.svg';

export const SlideCollection = ({ demo, collection, overlay }) => {
  let backgroundSrc = '';
  let hasCustomBackground = false;

  if (collection.hero_mobile_custom_background_photo) {
    backgroundSrc = collection.hero_mobile_custom_background_photo;
    hasCustomBackground = true;
  } else if (collection.hero_custom_mobile_background) {
    backgroundSrc = collection.hero_custom_mobile_background;
    hasCustomBackground = true;
  } else if (collection.background_photo) {
    backgroundSrc = collection.background_photo;
  }
  return (
    <div
      className={`Hero__collection brand__color__border__after${
        !hasCustomBackground ? ' Hero__collection--gradient-overlay' : ''
      }`}
      style={{ backgroundImage: `url("${backgroundSrc}")` }}
    >
      {overlay}
      {demo && <div className="Hero__collection--demo">DEMO</div>}
      {!hasCustomBackground && (
        <div className="Hero__collection__title">{collection.title}</div>
      )}
    </div>
  );
};

export const SlideCollectionV2 = ({ collection, overlay }) => {
  let backgroundSrc = '';
  let hasCustomBackground = true;

  if (collection.custom_mobile_background_photo) {
    backgroundSrc = collection.custom_mobile_background_photo;
  } else if (collection.custom_background_photo) {
    backgroundSrc = collection.custom_background_photo;
  } else if (collection.background_photo) {
    hasCustomBackground = false;
    backgroundSrc = collection.background_photo;
  }
  return (
    <div
      className={`Hero__collection brand__color__border__after${
        !hasCustomBackground ? ' Hero__collection--gradient-overlay' : ''
      }`}
      style={{ backgroundImage: `url("${backgroundSrc}")` }}
    >
      {overlay}
      {!hasCustomBackground && (
        <div className="Hero__collection__title">{collection.title}</div>
      )}
    </div>
  );
};

export const SlideLoader = ({ children }) => (
  <div className="Hero__loader">{children}</div>
);

export const Slide = ({ active, onClick, style, children }) => {
  let className = 'Hero__slide';
  className += active ? ' Hero__slide--active' : '';

  return (
    <div className={className} onClick={onClick} style={style}>
      {children}
    </div>
  );
};

export const ContainerView = ({ children, loading, history }) => {
  const [state, setState] = useState({
    activeSlide: 0,
  });
  const { activeSlide } = state;

  const goLeft = () => {
    setState({
      activeSlide: activeSlide - 1,
    });
  };
  const goRight = () => {
    setState({
      activeSlide: activeSlide + 1,
    });
  };
  const goTo = (index) => {
    setState({
      activeSlide: index,
    });
    return false;
  };

  const style = {};

  if (!children) {
    return <div />;
  }

  const childrenWithProps = React.Children.map(children, (child, key) => {
    const extraProps = {
      active: key === activeSlide ? 'true' : '',
      beforeActive: key < activeSlide,
      afterActive: key < activeSlide,
      style: { ...child.style, width: `${100 / children.length}%` },
    };

    if (key !== activeSlide) {
      extraProps.onClick = () => {
        goTo(key);
      };
    } else if (child.props.link) {
      extraProps.onClick = (event) => {
        if (child.props.onClick) {
          child.props.onClick(event);
        }
        history.push(child.props.link);
      };
    }
    return React.cloneElement(child, extraProps);
  });

  if (activeSlide) {
    style.transform = `translateX(calc(${100 * activeSlide}% * -1))`;
    style.msTransform = `translateX(-${100 * activeSlide}%)`;
  }

  return (
    <div
      className={`Hero ${loading ? 'Hero--loading' : ''}${
        children.length === 1 ? ' Hero--onlyOneSlide' : ''
      }`}
    >
      {activeSlide !== 0 && (
        <div
          onClick={() => goLeft()}
          className="Hero__navigation Hero__navigation--left"
        >
          <img src={arrowLeft} alt="Left Arrow" />
        </div>
      )}
      {activeSlide !== children.length - 1 && (
        <div
          onClick={() => goRight()}
          className="Hero__navigation Hero__navigation--right"
        >
          <img src={arrowRight} alt="Right Arrow" />
        </div>
      )}
      <div className="Hero__slides" style={style}>
        <div
          className="Hero__wrapper"
          style={{ width: `${100 * children.length}%` }}
        >
          {childrenWithProps}
        </div>
      </div>
    </div>
  );
};

export const Container = withRouter(ContainerView);
