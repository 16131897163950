import React, { useEffect, useState } from 'react';
import { useRouter } from 'hooks/useRouter';
import '../style.scss';
// import { BackButton } from 'elements/BackButton';
import { useUserContext, useBrand, useTextContext, useMediaQuery } from 'hooks';
import {
  Button,
  Icon,
  Popover,
  Loader,
  Intro,
  Flex,
  Message,
  Input,
  Tooltip,
} from 'elements_v2';

// import BannerCompany from 'components/BannerCompany';
import { ToastService } from 'elements/Toast';
import CollectionShare from 'components/CollectionShare';
// import Info from 'elements/Info';

import { analytics } from 'services';
import { AppContext } from 'contexts/App';
// import useRouterQuery from 'hooks/useRouterQuery';
import useUserStatus from 'hooks/useUserStatus';
import AppView from 'elements_v2/AppView';
import { isDirectEmbed } from 'utils/platform';
import useDownloadBag, { DownloadBagActions } from 'hooks/files/useDownloadBag';
import ImagesGrid from './ImagesGrid';
import Image from './Image';
import Search from './Search';
import Filters from './Filters';
import CollectionToolbar from './CollectionToolbar';
import TermsConfirm from '../../_Manage/Boards/helpers/Blocks/helpers/TermsConfirm';
// import "./style.scss";

/**
 * TODO: Contributor, external and anonymous users
 */

function Collection(props) {
  const { files } = props;
  const Router = useRouter();
  const User = useUserContext();
  const Brand = useBrand();
  const userStatus = useUserStatus();
  const Text = useTextContext();
  const App = React.useContext(AppContext);
  const isSmall = useMediaQuery('(max-width: 950px)');
  const Documents = props.context;
  const { downloadFiles } = useDownloadBag();
  const [isAtBottom, setIsAtBottom] = useState(false);
  // const location = useRouterQuery();
  const [collectionId, setCollectionId] = React.useState(false);
  const [collection, setCollection] = React.useReducer((state, action) => {
    return { ...action };
  }, null);
  const [isNoAccess, setIsNoAccess] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isSharing, setIsSharing] = React.useState(false);
  const [isToolbarVisible, setIsToolbarVisible] = React.useState(false);
  const params = new URLSearchParams(Router.location.search);
  const [isExternalView, setIsExternalView] = React.useState(
    !!params.get('external_token'),
  );
  const isExternal =
    collection?.communitySlug &&
    collection?.communitySlug !== User.spaces?.selected?.slug &&
    collection?.communitySlug !== User.spaces?.selected?.family_parent?.slug;
  const [searchQuery, setSearchQuery] = useState('');
  const [displayType, setDisplayType] = useState(
    props.context.type === 'documents'
      ? 'details'
      : (collection?.layout?.type ?? 'gallery'),
  );
  const isInCollection = !!props.collectionId;

  const [visibileButton, setButtonVisibility] = useState(false);
  const [updatedExternalUsers, setUpdatedExternalUsers] = useState('');
  const [isFollowingCollection, setIsFollowingCollection] = useState(
    !!User.cloudStorage.following?.collections?.find(
      (item) => item.id === collection?._id,
    ),
  );

  useEffect(() => {
    setDisplayType(
      props.context.type === 'documents'
        ? 'details'
        : (collection?.layout?.type ?? 'gallery'),
    );
  }, [props.context.type]);

  useEffect(() => {
    if (props.setAtBottomListener) {
      props.setAtBottomListener(() => {
        setIsAtBottom(true);
      });
    }
  }, [props.setAtBottomListener]);

  useEffect(() => {
    if (
      !Object.keys(files.filters)?.length &&
      files.isFired &&
      !files.isLoading
    ) {
      props.goBack?.();
    }
  }, [files.filters]);

  useEffect(() => {
    if (isAtBottom && !files.isLoading) {
      (async () => {
        await files.getFiles();
        setIsAtBottom(false);
      })();
    }
  }, [isAtBottom]);

  React.useEffect(() => {
    if (collection?.layout?.type && props.context.type !== 'documents') {
      setDisplayType(collection?.layout?.type);
    }
  }, [collection?.layout?.type]);

  React.useEffect(() => {
    setIsFollowingCollection(
      !!User.cloudStorage.following?.collections?.find(
        (item) => item.id === collection?._id,
      ),
    );
  }, [
    !!User.cloudStorage.following?.collections?.find(
      (item) => item.id === collection?._id,
    ),
  ]);

  async function getCollection() {
    try {
      setIsNoAccess(false);
      setIsLoading(true);
      setCollection(null);
      const collection = await Documents.getCollection(collectionId);
      if (collection) {
        User.setNewDocuments(undefined, { normal: [collection], hero: [] });
        if (User.isNewDoc(collection) || User.isUpdatedDoc(collection)) {
          User.setIsDocumentOpened(collection);
        }
        setCollection(collection);
        setIsExternalView(
          User.spaces?.selected?.slug !== collection?.communitySlug,
        );
        // setCurrentCollection(collection);
      }
      setIsLoading(false);
      setIsNoAccess(false);
    } catch (e) {
      setIsNoAccess(true);
      setIsLoading(false);
    }
  }

  React.useEffect(() => {
    return () => {
      props.context.setGlobalSettings({
        key: 'files_filters',
        data: {
          filters: {},
        },
      });
    };
  }, []);

  React.useEffect(() => {
    if (!isInCollection) {
      files.setIsReady(true);
    }
  }, [files.filters, files.sortBy, isInCollection]);

  React.useEffect(() => {
    if (
      isInCollection &&
      collection?.communitySlug &&
      files.filters.collections?.[0] &&
      !files.isReady
    ) {
      files.setIsReady(true);
    }
    if (!isInCollection && files?.filters?.query && !files.isReady) {
      files.setIsReady(true);
    }
  }, [
    files.filters.collections,
    files.isReady,
    files.filters.query,
    collection?.communitySlug,
  ]);

  React.useEffect(() => {
    if (isExternal && collection?.communitySlug) {
      Brand.setFromSlug(collection?.communitySlug);
      App.setIsHeaderHidden(true);
      const userSpace = User.spaces?.companies?.find(
        (company) => company.slug === collection?.communitySlug,
      );
      if (userSpace) {
        User.selectSpace(userSpace);
      }
      props.setIsAnonymous?.(true);
    }
    return () => {
      Brand.setFromSlug(User.spaces?.selected?.slug);
      App.setIsHeaderHidden(false);
    };
  }, [isExternal, collection?.communitySlug]);

  React.useEffect(() => {
    updatedExternalUsers === ''
      ? setButtonVisibility(false)
      : setButtonVisibility(true);
  }, [updatedExternalUsers]);

  React.useEffect(() => {
    if (props.view === 'collection' && props.collectionId && !collectionId) {
      files.setFilter('collections', props.collectionId);
      if (!props.noTracking) {
        analytics.trackEvent('File Collection Viewed', {
          Collection: props.collectionId,
          Service: props.context.type,
        });
      }
      setCollectionId(props.collectionId);
    }
  }, [props.collectionId]);

  React.useEffect(() => {
    if (collectionId && !collection && !isLoading) {
      getCollection();
    }
  }, [collectionId]);

  React.useEffect(() => {
    if (
      !props.popupMode &&
      (!User.authenticated || isExternalView) &&
      !App.isStandaloneView
    ) {
      App.setIsStandaloneView(true);
      if (isExternalView) {
        App.setIsIgnoreLIV(true);
      }
    }
  }, [App.isStandaloneView, props.popupMode]);

  if (!User.authenticated && isNoAccess) {
    window.location.hash = `/#/welcome?redirect=${encodeURIComponent(
      window.location.hash,
    )}`;
    return <></>;
  }

  if (isNoAccess) {
    return (
      <Intro padding>
        <Intro.Message>
          <h1>Access denied</h1>
          <p>You don't have access to this collection.</p>
        </Intro.Message>
        <Intro.Actions>
          <Button pixi inline primary onClick={() => window.location.reload()}>
            Try again
          </Button>
        </Intro.Actions>
      </Intro>
    );
  }

  // async function activateTrial() {
  //   if (User.authenticated) {
  //     await User.request.proxy.activateTrial(User.spaces?.selected?.slug);
  //   }
  //   Brand.clear();
  //   Router.history.push('/explore/pickit-v2');
  // }

  async function inviteExternalUsers() {
    await Documents.saveCollection({
      _id: collection._id,
      share_to_external_users: [
        ...collection.share_to_external_users,
        updatedExternalUsers,
      ],
    });
    setUpdatedExternalUsers('');
    ToastService.createToast({
      message: 'Users invited',
    });
  }

  const onRender = (image, imageProps) => {
    return (
      <Image
        image={image}
        data={imageProps}
        downloadWrapper={props.downloadWrapper}
      />
    );
  };

  const filters = (
    <Filters
      filters={props.context.filters.filter(
        (filter) => filter.view?.key !== 'collections',
      )}
      activeFilters={files.filters}
      onFilter={files.setFilter}
      setFilters={files.setFilters}
      files={files}
      onSort={files.sort}
      sortBy={files.sortBy}
      context={props.context}
    />
  );

  const externalUserInvite = (
    <>
      <Input.Field
        placeholder={Text.get('ui/share_to_external_user/invite_email')}
        value={updatedExternalUsers}
        onChange={setUpdatedExternalUsers}
      />
      <Button
        style={{
          marginTop: 5,
          visibility: visibileButton ? 'visible' : 'hidden',
        }}
        primary={updatedExternalUsers?.length}
        size="small"
        type="submit"
        onClick={() => inviteExternalUsers()}
      >
        {Text.get('ui/send_invite')}
      </Button>
    </>
  );

  const search = (
    <Search
      placeholder={Text.get('view/collection/search_in_collection')}
      onSearch={(query) => {
        files.setFilter('query', query);
        analytics.trackEvent('File Search', {
          Service: props.context.type,
          Query: searchQuery,
        });
      }}
      onChange={(query) => {
        // App.downloadBag.clearBag();
        setSearchQuery(query);
      }}
      value={files.filters.query}
    />
  );

  const sharing = (
    <>
      {collection?.share_to_anonymous &&
        !collection?.preferences?.hide_anonymous_sharing && (
          <AppView.Box>
            <CollectionShare
              text={collection?.name}
              url={`https://share.pickit.com/${props.context.type}/${collection?._id}`}
            />
          </AppView.Box>
        )}
      {User.authenticated &&
        !userStatus.product.isExternalUser &&
        User.spaces?.selected?.slug === collection?.communitySlug &&
        collection?.allow_share_to_external_users_from_end_user && (
          <AppView.Box
            info={{
              title: Text.get('ui/share_to_external_user/title'),
              text: Text.get('ui/share_to_external_user/text'),
            }}
            infoPosition="bottom-start"
            title={Text.get('ui/share_to_external_user/label')}
          >
            {externalUserInvite}
          </AppView.Box>
        )}
    </>
  );

  const isContributor =
    !userStatus.product.isBusinessAdmin &&
    collection?.libraryType &&
    collection?.permissions?.contributor_users?.some(
      (obj) =>
        obj?.email === User.djangoProfile?.email ||
        obj.user_id === User.djangoProfile?.id,
    );

  const contributor = (
    <>
      <p>The admin has given you access to upload to this collection</p>
      <Button style={{ marginTop: 5 }} onClick={goToContributor}>
        <Icon name="CloudArrowUpFill" />
        Upload to collection
      </Button>
    </>
  );

  function goToContributor(event) {
    event.preventDefault();
    event.stopPropagation();
    User.openInWeb(
      `/manage/library/${collection?.libraryType}/collection/${collection._id}`,
      true,
    );
  }
  let displayTypes = (
    <>
      <Button
        inline
        transparent={displayType !== 'gallery'}
        onClick={() => setDisplayType('gallery')}
      >
        <Icon
          name={displayType === 'gallery' ? 'grid-1x2-fill' : 'grid-1x2'}
          style={{ marginRight: 0 }}
        />
      </Button>
      <Button
        inline
        transparent={displayType !== 'details'}
        style={{ marginLeft: isSmall ? 0 : 5 }}
        onClick={() => setDisplayType('details')}
      >
        <Icon
          name={displayType === 'details' ? 'GridFill' : 'Grid'}
          style={{ marginRight: 0 }}
        />
      </Button>
    </>
  );

  if (props.context.type === 'documents') {
    displayTypes = <></>;
  }

  const SavedToggle = !props.popupMode &&
    !props.disableActions?.includes('favorite') && (
      <Button
        inline
        style={{ margin: '0 0 0 5px' }}
        onClick={() => Router.history.push(`/${props.context.type}/saved`)}
      >
        <Icon name="Heart" style={{ marginRight: 0 }} />
      </Button>
    );

  const isSharingAvailable =
    (collection?.share_to_anonymous &&
      !collection?.preferences?.hide_anonymous_sharing) ||
    (User.authenticated &&
      User.spaces?.selected?.slug === collection?.communitySlug &&
      collection?.allow_share_to_external_users_from_end_user);

  return (
    <>
      <AppView style={{ padding: props.popupMode ? 0 : '' }}>
        {!props.popupMode && (
          <AppView.Column className="hide-mobile">
            <AppView.Toolbox>
              <Flex style={{ marginBottom: 5 }}>
                {search}
                {searchQuery !== files.filters.query ? (
                  <Button
                    inline
                    primary
                    style={{ marginLeft: 5 }}
                    onClick={() => {
                      if (searchQuery) {
                        files.setFilter('query', searchQuery);
                        analytics.trackEvent('File Search', {
                          Service: props.context.type,
                          Query: searchQuery,
                        });
                      }
                    }}
                  >
                    <Icon name="Search" style={{ marginRight: 0 }} />
                  </Button>
                ) : (
                  SavedToggle
                )}
              </Flex>
              <div>
                {filters}
                {isContributor && (
                  <Message style={{ marginTop: 5 }}>{contributor}</Message>
                )}
              </div>
              {/* {collectionId && User.authenticated && isTrialActivated && (
              <CollectionHeaderV2.Icon
                title={Text.get('view/collection/save_collection')}
                onClick={() => Documents.favoriteCollection(props.collectionId)}>
                {!Documents.isCollectionFavorite(props.collectionId) ? <Icon name="Bookmark" /> : <Icon name="BookmarkFill" color="#2DA944" />}
              </CollectionHeaderV2.Icon>
            )} */}
            </AppView.Toolbox>
            <div>{sharing}</div>
          </AppView.Column>
        )}
        {/* {!isTrialActivated ? (
        <PromoteBanner
          style={{ marginBottom: '8vh' }}
          text="Access over a million images in PowerPoint and other channels with a free 7-day trial."
          action={
            <Button onClick={activateTrial} primary>
              Try Pickit now
            </Button>
          }
        />
      ) : (
        ''
      )} */}
        {/* {collection?.share_to_anonymous && (
          <Flex justifyContent="flex-end">
            <Button
              transparent
              inline
              size="small"
              style={{ margintop: 15, opacity: 0.5, paddingRight: 10 }}
              onClick={() => setIsShareLinkVisible(!isShareLinkVisible)}>
              <Icon name="Globe" />
              Share this collection
              <Icon name={`Caret${isShareLinkVisible ? 'Up' : 'Down'}Fill`} style={{ fontSize: 10, marginLeft: 8, marginRight: 0 }} />
            </Button>
          </Flex>
        )}
        {isShareLinkVisible && (
          <Input
            label="Share this collection"
            description={
              <>
                Anyone with the link can
                <br />
                access the assets in this collection
              </>
            }
            control={<input type="text" value={`https://share.pickit.com/${props.context.type}/${collection?._id}`} />}
            style={{ textAlign: 'right' }}
          />
        )} */}

        <AppView.Main style={{ padding: props.popupMode ? 0 : '' }}>
          {(!collection || files.isLoading) &&
          props.view !== 'search' &&
          !files.data?.documents?.length &&
          files.isLoading ? (
            <>
              <Loader>
                {!collectionId
                  ? Text.get('documents/loader/searching')
                  : Text.get('documents/loader/collection')}
              </Loader>
            </>
          ) : (
            <AppView.Box
              style={{ padding: props.popupMode ? 0 : '' }}
              desktop
              className={`FilesView__files ${
                props.ignoreResponsive
                  ? 'FilesView__files--ignoreResponsive'
                  : ''
              }`}
            >
              {props.popupMode ? (
                <Flex
                  width="100%"
                  alignItems="center"
                  className="hide-mobile"
                  style={{
                    padding:
                      displayType === 'details' && !props.popupMode
                        ? '2px 10px'
                        : '2px',
                    marginBottom: 15,
                  }}
                >
                  <Flex style={{ maxWidth: 300 }}>
                    {search} {SavedToggle}
                  </Flex>
                  <Popover
                    useV2
                    limitHeight
                    overlayStyle={{ overflow: 'auto' }}
                    trigger={(isOpen, setIsOpen) => (
                      <Button
                        onClick={() => setIsOpen(!isOpen)}
                        style={{ marginLeft: 5 }}
                      >
                        <Icon name="Filter" style={{ margin: 0 }} />
                      </Button>
                    )}
                  >
                    {filters}
                  </Popover>
                  {props.context.type !== 'documents' && (
                    <Flex
                      className="hide-mobile"
                      style={{ marginLeft: 'auto', flexShrink: 0 }}
                    >
                      {props.view === 'collection' && (
                        <CollectionToolbar
                          collection={collection}
                          totalFiles={files.totalFiles}
                          useTerms={!!props.downloadWrapper}
                          disableActions={props.disableActions}
                        />
                      )}
                      {displayTypes}
                    </Flex>
                  )}
                </Flex>
              ) : (
                ''
              )}
              {!props.popupMode && (
                <AppView.Header
                  left={
                    <>
                      {!isDirectEmbed && (
                        <Button
                          transparent
                          inline
                          onClick={() => {
                            if (props.goBack) {
                              return props.goBack();
                            }
                            if (params.get('goBack')) {
                              Router.history.push(
                                decodeURIComponent(params.get('goBack')),
                              );
                            }
                          }}
                        >
                          <Icon name="ChevronLeft" style={{ marginRight: 0 }} />
                          <span
                            className="hide-mobile"
                            style={{ marginLeft: 10 }}
                          >
                            {Text.get('ui/go_back')}
                          </span>
                        </Button>
                      )}
                    </>
                  }
                  center={
                    <h1>
                      {collection && props.view !== 'search'
                        ? collection.name
                        : Text.get('ui/search')}
                    </h1>
                  }
                  right={
                    <Flex>
                      {!isDirectEmbed && (
                        <Button
                          className="hide-mobile"
                          style={{ visibility: 'hidden' }}
                          transparent
                          onClick={() => {
                            if (props.goBack) {
                              return props.goBack();
                            }
                            if (params.get('goBack')) {
                              Router.history.push(
                                decodeURIComponent(params.get('goBack')),
                              );
                            }
                          }}
                        >
                          <Icon name="ChevronLeft" />
                          <span className="hide-mobile">
                            {Text.get('ui/go_back')}
                          </span>
                        </Button>
                      )}
                      {files.totalFiles < 200 &&
                        !collection?.preferences?.enable_zip_download && (
                          <Button
                            inline
                            transparent
                            style={{ marginLeft: 10 }}
                            title="Download all"
                            onClick={async () => {
                              setIsLoading(true);
                              await props.context.downloadCollectionAsZip(
                                collection,
                              );
                              setIsLoading(false);
                            }}
                          >
                            <Icon
                              name="cloud-download"
                              style={{ marginRight: 0 }}
                            />
                          </Button>
                        )}
                      <CollectionToolbar
                        collection={collection}
                        totalFiles={files.totalFiles}
                        useTerms={!!props.downloadWrapper}
                        disableActions={props.disableActions}
                      />
                      {props.context.type === 'media' && (
                        <Flex className="hide-mobile" column={isSmall}>
                          {displayTypes}
                        </Flex>
                      )}
                    </Flex>
                  }
                />
              )}
              {true && (
                <Flex
                  style={{ width: '100%', marginBottom: 20 }}
                  className="hide-desktop"
                >
                  {search}
                  {searchQuery !== files.filters.query ? (
                    <Button
                      inline
                      primary
                      style={{ marginLeft: 5 }}
                      onClick={() => {
                        if (searchQuery) {
                          files.setFilter('query', searchQuery);
                          analytics.trackEvent('File Search', {
                            Service: props.context.type,
                            Query: searchQuery,
                          });
                        }
                      }}
                    >
                      <Icon name="Search" style={{ marginRight: 0 }} />
                    </Button>
                  ) : (
                    <>
                      <Button
                        inline
                        className="hide-desktop"
                        style={{ marginLeft: 5 }}
                        onClick={() => {
                          setIsToolbarVisible(!isToolbarVisible);
                          setIsSharing(false);
                        }}
                      >
                        <Icon style={{ margin: 0 }} name="ThreeDotsVertical" />
                      </Button>
                    </>
                  )}
                </Flex>
              )}
              <div
                className={`Files__Toolbar ${
                  isToolbarVisible ? 'Files__Toolbar--active' : ''
                } hide-desktop`}
              >
                <Button
                  inline
                  onClick={() =>
                    setDisplayType(
                      displayType === 'details' ? 'gallery' : 'details',
                    )
                  }
                >
                  <Icon
                    name={displayType === 'details' ? 'GridFill' : 'Grid'}
                    style={{ marginRight: 0 }}
                  />
                </Button>
                <>
                  {isContributor && (
                    <Popover
                      position="bottom-start"
                      triggerStyle={{ marginLeft: 5 }}
                      boxContentStyle={{ padding: 20 }}
                      trigger={
                        <Button className="hide-desktop">
                          <Icon name="CloudArrowUp" style={{ margin: 0 }} />
                        </Button>
                      }
                    >
                      {contributor}
                    </Popover>
                  )}
                  {isSharingAvailable && (
                    <Button
                      onClick={() => setIsSharing(!isSharing)}
                      background={isSharing ? '#FFF' : ''}
                      style={{ color: isSharing ? '#515151' : '' }}
                      className="hide-desktop"
                    >
                      <Icon name="Share" style={{ margin: 0 }} />
                    </Button>
                  )}
                  <Popover
                    useV2
                    limitHeight
                    overlayStyle={{ overflow: 'auto' }}
                    trigger={(isOpen, setIsOpen) => (
                      <Button
                        onClick={() => setIsOpen(!isOpen)}
                        className="hide-desktop"
                      >
                        <Icon name="Filter" style={{ margin: 0 }} />
                      </Button>
                    )}
                  >
                    {/* {search} */}
                    {filters}
                  </Popover>
                </>
              </div>
              {isSharing && (
                <div
                  className="hide-desktop"
                  style={{ width: '100%', marginBottom: 10 }}
                >
                  {sharing}
                </div>
              )}
              {!files?.data?.documents?.length &&
              !files?.isLoading &&
              files.isAllFilesLoaded ? (
                <Intro style={{ marginTop: 25 }}>
                  <Intro.Message>
                    <h1>
                      {props.view === 'collection'
                        ? Text.get('error/empty_collection/title')
                        : Text.get('view/no_search_results/no_match')}
                    </h1>
                    <p>
                      {props.view !== 'collection'
                        ? Text.get(
                            'view/no_search_results/try_something_similar',
                          )
                        : Text.get('error/empty_collection/text')}
                    </p>
                  </Intro.Message>
                </Intro>
              ) : (
                ''
              )}
              <ImagesGrid
                context={props.context}
                images={[...(files?.data?.documents || [])]}
                loadingImages={20}
                isLoading={files.isLoading}
                isLoadingMore={files.isLoadingMore}
                onBottom={() => {
                  files.getFiles();
                }}
                includeDownloadBag={props.includeDownloadBag ?? true} // Alex: if includeDownloadBag bar is undefined -> we are showing it
                setSelectedFiles={props.setSelectedFiles}
                selectedFiles={props.selectedFiles}
                style={{ minHeight: props.popupMode ? '50vh' : '' }}
                onFileRedirect={props.onFileRedirect}
                altLink={props.documentLink}
                onRender={onRender}
                downloadWrapper={props.downloadWrapper}
                detailed={
                  displayType === 'details' ||
                  (!displayType && collection?.layout?.type === 'details') ||
                  props.grid === 'cards'
                }
                targetRowHeight={
                  files?.data?.documents?.length < 2 ? null : 150
                }
              />
            </AppView.Box>
          )}
          {files.isLoadingMore && !files.isLoading && <Loader />}
          {files.isAllFilesLoaded && (
            <div className="Files__message">
              {Text.get('ui/all_files_loaded')}
            </div>
          )}
        </AppView.Main>
        {!props.popupMode && (
          <AppView.Column className="hide-mobile">
            {/* <AppView.Details>
            {!!collection?.updatedAt && (
              <AppView.Details.Detail
                label="Last updated"
                value={Text.get('ui/date_ago').replace('XX', formatDistance(new Date(collection.updatedAt), new Date()))}
              />
            )}
            <AppView.Details.Detail label="Assets" value={`${files.totalFiles} files`} />
          </AppView.Details> */}
          </AppView.Column>
        )}
      </AppView>
    </>
  );
}

export default React.memo(Collection);
