import ApiService from './_ApiService';

interface Config {
  communitySlug?: string;
  type?: string;
}

interface InterfaceResponsibility {
  responsibilityType: 'people' | 'groups' | 'admins';
  ids?: string[];
}

export interface InterfaceFlowStep {
  _id: string;
  name: string;
  responsibility: InterfaceResponsibility;
  order: number;
}

export interface InterfaceFlowState {
  _id?: string;
  // FlowStepIDs
  currentSteps: string[];
  // FileID
  file: string;
  // Prent flow Id;
  flow: string;
  changes: InterfaceFlowStateChanges[];
  updatedAt?: Date;
  library?: 'media' | 'documents' | 'brandassets';
}

interface InterfaceFlowStateChanges {
  _id?: string;
  // UserId
  changedBy: number;
  step: string;
  action:
    | 'entered'
    | 'moved'
    | 'cleared'
    | 'reset'
    | 'pushedback'
    | 'exited'
    | 'approved'
    | 'changed'
    | 'rejected'
    | 'approved-manual'
    | 'entered-deleted'
    | 'reset-file-update'
    | 'approved-manual-deleted'
    | 'rejected-deleted'
    | 'approved-deleted';
  changedAt: Date;
  message?: string;
}

export interface FlowData {
  _id: string;
  name: string;
  isDefault?: boolean;
  // Enum for future expansion
  flowType: 'approvals';
  steps: InterfaceFlowStep[];
  state?: InterfaceFlowState[];
}

class Flows extends ApiService {
  config: Config = {
    communitySlug: undefined,
  };

  UPLOAD_URL =
    window.pickit.config.BASE_URL === 'pickit.com'
      ? window.pickit.config.UPLOAD_URL
      : window.pickit.config.API_URL;

  type = null;

  setCommunitySlug(slug: string) {
    this.config.communitySlug = slug;
  }

  async create(data: FlowData): Promise<Response> {
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'pickit-community': this.config.communitySlug,
        Pragma: 'no-cache',
      },
      skipBaseUrl: true,
      requestUrl: window.pickit.config.API_URL,
      body: JSON.stringify({
        data,
      }),
    };
    return this.request(`/flow`, options, undefined, undefined, undefined);
  }

  async update(data: FlowData): Promise<Response> {
    const options = {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'pickit-community': this.config.communitySlug,
        Pragma: 'no-cache',
      },
      skipBaseUrl: true,
      requestUrl: window.pickit.config.API_URL,
      body: JSON.stringify({
        data,
      }),
    };
    return this.request(
      `/flow/${data._id ?? ''}`,
      options,
      undefined,
      undefined,
      undefined,
    );
  }

  async get(): Promise<Response> {
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'pickit-community': this.config.communitySlug,
        Pragma: 'no-cache',
      },
      skipBaseUrl: true,
      requestUrl: window.pickit.config.API_URL,
    };
    return this.request(`/flow`, options, undefined, undefined, undefined);
  }

  async getStateContributor(fileId: string): Promise<Response> {
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'pickit-community': this.config.communitySlug,
        Pragma: 'no-cache',
      },
      skipBaseUrl: true,
      requestUrl: window.pickit.config.API_URL,
    };
    return this.request(
      `/flow/contributor/${fileId ?? ''}`,
      options,
      undefined,
      undefined,
      undefined,
    );
  }

  async getUserTasks(count?: boolean, split?: boolean): Promise<Response> {
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'pickit-community': this.config.communitySlug,
        Pragma: 'no-cache',
      },
      skipBaseUrl: true,
      requestUrl: window.pickit.config.API_URL,
    };
    const queries = [];
    if (count) {
      queries.push('count=true');
    }
    if (split) {
      queries.push('split=true');
    }
    return this.request(
      `/flow/user${queries.length ? `?${queries.join('&')}` : ''}`,
      options,
      undefined,
      undefined,
      undefined,
    );
  }

  async delete(id: string): Promise<Response> {
    const options = {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'pickit-community': this.config.communitySlug,
        Pragma: 'no-cache',
      },
      skipBaseUrl: true,
      requestUrl: window.pickit.config.API_URL,
    };
    return this.request(
      `/flow/${id}`,
      options,
      undefined,
      undefined,
      undefined,
    );
  }

  async action(
    flowId: string,
    fileIds: string[],
    action: string,
    stepId: string,
    message: string,
  ): Promise<Response> {
    const options = {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'pickit-community': this.config.communitySlug,
        Pragma: 'no-cache',
      },
      skipBaseUrl: true,
      requestUrl: window.pickit.config.API_URL,
      body: JSON.stringify({
        flowId,
        fileIds,
        action,
        stepId,
        message,
      }),
    };
    return this.request(
      `/flow/action`,
      options,
      undefined,
      undefined,
      undefined,
    );
  }

  async actionMultiple(
    states: InterfaceFlowState[],
    action: string,
    message: string,
  ): Promise<Response> {
    const options = {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'pickit-community': this.config.communitySlug,
        Pragma: 'no-cache',
      },
      skipBaseUrl: true,
      requestUrl: window.pickit.config.API_URL,
      body: JSON.stringify({
        states,
        action,
        message,
      }),
    };
    return this.request(
      `/flow/action/multiple`,
      options,
      undefined,
      undefined,
      undefined,
    );
  }

  async actionFiles(
    fileIds: string[],
    action: string,
    message: string,
  ): Promise<Response> {
    const options = {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'pickit-community': this.config.communitySlug,
        Pragma: 'no-cache',
      },
      skipBaseUrl: true,
      requestUrl: window.pickit.config.API_URL,
      body: JSON.stringify({
        fileIds,
        action,
        message,
      }),
    };
    return this.request(
      `/flow/action/files`,
      options,
      undefined,
      undefined,
      undefined,
    );
  }
}

export default Flows;
