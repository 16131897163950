import PixiButton from '@pixi/elements/Button';
import { useAssetGridContext } from '../../AssetGridContext';
import { Badge, Group } from '@mantine/core';
import { useState } from 'react';
import PixiIcon from '@pixi/elements/Icon';

export default function AssetGridTags() {
  const [isAllVisible, setIsAllVisible] = useState(false);
  const { availableFilters, activeFilters, setFilter } = useAssetGridContext();
  return (
    <Group w="100%" gap="8">
      {availableFilters.tags?.data
        ?.slice(0, isAllVisible ? 5000 : 20)
        .map((d) => {
          const isActive = !!activeFilters?.tags?.find(
            (t) =>
              t.value === d.value ||
              (t.value as string[])?.includes(d.value as string),
          );
          if (!d?.value) {
            return <></>;
          }
          return (
            <PixiButton
              key={d.value}
              variant="light"
              color={isActive ? 'primary' : 'dark'}
              size="xs"
              onClick={() => {
                setFilter('tags', [
                  {
                    value: d.value,
                    modifier: '$and',
                  },
                ]);
              }}
              radius="xl"
              rightSection={
                <Badge
                  size="xs"
                  color={isActive ? 'primary' : 'dark'}
                  variant="light"
                >
                  {d.count}
                </Badge>
              }
            >
              {d.value}
            </PixiButton>
          );
        })}
      {availableFilters.tags?.data?.length > 20 ? (
        <PixiButton
          onClick={() => {
            setIsAllVisible(!isAllVisible);
          }}
          radius="xl"
          size="xs"
          variant="light"
        >
          {isAllVisible ? 'See less' : 'See all'}
        </PixiButton>
      ) : (
        <></>
      )}
    </Group>
  );
}
