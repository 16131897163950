import { useState, useRef, useEffect } from 'react';

const useDelayedState = (initialState, delay = 500) => {
  const [state, setState] = useState(initialState);
  const [nextState, setNextState] = useState(initialState);
  const timeoutRef = useRef();

  useEffect(() => {
    if (state !== nextState) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = setTimeout(() => {
        setState(nextState);
      }, delay);
    }

    // Clean up on unmount
    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, [state, nextState, delay]);

  return [state, setNextState];
};

export default useDelayedState;
