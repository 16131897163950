import React, { useEffect, useReducer, useState } from 'react';
import { Button } from 'elements_v2';
import ImageEditor from 'components/ImageEditor';
import { randomString } from 'utils';
import slugify from 'slugify';
import Thumbnails from './Thumbnails';

export default function EditVariants(props) {
  const [fileAlias, setFileAlias] = React.useState(null);
  const [isAddVariant, setIsAddVariant] = useState(props.defaultOpen);
  const [isUploading, setIsUploading] = useState(false);

  if (props.onlyEditor) {
    return (
      <ImageEditor
        visible
        onComplete={(file, design) => {
          if (design.suggestedName) {
            setFileAlias(design.suggestedName);
          }
          const uploadFile = new File(
            [file],
            `${props.file?.file?.name.split('.')?.[0]}-${slugify(
              fileAlias || 'variant'
            )}.jpg`,
            {
              type: file.type,
            }
          );
          uploadFile.id = randomString(8);
          uploadFile.alias =
            design.suggestedName || `Variant ${(props.value?.length || 0) + 1}`;
          props.onChange(uploadFile, design);
        }}
        preferences={{
          cropSelectPresetOptions: [
            [
              'Crop',
              [
                [undefined, 'Custom'],
                [1, 'Square'],
                [1.5, 'Landscape'],
                [0.7, 'Portrait'],
              ],
            ],
            [
              'Size',
              [
                [[180, 180], 'Profile Picture'],
                [[1200, 600], 'Header Image'],
                [[800, 400], 'Timeline Photo'],
              ],
            ],
          ],
        }}
        onHide={() => {
          setIsAddVariant(false);
          props?.onClose?.();
        }}
        src={props.file?.file?.previews?.[0]?.url || props?.file?.file?.url}
      />
    );
  }

  return (
    <>
      <Button
        as="div"
        style={{ display: 'inline-flex', marginTop: 15 }}
        size="small"
        pixi
        inline
        primary
        onClick={() => setIsAddVariant(true)}
        loading={isUploading}
      >
        Create new variant
      </Button>
      <ImageEditor
        visible={isAddVariant}
        onComplete={(file, design) => {
          if (design.suggestedName) {
            setFileAlias(design.suggestedName);
          }
          const uploadFile = new File(
            [file],
            `${props.file?.file?.name.split('.')?.[0]}-${slugify(
              fileAlias || 'variant'
            )}.jpg`,
            {
              type: file.type,
            }
          );
          uploadFile.id = randomString(8);
          uploadFile.alias =
            design.suggestedName || `Variant ${(props.value?.length || 0) + 1}`;
          props.onChange(uploadFile, design);
        }}
        preferences={{
          cropSelectPresetOptions: [
            [
              'Crop',
              [
                [undefined, 'Custom'],
                [1, 'Square'],
                [1.5, 'Landscape'],
                [0.7, 'Portrait'],
              ],
            ],
            [
              'Size',
              [
                [[180, 180], 'Profile Picture'],
                [[1200, 600], 'Header Image'],
                [[800, 400], 'Timeline Photo'],
              ],
            ],
          ],
        }}
        onHide={() => setIsAddVariant(false)}
        src={props.file?.file?.previews?.[0]?.url || props?.file?.file?.url}
      />
      <Thumbnails
        isAliasEditable
        disableRearrange
        onChange={(thumbnails) => {
          props.onSort([...thumbnails]);
        }}
        onDelete={(fileId) => {
          props.onSort([...props.value.filter((thumb) => thumb.id !== fileId)]);
        }}
        thumbnails={[
          ...(props.value || []).map((file) => {
            if (!file.url) {
              file.url = URL.createObjectURL(file);
            }
            return file;
          }),
        ]}
      />
    </>
  );
}
