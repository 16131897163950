import React, { useRef, useState } from 'react';
import './style.scss';
import { Icon, Input, Loader, Popover } from 'elements_v2';
import Flag from 'elements_v2/Flag';

function Dropdown(props) {
  const [selectedOptions, setSelectedOptions] = React.useReducer(
    (state, action) => [...action],
    [],
  );
  const [selectedOption, setSelectedOption] = React.useState(null);
  const [filter, setFilter] = useState('');
  const ref = React.useRef();
  const searchRef = useRef();

  React.useEffect(() => {
    if (props.multiple && props.value) {
      if (props.children) {
        setSelectedOptions(
          props.children.filter((option) =>
            props.value.includes(option.props.value),
          ),
        );
      } else if (props.options) {
        setSelectedOptions(
          props.options.filter((option) => props.value.includes(option.value)),
        );
      }
    } else if (!props.value) {
      setSelectedOption(null);
      setSelectedOptions([]);
    } else if (props.children && selectedOption?.props?.value !== props.value) {
      if (Array.isArray(props.value)) {
        setSelectedOption(
          props.children
            .filter((option) => {
              return (
                JSON.stringify(option.props.value) ===
                JSON.stringify(props.value)
              );
            })
            ?.pop()?.props,
        );
      } else {
        setSelectedOption(
          props.children
            .filter((option) => {
              return (
                JSON.stringify(option.props.value) ===
                JSON.stringify(props.value)
              );
            })
            ?.pop()?.props,
        );
      }
    } else if (props.options && selectedOption?.props?.value !== props.value) {
      setSelectedOption(
        props.options.filter((option) => option.value === props.value)?.pop(),
      );
    }
  }, [props.value, props.options, props.children]);

  function onChange(value, event) {
    if (props.onChange) {
      props.onChange(value, event);
    }
    // if (props.closeOnChange !== false) {
    //   setIsOpen(false);
    // }
  }

  const className = ['Pixi', 'Pixi__Dropdown'];

  if (props.disabled) {
    className.push('Pixi__Dropdown--disabled');
  }
  if (props.value && !props.noActive) {
    className.push('Pixi__Dropdown--active');
  }

  if (props.primary) {
    className.push('Pixi__Dropdown--primary');
    className.push('brand__color__bg');
  }

  if (props.onlyIcon) {
    className.push('Pixi__Dropdown--onlyIcon');
  }

  if (props.position) {
    className.push(`Pixi__Dropdown--${props.position}`);
  }

  if (props.auto) {
    className.push(`Pixi__Dropdown--auto`);
  }

  if (props.pixi) {
    className.push(`Pixi__Dropdown--pixi`);
  }
  if (props.noTrigger && props.noPopover) {
    className.push(`Pixi__Dropdown--noTrigger`);
  }

  const renderOptions = (isOpen, setIsOpen) => (
    <div
      className={`Pixi Pixi__Dropdown__options ${
        props.noTrigger && props.noPopover
          ? 'Pixi__Dropdown__options--noTrigger'
          : ''
      }`}
      ref={ref}
      style={{
        zIndex: props.zIndex,
        maxHeight: props.optionsMaxHeight,
        ...(props.optionsStyle || {}),
      }}
    >
      {/* {props.value && !props.noClear && !props.noTrigger && !props.noPopover && (
        <Option title disabled onChange={() => onChange(null)} value={null}>
          {props.placeholder}
        </Option>
      )} */}
      {Array.isArray(props.children) ? (
        props.children?.flat()?.map((option) => {
          if (Array.isArray(option)) {
            return <></>;
          }
          if (!option || !option.props) {
            return <></>;
          }
          let isSelected =
            option.props.selected ||
            (props.value && option.props.value === props.value);
          if (
            !isSelected &&
            typeof props.value === 'object' &&
            typeof option.props.value === 'object'
          ) {
            isSelected =
              JSON.stringify(option.props.value) ===
              JSON.stringify(props.value);
          }
          return React.cloneElement(option, {
            ...option.props,
            onChange: (value, event) => {
              if (props.multiple) {
                if (props.value?.includes(value)) {
                  onChange([...props.value].filter((str) => str === value));
                } else {
                  onChange([...props.value, value]);
                }
              } else {
                onChange(value, event);
              }
              if (!props.keepOpen && setIsOpen) {
                setIsOpen(false);
              }
            },
            selected: isSelected,
          });
        })
      ) : typeof props.children === 'function' &&
        typeof isOpen === 'boolean' &&
        setIsOpen ? (
        props.children(isOpen, setIsOpen)
      ) : !props.children ? (
        <>
          {props.options &&
            props.options.map((option) => (
              <Option
                selected={props.value?.includes(option.value)}
                onChange={(value, event) => {
                  setFilter('');
                  if (props.multiple) {
                    if (props.value?.includes(option.value)) {
                      onChange(
                        [...(props.value || [])].filter(
                          (str) => str !== option.value,
                        ),
                      );
                    } else {
                      onChange([...(props.value || []), option.value]);
                    }
                  } else if (props.onChange) {
                    props.onChange(option.value, event);
                  }
                  if (!props.keepOpen && setIsOpen) {
                    setIsOpen(false);
                  }
                }}
                disabled={option.disabled}
                style={{
                  display:
                    filter &&
                    props.search &&
                    !option.text.toLowerCase().includes(filter.toLowerCase()) &&
                    !option.value.toLowerCase().includes(filter.toLowerCase())
                      ? 'none'
                      : '',
                  ...(option.style || {}),
                }}
                key={option.key}
                value={option.value}
              >
                {option.flag && <Flag name={option.flag} />}
                {/* {!option.icon && props.value?.includes(option.value) ? (
                  <Icon name="CheckCircleFill" />
                ) : (
                  ''
                )} */}
                {option.icon && <Icon name={option.icon} />}
                {option.image ? (
                  <img src={option.image} alt={option.text} />
                ) : (
                  ''
                )}
                {option.text}
              </Option>
            ))}
        </>
      ) : (
        React.cloneElement(props.children, {
          onChange: (value, event) => {
            onChange(value, event);
            if (!props.keepOpen && setIsOpen) {
              setIsOpen(false);
            }
          },
        })
      )}
      {props.isLoadingOptions && <Loader>{props.loadingOptionsLabel}</Loader>}
    </div>
  );

  if (props.noPopover && props.noTrigger) {
    return renderOptions();
  }

  return (
    <>
      <Popover
        auto={props.auto}
        position={props.position}
        isOpen={
          (!!props.query && !props.disabled && !props.forceClosed) ||
          props.isOpen
        }
        overlay={props.overlay}
        overlayStyle={props.overlayStyle}
        hover={props.hover}
        disabled={props.disabled}
        sameWidthAsTrigger={
          typeof props.sameWidthAsTrigger === 'boolean'
            ? props.sameWidthAsTrigger
            : true
        }
        noTriggerWrapper={props.noTriggerWrapper}
        boxStyle={props.boxStyle}
        width={props.width}
        onOpen={() => {
          props.onOpen?.();
          if (searchRef?.current) {
            searchRef.current.focus();
          }
        }}
        onBottomScroll={props.onBottomScroll}
        usePortal={props.usePortal}
        noPopover={props.noPopover}
        forceClosed={props.forceClosed}
        useV2={props.useV2}
        limitHeight={props.limitHeight}
        getPopoverContainer={props.getPopoverContainer}
        zIndex={props.zIndex}
        boxContentStyle={{
          maxHeight: props.optionsMaxHeight || 350,
          overflow: props.overflow || 'auto',
          ...(props.boxContentStyle || {}),
        }}
        triggerStyle={{ ...(props.style || {}), ...(props.triggerStyle || {}) }}
        triggerClassName={
          !props.triggerClassName && !props.trigger
            ? className.join(' ')
            : props.triggerClassName
        }
        closeOnClick={!props.keepOpen}
        trigger={
          props.trigger ||
          ((isOpen, setIsOpen) => (
            <Input
              ref={ref}
              onClick={() => setIsOpen(!isOpen)}
              disabled={props.disabled}
              label={props.label}
              description={props.description}
              error={props.error}
              errorMessage={props.errorMessage}
              style={props.inputStyle}
              field={
                props.searchable ? (
                  <input
                    placeholder={props.searchPlaceholder}
                    onChange={(event) => {
                      props.onSearch(event.target.value, setIsOpen);
                    }}
                    ref={searchRef}
                    value={props.query}
                  />
                ) : props.search && isOpen ? (
                  <input
                    placeholder={props.searchPlaceholder}
                    onChange={(event) => {
                      setFilter(event.target.value);
                    }}
                    ref={searchRef}
                    value={filter}
                  />
                ) : props.multiple ? (
                  !selectedOptions?.length ? (
                    props.placeholder
                  ) : (
                    selectedOptions
                      .map((option) => option?.label || option?.text)
                      .join(', ')
                  )
                ) : (
                  selectedOption?.label ||
                  selectedOption?.children ||
                  selectedOption?.text ||
                  props.query ||
                  props.placeholder
                )
              }
              icon={props.icon && <Icon name={props.icon} />}
              active={!!props.value && !props.noActive}
              outlined={props.outlined}
              largeStatusOnActive={!props.noClear}
              largeStatus={props.largeStatus}
              placeholder={props.placeholder}
              status={
                props.status || (
                  <Icon
                    bubble={!!props.value && !props.noClear}
                    style={{
                      fontSize: !props.value
                        ? 13
                        : props.value && !props.noClear
                          ? 13
                          : 24,
                      pointerEvents: !props.value ? 'none' : 'auto',
                    }}
                    onClick={(event) => {
                      if (!props.noClear) {
                        event.preventDefault();
                        event.stopPropagation();
                        onChange(null);
                      }
                    }}
                    name={
                      props.value && !props.noClear ? 'x-lg' : 'CaretDownFill'
                    }
                  />
                )
              }
            />
          ))
        }
      >
        {(isOpen, setIsOpen) => <>{renderOptions(isOpen, setIsOpen)}</>}
      </Popover>
    </>
  );
}

const Label = ({ children }) => {
  return <div className="Pixi__Dropdown__options__label">{children}</div>;
};

const Option = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const className = [
    'Pixi',
    'Pixi--clickable',
    'Pixi__Dropdown__options__option',
  ];
  if (props.selected) {
    className.push('Pixi__Dropdown__options__option--selected');
  }
  if (props.disabled || isLoading) {
    className.push('Pixi__Dropdown__options__option--disabled');
    className.splice(className.indexOf('Pixi--clickable'), 1);
  }
  if (props.title) {
    className.push('Pixi__Dropdown__options__option--title');
  }
  if (props.danger) {
    className.push('Pixi__Dropdown__options__option--danger');
  }
  if (props.ellipsis) {
    className.push('Pixi__Dropdown__options__option--ellipsis');
  }
  if (props.primary) {
    className.push('Pixi__Dropdown__options__option--primary');
  }
  if (props.sticky) {
    className.push('Pixi__Dropdown__options__option--sticky');
  }
  if (props.active) {
    className.push('brand__color__text');
    className.push('Pixi__Dropdown__options__option--active');
  }

  const elementProps = {
    className: className.join(' '),
    onClick: async (event) => {
      if (props.onChange) {
        const result = props.onChange(props.value, event);
        if (result instanceof Promise && !props.noLoader) {
          setIsLoading(true);
          await result;
          setIsLoading(false);
        }
      }
      if (props.onClick) {
        const result = props.onClick(event);
        if (result instanceof Promise && !props.noLoader) {
          setIsLoading(true);
          await result;
          setIsLoading(false);
        }
      }
    },
    onMouseDown: props.onMouseDown,
    style: props.style,
    draggable: props.draggable,
    onDragStart: props.onDragStart,
  };

  let editIcon = null;
  if (props.editIcon) {
    editIcon = (
      <Icon
        name="pencil-fill"
        bubble
        onClick={(event) => {
          event.preventDefault();
          event.stopPropagation();
          props.onEditIconClick(event);
        }}
        button
        className="Pixi__Dropdown__options__option__editIcon"
      />
    );
  }

  if (props.as === 'a') {
    return (
      <a {...elementProps} href={props.href} target={props.target}>
        {props.editIcon ? (
          props.editIconWrapper ? (
            props.editIconWrapper(editIcon)
          ) : (
            editIcon
          )
        ) : (
          <></>
        )}
        {props.children}
      </a>
    );
  }

  return (
    <div {...elementProps}>
      {props.editIcon ? (
        props.editIconWrapper ? (
          props.editIconWrapper(editIcon)
        ) : (
          editIcon
        )
      ) : (
        <></>
      )}
      {props.children}
      {isLoading && (
        <Loader
          horizontal
          style={{ margin: 0, marginLeft: 'auto', padding: 0 }}
          small
          inline
        />
      )}
    </div>
  );
};

Dropdown.Option = Option;
Dropdown.Label = Label;

export default Dropdown;
