import { addMilliseconds, isPast } from 'date-fns';
import React, { Component } from 'react';

import './style.scss';

export const Toast = ({ active, tiny, message, error }) => (
  <div
    className={`ToastContainer__Toast ${
      active ? ' ToastContainer__Toast--active ' : null
    }${tiny ? ' ToastContainer__Toast--tiny ' : null}${
      error ? ' ToastContainer__Toast--error ' : null
    }`}
  >
    {message}
  </div>
);

export class ToastService {
  static callbacks = [];

  static toastAdded(cb) {
    ToastService.callbacks.push(cb);
  }

  static createToast(toast) {
    ToastService.callbacks.forEach((cb) => cb(toast));
  }
}

export class ToastContainer extends Component {
  state = {
    toasts: [],
  };

  constructor(props) {
    super(props);
    ToastService.toastAdded((toast) => {
      const { toasts } = this.state;
      toasts.push(toast);
      toast = toasts[toasts.length - 1];
      toast.active = true;
      toast.expire = addMilliseconds(new Date(), 4000);
      this.setState({
        toasts,
      });
      setTimeout(() => {
        toasts.forEach((toast_) => {
          if (isPast(new Date(toast.expire))) {
            toast_.active = false;
          }
        });
        this.setState({
          toasts,
        });
      }, toast.delay || 4000);
    });
  }

  render() {
    return (
      <div className="ToastContainer">
        {this.state.toasts.map((toast) => (
          <Toast
            key={toast.message + toast.expire}
            active={toast.active && !isPast(new Date(toast.expire))}
            message={toast.message}
            error={toast.error}
            tiny={toast.tiny}
          />
        ))}
      </div>
    );
  }
}
