import { Icon, Popover } from 'elements_v2';
import Loader from 'elements_v2/Loader';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { isIE11 } from 'utils/platform';
import './style.scss';

/**
 * This component is NOT TypeScript ready.
 * I had to convert it to fix some lint errors.
 * — Jake
 */
const Button = React.forwardRef<
  HTMLButtonElement | HTMLLinkElement | HTMLDivElement,
  any
>((props, ref) => {
  const {
    pixi,
    as,
    primary,
    className,
    background,
    outlined,
    inline,
    color,
    size,
    children,
    style,
    highlight,
    transparent,
    rightIcon,
    padding,
    loading,
    inverted,
    unclickable,
    dropdown,
    dropdownProps,
    dropdownTriggerOnClick,
    showCaret,
    onClick,
    contentStyle,
    active,
    ...rest
  } = props as any;
  const [isLoading, setIsLoading] = useState(false);

  const buttonClassName = [className, 'Pixi', 'Pixi__Button'];
  const supportedSizes = ['xl', 'large', 'small', 'tiny', 'huge'];
  let elementStyle = {
    background: style?.background || background,
    color: style?.color || color,
    padding: padding || style?.padding,
    minHeight: padding ? 0 : null,
  } as any;

  function togglePrimary() {
    buttonClassName.push('Pixi__Button--primary');
    if (!background) {
      if (transparent || outlined) {
        buttonClassName.push('brand__color__text');
        if (outlined) {
          buttonClassName.push('brand__color__border');
        }
      } else {
        buttonClassName.push('brand__color__bg');
      }
    }
  }

  if (primary) {
    togglePrimary();
  }

  if (outlined) {
    buttonClassName.push('Pixi__Button--outlined');
    if (!props.style?.background) {
      delete elementStyle.background;
    }

    if (color) {
      elementStyle.borderColor = color;
    }
  }

  if (highlight && !rest.disabled) {
    buttonClassName.push('Pixi__Button--highlight');
  }

  if (transparent) {
    buttonClassName.push('Pixi__Button--transparent');
  }
  if (inline) {
    buttonClassName.push('Pixi__Button--inline');
  }
  if (pixi) {
    buttonClassName.push('Pixi__Button--pixi');
  }
  if (loading) {
    buttonClassName.push('Pixi__Button--loading');
  }
  if (inverted) {
    buttonClassName.push('Pixi__Button--inverted');
  }
  if (active) {
    buttonClassName.push('Pixi__Button--active');
  }
  if (rest.disabled) {
    buttonClassName.push('Pixi__Button--disabled');
  }

  if (size && supportedSizes.includes(size)) {
    buttonClassName.push(`Pixi__Button--${size}`);
  }

  if (isIE11) {
    elementStyle.height = 39;
  }

  elementStyle = {
    ...elementStyle,
    ...(style || {}),
  };

  rest.className = buttonClassName.join(' ');

  const content = (
    <>
      <div
        className={`Pixi__Button__bg ${
          unclickable ? 'Pixi--unclickable' : 'Pixi--clickable'
        }`}
      />
      <div
        className={`Pixi__Button__content ${
          unclickable ? 'Pixi--unclickable' : 'Pixi--clickable'
        }`}
        style={{
          ...(contentStyle || {}),
          ...(rightIcon ? { paddingRight: 5 } : {}),
          ...(props.loading || isLoading ? { color: 'transparent' } : {}),
        }}
        onClick={
          props.loading || isLoading
            ? undefined
            : async (event) => {
                if (onClick) {
                  let result = onClick(event);
                  if (result instanceof Promise && !props.noLoader) {
                    setIsLoading(true);
                    try {
                      result = await result;
                    } catch (e) {
                      setIsLoading(false);
                      throw e;
                    }
                    setIsLoading(false);
                  }
                }
              }
        }
      >
        {props.loading || isLoading ? (
          <div className="Pixi__Button__content__loader">
            <Loader horizontal white={primary}>
              {props.loading || isLoading}
            </Loader>
          </div>
        ) : (
          ''
        )}
        {children}
        {rightIcon ? (
          <div
            className="Pixi__Button__RightIcon"
            style={{
              marginLeft: 'auto',
              flexShrink: 0,
            }}
          >
            {rightIcon}
          </div>
        ) : (
          ''
        )}
      </div>
      {dropdown ? (
        <Popover
          width={215}
          useV2
          zIndex={99999}
          position="bottomRight"
          closeOnClick
          trigger={(isOpen: boolean, setIsOpen: any) => (
            <div
              className="Pixi__Button__DropdownTrigger"
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
                setIsOpen(!isOpen);
                dropdownTriggerOnClick?.();
                return false;
              }}
            >
              <Icon
                name="caret-down-fill"
                style={{
                  ...(props.size === 'small' ? { fontSize: 8 } : {}),
                }}
              />
            </div>
          )}
          {...(dropdownProps || {})}
        >
          {dropdown}
        </Popover>
      ) : showCaret ? (
        <div className="Pixi__Popover__trigger">
          <div className="Pixi__Button__DropdownTrigger">
            <Icon
              name="caret-down-fill"
              style={{
                ...(props.size === 'small' ? { fontSize: 8 } : {}),
              }}
            />
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );

  if (as === 'a') {
    return (
      <a {...rest} ref={ref} style={elementStyle}>
        {content}
      </a>
    );
  }
  if (as === 'link') {
    return (
      <Link {...rest} ref={ref} style={elementStyle}>
        {content}
      </Link>
    );
  }
  if (as === 'div') {
    return (
      <div {...rest} ref={ref} style={elementStyle}>
        {content}
      </div>
    );
  }

  // WARNING: Doesn't work in IE11.
  if (rest?.type === 'submit') {
    return (
      <button {...rest} ref={ref} style={elementStyle}>
        {content}
      </button>
    );
  }

  return (
    <div {...rest} ref={ref} style={elementStyle}>
      {content}
    </div>
  );
});

export default Button;
