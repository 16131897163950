import * as yup from 'yup';
import React from 'react';
import Icon from './icon.svg?react';
import IconMedia from './icon_media.svg?react';
import IconDocuments from './icon_documents.svg?react';

export default [
  {
    moduleId: 'FILES',
    menuGroupIds: ['FILES'],
    moduleInterface: {
      files: 'FILES',
    },
    status: {
      isEmpty: (data) => {
        return !data?.files?.length;
      },
    },
    moduleMetadataInterface: {
      detailed: yup.boolean(),
      thumbnailSizes: yup.mixed().oneOf(['small', 'medium', 'large']),
      backgroundColor: yup.string(),
      textColor: yup.string(),
    },
    menuContent: { key: 'FILES', icon: <Icon />, name: 'Files' },
  },
  {
    moduleId: 'FILES_MEDIA',
    menuGroupIds: ['FILES', 'HEADER'],
    moduleInterface: {
      files: 'FILES',
    },
    status: {
      isEmpty: (data) => {
        return !data?.files?.length;
      },
    },
    moduleMetadataInterface: {
      detailed: yup.boolean(),
      thumbnailSizes: yup.mixed().oneOf(['small', 'medium', 'large']),
      backgroundColor: yup.string(),
      textColor: yup.string(),
    },
    menuContent: {
      key: 'FILES_MEDIA',
      icon: <IconMedia />,
      name: 'Image gallery',
    },
  },
  {
    moduleId: 'FILES_DOCUMENTS',
    menuGroupIds: ['FILES'],
    moduleInterface: {
      files: 'FILES',
    },
    status: {
      isEmpty: (data) => {
        return !data?.files?.length;
      },
    },
    moduleMetadataInterface: {
      detailed: yup.boolean(),
      thumbnailSizes: yup.mixed().oneOf(['small', 'medium', 'large']),
      backgroundColor: yup.string(),
      textColor: yup.string(),
    },
    menuContent: {
      key: 'FILES_DOCUMENTS',
      icon: <IconDocuments />,
      name: 'Documents',
    },
  },
];
