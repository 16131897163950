import Pickit from './Pickit';
import Community from './Community';
import User from './User';
import Insight from './Insight';
import Manage from './Manage';
import Customer from './Customer';
import Guideline from './Guideline';
import Policy from './Policy';
import License from './License';
import Proxy from './Proxy';
import Files from './Files';
import Flows from './Flows';

const Api = {
  Pickit,
  Community,
  User,
  Manage,
  Policy,
  Insight,
  Customer,
  Guideline,
  License,
  Proxy,
  Files,
  Flows,
};

export default Api;
