import { useViewportSize } from '@mantine/hooks';
import { getConfigStore } from '@pixi/store';
import { useLocation } from 'hooks/useRouter';
import useWindowSize from 'hooks/useWindowSize';
import { useCallback, useEffect, useMemo, useState } from 'react';

export default function AppViewSetter() {
  const { width } = useViewportSize();
  const { location } = useLocation();

  useEffect(() => {
    const isManage = location.pathname.startsWith('/manage');
    getConfigStore('APP_VIEW').setValue('isManage', isManage);
  }, [location]);

  useEffect(() => {
    const newViewport = getViewport(width);
    const viewport = getConfigStore('APP_VIEW').getValue('viewport');
    if (newViewport !== viewport) {
      getConfigStore('APP_VIEW').setValue('viewport', newViewport);
    }
  }, [width]);

  function getViewport(
    width: number,
  ): 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' {
    if (width < 360) {
      return 'xxs';
    }
    if (width < 460) {
      return 'xs';
    }
    if (width < 500) {
      return 'sm';
    }
    if (width < 768) {
      return 'md';
    }
    if (width < 1250) {
      return 'lg';
    }
    return 'xl';
  }
  return <></>;
}
