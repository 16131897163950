import { useAppContext } from 'contexts/Providers/AppProvider';
import React, {
  createContext,
  useContext,
  useEffect,
  useReducer,
  useRef,
} from 'react';
import localStorageWrapper from 'utils/localStorageWrapper';
import objectPath from 'object-path';
import useLibraries from 'hooks/useLibraries';
import { lastDayOfISOWeek } from 'date-fns';
import useNativeUpdate, { UseNativeUpdate } from './useNativeUpdate';

interface NativeProps {
  settings: NativeSettings;
  setSettings: (settings: Partial<NativeSettings>) => void;
  isConfigured?: boolean;
  resetSettings?: () => void;
  openAtLogin: (openAtLogin: boolean) => void;
  copyFileToClipboard?: (
    data: {
      file?: any;
      fileUrl?: string;
    }[],
  ) => Promise<void>;
  openAndEditFile?: (data: { file?: any; fileUrl?: string }) => Promise<void>;
  openFile?: (data: { file?: any; fileUrl?: string }) => Promise<void>;
  clearCache: () => Promise<void>;
  cache: {
    size?: number;
  };
  setSettingsValue: (path: string, value: any, autoSave?: boolean) => void;
  nativeApi: Window['pickitNative'];
  updater: UseNativeUpdate;
}
interface NativeCache {
  size?: number;
}

export const NativeContext = createContext<NativeProps>({} as NativeProps);
const defaultSettings: NativeSettings = {
  imageQuality: 'large',
  colorScheme: 'system',
};

export function NativeProvider({
  children,
}: {
  children: JSX.Element;
}): JSX.Element {
  const { media, documents } = useLibraries() as { media: any; documents: any };
  const App = useAppContext();
  const updater = useNativeUpdate();
  const watchFileAlreadyRun = useRef(false);
  const Libraries = useLibraries() as any;
  const [cache, setCache] = useReducer(
    (state: NativeCache, action: NativeCache) => ({ ...action }),
    {},
  );
  const [settings, setSettings] = useReducer(
    (state: NativeSettings, action: NativeSettings) => {
      if (action.reset) {
        return { ...action };
      }
      return {
        ...state,
        ...action,
      };
    },
    defaultSettings,
  );

  function openAtLogin(openAtLogin: boolean) {
    window.pickitNative.openAtLogin(openAtLogin);
  }
  function resetSettings() {
    setSettings({});
    window.pickitNative.saveSettings({});
  }

  function setSettingsValue(path: string, value: any, autoSave?: boolean) {
    if (path === 'colorScheme') {
      App.setForcedTheme(value === 'system' ? false : value);
    }
    const currentSettings = { ...settings };
    objectPath.set(currentSettings, path, value);
    setSettings(currentSettings);
    if (autoSave) {
      window.pickitNative.saveSettings(currentSettings);
    }
  }

  async function refreshCacheInfo() {
    const cache = await window.pickitNative.getCache();
    setCache(cache);
  }

  async function clearCache() {
    await window.pickitNative.clearCache();
    setCache({ size: 0 });
  }

  async function copyFileToClipboard(data: { file?: any; fileUrl?: string }[]) {
    try {
      await window.pickitNative.copyContentToClipboard(data);
    } catch (e) {
      console.error(e);
    }
    refreshCacheInfo();
  }
  async function openFile(data: { file?: any; fileUrl?: string }) {
    try {
      await window.pickitNative.openFile(data);
    } catch (e) {
      console.error(e);
    }
  }
  async function openAndEditFile(data: { file?: any; fileUrl?: string }) {
    try {
      const path = await window.pickitNative.openAndEditFile(data);
      if (
        settings.watchingFiles?.find((file: any) => file?._id === data.file._id)
      ) {
        return;
      }
      setSettingsValue(
        'watchingFiles',
        [
          ...(settings.watchingFiles || []),
          {
            filePath: path,
            file: data.file,
          },
        ],
        true,
      );
    } catch (e) {
      console.error(e);
    }
  }

  useEffect(() => {
    const timeout = setInterval(() => {
      window.pickitNative?.getStorageMap?.();
    }, 5000);
    return () => {
      clearInterval(timeout);
    };
  }, []);

  useEffect(() => {
    const mediaTokens = media.sasToken;
    const documentTokens = documents.sasToken;
    window?.pickitNative?.setStorageToken({
      media: mediaTokens,
      documents: documentTokens,
    });
  }, [media?.sasToken, documents?.sasToken]);

  useEffect(() => {
    if (!window.pickitNative) {
      return;
    }
    if (settings.colorScheme === 'dark') {
      App.setForcedTheme('dark');
      App.forceDarkMode(true);
    }
    if (settings.colorScheme === 'light') {
      App.setForcedTheme('light');
      App.forceDarkMode(false);
      localStorageWrapper.removeItem('userDarkMode');
    }
    if (settings.colorScheme === 'system') {
      App.setForcedTheme(false);
      App.forceDarkMode(false);
      App.setAutoDarkMode();
      localStorageWrapper.removeItem('userDarkMode');
    }
  }, [settings?.colorScheme]);

  useEffect(() => {
    if (!window.pickitNative) {
      return;
    }
    if (settings.colorScheme) {
      window.pickitNative.setColorScheme(settings.colorScheme);
    }
    if (
      settings?.watchingFiles?.length &&
      media?.sasToken?.main &&
      !watchFileAlreadyRun?.current
    ) {
      watchFiles(settings);
    }
  }, [settings, media?.sasToken]);

  useEffect(() => {
    if (!window.pickitNative) {
      return;
    }
    (async () => {
      const settings = await window.pickitNative.getSettings();
      const cache = await window.pickitNative.getCache();
      if (settings) {
        setSettings(settings);
      }
      if (cache) {
        setCache(cache);
      }
    })();
  }, []);

  async function watchFiles({ watchingFiles: files }: NativeSettings) {
    if (!files?.length) {
      return;
    }
    watchFileAlreadyRun.current = true;
    const libraries: {
      media: string[];
      documents: string[];
    } = {
      media: [],
      documents: [],
    };
    files?.forEach((file) => {
      libraries[file.file?.type as keyof typeof libraries].push(
        file.file._id as string,
      );
    });
    if (!Libraries.media?.storageTokens?.main) {
      return;
    }
    await Promise.all(
      Object.keys(libraries).map(async (libraryKey) => {
        const newFiles = await Libraries[libraryKey].getDocumentsFromArrayId(
          libraries[libraryKey as keyof typeof libraries],
        );
        newFiles.forEach((nf: any) => {
          const oldFileIndex = files?.findIndex((f) => nf._id === f.file._id);
          if (oldFileIndex > -1) {
            files[oldFileIndex].file = nf;
          }
        });
      }),
    );
    let watchingFiles = files as {
      filePath: string;
      file: any;
    }[];
    let hasChanged = false;
    for (let i = 0; i < watchingFiles.length; i++) {
      const { file, filePath } = watchingFiles[i];
      const isOK = await window.pickitNative.watchFile(file, filePath);
      if (isOK === false) {
        hasChanged = true;
        watchingFiles = watchingFiles.filter(
          (f: any) => f.file._id !== file._id,
        );
      }
    }
    if (hasChanged) {
      setSettingsValue('watchingFiles', watchingFiles, true);
    }
  }

  return (
    <NativeContext.Provider
      value={{
        settings,
        setSettings,
        setSettingsValue,
        isConfigured: settings.wizardCompleted,
        resetSettings,
        copyFileToClipboard,
        openFile,
        openAndEditFile,
        clearCache,
        cache,
        openAtLogin,
        updater,
        nativeApi: window.pickitNative,
      }}
    >
      {children}
    </NativeContext.Provider>
  );
}

export function useNative<T>(): NativeProps {
  return useContext(NativeContext);
}
